import React, { useCallback, useRef } from "react";

// components
import { CheckBox } from "./CheckBox";
import { Accordion } from "./Accordion";

// third party
import { useTrail, config, animated } from "react-spring";
import ContextMenu from "./ContextMenu";
import Placeholder from "./Placeholder";

export const CommonTable = ({
	data = [],
	columns = [],
	loading,
	sortedField,
	sortList,
	hideTableHeader = false,
	classes = "",
	content = "",
	customPlaceholder = "",
	placeholderClasses = "",
	currencySymbol = "",
	archived = "",
	handleTask,
	checkbox = false,
	accordion = false,
	accordionContent = "",
	accordionContentColumns = [],
	infinteScroll = false,
	bordered = false,
	rightClickEnabled = false,
	hideColumns = [],
	closeContextMenu,
	openContextMenu,
	renderMenuItems,
	showContextMenu,
	showPlaceholder = false,
	placeholderContent,
	...rest
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});

	if (showPlaceholder && data.length === 0 && !loading) {
		return <Placeholder {...placeholderContent} />;
	}
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container " +
				classes
			}
		>
			<div className={"transactions-list-table " + (bordered ? "bordered" : "")}>
				<div className="at-table-row-based">
					{!hideTableHeader && (
						<TableHeader
							sortList={sortList}
							sortedField={sortedField}
							columns={columns}
							archived={archived}
							checkbox={checkbox}
							hideColumns={hideColumns}
							{...rest}
						/>
					)}
					{trails.map(({ rotate }, rowIndex) => {
						const restExtended = { ...rest, rowIndex };
						if (accordion) {
							return (
								<AccordionList
									key={rowIndex}
									style={{
										transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
									}}
									data={data[rowIndex] || {}}
									columns={columns}
									hideColumns={hideColumns}
									currencySymbol={currencySymbol}
									archived={archived}
									handleTask={handleTask}
									checkbox={checkbox}
									accordion={accordion}
									accordionContent={accordionContent}
									accordionContentColumns={accordionContentColumns}
									infinteScroll={infinteScroll}
									rightClickEnabled={rightClickEnabled}
									{...restExtended}
								/>
							);
						}
						return (
							<TableList
								key={rowIndex}
								style={{
									transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
								}}
								data={data[rowIndex] || {}}
								columns={columns}
								hideColumns={hideColumns}
								currencySymbol={currencySymbol}
								archived={archived}
								handleTask={handleTask}
								checkbox={checkbox}
								accordion={accordion}
								accordionContent={accordionContent}
								accordionColumns={accordionContentColumns}
								rightClickEnabled={rightClickEnabled}
								closeContextMenu={closeContextMenu}
								openContextMenu={openContextMenu}
								renderMenuItems={renderMenuItems}
								showContextMenu={showContextMenu}
								{...restExtended}
							/>
						);
					})}
					{data.length === 0 && !loading && content && !customPlaceholder && (
						<div className="no-items-placeholder">No {content} found!</div>
					)}
					{data.length === 0 && !loading && customPlaceholder && (
						<div className={"custom-placeholder " + placeholderClasses}>{customPlaceholder}</div>
					)}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ columns, sortList, sortedField, archived, checkbox, hideColumns, ...rest }) => (
	<div className="at-table-row transaction-header-row">
		{columns.map((obj, i) => {
			return !hideColumns.includes(obj.field) ? (
				<div
					key={i}
					className={
						`at-table-cell at-table-header at-header-text ${obj.field}` +
						(obj.sortKey ? " clickable" : "") +
						(sortedField && sortedField === obj.sortKey ? " active " + archived : "")
					}
					style={rest.appliedInlineStyles ? rest.appliedInlineStyles : {}}
					onClick={obj.sortKey && (() => sortList(obj.sortKey))}
				>
					{checkbox && i === 0 && (
						<CheckBox
							checked={rest.isCheckedAll}
							clickHandler={(e) => {
								e.stopPropagation();
								rest.handleCheckAll(e, !rest.isCheckedAll);
							}}
						/>
					)}
					<span>{obj.name}</span>
					{obj.sortKey && (
						<span>
							&nbsp;&nbsp;
							<img src="/assets/icons/icon-sort.svg" alt="" />
						</span>
					)}
					{obj.subHeader && obj.subHeader()}
				</div>
			) : null;
		})}
	</div>
);

const TableList = ({
	data,
	columns,
	currencySymbol,
	archived,
	handleTask,
	checkbox,
	style,
	rightClickEnabled = false,
	hideColumns,
	closeContextMenu,
	openContextMenu,
	renderMenuItems,
	showContextMenu,
	...rest
}) => {
	const { setAnchorPoint, setContextMenuId, setContextMenuData } = rest;

	const handleContextMenu = (event) => {
		if (rightClickEnabled) {
			event.preventDefault();
			setAnchorPoint && setAnchorPoint(event.pageX || 0, event.pageY || 0);
			setContextMenuId && setContextMenuId(data?.id);
			setContextMenuData && setContextMenuData(data);
		}
	};

	return (
		<animated.div
			// style={style}
			className={`at-table-row transaction-rows ${rest.contextMenuId === data.id ? "active" : ""}`}
			data-testid="transaction-rows"
			onContextMenu={handleContextMenu}
		>
			{columns.map((obj, i) => {
				return !hideColumns.includes(obj.field)
					? obj.render(data, i, archived, currencySymbol, handleTask, checkbox, rest)
					: null;
			})}
			{showContextMenu && (
				<ContextMenu
					isOpen={rest.contextMenuId === data.id}
					data={data}
					renderMenuItems={renderMenuItems}
					handleOpenMenu={() => openContextMenu(data)}
					handleOutsideClick={rest.contextMenuId === data.id ? () => closeContextMenu(data) : () => {}}
					clickEvent="mousedown"
					appliedInlineStyles={{ width: "10%" }}
				/>
			)}
		</animated.div>
	);
};

const AccordionContent = ({
	data,
	currencySymbol,
	archived,
	handleTask,
	checkbox,
	accordionColumns,
	lastElem,
	handleObserver,
	hideColumns,
	...rest
}) => {
	if (lastElem) {
		return (
			<div
				className={"at-table-row transaction-rows" + (rest?.currView?.id === data.id ? " view" : "")}
				ref={handleObserver}
			>
				{accordionColumns.map((obj, i) => {
					return !hideColumns.includes(obj.field)
						? obj.render(data, i, archived, currencySymbol, handleTask, checkbox, rest)
						: null;
				})}
			</div>
		);
	}
	return (
		<div className={"at-table-row transaction-rows" + (rest?.currView?.id === data.id ? " view" : "")}>
			{accordionColumns.map((obj, i) => {
				return !hideColumns.includes(obj.field)
					? obj.render(data, i, archived, currencySymbol, handleTask, checkbox, rest)
					: null;
			})}
		</div>
	);
};

const AccordionList = ({
	data,
	columns,
	currencySymbol,
	archived,
	handleTask,
	checkbox,
	accordionContent,
	accordionContentColumns,
	infinteScroll,
	style,
	rightClickEnabled = false,
	hideColumns,
	...rest
}) => {
	const { onAccordionOpen, onAccordionClose, rowIndex } = rest;
	const accordionData = accordionContent ? data[accordionContent] || {} : {};

	const observer = useRef();

	const handleObserver = useCallback(
		(node) => {
			if (accordionData?.loading) {
				return;
			}
			if (observer.current) {
				observer.current.disconnect();
			}
			observer.current = new IntersectionObserver((entries) => {
				const {
					[accordionContent]: { count, limit, offset }
				} = data;
				if (entries[0].isIntersecting && count > offset + limit) {
					onAccordionOpen(data, true, rowIndex);
				}
			});
			if (node) {
				observer.current.observe(node);
			}
		},
		[accordionData?.loading, observer]
	);

	return (
		<Accordion
			inTable={true}
			tableRow={columns.map((obj, i) => {
				return !hideColumns.includes(obj.field)
					? obj.render(data, i, archived, currencySymbol, handleTask, checkbox, rest)
					: null;
			})}
			currView={rest.currView || {}}
			record={data}
			isExpanded={rest?.expandedRows?.[data.id] || false}
			loading={accordionData?.loading || false}
			onAccordionOpen={onAccordionOpen ? () => onAccordionOpen(data, false, rowIndex) : undefined}
			onAccordionClose={onAccordionClose ? () => onAccordionClose(data) : undefined}
		>
			{accordionData?.objects?.map((obj, i) => (
				<AccordionContent
					key={i}
					data={obj || {}}
					currencySymbol={currencySymbol}
					archived={archived}
					handleTask={handleTask}
					checkbox={checkbox}
					accordionColumns={accordionContentColumns}
					hideColumns={hideColumns}
					accordion={false}
					lastElem={infinteScroll && accordionData?.objects?.length === i + 1}
					handleObserver={handleObserver}
					{...rest}
				/>
			))}
			{accordionData?.objects?.length === 0 && !accordionData?.loading && (
				<div className="no-items-placeholder">No data found!</div>
			)}
			{(accordionData?.objects?.length === 0 ||
				(infinteScroll && accordionData?.limit + accordionData?.offset < accordionData?.count)) &&
				accordionData?.loading && (
					<div className="loader P(10px)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				)}
		</Accordion>
	);
};

import React, { useEffect, useRef, useState } from "react";
import { FormSidebar } from "../../_commons/FormSidebar";
import { HorizonBanner } from "@urbanpiper-engineering/horizon";
import { InputWithLabel } from "../../_commons/InputWithLabel";
import { TagsInputWrapper } from "../../_commons/TagsInputWrapper";
import { getAllAppliedFilters, shareLinkThroughEmail } from "../../../actions/analytics";
import { lS, trackEvent } from "../../../atlas-utils";
import { store } from "../../../store/configureStore";
import NotificationServices from "../../../services/NotificationService";

export const Share = ({ module, handleCancel = () => {}, isOpen = false }) => {
	const bizId = store.getState().login?.loggedInbizDetail?.id;
	const title = (
		<div>
			<img className="sidebar-share-link" src="/assets/analytics/shareLink.svg" /> <span>Share Link</span>
		</div>
	);

	const [currEmail, setCurrEmail] = useState("");
	const [emailValidationMsg, setEmailValidationMsg] = useState("");
	const handleTagInput = (val) => {
		setCurrEmail(val);
		if (emailValidationMsg) {
			setEmailValidationMsg("");
		}
	};
	const [emailListFilter, setEmailListFilter] = useState(
		lS.get("analytics_emails") ? lS.get("analytics_emails")[bizId] || [] : []
	);
	const tagsRef = useRef();
	const setEmailFilter = (tags) => {
		setEmailListFilter(tags);
		lS.set("analytics_emails", { ...lS.get("analytics_emails"), [bizId]: [...tags] });
	};
	const trackTheEvent = (action, emailCount) => {
		const durationObject = getAllAppliedFilters()?.durationObject?.duration;
		const updatedCurrentDateFilter = {
			...store.getState().analyticsFiltersState.appliedDateFilter
		};
		let metaData = {
			share_method: "link",
			module: module,
			link_share_action: action,
			date_range: (durationObject?.preset
				? durationObject.preset
				: `last_${updatedCurrentDateFilter?.current?.rangeEndDate.diff(
						updatedCurrentDateFilter?.current?.rangeStartDate,
						"d"
					)}_days` || ""
			).toLowerCase()
		};
		if (action === "email_link") {
			metaData = { ...metaData, recipient_email_count: emailCount };
		}

		trackEvent("analytics_module_shared", {
			...metaData
		});
	};

	const handleSubmit = async () => {
		handleCancel();
		const emailList = [...emailListFilter];
		const resp = await shareLinkThroughEmail({
			emails: emailList,
			url: window.location.href
		});
		if (resp) {
			trackTheEvent("email_link", emailList.length);
			NotificationServices.pushNotification({
				message: `Link shared successfully`,
				timeout: 3000,
				type: "success",
				isClosable: true,
				theme: "dark"
			});
		} else {
			NotificationServices.pushNotification({
				message: `Failed to share link. Please try again.`,
				timeout: 3000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
		}
	};
	return (
		<>
			<FormSidebar
				isOpen={isOpen}
				close={handleCancel}
				title={title}
				isNested={false}
				overlaySidebarClasses="small"
				disabled={(emailListFilter || []).length == 0}
				submitTitle="Share"
				submit={handleSubmit}
				formSidebarClasses="share-link-form"
			>
				<HorizonBanner
					headingClass="share-link"
					status="info"
					bannerHeading={
						<div className="form-container">
							<div className="title"> Access Restrictions: Authorised Personnel Only</div>
							<div className="desc">
								Only individuals with authorisation are permitted to view this report on Atlas.
							</div>
						</div>
					}
				/>
				<InputWithLabel
					handleCopyText={() => trackTheEvent("copy_link")}
					value={window.location.href}
					readOnly={true}
					showCopyToClipboard={true}
					showIcon={true}
					classes="at-input--copy"
				>
					Copy link
				</InputWithLabel>
				<div className="or-container">
					<div className="text">OR</div>
				</div>
				<div className="export-template-btn">
					<TagsInputWrapper
						ref={tagsRef}
						tags={emailListFilter}
						onChange={setEmailFilter}
						tagInput={currEmail}
						onChangeInput={handleTagInput}
						requiredLabel={true}
						placeholder={"Enter an email"}
						validationRegex={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
						onValidationReject={() => setEmailValidationMsg("Please enter a valid email")}
						validationMessage={emailValidationMsg}
						showDropdown={true}
					>
						Recipient Emails
					</TagsInputWrapper>
				</div>
			</FormSidebar>
		</>
	);
};

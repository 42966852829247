import React, { useState } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Switch } from "../_commons/Switch";
import { Textarea } from "../_commons/Textarea";
import { Modal } from "../_commons/Modal";
import CascadeLocationsPrice from "../EntityAssociations/CascadeLocationsPrice";
import LightningIcon from "../_icons/LightningIcon";

// third party
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

//utils
import { isFeatureFlagEnabled, printCurrency } from "../../atlas-utils";

// constants
import { FOOD_TYPES, LOCATION_ENTITY_ASSOCIATION_TYPES } from "../../client-config";

// actions
import { fetchTimingGroupsDebounced } from "../../actions/actions";

const BasicInfo = ({
	categories,
	timingGroups,
	data = {},
	itemTags = [],
	handleForm,
	validations,
	biz,
	scrollDown,
	showDropdownNull = true,
	showLanguages = false,
	currSelectedLang = {},
	handleLanguage,
	handleTranslation,
	itemTranslations = {},
	saveTranslation,
	readOnly = true,
	setModalBusy,
	cascadePriceModalOpen,
	handleModal,
	showCascade = true,
	fetchItemTags,
	fulfillmentModes,
	isFromMenuSection,
	menuItemInfo,
	ruleAppliedFields = [],
	translationLoader
}) => {
	const [associationSidebar, setAssociationSidebar] = useState(false);
	const isMenuOverCatalogueEnabled = biz?.isMenuOverCatalogueEnabled || false;

	const { pathname } = useLocation();
	const isViewedFromMenuSection = pathname.includes("/menus/edit/");

	const openAssociationSidebar = () => {
		setAssociationSidebar(true);
		setModalBusy(true);
		handleModal();
	};

	const closeAssociationSidebar = () => {
		setAssociationSidebar(false);
		setModalBusy(false);
	};

	return (
		<React.Fragment>
			<div className="form-row row-full">
				<InputWithLabel
					value={
						isFromMenuSection
							? isFeatureFlagEnabled("Menu-V2-Translations")
								? (itemTranslations[currSelectedLang?.itemTitle?.lang]?.name ??
									(currSelectedLang.itemTitle.lang === "en" ? menuItemInfo?.name : ""))
								: menuItemInfo?.name
							: currSelectedLang.itemTitle
								? currSelectedLang.itemTitle.lang === "en"
									? data.itemTitle
									: currSelectedLang.itemTitle.value
								: data.itemTitle
					}
					onChange={(e) =>
						isFromMenuSection
							? handleForm("name", e.target.value)
							: showLanguages
								? handleTranslation("itemTitle", e.target.value)
								: handleForm("itemTitle", e.target.value)
					}
					requiredLabel={!showLanguages}
					showLanguages={
						showLanguages && (!isViewedFromMenuSection || isFeatureFlagEnabled("Menu-V2-Translations"))
					}
					currSelectedLang={(currSelectedLang.itemTitle && currSelectedLang.itemTitle.lang) || ""}
					handleLanguage={(lang) => handleLanguage(lang, "itemTitle")}
					showLoading={showLanguages && translationLoader}
					showActionBtn={currSelectedLang.itemTitle && currSelectedLang.itemTitle.showActionBtn}
					action={() =>
						saveTranslation(
							"itemTitle",
							currSelectedLang.itemTitle
								? currSelectedLang.itemTitle.lang === "en"
									? data.itemTitle
									: currSelectedLang.itemTitle.value
								: data.itemTitle
						)
					}
					field="item_title"
					validationMessage={validations.itemTitle || ""}
					readOnly={isFromMenuSection ? false : readOnly}
					classes={
						currSelectedLang.itemTitle && currSelectedLang.itemTitle.showActionBtn ? "at-input--save" : ""
					}
					endIcon={
						isFromMenuSection ? (
							<LightningIcon fillColor={ruleAppliedFields.includes("name") ? "#6D7AA8" : "#D0D0D0"} />
						) : null
					}
				>
					Title
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				{!biz?.isMenuOverCatalogueEnabled ? (
					<SelectFilter
						title="Category"
						options={categories?.items || []}
						readOnly={readOnly}
						isNull={
							showDropdownNull && data.itemDiscreteCategory !== undefined && !data.itemDiscreteCategory
						}
						archived={data.itemDiscreteCategory && !data.itemDiscreteCategory.isActive}
						isLoading={categories.isLoading}
						field="itemDiscreteCategory"
						currValue={data.itemDiscreteCategory}
						setFilter={handleForm}
						labelKey="name"
						valueKey="id"
						validationMessage={validations.itemDiscreteCategory || ""}
					/>
				) : isViewedFromMenuSection ? (
					<SelectFilter
						title="Section"
						options={categories?.items || []}
						readOnly={true}
						isNull={
							showDropdownNull && data.itemDiscreteCategory !== undefined && !data.itemDiscreteCategory
						}
						archived={data.itemDiscreteCategory && !data.itemDiscreteCategory.isActive}
						isLoading={categories.isLoading}
						field="itemDiscreteCategory"
						currValue={data.itemDiscreteCategory}
						setFilter={handleForm}
						labelKey="name"
						valueKey="id"
						validationMessage={validations.itemDiscreteCategory || ""}
					/>
				) : null}
				<SelectFilter
					title="Food type"
					options={FOOD_TYPES}
					readOnly={readOnly}
					field="foodType"
					currValue={data.foodType}
					setFilter={handleForm}
					labelKey="label"
					valueKey="value"
					requiredLabel={true}
					validationMessage={validations.foodType || ""}
				/>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.sortOrder}
					type="number"
					onChange={(e) => handleForm("sortOrder", e.target.value ? parseInt(e.target.value) : null)}
					validationMessage={validations.sortOrder || ""}
					showCustomTooltip={true}
					tooltipInfo="Control ordering of items as they are displayed to your customers. Values closer to zero will push the item to top while higher values will push it towards bottom"
					readOnly={readOnly}
				>
					Sort Order
				</InputWithLabel>
				<Switch
					title="Is Recommended"
					checked={
						menuItemInfo?.isRecommended !== null || menuItemInfo?.isRecommended !== undefined
							? menuItemInfo?.isRecommended
							: data.isRecommended
					}
					clickHandler={() =>
						handleForm(
							"isRecommended",
							menuItemInfo?.isRecommended !== null || menuItemInfo?.isRecommended !== undefined
								? !menuItemInfo?.isRecommended
								: !data.isRecommended
						)
					}
					validationMessage={validations.isRecommended || ""}
					readOnly={isFromMenuSection ? false : readOnly}
				/>
			</div>

			<div className="form-row row-half">
				<InputWithLabel
					value={isFromMenuSection ? menuItemInfo?.price : data.itemPrice}
					type="number"
					onChange={(e) =>
						isFromMenuSection
							? handleForm("price", e.target.value ? Number(e.target.value) : null)
							: handleForm("itemPrice", e.target.value ? Number(e.target.value) : null)
					}
					validationMessage={validations.itemPrice}
					requiredLabel={true}
					showLabel={true}
					classes="at-input--label"
					currency={true}
					currencySymbol={biz.currencySymbol}
					showCustomTooltip={true}
					tooltipInfo="Price of the item as it's displayed to your customers"
					readOnly={isFromMenuSection ? false : readOnly}
					showCascade={isFromMenuSection || isMenuOverCatalogueEnabled ? false : showCascade && !readOnly}
					showPriceCascadeSection={openAssociationSidebar}
					endIcon={
						isFromMenuSection ? (
							<LightningIcon fillColor={ruleAppliedFields.includes("price") ? "#6D7AA8" : "#D0D0D0"} />
						) : null
					}
				>
					Default Sales Price
				</InputWithLabel>
				<InputWithLabel
					value={isFromMenuSection ? menuItemInfo?.markupPrice : data.markupPrice}
					type="number"
					onChange={(e) => handleForm("markupPrice", e.target.value ? Number(e.target.value) : null)}
					validationMessage={validations.markupPrice || ""}
					showLabel={true}
					classes="at-input--label"
					currency={true}
					currencySymbol={biz.currencySymbol}
					showCustomTooltip={true}
					tooltipPosition="up"
					tooltipInfo="Markup price of item to be striked through and offered at default sales price. This trick lets you drive your sales up (eg. $̶9̶9̶9̶ $500!)"
					readOnly={isFromMenuSection ? false : readOnly}
					endIcon={
						isFromMenuSection ? (
							<LightningIcon
								fillColor={ruleAppliedFields.includes("markup_price") ? "#6D7AA8" : "#D0D0D0"}
							/>
						) : null
					}
				>
					Markup Price (Optional)
				</InputWithLabel>
			</div>
			<div className="form-row row-full">
				<Textarea
					value={
						isFromMenuSection
							? isFeatureFlagEnabled("Menu-V2-Translations")
								? (itemTranslations[currSelectedLang?.itemDesc?.lang]?.description ??
									(currSelectedLang.itemDesc.lang === "en" ? menuItemInfo?.description : ""))
								: menuItemInfo?.description
							: currSelectedLang.itemDesc
								? currSelectedLang.itemDesc.lang === "en"
									? data.itemDesc
									: currSelectedLang.itemDesc.value
								: data.itemDesc
					}
					onChange={(e) =>
						isFromMenuSection
							? handleForm("description", e.target.value)
							: showLanguages
								? handleTranslation("itemDesc", e.target.value)
								: handleForm("itemDesc", e.target.value)
					}
					showLanguages={
						showLanguages && (!isViewedFromMenuSection || isFeatureFlagEnabled("Menu-V2-Translations"))
					}
					currSelectedLang={(currSelectedLang.itemDesc && currSelectedLang.itemDesc.lang) || ""}
					handleLanguage={(lang) => handleLanguage(lang, "itemDesc")}
					showLoading={showLanguages && translationLoader}
					showActionBtn={currSelectedLang.itemDesc && currSelectedLang.itemDesc.showActionBtn}
					action={() =>
						saveTranslation(
							"itemDesc",
							currSelectedLang.itemDesc
								? currSelectedLang.itemDesc.lang === "en"
									? data.itemDesc
									: currSelectedLang.itemDesc.value
								: data.itemDesc
						)
					}
					field="item_desc"
					readOnly={isFromMenuSection ? false : readOnly}
					classes={
						currSelectedLang.itemDesc && currSelectedLang.itemDesc.showActionBtn ? "at-textarea--save" : ""
					}
					endIcon={
						isFromMenuSection ? (
							<LightningIcon
								fillColor={ruleAppliedFields.includes("description") ? "#6D7AA8" : "#D0D0D0"}
							/>
						) : null
					}
				>
					Description
				</Textarea>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.merchantRefId === "-1" ? "" : data.merchantRefId}
					onChange={(e) => handleForm("merchantRefId", e.target.value)}
					showCustomTooltip={true}
					tooltipInfo="Applies only for third party POS integrations. Ignore if it doesn't apply to you"
					validationMessage={validations.merchantRefId || ""}
					readOnly={readOnly}
				>
					POS ID
				</InputWithLabel>
				<InputWithLabel
					value={isFromMenuSection ? data?.crmName : data.crmTitle || ""}
					onChange={(e) => handleForm("crmTitle", e.target.value)}
					showCustomTooltip={true}
					tooltipInfo="Short text to easily identify and lookup the item"
					validationMessage={validations.crmTitle || ""}
					readOnly={readOnly}
				>
					CRM Title
				</InputWithLabel>
			</div>
			<div className="form-row row-half">
				<SelectFilter
					title="Fulfillment Modes"
					options={fulfillmentModes?.items || []}
					readOnly={readOnly}
					field="fulfillmentModes"
					isLoading={fulfillmentModes.isLoading}
					currValue={data.fulfillmentModes}
					setFilter={handleForm}
					labelKey="nameForDisplay"
					valueKey="id"
					validationMessage={validations.fulfillmentModes || ""}
					multi={true}
					isSearchable={false}
					placeholder="Select"
					// scrollDown={scrollDown}
				/>
				<SelectFilter
					title="Tags"
					loadOptions={fetchItemTags}
					readOnly={readOnly}
					field="tags"
					currValue={data.tags}
					setFilter={handleForm}
					labelKey="titleForDisplay"
					valueKey="title"
					validationMessage={validations.tags || ""}
					isClearable={false}
					multi={true}
					asyncCreatable={true}
					ellipsized={true}
					placeholder="Create tags by pressing Tab/Enter"
					// scrollDown={scrollDown}
				/>
			</div>
			<div className="form-row row-half">
				<SelectFilter
					title="Pre-order timings"
					options={timingGroups?.items || []}
					readOnly={readOnly}
					isLoading={timingGroups.isLoading}
					field="timingsGroup"
					currValue={data.timingsGroup}
					setFilter={handleForm}
					labelKey="title"
					valueKey="id"
					isAsync={true}
					handleSearch={fetchTimingGroupsDebounced}
					showCustomTooltip={true}
					tooltipInfo="Timings during which customers can pre-order item"
					tooltipPosition="up"
				/>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.itemWeight || ""}
					type="number"
					onChange={(e) => handleForm("itemWeight", e.target.value ? Number(e.target.value) : null)}
					validationMessage={validations.itemWeight || ""}
					readOnly={readOnly}
				>
					Weight (in gms)
				</InputWithLabel>
				<Switch
					title="Pre-order enabled"
					checked={data.preOrderEnabled}
					clickHandler={() => handleForm("preOrderEnabled", !data.preOrderEnabled)}
					validationMessage={validations.preOrderEnabled || ""}
					showCustomTooltip={true}
					tooltipInfo="If this item is available only during specific timings of the day, you can enable it for pre-ordering by customers before start of such timings"
					tooltipPosition="up"
					readOnly={readOnly}
				/>
			</div>
			<div className="form-row row-half">
				<InputWithLabel
					value={data.serves}
					type="number"
					onChange={(e) => handleForm("serves", e.target.value ? parseInt(e.target.value) : null)}
					validationMessage={validations.serves || ""}
					readOnly={readOnly}
				>
					Serves
				</InputWithLabel>
			</div>
			<div className="form-row row-full">
				<Textarea
					value={data.priceDescriptor}
					onChange={(e) => handleForm("priceDescriptor", e.target.value)}
					validationMessage={validations.priceDescriptor || ""}
					readOnly={readOnly}
				>
					Price description
				</Textarea>
			</div>
			<CascadeLocationsPrice
				isOpen={associationSidebar}
				close={closeAssociationSidebar}
				entityType={LOCATION_ENTITY_ASSOCIATION_TYPES[0]}
				entityId={data.id}
				salesPrice={data.itemPrice}
				currencySymbol={biz.currencySymbol}
			/>
			<Modal
				isOpen={cascadePriceModalOpen}
				close={handleModal}
				title="Cascade Price"
				subTitle=""
				showSubmitAction={true}
				submitTitle="Yes"
				submitAction={openAssociationSidebar}
				showCancelAction={true}
				cancelTitle="Not Now"
			>
				<div className="modal-gap-correction">
					The sales price has been updated from{" "}
					<strong>
						{printCurrency(biz.currencySymbol)}
						{data.currentItemPrice}
					</strong>
					&nbsp; to{" "}
					<strong>
						{printCurrency(biz.currencySymbol)}
						{data.itemPrice}
					</strong>
					, would you like to update the overridden prices at locations? (If any)
				</div>
			</Modal>
		</React.Fragment>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail,
	categories: store.configItems.itemCategories,
	timingGroups: store.configItems.timingGroups,
	fulfillmentModes: store.configItems.fulfillmentModes
});
export default connect(mapStateToProps)(BasicInfo);

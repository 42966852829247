import React, { useState, useEffect, useRef } from "react";

// third party
import { Transition, config, animated } from "react-spring/renderprops";

// component
import ContextMenu from "../_commons/ContextMenu";
import Image from "../_commons/Image";
import { getTranslatedField, trackEvent } from "../../atlas-utils";
import { TRACKING_EVENT_NAMES } from "../../client-config";

// utils
import { scroll } from "../../atlas-utils";

const ParentCategoriesList = ({
	parentSections = [],
	showHideCreateSection,
	showHideMenuReorderDrawer,
	selectedSection,
	setCurrClickedSection,
	isOnPreviewMode = false,
	handleEntityDeletionForm,
	handleDetailsEditSection,
	isMenuLocked,
	showCopyMenuSection = () => {},
	selectedLanguage = {}
}) => {
	const containerRef = useRef();
	const [contextMenuId, setContextMenuId] = useState(undefined);
	const [isSectionDropdownOpen, setIsSectionDropdownOpen] = useState(false);

	const handleOpenContextMenu = (e, id) => {
		e.stopPropagation();
		if (!contextMenuId || id !== contextMenuId) {
			setContextMenuId(id);
			return;
		}
		setContextMenuId(undefined);
	};

	const handleScrollToSelectedTab = () => {
		const container = containerRef.current;
		const currSelectedTab = container?.querySelector(".parent-category--selected");
		if (container && currSelectedTab) {
			scroll({ top: currSelectedTab.offsetTop - currSelectedTab.scrollHeight, left: 0 }, container);
		}
	};

	useEffect(() => {
		if (selectedSection) {
			setTimeout(() => {
				handleScrollToSelectedTab();
			}, 150);
		}
	}, [selectedSection]);

	const handleContextMenuOutsideClick = () => {
		setContextMenuId(undefined);
	};

	const renderMenuList = (id) => {
		return (
			<React.Fragment>
				<div
					className="action-item"
					onClick={(e) => {
						e.stopPropagation();
						handleDetailsEditSection(id, true);
						setContextMenuId(undefined);
					}}
				>
					Edit Section
				</div>

				<div
					className="action-item"
					onClick={(e) => {
						e.stopPropagation();
						showHideCreateSection(true, true, id);
						setContextMenuId(undefined);
						trackEvent(TRACKING_EVENT_NAMES.NEW_SUBSECTION_CREATION, {
							type: "3 dot"
						});
					}}
				>
					Add Subsection
				</div>
				<div
					className="action-item"
					onClick={(e) => {
						e.stopPropagation();
						handleEntityDeletionForm({ id, type: "section" });
						setContextMenuId(undefined);
					}}
					style={{ color: "#ff425c" }}
				>
					Delete Section
				</div>
			</React.Fragment>
		);
	};

	// useEffect(() => {
	// 	if(!selectedSection && !!parentSections.length) {
	// 		updatedSelectedSection(parentSections[0]?.id)
	// 	}
	// }, [parentSections])

	const handleSectionMenu = () => {
		setIsSectionDropdownOpen(!isSectionDropdownOpen);
	};

	const renderMenuItems = () => {
		return (
			<React.Fragment>
				<div className="action-item" onClick={() => showHideCreateSection(true)}>
					<div className="text">Create New Menu Section</div>
				</div>
				<div className="action-item" onClick={() => showCopyMenuSection()}>
					<div className="text">Copy from Another Menu</div>
				</div>
			</React.Fragment>
		);
	};
	return (
		<div className="parent-categories-list">
			<div className="parent-sections-list-container" ref={containerRef}>
				{parentSections.map((val, i) => (
					<React.Fragment key={i}>
						<div
							className={`parent-category ${
								val?.id === selectedSection
									? `parent-category--selected ${
											!!val?.subCategories?.length
												? "parent-category--selected-includes-subsection"
												: ""
										}`
									: ""
							}`}
							onClick={() => setCurrClickedSection(val?.id)}
						>
							<span className="parent-category--name">
								{getTranslatedField(val, selectedLanguage?.value, "name")}
							</span>
							{!isMenuLocked && (
								<ContextMenu
									isOpen={contextMenuId === val?.id}
									handleOutsideClick={
										val?.id === contextMenuId ? handleContextMenuOutsideClick : () => {}
									}
									renderMenuItems={renderMenuList}
									handleOpenMenu={(e) => handleOpenContextMenu(e, val?.id)}
									data={val?.id}
								/>
							)}
						</div>
						<Transition
							native
							from={{ height: 0, opacity: 0 }}
							enter={{ height: "auto", opacity: 1 }}
							leave={{ height: 0, opacity: 0, delay: 0 }}
							items={selectedSection === val?.id && !!val?.subCategories?.length}
							config={{ ...config.stiff, ...{ duration: 100 } }}
						>
							{(isOpen) =>
								isOpen &&
								((props) => (
									<animated.div className={"sub-sections-list"} style={props}>
										{(val?.subCategories ?? []).map((subCategory, i) => (
											<div className="sub-section" key={subCategory?.id}>
												{getTranslatedField(subCategory, selectedLanguage?.value, "name")}
												<span
													className={`line--vertical ${
														i === val?.subCategories.length - 1 ? "half" : ""
													}`}
												></span>
											</div>
										))}
									</animated.div>
								))
							}
						</Transition>
					</React.Fragment>
				))}
			</div>

			{!isMenuLocked && !isOnPreviewMode && !!parentSections?.length && (
				<React.Fragment>
					<div className="reorder-menu--cta">
						<div className="cta-container">
							<ContextMenu
								isOpen={isSectionDropdownOpen}
								renderMenuItems={renderMenuItems}
								handleOpenMenu={handleSectionMenu}
								handleOutsideClick={() => setIsSectionDropdownOpen(false)}
								clickEvent="mousedown"
							>
								<span className="hyperlink action-content">
									<span className="plus">+</span>
									<span>Add Menu Section</span>
								</span>
							</ContextMenu>
						</div>

						<div className="cta-container">
							<span className="hyperlink  action-content" onClick={() => showHideMenuReorderDrawer(true)}>
								<Image src="/assets/icons/icon-reorder.svg" alt="reorder" />
								<span>Reorder Menu</span>
							</span>
						</div>
					</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default ParentCategoriesList;

import gql from "graphql-tag";

export const GET_USERS_LIST = gql`
	query getUsersList($limit: Int, $offset: Int, $filters: [ListFilterArgument], $search: [SearchArgument]) {
		authServiceUsersList(limit: $limit, offset: $offset, filters: $filters, search: $search) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			searchKeywords {
				key
				valueForDisplay
			}
			objects {
				app
				authUserId
				isActive
				atlasData {
					id
					app
					fullName
					email
					phone
					emailVerified
					phoneVerified
					isdCode
					isActive
					lastLoginAt
					locations {
						id
						name
					}
					appBizName
					appUserId
					appBizId
					uleEmail
					ulePhone
					roles {
						id
						name
					}
				}
				primeData {
					id
					app
					fullName
					email
					phone
					emailVerified
					phoneVerified
					isdCode
					isActive
					lastLoginAt
					locations {
						id
						name
					}
					appBizName
					appUserId
					appBizId
					uleEmail
					ulePhone
					roles {
						id
						name
					}
				}
			}
		}
	}
`;

export const AUTH_USER_LOGOUT = gql`
	mutation authUserLogout($appUserIds: [Int], $tokenId: String) {
		authServiceLogout(input: { appUserIds: $appUserIds, tokenId: $tokenId }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_USER = gql`
	query getUser($id: Int!, $app: String!, $clientToken: String) {
		authServiceUserDetails(id: $id, app: $app, clientToken: $clientToken) {
			object {
				authUserId
				app
				isActive
				atlasData {
					app
					id
					isActive
					fullName
					email
					phone
					emailVerified
					phoneVerified
					isdCode
					allLocations
					locations {
						id
						name
						brandId
						brandName
						isAssociated
					}
					brands {
						id
						name
						isAssociated
					}
					roles {
						id
						name
						isAssociated
					}
					lastLoginAt
					appUserId
					appBizId
					appBizName
					appBizLogo
					ulePhone
					uleEmail
					thickClient
					pin
				}
				primeData {
					app
					id
					isActive
					fullName
					email
					phone
					emailVerified
					phoneVerified
					isdCode
					allLocations
					locations {
						id
						name
						isAssociated
					}
					roles {
						id
						name
						isAssociated
					}
					lastLoginAt
					appUserId
					appBizId
					appBizName
					appBizLogo
					ulePhone
					uleEmail
					thickClient
					pin
				}
				pendingInvites {
					id
					uuid
					bizId
					status
					createdAt
					invitedApps {
						appName
						appBizId
						appBizName
						appBizLogo
						locations {
							id
							name
							isAssociated
						}
						roles {
							id
							name
							isAssociated
						}
						allLocations
					}
				}
				uninvitedData {
					appName
					appBizId
					appBizName
					appBizLogo
					locations {
						id
						name
						isAssociated
					}
					roles {
						id
						name
						isAssociated
					}
					allLocations
				}
			}
		}
	}
`;

export const EDIT_USER = gql`
	mutation editUser(
		$clientToken: String!
		$authUserId: Int
		$app: String!
		$userLevelAction: Boolean!
		$isActive: Boolean
		$appsUserData: [AuthServiceAppUpdateUserInput]
		$inviteData: AuthServiceUserInviteInput
	) {
		authServiceUpdateInvitedUser(
			input: {
				clientToken: $clientToken
				authUserId: $authUserId
				app: $app
				userLevelAction: $userLevelAction
				isActive: $isActive
				appsUserData: $appsUserData
				inviteData: $inviteData
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			inviteUuid
			inviteStatus {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_INVITE_DETAILS = gql`
	query getInviteDetails($api: Boolean) {
		inviteClientDetails(api: $api) {
			bizIsdCodes {
				code
				name
				default
			}
			objects {
				appName
				appBizId
				appBizName
				appBizLogo
				roles {
					id
					name
				}
				locations {
					id
					name
					brandId
					brandName
				}
				brands {
					id
					name
				}
			}
		}
	}
`;

export const INVITE_USER = gql`
	mutation inviteUser(
		$clientToken: String!
		$bizId: Int!
		$invitedThrough: String!
		$value: String!
		$isdCode: String!
		$inviteData: AuthServiceInviteDataInput
	) {
		authServiceInvite(
			input: {
				clientToken: $clientToken
				bizId: $bizId
				invitedThrough: $invitedThrough
				value: $value
				isdCode: $isdCode
				inviteData: $inviteData
			}
		) {
			uuid
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

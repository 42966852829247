import React, { useEffect, useState } from "react";
import { SelectFilter } from "../_commons/SelectFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import { CheckBox } from "../_commons/CheckBox";
import { InfiniteLoader } from "../SiteComp";
import Placeholder from "../_commons/Placeholder";
import { FormSidebar } from "../_commons/FormSidebar";
import { requestMenuPull } from "../../actions/menuPull";
import { GET_STORES_LIST } from "../../graphql/misc";
import { client } from "../../client";
import { debounce } from "lodash";
import { RadioButton } from "../_commons/RadioButton";
import { SearchFilter } from "../_commons/SearchFilter";
import { GET_MENU_LIST_POS } from "../../graphql/menus";
import { isFeatureFlagEnabled } from "../../atlas-utils";

const MenuListView = ({
	isMultibrandEnabled,
	selectedLocation,
	setMenuListLoading,
	menuListLoading,
	isLoading,
	setStep,
	menuListData,
	setMenuListData,
	selectedMenu,
	setSelectedMenu
}) => {
	const [error, setError] = useState(false);

	const fetchMenusList = async () => {
		setError(false);
		setMenuListLoading(true);
		try {
			const resp = await client.query({
				fetchPolicy: "no-cache",
				query: GET_MENU_LIST_POS,
				variables: {
					bizLocationId: isMultibrandEnabled
						? selectedLocation?.brandLocation?.id
						: selectedLocation?.id || null
				}
			});
			if (resp?.data?.fetchMenuList?.status === "success") {
				const parsedMenus = JSON.parse(resp?.data?.fetchMenuList?.details);

				setMenuListData(parsedMenus);

				setMenuListLoading(false);
			} else if (resp?.data?.fetchMenuList?.status === "error") {
				setMenuListLoading(false);
				setError(true);
			}
		} catch (e) {
			setError(true);
			console.log(e);
		}
	};
	useEffect(() => {
		fetchMenusList();
	}, []);

	const handleMenuSelection = (menu) => {
		setSelectedMenu(menu);
	};
	return (
		<>
			{menuListLoading ? (
				<>
					<InfiniteLoader />
					<Placeholder
						placeholderImageUrl={"/assets/empty-states/verification-in-progress-state-2.svg"}
						placeholderText={"Pulling your menu"}
						placeholderSubtext={`Your menu is being pulled in and will reflect here shortly.`}
					/>
				</>
			) : (
				<>
					{error ? (
						<Placeholder
							placeholderImageUrl={"/assets/empty_states/error-icon.svg"}
							placeholderText={"There was an error."}
							placeholderSubtext={`An error occurred while fetching your menu. Please try again after some time. If the issue persists, please reach out to our support.`}
							placeholderButtonContent={"Try Again"}
							placeholderButtonClickAction={() => {
								setSelectedMenu(null);
								fetchMenusList();
							}}
							secondaryButtonContent={"Back to Location selection"}
							secondaryButtonClickAction={() => {
								setSelectedMenu(null);
								setStep(1);
							}}
						/>
					) : (
						<>
							{menuListData && menuListData?.length === 0 ? (
								<Placeholder
									placeholderImageUrl={"/assets/empty_states/no-menu-available.svg"}
									placeholderText={"No menu available"}
									placeholderSubtext={`No menus are available at this location. Try selecting a different location to search for menus.`}
									placeholderButtonContent={"Back to Location selection"}
									placeholderButtonClickAction={() => {
										setSelectedMenu(null);
										setStep(1);
									}}
								/>
							) : (
								<div className="menu-pull-menu-list-view-wrapper">
									<div className="form-row">
										<p className="menu-import-header">Select a menu to import</p>
										{/* <SearchFilter
											placeholder="Search"
											filterOption={{
												field: "name"
											}}
											setFilter={(f, v) => {}}
											value={""}
										/> */}
									</div>
									{menuListData &&
										menuListData?.length > 0 &&
										menuListData?.map((menu) => (
											<RadioButton
												clickHandler={() => {
													handleMenuSelection(menu);
												}}
												checked={menu?.id === selectedMenu?.id}
											>
												<div className="menu-info">
													<div className="menu-info--content">
														<div className="img-container">
															<img
																className={"menu-cover-img"}
																src={"/assets/icons/icon-menu-placeholder.svg"}
															/>
														</div>
														<div className="info">
															<div
																className="menu-title"
																title={"Menu Name"}
																onClick={() => {}}
															>
																<span
																	className={
																		isLoading
																			? ""
																			: "hyperlink hyperlink--black-color"
																	}
																	onClick={() => {}}
																>
																	{menu.name}
																</span>
															</div>
															<div className="brand-name">ID: {menu?.id}</div>
														</div>
													</div>
												</div>
											</RadioButton>
										))}
								</div>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

const ImportMenuDrawer = ({
	isOpen,
	handleClose = () => {},
	isMultibrandEnabled,
	brands,
	handleBrandsLabelOption,
	ongoingMenuPull,
	pos,
	canPullLocationMenu
}) => {
	const brandItemsWithoutAll = brands?.items?.filter?.((e) => e.id !== "all");
	const [selectedBrand, setSelectedBrand] = useState(
		brands?.selectedBrand?.id === "all" ? brandItemsWithoutAll?.[0] : brands?.selectedBrand
	);
	const [locationsList, setLocationsList] = useState({
		isLoading: false,
		objects: []
	});
	const [isImporting, setIsImporting] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState();
	const [selectedLocations, setSelectedLocations] = useState([]);
	const [importAllLocations, setImportAllLocation] = useState(false);
	const [clearCatalogue, setClearCatalogue] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [menuListLoading, setMenuListLoading] = useState(false);
	const [menuListData, setMenuListData] = useState([]);
	const [selectedMenu, setSelectedMenu] = useState(null);
	const [step, setStep] = useState(1);

	const isLightSpeedMenuImportEnabled = isFeatureFlagEnabled("lightspeed_menu_import");

	const menuPullStates = {
		1: () => <FilterView />,
		2: () => (
			<MenuListView
				isMultibrandEnabled={isMultibrandEnabled}
				setMenuListLoading={setMenuListLoading}
				menuListLoading={menuListLoading}
				selectedLocation={selectedLocation}
				isLoading={isLoading}
				setStep={setStep}
				setMenuListData={setMenuListData}
				selectedMenu={selectedMenu}
				setSelectedMenu={setSelectedMenu}
				menuListData={menuListData}
			/>
		),
		3: () => <PosMenuImportInProgress status={ongoingMenuPull?.status} />
	};

	const handleBrandSelection = (field, value) => {
		setSelectedBrand(value);
	};

	const handleLocation = (field, value) => {
		if (isLightSpeedMenuImportEnabled) {
			setSelectedLocation(value);
		} else {
			const uniqueLocations = Array.isArray(value) ? [...new Set(value)] : [value];
			setSelectedLocations(uniqueLocations);
		}
	};
	const handleImportAllLocations = () => {
		setImportAllLocation(!importAllLocations);
	};
	const handleClearCatalogue = () => {
		setClearCatalogue(!clearCatalogue);
	};
	const handleSubmit = async () => {
		try {
			setIsLoading(true);
			await requestMenuPull({
				locationIds: isLightSpeedMenuImportEnabled
					? selectedLocation?.id
					: selectedLocations.map((location) => location.id),
				brandId: isMultibrandEnabled ? selectedBrand?.id : null,
				pos: pos?.id,
				clearCatalogue,
				allLocations: importAllLocations,
				menuId: selectedMenu?.id
			});

			setIsImporting(true);
		} catch (e) {
			console.log(e);
		}
		setIsLoading(false);
	};

	const handleStepChange = () => {
		if (step === 2) {
			handleSubmit();
		}
		setStep((prevStep) => prevStep + 1);
	};

	const fetchLocations = async (searchText, brandId = null) => {
		if (searchText !== undefined) {
			setLocationsList({
				...locationsList,
				isLoading: true
			});
			try {
				const variables = {
					limit: 50,
					sort: {
						field: "name",
						order: "ASC"
					}
				};
				if (brandId) {
					variables.brand = String(brandId);
				} else {
					variables.brand = null;
				}
				let filters = [
					{
						field: "is_active",
						value: "true"
					}
				];
				if (searchText) {
					filters.push({ field: "name", value: searchText });
				}
				variables.filters = filters;
				const resp = await client.query({
					query: GET_STORES_LIST,
					variables
				});
				setLocationsList({
					isLoading: false,
					objects: resp.data.stores.objects
				});
				return resp.data.stores.objects;
			} catch (error) {
				console.log(error);
				setLocationsList({
					...locationsList,
					isLoading: false
				});
			}
		}
	};

	const fetchLocationsDebounced = debounce((searchText, brandId) => fetchLocations(searchText, brandId), 300);

	useEffect(() => {
		fetchLocations("", isMultibrandEnabled ? selectedBrand?.id || null : null);
	}, [selectedBrand]);

	const FilterView = () => {
		return (
			<div className="import-menu-pos-wrapper">
				{isLightSpeedMenuImportEnabled ? (
					<>
						<div className="pos-menu-content">
							We see that <b>{pos?.name}</b> is connected to your business.
						</div>
						<p className="pos-header">Select Brand & location to import the menu from.</p>
					</>
				) : (
					<div className="pos-menu-content">
						We see that <b>{pos?.name}</b> is connected to your business. Before proceeding, please choose{" "}
						{isMultibrandEnabled ? "brand and" : "the"} locations for which you want to import the menu.
					</div>
				)}

				<div className="menu-import-filters">
					{isMultibrandEnabled && (
						<>
							<SelectFilterCustom
								options={brandItemsWithoutAll}
								isLoading={brands.isLoading}
								field="brands"
								currValue={selectedBrand}
								setFilter={handleBrandSelection}
								labelKey={"name"}
								valueKey={"id"}
								isSearchable={false}
								customLabel={true}
								customOptions={true}
								renderLabel={handleBrandsLabelOption}
								renderOptions={handleBrandsLabelOption}
								placeholder="Select brand"
								title={"Brands"}
							/>
						</>
					)}
					{canPullLocationMenu && (
						<SelectFilter
							options={locationsList.objects || []}
							currValue={isLightSpeedMenuImportEnabled ? selectedLocation : selectedLocations}
							multi={isLightSpeedMenuImportEnabled ? false : true}
							setFilter={handleLocation}
							field={"location"}
							isClearable={true}
							title={"Locations"}
							isLoading={locationsList?.isLoading}
							placeholder="Select Locations"
							isSearchable={true}
							handleSearch={(search) => fetchLocationsDebounced(search, selectedBrand?.id || null)}
							labelKey="name"
							valueKey="id"
							classes={importAllLocations ? "disabled" : ""}
						/>
					)}
				</div>
				<div className="checkbox-wrapper">
					{selectedLocations?.length > 0 && !isLightSpeedMenuImportEnabled && (
						<CheckBox checked={importAllLocations} clickHandler={handleImportAllLocations}>
							<span>Import for all locations</span>
						</CheckBox>
					)}
					<CheckBox checked={clearCatalogue} clickHandler={handleClearCatalogue}>
						<span>Clear existing catalogue</span>
					</CheckBox>
				</div>
				{clearCatalogue && (
					<div className="catalogue-alert">
						<img src={"/assets/icons/error-red-icon.svg"} />
						<p>
							This will archive your existing catalogue and replace it with the one imported from your POS
						</p>
					</div>
				)}
			</div>
		);
	};

	const getMenuImportIcon = (status) => {
		switch (status) {
			case "success":
				return "/assets/icons/success-green-icon.svg";
			case "failure":
				return "/assets/icons/error-red-icon.svg";
			default:
				return "/assets/icons/info.png";
		}
	};

	const getMenuImportText = (status) => {
		switch (status) {
			case "success":
				return "Menu Import Completed, You can close the drawer.";
			case "failure":
				return "Menu Import Failed, Please try again later.";
			default:
				return "This may take a minute. You can close this drawer and return later.";
		}
	};

	const PosMenuImportInProgress = ({ status }) => {
		return (
			<div className="pos-menu-import-inprogress-wrapper">
				{status == "inprogress" ? <InfiniteLoader /> : <></>}
				<div className="pos-menu-content">
					<div>
						<Placeholder
							placeholderImageUrl={"/assets/empty-states/verification-in-progress-state-2.svg"}
							placeholderText={"Importing Menu from POS"}
							placeholderSubtext={`We are importing your menu present on your POS Systems
						and syncing with Atlas`}
						/>
					</div>
					<div className="import-notice">
						<img src={getMenuImportIcon(status)} />
						<p>{getMenuImportText(status)}</p>
					</div>
				</div>
			</div>
		);
	};
	const getDisabledState = () => {
		if (!isLightSpeedMenuImportEnabled) {
			if ((isMultibrandEnabled && selectedBrand?.id) || !isMultibrandEnabled) {
				if ((importAllLocations || selectedLocations.length > 0) && canPullLocationMenu) {
					return false;
				}
			}
			if (!isMultibrandEnabled && !canPullLocationMenu) {
				return false;
			}
			return true;
		} else {
			if (step === 2 && selectedMenu !== null) {
				return false;
			}
			if (step !== 2) {
				if ((isMultibrandEnabled && selectedBrand?.id) || !isMultibrandEnabled) {
					if (canPullLocationMenu && selectedLocation?.id) {
						return false;
					}
				}
				if (!isMultibrandEnabled && !canPullLocationMenu) {
					return false;
				}
			}

			return true;
		}
	};

	useEffect(() => {
		getDisabledState();
	}, [selectedBrand, selectedLocation, importAllLocations, selectedLocations, menuListData, selectedMenu]);

	return (
		<>
			{isLightSpeedMenuImportEnabled ? (
				<FormSidebar
					close={handleClose}
					isOpen={isOpen}
					overlaySidebarClasses={step === 2 ? "large" : "medium"}
					title={step === 2 ? `Menu at ${selectedLocation?.name}` : "Import Menu from POS"}
					subTitle={step === 2 ? "" : "Menu will be imported from your connected POS system"}
					submitTitle={step === 1 ? "View Menu" : "Import"}
					submit={handleStepChange}
					hideSubmitAction={isImporting || menuListLoading}
					loading={isLoading}
					cancelTitle={isImporting ? "Close" : "Cancel"}
					disabled={getDisabledState()}
					formSidebarClasses="import-menu-drawer"
					hideActions={step === 2 && (menuListLoading || menuListData?.length === 0)}
				>
					{menuPullStates[step]()}
				</FormSidebar>
			) : (
				<FormSidebar
					close={handleClose}
					isOpen={isOpen}
					overlaySidebarClasses="medium"
					title="Import Menu from POS"
					subTitle="Menu will be imported from your connected POS system"
					submitTitle="Import"
					submit={handleSubmit}
					hideSubmitAction={isImporting}
					loading={isLoading}
					cancelTitle={isImporting ? "Close" : "Cancel"}
					disabled={getDisabledState()}
				>
					{isImporting ? <PosMenuImportInProgress status={ongoingMenuPull?.status} /> : <FilterView />}
				</FormSidebar>
			)}
		</>
	);
};

export default ImportMenuDrawer;

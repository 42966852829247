import React, { useState, useEffect, useCallback, useReducer, useRef, useImperativeHandle } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BasicInfo from "../components/ModifierEdit/BasicInfo";
import Images from "../components/ModifierEdit/Images";
import AssociatedLocations from "../components/ModifierEdit/AssociatedLocations";
import ModifierGroups from "../components/ModifierEdit/ModifierGroups";
import NestedModifierGroups from "../components/ModifierEdit/NestedModifierGroups";
import CustomFields from "../components/ModifierEdit/CustomFields";

// client
import { client } from "../client";
import { store } from "../store/configureStore";

// utils
import history from "../history";
import {
	removeProp,
	parseSuperstructErrorMessages,
	adjustNestedContainer,
	findChangedKeysInTwoObjects,
	trackEvent,
	createTranslationsObject
} from "../atlas-utils";

// third party
import { connect } from "react-redux";
import { validate } from "superstruct";
import { useLocation } from "react-router-dom";
import SatismeterService from "../services/SatismeterService";

// actions
import { fetchModifiersList, fetchModifierDetail, editModifier } from "../actions/modifiers";
import { fetchCiModifierGroups, fetchTranslation, updateTranslation } from "../actions/actions";
import { ActionTypes } from "../actions/_types";

// reducers
import { modifierDetailsReducer, MODIFIER_DETAILS_INITIAL_STATE } from "../reducers/modifiers";

// graphql
import { GET_ITEM_TAGS } from "../graphql/items";
import { GET_BIZ_PLATFORMS } from "../graphql/locations";

// assets
import HelpIcon from "../components/_commons/HelpIcon";

// constants
import {
	FOOD_TYPE_MAP,
	NESTED_ENTITY_TYPES,
	TRACKING_EVENT_NAMES,
	TRACKING_MISC,
	TRACKING_SOURCE,
	TRACKING_STATUS
} from "../client-config";
import { MODIFIER_FORM_SCHEMA } from "./ModifierCreate";
import { clientMenu } from "../client-menu";
import { UPDATE_MENU } from "../graphql/menus";
import NotificationServices from "../services/NotificationService";
import EntityRulesAssociationList from "../components/EntityAssociations/EntityRulesAssociationList";
import { DRAGGABLE_ENTITY_TYPES } from "../components/_commons/DraggableModifierAccordion";
const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Images",
		value: "images"
	},
	{
		label: "Locations",
		value: "locations"
	},
	{
		label: "Parent Modifier Groups",
		value: "parentModifierGroups"
	},
	{
		label: "Nested Modifier Groups",
		value: "nestedModifierGroups"
	},
	{
		label: "Custom Fields",
		value: "customFields"
	}
];

//tabs to be shown when the user tries add/edit through menus -> select a single item detail view -> modifiers
const FORM_TABS_MENU_SERVICE = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Images",
		value: "images"
	},
	{
		label: "Rules",
		value: "rules"
	},
	{
		label: "Nested Modifier Groups",
		value: "nestedModifierGroups"
	},
	{
		label: "Custom Fields",
		value: "customFields"
	}
];

// tabs to be shown when the user tries to view list of modifier -> detail view when menu management enabled
const FORM_TABS_MENU = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Images",
		value: "images"
	},
	{
		label: "Custom Fields",
		value: "customFields"
	}
];

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const TRANSLATION_FIELDS_MAP = {
	optionTitle: "option_title",
	optionDesc: "option_desc"
};

const ModifierEdit = ({
	match,
	access,
	readOnly = false,
	currencySymbol,
	supportedLanguages = [],
	isEnforcedOla = false,
	isNested = false,
	isForeignSource = false,
	closeNestedContainer,
	connectedRef,
	menuDetailsData,
	biz,
	handleEntityDissociation = () => {},
	handleModifierSortOrderUpdate = () => {},
	tab = null,
	isOpen = false,
	isFromMenuSection,
	isMenuFlowIgnored = false
}) => {
	const [formTab, setFormTab] = useState(tab || FORM_TABS[0].value);
	const [isBasicFormTouched, setBasicFormTouched] = useState(false);
	const [isLocationsFormTouched, setLocationsFormTouched] = useState(false);
	const [isImageFormTouched, setImageFormTouched] = useState(false);
	const [isFormOpen, setFormState] = useState(false);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [currSelectedLang, setCurrSelectedLang] = useState({
		optionTitle: {
			lang: supportedLanguages.length ? supportedLanguages[0].value : "",
			value: "",
			showActionBtn: false
		},
		optionDesc: {
			lang: supportedLanguages.length ? supportedLanguages[0].value : "",
			value: "",
			showActionBtn: false
		}
	});
	const [optionsTranslations, setOptionsTranslations] = useState({});

	const [modifierDetails, dispatch] = useReducer(modifierDetailsReducer, MODIFIER_DETAILS_INITIAL_STATE);
	const { loading, data, error, backupData } = modifierDetails;
	const [isModalBusy, setModalBusy] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [locationsToCascadePrice, setLocationsToCascadePrice] = useState({});
	const [cascadePriceModalOpen, setCascadePriceModalOpen] = useState(false);
	const [menuSectionInfo, setMenuSectionInfo] = useState({});
	const [isUpdatingMenuOverrides, setUpdatingMenuOverrides] = useState(false);
	const [bizPlatformsList, setBizPlatformsList] = useState([]);
	const nestedRef = useRef();
	const associatedLocationsRef = useRef();

	const { pathname } = useLocation();
	const isMenuOverCatalogueEnabled = biz?.isMenuOverCatalogueEnabled || false;
	const isViewedFromMenuSection = pathname.includes("/menus/edit/");
	const pathnameEntities = pathname.split("/");
	const menuId = isViewedFromMenuSection ? pathnameEntities[pathnameEntities.length - 1] : null;
	const [reOrderedMenuNestedOptionGroups, setReOrderedMenuNestedOptionGroups] = useState([]);
	const [isMenuNestedModifiersFormTouched, setIsMenuNestedModifiersFormTouched] = useState(false);

	const handleNestedEntity = useCallback((toOpen = false, type, id, tab = null) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id,
				tab
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchCiModifierGroups("");
		fetchBizPlatformsList();
	}, []);

	useEffect(() => {
		if (match.params.id) {
			fetchModifierDetail(parseInt(match.params.id), dispatch, "", !isMenuFlowIgnored ? menuId : null);
		}
	}, [match.params.id]);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			handleModal();
			setFormState(false);
			setTimeout(() => {
				if (isNested || isForeignSource) {
					closeNestedContainer();
				} else {
					fetchModifiersList();
					history.push("/modifiers");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const fetchBizPlatformsList = async () => {
		try {
			const variables = {
				limit: 10,
				offset: 0,
				includeUrbanpiper: true
			};
			const resp = await client.query({
				query: GET_BIZ_PLATFORMS,
				variables,
				fetchPolicy: "no-cache"
			});
			setBizPlatformsList(resp.data.bizPlatforms.objects);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	const fetchItemTags = async (searchText = "") => {
		try {
			const variables = {
				limit: 200,
				offset: 0,
				sort: { field: "title", order: "ASC" }
			};
			if (searchText !== "") {
				variables.search = [{ key: "default", value: searchText }];
			}
			const resp = await client.query({
				query: GET_ITEM_TAGS,
				variables
			});
			// get unique tag groups and its tags
			let tagGroups = {};
			resp.data.itemTags.objects.forEach((tag) => {
				tag = {
					...tag,
					titleForDisplay: tag?.tagGroup ? `${tag.title} (${tag?.tagGroup?.title})` : `${tag.title} (General)`
				};
				if (tagGroups[tag.tagGroup ? tag.tagGroup.title : "General"]) {
					tagGroups[tag.tagGroup ? tag.tagGroup.title : "General"].push(tag);
				} else {
					tagGroups[tag.tagGroup ? tag.tagGroup.title : "General"] = [tag];
				}
			});
			// combine all tag groups' tags using concat
			return [].concat.apply([], Object.values(tagGroups));
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
			return { options: [] };
		}
	};

	const setError = (error) => {
		dispatch({
			type: ActionTypes.EDIT_MODIFIER_DETAIL_FAILURE,
			error
		});
	};

	const validateData = (data) => {
		const [error] = validate(data, MODIFIER_FORM_SCHEMA);
		if (error) {
			setError(parseSuperstructErrorMessages(error));
			return false;
		}
		setError({});
		return true;
	};

	const handleUpdateOptionDetailsInMenuService = async () => {
		if (!menuDetailsData) {
			return;
		}
		setUpdatingMenuOverrides(true);
		try {
			const menuUpdationVariables = {
				id: menuId,
				menuData: {
					image: null,
					imageUrl: menuDetailsData?.imageUrl,
					name: menuDetailsData.name,
					description: menuDetailsData.description,
					brand: menuDetailsData.brand,
					translations: menuDetailsData.translations,
					options: menuDetailsData.options.map((option) =>
						option?.id === String(match.params.id)
							? {
									...option,
									overrides: {
										...option.overrides,
										name: menuSectionInfo?.name || "",
										description: menuSectionInfo?.description || "",
										price: parseFloat(menuSectionInfo?.price) ?? null,
										image: menuSectionInfo?.image || "",
										imageUrl: menuSectionInfo?.imageUrl || "",
										translations: createTranslationsObject(optionsTranslations)
									}
								}
							: {
									...option,
									overrides: {
										...option.overrides,
										image: ""
									}
								}
					),
					optionGroups: menuDetailsData.optionGroups,
					items: menuDetailsData.items.map((item) => ({
						...item,
						overrides: { ...item.overrides, image: "" }
					})),
					categories: menuDetailsData.categories.map((category) => ({
						...category,
						overrides: {
							...category.overrides,
							image: ""
						}
					}))
				}
			};

			const itemOptionGroupAddResp = await clientMenu.mutate({
				mutation: UPDATE_MENU,
				variables: menuUpdationVariables
			});

			if (itemOptionGroupAddResp?.data?.updateMenuV2) {
				NotificationServices.pushNotification({
					message: "Updated modifier details in menu successfully!",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: itemOptionGroupAddResp?.data?.updateMenuV2
				});
				setUpdatingMenuOverrides(false);
				setBasicFormTouched(false);
				trackEvent(TRACKING_EVENT_NAMES.MENU_MODIFIER_DETAILS_BASIC_INFO_UPDATE, {
					status: "success"
				});
				return true;
			} else {
				NotificationServices.pushNotification({
					message: "Failed to update modifier details",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
				setUpdatingMenuOverrides(false);
				trackEvent(TRACKING_EVENT_NAMES.MENU_MODIFIER_DETAILS_BASIC_INFO_UPDATE, {
					status: "failure"
				});
			}
		} catch (e) {
			setUpdatingMenuOverrides(false);
			NotificationServices.pushNotification({
				message: "Failed to update modifier details",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			console.log(e);
			trackEvent(TRACKING_EVENT_NAMES.MENU_MODIFIER_DETAILS_BASIC_INFO_UPDATE, {
				status: "failure"
			});
		}
	};

	const handleForm = (field, value, id) => {
		if (isViewedFromMenuSection) {
			if (field === "name") {
				setOptionsTranslations((prev) => ({
					...prev,
					[currSelectedLang?.optionTitle?.lang]: {
						...prev[currSelectedLang?.optionTitle.lang],
						[field]: value
					}
				}));
				setBasicFormTouched(true);
				if (currSelectedLang?.optionTitle?.lang !== "en") {
					return;
				}
			}
			if (field === "description") {
				setOptionsTranslations((prev) => ({
					...prev,
					[currSelectedLang?.optionDesc?.lang]: {
						...prev[currSelectedLang?.optionDesc?.lang],
						[field]: value
					}
				}));
				setBasicFormTouched(true);
				if (currSelectedLang?.optionDesc?.lang !== "en") {
					return;
				}
			}
			setMenuSectionInfo((current) => ({
				...current,
				[field]: value
			}));
			if (field === "image" || field === "imageUrl") {
				setImageFormTouched(true);
			} else {
				setBasicFormTouched(true);
			}
			return;
		}
		if (formTab === FORM_TABS[0].value) {
			dispatch({
				type: ActionTypes.UPDATE_MODIFIER_DETAIL,
				payload: {
					[field]: value
				}
			});
			const newData = {
				...data,
				[field]: value
			};
			validateData(newData);
			if (!isBasicFormTouched) {
				setBasicFormTouched(true);
			}
		}
	};

	const handleSubmit = async () => {
		if (isViewedFromMenuSection) {
			if (FORM_TABS_MENU_SERVICE[3].value === formTab) {
				setUpdatingMenuOverrides(true);
				await handleModifierSortOrderUpdate(
					reOrderedMenuNestedOptionGroups,
					match.params.id,
					DRAGGABLE_ENTITY_TYPES.NESTED_MODIFIER_GROUP
				);
				setUpdatingMenuOverrides(false);
				return;
			}
			handleUpdateOptionDetailsInMenuService();
			return;
		}
		if (formTab === FORM_TABS[0].value) {
			const keysToIgnore = ["currentOptionPrice"];
			const fieldsChanged = findChangedKeysInTwoObjects(data, backupData, keysToIgnore);
			const isPriceChanged = fieldsChanged.includes("itemPrice");
			const priceChange = isPriceChanged
				? backupData.optionPrice > data.optionPrice
					? TRACKING_MISC.DECREASE
					: TRACKING_MISC.INCREASE
				: TRACKING_MISC.SAME;
			const eventMeta = {
				fields_updated: fieldsChanged,
				num_of_fields_updated: fieldsChanged.length,
				is_price_changed: isPriceChanged,
				price_change: priceChange
			};

			const sanitisedData = removeProp(data, "__typename");
			if (!validateData(sanitisedData)) {
				return;
			}
			if (sanitisedData.merchantRefId === "") {
				sanitisedData.merchantRefId = "-1";
			}
			if (!sanitisedData.optionWeight) {
				sanitisedData.optionWeight = null;
			}
			sanitisedData.locationsToUpdate = [];

			const resp = await editModifier(sanitisedData, dispatch);
			if (resp) {
				eventMeta.status = TRACKING_STATUS.SUCCESS;
				trackEvent(TRACKING_EVENT_NAMES.MODIFIER_BASIC_INFO_UPDATE, eventMeta);

				if (data.optionPrice !== data.currentOptionPrice && !isMenuOverCatalogueEnabled) {
					setCascadePriceModalOpen(true);
					// satismeter event
					SatismeterService.menuPublish();
				}
				setBasicFormTouched(false);
			} else {
				eventMeta.status = TRACKING_STATUS.FAILURE;
				trackEvent(TRACKING_EVENT_NAMES.MODIFIER_BASIC_INFO_UPDATE, eventMeta);
			}
		} else if (formTab === FORM_TABS[2].value) {
			if (associatedLocationsRef?.current) {
				associatedLocationsRef.current.handleSubmit();
			}
		}
	};

	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				dispatch({
					type: ActionTypes.UPDATE_MODIFIER_DETAIL,
					payload: {
						isEnabled: !data.isEnabled
					}
				});
			}
			setArchiveRestore(false);
		},
		[data]
	);

	const handleLanguage = async (lang, field) => {
		if (!isViewedFromMenuSection) {
			const resp = await fetchTranslation(
				parseInt(match.params.id),
				"OPTION",
				TRANSLATION_FIELDS_MAP[field],
				lang.value
			);
			if (resp) {
				setCurrSelectedLang({
					...currSelectedLang,
					[field]: {
						lang: lang.value,
						value: resp.value,
						showActionBtn: false
					}
				});
			}
		} else {
			setCurrSelectedLang({
				...currSelectedLang,
				[field]: {
					lang: lang.value,
					value: "",
					showActionBtn: false
				}
			});
		}
	};

	const handleTranslation = (field, value) => {
		if (currSelectedLang[field].lang === "en") {
			dispatch({
				type: ActionTypes.UPDATE_MODIFIER_DETAIL,
				payload: {
					[field]: value
				}
			});
			if (!isBasicFormTouched) {
				setBasicFormTouched(true);
			}
		} else {
			setCurrSelectedLang({
				...currSelectedLang,
				[field]: {
					...currSelectedLang[field],
					value: value,
					showActionBtn: true
				}
			});
		}
	};

	const saveTranslation = async (field, value) => {
		const resp = await updateTranslation(
			parseInt(match.params.id),
			"OPTION",
			TRANSLATION_FIELDS_MAP[field],
			currSelectedLang[field].lang,
			value
		);
		if (resp && !resp.status) {
			dispatch({
				type: ActionTypes.EDIT_MODIFIER_DETAIL_FAILURE,
				error: resp.error
			});
		} else if (resp && resp.status) {
			setTimeout(() => {
				setCurrSelectedLang({
					...currSelectedLang,
					[field]: {
						...currSelectedLang[field],
						showActionBtn: false
					}
				});
				store.dispatch({
					type: "RESET_TRANSLATION"
				});
			}, 3000);
		}
	};

	const switchTab = (tab) => {
		setFormTab(tab.value);
		setLocationsFormTouched(false);
	};

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "modifiers"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	const handleModal = () => {
		setCascadePriceModalOpen(false);
		// Sets the currentOptionPrice variable to the actual optionPrice
		dispatch({
			type: ActionTypes.UPDATE_MODIFIER_DETAIL,
			payload: {
				currentOptionPrice: data.optionPrice
			}
		});
	};

	const renderTitle = () => {
		if (data.optionTitle || data.name) {
			return (
				<div className="modifier-custom-title">
					{data.foodType && (
						<div className={`food-type ${FOOD_TYPE_MAP[data.foodType.value || data.foodType]}`} />
					)}
					<div className="modifier-title">{data.optionTitle || data.name}</div>
				</div>
			);
		}
		return "Modifier";
	};

	const handleNestedModifierGroups = (option) => {
		return (
			<div
				className="custom-select-option"
				title={option.optionGroupTitle}
				onClick={(e) => {
					e.stopPropagation();
					handleNestedEntity(true, NESTED_ENTITY_TYPES[5], option.id);
				}}
				onMouseDown={(e) => e.stopPropagation()}
			>
				{option.optionGroupTitle}
			</div>
		);
	};

	const getTabs = () => {
		if (isMenuOverCatalogueEnabled && isViewedFromMenuSection) {
			return FORM_TABS_MENU_SERVICE;
		} else if (isMenuOverCatalogueEnabled && !isViewedFromMenuSection) {
			return FORM_TABS_MENU;
		} else {
			return FORM_TABS;
		}
	};

	useEffect(() => {
		setOptionsTranslations(
			data?.translations?.reduce((acc, curr) => {
				acc[curr.language] = { language: curr.language, name: curr.name, description: curr.description };
				return acc;
			}, {})
		);
		setMenuSectionInfo({
			name: data?.name,
			description: data?.description,
			price: data?.price,
			isRecommended: data?.isRecommended,
			imageUrl: data?.imageUrl,
			translations: data?.translations
				? Object.entries(data.translations).map(([key, translation]) => {
						return {
							language: translation.language,
							name: translation.name,
							description: translation.description
						};
					})
				: []
		});
	}, [data]);

	return (
		<div className="modifier-edit-container">
			<FormSidebar
				isOpen={isFromMenuSection ? isOpen : isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				disabled={
					formTab === "basic"
						? !isBasicFormTouched
						: formTab === "nestedModifierGroups"
							? !isMenuNestedModifiersFormTouched
							: formTab === "locations"
								? !isLocationsFormTouched
								: false
				}
				title={renderTitle()}
				subTitle="Edit this modifier"
				submitTitle="Save"
				loading={
					["basic", "images", "nestedModifierGroups"].includes(formTab) &&
					(loading || isUpdatingMenuOverrides)
				}
				isNested={isNested}
				showMoreBtn={formTab === FORM_TABS[0].value}
				numRows={3}
				hideActions={
					(formTab === FORM_TABS[0].value && !isBasicFormTouched) ||
					(isViewedFromMenuSection
						? formTab === FORM_TABS[1].value && !isImageFormTouched
						: formTab === FORM_TABS[1].value) ||
					(formTab === FORM_TABS[2].value && !isLocationsFormTouched) ||
					formTab === FORM_TABS[3].value ||
					(formTab === FORM_TABS[4].value && !isMenuNestedModifiersFormTouched) ||
					formTab === FORM_TABS[5].value ||
					formTab === FORM_TABS_MENU_SERVICE[2].value
				}
				headerRight={
					<React.Fragment>
						{access.isCatalogueManagement && !readOnly && (
							<Button
								classes={data.isEnabled ? "at-btn--danger" : "at-btn--success"}
								clickHandler={() => setArchiveRestore(true)}
							>
								{data.isEnabled ? "Archive" : "Restore"}
							</Button>
						)}
						<div className="help-btn-container">
							<Button clickHandler={handlePiperAcademy} type="secondary">
								<HelpIcon />
								<span>Help</span>
							</Button>
						</div>
					</React.Fragment>
				}
			>
				<Topbar
					tabs={getTabs()}
					selectedTab={formTab}
					switchTab={switchTab}
					isStickyOnTop={true}
					isScrollable={getTabs() !== FORM_TABS_MENU}
					//hiddenTabs={bizPlatformsList?.find((plf) => plf.platformName === "Zomato") ? [] : ["images"]}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data}
							handleForm={handleForm}
							validations={error.fields || {}}
							currencySymbol={currencySymbol}
							showLanguages={true}
							currSelectedLang={currSelectedLang}
							handleLanguage={handleLanguage}
							handleTranslation={handleTranslation}
							saveTranslation={saveTranslation}
							fetchItemTags={fetchItemTags}
							handleNestedModifierGroups={handleNestedModifierGroups}
							readOnly={
								!access.isCatalogueManagement ||
								readOnly ||
								(isViewedFromMenuSection && !isMenuFlowIgnored)
							}
							setModalBusy={setModalBusy}
							setFormTouched={setBasicFormTouched}
							setLocationsToCascadePrice={setLocationsToCascadePrice}
							locationsToCascadePrice={locationsToCascadePrice}
							cascadePriceModalOpen={cascadePriceModalOpen}
							handleModal={handleModal}
							isViewedFromMenuSection={isViewedFromMenuSection && !isMenuFlowIgnored}
							menuSectionInfo={menuSectionInfo}
							optionsTranslations={optionsTranslations}
						/>
					)}
					{formTab === FORM_TABS[1].value && (
						<Images
							modifierId={parseInt(match.params.id)}
							platforms={bizPlatformsList || []}
							modulesEnabled={biz?.modulesEnabled || []}
							isFromMenuSection={isViewedFromMenuSection}
							menuModifierInfo={menuSectionInfo}
							handleForm={handleForm}
							readOnly={!access.isCatalogueManagement || readOnly}
						/>
					)}
					{formTab === FORM_TABS_MENU_SERVICE[2].value && isViewedFromMenuSection && (
						<EntityRulesAssociationList
							isOpen={formTab === FORM_TABS_MENU_SERVICE[2].value}
							menuId={menuId}
							entityType="option"
							brandId={menuDetailsData?.brand}
							entityName={data?.name}
							entityId={String(data?.id)}
						/>
					)}
					{formTab === FORM_TABS[2].value && (
						<AssociatedLocations
							connectedRef={associatedLocationsRef}
							modifierId={parseInt(match.params.id)}
							currencySymbol={currencySymbol}
							isEnforcedOla={isEnforcedOla}
							setModalBusy={setModalBusy}
							handleNestedEntity={handleNestedEntity}
							readOnly={
								!access.isCatalogueManagement ||
								readOnly ||
								(isViewedFromMenuSection && !isMenuFlowIgnored)
							}
							isFormTouched={isLocationsFormTouched}
							setFormTouched={(val) => setLocationsFormTouched(val)}
							dispatch={dispatch}
						/>
					)}
					{formTab === FORM_TABS[3].value && (
						<ModifierGroups
							modifierId={parseInt(match.params.id)}
							modifierName={data?.optionTitle || "modifier"}
							handleNestedEntity={handleNestedEntity}
							setModalBusy={setModalBusy}
							readOnly={!access.isCatalogueManagement || readOnly}
							isViewedFromMenuSection={isViewedFromMenuSection && !isMenuFlowIgnored}
							menuId={menuId}
						/>
					)}
					{formTab === FORM_TABS[4].value && (
						<NestedModifierGroups
							modifierId={parseInt(match.params.id)}
							modifierName={data?.optionTitle || "modifier"}
							handleNestedEntity={handleNestedEntity}
							setModalBusy={setModalBusy}
							readOnly={!access.isCatalogueManagement || readOnly}
							isViewedFromMenuSection={isViewedFromMenuSection && !isMenuFlowIgnored}
							menuId={menuId}
							reOrderedMenuNestedOptionGroups={reOrderedMenuNestedOptionGroups}
							setReOrderedMenuNestedOptionGroups={setReOrderedMenuNestedOptionGroups}
							setFormTouched={setIsMenuNestedModifiersFormTouched}
							handleEntityDissociation={handleEntityDissociation}
							menuDetailsData={menuDetailsData}
						/>
					)}
					{formTab === FORM_TABS[5].value && (
						<CustomFields
							modifierId={parseInt(match.params.id)}
							readOnly={
								!access.isCatalogueManagement ||
								readOnly ||
								(isViewedFromMenuSection && !isMenuFlowIgnored)
							}
						/>
					)}
					<ArchiveRestore
						isOpen={archiveRestore}
						close={handleArchiveRestore}
						entityType={CATALOGUE_ENTITY_TYPES[4]}
						entityName={data.optionTitle}
						object={data}
						mode={data.isEnabled ? "archive" : "restore"}
						fieldName="isEnabled"
						sourceOfTrigger={TRACKING_SOURCE.DETAIL_VIEW}
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						tab={nestedEntity?.tab || null}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
						readOnly={!access.isCatalogueManagement || readOnly}
						handleEntityDissociation={handleEntityDissociation}
						handleModifierSortOrderUpdate={handleModifierSortOrderUpdate}
						menuDetailsData={menuDetailsData}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
const mapStateToProps = (store) => ({
	currencySymbol: store.login.loggedInbizDetail.currencySymbol,
	supportedLanguages: store.login.loggedInbizDetail.supportedLanguages,
	access: store.login.loginDetail.access,
	isEnforcedOla: store.login.loggedInbizDetail.isEnforcedOla,
	biz: store.login.loggedInbizDetail,
	menuDetailsData: store.menuDetailsState.menuDetailsData
});
export default connect(mapStateToProps)(ModifierEdit);

// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { SUGGESTED_SEGMENTS, SEGMENT_GROUPS } from "../graphql/segments";

// utils
import { ActionTypes } from "../actions/_types";

export const fetchSegmentGroups = async (callback, fetchPolicy = "cache-first") => {
	store.dispatch({
		type: "GET_SEGMENT_GROUPS_REQUEST"
	});
	try {
		const resp = await client.query({
			query: SEGMENT_GROUPS,
			fetchPolicy
		});
		const segmentGroups = resp.data.segmentGroups.objects;
		callback(segmentGroups);
	} catch (error) {
		store.dispatch({
			type: "GET_SEGMENT_GROUPS_FAILURE"
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Failed to fetch segments",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchSuggestedSegment = async (props, fetchPolicy = "cache-first") => {
	store.dispatch({
		type: props.activeSegments ? "GET_SUGG_SEGMENT_LIST_REQUEST" : "GET_INACTIVE_SEGMENT_LIST_REQUEST"
	});
	const variables = {
		filters: [
			{ field: "is_active", value: String(props.activeSegments) },
			{ field: "is_implicit", value: "false" }
		],
		limit: 10
	};
	if (props.selectedGroup === "Recommended Segments") {
		variables.suggested = true;
	} else {
		variables.filters.push({ field: "group", value: props.selectedGroup });
	}
	try {
		const resp = await client.query({
			query: SUGGESTED_SEGMENTS,
			variables,
			fetchPolicy
		});
		store.dispatch({
			type: props.activeSegments ? "GET_SUGG_SEGMENT_LIST_SUCCESS" : "GET_INACTIVE_SEGMENT_LIST_SUCCESS",
			payload: resp.data.segments
		});
	} catch (error) {
		store.dispatch({
			type: props.activeSegments ? "GET_SUGG_SEGMENT_LIST_FAILURE" : "GET_INACTIVE_SEGMENT_LIST_FAILURE",
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

import React, { useState, useEffect, useRef, useCallback } from "react";

// components
import MetricCard from "../Common/MetricCard";
import MetricSection from "../Common/MetricSection";
import MetricHeader from "../Common/MetricHeader";
import ChartMetric from "../Common/ChartMetric";
import MultiChartMetrics from "../Common/MultiChartMetrics";
import { CompareFilter, BreakdownByFilter } from "../Common/Filters";
import { CustomTable } from "../../_commons/CustomTable";
import ChartSelector from "../Common/ChartSelector";
import ShowComparison from "../Common/ShowComparison";
import { ButtonIcon } from "../../_commons/ButtonIcon";

// charts
import Line from "../../NivoCharts/Line";
import Heatmap from "../../NivoCharts/Heatmap";
import Pie from "../../NivoCharts/Pie";
import Bar from "../../NivoCharts/Bar";

// third party
import { connect } from "react-redux";
import he from "he";

// utils
import { getSortOrder, trackEvent, commifyNumbers, capitaliseTextStrict } from "../../../atlas-utils";

// store
import { client } from "../../../client";
import { store } from "../../../store/configureStore";

// graphql
import { GET_ITEM_MODIFIER_GROUPS } from "../../../graphql/analytics";

// helpers
import { getReadableDateFilter } from "../../../helpers/analytics";

// actions
import { ActionTypes } from "../../../actions/_types";
import {
	updateAnalyticsEntityDetailState,
	fetchRevenueTrendMetrics,
	fetchRevenueTrendChart,
	fetchUnitsSoldMetrics,
	fetchUnitsSoldChart,
	fetchCatalogueOrderFrequency,
	fetchCatalogueLocationPerformanceTable,
	fetchPopularAddOnsTable,
	fetchVariantPerformanceChart,
	fetchVariantPerformanceTable,
	fetchOfflineCountMetrics,
	fetchOfflineCountChart,
	fetchItemAvailability,
	fetchCatalogueLostOrdersMetrics,
	fetchCatalogueLostOrdersChart,
	fetchCatalogueLostOrdersTable,
	fetchCatalogueLostOrdersBreakdownChart,
	fetchCatalogueLostOrdersBreakdownTable
} from "../../../actions/analytics";

// constants
import { ANALYTICS_DEFAULT_COLORS } from "../../../client-config";
const ENVIRONMENT_FLAG = process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG;

const ItemPerformance = ({
	match,
	query,
	section,
	analyticsEntityDetail,
	analyticsFiltersState,
	currency,
	currencySymbol,
	isMultibrandEnabled = false,
	tabs = [],
	setTabs,
	setInitTabs,
	setCurrTab,
	refs,
	handleViewAction,
	access
}) => {
	const [itemVariants, setItemVariants] = useState([]);
	const { appliedFilters } = analyticsFiltersState;
	const firstRender = useRef(true);
	const areMultipleBrandsSelected =
		Boolean(appliedFilters?.brand_id?.find((brand) => brand === "all")) || appliedFilters?.brand_id?.length > 1;
	const areMultiplePlatformsSelected =
		Boolean(appliedFilters?.platform_names?.find((plf) => plf === "all")) ||
		appliedFilters?.platform_names?.length > 1;

	const getItemModifierGroups = async () => {
		try {
			const variables = {
				id: parseInt(match.params.id),
				limit: 50,
				offset: 0,
				filters: [
					{
						field: "is_type_variant",
						value: "true"
					}
				]
			};

			const resp = await client.query({
				query: GET_ITEM_MODIFIER_GROUPS,
				variables,
				fetchPolicy: "cache-first"
			});

			return resp.data.item?.optionGroups?.objects || [];
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	};

	const handleInitState = async () => {
		// get variants of item
		let variants = [];
		variants = await getItemModifierGroups();
		setItemVariants(variants);

		// update tabs
		let updatedTabs = [...tabs];
		const applFilters = store.getState().analyticsFiltersState?.appliedFilters || {};
		const _areMultipleBrandsSelected =
			Boolean(applFilters?.brand_id?.find((brand) => brand === "all")) || applFilters?.brand_id?.length > 1;
		const _areMultiplePlatformsSelected =
			Boolean(applFilters?.platform_names?.find((plf) => plf === "all")) ||
			applFilters?.platform_names?.length > 1;

		updatedTabs.splice(
			5,
			0,
			...(variants ?? []).map((variant) => ({
				label: `Variant Performance: ${variant.optionGroupTitle}`,
				value: `variant_performance_${variant.optionGroupTitle?.split(" ")?.join("_")?.toLowerCase()}`
			}))
		);

		setTabs(
			updatedTabs.filter((tab) =>
				(!isMultibrandEnabled && !_areMultiplePlatformsSelected) ||
				(isMultibrandEnabled && !_areMultiplePlatformsSelected && !_areMultipleBrandsSelected)
					? tab.value !== "lost_orders_breakdown"
					: tab
			)
		);
		setInitTabs(updatedTabs);

		// update entity detail init state
		const payload = {
			revenue_trend: {
				metricsLoading: true,
				chartLoading: true,
				metrics: {
					item_revenue: {},
					item_avg_revenue_per_day: {},
					item_avg_order_value: {},
					item_order_completed_orders: {}
				},
				graphData: [],
				yScaleMax: "auto"
			},
			units_sold: {
				metricsLoading: true,
				chartLoading: true,
				metrics: {
					item_units_sold: {},
					item_avg_orders_per_day: {},
					item_avg_price: {},
					item_order_completed_orders: {}
				},
				graphData: [],
				yScaleMax: "auto"
			},
			order_frequency: {
				loading: true,
				compare: { label: "Hours", value: "ORDER_ITEM_CREATED_DATE_HOUR" },
				applCompare: { label: "Hours", value: "ORDER_ITEM_CREATED_DATE_HOUR" },
				compareOptions: [],
				compareFilterDisabled: false,
				showComparison: false,
				applShowComparison: false,
				graphData: [],
				maxValue: "auto"
			},
			location_performance: {
				loading: true,
				limit: 10,
				offset: 0,
				sort: {
					field: "ITEM_ORDER_COMPLETED_ORDERS",
					order: "DESC"
				},
				sortedField: "ITEM_ORDER_COMPLETED_ORDERS",
				tabularData: {
					fields: [],
					columns: [],
					rows: [],
					count: 0,
					isRowClickable: false
				},
				tableColumnsSelected: {
					columns: {}
				},
				filters: [],
				searchKeywords: [],
				searchFieldSelected: { key: "default", valueForDisplay: "Default" },
				searchFieldValue: ""
			},
			popular_add_ons: {
				loading: true,
				limit: 10,
				offset: 0,
				sort: {
					field: "OPTION_COMPLETED_ORDERS",
					order: "DESC"
				},
				sortedField: "OPTION_COMPLETED_ORDERS",
				tabularData: {
					fields: [],
					columns: [],
					rows: [],
					count: 0,
					isRowClickable: false
				},
				tableColumnsSelected: {
					columns: {}
				},
				filters: [],
				searchKeywords: [],
				searchFieldSelected: { key: "default", valueForDisplay: "Default" },
				searchFieldValue: ""
			},
			offline_count: {
				metricsLoading: true,
				chartLoading: true,
				metrics: {
					item_action_offline_items: {},
					item_action_offline_time: {}
				},
				graphData: [],
				yScaleMax: "auto"
			},
			item_availability: {
				loading: true,
				compare: { label: "Hours", value: "ITEM_ACTION_DATE_HOUR", type: "hour" },
				applCompare: { label: "Hours", value: "ITEM_ACTION_DATE_HOUR", type: "hour" },
				compareOptions: [],
				compareFilterDisabled: false,
				showComparison: false,
				applShowComparison: false,
				graphData: [],
				maxValue: "auto"
			},
			lost_orders: {
				metricsLoading: true,
				chartLoading: true,
				tableLoading: true,
				showComparison: false,
				selectedChart: "bar",
				metrics: {
					item_lost_orders: {}
				},
				graphData: {
					bar: [],
					line: []
				},
				maxValue: "auto",
				yScaleMax: "auto",
				limit: 10,
				offset: 0,
				sort: {
					field: "lost_orders",
					order: "DESC"
				},
				sortedField: "lost_orders",
				tabularData: {
					columns: [],
					rows: [],
					count: 0,
					isRowClickable: false
				}
			},
			lost_orders_breakdown: {
				chartLoading: true,
				tableLoading: true,
				showComparison: false,
				selectedChart: "bar",
				breakdownBy: { label: "Platform", value: "platform" },
				graphData: {
					bar: [],
					line: []
				},
				maxValue: "auto",
				yScaleMax: "auto",
				limit: 10,
				offset: 0,
				sort: {
					field: "ITEM_LOST_ORDERS",
					order: "DESC"
				},
				sortedField: "ITEM_LOST_ORDERS",
				tabularData: {
					columns: [],
					rows: [],
					count: 0,
					isRowClickable: false
				},
				hideColumns: ["brand"]
			}
		};
		(variants ?? []).forEach((variant) => {
			payload[`variant_performance_${variant.optionGroupTitle?.split(" ")?.join("_")?.toLowerCase()}`] = {
				chartLoading: true,
				tableLoading: true,
				selectedChart: "pie",
				graphData: {
					pie: [],
					line: []
				},
				yScaleMax: "auto",
				legends: {},
				limit: 10,
				offset: 0,
				sort: {
					field: "OPTION_ORDER_NET_REVENUE",
					order: "DESC"
				},
				sortedField: "OPTION_ORDER_NET_REVENUE",
				tabularData: {
					columns: [],
					rows: [],
					count: 0,
					isRowClickable: false
				},
				tableColumnsSelected: {
					columns: {}
				}
			};
		});
		store.dispatch({
			type: ActionTypes.UPDATE_ANALYTICS_ENTITY_DETAIL_INIT_STATE,
			payload
		});
	};

	useEffect(() => {
		handleInitState();
		trackEvent("analytics_module", { module: "Item Performance", from: capitaliseTextStrict(section) });
	}, []);

	const updateTabs = useCallback(() => {
		let updatedTabs = [...tabs];
		const applFilters = store.getState().analyticsFiltersState?.appliedFilters || {};
		const _areMultipleBrandsSelected =
			Boolean(applFilters?.brand_id?.find((brand) => brand === "all")) || applFilters?.brand_id?.length > 1;
		const _areMultiplePlatformsSelected =
			Boolean(applFilters?.platform_names?.find((plf) => plf === "all")) ||
			applFilters?.platform_names?.length > 1;

		setTabs(
			updatedTabs.filter((tab) =>
				(!isMultibrandEnabled && !_areMultiplePlatformsSelected) ||
				(isMultibrandEnabled && !_areMultiplePlatformsSelected && !_areMultipleBrandsSelected)
					? tab.value !== "lost_orders_breakdown"
					: tab
			)
		);
	}, [tabs]);

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
			return;
		}
		updateTabs();
	}, [appliedFilters?.platform_names, appliedFilters?.brand_id]);

	const renderCenterMetricValue = (props) => {
		let total = 0;
		props.dataWithArc.forEach((datum) => {
			total += Math.round(datum.value);
		});
		return `${he.unescape(currencySymbol)}${commifyNumbers(total)}`;
	};

	return (
		<div className="item-performance">
			<MetricCard
				metric="revenue_trend"
				cardRef={refs.current["revenue_trend"]}
				handleCardRef={(el) => (refs.current["revenue_trend"] = el)}
				setCurrTab={setCurrTab}
			>
				{(metric, metricCardInViewport) => (
					<React.Fragment>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={(metric) => fetchRevenueTrendMetrics(metric, match.params.id)}
							state={analyticsEntityDetail}
						>
							{({ metricsLoading, metrics }) => (
								<React.Fragment>
									<MetricHeader>
										<div className="header-left">
											<ChartMetric
												size="large"
												loading={metricsLoading}
												label="Net Revenue"
												description="Total earned from all fulfilled orders. Sum of order totals, plus any additional charges and discounts provided by platforms, less any discounts you offer and total taxes."
												value={metrics?.item_revenue?.value}
												percentageChange={metrics?.item_revenue?.percentageChange}
												compareValue={metrics?.item_revenue?.compareValue}
												currencySymbol={currencySymbol}
											/>
										</div>
									</MetricHeader>
									<MultiChartMetrics>
										<ChartMetric
											size="small"
											loading={metricsLoading}
											label="Avg Revenue / Day"
											description="Average net revenue earned per day over the selected time frame. It is the net revenue divided by the total days in the selected time frame."
											value={metrics?.item_avg_revenue_per_day?.value}
											percentageChange={metrics?.item_avg_revenue_per_day?.percentageChange}
											compareValue={metrics?.item_avg_revenue_per_day?.compareValue}
											currencySymbol={currencySymbol}
										/>
										<ChartMetric
											size="small"
											loading={metricsLoading}
											label="Avg Order Value"
											description="Average transaction value of an order during the selected time frame. It is the net revenue divided by the total orders recieved for the selected time frame."
											value={metrics?.item_avg_order_value?.value}
											percentageChange={metrics?.item_avg_order_value?.percentageChange}
											compareValue={metrics?.item_avg_order_value?.compareValue}
											currencySymbol={currencySymbol}
										/>
										<ChartMetric
											size="small"
											loading={metricsLoading}
											label="Completed Orders"
											description="Total orders that have been successfully marked completed during selected time frame."
											value={metrics?.item_order_completed_orders?.value}
											percentageChange={metrics?.item_order_completed_orders?.percentageChange}
											compareValue={metrics?.item_order_completed_orders?.compareValue}
										/>
									</MultiChartMetrics>
								</React.Fragment>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={(metric) => fetchRevenueTrendChart(metric, match.params.id)}
							state={analyticsEntityDetail}
						>
							{({ chartLoading, graphData, yScaleMax }, applDateFilter) => (
								<Line
									height={400}
									data={graphData}
									loading={chartLoading}
									marginLeft={70}
									durationPreset={applDateFilter?.current?.dateFilter}
									axisBottomTickRotation={graphData?.[0]?.data?.length > 7 ? -45 : 0}
									axisLeftLegendOffset={-60}
									yScaleStacked={false}
									yScaleMax={yScaleMax}
									enableArea={true}
									showCompareInTooltip={applDateFilter?.compare?.dateFilter}
									colors={["#2543B6"]}
									dashedLines={applDateFilter?.compare?.dateFilter}
									dashedLineIds={
										applDateFilter?.compare?.dateFilter
											? [`Net Revenue (${getReadableDateFilter(true)})*`]
											: []
									}
									legendItemWidth={applDateFilter?.compare?.dateFilter ? 250 : 100}
									currencySymbol={currencySymbol}
									renderTooltipYName={(props) => "Net Revenue"}
								/>
							)}
						</MetricSection>
					</React.Fragment>
				)}
			</MetricCard>
			<MetricCard
				metric="units_sold"
				cardRef={refs.current["units_sold"]}
				handleCardRef={(el) => (refs.current["units_sold"] = el)}
				setCurrTab={setCurrTab}
			>
				{(metric, metricCardInViewport) => (
					<React.Fragment>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={(metric) => fetchUnitsSoldMetrics(metric, match.params.id)}
							state={analyticsEntityDetail}
						>
							{({ metricsLoading, metrics }) => (
								<React.Fragment>
									<MetricHeader>
										<div className="header-left">
											<ChartMetric
												size="large"
												loading={metricsLoading}
												label="Units Sold"
												description="Total sales volume for an item."
												value={metrics?.item_units_sold?.value}
												percentageChange={metrics?.item_units_sold?.percentageChange}
												compareValue={metrics?.item_units_sold?.compareValue}
											/>
										</div>
									</MetricHeader>
									<MultiChartMetrics>
										<ChartMetric
											size="small"
											loading={metricsLoading}
											label="Avg Orders / Day"
											description="Average number of orders received daily during the selected time frame."
											value={metrics?.item_avg_orders_per_day?.value}
											percentageChange={metrics?.item_avg_orders_per_day?.percentageChange}
											compareValue={metrics?.item_avg_orders_per_day?.compareValue}
										/>
										<ChartMetric
											size="small"
											loading={metricsLoading}
											label="Avg Price"
											description="Average price at which an item was sold."
											value={metrics?.item_avg_price?.value}
											percentageChange={metrics?.item_avg_price?.percentageChange}
											compareValue={metrics?.item_avg_price?.compareValue}
											currencySymbol={currencySymbol}
										/>
										<ChartMetric
											size="small"
											loading={metricsLoading}
											label="Completed Orders"
											description="Total orders that have been successfully marked completed during selected time frame."
											value={metrics?.item_order_completed_orders?.value}
											percentageChange={metrics?.item_order_completed_orders?.percentageChange}
											compareValue={metrics?.item_order_completed_orders?.compareValue}
										/>
									</MultiChartMetrics>
								</React.Fragment>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={(metric) => fetchUnitsSoldChart(metric, match.params.id)}
							state={analyticsEntityDetail}
						>
							{({ chartLoading, graphData, yScaleMax }, applDateFilter) => (
								<Line
									height={400}
									data={graphData}
									loading={chartLoading}
									marginLeft={70}
									durationPreset={applDateFilter?.current?.dateFilter}
									axisBottomTickRotation={graphData?.[0]?.data?.length > 7 ? -45 : 0}
									axisLeftLegendOffset={-60}
									yScaleStacked={false}
									yScaleMax={yScaleMax}
									enableArea={true}
									showCompareInTooltip={applDateFilter?.compare?.dateFilter}
									colors={["#2543B6"]}
									dashedLines={applDateFilter?.compare?.dateFilter}
									dashedLineIds={
										applDateFilter?.compare?.dateFilter
											? [`Units Sold (${getReadableDateFilter(true)})*`]
											: []
									}
									legendItemWidth={applDateFilter?.compare?.dateFilter ? 250 : 100}
									renderTooltipYName={(props) => "Units Sold"}
								/>
							)}
						</MetricSection>
					</React.Fragment>
				)}
			</MetricCard>
			<MetricCard
				metric="order_frequency"
				cardRef={refs.current["order_frequency"]}
				handleCardRef={(el) => (refs.current["order_frequency"] = el)}
				setCurrTab={setCurrTab}
			>
				{(metric, metricCardInViewport) => (
					<MetricSection
						metric={metric}
						metricCardInViewport={metricCardInViewport}
						metricSectionApi={(metric) => fetchCatalogueOrderFrequency(metric, match.params.id)}
						state={analyticsEntityDetail}
						dependencies={[
							analyticsEntityDetail[metric]?.compare?.value,
							analyticsEntityDetail[metric]?.showComparison
						]}
					>
						{(
							{
								loading,
								graphData,
								maxValue = "auto",
								compare,
								applCompare,
								compareOptions = [],
								compareFilterDisabled = false,
								showComparison = false,
								applShowComparison = false
							},
							applDateFilter
						) => (
							<React.Fragment>
								<MetricHeader>
									<div className="header-left">
										<ChartMetric
											size="large"
											label="Order Frequency"
											description="Order volume frequency density across various times and days."
										/>
									</div>
								</MetricHeader>
								<div className="heatmap-filters">
									<CompareFilter
										currValue={compare}
										options={compareOptions}
										setFilter={(field, value) =>
											updateAnalyticsEntityDetailState(metric, {
												[field]: value
											})
										}
										module="Catalogue"
										metric={metric}
										readOnly={compareFilterDisabled}
									/>
									<ShowComparison
										title="Average & Compare"
										loading={loading}
										isChecked={showComparison}
										clickHandler={() =>
											updateAnalyticsEntityDetailState(metric, {
												showComparison: !showComparison
											})
										}
										module="Catalogue"
										metric={metric}
										readOnly={!applDateFilter?.compare?.dateFilter}
									/>
								</div>
								<Heatmap
									responsive={!["Hours", "Dates", "Weeks"].includes(applCompare?.label)}
									enableCustomLegends={["Hours", "Dates", "Weeks"].includes(applCompare?.label)}
									canvas={true}
									width={
										(applShowComparison && graphData.length === 2) || applCompare?.label === "Dates"
											? 3000
											: 2500
									}
									height={graphData?.length < 4 ? 350 : (graphData?.length || 3) * 28 + 160}
									loading={loading}
									data={graphData}
									minValue={maxValue === 0 ? 0 : undefined}
									maxValue={maxValue === 0 ? 4 : undefined}
									marginLeft={
										!applShowComparison && applCompare?.label === "Days of the Week"
											? 200
											: applShowComparison &&
												  applDateFilter.current.dateTypeSelected.value === "range"
												? 150
												: applShowComparison &&
													  applDateFilter.current.dateFilter === "YESTERDAY"
													? 125
													: 100
									}
									marginRight={!["Hours", "Dates", "Weeks"].includes(applCompare?.label) ? 10 : 40}
									colors="cool"
									yInnerPadding={applShowComparison && graphData.length === 2 ? 0.25 : 0}
									enableLabels={false}
									scrollOffset={
										applCompare?.label === "Hours" ? (applShowComparison ? 1000 : 800) : 0
									}
								/>
							</React.Fragment>
						)}
					</MetricSection>
				)}
			</MetricCard>
			<MetricCard
				metric="location_performance"
				cardRef={refs.current["location_performance"]}
				handleCardRef={(el) => (refs.current["location_performance"] = el)}
				setCurrTab={setCurrTab}
			>
				{(metric, metricCardInViewport) => (
					<MetricSection
						metric={metric}
						metricCardInViewport={metricCardInViewport}
						metricSectionApi={(metric) =>
							fetchCatalogueLocationPerformanceTable(metric, {}, match.params.id)
						}
						state={analyticsEntityDetail}
						dependencies={[analyticsEntityDetail[metric]?.sort]}
					>
						{({ loading, tabularData, tableColumnsSelected, sort, sortedField }) => (
							<React.Fragment>
								<MetricHeader>
									<div className="header-left">
										<ChartMetric
											size="large"
											label="Location Performance"
											description="Overview of revenue distribution across different store locations."
										/>
									</div>
								</MetricHeader>
								<CustomTable
									forAnalytics={true}
									loading={loading}
									data={tabularData?.rows || []}
									columns={
										tabularData?.columns?.filter(
											(col) => tableColumnsSelected?.columns?.[col.field]
										) || []
									}
									lastColumn={
										tableColumnsSelected?.columns
											? Object.keys(tableColumnsSelected?.columns)
													?.filter((key) => tableColumnsSelected?.columns?.[key])
													?.slice(-1)?.[0]
											: undefined
									}
									sortList={(field) =>
										updateAnalyticsEntityDetailState("location_performance", {
											sort: {
												field: field,
												order: getSortOrder(sort, { field })
											},
											sortedField: field
										})
									}
									sortedField={sortedField}
									sortedOrder={sort?.order}
									classes="location-performance-table-container"
									content="Locations"
									currencySymbol={currencySymbol}
									hasLimitedRows={true}
									limitRows={5}
									isRowClickable={tabularData?.isRowClickable}
									viewAction="View All"
									handleViewAction={() => {
										handleViewAction(
											"/analytics/detail/list",
											"location-performance",
											`location-performance/${match.params.id}`,
											"location_performance"
										);
										trackEvent("analytics_link_text_clicked", {
											module: "Catalogue",
											type: "View All",
											origin: metric
										});
									}}
								/>
							</React.Fragment>
						)}
					</MetricSection>
				)}
			</MetricCard>
			<MetricCard
				metric="popular_add_ons"
				cardRef={refs.current["popular_add_ons"]}
				handleCardRef={(el) => (refs.current["popular_add_ons"] = el)}
				setCurrTab={setCurrTab}
			>
				{(metric, metricCardInViewport) => (
					<MetricSection
						metric={metric}
						metricCardInViewport={metricCardInViewport}
						metricSectionApi={(metric) => fetchPopularAddOnsTable(metric, {}, match.params.id)}
						state={analyticsEntityDetail}
						dependencies={[analyticsEntityDetail[metric]?.sort]}
					>
						{({ loading, tabularData, tableColumnsSelected, sort, sortedField }) => (
							<React.Fragment>
								<MetricHeader>
									<div className="header-left">
										<ChartMetric
											size="large"
											label="Popular Add-Ons"
											description="Most commonly ordered add-ons associated with the item."
										/>
									</div>
								</MetricHeader>
								<CustomTable
									forAnalytics={true}
									loading={loading}
									data={tabularData?.rows || []}
									columns={
										tabularData?.columns?.filter(
											(col) => tableColumnsSelected?.columns?.[col.field]
										) || []
									}
									lastColumn={
										tableColumnsSelected?.columns
											? Object.keys(tableColumnsSelected?.columns)
													?.filter((key) => tableColumnsSelected?.columns?.[key])
													?.slice(-1)?.[0]
											: undefined
									}
									sortList={(field) =>
										updateAnalyticsEntityDetailState("popular_add_ons", {
											sort: {
												field: field,
												order: getSortOrder(sort, { field })
											},
											sortedField: field
										})
									}
									sortedField={sortedField}
									sortedOrder={sort?.order}
									classes="popular-add-ons-table-container"
									content="Add-Ons"
									currencySymbol={currencySymbol}
									hasLimitedRows={true}
									limitRows={5}
									isRowClickable={tabularData?.isRowClickable}
									viewAction="View All"
									handleViewAction={() => {
										handleViewAction(
											"/analytics/detail/list",
											"popular-add-ons",
											`popular-add-ons/${match.params.id}`,
											"popular_add_ons"
										);
										trackEvent("analytics_link_text_clicked", {
											module: "Catalogue",
											type: "View All",
											origin: metric
										});
									}}
								/>
							</React.Fragment>
						)}
					</MetricSection>
				)}
			</MetricCard>
			{itemVariants?.length > 0 &&
				itemVariants?.map((variant, i) => {
					let variantName = variant.optionGroupTitle?.split(" ")?.join("_")?.toLowerCase();
					return (
						<MetricCard
							key={i}
							metric={`variant_performance_${variantName}`}
							classes="variant-performance"
							cardRef={refs.current[`variant_performance_${variantName}`]}
							handleCardRef={(el) => (refs.current[`variant_performance_${variantName}`] = el)}
							setCurrTab={setCurrTab}
						>
							{(metric, metricCardInViewport) => (
								<React.Fragment>
									<MetricSection
										metric={metric}
										metricCardInViewport={metricCardInViewport}
										metricSectionApi={(metric) =>
											fetchVariantPerformanceChart(metric, match.params.id, variant.id)
										}
										state={analyticsEntityDetail}
										dependencies={[analyticsEntityDetail[metric]?.selectedChart]}
									>
										{(
											{ chartLoading, selectedChart = "pie", graphData, yScaleMax },
											applDateFilter
										) => (
											<React.Fragment>
												<MetricHeader>
													<div className="header-left">
														<ChartMetric
															size="large"
															label={`Variant Performance: ${variant.optionGroupTitle}`}
															description="Overview of sales performance by item variant."
														/>
													</div>
													<div className="header-right">
														<ChartSelector
															selectedChart={selectedChart}
															options={["pie", "line"]}
															clickHandler={(chart) => {
																updateAnalyticsEntityDetailState(metric, {
																	selectedChart: chart
																});
																trackEvent("analytics_chart_type_switched", {
																	module: "Catalogue",
																	origin: metric,
																	from: selectedChart,
																	to: chart
																});
															}}
														/>
													</div>
												</MetricHeader>
												{selectedChart === "pie" && (
													<Pie
														height={400}
														marginTop={40}
														marginBottom={100}
														data={graphData?.pie || []}
														colors={[...ANALYTICS_DEFAULT_COLORS]}
														idKey="name"
														arcLabel="name"
														loading={chartLoading}
														enableLegends={false}
														enableCenterMetric={true}
														renderCenterMetricLabel={() => "Net Revenue"}
														renderCenterMetricValue={renderCenterMetricValue}
														sortByValue={false}
														currencySymbol={currencySymbol}
													/>
												)}
												{selectedChart === "line" && (
													<Line
														height={400}
														data={graphData?.line || []}
														durationPreset={applDateFilter?.current?.dateFilter}
														colors={{ datum: "color" }}
														loading={chartLoading}
														enableLegends={false}
														yScaleMax={yScaleMax}
														showCompareInTooltip={applDateFilter?.compare?.dateFilter}
														suffixedYValues={true}
														axisBottomTickRotation={
															graphData?.line?.[0]?.data?.length > 7 ? -45 : 0
														}
														dashedLines={applDateFilter?.compare?.dateFilter}
														dashedLineIndices={
															applDateFilter?.compare?.dateFilter ? "odd" : ""
														}
														showDashedLineOnHover={true}
														currencySymbol={currencySymbol}
													/>
												)}
											</React.Fragment>
										)}
									</MetricSection>
									<MetricSection
										metric={metric}
										metricCardInViewport={metricCardInViewport}
										metricSectionApi={(metric) =>
											fetchVariantPerformanceTable(metric, match.params.id, variant.id)
										}
										state={analyticsEntityDetail}
										dependencies={[analyticsEntityDetail[metric]?.sort]}
									>
										{({
											tableLoading,
											tabularData,
											legends,
											tableColumnsSelected,
											sortedField,
											sort
										}) => (
											<CustomTable
												forAnalytics={true}
												loading={tableLoading}
												data={tabularData?.rows || []}
												legends={legends}
												columns={
													tabularData?.columns?.filter(
														(col) => tableColumnsSelected?.columns?.[col.field]
													) || []
												}
												lastColumn={
													tableColumnsSelected?.columns
														? Object.keys(tableColumnsSelected?.columns)
																?.filter((key) => tableColumnsSelected?.columns?.[key])
																?.slice(-1)?.[0]
														: undefined
												}
												sortList={(field) =>
													updateAnalyticsEntityDetailState(
														`variant_performance_${variantName}`,
														{
															sort: {
																field: field,
																order: getSortOrder(sort, { field })
															},
															sortedField: field
														}
													)
												}
												sortedField={sortedField}
												sortedOrder={sort?.order}
												classes="variant-performance-table-container"
												content="Data"
												currencySymbol={currencySymbol}
											/>
										)}
									</MetricSection>
								</React.Fragment>
							)}
						</MetricCard>
					);
				})}
			<MetricCard
				metric="offline_count"
				cardRef={refs.current["offline_count"]}
				handleCardRef={(el) => (refs.current["offline_count"] = el)}
				setCurrTab={setCurrTab}
			>
				{(metric, metricCardInViewport) => (
					<React.Fragment>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={(metric) => fetchOfflineCountMetrics(metric, match.params.id)}
							state={analyticsEntityDetail}
						>
							{({ metricsLoading, metrics }) => (
								<React.Fragment>
									<MetricHeader>
										<div className="header-left">
											<ChartMetric
												size="large"
												loading={metricsLoading}
												label="Offline Count"
												description="Count of times items were made unavailable."
												value={metrics?.item_action_offline_items?.value}
												percentageChange={metrics?.item_action_offline_items?.percentageChange}
												compareValue={metrics?.item_action_offline_items?.compareValue}
												invertColors={true}
											/>
										</div>
									</MetricHeader>
									{/* <MultiChartMetrics>
										<ChartMetric
											size='small'
											loading={metricsLoading}
											label="Total Offline Time"
											description="Cumulative time items were unavailable for order."
											value={metrics?.item_action_offline_items?.value}
											percentageChange={metrics?.item_action_offline_items?.percentageChange}
											compareValue={metrics?.item_action_offline_items?.compareValue}
											units=" mins"
										/>
									</MultiChartMetrics> */}
								</React.Fragment>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={(metric) => fetchOfflineCountChart(metric, match.params.id)}
							state={analyticsEntityDetail}
						>
							{({ chartLoading, graphData, yScaleMax }, applDateFilter) => (
								<Line
									height={400}
									data={graphData}
									loading={chartLoading}
									marginLeft={70}
									durationPreset={applDateFilter?.current?.dateFilter}
									axisBottomTickRotation={graphData?.[0]?.data?.length > 7 ? -45 : 0}
									axisLeftLegendOffset={-60}
									yScaleStacked={false}
									yScaleMax={yScaleMax}
									enableArea={true}
									showCompareInTooltip={applDateFilter?.compare?.dateFilter}
									colors={["#2543B6"]}
									dashedLines={applDateFilter?.compare?.dateFilter}
									dashedLineIds={
										applDateFilter?.compare?.dateFilter
											? [`Offline Count (${getReadableDateFilter(true)})*`]
											: []
									}
									legendItemWidth={applDateFilter?.compare?.dateFilter ? 250 : 100}
									renderTooltipYName={(props) => "Offline Count"}
								/>
							)}
						</MetricSection>
					</React.Fragment>
				)}
			</MetricCard>
			<MetricCard
				metric="item_availability"
				cardRef={refs.current["item_availability"]}
				handleCardRef={(el) => (refs.current["item_availability"] = el)}
				setCurrTab={setCurrTab}
			>
				{(metric, metricCardInViewport) => (
					<MetricSection
						metric={metric}
						metricCardInViewport={metricCardInViewport}
						metricSectionApi={(metric) => fetchItemAvailability(metric, match.params.id)}
						state={analyticsEntityDetail}
						dependencies={[
							analyticsEntityDetail[metric]?.compare?.value,
							analyticsEntityDetail[metric]?.showComparison
						]}
					>
						{(
							{
								loading,
								graphData,
								maxValue = "auto",
								compare,
								applCompare,
								compareOptions = [],
								compareFilterDisabled = false,
								showComparison = false,
								applShowComparison = false
							},
							applDateFilter
						) => (
							<React.Fragment>
								<MetricHeader>
									<div className="header-left">
										<ChartMetric
											size="large"
											label="Item Availability"
											description="Heatmap showing when items were available or offline. Darker shades indicate longer offline periods, assisting in identifying potential availability issues."
										/>
									</div>
								</MetricHeader>
								<div className="heatmap-filters">
									<CompareFilter
										currValue={compare}
										options={compareOptions}
										setFilter={(field, value) =>
											updateAnalyticsEntityDetailState(metric, {
												[field]: value
											})
										}
										module="Catalogue"
										metric={metric}
										readOnly={compareFilterDisabled}
									/>
									<ShowComparison
										title="Average & Compare"
										loading={loading}
										isChecked={showComparison}
										clickHandler={() =>
											updateAnalyticsEntityDetailState(metric, {
												showComparison: !showComparison
											})
										}
										module="Catalogue"
										metric={metric}
										readOnly={!applDateFilter?.compare?.dateFilter}
									/>
								</div>
								<Heatmap
									responsive={!["Hours", "Dates", "Weeks"].includes(applCompare?.label)}
									enableLegends={false}
									enableCustomLegends={true}
									canvas={true}
									width={
										(applShowComparison && graphData.length === 2) || applCompare?.label === "Dates"
											? 3000
											: 2500
									}
									height={
										graphData?.length === 1
											? 200
											: graphData?.length < 6
												? 350
												: (graphData?.length || 3) * 28 + 160
									}
									loading={loading}
									data={graphData}
									minValue={maxValue === 0 ? 0 : undefined}
									maxValue={maxValue === 0 ? 4 : undefined}
									marginLeft={
										!applShowComparison && applCompare?.label === "Days of the Week"
											? 200
											: applShowComparison &&
												  applDateFilter.current.dateTypeSelected.value === "range"
												? 150
												: applShowComparison &&
													  applDateFilter.current.dateFilter === "YESTERDAY"
													? 125
													: 100
									}
									marginRight={!["Hours", "Dates", "Weeks"].includes(applCompare?.label) ? 10 : 40}
									colors="warm"
									yInnerPadding={applShowComparison && graphData.length === 2 ? 0.25 : 0}
									enableLabels={false}
									legendTicks={["Online", "", "", "", "Offline"]}
									legendCellLength={100}
									scrollOffset={
										applCompare?.label === "Hours" ? (applShowComparison ? 1000 : 800) : 0
									}
									renderCustomTooltipLabel={() => "Offline Percentage"}
								/>
							</React.Fragment>
						)}
					</MetricSection>
				)}
			</MetricCard>
			<MetricCard
				metric="lost_orders"
				cardRef={refs.current["lost_orders"]}
				handleCardRef={(el) => (refs.current["lost_orders"] = el)}
				setCurrTab={setCurrTab}
			>
				{(metric, metricCardInViewport) => (
					<React.Fragment>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={(metric) => fetchCatalogueLostOrdersMetrics(metric, match.params.id)}
							state={analyticsEntityDetail}
						>
							{(
								{
									metricsLoading,
									chartLoading,
									metrics,
									selectedChart = "bar",
									showComparison = false
								},
								applDateFilter
							) => (
								<React.Fragment>
									<MetricHeader>
										<div className="header-left">
											<ChartMetric
												size="large"
												loading={metricsLoading}
												label="Lost Orders"
												description="Cancelled orders categorisation segmented by restaurant and platform."
												value={metrics?.item_lost_orders?.value}
												percentageChange={metrics?.item_lost_orders?.percentageChange}
												compareValue={metrics?.item_lost_orders?.compareValue}
												invertColors={true}
											/>
										</div>
										<div className="header-right">
											<ChartSelector
												selectedChart={selectedChart}
												options={["bar", "line"]}
												clickHandler={(chart) => {
													updateAnalyticsEntityDetailState(metric, {
														selectedChart: chart
													});
													trackEvent("analytics_chart_type_switched", {
														module: "Catalogue",
														origin: metric,
														from: selectedChart,
														to: chart
													});
												}}
											/>
										</div>
									</MetricHeader>
									<ShowComparison
										loading={chartLoading}
										isChecked={showComparison}
										clickHandler={() =>
											updateAnalyticsEntityDetailState(metric, {
												showComparison: !showComparison
											})
										}
										module="Catalogue"
										metric={metric}
										readOnly={!applDateFilter?.compare?.dateFilter}
									/>
								</React.Fragment>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={(metric) => fetchCatalogueLostOrdersChart(metric, match.params.id)}
							state={analyticsEntityDetail}
							dependencies={[
								analyticsEntityDetail[metric]?.selectedChart,
								analyticsEntityDetail[metric]?.showComparison
							]}
						>
							{(
								{
									chartLoading,
									selectedChart = "bar",
									showComparison = false,
									graphData,
									yScaleMax,
									maxValue
								},
								applDateFilter
							) => (
								<React.Fragment>
									{selectedChart === "bar" && (
										<Bar
											height={400}
											data={graphData?.bar}
											loading={chartLoading}
											colors={
												showComparison && applDateFilter?.compare?.dateFilter
													? ["#2543B6", "#2543B6", "#88D9F8", "#88D9F8", "#9048C8", "#9048C8"]
													: ["#2543B6", "#88D9F8", "#9048C8"]
											}
											keys={
												showComparison && applDateFilter?.compare?.dateFilter
													? [
															"Cancelled Pre Acknowledgement",
															"Cancelled Pre Acknowledgement*",
															"Cancelled Post Acknowledgement",
															"Cancelled Post Acknowledgement*"
														]
													: [
															"Cancelled Pre Acknowledgement",
															"Cancelled Post Acknowledgement"
														]
											}
											patternIds={[
												{
													match: { id: "Cancelled Pre Acknowledgement*" },
													id: "lines"
												},
												{
													match: { id: "Cancelled Post Acknowledgement*" },
													id: "lines"
												}
											]}
											maxValue={maxValue}
											enableLegends={!showComparison || !applDateFilter?.compare?.dateFilter}
											customLegends={showComparison && applDateFilter?.compare?.dateFilter}
											applDateFilter={applDateFilter}
											groupMode="grouped"
											indexBy="label"
											padding={showComparison ? 0.5 : 0.75}
											innerPadding={20}
											legendItemWidth={230}
										/>
									)}
									{selectedChart === "line" && (
										<Line
											height={400}
											data={graphData?.line}
											durationPreset={applDateFilter?.current?.dateFilter}
											showCompareInTooltip={showComparison && applDateFilter?.compare?.dateFilter}
											loading={chartLoading}
											marginLeft={70}
											axisLeftLegendOffset={-60}
											yScaleMax={yScaleMax}
											yScaleStacked={false}
											axisBottomTickRotation={graphData?.line?.[0]?.data?.length > 7 ? -45 : 0}
											dashedLines={showComparison && applDateFilter?.compare?.dateFilter}
											dashedLineIds={
												applDateFilter?.compare?.dateFilter
													? [`Lost Orders (${getReadableDateFilter(true)})*`]
													: []
											}
											enableArea={true}
											colors={["#2543B6"]}
											legendItemWidth={
												showComparison && applDateFilter?.compare?.dateFilter ? 250 : 100
											}
											renderTooltipYName={(props) => "Lost Orders"}
										/>
									)}
								</React.Fragment>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={(metric) => fetchCatalogueLostOrdersTable(metric, match.params.id)}
							state={analyticsEntityDetail}
							dependencies={[analyticsEntityDetail[metric]?.sort]}
						>
							{({ tableLoading, tabularData, sortedField, sort }) => (
								<CustomTable
									forAnalytics={true}
									loading={tableLoading}
									data={tabularData?.rows || []}
									columns={tabularData?.columns || []}
									sortList={(field) =>
										updateAnalyticsEntityDetailState(metric, {
											sort: {
												field: field,
												order: getSortOrder(sort, { field })
											},
											sortedField: field
										})
									}
									sortedField={sortedField}
									sortedOrder={sort?.order}
									classes="lost-orders-table-container"
									content="Data"
									currencySymbol={currencySymbol}
								/>
							)}
						</MetricSection>
					</React.Fragment>
				)}
			</MetricCard>
			{((!isMultibrandEnabled && areMultiplePlatformsSelected) ||
				(isMultibrandEnabled && (areMultiplePlatformsSelected || areMultipleBrandsSelected))) && (
				<MetricCard
					metric="lost_orders_breakdown"
					cardRef={refs.current["lost_orders_breakdown"]}
					handleCardRef={(el) => (refs.current["lost_orders_breakdown"] = el)}
					setCurrTab={setCurrTab}
				>
					{(metric, metricCardInViewport) => (
						<React.Fragment>
							<MetricSection
								metric={metric}
								metricCardInViewport={metricCardInViewport}
								metricSectionApi={(metric) =>
									fetchCatalogueLostOrdersBreakdownChart(metric, match.params.id)
								}
								state={analyticsEntityDetail}
								dependencies={[
									analyticsEntityDetail[metric]?.selectedChart,
									analyticsEntityDetail[metric]?.breakdownBy?.value,
									analyticsEntityDetail[metric]?.showComparison
								]}
							>
								{(
									{
										chartLoading,
										selectedChart = "bar",
										showComparison = false,
										breakdownBy,
										graphData,
										yScaleMax,
										maxValue
									},
									applDateFilter
								) => (
									<React.Fragment>
										<MetricHeader>
											<div className="header-left">
												<ChartMetric
													size="large"
													label="Lost Orders Breakdown"
													description="Lost orders categorisation based on reasons for order cancellations, segmented by acknowledgment status and source."
												/>
											</div>
											<div className="header-right">
												<ChartSelector
													selectedChart={selectedChart}
													options={["bar", "line"]}
													clickHandler={(chart) => {
														updateAnalyticsEntityDetailState(metric, {
															selectedChart: chart
														});
														trackEvent("analytics_chart_type_switched", {
															module: "Catalogue",
															origin: metric,
															from: selectedChart,
															to: chart
														});
													}}
												/>
												<BreakdownByFilter
													currValue={breakdownBy}
													setFilter={(field, value) => {
														updateAnalyticsEntityDetailState(metric, {
															[field]: value
														});
														trackEvent("analytics_breakdown_selection", {
															module: "Catalogue",
															metric: metric,
															breakdownType: value.label
														});
													}}
													isMultibrandEnabled={isMultibrandEnabled}
													readOnly={
														!areMultiplePlatformsSelected || !areMultipleBrandsSelected
													}
												/>
											</div>
										</MetricHeader>
										<ShowComparison
											loading={chartLoading}
											isChecked={showComparison}
											clickHandler={() =>
												updateAnalyticsEntityDetailState(metric, {
													showComparison: !showComparison
												})
											}
											module="Catalogue"
											metric={metric}
											readOnly={!applDateFilter?.compare?.dateFilter}
										/>
										{selectedChart === "bar" && (
											<Bar
												height={400}
												data={graphData?.bar}
												marginBottom={breakdownBy?.value === "platform" ? 130 : 150}
												loading={chartLoading}
												colors={[...ANALYTICS_DEFAULT_COLORS]}
												keys={
													showComparison && applDateFilter?.compare?.dateFilter
														? ["current", "previous"]
														: [
																"Merchant (Pre-Ack)",
																"Merchant (Post-Ack)",
																"Platform (Pre-Ack)",
																"Platform (Post-Ack)"
															]
												}
												patternIds={[
													{
														match: {
															id: "previous"
														},
														id: "lines"
													}
												]}
												maxValue={maxValue}
												groupMode={
													showComparison && applDateFilter?.compare?.dateFilter
														? "grouped-stacked"
														: "stacked"
												}
												enableLegends={!showComparison || !applDateFilter?.compare?.dateFilter}
												customLegends={showComparison && applDateFilter?.compare?.dateFilter}
												padding={
													showComparison && applDateFilter?.compare?.dateFilter ? 0.6 : 0.8
												}
												innerPadding={20}
												applDateFilter={applDateFilter}
												indexBy="label"
												axisBottomTickRotation={graphData?.bar?.length > 7 ? -35 : 0}
											/>
										)}
										{selectedChart === "line" && (
											<Line
												height={400}
												data={graphData?.line || []}
												durationPreset={applDateFilter?.current?.dateFilter}
												showCompareInTooltip={
													showComparison && applDateFilter?.compare?.dateFilter
												}
												colors={{ datum: "color" }}
												loading={chartLoading}
												yScaleMax={yScaleMax}
												dashedLines={showComparison && applDateFilter?.compare?.dateFilter}
												dashedLineIndices={applDateFilter?.compare?.dateFilter ? "odd" : ""}
												showDashedLineOnHover={true}
												enableLegends={!showComparison || !applDateFilter?.compare?.dateFilter}
												axisBottomTickRotation={
													graphData?.line?.[0]?.data?.length > 7 ? -45 : 0
												}
												legendItemWidth={breakdownBy.value === "platform" ? 100 : 130}
											/>
										)}
									</React.Fragment>
								)}
							</MetricSection>
							<MetricSection
								metric={metric}
								metricCardInViewport={metricCardInViewport}
								metricSectionApi={(metric) =>
									fetchCatalogueLostOrdersBreakdownTable(metric, match.params.id)
								}
								state={analyticsEntityDetail}
								dependencies={[
									analyticsEntityDetail[metric]?.breakdownBy?.value,
									analyticsEntityDetail[metric]?.sort
								]}
							>
								{({ tableLoading, breakdownBy, tabularData, sortedField, sort, hideColumns }) => (
									<CustomTable
										forAnalytics={true}
										loading={tableLoading}
										data={tabularData?.rows || []}
										columns={tabularData?.columns || []}
										sortList={(field) =>
											updateAnalyticsEntityDetailState(metric, {
												sort: {
													field: field,
													order: getSortOrder(sort, { field })
												},
												sortedField: field
											})
										}
										sortedField={sortedField}
										sortedOrder={sort?.order}
										classes="lost-orders-breakdown-table-container"
										content={`${breakdownBy?.label}s`}
										currencySymbol={currencySymbol}
										hideColumns={hideColumns || []}
									/>
								)}
							</MetricSection>
						</React.Fragment>
					)}
				</MetricCard>
			)}
		</div>
	);
};
export default connect((store) => ({
	analyticsEntityDetail: store.analyticsEntityDetail,
	analyticsFiltersState: store.analyticsFiltersState,
	currency: store.login.loggedInbizDetail.currency,
	currencySymbol: store.login.loggedInbizDetail.currencySymbol,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	access: store.login.loginDetail.access
}))(ItemPerformance);

import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";

// components
import Header from "../../components/Analytics/Common/Header";
import SectionHeader from "../../components/Analytics/Common/SectionHeader";
import ShareReport from "../../components/Analytics/Common/ShareReport";
import Filters, { BreakdownByFilter } from "../../components/Analytics/Common/Filters";
import { Topbar } from "../../components/_commons/Topbar";
import MetricCard from "../../components/Analytics/Common/MetricCard";
import MetricHeader from "../../components/Analytics/Common/MetricHeader";
import MetricSection from "../../components/Analytics/Common/MetricSection";
import ChartMetric from "../../components/Analytics/Common/ChartMetric";
import MultiChartMetrics from "../../components/Analytics/Common/MultiChartMetrics";
import ActionButton from "../../components/Analytics/Common/ActionButton";
import ChartSelector from "../../components/Analytics/Common/ChartSelector";
import ShowComparison from "../../components/Analytics/Common/ShowComparison";
import { CustomTable } from "../../components/_commons/CustomTable";
import { NestedEntityContainer } from "../../components/_commons/NestedEntityContainer";
import DownloadButton from "../../components/Analytics/Common/DownloadButton";
import { Share } from "../../components/Analytics/Common/Share";

// charts
import Line from "../../components/NivoCharts/Line";
import Pie from "../../components/NivoCharts/Pie";
import Bar from "../../components/NivoCharts/Bar";

// third party
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import history from "../../history";
import he from "he";

// store
import { store } from "../../store/configureStore";

// utils
import {
	scroll,
	commifyNumbers,
	getSortOrder,
	breadcrumbs,
	trackEvent,
	adjustNestedContainer
} from "../../atlas-utils";

// helpers
import {
	captureScreenshot,
	generateDurationText,
	getDurationPreset,
	getReadableDateFilter,
	trackPNGDownload
} from "../../helpers/analytics";

// actions
import {
	updateRevenueAnalyticsState,
	fetchGrossRevenueMetrics,
	fetchRevenueBreakdownChart,
	fetchRevenueBreakdownTable,
	fetchAverageOrderValueMetrics,
	fetchAverageOrderValueChart,
	fetchRevenueByLocation,
	fetchRevenueByItem,
	fetchLostRevenueMetrics,
	fetchLostRevenueChart,
	fetchLostRevenueTable,
	fetchLostRevenueBreakdownChart,
	fetchLostRevenueBreakdownTable,
	fetchGrossRevenueChart,
	fetchAndGenerateCSVReport,
	getAllAppliedFilters
} from "../../actions/analytics";

// constants
import { ANALYTICS_SECTIONS, ANALYTICS_DEFAULT_COLORS, NESTED_ENTITY_TYPES } from "../../client-config";

const ENVIRONMENT_FLAG = process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG;
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const Revenue = ({
	match,
	revenueAnalytics,
	analyticsFiltersState,
	currencySymbol,
	isMultibrandEnabled = false,
	analyticsReportState
}) => {
	const { search } = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);
	const [currTab, setCurrTab] = useState(query.get("metric") || ANALYTICS_SECTIONS["revenue"]?.["tabs"]?.[0]?.value);
	const [isPageScrolled, setPageScrolled] = useState(document?.documentElement?.scrollTop > 60 || false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const { appliedFilters } = analyticsFiltersState;
	const nestedRef = useRef();
	const refs = {
		gross_revenue: useRef(),
		net_revenue: useRef(),
		revenue_breakdown: useRef(),
		avg_order_value: useRef(),
		revenue_by_location: useRef(),
		revenue_by_item: useRef(),
		lost_revenue: useRef(),
		lost_revenue_breakdown: useRef()
	};
	const areMultipleBrandsSelected =
		Boolean(appliedFilters?.brand_id?.find((brand) => brand === "all")) || appliedFilters?.brand_id?.length > 1;
	const areMultiplePlatformsSelected =
		Boolean(appliedFilters?.platform_names?.find((plf) => plf === "all")) ||
		appliedFilters?.platform_names?.length > 1;

	const switchTab = (tab, tabClick = false) => {
		setCurrTab(tab.value);
		window.requestAnimationFrame(() => {
			scroll(
				{
					top: refs[tab.value]?.current?.offsetTop - (document.querySelector(".with-banner") ? 290 : 230),
					left: 0
				},
				window,
				"instant"
			);
		});
		// track event
		if (tabClick) {
			trackEvent("analytics_tab_clicked", {
				module: "Revenue",
				tab: tab.label
			});
		}
	};

	const handlePageScroll = () => {
		if (document.documentElement.scrollTop > 60) {
			setPageScrolled(true);
		} else {
			setPageScrolled(false);
		}
	};

	useEffect(() => {
		// if metric param exists in url, scroll to that metric
		if (query.get("metric")) {
			switchTab({ value: query.get("metric") });
		} else {
			document.documentElement.scrollTop = 0;
		}
		trackEvent("analytics_module", { module: "Revenue" });
		window.addEventListener("scroll", handlePageScroll);
		return () => {
			window.removeEventListener("scroll", handlePageScroll);
		};
	}, []);

	const handleTableRowClick = (rowData, metric, entity) => {
		if (rowData.name && rowData.item) {
			// create breadcrumbs
			let bc = [];
			bc.push({
				title: "Revenue Analytics",
				to: `${match.url}?metric=${metric}`
			});
			const encodedName = encodeURIComponent(rowData?.name?.replaceAll("%", "percent"));
			const url = `${match.url}/view/${entity}/${encodedName}/${rowData.item}${`?filters=${query.get(
				"filters"
			)}`}${`&bc=${breadcrumbs.encode(bc)}`}`;
			history.push(url);
		}
	};

	const renderCenterMetricValue = (props) => {
		let total = 0;
		props.dataWithArc.forEach((datum) => {
			total += Math.round(datum.value);
		});
		return `${he.unescape(currencySymbol)}${commifyNumbers(total)}`;
	};

	const handleMetricScroll = () => {
		switch (currTab) {
			case "revenue_breakdown":
				if (isMultibrandEnabled && !areMultiplePlatformsSelected && !areMultipleBrandsSelected) {
					switchTab(ANALYTICS_SECTIONS["revenue"]?.["tabs"]?.[0]);
				} else if (!isMultibrandEnabled && !areMultiplePlatformsSelected) {
					switchTab(ANALYTICS_SECTIONS["revenue"]?.["tabs"]?.[0]);
				}
				break;
			case "lost_revenue_breakdown":
				if (isMultibrandEnabled && !areMultiplePlatformsSelected && !areMultipleBrandsSelected) {
					switchTab(ANALYTICS_SECTIONS["revenue"]?.["tabs"]?.[5]);
				} else if (!isMultibrandEnabled && !areMultiplePlatformsSelected) {
					switchTab(ANALYTICS_SECTIONS["revenue"]?.["tabs"]?.[5]);
				}
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		handleMetricScroll();
	}, [appliedFilters?.platform_names?.id, appliedFilters?.brand_id?.id]);

	const handleNestedEntity = (toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	};

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "analytics",
				start: "01:10"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	const handleSelectCustomLegends = (data) => {
		const filteredData = data
			.filter((val) => {
				return !val?.id?.endsWith("*");
			})
			.map((val) => ({
				id: val.id,
				label: val.id,
				color: val.color
			}));
		return filteredData;
	};

	const generateImage = (key, title = "Revenue", subChart) => {
		const durationObject = getAllAppliedFilters()?.durationObject?.duration;
		const comparisonDuration = getAllAppliedFilters()?.durationObject?.comparisonDuration;

		let timeDuration = durationObject?.preset
			? `${getDurationPreset(durationObject?.preset)?.label}`
			: durationObject?.preset ||
				`${generateDurationText(durationObject?.custom?.startDate, durationObject?.custom?.endDate)}`;
		timeDuration += `${comparisonDuration ? ` / Compared to ${generateDurationText(comparisonDuration?.startDate, comparisonDuration?.endDate)}` : ""}`;

		if (key === "revenue_breakdown") {
			const metricSectionElement = refs[key]?.current?.querySelector(`.metric-section .nivo-chart-${subChart}`);
			if (metricSectionElement) {
				let elementRef = { current: metricSectionElement };
				captureScreenshot(elementRef, title, timeDuration).then((res) => {
					trackPNGDownload(key, subChart);
				});
			}
		} else if (key === "lost_revenue" || key === "lost_revenue_breakdown") {
			const metricSectionElement = refs[key]?.current?.querySelector(".metric-section .chart-container");
			if (metricSectionElement) {
				let elementRef = { current: metricSectionElement };
				captureScreenshot(elementRef, title, timeDuration).then((res) => {
					trackPNGDownload(key, subChart);
				});
			}
		} else {
			captureScreenshot(refs[key], title, timeDuration).then((res) => {
				trackPNGDownload(key, subChart);
			});
		}
	};

	const [isFormOpen, setIsFormOpen] = useState(false);
	const handleCancel = () => {
		setIsFormOpen(false);
	};
	const linkShareBtn = (
		<div onClick={() => setIsFormOpen(true)} className="share">
			Share
		</div>
	);

	return (
		<div className={"analytics-revenue section-container-common" + (isPageScrolled ? " scrolled" : "")}>
			<Header>
				<SectionHeader
					title="Revenue Analytics"
					headerRight={linkShareBtn}
					showHelpBtn={true}
					handlePiperAcademy={handlePiperAcademy}
					isPageScrolled={isPageScrolled}
				/>
				<Filters showBrands showLocations showPlatforms showDateCompare module="Revenue" />
				<Topbar
					tabs={ANALYTICS_SECTIONS["revenue"]["tabs"]}
					selectedTab={currTab}
					switchTab={(tab) => switchTab(tab, true)}
					hiddenTabs={
						(!isMultibrandEnabled && !areMultiplePlatformsSelected) ||
						(isMultibrandEnabled && !areMultiplePlatformsSelected && !areMultipleBrandsSelected)
							? ["revenue_breakdown", "lost_revenue_breakdown"]
							: []
					}
				/>
			</Header>
			<Share module="revenue" handleCancel={handleCancel} isOpen={isFormOpen} />
			<MetricCard metric="gross_revenue" cardRef={refs["gross_revenue"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<React.Fragment>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchGrossRevenueMetrics}
							state={revenueAnalytics}
						>
							{({ metricsLoading, metrics }) => (
								<React.Fragment>
									<MetricHeader>
										<div className="header-left">
											<ChartMetric
												size="large"
												loading={metricsLoading}
												label="Gross Revenue"
												description="Total revenue generated before any discounts are applied."
												value={metrics?.order_gross_revenue?.value}
												percentageChange={metrics?.order_gross_revenue?.percentageChange}
												compareValue={metrics?.order_gross_revenue?.compareValue}
												currencySymbol={currencySymbol}
											/>
										</div>
										<div className="header-right">
											<ActionButton
												variant="link"
												href={`/analytics/orders${
													query.get("filters") ? `?filters=${query.get("filters")}` : ""
												}`}
												clickHandler={() =>
													trackEvent("analytics_link_text_clicked", {
														module: "Revenue",
														type: "Module",
														origin: metric,
														destination: "Orders"
													})
												}
												showArrow={false}
											>
												Go to Order Analytics
											</ActionButton>
											<DownloadButton
												coachMark={true}
												emptyData={
													((revenueAnalytics?.gross_revenue?.graphData || []).length == 0 ||
														!revenueAnalytics?.gross_revenue?.metrics) &&
													!metricsLoading &&
													!revenueAnalytics?.gross_revenue?.chartLoading
												}
												tooltipText="Export Chart as PNG"
												loading={
													metricsLoading || revenueAnalytics?.gross_revenue?.chartLoading
												}
												handleClick={() => {
													!metricsLoading &&
														!revenueAnalytics?.gross_revenue?.chartLoading &&
														(revenueAnalytics?.gross_revenue?.graphData || []).length !==
															0 &&
														generateImage("gross_revenue", "Revenue Summary", "line");
												}}
												borderLeft={true}
											/>
										</div>
									</MetricHeader>
									<MultiChartMetrics>
										<ChartMetric
											size="small"
											loading={metricsLoading}
											label="Charges"
											description="Total charges collected."
											value={metrics?.order_total_charges?.value}
											percentageChange={metrics?.order_total_charges?.percentageChange}
											compareValue={metrics?.order_total_charges?.compareValue}
											currencySymbol={currencySymbol}
										/>
										<ChartMetric
											size="small"
											loading={metricsLoading}
											label="Taxes"
											description="Total taxes collected."
											value={metrics?.order_total_taxes?.value}
											percentageChange={metrics?.order_total_taxes?.percentageChange}
											compareValue={metrics?.order_total_taxes?.compareValue}
										/>
										<ChartMetric
											size="small"
											loading={metricsLoading}
											label="Discounts"
											description="Total discounts offered by the restaurant."
											value={metrics?.order_merchant_discount?.value}
											percentageChange={metrics?.order_merchant_discount?.percentageChange}
											compareValue={metrics?.order_merchant_discount?.compareValue}
											currencySymbol={currencySymbol}
										/>
										<ChartMetric
											size="small"
											loading={metricsLoading}
											label="Net Revenue"
											description="Revenue after deducting restaurant discounts, excluding taxes and charges."
											value={metrics?.order_revenue?.value}
											percentageChange={metrics?.order_revenue?.percentageChange}
											compareValue={metrics?.order_revenue?.compareValue}
										/>
									</MultiChartMetrics>
								</React.Fragment>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchGrossRevenueChart}
							state={revenueAnalytics}
							classes="gross_revenue"
						>
							{({ chartLoading, graphData, yScaleMax }, applDateFilter) => {
								return (
									<Line
										height={400}
										data={graphData || []}
										durationPreset={applDateFilter?.current?.dateFilter}
										colors={{ datum: "color" }}
										showCompareInTooltip={applDateFilter?.compare?.dateFilter}
										dashedLines={applDateFilter?.compare?.dateFilter}
										dashedLineIndices={applDateFilter?.compare?.dateFilter ? "odd" : ""}
										showDashedLineOnHover
										enableLegends
										loading={chartLoading}
										yScaleMax={yScaleMax}
										suffixedYValues
										axisBottomTickRotation={graphData?.[0].data.length > 7 ? -45 : 0}
										currencySymbol={currencySymbol}
										legendItemWidth={130}
										marginBottom={120}
										customLegends
										legendTranslateY={90}
										handleSelectCustomLegends={handleSelectCustomLegends}
									/>
								);
							}}
						</MetricSection>
					</React.Fragment>
				)}
			</MetricCard>
			{((!isMultibrandEnabled && areMultiplePlatformsSelected) ||
				(isMultibrandEnabled && (areMultiplePlatformsSelected || areMultipleBrandsSelected))) && (
				<MetricCard metric="revenue_breakdown" cardRef={refs["revenue_breakdown"]} setCurrTab={setCurrTab}>
					{(metric, metricCardInViewport) => (
						<React.Fragment>
							<MetricSection
								metric={metric}
								metricCardInViewport={metricCardInViewport}
								metricSectionApi={fetchRevenueBreakdownChart}
								state={revenueAnalytics}
								dependencies={[
									revenueAnalytics[metric]?.selectedChart,
									revenueAnalytics[metric]?.breakdownBy?.value
								]}
							>
								{(
									{ chartLoading, selectedChart = "pie", breakdownBy, graphData, yScaleMax },
									applDateFilter
								) => (
									<React.Fragment>
										<MetricHeader>
											<div className="header-left">
												<ChartMetric
													size="large"
													label="Revenue Breakdown"
													description="A detailed view of net revenue categorised by different platforms or brands."
												/>
											</div>
											<div className="header-right">
												<ChartSelector
													selectedChart={selectedChart}
													options={["pie", "line"]}
													clickHandler={(chart) => {
														updateRevenueAnalyticsState(metric, {
															selectedChart: chart
														});
														trackEvent("analytics_chart_type_switched", {
															module: "Revenue",
															origin: metric,
															from: selectedChart,
															to: chart
														});
													}}
												/>
												<BreakdownByFilter
													currValue={breakdownBy}
													setFilter={(field, value) => {
														updateRevenueAnalyticsState(metric, {
															[field]: value
														});
														trackEvent("analytics_breakdown_selection", {
															module: "Revenue",
															metric: metric,
															breakdownType: value.label
														});
													}}
													isMultibrandEnabled={isMultibrandEnabled}
													readOnly={
														!areMultiplePlatformsSelected || !areMultipleBrandsSelected
													}
												/>
												<DownloadButton
													emptyData={
														(revenueAnalytics?.revenue_breakdown?.tabularData?.rows || [])
															.length == 0 &&
														!revenueAnalytics?.revenue_breakdown?.tableLoading &&
														!chartLoading
													}
													tooltipText="Export"
													classes={
														(revenueAnalytics?.revenue_breakdown?.tabularData?.rows || [])
															.length !== 0 &&
														!revenueAnalytics?.revenue_breakdown?.tableLoading &&
														!chartLoading
															? "center"
															: ""
													}
													loading={
														analyticsReportState?.revenue_breakdown?.loading ||
														revenueAnalytics?.revenue_breakdown?.tableLoading ||
														chartLoading
													}
													options={[
														{ value: "png", valueForDisplay: "Export Chart as PNG" },
														{ value: "csv", valueForDisplay: "Export Table as CSV" }
													]}
													handleClick={(opt) => {
														if (opt == "csv") {
															!analyticsReportState?.revenue_breakdown?.loading &&
																!revenueAnalytics?.revenue_breakdown?.tableLoading &&
																!chartLoading &&
																(
																	revenueAnalytics?.revenue_breakdown?.tabularData
																		?.rows || []
																).length !== 0 &&
																fetchAndGenerateCSVReport(
																	fetchRevenueBreakdownTable,
																	"getTabularData",
																	["revenue_breakdown", true]
																);
														} else if (opt == "png") {
															!chartLoading &&
																generateImage(
																	"revenue_breakdown",
																	"Revenue Breakdown",
																	selectedChart
																);
														}
													}}
												/>
											</div>
										</MetricHeader>
										{selectedChart === "pie" && (
											<Pie
												height={400}
												marginTop={40}
												marginBottom={100}
												data={graphData?.pie || []}
												colors={[...ANALYTICS_DEFAULT_COLORS]}
												idKey="name"
												arcLabel="name"
												loading={chartLoading}
												// commifyArcLinkLabel={true}
												enableCenterMetric={true}
												enableLegends={false}
												renderCenterMetricLabel={() => "Net Revenue"}
												renderCenterMetricValue={renderCenterMetricValue}
												sortByValue={false}
												// currency={currency}
												currencySymbol={currencySymbol}
											/>
										)}
										{selectedChart === "line" && (
											<Line
												height={400}
												data={graphData?.line || []}
												durationPreset={applDateFilter?.current?.dateFilter}
												// colors={[...ANALYTICS_DEFAULT_COLORS.slice(0, graphData?.line?.length)]}
												colors={{ datum: "color" }}
												loading={chartLoading}
												enableLegends={false}
												yScaleMax={yScaleMax}
												showCompareInTooltip={applDateFilter?.compare?.dateFilter}
												// axisBottomLegend="Date"
												// axisLeftLegend="Revenue"
												suffixedYValues={true}
												axisBottomTickRotation={
													graphData?.line?.[0]?.data?.length > 7 ? -45 : 0
												}
												dashedLines={applDateFilter?.compare?.dateFilter}
												dashedLineIndices={applDateFilter?.compare?.dateFilter ? "odd" : ""}
												showDashedLineOnHover={true}
												currencySymbol={currencySymbol}
											/>
										)}
									</React.Fragment>
								)}
							</MetricSection>
							<MetricSection
								metric={metric}
								metricCardInViewport={metricCardInViewport}
								metricSectionApi={fetchRevenueBreakdownTable}
								state={revenueAnalytics}
								dependencies={[
									revenueAnalytics[metric]?.breakdownBy?.value,
									revenueAnalytics[metric]?.sort
								]}
							>
								{({
									tableLoading,
									breakdownBy,
									tabularData,
									legends,
									sortedField,
									sort,
									hideColumns
								}) => (
									<CustomTable
										forAnalytics={true}
										loading={tableLoading}
										data={tabularData?.rows || []}
										columns={tabularData?.columns || []}
										legends={legends}
										sortList={(field) =>
											updateRevenueAnalyticsState(metric, {
												sort: {
													field: field,
													order: getSortOrder(sort, { field })
												},
												sortedField: field
											})
										}
										sortedField={sortedField}
										sortedOrder={sort?.order}
										classes="revenue-breakdown-table-container"
										content={`${breakdownBy?.label || "result"}s`}
										currencySymbol={currencySymbol}
										viewAction="View Orders Breakdown"
										viewActionVariant="link"
										viewActionHref={`/analytics/orders?metric=orders_breakdown${
											query.get("filters") ? `&filters=${query.get("filters")}` : ""
										}`}
										handleViewAction={() =>
											trackEvent("analytics_link_text_clicked", {
												module: "Revenue",
												type: "Module",
												origin: metric,
												destination: "orders_breakdown"
											})
										}
										hideColumns={hideColumns || []}
									/>
								)}
							</MetricSection>
						</React.Fragment>
					)}
				</MetricCard>
			)}
			<MetricCard metric="avg_order_value" cardRef={refs["avg_order_value"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<React.Fragment>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchAverageOrderValueMetrics}
							state={revenueAnalytics}
						>
							{({ metricsLoading, metrics }) => (
								<React.Fragment>
									<MetricHeader>
										<div className="header-left">
											<ChartMetric
												size="large"
												loading={metricsLoading}
												label="Average Order Value"
												description="Average transaction value of an order during the selected time frame. It is the net revenue divided by the total orders recieved for the selected time frame."
												value={metrics?.order_avg_order_value?.value}
												percentageChange={metrics?.order_avg_order_value?.percentageChange}
												compareValue={metrics?.order_avg_order_value?.compareValue}
												currencySymbol={currencySymbol}
											/>
										</div>
										<div className="header-right">
											<DownloadButton
												emptyData={
													((revenueAnalytics?.avg_order_value?.graphData || []).length == 0 ||
														!metrics?.order_avg_order_value) &&
													!metricsLoading &&
													!revenueAnalytics?.avg_order_value?.chartLoading
												}
												tooltipText="Export Chart as PNG"
												loading={
													metricsLoading || revenueAnalytics?.avg_order_value?.chartLoading
												}
												handleClick={() => {
													!metricsLoading &&
														!revenueAnalytics?.avg_order_value?.chartLoading &&
														(revenueAnalytics?.avg_order_value?.graphData || []).length !==
															0 &&
														generateImage("avg_order_value", "Average Order Value", "line");
												}}
											/>
										</div>
									</MetricHeader>
									<MultiChartMetrics>
										<ChartMetric
											size="large"
											label="Order Value Distribution Curve"
											description="Graphical representation depicting the frequency of specific order values across transactions."
										/>
									</MultiChartMetrics>
								</React.Fragment>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchAverageOrderValueChart}
							state={revenueAnalytics}
						>
							{({ chartLoading, graphData, yScaleMax }, applDateFilter) => (
								<Line
									height={400}
									data={graphData}
									metric={metric}
									loading={chartLoading}
									marginLeft={70}
									yScaleMax={yScaleMax}
									axisBottomTickRotation={graphData?.[0]?.data?.length > 7 ? -45 : 0}
									// axisBottomLegend="Order Value"
									// axisLeftLegend="Orders"
									axisLeftLegendOffset={-60}
									enableArea={true}
									colors={["#2543B6"]}
									showCompareInTooltip={applDateFilter?.compare?.dateFilter}
									dashedLines={applDateFilter?.compare?.dateFilter}
									dashedLineIds={
										applDateFilter?.compare?.dateFilter
											? [`Number of Orders (${getReadableDateFilter(true)})*`]
											: []
									}
									legendItemWidth={applDateFilter?.compare?.dateFilter ? 280 : 100}
									hasDate={false}
									currency={currencySymbol}
									currencyXValues={true}
								/>
							)}
						</MetricSection>
					</React.Fragment>
				)}
			</MetricCard>
			<MetricCard metric="revenue_by_location" cardRef={refs["revenue_by_location"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<MetricSection
						metric={metric}
						metricCardInViewport={metricCardInViewport}
						metricSectionApi={fetchRevenueByLocation}
						state={revenueAnalytics}
						dependencies={[revenueAnalytics[metric]?.sort]}
					>
						{({ loading, tabularData, tableColumnsSelected, sort, sortedField }) => (
							<React.Fragment>
								<MetricHeader>
									<div className="header-left">
										<ChartMetric
											size="large"
											label="Revenue By Location"
											description="Overview of revenue distribution across different store locations."
										/>
									</div>
									<div className="header-right">
										<DownloadButton
											handleClick={() =>
												!analyticsReportState?.revenue_by_location?.loading &&
												!revenueAnalytics?.revenue_by_location?.loading &&
												(revenueAnalytics?.revenue_by_location?.tabularData?.rows || [])
													.length !== 0 &&
												fetchAndGenerateCSVReport(fetchRevenueByLocation, "getTabularData", [
													"revenue_by_location",
													{},
													true
												])
											}
											emptyData={
												(revenueAnalytics?.revenue_by_location?.tabularData?.rows || [])
													.length == 0 && !revenueAnalytics?.revenue_by_location?.loading
											}
											classes={
												(revenueAnalytics?.revenue_by_location?.tabularData?.rows || [])
													.length !== 0
													? "center"
													: ""
											}
											loading={
												analyticsReportState?.revenue_by_location?.loading ||
												revenueAnalytics?.revenue_by_location?.loading
											}
										/>
									</div>
								</MetricHeader>
								<CustomTable
									forAnalytics={true}
									loading={loading}
									data={tabularData?.rows || []}
									columns={
										tabularData?.columns?.filter(
											(col) => tableColumnsSelected?.columns?.[col.field]
										) || []
									}
									lastColumn={
										tableColumnsSelected?.columns
											? Object.keys(tableColumnsSelected?.columns)
													?.filter((key) => tableColumnsSelected?.columns?.[key])
													?.slice(-1)?.[0]
											: undefined
									}
									sortList={(field) =>
										updateRevenueAnalyticsState(metric, {
											sort: {
												field: field,
												order: getSortOrder(sort, { field })
											},
											sortedField: field
										})
									}
									sortedField={sortedField}
									sortedOrder={sort?.order}
									classes="revenue-by-location-table-container"
									content="Locations"
									currencySymbol={currencySymbol}
									hasLimitedRows={true}
									limitRows={5}
									isRowClickable={tabularData?.isRowClickable}
									viewAction="View All"
									viewActionVariant="link"
									viewActionHref={`/analytics/revenue/list/location-performance/revenue-by-location${`?filters=${query.get(
										"filters"
									)}`}${`&bc=${breadcrumbs.encode([
										{
											title: "Revenue Analytics",
											to: `/analytics/revenue?metric=${metric}`
										}
									])}`}`}
									handleViewAction={() =>
										trackEvent("analytics_link_text_clicked", {
											module: "Revenue",
											type: "View All",
											origin: metric
										})
									}
								/>
							</React.Fragment>
						)}
					</MetricSection>
				)}
			</MetricCard>
			<MetricCard metric="revenue_by_item" cardRef={refs["revenue_by_item"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<MetricSection
						metric={metric}
						metricCardInViewport={metricCardInViewport}
						metricSectionApi={fetchRevenueByItem}
						state={revenueAnalytics}
						dependencies={[revenueAnalytics[metric]?.sort]}
					>
						{({ loading, tabularData, tableColumnsSelected, sort, sortedField }) => (
							<React.Fragment>
								<MetricHeader>
									<div className="header-left">
										<ChartMetric
											size="large"
											label="Revenue By Item"
											description="Overview of revenue distribution based on individual menu items"
										/>
									</div>
									<div className="header-right">
										<DownloadButton
											handleClick={() =>
												!analyticsReportState?.revenue_by_item?.loading &&
												!revenueAnalytics?.revenue_by_item?.loading &&
												(revenueAnalytics?.revenue_by_item?.tabularData?.rows || []).length !==
													0 &&
												fetchAndGenerateCSVReport(fetchRevenueByItem, "getTabularData", [
													"revenue_by_item",
													{},
													true
												])
											}
											emptyData={
												(revenueAnalytics?.revenue_by_item?.tabularData?.rows || []).length ==
													0 && !revenueAnalytics?.revenue_by_item?.loading
											}
											classes={
												(revenueAnalytics?.revenue_by_item?.tabularData?.rows || []).length !==
												0
													? "center"
													: ""
											}
											loading={
												analyticsReportState?.revenue_by_item?.loading ||
												revenueAnalytics?.revenue_by_item?.loading
											}
										/>
									</div>
								</MetricHeader>
								<CustomTable
									forAnalytics={true}
									loading={loading}
									data={tabularData?.rows || []}
									columns={
										tabularData?.columns?.filter(
											(col) => tableColumnsSelected?.columns?.[col.field]
										) || []
									}
									lastColumn={
										tableColumnsSelected?.columns
											? Object.keys(tableColumnsSelected?.columns)
													?.filter((key) => tableColumnsSelected?.columns?.[key])
													?.slice(-1)?.[0]
											: undefined
									}
									sortList={(field) =>
										updateRevenueAnalyticsState(metric, {
											sort: {
												field: field,
												order: getSortOrder(sort, { field })
											},
											sortedField: field
										})
									}
									sortedField={sortedField}
									sortedOrder={sort?.order}
									classes="revenue-by-item-table-container"
									content="Items"
									currencySymbol={currencySymbol}
									hasLimitedRows={true}
									limitRows={5}
									isRowClickable={tabularData?.isRowClickable}
									rowClickHandler={(rowData) =>
										handleTableRowClick(rowData, metric, tabularData?.rowLink?.entity)
									}
									viewAction="View All"
									viewActionVariant="link"
									viewActionHref={`/analytics/revenue/list/item-performance/revenue-by-item${`?filters=${query.get(
										"filters"
									)}`}${`&bc=${breadcrumbs.encode([
										{
											title: "Revenue Analytics",
											to: `/analytics/revenue?metric=${metric}`
										}
									])}`}`}
									handleViewAction={() =>
										trackEvent("analytics_link_text_clicked", {
											module: "Revenue",
											type: "View All",
											origin: metric
										})
									}
								/>
							</React.Fragment>
						)}
					</MetricSection>
				)}
			</MetricCard>
			<MetricCard metric="lost_revenue" cardRef={refs["lost_revenue"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<React.Fragment>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchLostRevenueMetrics}
							state={revenueAnalytics}
						>
							{(
								{
									metricsLoading,
									chartLoading,
									metrics,
									selectedChart = "bar",
									showComparison = false
								},
								applDateFilter
							) => (
								<React.Fragment>
									<MetricHeader>
										<div className="header-left">
											<ChartMetric
												size="large"
												loading={metricsLoading}
												label="Lost Revenue"
												description="Total value of unrealised revenue due to order cancellations."
												value={metrics?.order_lost_revenue?.value}
												percentageChange={metrics?.order_lost_revenue?.percentageChange}
												compareValue={metrics?.order_lost_revenue?.compareValue}
												currencySymbol={currencySymbol}
												invertColors={true}
											/>
										</div>
										<div className="header-right">
											<ChartSelector
												selectedChart={selectedChart}
												options={["bar", "line"]}
												clickHandler={(chart) => {
													updateRevenueAnalyticsState(metric, {
														selectedChart: chart
													});
													trackEvent("analytics_chart_type_switched", {
														module: "Revenue",
														origin: metric,
														from: selectedChart,
														to: chart
													});
												}}
											/>
											<DownloadButton
												handleClick={(opt) => {
													if (opt == "csv") {
														!analyticsReportState?.lost_revenue?.loading &&
															!revenueAnalytics?.lost_revenue?.tableLoading &&
															(revenueAnalytics?.lost_revenue?.tabularData?.rows || [])
																.length !== 0 &&
															fetchAndGenerateCSVReport(
																fetchLostRevenueTable,
																"getTabularData",
																["lost_revenue", true]
															);
													} else if (opt == "png") {
														!chartLoading &&
															generateImage(
																"lost_revenue",
																"Lost Revenue",
																selectedChart
															);
													}
												}}
												options={[
													{ value: "png", valueForDisplay: "Export Chart as PNG" },
													{ value: "csv", valueForDisplay: "Export Table as CSV" }
												]}
												tooltipText="Export"
												emptyData={
													(revenueAnalytics?.lost_revenue?.tabularData?.rows || []).length ==
														0 &&
													revenueAnalytics?.lost_revenue?.graphData?.[selectedChart].length ==
														0 &&
													!revenueAnalytics?.lost_revenue?.tableLoading &&
													!chartLoading
												}
												classes={
													(revenueAnalytics?.lost_revenue?.tabularData?.rows || []).length !==
														0 &&
													revenueAnalytics?.lost_revenue?.graphData?.[selectedChart]
														.length !== 0 &&
													!revenueAnalytics?.lost_revenue?.tableLoading &&
													!chartLoading &&
													"center"
												}
												loading={
													analyticsReportState?.lost_revenue?.loading ||
													revenueAnalytics?.lost_revenue?.tableLoading ||
													chartLoading
												}
											/>
										</div>
									</MetricHeader>
									<ShowComparison
										loading={chartLoading}
										isChecked={showComparison}
										clickHandler={() =>
											updateRevenueAnalyticsState(metric, { showComparison: !showComparison })
										}
										module="Revenue"
										metric={metric}
										readOnly={!applDateFilter?.compare?.dateFilter}
									/>
								</React.Fragment>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchLostRevenueChart}
							state={revenueAnalytics}
							dependencies={[
								revenueAnalytics[metric]?.selectedChart,
								revenueAnalytics[metric]?.showComparison
							]}
						>
							{(
								{
									chartLoading,
									selectedChart = "bar",
									showComparison = false,
									graphData,
									yScaleMax,
									maxValue
								},
								applDateFilter
							) => (
								<div className="chart-container">
									{selectedChart === "bar" && (
										<Bar
											height={400}
											data={graphData?.bar}
											loading={chartLoading}
											colors={
												showComparison && applDateFilter?.compare?.dateFilter
													? ["#2543B6", "#2543B6", "#88D9F8", "#88D9F8", "#9048C8", "#9048C8"]
													: ["#2543B6", "#88D9F8", "#9048C8"]
											}
											keys={
												showComparison && applDateFilter?.compare?.dateFilter
													? [
															"Cancelled Pre Acknowledgement",
															"Cancelled Pre Acknowledgement*",
															"Cancelled Post Acknowledgement",
															"Cancelled Post Acknowledgement*"
														]
													: [
															"Cancelled Pre Acknowledgement",
															"Cancelled Post Acknowledgement"
														]
											}
											patternIds={[
												{
													match: { id: "Cancelled Pre Acknowledgement*" },
													id: "lines"
												},
												{
													match: { id: "Cancelled Post Acknowledgement*" },
													id: "lines"
												}
											]}
											maxValue={maxValue}
											enableLegends={!showComparison || !applDateFilter?.compare?.dateFilter}
											customLegends={showComparison && applDateFilter?.compare?.dateFilter}
											applDateFilter={applDateFilter}
											groupMode="grouped"
											indexBy="label"
											padding={showComparison ? 0.5 : 0.75}
											innerPadding={20}
											// axisLeftLegend="Revenue"
											suffixedYValues={true}
											currencySymbol={currencySymbol}
											legendItemWidth={230}
										/>
									)}
									{selectedChart === "line" && (
										<Line
											height={400}
											data={graphData?.line}
											durationPreset={applDateFilter?.current?.dateFilter}
											loading={chartLoading}
											marginLeft={70}
											yScaleMax={yScaleMax}
											// axisBottomLegend="Date"
											// axisLeftLegend="Revenue"
											axisLeftLegendOffset={-60}
											yScaleStacked={false}
											enableArea={true}
											colors={["#2543B6"]}
											showCompareInTooltip={showComparison && applDateFilter?.compare?.dateFilter}
											axisBottomTickRotation={graphData?.line?.[0]?.data?.length > 7 ? -45 : 0}
											dashedLines={showComparison && applDateFilter?.compare?.dateFilter}
											dashedLineIds={
												applDateFilter?.compare?.dateFilter
													? [`Lost Revenue (${getReadableDateFilter(true)})*`]
													: []
											}
											currencySymbol={currencySymbol}
											legendItemWidth={
												showComparison && applDateFilter?.compare?.dateFilter ? 250 : 100
											}
											renderTooltipYName={(props) => "Lost Revenue"}
										/>
									)}
								</div>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchLostRevenueTable}
							state={revenueAnalytics}
							dependencies={[revenueAnalytics[metric]?.sort]}
						>
							{({ tableLoading, tabularData, sortedField, sort }) => (
								<CustomTable
									forAnalytics={true}
									loading={tableLoading}
									data={tabularData?.rows || []}
									columns={tabularData?.columns || []}
									sortList={(field) =>
										updateRevenueAnalyticsState(metric, {
											sort: {
												field: field,
												order: getSortOrder(sort, { field })
											},
											sortedField: field
										})
									}
									sortedField={sortedField}
									sortedOrder={sort?.order}
									classes="lost-revenue-table-container"
									content="Data"
									currencySymbol={currencySymbol}
								/>
							)}
						</MetricSection>
					</React.Fragment>
				)}
			</MetricCard>
			{((!isMultibrandEnabled && areMultiplePlatformsSelected) ||
				(isMultibrandEnabled && (areMultiplePlatformsSelected || areMultipleBrandsSelected))) && (
				<MetricCard
					metric="lost_revenue_breakdown"
					cardRef={refs["lost_revenue_breakdown"]}
					setCurrTab={setCurrTab}
				>
					{(metric, metricCardInViewport) => (
						<React.Fragment>
							<MetricSection
								metric={metric}
								metricCardInViewport={metricCardInViewport}
								metricSectionApi={fetchLostRevenueBreakdownChart}
								state={revenueAnalytics}
								dependencies={[
									revenueAnalytics[metric]?.selectedChart,
									revenueAnalytics[metric]?.breakdownBy?.value,
									revenueAnalytics[metric]?.showComparison
								]}
							>
								{(
									{
										chartLoading,
										selectedChart = "bar",
										showComparison = false,
										breakdownBy,
										graphData,
										yScaleMax,
										maxValue
									},
									applDateFilter
								) => (
									<React.Fragment>
										<MetricHeader>
											<div className="header-left">
												<ChartMetric
													size="large"
													label="Lost Revenue Breakdown"
													description="Lost revenue categorisation based on reasons for order cancellations, segmented by acknowledgment status and source."
												/>
											</div>
											<div className="header-right">
												<ChartSelector
													selectedChart={selectedChart}
													options={["bar", "line"]}
													clickHandler={(chart) => {
														updateRevenueAnalyticsState(metric, {
															selectedChart: chart
														});
														trackEvent("analytics_chart_type_switched", {
															module: "Revenue",
															origin: metric,
															from: selectedChart,
															to: chart
														});
													}}
												/>
												<BreakdownByFilter
													currValue={breakdownBy}
													setFilter={(field, value) => {
														updateRevenueAnalyticsState(metric, {
															[field]: value
														});
														trackEvent("analytics_breakdown_selection", {
															module: "Revenue",
															metric: metric,
															breakdownType: value.label
														});
													}}
													isMultibrandEnabled={isMultibrandEnabled}
													readOnly={
														!areMultiplePlatformsSelected || !areMultipleBrandsSelected
													}
												/>
												<DownloadButton
													handleClick={(opt) => {
														if (opt == "csv") {
															!analyticsReportState?.lost_revenue_breakdown?.loading &&
																!revenueAnalytics?.lost_revenue_breakdown
																	?.tableLoading &&
																(
																	revenueAnalytics?.lost_revenue_breakdown
																		?.tabularData?.rows || []
																).length !== 0 &&
																fetchAndGenerateCSVReport(
																	fetchLostRevenueBreakdownTable,
																	"getTabularData",
																	["lost_revenue_breakdown", true]
																);
														} else if (opt == "png") {
															!chartLoading &&
																generateImage(
																	"lost_revenue_breakdown",
																	"Lost Revenue Breakdown",
																	selectedChart
																);
														}
													}}
													options={[
														{ value: "png", valueForDisplay: "Export Chart as PNG" },
														{ value: "csv", valueForDisplay: "Export Table as CSV" }
													]}
													tooltipText="Export"
													emptyData={
														(
															revenueAnalytics?.lost_revenue_breakdown?.tabularData
																?.rows || []
														).length == 0 &&
														revenueAnalytics?.lost_revenue_breakdown?.graphData?.[
															selectedChart
														].length == 0 &&
														!revenueAnalytics?.lost_revenue_breakdown?.tableLoading &&
														!chartLoading
													}
													classes={
														(
															revenueAnalytics?.lost_revenue_breakdown?.tabularData
																?.rows || []
														).length !== 0 &&
														revenueAnalytics?.lost_revenue_breakdown?.graphData?.[
															selectedChart
														].length !== 0 &&
														"center"
													}
													loading={
														analyticsReportState?.lost_revenue_breakdown?.loading ||
														revenueAnalytics?.lost_revenue_breakdown?.tableLoading ||
														chartLoading
													}
												/>
											</div>
										</MetricHeader>
										<ShowComparison
											loading={chartLoading}
											isChecked={showComparison}
											clickHandler={() =>
												updateRevenueAnalyticsState(metric, { showComparison: !showComparison })
											}
											module="Revenue"
											metric={metric}
											readOnly={!applDateFilter?.compare?.dateFilter}
										/>
										<div className="chart-container">
											{selectedChart === "bar" && (
												<Bar
													height={400}
													data={graphData?.bar}
													marginBottom={breakdownBy.value === "platform" ? 130 : 150}
													loading={chartLoading}
													colors={[...ANALYTICS_DEFAULT_COLORS]}
													keys={
														showComparison && applDateFilter?.compare?.dateFilter
															? ["current", "previous"]
															: [
																	"Merchant (Pre-Ack)",
																	"Merchant (Post-Ack)",
																	"Platform (Pre-Ack)",
																	"Platform (Post-Ack)"
																]
													}
													patternIds={[
														{
															match: {
																id: "previous"
															},
															id: "lines"
														}
													]}
													maxValue={maxValue}
													groupMode={
														showComparison && applDateFilter?.compare?.dateFilter
															? "grouped-stacked"
															: "stacked"
													}
													enableLegends={
														!showComparison || !applDateFilter?.compare?.dateFilter
													}
													customLegends={
														showComparison && applDateFilter?.compare?.dateFilter
													}
													padding={
														showComparison && applDateFilter?.compare?.dateFilter
															? 0.6
															: 0.8
													}
													innerPadding={20}
													applDateFilter={applDateFilter}
													indexBy="label"
													// axisBottomLegend={`${breakdownBy?.label}s`}
													axisBottomTickRotation={graphData?.bar?.length > 7 ? -35 : 0}
													suffixedYValues={true}
													currency={currencySymbol}
													// axisLeftLegend="Revenue"
												/>
											)}
											{selectedChart === "line" && (
												<Line
													height={400}
													data={graphData?.line || []}
													durationPreset={applDateFilter?.current?.dateFilter}
													// colors={[...ANALYTICS_DEFAULT_COLORS.slice(0, graphData?.line?.length)]}
													colors={{ datum: "color" }}
													showCompareInTooltip={
														showComparison && applDateFilter?.compare?.dateFilter
													}
													dashedLines={showComparison && applDateFilter?.compare?.dateFilter}
													dashedLineIndices={applDateFilter?.compare?.dateFilter ? "odd" : ""}
													showDashedLineOnHover={true}
													enableLegends={
														!showComparison || !applDateFilter?.compare?.dateFilter
													}
													loading={chartLoading}
													yScaleMax={yScaleMax}
													// axisBottomLegend="Date"
													// axisLeftLegend="Revenue"
													suffixedYValues={true}
													axisBottomTickRotation={
														graphData?.line?.[0]?.data?.length > 7 ? -45 : 0
													}
													currencySymbol={currencySymbol}
													legendItemWidth={breakdownBy.value === "platform" ? 100 : 130}
												/>
											)}
										</div>
									</React.Fragment>
								)}
							</MetricSection>
							<MetricSection
								metric={metric}
								metricCardInViewport={metricCardInViewport}
								metricSectionApi={fetchLostRevenueBreakdownTable}
								state={revenueAnalytics}
								dependencies={[
									revenueAnalytics[metric]?.breakdownBy?.value,
									revenueAnalytics[metric]?.sort
								]}
							>
								{({ tableLoading, breakdownBy, tabularData, sortedField, sort, hideColumns }) => (
									<CustomTable
										forAnalytics={true}
										loading={tableLoading}
										data={tabularData?.rows || []}
										columns={tabularData?.columns || []}
										sortList={(field) =>
											updateRevenueAnalyticsState(metric, {
												sort: {
													field: field,
													order: getSortOrder(sort, { field })
												},
												sortedField: field
											})
										}
										sortedField={sortedField}
										sortedOrder={sort?.order}
										classes="lost-revenue-breakdown-table-container"
										content={`${breakdownBy?.label || "result"}s`}
										currencySymbol={currencySymbol}
										viewAction="View Lost Orders Breakdown"
										viewActionVariant="link"
										viewActionHref={`/analytics/orders?metric=lost_orders_breakdown${
											query.get("filters") ? `&filters=${query.get("filters")}` : ""
										}`}
										handleViewAction={() =>
											trackEvent("analytics_link_text_clicked", {
												module: "Revenue",
												type: "Module",
												origin: metric,
												destination: "lost_orders_breakdown"
											})
										}
										hideColumns={hideColumns || []}
									/>
								)}
							</MetricSection>
						</React.Fragment>
					)}
				</MetricCard>
			)}
			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
			/>
		</div>
	);
};
export default connect((store) => ({
	revenueAnalytics: store.revenueAnalytics,
	analyticsFiltersState: store.analyticsFiltersState,
	currencySymbol: store.login.loggedInbizDetail.currencySymbol,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	analyticsReportState: store.analyticsReportState
}))(Revenue);

import React, { useState, useEffect, useMemo } from "react";

// components
import { FormContainer } from "../../containers/Meraki";
import { Filters } from "./LocationsAndMenu";
import { Button } from "../_commons/Button";
import { Modal } from "../_commons/Modal";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { CheckBox } from "../_commons/CheckBox";
import { SelectFilter } from "../_commons/SelectFilter";
import { CustomTable } from "../_commons/CustomTable";
import { Paginator } from "../_commons/Paginator";
import { ButtonIcon } from "../_commons/ButtonIcon";
import Breadcrumbs from "../_commons/Breadcrumbs";
import BrandSelection from "./BrandSelection";
import ReviewLocationsMapping from "./ReviewLocationsMapping";

// third party
import history from "../../history";
import { connect } from "react-redux";
import { debounce } from "lodash";

// store
import { store } from "../../store/configureStore";

// utils
import { capitaliseText } from "../../atlas-utils";

// sevices
import NotificationServices from "../../services/NotificationService";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchMinimalLocationsList, handleUpdateChefzStoreConfig } from "../../actions/dspIntegration";
import { fetchCitiesList } from "../../actions/locations";

// constants
import { DSP_INTEGRATION_INITIAL_STATE } from "../../reducers/dspIntegration";
import { PLATFORM_MAP } from "../Hub/Integrations";
import { ONBOARDING_FLOWS, THE_CHEFZ_CITIES_MAP, countrySpecificPopularCities } from "../../client-config";

const LocationsMapping = ({
	match,
	editMode = false,
	dspIntegration,
	renderBrandSelection,
	isMultibrandEnabled = false,
	tempBrandSelected = null,
	setTempBrandSelected,
	flowData,
	stores,
	brands,
	bizCountry
}) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [validations, setValidations] = useState({});
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [locationsMappingSuccess, setLocationsMappingSuccess] = useState(false);
	const isOnboardingFlowEnabled = flowData?.name === ONBOARDING_FLOWS.ATLAS_ONBOARDING_FLOW;
	const country = bizCountry.toLowerCase() === "united kingdom" ? "uk" : bizCountry.toLowerCase();
	const INIT_CITY_ARRAY_VALUES = [...(countrySpecificPopularCities[country] ?? [])];
	const [citiesListState, setCitiesListState] = useState({
		loading: false,
		values: INIT_CITY_ARRAY_VALUES
	});
	const {
		loading,
		limit,
		offset,
		data,
		platform,
		selectedTab,
		searchFieldValue,
		appliedFilters,
		mappedLocations,
		associationUpdates,
		selectedBrand
	} = dspIntegration;
	// const storeStatusCount = useMemo(() => {
	//     return {
	//         all: data?.mappedStoresCount + data?.activeStoresCount + data?.unmappedStoresCount,
	//         mapped: data?.mappedStoresCount,
	//         active: data?.activeStoresCount,
	//         unmapped: data?.unmappedStoresCount,
	//         failed: data?.mappedStoresCount - data?.activeStoresCount
	//     }
	// }, [data?.mappedStoresCount, data?.activeStoresCount, data?.unmappedStoresCount]);

	const updateDspIntegrationState = (payload) => {
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload
		});
	};

	useEffect(() => {
		if (isMultibrandEnabled && !selectedBrand && !editMode) {
			updateDspIntegrationState({ selectedBrand: brands?.items?.[0] || null });
		}
	}, [brands?.items]);

	useEffect(() => {
		if (tempBrandSelected) {
			handleBrandSelection();
		}
	}, [tempBrandSelected]);

	useEffect(() => {
		setLoading(true);
		fetchMinimalLocationsList();
		setLoading(false);
	}, [platform, limit, offset, appliedFilters, selectedBrand, selectedTab]);

	const resetDspIntegrationState = (selectedTab = 1, props = {}) => {
		updateDspIntegrationState({
			selectedTab,
			offset: 0,
			limit: 10,
			currentFilters: DSP_INTEGRATION_INITIAL_STATE.currentFilters,
			appliedFilters: DSP_INTEGRATION_INITIAL_STATE.appliedFilters,
			searchFieldValue: "",
			associationUpdates: {},
			mappedLocations: {},
			...props
		});
	};

	const handleModalAction = (primary = false) => {
		updateDspIntegrationState({
			associationUpdates: {},
			mappedLocations: {},
			selectedTab: 1
		});
		setValidations({});
		if (primary && editMode) {
			history.push(match.url?.replace("/edit", ""));
		}
		if (primary && !editMode) {
			history.push(`${match?.url}/edit`);
		}
		setLocationsMappingSuccess(false);
		setModalOpen(false);
	};

	const handleBrandSelection = () => {
		resetDspIntegrationState(1, { selectedBrand: tempBrandSelected });
		setTempBrandSelected(null);
	};

	const handlePagination = (page) => {
		// set new offset
		const offset = (page - 1) * limit;
		updateDspIntegrationState({
			offset
		});
		setIsCheckedAll(false);
	};

	const handlePageSize = async (field, size) => {
		// set new limit
		if (size && size?.value !== limit) {
			updateDspIntegrationState({
				[field]: size.value,
				offset: 0
			});
			setIsCheckedAll(false);
		}
	};

	const handleStatusFilter = (field, value) => {
		const filters = {
			...appliedFilters
		};
		filters[field] = value;
		updateDspIntegrationState({
			currentFilters: filters,
			appliedFilters: filters,
			offset: 0
		});
	};

	const handleCheck = (record, toAssociate) => {
		let newAssociationUpdates = { ...associationUpdates };
		let updatedValidations = { ...validations };
		const config = record?.chefzStoreConfig || {};
		if (toAssociate) {
			newAssociationUpdates[record?.id] = {
				locationId: record?.id,
				...config
			};
		} else {
			delete newAssociationUpdates[record?.id];
			delete updatedValidations[record?.id];
			setIsCheckedAll(false);
		}
		// update dsp integration state
		updateDspIntegrationState({
			associationUpdates: newAssociationUpdates
		});
		setValidations(updatedValidations);
	};

	const handleCheckAll = (e, toCheckAll) => {
		let newAssociationUpdates = { ...associationUpdates };
		let updatedValidations = { ...validations };
		if (toCheckAll) {
			(data?.objects ?? []).forEach((loc) => {
				const config = loc?.chefzStoreConfig || {};
				newAssociationUpdates[loc?.id] = {
					locationId: loc?.id,
					...config,
					...(newAssociationUpdates[loc?.id] || {})
				};
			});
		} else {
			(data?.objects ?? []).forEach((loc) => {
				delete newAssociationUpdates[loc?.id];
				delete updatedValidations[loc?.id];
			});
		}
		setIsCheckedAll(toCheckAll);
		// update dsp integration state
		updateDspIntegrationState({
			associationUpdates: newAssociationUpdates
		});
		setValidations(updatedValidations);
	};

	const handleAssociationUpdates = (record, field, value) => {
		// update association updates
		const config = record?.chefzStoreConfig || {};
		let newAssociationUpdates = { ...associationUpdates };
		let updatedValidations = { ...validations };
		newAssociationUpdates[record?.id] = {
			locationId: record?.id,
			...config,
			...(newAssociationUpdates[record?.id] || {}),
			[field]: value
		};
		updatedValidations[record?.id] = {
			...(updatedValidations[record?.id] || {}),
			[field]: ""
		};
		// update dsp integration state
		updateDspIntegrationState({
			associationUpdates: newAssociationUpdates
		});
		setValidations(updatedValidations);

		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const applySearchFilter = debounce(() => {
		fetchMinimalLocationsList();
		setIsCheckedAll(false);
	}, 500);

	const handleSearchFilter = (field, value) => {
		updateDspIntegrationState({
			[field]: value,
			offset: 0
		});

		applySearchFilter();
	};

	const handleCreateLocation = () => {
		history.push(`/locations/new`);
	};

	const handleCancel = (reset = false) => {
		if (isMultibrandEnabled) {
			if (selectedTab === 1 || reset) {
				if (reset) {
					updateDspIntegrationState({
						associationUpdates: {},
						mappedLocations: {},
						selectedTab: 1
					});
				}
				history.push(reset ? "/hub/integrations" : match.url?.replace("/edit", ""));
				return;
			}
			if (selectedTab === 2) {
				if (Object.keys(associationUpdates)?.length === 0) {
					updateDspIntegrationState({
						associationUpdates: {},
						mappedLocations: {},
						selectedTab: 1
					});
					return;
				}
				// show modal confirmation to discard association updates
				setModalOpen(true);
				return;
			}
		}
		if (selectedTab === 1 || reset) {
			if (Object.keys(associationUpdates)?.length === 0 || reset) {
				updateDspIntegrationState({
					associationUpdates: {},
					mappedLocations: {},
					selectedTab: 1
				});
				history.push(reset ? "/hub/integrations" : match.url?.replace("/edit", ""));
				return;
			}
			// show modal confirmation to discard association updates
			setModalOpen(true);
			return;
		}
	};

	const handleSubmit = async () => {
		if (isMultibrandEnabled && selectedTab === 1) {
			updateDspIntegrationState({ selectedTab: 2 });
			return;
		}
		if ((!isMultibrandEnabled && selectedTab === 1) || (isMultibrandEnabled && selectedTab === 2)) {
			if (Object.keys(associationUpdates)?.length > 0) {
				// validate if all association updates have the required fields filled.
				let isValid = true;
				let updatedValidations = { ...validations };
				const validationMessage = "This field is required";
				for (let loc in associationUpdates) {
					if (!associationUpdates[loc]?.nameAr) {
						isValid = false;
						updatedValidations[loc] = {
							...(updatedValidations[loc] || {}),
							nameAr: validationMessage
						};
					}
					if (!associationUpdates[loc]?.contact) {
						isValid = false;
						updatedValidations[loc] = {
							...(updatedValidations[loc] || {}),
							contact: validationMessage
						};
					}
					if (!associationUpdates[loc]?.email) {
						isValid = false;
						updatedValidations[loc] = {
							...(updatedValidations[loc] || {}),
							email: validationMessage
						};
					}
					if (!associationUpdates[loc]?.city) {
						isValid = false;
						updatedValidations[loc] = {
							...(updatedValidations[loc] || {}),
							city: validationMessage
						};
					}
					if (!associationUpdates[loc]?.latitude) {
						isValid = false;
						updatedValidations[loc] = {
							...(updatedValidations[loc] || {}),
							latitude: validationMessage
						};
					}
					if (!associationUpdates[loc]?.longitude) {
						isValid = false;
						updatedValidations[loc] = {
							...(updatedValidations[loc] || {}),
							longitude: validationMessage
						};
					}
				}
				if (!isValid) {
					setValidations(updatedValidations);
					NotificationServices.pushNotification({
						message: "Please recheck details for selected locations",
						timeout: 5000,
						type: "error",
						isClosable: true,
						theme: "dark"
					});
					return;
				} else {
					setValidations({});
				}
				// proceed once validation is done
				setLoading(true);
				const status = await handleUpdateChefzStoreConfig();
				if (status?.success) {
					setLocationsMappingSuccess(true);
					setLoading(false);
				} else if (!status?.success && status?.messages?.length && status?.messages?.[0]?.field) {
					// handle errors and show validation messages
					const messages = status?.messages || [];
					let updatedValidations = { ...validations };
					const locations = Object.keys(associationUpdates);
					messages.forEach((msg) => {
						const validation = msg.field.split("#");
						updatedValidations[locations[validation[0]]] = {
							...(updatedValidations[locations[validation[0]]] ?? {}),
							[validation[1]]: msg.message
						};
					});
					setValidations(updatedValidations);
					setLoading(false);
				} else {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Something went wrong.",
							timeout: 2000,
							error: true
						}
					});
					setLoading(false);
				}
			}
		}
	};

	const searchCities = async (searchCity) => {
		if (searchCity === "") {
			setCitiesListState((current) => ({ ...current, values: INIT_CITY_ARRAY_VALUES }));
			return;
		}
		setCitiesListState((current) => ({ ...current, loading: true }));
		const citySearchResult = await fetchCitiesList(searchCity);

		if (Array.isArray(citySearchResult?.data)) {
			const updatedCitiesList = [...citySearchResult.data];
			setCitiesListState((current) => ({
				...current,
				loading: false,
				values: [
					...updatedCitiesList.map((city) => ({
						value: `${city.name}`,
						valueForDisplay: `${city.name}, ${city.state_name}, ${city.country_name}`
					}))
				]
			}));
		} else {
			setCitiesListState((current) => ({ ...current, loading: false }));
		}
	};
	const searchCitiesDebounced = debounce((searchCity) => searchCities(searchCity), 500);

	const handleFormSubmitTitle = () => {
		if (isMultibrandEnabled) {
			if (selectedTab === 1) {
				return "Continue to Map Locations";
			}
			if (selectedTab === 2) {
				return "Submit";
			}
		}
		if (selectedTab === 1) {
			return "Submit";
		}
	};

	const handleDisableFormSubmit = () => {
		if (isMultibrandEnabled && selectedTab === 1 && selectedBrand) {
			return false;
		}
		if (isMultibrandEnabled && selectedTab === 1 && !selectedBrand) {
			return true;
		}
		if (data?.objects.length === 0) return true;

		if (loading) return true;

		if (
			((!isMultibrandEnabled && selectedTab === 1) || (isMultibrandEnabled && selectedTab === 2)) &&
			Object.keys(associationUpdates)?.length > 0
		) {
			return false;
		}
		return true;
	};

	const connectedLinks = editMode
		? [
				{
					to: `/hub/integrations`,
					title: `Integrations`
				},
				{
					to: `/hub/integrations/${match?.params?.dsp}`,
					title: `${capitaliseText(PLATFORM_MAP[match?.params?.dsp] || match?.params?.dsp)}`,
					onClick: () => {
						resetDspIntegrationState();
					}
				}
			]
		: [
				{
					to: `/hub/integrations`,
					title: `Integrations`
				}
			];

	if (editMode && locationsMappingSuccess) {
		return (
			<div className="dsp-locations-mapping">
				<div className="dsp-location-container">
					<Breadcrumbs connectedLinks={connectedLinks} />
					<div className="wrapper-dsp">
						<div className="dsp-locations-mapping">
							<img src="/assets/empty_states/location-mapping.svg" alt="" />
							<div className="header-text">Request to go live submitted!</div>
							<div className="no-items-placeholder">
								Your request to go live on storefronts will now be initiated to{" "}
								{capitaliseText(PLATFORM_MAP[platform] || platform)}
							</div>
							<div className="no-items-placeholder">
								<ButtonIcon icon="check" color="#AAAAAA" />
								Requests are automatically sent to the Online Ordering Platform
							</div>
							<div className="actions">
								<Button clickHandler={() => handleModalAction(true)}>
									<span>View mapping status</span>
								</Button>
								<Button type="secondary" clickHandler={() => handleModalAction(true)}>
									<span>Go live on other {isMultibrandEnabled ? "brands /" : ""} platforms</span>
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="dsp-locations-mapping">
			<FormContainer
				cancel={handleCancel}
				classes="dsp-location-container"
				footerClass=""
				cancelTitle="Back"
				submit={handleSubmit}
				submitLoading={isLoading}
				disabled={isLoading}
				disableSubmit={handleDisableFormSubmit()}
				submitTitle={handleFormSubmitTitle()}
				hideSubmitAction={false}
				submitClass={isMultibrandEnabled && selectedTab === 1 ? "W(200px)" : ""}
				hideActions={!editMode}
			>
				<Breadcrumbs connectedLinks={connectedLinks} />
				{editMode ? (
					<div className="credits-section-header hub-config-header">
						<div className="header-text">
							<div className="title">
								Select locations to to take live on {capitaliseText(PLATFORM_MAP[platform] || platform)}
							</div>
							<div className="subtitle">
								Add mandatory details to go live on {capitaliseText(PLATFORM_MAP[platform] || platform)}{" "}
								for the selected locations
							</div>
						</div>
					</div>
				) : (
					<div className="credits-section-header hub-config-header">
						<div className="header-text">
							<div className="title">
								Request to go live on {capitaliseText(PLATFORM_MAP[platform] || platform)}
							</div>
							<div className="subtitle">
								Manage location mapping requests for all brands from{" "}
								{capitaliseText(PLATFORM_MAP[platform] || platform)} here
							</div>
						</div>
						<div className="header-action-button">
							{data?.objects.length > 0 && (
								<Button
									clickHandler={() => handleModalAction(true)}
									classes={data?.count === 0 ? "disabled" : ""}
								>
									{/* <img src="/assets/icons/icon-edit--filled-white.svg" alt="" /> */}
									<span>Create Request</span>
								</Button>
							)}
						</div>
					</div>
				)}
				{!editMode && (
					<div className="form-content">
						<Filters
							searchFieldValue={searchFieldValue}
							handleSearchFilter={handleSearchFilter}
							loading={loading}
							statusFilter={data?.filters?.find((f) => f.field === "chefz_status")}
							handleStatusFilter={handleStatusFilter}
							statusValue={appliedFilters["chefz_status"] || null}
							renderBrandSelection={renderBrandSelection}
							isMultibrandEnabled={isMultibrandEnabled}
							editMode={editMode}
						/>
						{!editMode &&
						!loading &&
						data?.objects?.length === 0 &&
						(!appliedFilters?.state || appliedFilters?.state?.value === "all") ? (
							<div className="wrapper-dsp">
								<div className="dsp-locations-none">
									<img src="/assets/empty-states/verification-in-progress.svg" alt="" />
									<div className="header-text">No storefronts mapped</div>
									<div className="no-items-placeholder">
										Start taking your locations live on{" "}
										{capitaliseText(PLATFORM_MAP[platform] || platform)} to view{" "}
										{capitaliseText(PLATFORM_MAP[platform] || platform)} orders on UrbanPiper.
									</div>
									<Button clickHandler={() => handleCreateLocation()}>
										<span>Create Storefronts</span>
									</Button>
								</div>
							</div>
						) : (
							<div>
								{/* <div className="locations-status-count">
                                    <div className="container">
                                        <div className="header">Storefront Status Overview</div>
                                        <div className="status-overview">
                                            <div className="status-item">
                                                <div className="status-title">Storefronts Live</div>
                                                {loading ? (
                                                    <div className="shimmer W(100px) H(20px)"></div>
                                                ) : (
                                                    <div className="status-value">
                                                        {storeStatusCount?.active}/{storeStatusCount?.all}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
								<div className="primary-location-table">
									<CustomTable
										bordered={true}
										loading={loading}
										data={data?.objects || []}
										columns={getColumns(
											match?.params?.dsp,
											editMode,
											isMultibrandEnabled,
											selectedBrand,
											citiesListState,
											searchCitiesDebounced
										)}
										classes="locations-mapping-table-container no-top-margin"
										content="locations"
										stores={stores}
										showLoadingForce={loading}
										mappedLocations={mappedLocations}
										platform={platform}
										validations={validations}
										hideColumns={[
											"translation",
											"contact-number",
											"contact-email",
											"city",
											"latitude",
											"longitude"
										]}
									/>
									<Paginator
										limit={limit}
										offset={offset}
										loading={loading}
										count={data?.count || 0}
										goToPage={handlePagination}
										setPageSize={handlePageSize}
										showPageSize={true}
									/>
								</div>
							</div>
						)}
					</div>
				)}
				{editMode && (
					<React.Fragment>
						{selectedTab === 1 &&
							(isMultibrandEnabled ? (
								<BrandSelection
									dspIntegration={dspIntegration}
									updateDspIntegrationState={updateDspIntegrationState}
								/>
							) : (
								<ReviewLocationsMapping
									dsp={match?.params?.dsp}
									dspIntegration={dspIntegration}
									stores={stores}
									handleSearchFilter={handleSearchFilter}
									handleStatusFilter={handleStatusFilter}
									handlePagination={handlePagination}
									handlePageSize={handlePageSize}
									handleAssociationUpdates={handleAssociationUpdates}
									editMode={editMode}
									handleCheck={handleCheck}
									handleCheckAll={handleCheckAll}
									isCheckedAll={isCheckedAll}
									mappedLocations={mappedLocations}
									isMultibrandEnabled={isMultibrandEnabled}
									renderBrandSelection={renderBrandSelection}
									citiesListState={citiesListState}
									searchCitiesDebounced={searchCitiesDebounced}
									validations={validations}
								/>
							))}
						{selectedTab === 2 && isMultibrandEnabled && (
							<ReviewLocationsMapping
								dsp={match?.params?.dsp}
								dspIntegration={dspIntegration}
								stores={stores}
								handleSearchFilter={handleSearchFilter}
								handleStatusFilter={handleStatusFilter}
								handlePagination={handlePagination}
								handlePageSize={handlePageSize}
								handleAssociationUpdates={handleAssociationUpdates}
								editMode={editMode}
								handleCheck={handleCheck}
								handleCheckAll={handleCheckAll}
								isCheckedAll={isCheckedAll}
								mappedLocations={mappedLocations}
								isMultibrandEnabled={isMultibrandEnabled}
								renderBrandSelection={renderBrandSelection}
								citiesListState={citiesListState}
								searchCitiesDebounced={searchCitiesDebounced}
								validations={validations}
							/>
						)}
					</React.Fragment>
				)}
			</FormContainer>
			<Modal
				isOpen={modalOpen && editMode}
				close={handleModalAction}
				title={`${editMode ? "Discard" : "Edit"} Storefront Mapping Updates`}
				showSubmitAction={true}
				submitTitle={editMode ? "Confirm" : "Continue"}
				submitAction={() => handleModalAction(true)}
				showCancelAction={true}
				cancelTitle="Cancel"
				showCloseIcon={false}
			>
				{editMode
					? "Storefront mapping updates will be discarded, are you sure you want to go back?"
					: "Are you sure you want to edit Storefront and Menu Associations?"}
			</Modal>
		</div>
	);
};
const mapStateToProps = (store) => ({
	dspIntegration: store.dspIntegration,
	stores: store.configItems.stores,
	flowData: store.atlasOnboardingState.flowData,
	bizCountry: store.login.loggedInbizDetail.country
});
export default connect(mapStateToProps)(LocationsMapping);

export const getColumns = (
	dsp,
	editMode = false,
	isMultibrandEnabled = false,
	selectedBrand = null,
	citiesListState,
	searchCitiesDebounced
) => {
	const columns = [
		{
			name: "Locations",
			field: "dsp-stores",
			render: (record, i, rest) => {
				const status = rest?.associationUpdates?.[record?.id]?.locationId || false;
				return (
					<div className="table-cell dsp-stores" key={i}>
						<div className="location-name">
							{editMode && (
								<CheckBox
									checked={status}
									clickHandler={(e) => rest.handleCheck(record, !status)}
									title={record?.name || "--"}
								/>
							)}
							{record?.name || "--"}
						</div>
						{editMode && <div className="location-id">Location ID: {record?.id}</div>}
					</div>
				);
			}
		},
		{
			name: "Translation",
			field: "translation",
			isRequired: true,
			render: (record, i, rest) => {
				const currValue = rest?.associationUpdates?.hasOwnProperty(record?.id)
					? rest?.associationUpdates?.[record?.id]?.nameAr
					: record?.chefzStoreConfig?.nameAr;
				return (
					<div className="table-cell translation" key={i}>
						<InputWithLabel
							value={currValue}
							onChange={(e) => rest.handleAssociationUpdates(record, "nameAr", e.target.value)}
							validationMessage={rest?.validations?.[record.id]?.nameAr || ""}
						/>
					</div>
				);
			}
		},
		{
			name: "Unique Contact Number",
			field: "contact-number",
			isRequired: true,
			render: (record, i, rest) => {
				const currValue = rest?.associationUpdates?.hasOwnProperty(record?.id)
					? rest?.associationUpdates?.[record?.id]?.contact
					: record?.chefzStoreConfig?.contact;
				return (
					<div className="table-cell contact-number" key={i}>
						<InputWithLabel
							value={currValue}
							type="tel"
							onChange={(e) => rest.handleAssociationUpdates(record, "contact", e.target.value)}
							validationMessage={rest?.validations?.[record.id]?.contact || ""}
						/>
					</div>
				);
			}
		},
		{
			name: "Unique Email",
			field: "contact-email",
			isRequired: true,
			render: (record, i, rest) => {
				const currValue = rest?.associationUpdates?.hasOwnProperty(record?.id)
					? rest?.associationUpdates?.[record?.id]?.email
					: record?.chefzStoreConfig?.email;
				return (
					<div className="table-cell contact-email" key={i}>
						<InputWithLabel
							value={currValue}
							onChange={(e) => rest.handleAssociationUpdates(record, "email", e.target.value)}
							validationMessage={rest?.validations?.[record.id]?.email || ""}
						/>
					</div>
				);
			}
		},
		{
			name: "City",
			field: "city",
			isRequired: true,
			render: (record, i, rest) => {
				const currValue = rest?.associationUpdates?.hasOwnProperty(record?.id)
					? rest?.associationUpdates?.[record?.id]?.city
					: record?.chefzStoreConfig?.city;
				return (
					<div className="table-cell city" key={i}>
						<SelectFilter
							field="city"
							currValue={currValue || null}
							options={THE_CHEFZ_CITIES_MAP}
							labelKey="valueForDisplay"
							valueKey="value"
							setFilter={(field, value) => rest.handleAssociationUpdates(record, field, value)}
							// isAsync
							// isLoading={citiesListState.loading}
							// handleSearch={searchCitiesDebounced}
							validationMessage={rest?.validations?.[record.id]?.city || ""}
						/>
					</div>
				);
			}
		},
		{
			name: "Latitude",
			field: "latitude",
			isRequired: true,
			render: (record, i, rest) => {
				const currValue = rest?.associationUpdates?.hasOwnProperty(record?.id)
					? rest?.associationUpdates?.[record?.id]?.latitude
					: record?.chefzStoreConfig?.latitude;
				return (
					<div className="table-cell latitude" key={i}>
						<InputWithLabel
							value={currValue}
							onChange={(e) => rest.handleAssociationUpdates(record, "latitude", e.target.value)}
							validationMessage={rest?.validations?.[record.id]?.latitude || ""}
						/>
					</div>
				);
			}
		},
		{
			name: "Longitude",
			field: "longitude",
			isRequired: true,
			render: (record, i, rest) => {
				const currValue = rest?.associationUpdates?.hasOwnProperty(record?.id)
					? rest?.associationUpdates?.[record?.id]?.longitude
					: record?.chefzStoreConfig?.longitude;
				return (
					<div className="table-cell longitude" key={i}>
						<InputWithLabel
							value={currValue}
							onChange={(e) => rest.handleAssociationUpdates(record, "longitude", e.target.value)}
							validationMessage={rest?.validations?.[record.id]?.longitude || ""}
						/>
					</div>
				);
			}
		},
		{
			name: "Status",
			field: "status",
			render: (record, i) => {
				return (
					<div className="table-cell status" title={record?.chefzState?.status || "Unmapped"} key={i}>
						<div className={"tag " + (record?.chefzState?.status?.toLowerCase() || "unmapped")}>
							{record?.chefzState?.status || "Unmapped"}
						</div>
					</div>
				);
			}
		}
	];
	return columns;
};

import React from "react";

// components
import { chartTooltip } from "./Tooltip";
import Loader from "./Loader";

// third party
import { ResponsiveLine } from "@nivo/line";
import { line, curveMonotoneX, curveLinear } from "d3-shape";
import he from "he";

// utils
import { commifyNumbers, getSuffixedNumber } from "../../atlas-utils";

// actinos
import { getReadableTimestamp } from "../../actions/analytics";

// constants
import { ANALYTICS_DEFAULT_COLORS } from "../../client-config";

const Line = ({
	height = 600,
	data = [],
	metric,
	colors = [],
	loading = false,
	yScaleMin = 0,
	yScaleMax = "auto",
	yScaleStacked = false,
	marginTop = 25,
	marginBottom = 100,
	marginLeft = 60,
	marginRight = 25,
	xFormat,
	yFormat,
	curveType = "monotoneX",
	durationPreset,
	axisBottomLegend,
	axisLeftTickValues,
	axisBottomTickRotation = 0,
	axisLeftLegend,
	axisLeftLegendOffset = -50,
	lineWidth = 2,
	enableLegends = true,
	legendItemWidth = 100,
	enableArea = false,
	dashedLines = false,
	dashedLineIds = [],
	dashedLineIndices = "", // odd, even
	showDashedLineOnHover = false,
	enablePoints = false,
	pointSize = 4,
	enableSlices = false, // false, x, y
	sliceTooltip,
	showCompareInTooltip = false,
	renderTooltipYName,
	tooltipPosition = "top", // sides
	hasDate = true,
	currency,
	currencySymbol = "",
	units = "",
	suffixedYValues = false,
	currencyXValues = false,
	placeholder = "No data available",
	customLegends = false,
	handleSelectCustomLegends = () => {},
	legendTranslateY = 80,
	customAxisLeft = false,
	customAxisLeftCondition
}) => {
	const handleDashedLines = (props) => {
		const { series, xScale, yScale, currentPoint } = props;
		const lineGenerator = line()
			.x(({ data }) => xScale(data.x))
			.y(({ data }) => yScale(yScaleStacked ? data.yStacked : data.y))
			.curve(curveType === "monotoneX" ? curveMonotoneX : curveLinear);

		return series.map(({ id, data, color }, i) =>
			!showDashedLineOnHover ||
			(dashedLineIds.length > 0 && !dashedLineIds.includes(id)) ||
			(dashedLineIndices === "odd" && i % 2 === 0) ||
			(dashedLineIndices === "even" && i % 2 !== 0) ||
			id.includes(currentPoint?.serieId) ? (
				<path
					key={`${id}_${color}`}
					d={lineGenerator(data)}
					fill="none"
					stroke={color}
					strokeOpacity={
						!showDashedLineOnHover ||
						!currentPoint ||
						currentPoint?.serieId?.includes(id) ||
						id.includes(currentPoint?.serieId)
							? 1
							: 0.1
					}
					style={{
						strokeDasharray:
							(dashedLineIds.length > 0 && dashedLineIds.includes(id)) ||
							(dashedLineIndices === "odd" && i % 2 !== 0) ||
							(dashedLineIndices === "even" && i % 2 === 0)
								? "7, 7"
								: "0, 0",
						strokeWidth: "2px"
					}}
				/>
			) : null
		);
	};

	const handlePoints = (props) => {
		const { currentPoint, currentSlice, points } = props;

		let comparePoint = undefined;
		if (showCompareInTooltip) {
			comparePoint = points.find(
				(pt) =>
					currentPoint?.id !== pt.id &&
					currentPoint?.id?.split(".")?.[1] === pt.id?.split(".")?.[1] &&
					currentPoint?.serieColor === pt.serieColor
			);
		}

		// it will show the current point only on one line at hovered axis (and compare point)
		if (currentPoint) {
			return (
				<g>
					<circle fill={currentPoint.serieColor} r={pointSize} cx={currentPoint.x} cy={currentPoint.y} />
					{comparePoint !== undefined && (
						<circle fill={comparePoint.serieColor} r={pointSize} cx={comparePoint.x} cy={comparePoint.y} />
					)}
				</g>
			);
		}

		// it will show all the current points on all lines at hovered axis
		if (currentSlice?.points?.length) {
			return currentSlice.points.map((currPoint) => (
				<g>
					<circle fill={currPoint.serieColor} r={pointSize} cx={currPoint.x} cy={currPoint.y} />
				</g>
			));
		}
	};

	const handleAreas = (props) => {
		const { series, areaGenerator, xScale, yScale, areaOpacity } = props;
		return series.map(({ id, data, color }, i) => (
			<>
				<defs aria-hidden="true">
					<linearGradient id={`gradientA.${i}.${color}`} x1="0" x2="0" y1="0" y2="1">
						<stop
							offset="0%"
							stopColor={color}
							stopOpacity={dashedLineIds.includes(id) ? "0" : "0.5"}
						></stop>
						<stop offset="100%" stopColor={color} stopOpacity="0"></stop>
					</linearGradient>
				</defs>
				<path
					key={id}
					d={areaGenerator(
						data.map((d) => ({
							x: xScale(d.data.x),
							y: yScale(d.data.y)
						}))
					)}
					fill={`url(#gradientA.${i}.${color})`}
					fillOpacity={areaOpacity}
				/>
			</>
		));
	};

	const customLegendSymbolShape = (props) => {
		const { id, x, y, fill, borderWidth, borderColor } = props;
		return (
			<g>
				{dashedLines && dashedLineIds.length > 0 && dashedLineIds?.includes(id) && (
					<defs aria-hidden="true">
						<pattern id="lines-pattern" width="12" height="12" patternUnits="userSpaceOnUse">
							<rect width="12" height="12" fill="#ffffff" stroke={borderColor} strokeWidth="0"></rect>
							<path
								d="M 0 0 L 0 12 M 12 0 L 12 12"
								strokeWidth="7"
								stroke={fill}
								strokeLinecap="square"
							></path>
						</pattern>
					</defs>
				)}
				<rect
					x={x - 15}
					y={y + 6}
					fill={
						dashedLines && dashedLineIds.length > 0 && dashedLineIds?.includes(id)
							? "url(#lines-pattern)"
							: fill
					}
					strokeWidth={borderWidth}
					stroke={borderColor}
					width={30}
					height={lineWidth}
				/>
			</g>
		);
	};

	return (
		<div className={"nivo-chart-line" + (data.length > 0 && loading ? " disabled" : "")} style={{ height: height }}>
			{data.length > 0 && (
				<ResponsiveLine
					data={data}
					colors={colors ? colors : ANALYTICS_DEFAULT_COLORS}
					margin={{
						top: marginTop,
						right: marginRight,
						bottom: enableLegends
							? axisBottomLegend
								? marginBottom
								: marginBottom - 20
							: axisBottomLegend
								? marginBottom - 20
								: marginBottom - 30,
						left: axisLeftLegend ? marginLeft : marginLeft - 10
					}}
					xScale={{ type: "point" }}
					yScale={{
						type: "linear",
						min: yScaleMin,
						max: yScaleMax,
						stacked: yScaleStacked,
						reverse: false
					}}
					xFormat={xFormat}
					yFormat={yFormat}
					curve={curveType}
					axisTop={null}
					axisRight={null}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: axisBottomTickRotation,
						legend: axisBottomLegend,
						legendOffset: 60,
						legendPosition: "middle",
						format: hasDate
							? (value) => getReadableTimestamp(value, durationPreset)
							: currencyXValues && currency
								? (value) => `${he.unescape(currency)}${commifyNumbers(Math.round(value))}`
								: ""
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 10,
						tickRotation: 0,
						tickValues: yScaleMax === 1 ? 1 : axisLeftTickValues,
						legend: axisLeftLegend,
						legendOffset: axisLeftLegendOffset,
						legendPosition: "middle",
						fontSize: 30,
						format: (value) =>
							customAxisLeft
								? customAxisLeftCondition(value)
								: suffixedYValues || value >= 10000
									? getSuffixedNumber(value)
									: units
										? `${value}${units}`
										: value
					}}
					lineWidth={lineWidth}
					enablePoints={enablePoints}
					pointSize={pointSize}
					pointColor={{ theme: "background" }}
					pointBorderWidth={pointSize}
					pointBorderColor={{ from: "serieColor" }}
					enableArea={enableArea}
					areaOpacity={0.4}
					useMesh={true}
					enableSlices={enableSlices}
					crosshairType="x"
					sliceTooltip={sliceTooltip}
					legends={
						enableLegends
							? [
									{
										anchor: "bottom",
										direction: "row",
										justify: false,
										translateX: 0,
										translateY: axisBottomLegend ? 90 : legendTranslateY,
										itemsSpacing: 10,
										itemDirection: "left-to-right",
										itemWidth: legendItemWidth,
										itemHeight: 15,
										itemOpacity: 1,
										symbolSize: 12,
										symbolShape: customLegendSymbolShape,
										...(customLegends ? { data: [...handleSelectCustomLegends(data)] } : {})
									}
								]
							: []
					}
					fill={[
						{
							id: "gradientA",
							match: "*"
						}
					]}
					defs={[
						{
							colors: [
								{
									color: "inherit",
									offset: 0,
									opacity: 0.5
								},
								{
									color: "inherit",
									offset: 100,
									opacity: 0
								}
							],
							id: "gradientA",
							type: "linearGradient"
						}
					]}
					theme={{
						grid: {
							line: {
								// cartesian grid
								strokeWidth: 1,
								strokeDasharray: "4 4"
							}
						},
						axis: {
							domain: {
								// x and y axis lines
								line: {
									stroke: "#dddddd",
									strokeWidth: 1
								}
							},
							ticks: {
								// x and y axis ticks
								text: {
									fontSize: 14
								},
								line: {
									stroke: "#dddddd"
								}
							},
							legend: {
								// x and Y axis legends
								text: {
									fontSize: 12,
									fontWeight: "bold"
								}
							}
						},
						legends: {
							// legends info of lines
							text: {
								fontSize: 12,
								fill: "#646464"
							}
						}
					}}
					tooltip={(props) =>
						chartTooltip(
							{
								...props,
								data,
								metric,
								showCompareInTooltip,
								lineWidth,
								hasDate,
								currency,
								currencyXValues,
								currencySymbol,
								yScaleStacked,
								units,
								durationPreset,
								renderCustomLabel: renderTooltipYName
							},
							"line"
						)
					}
					layers={[
						"grid",
						"markers",
						"axes",
						dashedLines && enableArea ? handleAreas : "areas",
						"crosshair",
						dashedLines ? handleDashedLines : "lines",
						!enablePoints ? handlePoints : "points",
						"slices",
						"mesh",
						"legends"
					]}
				/>
			)}
			{data.length === 0 && loading && <Loader />}
			{data.length === 0 && !loading && <div className="no-items-placeholder">{placeholder}</div>}
		</div>
	);
};
export default Line;

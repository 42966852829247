// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// actions
import { ActionTypes } from "./_types";

// graphql
import { GET_MENU_PULL_INFO, REQUEST_MENU_PULL } from "../graphql/menus";

// services
import NotificationServices from "../services/NotificationService";

export const requestMenuPull = async ({
	locationIds,
	brandId = null,
	posId,
	clearCatalogue,
	allLocations = false,
	menuId = null
}) => {
	store.dispatch({
		type: ActionTypes.REQUEST_MENU_PULL_REQUEST
	});
	try {
		let variables = {
			locationIds,

			pos: posId,
			flushAll: clearCatalogue,
			allLocations: allLocations,
			menuId
		};
		if (brandId !== null) {
			variables.brandId = brandId;
		}
		const resp = await client.mutate({
			mutation: REQUEST_MENU_PULL,
			variables
		});

		if (resp.data.requestMenuPull.status) {
			store.dispatch({
				type: ActionTypes.REQUEST_MENU_PULL_SUCCESS
			});

			NotificationServices.pushNotification({
				message: resp.data.requestMenuPull.message,
				timeout: 5000,
				type: "success",
				isClosable: true,
				theme: "dark"
			});
		} else {
			store.dispatch({
				type: ActionTypes.REQUEST_MENU_PULL_FAILURE
			});

			NotificationServices.pushNotification({
				message: resp?.data?.requestMenuPull?.message || "Something went wrong",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			return false;
		}
	} catch (e) {
		store.dispatch({
			type: ActionTypes.REQUEST_MENU_PULL_FAILURE
		});

		NotificationServices.pushNotification({
			message: "Something went wrong",
			timeout: 5000,
			type: "error",
			isClosable: true,
			theme: "dark"
		});
		return false;
	}

	// re-fetching to check the status
	getMenuPullInfo();
	return true;
};
function extractIssues(report) {
	const issues = [];

	const res = report?.entities?.map((entity) => {
		const entityType = entity?.entity;

		const errArr = entity?.errors?.map((error) => {
			issues.push({
				name: error?.name,
				entityType: entityType,
				issue: {
					message: error?.messages?.[0],
					type: "ERROR"
				}
			});
		});

		const warningArr = entity?.warnings?.map((warning) => {
			issues.push({
				name: warning?.name,
				entityType: entityType,
				issue: {
					message: warning?.messages?.[0],
					type: "WARNING"
				}
			});
		});
	});

	return issues;
}
export const getMenuPullInfo = async () => {
	store.dispatch({
		type: ActionTypes.GET_MENU_PULL_INFO_REQUEST
	});
	try {
		const res = await client.query({
			query: GET_MENU_PULL_INFO,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_MENU_PULL_INFO_SUCCESS,
			payload: {
				ongoingMenuPull: res?.data?.getMenuPullInfo?.ongoingMenuPull || null,
				canPullMenu: res?.data?.getMenuPullInfo?.canPullMenu || false,
				canPullLocationMenu: res?.data?.getMenuPullInfo?.canPullMenuLocation || false,
				menuPullPOS: res?.data?.getMenuPullInfo?.pos || null,
				lastMenuPull:
					{
						...res?.data?.getMenuPullInfo?.lastMenuPull,
						report: {
							...res?.data?.getMenuPullInfo?.lastMenuPull?.report,
							structuredIssues: extractIssues(res?.data?.getMenuPullInfo?.lastMenuPull?.report)
						}
					} || null
			}
		});
	} catch (e) {
		console.log(e);
		store.dispatch({
			type: ActionTypes.GET_MENU_PULL_INFO_FAILURE
		});
	}
};

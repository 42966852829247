// utils
import { lS, isLoggedin, getLoggedInBizId } from "../atlas-utils";

// types
import { ActionTypes } from "../actions/_types";

export const defaultBannerState = {
	topic: "",
	icon: "",
	message: "",
	links: [],
	classes: "default",
	isDismissible: false,
	isDismissed: false,
	dismissedAt: null,
	trackEvent: false,
	resetOnNewSession: true
};

const INITIAL_STATE = isLoggedin() ? lS.get("banner")?.[getLoggedInBizId()] || [] : [];

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_BANNER_STATE:
			return [...action.payload];
		default:
			return state;
	}
};

import React, { useState, useCallback, useEffect, useRef } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { SelectFilter } from "../_commons/SelectFilter";
import { Button } from "../_commons/Button";
import { FormSidebar } from "../_commons/FormSidebar";
import { Paginator } from "../_commons/Paginator";
import { CheckBox } from "../_commons/CheckBox";
import { SelectAllFields } from "../_commons/SelectAllFields";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";

// utils
import { client } from "../../client";
import { scroll, msaagesArrayToHtml, printCurrency, trackEvent } from "../../atlas-utils";
import { store } from "../../store/configureStore";

// third party
import { connect } from "react-redux";

// graphql
import { GET_ITEMS_LIST } from "../../graphql/items";
import { UPDATE_LOCATION_ITEMS, UPDATE_LOCATION_MODIFIERS } from "../../graphql/locations";
import { GET_MODIFIERS_LIST } from "../../graphql/modifiers";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
import {
	FOOD_TYPE_MAP,
	TRACKING_ACTION,
	TRACKING_ENTITY,
	TRACKING_EVENT_NAMES,
	TRACKING_SOURCE,
	TRACKING_STATUS
} from "../../client-config";

const ITEMS_FILTER_INITIAL_STATE = {
	title: "",
	item_discrete_category_id: undefined,
	tags: undefined
};

const MOODIFIERS_FILTER_INITIAL_STATE = {
	option_title: "",
	crm_title: ""
};
const INITIAL_DATA = {
	count: 0,
	objects: [],
	filters: []
};

const LocationsEntityAssociation = ({
	isOpen,
	close,
	locations,
	currencySymbol,
	selectedBrand = null,
	modifiersUpdate
}) => {
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [currFilters, setCurrFilters] = useState(
		modifiersUpdate ? MOODIFIERS_FILTER_INITIAL_STATE : ITEMS_FILTER_INITIAL_STATE
	);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [categoryfilters, setCategoryfilters] = useState({});
	const [tagfilters, setTagfilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [data, setData] = useState(INITIAL_DATA);
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const [toCheck, setToCheck] = useState(false);
	const [updates, setUpdates] = useState({});
	const [allSelected, setAllSelected] = useState(false);
	const [showAllFieldSelector, setShowAllFieldSelector] = useState(false);
	const [eventTracking, setEventTracking] = useState({
		type: "Multi-Locations first",
		filters: {}
	});
	const tableRef = useRef();
	const totalChanges = Object.values(updates).filter((val) => val === true).length;
	const totalPages = Math.ceil(data?.count / limit);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				limit,
				offset,
				sort: {
					field: modifiersUpdate ? "option_order" : "sort_order",
					order: "ASC"
				}
			};
			// sidebar filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});
			filtersObject.push({ field: "is_enabled", value: "true" });
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			const resp = await client.query({
				query: modifiersUpdate ? GET_MODIFIERS_LIST : GET_ITEMS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});
			setData(modifiersUpdate ? resp.data.modifiers : resp.data.items);
			if (!modifiersUpdate) {
				setCategoryfilters(resp.data.items.filters.find((fl) => fl.field === "item_discrete_category_id"));
				setTagfilters(resp.data.items.filters.find((fl) => fl.field === "tags"));
			}
			setAllSelected(false);
			setShowAllFieldSelector(false);
			setIsCheckedAll(false);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [limit, offset, appliedFilters, modifiersUpdate]);

	const applyFilter = useCallback(
		debounce((filters, field) => {
			setAppliedFilters(filters);
			// track filters which are being used
			setEventTracking({
				...eventTracking,
				filters: {
					...eventTracking["filters"],
					[field]: true
				}
			});
			setOffset(0);
		}, 500),
		[eventTracking]
	);

	const setFilter = useCallback(
		(field, value) => {
			const newFilters = {
				...currFilters,
				[field]: value
			};
			setCurrFilters(newFilters);
			applyFilter(newFilters, field);
		},
		[currFilters, applyFilter]
	);

	useEffect(() => {
		if (isOpen) {
			fetchData();
		}
	}, [fetchData, isOpen]);

	const handleClose = useCallback(
		(success = false) => {
			// reset state before closing
			setData(INITIAL_DATA);
			setCurrFilters(modifiersUpdate ? MOODIFIERS_FILTER_INITIAL_STATE : ITEMS_FILTER_INITIAL_STATE);
			setAppliedFilters({});
			setOffset(0);
			setUpdates({});
			setIsCheckedAll(false);
			setEventTracking({
				type: "Multi-Locations first",
				filters: {}
			});
			setAllSelected(false);
			setShowAllFieldSelector(false);
			setIsCheckedAll(false);
			close(success);
		},
		[close]
	);

	const handleUpdate = useCallback(
		async (associateAll = false, dissociateAll = false, action) => {
			let eventMeta = {
				entity: modifiersUpdate ? TRACKING_ENTITY.MODIFIER : TRACKING_ENTITY.ITEM,
				action: action ? TRACKING_ACTION.ASSOCIATE : TRACKING_ACTION.DISASSOCIATE,
				number_of_locations: Object.keys(locations).length,
				source: TRACKING_SOURCE.LIST_VIEW_BULK
			};
			try {
				setConfirmLoading(true);
				const variables = {
					locationIds: [],
					associateAll: associateAll,
					disassociateAll: dissociateAll,
					brand: selectedBrand === null ? null : selectedBrand.id
				};
				if (modifiersUpdate) {
					variables.optionsToAssociate = [];
					variables.optionsToDisassociate = [];
				} else {
					variables.itemsToAssociate = [];
					variables.itemsToDisassociate = [];
				}
				for (let id in locations) {
					if (locations[id]) {
						variables.locationIds.push(parseInt(id));
					}
				}
				if (!allSelected) {
					for (let id in updates) {
						if (updates[id]) {
							if (action) {
								if (modifiersUpdate) {
									variables.optionsToAssociate.push(parseInt(id));
								} else {
									variables.itemsToAssociate.push(parseInt(id));
								}
							} else {
								if (modifiersUpdate) {
									variables.optionsToDisassociate.push(parseInt(id));
								} else {
									variables.itemsToDisassociate.push(parseInt(id));
								}
							}
						}
					}
					// track locations-items / location-modifier association
					eventMeta = {
						...eventMeta,
						...eventTracking,
						created: modifiersUpdate
							? variables.optionsToAssociate.length
							: variables.itemsToAssociate.length,
						removed: modifiersUpdate
							? variables.optionsToDisassociate.length
							: variables.itemsToDisassociate.length
					};
				} else {
					// track locations-items association
					eventMeta = {
						...eventMeta,
						...eventTracking,
						createdAll: data.count
					};
				}
				// sidebar filters
				let filtersObject = [];
				Object.keys(appliedFilters).forEach((f) => {
					if (!appliedFilters[f]) {
						return;
					}
					if (typeof appliedFilters[f] === "object") {
						if (appliedFilters[f].value) {
							filtersObject.push({
								field: f,
								value: appliedFilters[f].value
							});
						}
					} else {
						filtersObject.push({
							field: f,
							value: appliedFilters[f]
						});
					}
				});

				eventMeta["number_of_entities"] = allSelected
					? data.count
					: modifiersUpdate
						? variables.optionsToAssociate.length + variables.optionsToDisassociate.length
						: variables.itemsToAssociate.length + variables.itemsToDisassociate.length;

				variables.filters = filtersObject;
				const resp = await client.mutate({
					mutation: modifiersUpdate ? UPDATE_LOCATION_MODIFIERS : UPDATE_LOCATION_ITEMS,
					variables,
					fetchPolicy: "no-cache"
				});
				let success = modifiersUpdate
					? resp.data.updateLocationModifiers.status.success
					: resp.data.updateLocationItems.status.success;
				if (success) {
					eventMeta = {
						...eventMeta,
						status: TRACKING_STATUS.SUCCESS
					};
					trackEvent(TRACKING_EVENT_NAMES.UPDATE_ENTITES_LOCATIONS_LIST_VIEW, eventMeta);

					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: action
								? `${modifiersUpdate ? "Modifiers" : "Items"} association updated!`
								: `${modifiersUpdate ? "Modifiers" : "Items"} disassociation updated!`,
							timeout: 2000,
							error: false
						}
					});
					handleClose(true);
				} else {
					eventMeta = {
						...eventMeta,
						status: TRACKING_STATUS.FAILURE
					};
					trackEvent(TRACKING_EVENT_NAMES.UPDATE_ENTITES_LOCATIONS_LIST_VIEW, eventMeta);
					// handle error message
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: msaagesArrayToHtml(
								modifiersUpdate
									? resp.data.updateLocationModifiers.status.messages
									: resp.data.updateLocationItems.status.messages
							),
							timeout: 5000,
							error: true
						}
					});
				}
			} catch (error) {
				eventMeta = {
					...eventMeta,
					status: TRACKING_STATUS.FAILURE
				};
				trackEvent(TRACKING_EVENT_NAMES.UPDATE_ENTITES_LOCATIONS_LIST_VIEW, eventMeta);
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setConfirmLoading(false);
		},
		[updates, handleClose, eventTracking]
	);

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			setIsCheckedAll(false);
			setShowAllFieldSelector(false);
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		},
		[limit]
	);

	const handleCheck = (id, toAssociate) => {
		setUpdates({
			...updates,
			[id]: toAssociate
		});
		if (!toAssociate) {
			setIsCheckedAll(false);
			setShowAllFieldSelector(false);
		}
	};

	const handleCheckAll = (toCheckAll) => {
		setIsCheckedAll(toCheckAll);
		let checkedUpdates = {
			...updates
		};
		data.objects.forEach((item) => {
			checkedUpdates[item.id] = toCheckAll;
		});
		setUpdates({
			...checkedUpdates
		});
		if (toCheckAll && Math.ceil(data.count / limit) > 1) {
			setShowAllFieldSelector(true);
		}
		if (!toCheckAll) {
			setShowAllFieldSelector(false);
		}
	};

	const handleCompleteSelection = (associateAll) => {
		setAllSelected(associateAll);
		handleCheckAll(associateAll);
		if (!associateAll) {
			setShowAllFieldSelector(false);
			setUpdates({});
		}
	};

	return (
		<div className="locations-items-association-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={() => {}}
				title={`Update ${modifiersUpdate ? "Modifiers" : "Items"} Association`}
				subTitle={
					<div>
						Associate/ disassociate {modifiersUpdate ? "modifiers" : "items"} to the selected location(s){" "}
						{selectedBrand !== null ? (
							<React.Fragment>
								for <span>{selectedBrand?.name}</span>
							</React.Fragment>
						) : (
							""
						)}
					</div>
				}
				submitTitle="Save"
				loading={confirmLoading}
				hideActions={true}
				headerRight={
					<React.Fragment>
						<Button
							clickHandler={() => {
								if (allSelected) {
									handleUpdate(true, false, true);
								} else {
									handleUpdate(false, false, true);
								}
							}}
							classes={totalChanges === 0 ? "disabled" : ""}
						>
							{totalChanges === 0
								? "Associate"
								: `Associate ${allSelected ? data.count : totalChanges} ${
										modifiersUpdate ? "Modifier" : "Item"
									}(s)`}
						</Button>
						<Button
							clickHandler={() => {
								if (allSelected) {
									handleUpdate(false, true, true);
								} else {
									handleUpdate(false, false, false);
								}
							}}
							classes={`margin-right at-btn--danger ` + (totalChanges === 0 ? "disabled" : "")}
						>
							{totalChanges === 0
								? "Dissociate"
								: `Dissociate ${allSelected ? data.count : totalChanges} ${
										modifiersUpdate ? "Modifier" : "Item"
									}(s)`}
						</Button>
					</React.Fragment>
				}
			>
				<div className="form-content" ref={tableRef}>
					<div className={`search-container ${modifiersUpdate ? "modifier-search-container" : ""}`}>
						<InputWithLabel
							value={modifiersUpdate ? currFilters.option_title : currFilters.title}
							onChange={(e) => setFilter(modifiersUpdate ? "option_title" : "title", e.target.value)}
							placeholder="Enter Name"
						>
							Name
						</InputWithLabel>
						{modifiersUpdate && (
							<InputWithLabel
								value={currFilters.crm_title}
								onChange={(e) => setFilter("crm_title", e.target.value)}
								placeholder="Enter CRM Title"
							>
								CRM Title
							</InputWithLabel>
						)}
						{!modifiersUpdate && (
							<SelectFilter
								title="Category"
								options={categoryfilters.values || []}
								field={categoryfilters.field || ""}
								currValue={currFilters.item_discrete_category_id || ""}
								setFilter={setFilter}
								labelKey="valueForDisplay"
								valueKey="value"
							/>
						)}
						{!modifiersUpdate && (
							<SelectFilter
								title="Tags"
								options={tagfilters.values || []}
								field={tagfilters.field || ""}
								currValue={currFilters.tags || ""}
								setFilter={setFilter}
								labelKey="valueForDisplay"
								valueKey="value"
							/>
						)}
					</div>
					{!modifiersUpdate && (
						<div className="search-container">
							<InputWithLabel
								value={currFilters.crm_title}
								onChange={(e) => setFilter("crm_title", e.target.value)}
								placeholder="Enter CRM Title"
							>
								CRM Title
							</InputWithLabel>
						</div>
					)}
					{showAllFieldSelector && totalPages > 1 && totalChanges !== 0 && (
						<SelectAllFields
							allSelected={allSelected}
							handleCompleteSelection={handleCompleteSelection}
							mainMessage={
								<span>
									{allSelected ? "All " : ""}
									<strong>{allSelected ? data.count : data.objects.length} </strong>{" "}
									{`${modifiersUpdate ? "modifier" : "item"}(s)`}{" "}
									{allSelected ? "on all pages are selected." : "on this page are selected."}
								</span>
							}
							linkMessage={
								allSelected ? (
									" Clear selection"
								) : (
									<span>
										{" "}
										Select <strong>{data.count}</strong>{" "}
										{`${modifiersUpdate ? "modifiers" : "items"}`} from all pages
									</span>
								)
							}
						/>
					)}
					<Table
						data={data.objects}
						loading={loading}
						handleCheck={handleCheck}
						updates={updates}
						toCheck={toCheck}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						currencySymbol={currencySymbol}
						allSelected={allSelected}
						modifiersUpdate={modifiersUpdate}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={data.count}
						goToPage={handlePagination}
						readOnly={allSelected}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	selectedPlatform: store.locationsList.selectedPlatform
}))(LocationsEntityAssociation);

export const Table = ({
	data,
	loading,
	sortList,
	sortedField,
	toCheck,
	handleCheck,
	isCheckedAll,
	handleCheckAll,
	updates,
	currencySymbol,
	allSelected,
	modifiersUpdate
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && (loading || allSelected) ? "disabled" : "") +
				" transaction-table-holder common-table-container locations-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						modifiersUpdate={modifiersUpdate}
					/>
					{trails.map(({ rotate }, i) => (
						<TableList
							key={data[i].id}
							toCheck={toCheck}
							handleCheck={handleCheck}
							updates={updates}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
							currencySymbol={currencySymbol}
							modifiersUpdate={modifiersUpdate}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Items found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ isCheckedAll, handleCheckAll, modifiersUpdate }) => (
	<div className={`at-table-row transaction-header-row`}>
		<div className={`at-table-cell at-table-header at-header-text name ${modifiersUpdate ? "modifier-name" : ""}`}>
			<CheckBox checked={isCheckedAll} clickHandler={() => handleCheckAll(!isCheckedAll)}>
				Name
			</CheckBox>
		</div>
		<div
			className={`at-table-cell at-table-header at-header-text ${
				modifiersUpdate ? "associated-locations" : "category"
			}`}
		>
			{modifiersUpdate ? "Associated Locations" : "Category"}
		</div>
		<div className={`at-table-cell at-table-header at-header-text city`}>Price</div>
	</div>
);

export const TableList = ({
	id,
	itemTitle,
	optionTitle,
	foodType,
	crmTitle,
	numAssociatedLocations,
	tags,
	toCheck,
	category,
	itemPrice,
	optionPrice,
	currencySymbol,
	style,
	handleCheck,
	updates,
	modifiersUpdate
}) => {
	const status = updates[id] === undefined ? toCheck : updates[id];
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div
				className={`at-table-cell at-cell-text name ${modifiersUpdate ? "modifier-name" : ""}`}
				title={itemTitle}
			>
				<CheckBox
					checked={status}
					clickHandler={() => handleCheck(id, !status)}
					title={modifiersUpdate ? optionTitle : itemTitle || "--"}
				>
					{!modifiersUpdate && <span className={`food-type ${FOOD_TYPE_MAP[foodType]}`} />}
					<span>{(modifiersUpdate ? optionTitle : itemTitle) || id}</span>
				</CheckBox>
				{crmTitle && <div className="crm-title">{`CRM Title: ${crmTitle}`}</div>}
				<div className="text--light item_tags">
					{!modifiersUpdate && tags?.length > 0 ? (
						<div className="tags-container table-mode">
							<div className="list">
								{tags?.map(
									(tag, i) =>
										i < 3 && (
											<span key={i} className="tag-item" title={tag}>
												{tag}
											</span>
										)
								)}
								{tags.length > 3 && (
									<span className="tag-item more-tags" title={tags.slice(3).join(", ")}>
										+{tags.length - 3} more
									</span>
								)}
							</div>
						</div>
					) : (
						""
					)}
				</div>
			</div>
			<div className={`at-table-cell at-cell-text ${modifiersUpdate ? "associated-locations" : "category"}`}>
				{(modifiersUpdate ? numAssociatedLocations || "0" : category) || "--"}
			</div>
			<div className="at-table-cell at-cell-text city">
				{printCurrency(currencySymbol)}
				{modifiersUpdate ? optionPrice : itemPrice || 0}
			</div>
		</animated.div>
	);
};

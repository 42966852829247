import React from "react";
import InfiniteSpinner from "./InfiniteSpinner";
import { trackEvent } from "../../atlas-utils";
import { TRACKING_EVENT_NAMES } from "../../client-config";

const PendingStatusAlertBox = () => {
	return (
		<div
			className="status-alert-container"
			style={{
				borderLeft: `6px solid #3B5FEB`,
				backgroundColor: "#F2F5FF"
			}}
		>
			<InfiniteSpinner />

			<div className="error-text-link">
				<div className="main-error-header">Verification in Progress</div>
				<div className="main-error-desc">The verification of your menu is taking longer than expected.</div>
			</div>
		</div>
	);
};
const FailureStatusAlertBox = ({ totalErrors, totalWarnings, handleFixErrorDrawerOpen }) => {
	const styleInfo = {};
	if (totalWarnings >= 0 && totalErrors > 0) {
		styleInfo.backgroundColor = "#FFF5F5";
		styleInfo.borderColor = "#D64949";
		styleInfo.icon = "/assets/icons/error-red-icon.svg";
	}
	if (totalWarnings > 0 && totalErrors === 0) {
		styleInfo.backgroundColor = "#FEF6E9";
		styleInfo.borderColor = "#E5B917";
		styleInfo.icon = "/assets/icons/warning-icon.svg";
	}
	const getMsg = () => {
		if (totalErrors === 0 && totalWarnings > 0) {
			return `There are ${totalWarnings} warnings in your menu. Fix these issues to avoid publish failure.`;
		}
		if (totalErrors > 0 && totalWarnings === 0) {
			return `There are ${totalErrors} errors in your menu. Fix these issues to avoid publish failure.`;
		} else {
			return `There are ${totalWarnings} warnings and ${totalErrors} errors in your
			menu. Fix these issues to avoid publish failure.`;
		}
	};
	return (
		<div
			className="status-alert-container"
			style={{
				borderLeft: `6px solid ${styleInfo?.borderColor}`,
				backgroundColor: styleInfo?.backgroundColor
			}}
		>
			<div className="error-icon">
				<img src={styleInfo?.icon} />
			</div>

			<div className="error-text-link">
				<div className="main-error-header">Fix issues across platforms before publishing</div>
				<div className="main-error-desc">{getMsg()}</div>

				<span
					className="hyperlink"
					style={{ width: "fit-content" }}
					onClick={() => {
						handleFixErrorDrawerOpen();
						trackEvent(TRACKING_EVENT_NAMES.MENU_PREVIEW_FIX_ISSUES_CLICKS, {
							source: "list_view"
						});
					}}
				>
					Fix errors <img src="/assets/icons/ic_right_arrow_blue.svg" />
				</span>
			</div>
		</div>
	);
};

const ErrorStatusAlertBox = ({ message = "", title = "" }) => {
	return (
		<div
			className="status-alert-container"
			style={{
				borderLeft: `6px solid #D64949`,
				backgroundColor: "#FFF5F5"
			}}
		>
			<div className="error-icon">
				<img src="/assets/icons/error-red-icon.svg" />
			</div>

			<div className="error-text-link">
				<div className="main-error-header">{title || "Verification error occurred."} </div>
				<div className="main-error-desc">{message?.length > 0 ? message : "Contact support"}</div>
			</div>
		</div>
	);
};
const ValidationSuccessStatusAlertBox = ({ isFromPreviewSection = false }) => {
	return (
		<div
			className="status-alert-container"
			style={{
				borderLeft: `6px solid #0DA125`,
				backgroundColor: "#F6FBF8"
			}}
		>
			<div className="error-icon">
				<img src={"/assets/icons/success-green-icon.svg"} />
			</div>

			<div className="error-text-link">
				<div className="main-error-header">
					{isFromPreviewSection ? "Menu is ready to publish!" : "Verification Complete"}
				</div>
				<div className="main-error-desc">
					{isFromPreviewSection
						? "You have no warnings or errors and can now publish your menu without any anticipated failures!"
						: "No issues found."}
				</div>
			</div>
		</div>
	);
};
const StatusAlertBox = ({
	totalWarnings = 0,
	totalErrors = 0,
	handleFixErrorDrawerOpen = () => {},
	status = "",
	isFromPreviewSection = false,
	message = "",
	title = ""
}) => {
	const getStatusAlertBox = () => {
		if (status === "PENDING") {
			return <PendingStatusAlertBox />;
		} else if (status === "FAILURE" && (totalErrors > 0 || totalWarnings > 0)) {
			return (
				<FailureStatusAlertBox
					totalErrors={totalErrors}
					totalWarnings={totalWarnings}
					handleFixErrorDrawerOpen={handleFixErrorDrawerOpen}
				/>
			);
		} else if (status === "ERROR") {
			return <ErrorStatusAlertBox message={message} title={title} />;
		} else if (status === "SUCCESS") {
			return <ValidationSuccessStatusAlertBox isFromPreviewSection={isFromPreviewSection} />;
		} else {
			return <></>;
		}
	};
	return <React.Fragment>{getStatusAlertBox()}</React.Fragment>;
};

export default StatusAlertBox;

import React, { useMemo } from "react";
import { connect } from "react-redux";
import { useTable, useSortBy } from "react-table";
import LocationCell from "../../../components/Periscope/ItemAvailability/LocationCell";
import Tooltip from "../../../components/Periscope/ItemAvailability/Tooltip";
import { Paginator } from "../../../components/_commons/Paginator";
import LocationPlatformCell from "../../../components/Periscope/ItemAvailability/LocationPlatformCell";
import TableHeaderDisplay from "../../../components/Periscope/ItemAvailability/TableHeaderDisplay";
import LocationBrandCell from "../../../components/Periscope/ItemAvailability/LocationBrandCell";
import LocationTableLoading from "../../../components/Periscope/LocationAvailability/LocationTableLoading";
import { store } from "../../../store/configureStore";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getBrandDetail, getBrandDetailViaName, getPlatformDetailViaName } from "../../../helpers/periscope";
import { ActionTypes } from "../../../actions/_types";
import { lS, trackEvent } from "../../../atlas-utils";
import Popover from "../../../components/_commons/Popover";

const sidebarLocation = {
	verticalAlign: "top",
	padding: "20px 16px 20px 24px ",
	minWidth: "260px"
};

function useInstance(instance) {
	const { allColumns } = instance;
	let rowSpanHeaders = [];

	allColumns.forEach((column, i) => {
		const { id, enableRowSpan } = column;
		if (enableRowSpan) {
			rowSpanHeaders = [...rowSpanHeaders, { id, topCellValue: null, topCellIndex: 0 }];
		}
	});

	Object.assign(instance, { rowSpanHeaders });
}

const LocationTable = ({
	tableData,
	platformCount,
	defaultPlatforms,
	periscopeItemAvailabilityData,
	periscopeItemAvailabilityState,
	handlePagination,
	handlePageSize,
	selectedBrand,
	configItems,
	refreshBtn,
	periscopeCountryWiseSupportedPlatforms,
	login
}) => {
	const data = periscopeItemAvailabilityData?.data?.objects?.data;
	const brands = configItems?.brands?.items;
	const loading = periscopeItemAvailabilityData?.loading;
	const error = periscopeItemAvailabilityData?.error;
	const dataCount = periscopeItemAvailabilityState?.count || 0;
	const limit = periscopeItemAvailabilityState?.limit;
	const offset = periscopeItemAvailabilityState?.offset;
	const country = (login?.loggedInbizDetail?.country || lS.get("auth")?.biz?.country).toLowerCase();
	const isMultibrandEnabled = login?.loggedInbizDetail?.isMultibrandEnabled;
	const bizPlatforms = configItems?.bizPlatforms?.items.filter((platform) => {
		if (platform.platformName == "All Platforms") {
			return true;
		}
		return (periscopeCountryWiseSupportedPlatforms?.[country] ||
			lS.get("periscopeCountryWisePlatforms")?.[country])?.[platform.platformName.toLowerCase()]?.enabled;
	});

	const columns = useMemo(() => {
		return Object.keys(data?.[0] || {}).map((column) => ({
			accessor: column,
			enableRowSpan: column == "location",
			Cell: (props) => {
				if (column == "location") {
					return <LocationCell props={props} />;
				} else if (column == "brand") {
					return (
						<>
							<LocationBrandCell props={props} />
						</>
					);
				} else {
					return <LocationPlatformCell props={props} />;
				}
			}
		}));
	}, [data]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, rowSpanHeaders } = useTable(
		{ columns, data },
		useSortBy,
		(hooks) => {
			hooks.useInstance.push(useInstance);
		}
	);
	const dispatchSortFunction = (sort) => {
		if (sort == null) {
			store.dispatch({
				type: ActionTypes.ITEM_AVAILABILITY_INITIAL_SORT
			});
		} else {
			store.dispatch({
				type: ActionTypes.ITEM_AVAILABILITY_STATE_FILTERS,
				payload: sort
			});
		}
	};

	const sortThePlatform = (column) => {
		let order = null;
		const currentSort = periscopeItemAvailabilityState?.sort;
		store.dispatch({
			type: ActionTypes.ITEM_AVAILABILITY_STATE_FILTERS,
			payload: { offset: 0 }
		});
		if (currentSort?.field === column) {
			order = currentSort.order === "asc" ? "desc" : currentSort.order === "desc" ? null : "asc";
		} else {
			order = "asc";
		}
		const sort = order ? { sort: { field: column, order } } : null;
		dispatchSortFunction(sort);
	};

	const history = useHistory();
	const goToSpecficLocationCell = (cell) => {
		const total = cell?.value?.total;
		if (total !== 0) {
			trackEvent("periscope_detail_view_clicked", { module: "item_availability" });
			const platformName = cell.column.id;
			const locationValues = cell.row.allCells[0].value;
			let brandName = "";
			let g = cell.row.allCells[1];
			if (g.column.id == "brand") {
				const brandColumn = cell.row.allCells[1].value;
				brandName = getBrandDetailViaName(brandColumn?.name, brands);
			}
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
				payload: {
					currentFilters: { platform_names: [cell.column.id] },
					appliedFilters: { platform_names: [cell.column.id] },
					initialCall: false
				}
			});
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_LOCATION_INITIAL_STATE
			});
			const platformId = getPlatformDetailViaName(platformName, bizPlatforms).id;
			const brandDetails =
				cell?.row?.allCells[1].column.id == "brand"
					? getBrandDetail(cell?.row?.allCells[1]?.value?.id, brands)
					: getBrandDetail(selectedBrand?.id, brands);
			let queryParam = `?location_id=${locationValues?.id}&location=${locationValues?.name}&platform_id=${platformId}&platform=${platformName}${isMultibrandEnabled ? `&brand_id=${brandDetails?.id}&brand=${brandDetails?.name}` : ``}`;
			history.push(`/periscope/item-availability/location/${locationValues?.name}${queryParam}`, {
				locationValues
			});
		}
	};

	const renderPopover = (obj) => {
		return <div className="info-popover">{obj?.name}</div>;
	};

	return (
		<div>
			{defaultPlatforms.length > 0 ? (
				<>
					<div className="table-div-location">
						<table
							{...(loading ? { ...getTableProps() } : null)}
							style={{ borderCollapse: "collapse", border: "1px solid  #EAEAEA", background: "#FFFFFF" }}
						>
							<thead className="tablehead-location-item-availability">
								<tr style={{ border: "1px solid #EAEAEA" }}>
									<td
										rowSpan={2}
										style={{
											padding: "16px 16px 16px 24px",
											minWidth: "260px",
											...(selectedBrand?.id !== "all" ? { border: "1px solid #EAEAEA" } : {})
										}}
										className="header-text-location"
									>
										Location
									</td>
									{selectedBrand?.id !== "all" ? null : (
										<td
											rowSpan={2}
											style={{
												borderRight: "1px solid #EAEAEA",
												padding: "16px",
												minWidth: "192px"
											}}
											className="header-text-location"
										>
											Brand
										</td>
									)}

									<td
										colSpan={platformCount.count}
										className="platforms-head-location header-text-location"
									>
										Items at location
									</td>
								</tr>
								<tr>
									{tableData.map((table, index) => {
										if (
											(selectedBrand?.id !== "all" && table == "brand") ||
											table == "location" ||
											table == "brand"
										) {
											return null;
										}
										return (
											<td
												key={index}
												style={{ width: ` ${platformCount.percent}`, cursor: "pointer" }}
												onClick={() => sortThePlatform(table)}
											>
												<TableHeaderDisplay index={index} table={table} textRight={true} />
											</td>
										);
									})}
								</tr>
							</thead>

							{loading || error ? (
								<>
									<LocationTableLoading
										tableData={tableData}
										selectedBrand={selectedBrand}
										error={error}
										refreshBtn={refreshBtn}
									/>
								</>
							) : (
								<>
									<>
										<tbody {...getTableBodyProps()} style={{ borderBottom: "1px solid #EAEAEA" }}>
											{rows.map((row, i) => {
												prepareRow(row);

												for (let j = 0; j < row.allCells.length; j++) {
													let cell = row.allCells[j];
													let rowSpanHeader = rowSpanHeaders.find((x) => {
														return x.id === cell.column.id;
													});

													if (rowSpanHeader !== undefined) {
														if (
															rowSpanHeader.topCellValue === null ||
															rowSpanHeader.topCellValue.name !== cell.value.name
														) {
															cell.isRowSpanned = false;
															rowSpanHeader.topCellValue = cell.value;
															rowSpanHeader.topCellIndex = i;
															cell.rowSpan = 1;
														} else {
															rows[rowSpanHeader.topCellIndex].allCells[j].rowSpan++;
															cell.isRowSpanned = true;
														}
													}
												}
												return null;
											})}

											{rows.map((row, index) => {
												let rowIndex = -1;
												if (index > 0) {
													if (
														row?.original?.location?.name !==
														rows[index - 1]?.original?.location?.name
													) {
														rowIndex = row.index;
													}
												}
												let lastRowId = rows[rows.length - 1].index;

												return (
													<>
														<tr {...row.getRowProps()} className="api-table-data">
															{row.cells.map((cell, index) => {
																let classname = "";
																if (
																	cell.column.id !== "brand" &&
																	cell.column.id !== "location"
																) {
																	const totalLocations = cell.value.total;
																	const onlineLocations = cell.value.available;

																	if (onlineLocations == 0 && totalLocations > 0) {
																		classname = " complete-offline-stores ";
																	} else if (
																		totalLocations == onlineLocations &&
																		totalLocations !== 0 &&
																		onlineLocations !== 0
																	) {
																		classname = "online-color";
																	} else if (
																		onlineLocations == 0 &&
																		totalLocations == 0
																	) {
																		classname = "partially-offline-color";
																	} else {
																		classname = "offline-color";
																	}
																}
																if (cell.isRowSpanned) return null;
																else
																	return (
																		<td
																			rowSpan={cell.rowSpan}
																			{...cell.getCellProps()}
																			className={`${classname} percent-table-display`}
																			style={{
																				...(cell.column.id === "location"
																					? sidebarLocation
																					: {}),
																				...(cell.row.id == rowIndex
																					? { borderTop: "1px solid #EAEAEA" }
																					: {}),
																				...(cell.column.id == "brand"
																					? {
																							borderRight:
																								"1px solid #EAEAEA"
																						}
																					: {}),
																				...(row.index == 0
																					? {
																							borderTop:
																								"1px solid  #EAEAEA",
																							borderBottom:
																								" 1px dashed #EAEAEA",
																							height: "100%"
																						}
																					: cell.row.id == lastRowId
																						? {
																								borderBottom:
																									"1px solid #EAEAEA",
																								height: "100%"
																							}
																						: {
																								borderBottom:
																									"1px dashed #EAEAEA",
																								height: "100%"
																							}),

																				...(row.index == lastRowId
																					? {
																							borderBottom:
																								"1px solid #EAEAEA"
																						}
																					: {}),
																				...(cell.column.id == "location" &&
																				selectedBrand?.id !== "all"
																					? {
																							borderRight:
																								"1px solid #EAEAEA"
																						}
																					: {}),
																				...(cell.column.id != "location" &&
																				cell.column.id !== "brand" &&
																				cell.value?.total &&
																				cell.value?.total > 0
																					? { cursor: "pointer" }
																					: {})
																			}}
																		>
																			{cell.column.id == "location" ||
																			cell.column.id == "brand" ? (
																				<div className={cell.column?.id}>
																					{(cell.column?.id == "location" &&
																						(cell.value?.name || "")
																							.length >= 20) ||
																					(cell.column?.id == "brand" &&
																						(cell.value?.name || "")
																							.length >= 12) ? (
																						<Popover
																							data={cell.value}
																							renderPopover={
																								renderPopover
																							}
																							position={"down-left"}
																							showOnHover={true}
																							showOnClick
																							classes="fit-content"
																						>
																							{cell.render("Cell")}
																						</Popover>
																					) : (
																						<>{cell.render("Cell")}</>
																					)}
																				</div>
																			) : (
																				<>
																					<div
																						className={`tooltip-item`}
																						onClick={() =>
																							goToSpecficLocationCell(
																								cell
																							)
																						}
																					>
																						<div
																							style={{
																								paddingRight: "24px",
																								display: "flex",
																								justifyContent: "right"
																							}}
																						>
																							{cell.render("Cell")}
																						</div>
																						<>
																							<Tooltip
																								cell={cell}
																								unitText="items"
																							/>
																						</>
																					</div>
																				</>
																			)}
																		</td>
																	);
															})}
														</tr>
													</>
												);
											})}
										</tbody>
									</>
								</>
							)}
							{defaultPlatforms.length > 0 && dataCount > 0 && !loading && !error ? (
								<>
									<tfoot>
										<tr>
											<td
												colSpan="100%"
												style={{ textAlign: "right" }}
												className="paginator-location"
											>
												<Paginator
													limit={limit}
													offset={offset}
													count={dataCount || 0}
													showPageSize={true}
													goToPage={handlePagination}
													setPageSize={handlePageSize}
												/>
											</td>
										</tr>
									</tfoot>
								</>
							) : (
								<> </>
							)}
						</table>
					</div>
				</>
			) : (
				<></>
			)}
		</div>
	);
};

const mapStateToProps = (state) => ({
	configItems: state.configItems,
	login: state.login,
	periscopeItemAvailabilityData: state.periscopeItemAvailabilityData,
	periscopeCountryWiseSupportedPlatforms: state.periscopeCountryWiseSupportedPlatforms,
	periscopeItemAvailabilityState: state.periscopeItemAvailabilityState
});

export default connect(mapStateToProps)(LocationTable);

import gql from "graphql-tag";

export const GET_METRIC_DATA = gql`
	query getMetricData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: MetricsQuery!
		$sort: SortInput
	) {
		getMetricData(requiredFilters: $requiredFilters, filters: $filters, query: $query, sort: $sort) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				selection
				value
				compareValue
				percentageChange
				isPrimary
				description
				type
				valueType
			}
			isLocationOverlapping
		}
	}
`;

export const GET_LINE_CHART_DATA = gql`
	query getLineChartData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: LineQuery!
		$sort: SortInput
	) {
		getLineChartData(requiredFilters: $requiredFilters, filters: $filters, query: $query, sort: $sort) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				id
				data {
					x
					y
					z
				}
			}
			isLocationOverlapping
		}
	}
`;

export const GET_GROUPED_LINE_CHART_DATA = gql`
	query getGroupedLineChartData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: GroupedLineQuery!
		$sort: SortInput
	) {
		getGroupedLineChartData(requiredFilters: $requiredFilters, filters: $filters, query: $query, sort: $sort) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				id
				data {
					x
					y
					z
				}
			}
			isLocationOverlapping
		}
	}
`;

export const GET_PIE_CHART_DATA = gql`
	query getPieChartData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: PieQuery!
		$sort: SortInput
	) {
		getPieChartData(requiredFilters: $requiredFilters, filters: $filters, query: $query, sort: $sort) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				id
				name
				value
				compareValue
			}
			isLocationOverlapping
		}
	}
`;

export const GET_BAR_CHART_DATA = gql`
	query getBarChartData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: BarQuery!
		$sort: SortInput
	) {
		getBarChartData(requiredFilters: $requiredFilters, filters: $filters, query: $query, sort: $sort) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				label
				values {
					key
					value
					compareValue
				}
			}
			isLocationOverlapping
		}
	}
`;

export const GET_STACKED_BAR_CHART_DATA = gql`
	query getStackedBarChartData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: StackedBarQuery!
		$sort: SortInput
	) {
		getStackedBarChartData(requiredFilters: $requiredFilters, filters: $filters, query: $query, sort: $sort) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				label
				values {
					key
					value
					compareValue
				}
			}
			isLocationOverlapping
		}
	}
`;

export const GET_SANKEY_CHART_DATA = gql`
	query getSankeyChartData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: SankeyQuery!
		$sort: SortInput
	) {
		getSankeyChartData(requiredFilters: $requiredFilters, filters: $filters, query: $query, sort: $sort) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				nodes {
					id
				}
				links {
					source
					target
					value
				}
			}
			isLocationOverlapping
		}
	}
`;

export const GET_HEATMAP_CHART_DATA = gql`
	query getHeatmapChartData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: HeapMapQuery!
		$sort: SortInput
	) {
		getHeatmapChartData(requiredFilters: $requiredFilters, filters: $filters, query: $query, sort: $sort) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				y
				data {
					x
					value
					compareValue
				}
			}
			isLocationOverlapping
		}
	}
`;

export const GET_AVAILABILITY_HEATMAP_CHART_DATA = gql`
	query getAvailabilityHeatmapChartData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: AvailabilityHeatMapQuery!
		$sort: SortInput
	) {
		getAvailabilityHeatmapChartData(
			requiredFilters: $requiredFilters
			filters: $filters
			query: $query
			sort: $sort
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				y
				data {
					x
					value
					compareValue
					timestamp
				}
			}
			isLocationOverlapping
		}
	}
`;

export const GET_TABULAR_DATA = gql`
	query getTabularData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: TabularQuery!
		$search: [SearchArgument]
		$sort: SortInput
	) {
		getTabularData(
			requiredFilters: $requiredFilters
			filters: $filters
			query: $query
			search: $search
			sort: $sort
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				count
				columns {
					key
					displayName
					subDisplayName
					isDefault
					isPrimary
					isSortable
					valueType
					value
					type
				}
				rows {
					key
					value
					compareValue
					percentageChange
					description
				}
			}
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			searchKeywords {
				key
				valueForDisplay
			}
			isLocationOverlapping
		}
	}
`;

export const GET_ITEM_MODIFIER_GROUPS = gql`
	query getItemModifierGroups($id: Int, $limit: Int, $offset: Int, $filters: [ListFilterArgument]) {
		item(id: $id) {
			optionGroups(limit: $limit, offset: $offset, filters: $filters) {
				objects {
					id
					optionGroupTitle
					isVariant
				}
				isLocationOverlapping
			}
		}
	}
`;

export const GET_AVAILABILITY_HEATMAP_DETAIL_DATA = gql`
	query getAvailabilityHeatmapDetailData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: AvailabilityHeatMapDetailQuery!
		$sort: SortInput
	) {
		getAvailabilityHeatmapDetailData(
			requiredFilters: $requiredFilters
			filters: $filters
			query: $query
			sort: $sort
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				locationId
				locationName
				offlineSeconds
				availabilityPercentage
				platformCount
				brandCount
			}
			isLocationOverlapping
		}
	}
`;

export const GET_AVAILABILITY_HEATMAP_LOCATION_DETAIL_DATA = gql`
	query getAvailabilityHeatmapLocationDetailData(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: AvailabilityHeatMapDetailQuery!
	) {
		getAvailabilityHeatmapLocationDetailData(requiredFilters: $requiredFilters, filters: $filters, query: $query) {
			status {
				success
				messages {
					field
					message
				}
			}
			objects {
				timingGroup {
					timingGroupId
					brandName
					slots {
						start
						end
					}
				}
				locationData {
					brandId
					brandName
					brandLogo
					offlineSeconds
					expectedAvailability
					availabilityPercentage
					platform
				}
			}
			isLocationOverlapping
		}
	}
`;

export const GET_ITEMS_LIST = gql`
	query getItems($limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		items(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
			offset
			limit
			count
			objects {
				title
				id
			}
		}
	}
`;

export const GET_LOCATIONS = gql`
	query getLocationsList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
		$brand: String
	) {
		stores(limit: $limit, offset: $offset, filters: $filters, sort: $sort, search: $search, brand: $brand) {
			count
			offset
			limit
			objects {
				id
				title
				city
			}
		}
	}
`;

export const GET_LOCATION_GROUPS_LIST = gql`
	query getLocationGroupsList($limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		locationGroups(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
			count
			offset
			limit
			objects {
				id
				title
				description
				numAssociatedLocations
			}
		}
	}
`;

export const GET_LOCATION_GROUP = gql`
	query getLocationGroup($id: Int) {
		locationGroup(id: $id) {
			id
			title
		}
	}
`;
export const GET_STORES_LIST = gql`
	query getStores($limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput, $brand: String) {
		stores(limit: $limit, offset: $offset, filters: $filters, sort: $sort, brand: $brand) {
			limit
			offset
			count
			objects {
				id
				name
				brandLocation {
					id
					name
				}
			}
		}
	}
`;

export const GET_LOCATION = gql`
	query getLocation($id: Int) {
		store(id: $id) {
			id
			name
		}
	}
`;

export const GET_ITEM = gql`
	query getItem($id: Int) {
		item(id: $id) {
			id
			itemTitle
		}
	}
`;

export const GET_BIZ_PLATFORMS = gql`
	query getBizPlatforms(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$includeUrbanpiper: Boolean
	) {
		bizPlatforms(
			limit: $limit
			offset: $offset
			filters: $filters
			sort: $sort
			includeUrbanpiper: $includeUrbanpiper
		) {
			objects {
				id
				platformName
				logo
				isEnabled
				isItemAssociated
			}
		}
	}
`;

export const SAVE_COMPARISON = gql`
	mutation saveComparison($input: ComparisonInput!) {
		saveComparison(input: $input) {
			status {
				success
				messages {
					field
					message
				}
			}
			comparisonId
			lastViewedId
		}
	}
`;

export const GET_COMPARISONS = gql`
	query getComparisons(
		$limit: Int = 10
		$offset: Int = 0
		$search: [SearchArgument]
		$bizId: String!
		$view: String = "saved"
	) {
		getComparisons(limit: $limit, offset: $offset, search: $search, bizId: $bizId, view: $view) {
			objects {
				id
				bizId
				userId
				status
				type
				savedFor
				createdAt
				createdBy
				updatedAt
				lastViewedAt
				comparison {
					name
					filters {
						field
						value
					}
					metrics
					duration {
						preset
						custom {
							startDate
							endDate
						}
					}
				}
			}
			savedCount
			recentlyViewedCount
		}
	}
`;
export const GET_COMPARISON = gql`
	query getComparison($id: String!) {
		getComparison(id: $id) {
			id
			bizId
			userId
			status
			type
			savedFor
			createdBy
			createdAt
			updatedAt
			lastViewedAt
			comparison {
				name
				filters {
					field
					value
				}
				metrics
				duration {
					preset
					custom {
						startDate
						endDate
					}
				}
			}
		}
	}
`;

export const UPDATE_COMPARISON = gql`
	mutation updateComparison($comparisonId: String!, $input: ComparisonInput!) {
		updateComparison(comparisonId: $comparisonId, input: $input) {
			comparisonId
			lastViewedId
			status {
				success
			}
		}
	}
`;

export const DELETE_COMAPARISON = gql`
	mutation deleteComparison($id: String!) {
		deleteComparison(id: $id) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const UPDATE_LAST_VIEWED = gql`
	mutation updateLastViewed($input: UpdateLastViewedInput!) {
		updateLastViewed(input: $input) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_USER_COMPARE_VISIT = gql`
	query getUserCompareVisit($bizId: String!) {
		getUserCompareVisit(bizId: $bizId) {
			id
			bizId
			userId
			dismissed
			hasViewedCompare
			viewedAt
		}
	}
`;
export const UPDATE_USER_COMPARE_VISIT_MUTATION = gql`
	mutation updateUserCompareVisit($input: UpdateUserCompareVisitInput!) {
		updateUserCompareVisit(input: $input) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const SHARE_GLOBAL_LINKS = gql`
	mutation ShareGlobalLinks($input: ShareGlobalAnalyticsInput!) {
		shareGlobalLinks(input: $input) {
			status {
				success
			}
		}
	}
`;

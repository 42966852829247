import React, { Component } from "react";

// components
import { ButtonIcon } from "../components/_commons/ButtonIcon";

// third party
import { connect } from "react-redux";
import { Transition, config, animated } from "react-spring/renderprops";
import history from "../history";
import moment from "moment";

// client
import { store } from "../store/configureStore";

// utils
import { lS, capitaliseText } from "../atlas-utils";

// actions
import { ActionTypes } from "../actions/_types";
import { renderNotificationMessage } from "../actions/notifications";

// config
import { NOTIFICATIONS_ENTITY_MAP, CATALOGUE_PLATFORMS_LOGO } from "../client-config";

@connect((store) => ({
	notificationsList: store?.notificationsList,
	bizId: store?.login?.loggedInbizDetail?.id
}))
class Notifications extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expandMenu: false
		};
	}

	componentDidMount() {
		window.addEventListener("click", this.closeMenu, false);
	}

	componentWillUnmount() {
		window.removeEventListener("click", this.closeMenu, false);
	}

	closeMenu = () => {
		if (this.state.expandMenu) {
			this.setState({
				expandMenu: false
			});
		}
	};

	flipMenu = (e) => {
		e.stopPropagation();
		this.setState({
			expandMenu: !this.state.expandMenu
		});
	};

	handleNotification = (notification, index) => {
		const { payload } = notification;
		history.push(
			`/${NOTIFICATIONS_ENTITY_MAP[payload.entity]?.entity || payload.entity}/${
				NOTIFICATIONS_ENTITY_MAP[payload.entity]?.type || "edit"
			}/${payload.id}`
		);
		this.handleMarkAsRead(payload.id, index, notification.isRead);
		this.closeMenu();
	};

	handleMarkAsRead = (id, index, isRead = false) => {
		if (!isRead) {
			const {
				notificationsList: { data },
				bizId
			} = this.props;
			const updatedData = data.objects.map((notification, i) => {
				if (id === "all") {
					notification.isRead = true;
				} else if (
					(notification.payload ? notification.payload.id === id : notification.message === id) &&
					index === i
				) {
					notification.isRead = true;
				}
				return notification;
			});
			store.dispatch({
				type: ActionTypes.UPDATE_NOTIFICATIONS_LIST,
				payload: {
					objects: updatedData,
					count: id === "all" ? 0 : data.count - 1
				}
			});
			// update notifications in local storage
			lS.set("notifications", {
				...(lS.get("notifications") ?? {}),
				[bizId]: [...updatedData]
			});
		}
	};

	render() {
		let {
			notificationsList: { data }
		} = this.props;
		let { expandMenu } = this.state;
		return (
			<div className="notifications-block" onClick={this.flipMenu}>
				<div className={"notifications-icon"} onClick={() => {}}>
					<ButtonIcon
						icon={`notification-bell${expandMenu ? "-selected" : ""}`}
						color={expandMenu ? "#3F66FF" : "#828282"}
					/>
					{data.count > 0 && <div className="count">{data.count}</div>}
				</div>
				<Transition
					native
					items={expandMenu}
					from={{ opacity: 0, transform: "perspective(800px) rotateX(-90deg)", transformOrigin: "top" }}
					enter={{ opacity: 1, transform: "perspective(800px) rotateX(0deg)", transformOrigin: "top" }}
					leave={{ opacity: 0, transform: "perspective(800px) rotateX(-90deg)", transformOrigin: "top" }}
					config={config.stiff}
				>
					{(isOpen) =>
						isOpen &&
						((props) => (
							<animated.div
								style={props}
								className="notifications-menu"
								onClick={(e) => e.stopPropagation()}
							>
								<div className="header">
									<div className="title">Notifications</div>
									{data.count > 0 && (
										<div className="mark-read" onClick={() => this.handleMarkAsRead("all")}>
											<img src="/assets/icons/icon-mark-as-read.svg" alt="" />
											<div>Mark all as read</div>
										</div>
									)}
								</div>
								{data?.objects?.map((notification, i) => {
									if (notification?.overrideHide || notification?.hide) {
										return null;
									}
									return (
										<div
											key={i}
											className={"message-container " + (!notification.isRead ? "new" : "read")}
										>
											{notification?.payload && !notification?.hideEntity && (
												<div className="section">
													{NOTIFICATIONS_ENTITY_MAP[notification?.payload?.entity]?.section
														? `${
																NOTIFICATIONS_ENTITY_MAP[notification?.payload?.entity]
																	?.section
															}:`
														: ""}{" "}
													<span>
														{NOTIFICATIONS_ENTITY_MAP[notification?.payload?.entity]
															?.name ||
															NOTIFICATIONS_ENTITY_MAP[notification?.payload?.entity]
																?.entity ||
															notification?.payload?.entity}
													</span>
												</div>
											)}
											<div className="message">
												{notification?.payload?.platform && (
													<div
														className="platform-icon"
														title={capitaliseText(notification?.payload?.platform)}
													>
														<img
															src={
																CATALOGUE_PLATFORMS_LOGO[
																	notification?.payload?.platform?.toLowerCase()
																] || "/assets/icons/icons8-globe-40.png"
															}
														/>
													</div>
												)}
												{renderNotificationMessage(notification, this.handleNotification, i)}
											</div>
											<div className="info">
												<div className="date-time">
													{moment(notification.dateTime).isSame(moment(), "day")
														? "Today, " + moment(notification.dateTime).format("hh:mm A")
														: moment(notification.dateTime).isSame(
																	moment().subtract(1, "day"),
																	"day"
															  )
															? "Yesterday, " +
																moment(notification.dateTime).format("hh:mm A")
															: moment(notification.dateTime).format("DD MMM, hh:mm A")}
												</div>
												<div className="actions">
													{!notification.isRead && (
														<div
															title="Mark as read"
															className="mark-as-read"
															onClick={() =>
																this.handleMarkAsRead(
																	notification?.payload
																		? notification.payload.id
																		: notification.message,
																	i,
																	notification.isRead
																)
															}
														>
															<img src="/assets/icons/icon-mark-as-read.svg" alt="" />
														</div>
													)}
												</div>
											</div>
											{!notification.isRead && <div className="new-notification"></div>}
										</div>
									);
								})}
								{data?.objects?.filter((obj) => !obj?.overrideHide && !obj.hide)?.length === 0 && (
									<div className="placeholder">No new notifications.</div>
								)}
							</animated.div>
						))
					}
				</Transition>
			</div>
		);
	}
}
export default Notifications;

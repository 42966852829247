export const ActionTypes = {
	// login
	LOGIN_REQUEST: "LOGIN_REQUEST",
	LOGIN_SUCCESS: "LOGIN_SUCCESS",
	LOGIN_FAILRUE: "LOGIN_FAILURE",
	UPDATE_LOGGEDIN_BIZ_SUCCESS: "UPDATE_LOGGEDIN_BIZ_SUCCESS",
	UPDATE_LOGGEDIN_BIZ_FAILURE: "UPDATE_LOGGEDIN_BIZ_FAILURE",
	UPDATE_LOGGEDIN_BIZ_REQUEST: "UPDATE_LOGGEDIN_BIZ_REQUEST",
	UPDATE_LOGIN_STATE: "UPDATE_LOGIN_STATE",

	// banner
	UPDATE_BANNER_STATE: "UPDATE_BANNER_STATE",

	// rewind
	REWIND_OPEN: "REWIND_OPEN",
	UPDATE_REWIND_STATE: "UPDATE_REWIND_STATE",
	RESET_REWIND_STATE: "RESET_REWIND_STATE",

	// billing recovery
	UPDATE_BILLING_RECOVERY_STATE: "UPDATE_BILLING_RECOVERY_STATE",
	UPDATE_BILLING_RECOVERY_MODAL_STATE: "UPDATE_BILLING_RECOVERY_MODAL_STATE",
	RESET_BILLING_RECOVERY_STATE: "RESET_BILLING_RECOVERY_STATE",

	// customers
	GET_CUSTOMER_DETAILS_REQUEST: "GET_CUSTOMER_DETAILS_REQUEST",
	GET_CUSTOMER_DETAILS_SUCCESS: "GET_CUSTOMER_DETAILS_SUCCESS",
	GET_CUSTOMER_DETAILS_FAILURE: "GET_CUSTOMER_DETAILS_FAILURE",
	UPDATE_CUSTOMER_DETAILS_REQUEST: "UPDATE_CUSTOMER_DETAILS_REQUEST",
	UPDATE_CUSTOMER_DETAILS_SUCCESS: "UPDATE_CUSTOMER_DETAILS_SUCCESS",
	UPDATE_CUSTOMER_DETAILS_FAILURE: "UPDATE_CUSTOMER_DETAILS_FAILURE",
	GET_CUSTOMER_FEEDBACKS_REQUEST: "GET_CUSTOMER_FEEDBACKS_REQUEST",
	GET_CUSTOMER_FEEDBACKS_SUCCESS: "GET_CUSTOMER_FEEDBACKS_SUCCESS",
	GET_CUSTOMER_FEEDBACKS_FAILURE: "GET_CUSTOMER_FEEDBACKS_FAILURE",
	SORT_CUSTOMER_FEEDBACKS: "SORT_CUSTOMER_FEEDBACKS",
	UPDATE_CUSTOMER_FEEDBACKS_LIST: "UPDATE_CUSTOMER_FEEDBACKS_LIST",
	GET_CUSTOMER_NOTES_REQUEST: "GET_CUSTOMER_NOTES_REQUEST",
	GET_CUSTOMER_NOTES_SUCCESS: "GET_CUSTOMER_NOTES_SUCCESS",
	GET_CUSTOMER_NOTES_FAILURE: "GET_CUSTOMER_NOTES_FAILURE",
	UPDATE_CUSTOMER_NOTES_REQUEST: "UPDATE_CUSTOMER_NOTES_REQUEST",
	UPDATE_CUSTOMER_NOTES_SUCCESS: "UPDATE_CUSTOMER_NOTES_SUCCESS",
	UPDATE_CUSTOMER_NOTES_FAILURE: "UPDATE_CUSTOMER_NOTES_FAILURE",
	GET_CUSTOMER_TIMELINE_REQUEST: "GET_CUSTOMER_TIMELINE_REQUEST",
	GET_CUSTOMER_TIMELINE_SUCCESS: "GET_CUSTOMER_TIMELINE_SUCCESS",
	GET_CUSTOMER_TIMELINE_FAILURE: "GET_CUSTOMER_TIMELINE_FAILURE",
	UPDATE_CUSTOMER_TIMELINE_LIST_STATE: "UPDATE_CUSTOMER_TIMELINE_LIST_STATE",

	// advanced analytics (v2)
	ANALYTICS_FILTERS_STATE_CHANGE: "ANALYTICS_FILTERS_STATE_CHANGE",
	ANALYTICS_FILTERS_STATE_RESET: "ANALYTICS_FILTERS_STATE_RESET",
	UPDATE_REVENUE_ANALYTICS_STATE: "UPDATE_REVENUE_ANALYTICS_STATE",
	UPDATE_ORDER_ANALYTICS_STATE: "UPDATE_ORDER_ANALYTICS_STATE",
	UPDATE_OPERATIONS_ANALYTICS_STATE: "UPDATE_OPERATIONS_ANALYTICS_STATE",
	UPDATE_CATALOGUE_ANALYTICS_STATE: "UPDATE_CATALOGUE_ANALYTICS_STATE",
	UPDATE_ANALYTICS_ENTITY_DETAIL_INIT_STATE: "UPDATE_ANALYTICS_ENTITY_DETAIL_INIT_STATE",
	UPDATE_ANALYTICS_ENTITY_DETAIL_STATE: "UPDATE_ANALYTICS_ENTITY_DETAIL_STATE",
	RESET_ANALYTICS_ENTITY_DETAIL_STATE: "RESET_ANALYTICS_ENTITY_DETAIL_STATE",

	//compare
	UPDATE_COMPARE_ANALYTICS_INITIAL_COLUMN_DATA: "UPDATE_COMPARE_ANALYTICS_INITIAL_COLUMN_DATA",
	UPDATE_COMPARE_ANALYTICS_CHANGE_STATE: "UPDATE_COMPARE_ANALYTICS_CHANGE_STATE",
	ANALYTICS_FILTERS_STATE_RESET: "ANALYTICS_FILTERS_STATE_RESET",
	UPDATE_COMPARE_ANALYTICS_TABLE_DATA: "UPDATE_COMPARE_ANALYTICS_TABLE_DATA",
	UPDATE_COMPARE_ANALYTICS_ENTITY_DETAIL_STATE: "UPDATE_COMPARE_ANALYTICS_ENTITY_DETAIL_STATE",
	UPDATE_SAVED_COMPARISONS: "UPDATE_SAVED_COMPARISONS",

	UPDATE_ANALYTICS_REPORT_STATE: "UPDATE_ANALYTICS_REPORT_STATE",

	// sales analytics
	UPDATE_SA_STATE: "UPDATE_SA_STATE",
	RESET_SA_STATE: "RESET_SA_STATE",
	GET_SA_TOPICS_REQUEST: "GET_SA_TOPICS_REQUEST",
	GET_SA_TOPICS_SUCCESS: "GET_SA_TOPICS_SUCCESS",
	GET_SA_TOPICS_FAILURE: "GET_SA_TOPICS_FAILURE",
	GET_SA_TRENDS_CHANNELS_REQUEST: "GET_SA_TRENDS_CHANNELS_REQUEST",
	GET_SA_TRENDS_CHANNELS_SUCCESS: "GET_SA_TRENDS_CHANNELS_SUCCESS",
	GET_SA_TRENDS_CHANNELS_FAILURE: "GET_SA_TRENDS_CHANNELS_FAILURE",
	GET_SA_TRENDS_GRAPH_REQUEST: "GET_SA_TRENDS_GRAPH_REQUEST",
	GET_SA_TRENDS_GRAPH_SUCCESS: "GET_SA_TRENDS_GRAPH_SUCCESS",
	GET_SA_TRENDS_GRAPH_FAILURE: "GET_SA_TRENDS_GRAPH_FAILURE",
	GET_SA_PERFORMANCE_REQUEST: "GET_SA_PERFORMANCE_REQUEST",
	GET_SA_PERFORMANCE_SUCCESS: "GET_SA_PERFORMANCE_SUCCESS",
	GET_SA_PERFORMANCE_FAILURE: "GET_SA_PERFORMANCE_FAILURE",

	//operational efficiency metrics
	UPDATE_OE_STATE: "UPDATE_OE_STATE",
	RESET_OE_STATE: "RESET_OE_STATE",
	GET_OPERATIONAL_EFFICIENCY_FILTERS_REQUEST: "GET_OPERATIONAL_EFFIECIENCY_FILTERS_REQUEST",
	GET_OPERATIONAL_EFFICIENCY_FILTERS_SUCCESS: "GET_OPERATIONAL_EFFIECIENCY_FILTERS_SUCCESS",
	GET_OPERATIONAL_EFFICIENCY_FILTERS_FAILURE: "GET_OPERATIONAL_EFFIECIENCY_FILTERS_FAILURE",
	GET_OPERATIONAL_EFFICIENCY_METRICS_REQUEST: "GET_OPERATIONAL_EFFIECIENCY_METRICS_REQUEST",
	GET_OPERATIONAL_EFFICIENCY_METRICS_SUCCESS: "GET_OPERATIONAL_EFFIECIENCY_METRICS_SUCCESS",
	GET_OPERATIONAL_EFFICIENCY_METRICS_FAILURE: "GET_OPERATIONAL_EFFIECIENCY_METRICS_FAILURE",
	GET_OPERATIONAL_EFFICIENCY_METRICS_LOCATION_LEVEL_REQUEST:
		"GET_OPERATIONAL_EFFIECIENCY_METRICS_LOCATION_LEVEL_REQUEST",
	GET_OPERATIONAL_EFFICIENCY_METRICS_LOCATION_LEVEL_SUCCESS:
		"GET_OPERATIONAL_EFFIECIENCY_METRICS_LOCATION_LEVEL_SUCCESS",
	GET_OPERATIONAL_EFFICIENCY_METRICS_LOCATION_LEVEL_FAILURE:
		"GET_OPERATIONAL_EFFIECIENCY_METRICS_LOCATION_LEVEL_FAILURE",
	GET_OPERATIONAL_EFFICIENCY_LIST_VIEW_REQUEST: "GET_OPERATIONAL_EFFIECIENCY_LIST_VIEW_REQUEST",
	GET_OPERATIONAL_EFFICIENCY_LIST_VIEW_SUCCESS: "GET_OPERATIONAL_EFFIECIENCY_LIST_VIEW_SUCCESS",
	GET_OPERATIONAL_EFFICIENCY_LIST_VIEW_FAILURE: "GET_OPERATIONAL_EFFIECIENCY_LIST_VIEW_FAILURE",
	OPERATIONAL_EFFICIENCY_TRANSACTION_LIST_STATE_CHANGE: "OPERATIONAL_EFFICIENCY_TRANSACTION_LIST_STATE_CHANGE",
	OPERATIONAL_EFFICIENCY_METRICS_BIZ_LOCATION_LEVEL_LIST_STATE_CHANGE:
		"OPERATIONAL_EFFICIENCY_METRICS_BIZ_LOCATION_LEVEL_LIST_STATE_CHANGE",

	// invoices
	GET_INVOICES_LIST_VIEW_REQUEST: "GET_INVOICES_LIST_VIEW_REQUEST",
	GET_INVOICES_LIST_VIEW_SUCCESS: "GET_INVOICES_LIST_VIEW_SUCCESS",
	GET_INVOICES_LIST_VIEW_FAILURE: "GET_INVOICES_LIST_VIEW_FAILURE",
	GET_INVOICE_DETAIL_VIEW_REQUEST: "GET_INVOICE_DETAIL_VIEW_REQUEST",
	GET_INVOICE_DETAIL_VIEW_SUCCESS: "GET_INVOICE_DETAIL_VIEW_SUCCESS",
	GET_INVOICE_DETAIL_VIEW_FAILURE: "GET_INVOICE_DETAIL_VIEW_FAILURE",
	GET_OUTSTANDING_INVOICES_REQUEST: "GET_OUTSTANDING_INVOICES_REQUEST",
	GET_OUTSTANDING_INVOICES_SUCESS: "GET_OUTSTANDING_INVOICES_SUCCESS",
	GET_OUTSTANDING_INVOICES_FAILURE: "GET_OUTSTANDING_INVOICES_FAILURE",

	// misc
	SHOW_GLOBAL_MESSAGE: "SHOW_GLOBAL_MESSAGE",
	HIDE_GLOBAL_MESSAGE: "HIDE_GLOBAL_MESSAGE",
	SHOW_CLOSE_DRAWER_INSTRUCTION: "SHOW_CLOSE_DRAWER_INSTRUCTION",
	HIDE_CLOSE_DRAWER_INSTRUCTION: "HIDE_CLOSE_DRAWER_INSTRUCTION",

	// locations
	LOCATIONS_LIST_STATE_CHANGE: "LOCATIONS_LIST_STATE_CHANGE",
	LOCATIONS_LIST_STATE_CHANGE_SORT: "LOCATIONS_LIST_STATE_CHANGE_SORT",
	LOCATIONS_LIST_STATE_RESET: "LOCATIONS_LIST_STATE_RESET",
	GET_LOCATIONS_LIST_REQUEST: "GET_LOCATIONS_LIST_REQUEST",
	GET_LOCATIONS_LIST_SUCCESS: "GET_LOCATIONS_LIST_SUCCESS",
	GET_LOCATIONS_LIST_FAILURE: "GET_LOCATIONS_LIST_FAILURE",
	UPDATE_LOCATIONS_LIST: "UPDATE_LOCATIONS_LIST",
	UPDATE_SELECTED_PLATFORM: "UPDATE_SELECTED_PLATFORM",
	LOCATIONS_LIST_SEARCH: "LOCATIONS_LIST_SEARCH",
	UPDATE_CREATE_LOCATION_STATUS: "UPDATE_CREATE_LOCATION_STATUS",
	GET_LOCATION_DETAIL_REQUEST: "GET_LOCATION_DETAIL_REQUEST",
	GET_LOCATION_DETAIL_SUCCESS: "GET_LOCATION_DETAIL_SUCCESS",
	GET_LOCATION_DETAIL_FAILURE: "GET_LOCATION_DETAIL_FAILURE",
	EDIT_LOCATION_DETAIL_REQUEST: "EDIT_LOCATION_DETAIL_REQUEST",
	EDIT_LOCATION_DETAIL_SUCCESS: "EDIT_LOCATION_DETAIL_SUCCESS",
	EDIT_LOCATION_DETAIL_FAILURE: "EDIT_LOCATION_DETAIL_FAILURE",
	REDIRECT_TO_LOCATION_DETAIL: "REDIRECT_TO_LOCATION_DETAIL",
	CLEAR_REDIRECTION: "CLEAR_REDIRECTION",
	UPDATE_LOCATION_DETAIL: "UPDATE_LOCATION_DETAIL",
	UPDATE_LOCATION_BACKUP_DATA: "UPDATE_LOCATION_BACKUP_DATA",
	SET_ILPA_LOGS_LOADING: "SET_ILPA_LOGS_LOADING",
	SET_ILPA_ACTION_LOGS_LOADING: "SET_ILPA_ACTION_LOGS_LOADING",
	SET_VERIFICATION_STATUS_LOADING: "SET_VERIFICATION_STATUS_LOADING",

	// categories
	CATEGORIES_LIST_STATE_CHANGE: "CATEGORIES_LIST_STATE_CHANGE",
	CATEGORIES_LIST_STATE_CHANGE_SORT: "CATEGORIES_LIST_STATE_CHANGE_SORT",
	CATEGORIES_LIST_STATE_RESET: "CATEGORIES_LIST_STATE_RESET",
	GET_CATEGORIES_LIST_REQUEST: "GET_CATEGORIES_LIST_REQUEST",
	GET_CATEGORIES_LIST_SUCCESS: "GET_CATEGORIES_LIST_SUCCESS",
	GET_CATEGORIES_LIST_FAILURE: "GET_CATEGORIES_LIST_FAILURE",
	UPDATE_CATEGORIES_LIST_STATE: "UPDATE_CATEGORIES_LIST_STATE",
	GET_CATEGORY_DETAIL_REQUEST: "GET_CATEGORY_DETAIL_REQUEST",
	GET_CATEGORY_DETAIL_SUCCESS: "GET_CATEGORY_DETAIL_SUCCESS",
	GET_CATEGORY_DETAIL_FAILURE: "GET_CATEGORY_DETAIL_FAILURE",
	UPDATE_CATEGORY_DETAIL: "UPDATE_CATEGORY_DETAIL",
	EDIT_CATEGORY_DETAIL_REQUEST: "EDIT_CATEGORY_DETAIL_REQUEST",
	EDIT_CATEGORY_DETAIL_SUCCESS: "EDIT_CATEGORY_DETAIL_SUCCESS",
	EDIT_CATEGORY_DETAIL_FAILURE: "EDIT_CATEGORY_DETAIL_FAILURE",
	GET_CATEGORY_ITEMS_REQUEST: "GET_CATEGORY_ITEMS_REQUEST",
	GET_CATEGORY_ITEMS_SUCCESS: "GET_CATEGORY_ITEMS_SUCCESS",
	GET_CATEGORY_ITEMS_FAILURE: "GET_CATEGORY_ITEMS_FAILURE",
	CATEGORY_ITEMS_STATE_CHANGE: "CATEGORY_ITEMS_STATE_CHANGE",
	CATEGORY_ITEMS_STATE_CHANGE_SORT: "CATEGORY_ITEMS_STATE_CHANGE_SORT",
	CATEGORY_ITEMS_STATE_RESET: "CATEGORY_ITEMS_STATE_RESET",

	// items
	CATALOGUE_ITEMS_LIST_STATE_CHANGE: "CATALOGUE_ITEMS_LIST_STATE_CHANGE",
	CATALOGUE_ITEMS_LIST_STATE_CHANGE_SORT: "CATALOGUE_ITEMS_LIST_STATE_CHANGE_SORT",
	CATALOGUE_ITEMS_LIST_STATE_RESET: "CATALOGUE_ITEMS_LIST_STATE_RESET",
	GET_CATALOGUE_ITEMS_LIST_REQUEST: "GET_CATALOGUE_ITEMS_LIST_REQUEST",
	GET_CATALOGUE_ITEMS_LIST_SUCCESS: "GET_CATALOGUE_ITEMS_LIST_SUCCESS",
	GET_CATALOGUE_ITEMS_LIST_FAILURE: "GET_CATALOGUE_ITEMS_LIST_FAILURE",
	UPDATE_CATALOGUE_ITEMS_LIST: "UPDATE_CATALOGUE_ITEMS_LIST",
	CATALOGUE_ITEMS_LIST_SEARCH: "CATALOGUE_ITEMS_LIST_SEARCH",
	GET_CATALOGUE_ITEM_DETAIL_REQUEST: "GET_CATALOGUE_ITEM_DETAILS_REQUEST",
	GET_CATALOGUE_ITEM_DETAIL_SUCCESS: "GET_CATALOGUE_ITEM_DETAILS_SUCCESS",
	GET_CATALOGUE_ITEM_DETAIL_FAILURE: "GET_CATALOGUE_ITEM_DETAILS_FAILURE",
	EDIT_CATALOGUE_ITEM_DETAIL_REQUEST: "EDIT_CATALOGUE_ITEM_DETAILS_REQUEST",
	EDIT_CATALOGUE_ITEM_DETAIL_SUCCESS: "EDIT_CATALOGUE_ITEM_DETAILS_SUCCESS",
	EDIT_CATALOGUE_ITEM_DETAIL_FAILURE: "EDIT_CATALOGUE_ITEM_DETAILS_FAILURE",
	UPDATE_CATALOGUE_ITEM_DETAIL: "UPDATE_CATALOGUE_ITEM_DETAIL",
	CATALOGUE_ITEMS_LIST_UPDATE_STORES_LOOKUP: "CATALOGUE_ITEMS_LIST_UPDATE_STORES_LOOKUP",

	// modifier groups
	MODIFIER_GROUPS_LIST_STATE_CHANGE: "MODIFIER_GROUPS_LIST_STATE_CHANGE",
	MODIFIER_GROUPS_LIST_STATE_CHANGE_SORT: "MODIFIER_GROUPS_LIST_STATE_CHANGE_SORT",
	MODIFIER_GROUPS_LIST_STATE_RESET: "MODIFIER_GROUPS_LIST_STATE_RESET",
	GET_MODIFIER_GROUPS_LIST_REQUEST: "GET_MODIFIER_GROUPS_LIST_REQUEST",
	GET_MODIFIER_GROUPS_LIST_SUCCESS: "GET_MODIFIER_GROUPS_LIST_SUCCESS",
	GET_MODIFIER_GROUPS_LIST_FAILURE: "GET_MODIFIER_GROUPS_LIST_FAILURE",
	UPDATE_MODIFIER_GROUPS_LIST: "UPDATE_MODIFIER_GROUPS_LIST",
	MODIFIER_GROUPS_LIST_SEARCH: "MODIFIER_GROUPS_LIST_SEARCH",
	GET_MODIFIER_GROUP_DETAIL_REQUEST: "GET_MODIFIER_GROUP_DETAIL_REQUEST",
	GET_MODIFIER_GROUP_DETAIL_SUCCESS: "GET_MODIFIER_GROUP_DETAIL_SUCCESS",
	GET_MODIFIER_GROUP_DETAIL_FAILURE: "GET_MODIFIER_GROUP_DETAIL_FAILURE",
	EDIT_MODIFIER_GROUP_DETAIL_REQUEST: "EDIT_MODIFIER_GROUP_DETAIL_REQUEST",
	EDIT_MODIFIER_GROUP_DETAIL_SUCCESS: "EDIT_MODIFIER_GROUP_DETAIL_SUCCESS",
	EDIT_MODIFIER_GROUP_DETAIL_FAILURE: "EDIT_MODIFIER_GROUP_DETAIL_FAILURE",
	UPDATE_MODIFIER_GROUP_DETAIL: "UPDATE_MODIFIER_GROUP_DETAIL",

	// modifiers
	MODIFIERS_LIST_STATE_CHANGE: "MODIFIERS_LIST_STATE_CHANGE",
	MODIFIERS_LIST_STATE_CHANGE_SORT: "MODIFIERS_LIST_STATE_CHANGE_SORT",
	MODIFIERS_LIST_STATE_RESET: "MODIFIERS_LIST_STATE_RESET",
	GET_MODIFIERS_LIST_REQUEST: "GET_MODIFIERS_LIST_REQUEST",
	GET_MODIFIERS_LIST_SUCCESS: "GET_MODIFIERS_LIST_SUCCESS",
	GET_MODIFIERS_LIST_FAILURE: "GET_MODIFIERS_LIST_FAILURE",
	UPDATE_MODIFIERS_LIST: "UPDATE_MODIFIERS_LIST",
	MODIFIERS_LIST_SEARCH: "MODIFIERS_LIST_SEARCH",
	GET_MODIFIER_DETAIL_REQUEST: "GET_MODIFIER_DETAIL_REQUEST",
	GET_MODIFIER_DETAIL_SUCCESS: "GET_MODIFIER_DETAIL_SUCCESS",
	GET_MODIFIER_DETAIL_FAILURE: "GET_MODIFIER_DETAIL_FAILURE",
	EDIT_MODIFIER_DETAIL_REQUEST: "EDIT_MODIFIER_DETAIL_REQUEST",
	EDIT_MODIFIER_DETAIL_SUCCESS: "EDIT_MODIFIER_DETAIL_SUCCESS",
	EDIT_MODIFIER_DETAIL_FAILURE: "EDIT_MODIFIER_DETAIL_FAILURE",
	UPDATE_MODIFIER_DETAIL: "UPDATE_MODIFIER_DETAIL",

	// taxes
	TAXES_LIST_STATE_CHANGE: "TAXES_LIST_STATE_CHANGE",
	TAXES_LIST_STATE_CHANGE_SORT: "TAXES_LIST_STATE_CHANGE_SORT",
	TAXES_LIST_STATE_RESET: "TAXES_LIST_STATE_RESET",
	TAXES_LIST_UPDATE_ITEMS_LOOKUP: "TAXES_LIST_UPDATE_ITEMS_LOOKUP",
	TAXES_LIST_UPDATE_STORES_LOOKUP: "TAXES_LIST_UPDATE_STORES_LOOKUP",
	GET_TAXES_LIST_REQUEST: "GET_TAXES_LIST_REQUEST",
	GET_TAXES_LIST_SUCCESS: "GET_TAXES_LIST_SUCCESS",
	GET_TAXES_LIST_FAILURE: "GET_TAXES_LIST_FAILURE",
	UPDATE_TAXES_LIST: "UPDATE_TAXES_LIST",
	GET_TAX_DETAIL_REQUEST: "GET_TAX_DETAIL_REQUEST",
	GET_TAX_DETAIL_SUCCESS: "GET_TAX_DETAIL_SUCCESS",
	GET_TAX_DETAIL_FAILURE: "GET_TAX_DETAIL_FAILURE",
	EDIT_TAX_DETAIL_REQUEST: "EDIT_TAX_DETAIL_REQUEST",
	EDIT_TAX_DETAIL_SUCCESS: "EDIT_TAX_DETAIL_SUCCESS",
	EDIT_TAX_DETAIL_FAILURE: "EDIT_TAX_DETAIL_FAILURE",
	UPDATE_TAX_DETAIL: "UPDATE_TAX_DETAIL",

	// item groups
	ITEM_GROUPS_LIST_STATE_CHANGE: "ITEM_GROUPS_LIST_STATE_CHANGE",
	ITEM_GROUPS_LIST_STATE_CHANGE_SORT: "ITEM_GROUPS_LIST_STATE_CHANGE_SORT",
	ITEM_GROUPS_LIST_STATE_RESET: "ITEM_GROUPS_LIST_STATE_RESET",
	GET_ITEM_GROUPS_LIST_REQUEST: "GET_ITEM_GROUPS_LIST_REQUEST",
	GET_ITEM_GROUPS_LIST_SUCCESS: "GET_ITEM_GROUPS_LIST_SUCCESS",
	GET_ITEM_GROUPS_LIST_FAILURE: "GET_ITEM_GROUPS_LIST_FAILURE",
	UPDATE_ITEM_GROUPS_LIST: "UPDATE_ITEM_GROUPS_LIST",
	GET_ITEM_GROUPS_DETAIL_REQUEST: "GET_ITEM_GROUPS_DETAIL_REQUEST",
	GET_ITEM_GROUPS_DETAIL_SUCCESS: "GET_ITEM_GROUPS_DETAIL_SUCCESS",
	GET_ITEM_GROUPS_DETAIL_FAILURE: "GET_ITEM_GROUPS_DETAIL_FAILURE",
	RESET_ITEM_GROUPS_DETAIL: "RESET_ITEM_GROUPS_DETAIL",
	EDIT_ITEM_GROUPS_DETAIL_REQUEST: "EDIT_ITEM_GROUPS_DETAIL_REQUEST",
	EDIT_ITEM_GROUPS_DETAIL_SUCCESS: "EDIT_ITEM_GROUPS_DETAIL_SUCCESS",
	EDIT_ITEM_GROUPS_DETAIL_FAILURE: "EDIT_ITEM_GROUPS_DETAIL_FAILURE",
	UPDATE_ITEM_GROUPS_DETAIL: "UPDATE_ITEM_GROUPS_DETAIL",

	// location groups
	LOCATION_GROUPS_LIST_STATE_CHANGE: "LOCATION_GROUPS_LIST_STATE_CHANGE",
	LOCATION_GROUPS_LIST_STATE_CHANGE_SORT: "LOCATION_GROUPS_LIST_STATE_CHANGE_SORT",
	LOCATION_GROUPS_LIST_STATE_RESET: "LOCATION_GROUPS_LIST_STATE_RESET",
	GET_LOCATION_GROUPS_LIST_REQUEST: "GET_LOCATION_GROUPS_LIST_REQUEST",
	GET_LOCATION_GROUPS_LIST_SUCCESS: "GET_LOCATION_GROUPS_LIST_SUCCESS",
	GET_LOCATION_GROUPS_LIST_FAILURE: "GET_LOCATION_GROUPS_LIST_FAILURE",
	UPDATE_LOCATION_GROUPS_LIST: "UPDATE_LOCATION_GROUPS_LIST",
	GET_LOCATION_GROUPS_DETAIL_REQUEST: "GET_LOCATION_GROUPS_DETAIL_REQUEST",
	GET_LOCATION_GROUPS_DETAIL_SUCCESS: "GET_LOCATION_GROUPS_DETAIL_SUCCESS",
	GET_LOCATION_GROUPS_DETAIL_FAILURE: "GET_LOCATION_GROUPS_DETAIL_FAILURE",
	RESET_LOCATION_GROUPS_DETAIL: "RESET_LOCATION_GROUPS_DETAIL",
	EDIT_LOCATION_GROUPS_DETAIL_REQUEST: "EDIT_LOCATION_GROUPS_DETAIL_REQUEST",
	EDIT_LOCATION_GROUPS_DETAIL_SUCCESS: "EDIT_LOCATION_GROUPS_DETAIL_SUCCESS",
	EDIT_LOCATION_GROUPS_DETAIL_FAILURE: "EDIT_LOCATION_GROUPS_DETAIL_FAILURE",
	UPDATE_LOCATION_GROUPS_DETAIL: "UPDATE_LOCATION_GROUPS_DETAIL",

	// timing groups
	TIMING_GROUPS_LIST_STATE_CHANGE: "TIMING_GROUPS_LIST_STATE_CHANGE",
	TIMING_GROUPS_LIST_STATE_CHANGE_SORT: "TIMING_GROUPS_LIST_STATE_CHANGE_SORT",
	TIMING_GROUPS_LIST_STATE_RESET: "TIMING_GROUPS_LIST_STATE_RESET",

	GET_TIMING_GROUPS_LIST_REQUEST: "GET_TIMING_GROUPS_LIST_REQUEST",
	GET_TIMING_GROUPS_LIST_SUCCESS: "GET_TIMING_GROUPS_LIST_SUCCESS",
	GET_TIMING_GROUPS_LIST_FAILURE: "GET_TIMING_GROUPS_LIST_FAILURE",
	UPDATE_TIMING_GROUPS_LIST: "UPDATE_TIMING_GROUPS_LIST",

	GET_HOLIDAY_SCHEDULE_LIST_REQUEST: "GET_HOLIDAY_SCHEDULE_LIST_REQUEST",
	GET_HOLIDAY_SCHEDULE_LIST_SUCCESS: "GET_HOLIDAY_SCHEDULE_LIST_SUCCESS",
	GET_HOLIDAY_SCHEDULE_LIST_FAILURE: "GET_HOLIDAY_SCHEDULE_LIST_FAILURE",
	UPDATE_HOLIDAY_SCHEDULE_LIST: "UPDATE_HOLIDAY_SCHEDULE_LIST",

	GET_TIMING_GROUPS_DETAIL_REQUEST: "GET_TIMING_GROUPS_DETAIL_REQUEST",
	GET_TIMING_GROUPS_DETAIL_SUCCESS: "GET_TIMING_GROUPS_DETAIL_SUCCESS",
	GET_TIMING_GROUPS_DETAIL_FAILURE: "GET_TIMING_GROUPS_DETAIL_FAILURE",
	RESET_TIMING_GROUPS_DETAIL: "RESET_TIMING_GROUPS_DETAIL",

	EDIT_TIMING_GROUPS_DETAIL_REQUEST: "EDIT_TIMING_GROUPS_DETAIL_REQUEST",
	EDIT_TIMING_GROUPS_DETAIL_SUCCESS: "EDIT_TIMING_GROUPS_DETAIL_SUCCESS",
	EDIT_TIMING_GROUPS_DETAIL_FAILURE: "EDIT_TIMING_GROUPS_DETAIL_FAILURE",
	UPDATE_TIMING_GROUPS_DETAIL: "UPDATE_TIMING_GROUPS_DETAIL",

	GET_HOLIDAY_SCHEDULE_DETAIL_REQUEST: "GET_HOLIDAY_SCHEDULE_DETAIL_REQUEST",
	GET_HOLIDAY_SCHEDULE_DETAIL_SUCCESS: "GET_HOLIDAY_SCHEDULE_DETAIL_SUCCESS",
	GET_HOLIDAY_SCHEDULE_DETAIL_FAILURE: "GET_HOLIDAY_SCHEDULE_DETAIL_FAILURE",
	RESET_HOLIDAY_SCHEDULE_DETAIL: "RESET_HOLIDAY_SCHEDULE_DETAIL",

	EDIT_HOLIDAY_SCHEDULE_DETAIL_REQUEST: "EDIT_HOLIDAY_SCHEDULE_DETAIL_REQUEST",
	EDIT_HOLIDAY_SCHEDULE_DETAIL_SUCCESS: "EDIT_HOLIDAY_SCHEDULE_DETAIL_SUCCESS",
	EDIT_HOLIDAY_SCHEDULE_DETAIL_FAILURE: "EDIT_HOLIDAY_SCHEDULE_DETAIL_FAILURE",
	UPDATE_HOLIDAY_SCHEDULE_DETAIL: "UPDATE_HOLIDAY_SCHEDULE_DETAIL",

	// charges
	CHARGES_LIST_STATE_CHANGE: "CHARGES_LIST_STATE_CHANGE",
	CHARGES_LIST_STATE_CHANGE_SORT: "CHARGES_LIST_STATE_CHANGE_SORT",
	CHARGES_LIST_STATE_RESET: "CHARGES_LIST_STATE_RESET",
	CHARGES_LIST_UPDATE_ITEMS_LOOKUP: "CHARGES_LIST_UPDATE_ITEMS_LOOKUP",
	CHARGES_LIST_UPDATE_STORES_LOOKUP: "CHARGES_LIST_UPDATE_STORES_LOOKUP",
	GET_CHARGES_LIST_REQUEST: "GET_CHARGES_LIST_REQUEST",
	GET_CHARGES_LIST_SUCCESS: "GET_CHARGES_LIST_SUCCESS",
	GET_CHARGES_LIST_FAILURE: "GET_CHARGES_LIST_FAILURE",
	UPDATE_CHARGES_LIST: "UPDATE_CHARGES_LIST",
	GET_CHARGE_DETAIL_REQUEST: "GET_CHARGE_DETAIL_REQUEST",
	GET_CHARGE_DETAIL_SUCCESS: "GET_CHARGE_DETAIL_SUCCESS",
	GET_CHARGE_DETAIL_FAILURE: "GET_CHARGE_DETAIL_FAILURE",
	EDIT_CHARGE_DETAIL_REQUEST: "EDIT_CHARGE_DETAIL_REQUEST",
	EDIT_CHARGE_DETAIL_SUCCESS: "EDIT_CHARGE_DETAIL_SUCCESS",
	EDIT_CHARGE_DETAIL_FAILURE: "EDIT_CHARGE_DETAIL_FAILURE",
	UPDATE_CHARGE_DETAIL: "UPDATE_CHARGE_DETAIL",

	// users
	USERS_LIST_STATE_CHANGE: "USERS_LIST_STATE_CHANGE",
	USERS_LIST_STATE_CHANGE_SORT: "USERS_LIST_STATE_CHANGE_SORT",
	USERS_LIST_STATE_RESET: "USERS_LIST_STATE_RESET",
	GET_USERS_LIST_REQUEST: "GET_USERS_LIST_REQUEST",
	GET_USERS_LIST_SUCCESS: "GET_USERS_LIST_SUCCESS",
	GET_USERS_LIST_FAILURE: "GET_USERS_LIST_FAILURE",
	UPDATE_USERS_LIST: "UPDATE_USERS_LIST",
	GET_USERS_DETAIL_REQUEST: "GET_USERS_DETAIL_REQUEST",
	GET_USERS_DETAIL_SUCCESS: "GET_USERS_DETAIL_SUCCESS",
	GET_USERS_DETAIL_FAILURE: "GET_USERS_DETAIL_FAILURE",
	RESET_USERS_DETAIL: "RESET_USERS_DETAIL",
	EDIT_USERS_DETAIL_REQUEST: "EDIT_USERS_DETAIL_REQUEST",
	EDIT_USERS_DETAIL_SUCCESS: "EDIT_USERS_DETAIL_SUCCESS",
	EDIT_USERS_DETAIL_FAILURE: "EDIT_USERS_DETAIL_FAILURE",
	UPDATE_USERS_DETAIL: "UPDATE_USERS_DETAIL",

	// unified users
	UNIFIED_USERS_LIST_STATE_CHANGE: "UNIFIED_USERS_LIST_STATE_CHANGE",
	UNIFIED_USERS_LIST_STATE_CHANGE_SORT: "UNIFIED_USERS_LIST_STATE_CHANGE_SORT",
	UNIFIED_USERS_LIST_STATE_RESET: "UNIFIED_USERS_LIST_STATE_RESET",
	GET_UNIFIED_USERS_LIST_REQUEST: "GET_UNIFIED_USERS_LIST_REQUEST",
	GET_UNIFIED_USERS_LIST_SUCCESS: "GET_UNIFIED_USERS_LIST_SUCCESS",
	GET_UNIFIED_USERS_LIST_FAILURE: "GET_UNIFIED_USERS_LIST_FAILURE",
	UPDATE_UNIFIED_USERS_LIST: "UPDATE_UNIFIED_USERS_LIST",
	UNIFIED_USERS_LIST_SEARCH: "UNIFIED_USERS_LIST_SEARCH",
	ULE_USER_ACTION_REQUEST: "ULE_USER_ACTION_REQUEST",
	ULE_USER_ACTION_SUCCESS: "ULE_USER_ACTION_SUCCESS",
	ULE_USER_ACTION_FAILURE: "ULE_USER_ACTION_FAILURE",
	GET_UNIFIED_USER_DETAIL_REQUEST: "GET_UNIFIED_USER_DETAIL_REQUEST",
	GET_UNIFIED_USER_DETAIL_SUCCESS: "GET_UNIFIED_USER_DETAIL_SUCCESS",
	GET_UNIFIED_USER_DETAIL_FAILURE: "GET_UNIFIED_USER_DETAIL_FAILURE",
	RESET_UNIFIED_USER_DETAIL: "RESET_UNIFIED_USER_DETAIL",
	EDIT_UNIFIED_USER_DETAIL_REQUEST: "EDIT_UNIFIED_USER_DETAIL_REQUEST",
	EDIT_UNIFIED_USER_DETAIL_SUCCESS: "EDIT_UNIFIED_USER_DETAIL_SUCCESS",
	EDIT_UNIFIED_USER_DETAIL_FAILURE: "EDIT_UNIFIED_USER_DETAIL_FAILURE",
	UPDATE_UNIFIED_USER_DETAIL: "UPDATE_UNIFIED_USER_DETAIL",

	// roles
	ROLES_LIST_STATE_CHANGE: "ROLES_LIST_STATE_CHANGE",
	ROLES_LIST_STATE_CHANGE_SORT: "ROLES_LIST_STATE_CHANGE_SORT",
	ROLES_LIST_STATE_RESET: "ROLES_LIST_STATE_RESET",
	GET_ROLES_LIST_REQUEST: "GET_ROLES_LIST_REQUEST",
	GET_ROLES_LIST_SUCCESS: "GET_ROLES_LIST_SUCCESS",
	GET_ROLES_LIST_FAILURE: "GET_ROLES_LIST_FAILURE",
	UPDATE_ROLES_LIST: "UPDATE_ROLES_LIST",
	GET_ROLE_DETAIL_REQUEST: "GET_ROLE_DETAIL_REQUEST",
	GET_ROLE_DETAIL_SUCCESS: "GET_ROLE_DETAIL_SUCCESS",
	GET_ROLE_DETAIL_FAILURE: "GET_ROLE_DETAIL_FAILURE",
	RESET_ROLE_DETAIL: "RESET_ROLE_DETAIL",
	EDIT_ROLE_DETAIL_REQUEST: "EDIT_ROLE_DETAIL_REQUEST",
	EDIT_ROLE_DETAIL_SUCCESS: "EDIT_ROLE_DETAIL_SUCCESS",
	EDIT_ROLE_DETAIL_FAILURE: "EDIT_ROLE_DETAIL_FAILURE",
	UPDATE_ROLE_DETAIL: "UPDATE_ROLE_DETAIL",

	// api accounts
	API_ACCOUNTS_LIST_STATE_CHANGE: "API_ACCOUNTS_LIST_STATE_CHANGE",
	API_ACCOUNTS_LIST_STATE_CHANGE_SORT: "API_ACCOUNTS_LIST_STATE_CHANGE_SORT",
	API_ACCOUNTS_LIST_STATE_RESET: "API_ACCOUNTS_LIST_STATE_RESET",
	GET_API_ACCOUNTS_LIST_REQUEST: "GET_API_ACCOUNTS_LIST_REQUEST",
	GET_API_ACCOUNTS_LIST_SUCCESS: "GET_API_ACCOUNTS_LIST_SUCCESS",
	GET_API_ACCOUNTS_LIST_FAILURE: "GET_API_ACCOUNTS_LIST_FAILURE",
	UPDATE_API_ACCOUNTS_LIST: "UPDATE_API_ACCOUNTS_LIST",
	GET_API_ACCOUNT_DETAIL_REQUEST: "GET_API_ACCOUNT_DETAIL_REQUEST",
	GET_API_ACCOUNT_DETAIL_SUCCESS: "GET_API_ACCOUNT_DETAIL_SUCCESS",
	GET_API_ACCOUNT_DETAIL_FAILURE: "GET_API_ACCOUNT_DETAIL_FAILURE",
	RESET_API_ACCOUNT_DETAIL: "RESET_API_ACCOUNT_DETAIL",
	EDIT_API_ACCOUNT_DETAIL_REQUEST: "EDIT_API_ACCOUNT_DETAIL_REQUEST",
	EDIT_API_ACCOUNT_DETAIL_SUCCESS: "EDIT_API_ACCOUNT_DETAIL_SUCCESS",
	EDIT_API_ACCOUNT_DETAIL_FAILURE: "EDIT_API_ACCOUNT_DETAIL_FAILURE",
	UPDATE_API_ACCOUNT_DETAIL: "UPDATE_API_ACCOUNT_DETAIL",

	// api roles
	API_ROLES_LIST_STATE_CHANGE: "API_ROLES_LIST_STATE_CHANGE",
	API_ROLES_LIST_STATE_CHANGE_SORT: "API_ROLES_LIST_STATE_CHANGE_SORT",
	API_ROLES_LIST_STATE_RESET: "API_ROLES_LIST_STATE_RESET",
	GET_API_ROLES_LIST_REQUEST: "GET_API_ROLES_LIST_REQUEST",
	GET_API_ROLES_LIST_SUCCESS: "GET_API_ROLES_LIST_SUCCESS",
	GET_API_ROLES_LIST_FAILURE: "GET_API_ROLES_LIST_FAILURE",
	UPDATE_API_ROLES_LIST: "UPDATE_API_ROLES_LIST",
	GET_API_ROLE_DETAIL_REQUEST: "GET_API_ROLE_DETAIL_REQUEST",
	GET_API_ROLE_DETAIL_SUCCESS: "GET_API_ROLE_DETAIL_SUCCESS",
	GET_API_ROLE_DETAIL_FAILURE: "GET_API_ROLE_DETAIL_FAILURE",
	RESET_API_ROLE_DETAIL: "RESET_API_ROLE_DETAIL",
	EDIT_API_ROLE_DETAIL_REQUEST: "EDIT_API_ROLE_DETAIL_REQUEST",
	EDIT_API_ROLE_DETAIL_SUCCESS: "EDIT_API_ROLE_DETAIL_SUCCESS",
	EDIT_API_ROLE_DETAIL_FAILURE: "EDIT_API_ROLE_DETAIL_FAILURE",
	UPDATE_API_ROLE_DETAIL: "UPDATE_API_ROLE_DETAIL",

	// meraki
	GET_MERAKI_SETTINGS_DETAIL_REQUEST: "GET_MERAKI_SETTINGS_DETAIL_REQUEST",
	GET_MERAKI_SETTINGS_DETAIL_SUCCESS: "GET_MERAKI_SETTINGS_DETAIL_SUCCESS",
	GET_MERAKI_SETTINGS_DETAIL_FAILURE: "GET_MERAKI_SETTINGS_DETAIL_FAILURE",
	RESET_MERAKI_SETTINGS_DETAIL: "RESET_MERAKI_SETTINGS_DETAIL",
	EDIT_MERAKI_SETTINGS_DETAIL_REQUEST: "EDIT_MERAKI_SETTINGS_DETAIL_REQUEST",
	EDIT_MERAKI_SETTINGS_DETAIL_SUCCESS: "EDIT_MERAKI_SETTINGS_DETAIL_SUCCESS",
	EDIT_MERAKI_SETTINGS_DETAIL_FAILURE: "EDIT_MERAKI_SETTINGS_DETAIL_FAILURE",
	UPDATE_MERAKI_SETTINGS_DETAIL: "UPDATE_MERAKI_SETTINGS_DETAIL",
	GET_MERAKI_APPS_CONFIGURATIONS: "GET_MERAKI_APPS_CONFIGURATIONS",
	UPDATE_MERAKI_APPS_CONFIGURATIONS: "UPDATE_MERAKI_APPS_CONFIGURATIONS",

	// hub configurations
	GET_HUB_CONFIGURATIONS_REQUEST: "GET_HUB_CONFIGURATIONS_REQUEST",
	GET_HUB_CONFIGURATIONS_SUCCESS: "GET_HUB_CONFIGURATIONS_SUCCESS",
	GET_HUB_CONFIGURATIONS_FAILURE: "GET_HUB_CONFIGURATIONS_FAILURE",
	RESET_HUB_CONFIGURATIONS: "RESET_HUB_CONFIGURATIONS",
	EDIT_HUB_CONFIGURATIONS_REQUEST: "EDIT_HUB_CONFIGURATIONS_REQUEST",
	EDIT_HUB_CONFIGURATIONS_SUCCESS: "EDIT_HUB_CONFIGURATIONS_SUCCESS",
	EDIT_HUB_CONFIGURATIONS_FAILURE: "EDIT_HUB_CONFIGURATIONS_FAILURE",
	UPDATE_HUB_CONFIGURATIONS: "UPDATE_HUB_CONFIGURATIONS",

	// dsp integration
	UPDATE_DSP_PLATFORMS_LIST_STATE: "UPDATE_DSP_PLATFORMS_LIST_STATE",
	UPDATE_DSP_INTEGRATION_STATE: "UPDATE_DSP_INTEGRATION_STATE",

	// discounts
	DISCOUNTS_LIST_STATE_CHANGE: "DISCOUNTS_LIST_STATE_CHANGE",
	DISCOUNTS_LIST_STATE_CHANGE_SORT: "DISCOUNTS_LIST_STATE_CHANGE_SORT",
	DISCOUNTS_LIST_STATE_RESET: "DISCOUNTS_LIST_STATE_RESET",
	DISCOUNTS_LIST_STATE_SELECTED_PLATFORM: "DISCOUNTS_LIST_STATE_SELECTED_PLATFORM",
	GET_DISCOUNTS_LIST_REQUEST: "GET_DISCOUNTS_LIST_REQUEST",
	GET_DISCOUNTS_LIST_SUCCESS: "GET_DISCOUNTS_LIST_SUCCESS",
	GET_DISCOUNTS_LIST_FAILURE: "GET_DISCOUNTS_LIST_FAILURE",
	UPDATE_DISCOUNTS_LIST: "UPDATE_DISCOUNTS_LIST",
	GET_DISCOUNT_DETAIL_REQUEST: "GET_DISCOUNT_DETAIL_REQUEST",
	GET_DISCOUNT_DETAIL_SUCCESS: "GET_DISCOUNT_DETAIL_SUCCESS",
	GET_DISCOUNT_DETAIL_FAILURE: "GET_DISCOUNT_DETAIL_FAILURE",
	EDIT_DISCOUNT_DETAIL_REQUEST: "EDIT_DISCOUNT_DETAIL_REQUEST",
	EDIT_DISCOUNT_DETAIL_SUCCESS: "EDIT_DISCOUNT_DETAIL_SUCCESS",
	EDIT_DISCOUNT_DETAIL_FAILURE: "EDIT_DISCOUNT_DETAIL_FAILURE",
	UPDATE_DISCOUNT_DETAIL: "UPDATE_DISCOUNT_DETAIL",

	// tags
	ITEM_TAGS_LIST_STATE_CHANGE: "ITEM_TAGS_LIST_STATE_CHANGE",
	ITEM_TAGS_LIST_STATE_CHANGE_SORT: "ITEM_TAGS_LIST_STATE_CHANGE_SORT",
	ITEM_TAGS_LIST_STATE_RESET: "ITEM_TAGS_LIST_STATE_RESET",
	GET_ITEM_TAGS_LIST_REQUEST: "GET_ITEM_TAGS_LIST_REQUEST",
	GET_ITEM_TAGS_LIST_SUCCESS: "GET_ITEM_TAGS_LIST_SUCCESS",
	GET_ITEM_TAGS_LIST_FAILURE: "GET_ITEM_TAGS_LIST_FAILURE",
	UPDATE_ITEM_TAGS_LIST: "UPDATE_ITEM_TAGS_LIST",
	RESET_ITEM_TAGS_LIST: "RESET_ITEM_TAGS_LIST",
	GET_ITEM_TAG_DETAIL_REQUEST: "GET_ITEM_TAG_DETAIL_REQUEST",
	GET_ITEM_TAG_DETAIL_SUCCESS: "GET_ITEM_TAG_DETAIL_SUCCESS",
	GET_ITEM_TAG_DETAIL_FAILURE: "GET_ITEM_TAG_DETAIL_FAILURE",
	RESET_ITEM_TAG_DETAIL: "RESET_ITEM_TAG_DETAIL",
	EDIT_ITEM_TAG_DETAIL_REQUEST: "EDIT_ITEM_TAG_DETAIL_REQUEST",
	EDIT_ITEM_TAG_DETAIL_SUCCESS: "EDIT_ITEM_TAG_DETAIL_SUCCESS",
	EDIT_ITEM_TAG_DETAIL_FAILURE: "EDIT_ITEM_TAG_DETAIL_FAILURE",
	UPDATE_ITEM_TAG_DETAIL: "UPDATE_ITEM_TAG_DETAIL",

	// tag groups
	TAG_GROUPS_LIST_STATE_CHANGE: "TAG_GROUPS_LIST_STATE_CHANGE",
	TAG_GROUPS_LIST_STATE_CHANGE_SORT: "TAG_GROUPS_LIST_STATE_CHANGE_SORT",
	TAG_GROUPS_LIST_STATE_RESET: "TAG_GROUPS_LIST_STATE_RESET",
	GET_TAG_GROUPS_LIST_REQUEST: "GET_TAG_GROUPS_LIST_REQUEST",
	GET_TAG_GROUPS_LIST_SUCCESS: "GET_TAG_GROUPS_LIST_SUCCESS",
	GET_TAG_GROUPS_LIST_FAILURE: "GET_TAG_GROUPS_LIST_FAILURE",
	UPDATE_TAG_GROUPS_STATE: "UPDATE_TAG_GROUPS_STATE",
	UPDATE_TAG_GROUPS_LIST: "UPDATE_TAG_GROUPS_LIST",
	GET_TAG_GROUP_DETAIL_REQUEST: "GET_TAG_GROUP_DETAIL_REQUEST",
	GET_TAG_GROUP_DETAIL_SUCCESS: "GET_TAG_GROUP_DETAIL_SUCCESS",
	GET_TAG_GROUP_DETAIL_FAILURE: "GET_TAG_GROUP_DETAIL_FAILURE",
	RESET_TAG_GROUP_DETAIL: "RESET_TAG_GROUP_DETAIL",
	EDIT_TAG_GROUP_DETAIL_REQUEST: "EDIT_TAG_GROUP_DETAIL_REQUEST",
	EDIT_TAG_GROUP_DETAIL_SUCCESS: "EDIT_TAG_GROUP_DETAIL_SUCCESS",
	EDIT_TAG_GROUP_DETAIL_FAILURE: "EDIT_TAG_GROUP_DETAIL_FAILURE",
	UPDATE_TAG_GROUP_DETAIL: "UPDATE_TAG_GROUP_DETAIL",

	// coupons
	COUPONS_LIST_STATE_CHANGE: "COUPONS_LIST_STATE_CHANGE",
	COUPONS_LIST_STATE_CHANGE_SORT: "COUPONS_LIST_STATE_CHANGE_SORT",
	COUPONS_LIST_STATE_RESET: "COUPONS_LIST_STATE_RESET",
	GET_COUPONS_LIST_REQUEST: "GET_COUPONS_LIST_REQUEST",
	GET_COUPONS_LIST_SUCCESS: "GET_COUPONS_LIST_SUCCESS",
	GET_COUPONS_LIST_FAILURE: "GET_COUPONS_LIST_FAILURE",
	UPDATE_COUPONS_LIST: "UPDATE_COUPONS_LIST",
	GET_COUPON_DETAIL_REQUEST: "GET_COUPON_DETAIL_REQUEST",
	GET_COUPON_DETAIL_SUCCESS: "GET_COUPON_DETAIL_SUCCESS",
	GET_COUPON_DETAIL_FAILURE: "GET_COUPON_DETAIL_FAILURE",
	EDIT_COUPON_DETAIL_REQUEST: "EDIT_COUPON_DETAIL_REQUEST",
	EDIT_COUPON_DETAIL_SUCCESS: "EDIT_COUPON_DETAIL_SUCCESS",
	EDIT_COUPON_DETAIL_FAILURE: "EDIT_COUPON_DETAIL_FAILURE",
	UPDATE_COUPON_DETAIL_STATE: "UPDATE_COUPON_DETAIL_STATE",
	UPDATE_COUPON_DETAIL: "UPDATE_COUPON_DETAIL",

	//referrals
	GET_REFERRALS_LIST_REQUEST: "GET_REFERRALS_LIST_REQUEST",
	GET_REFERRALS_LIST_SUCCESS: "GET_REFERRALS_LIST_SUCCESS",
	GET_REFERRALS_LIST_FAILURE: "GET_REFERRALS_LIST_FAILURE",
	REFERRALS_LIST_STATE_CHANGE: "REFERRALS_LIST_STATE_CHANGE",
	REFERRALS_LIST_STATE_RESET: "REFERRALS_LIST_STATE_RESET",
	REFERRALS_LIST_CHANGE_SORT_ORDER: "REFERRALS_LIST_CHANGE_SORT_ORDER",
	REFERRALS_LIST_SEARCH: "REFERRALS_LIST_SEARCH",
	GET_REFERRALS_STATS_REQUEST: "GET_REFERRALS_STATS_REQUEST",
	GET_REFERRALS_STATS_SUCCESS: "GET_REFERRALS_STATS_SUCCESS",
	GET_REFERRALS_STATS_FAILURE: "GET_REFERRALS_STATS_FAILURE",
	REFERRALS_STATS_STATE_CHANGE: "REFERRALS_STATS_STATE_CHANGE",
	REFERRALS_STATS_STATE_RESET: "REFERRALS_STATS_STATE_RESET",

	// catalogue verification
	CATALOGUE_VERIFICATION_LIST_STATE_CHANGE: "CATALOGUE_VERIFICATION_LIST_STATE_CHANGE",
	CATALOGUE_VERIFICATION_LIST_STATE_CHANGE_SORT: "CATALOGUE_VERIFICATION_LIST_STATE_CHANGE_SORT",
	CATALOGUE_VERIFICATION_LIST_STATE_RESET: "CATALOGUE_VERIFICATION_LIST_STATE_RESET",
	GET_CATALOGUE_VERIFICATION_LIST_REQUEST: "GET_CATALOGUE_VERIFICATION_LIST_REQUEST",
	GET_CATALOGUE_VERIFICATION_LIST_SUCCESS: "GET_CATALOGUE_VERIFICATION_LIST_SUCCESS",
	GET_CATALOGUE_VERIFICATION_LIST_FAILURE: "GET_CATALOGUE_VERIFICATION_LIST_FAILURE",
	UPDATE_CATALOGUE_VERIFICATION_LIST: "UPDATE_CATALOGUE_VERIFICATION_LIST",
	CATALOGUE_VERIFICATION_LIST_SEARCH: "CATALOGUE_VERIFICATION_LIST_SEARCH",

	// meraki web
	GET_MERAKI_WEB_WORKSPACE_REQUEST: "GET_MERAKI_WEB_WORKSPACE_REQUEST",
	GET_MERAKI_WEB_WORKSPACE_SUCCESS: "GET_MERAKI_WEB_WORKSPACE_SUCCESS",
	GET_MERAKI_WEB_WORKSPACE_FAILURE: "GET_MERAKI_WEB_WORKSPACE_FAILURE",
	GET_MERAKI_WEB_THEMES_LIST_REQUEST: "GET_MERAKI_WEB_THEMES_LIST_REQUEST",
	GET_MERAKI_WEB_THEMES_LIST_SUCCESS: "GET_MERAKI_WEB_THEMES_LIST_SUCCESS",
	GET_MERAKI_WEB_THEMES_LIST_FAILURE: "GET_MERAKI_WEB_THEMES_LIST_FAILURE",
	GET_MERAKI_WEB_THEME_DETAILS_REQUEST: "GET_MERAKI_WEB_THEME_DETAILS_REQUEST",
	GET_MERAKI_WEB_THEME_DETAILS_SUCCESS: "GET_MERAKI_WEB_THEME_DETAILS_SUCCESS",
	GET_MERAKI_WEB_THEME_DETAILS_FAILURE: "GET_MERAKI_WEB_THEME_DETAILS_FAILURE",
	GET_MERAKI_WEB_SUBSCRIPTION_DETAILS_REQUEST: "GET_MERAKI_WEB_SUBSCRIPTION_DETAILS_REQUEST",
	GET_MERAKI_WEB_SUBSCRIPTION_DETAILS_SUCCESS: "GET_MERAKI_WEB_SUBSCRIPTION_DETAILS_SUCCESS",
	GET_MERAKI_WEB_SUBSCRIPTION_DETAILS_FAILURE: "GET_MERAKI_WEB_SUBSCRIPTION_DETAILS_FAILURE",

	// webhooks
	WEBHOOKS_LIST_STATE_CHANGE: "WEBHOOKS_LIST_STATE_CHANGE",
	WEBHOOKS_LIST_STATE_CHANGE_SORT: "WEBHOOKS_LIST_STATE_CHANGE_SORT",
	WEBHOOKS_LIST_STATE_RESET: "WEBHOOKS_LIST_STATE_RESET",
	GET_WEBHOOKS_LIST_REQUEST: "GET_WEBHOOKS_LIST_REQUEST",
	GET_WEBHOOKS_LIST_SUCCESS: "GET_WEBHOOKS_LIST_SUCCESS",
	GET_WEBHOOKS_LIST_FAILURE: "GET_WEBHOOKS_LIST_FAILURE",
	UPDATE_WEBHOOKS_LIST: "UPDATE_WEBHOOKS_LIST",
	WEBHOOKS_LIST_SEARCH: "WEBHOOKS_LIST_SEARCH",
	GET_WEBHOOK_DETAIL_REQUEST: "GET_WEBHOOK_DETAILS_REQUEST",
	GET_WEBHOOK_DETAIL_SUCCESS: "GET_WEBHOOK_DETAILS_SUCCESS",
	GET_WEBHOOK_DETAIL_FAILURE: "GET_WEBHOOK_DETAILS_FAILURE",
	EDIT_WEBHOOK_DETAIL_REQUEST: "EDIT_WEBHOOK_DETAILS_REQUEST",
	EDIT_WEBHOOK_DETAIL_SUCCESS: "EDIT_WEBHOOK_DETAILS_SUCCESS",
	EDIT_WEBHOOK_DETAIL_FAILURE: "EDIT_WEBHOOK_DETAILS_FAILURE",
	UPDATE_WEBHOOK_DETAIL: "UPDATE_WEBHOOK_DETAIL",

	// audit events
	AUDIT_EVENTS_LIST_STATE_CHANGE: "AUDIT_EVENTS_LIST_STATE_CHANGE",
	AUDIT_EVENTS_LIST_STATE_CHANGE_SORT: "AUDIT_EVENTS_LIST_STATE_CHANGE_SORT",
	AUDIT_EVENTS_LIST_STATE_RESET: "AUDIT_EVENTS_LIST_STATE_RESET",
	GET_AUDIT_EVENTS_LIST_REQUEST: "GET_AUDIT_EVENTS_LIST_REQUEST",
	GET_AUDIT_EVENTS_LIST_SUCCESS: "GET_AUDIT_EVENTS_LIST_SUCCESS",
	GET_AUDIT_EVENTS_LIST_FAILURE: "GET_AUDIT_EVENTS_LIST_FAILURE",
	GET_RELATED_AUDIT_EVENTS_LIST_REQUEST: "GET_RELATED_AUDIT_EVENTS_LIST_REQUEST",
	GET_RELATED_AUDIT_EVENTS_LIST_SUCCESS: "GET_RELATED_AUDIT_EVENTS_LIST_SUCCESS",
	GET_RELATED_AUDIT_EVENTS_LIST_FAILURE: "GET_RELATED_AUDIT_EVENTS_LIST_FAILURE",
	UPDATE_AUDIT_EVENTS_LIST: "UPDATE_AUDIT_EVENTS_LIST",
	UPDATE_RELATED_AUDIT_EVENTS_LIST: "UPDATE_RELATED_AUDIT_EVENTS_LIST",
	AUDIT_EVENTS_LIST_SEARCH: "AUDIT_EVENTS_LIST_SEARCH",
	GET_AUDIT_EVENT_DETAIL_REQUEST: "GET_AUDIT_EVENT_DETAIL_REQUEST",
	GET_AUDIT_EVENT_DETAIL_SUCCESS: "GET_AUDIT_EVENT_DETAIL_SUCCESS",
	GET_AUDIT_EVENT_DETAIL_FAILURE: "GET_AUDIT_EVENT_DETAIL_FAILURE",
	UPDATE_AUDIT_EVENT_DETAIL: "UPDATE_AUDIT_EVENT_DETAIL",

	// gallery
	GALLERIES_LIST_STATE_CHANGE: "GALLERIES_LIST_STATE_CHANGE",
	GALLERIES_LIST_STATE_CHANGE_SORT: "GALLERIES_LIST_STATE_CHANGE_SORT",
	GALLERIES_LIST_STATE_RESET: "GALLERIES_LIST_STATE_RESET",
	GET_GALLERIES_LIST_REQUEST: "GET_GALLERIES_LIST_REQUEST",
	GET_GALLERIES_LIST_SUCCESS: "GET_GALLERIES_LIST_SUCCESS",
	GET_GALLERIES_LIST_FAILURE: "GET_GALLERIES_LIST_FAILURE",
	UPDATE_GALLERIES_LIST: "UPDATE_GALLERIES_LIST",
	UPLOAD_GALLERY_IMAGE: "UPLOAD_GALLERY_IMAGE",
	GET_GALLERY_DETAIL_REQUEST: "GET_GALLERY_DETAILS_REQUEST",
	GET_GALLERY_DETAIL_SUCCESS: "GET_GALLERY_DETAILS_SUCCESS",
	GET_GALLERY_DETAIL_FAILURE: "GET_GALLERY_DETAILS_FAILURE",
	EDIT_GALLERY_DETAIL_REQUEST: "EDIT_GALLERY_DETAILS_REQUEST",
	EDIT_GALLERY_DETAIL_SUCCESS: "EDIT_GALLERY_DETAILS_SUCCESS",
	EDIT_GALLERY_DETAIL_FAILURE: "EDIT_GALLERY_DETAILS_FAILURE",
	UPDATE_GALLERY_DETAIL: "UPDATE_GALLERY_DETAIL",

	// reports
	GET_REPORTS_LIST_REQUEST: "GET_REPORTS_LIST_REQUEST",
	GET_REPORTS_LIST_SUCCESS: "GET_REPORTS_LIST_SUCCESS",
	GET_REPORTS_LIST_FAILURE: "GET_REPORTS_LIST_FAILURE",
	UPDATE_REPORTS_LIST_STATE: "UPDATE_REPORTS_LIST_STATE",
	RESET_REPORTS_LIST: "RESET_REPORTS_LIST",
	GET_REPORTS_NEW_LIST_REQUEST: "GET_REPORTS_NEW_LIST_REQUEST",
	GET_REPORTS_NEW_LIST_SUCCESS: "GET_REPORTS_NEW_LIST_SUCCESS",
	GET_REPORTS_NEW_LIST_FAILURE: "GET_REPORTS_NEW_LIST_FAILURE",
	UPDATE_REPORTS_NEW_LIST_STATE: "UPDATE_REPORTS_NEW_LIST_STATE",
	RESET_REPORTS_NEW_LIST: "RESET_REPORTS_NEW_LIST",
	GET_REPORT_DETAIL_REQUEST: "GET_REPORT_DETAIL_REQUEST",
	GET_REPORT_DETAIL_SUCCESS: "GET_REPORT_DETAIL_SUCCESS",
	GET_REPORT_DETAIL_FAILURE: "GET_REPORT_DETAIL_FAILURE",
	UPDATE_REPORT_DETAIL: "UPDATE_REPORT_DETAIL",
	RESET_REPORT_DETAIL: "RESET_REPORT_DETAIL",
	GET_EXPORT_REPORT_HISTORY_REQUEST: "GET_EXPORT_REPORT_HISTORY_REQUEST",
	GET_EXPORT_REPORT_HISTORY_SUCCESS: "GET_EXPORT_REPORT_HISTORY_SUCCESS",
	GET_EXPORT_REPORT_HISTORY_FAILURE: "GET_EXPORT_REPORT_HISTORY_FAILURE",
	UPDATE_EXPORT_REPORT_HISTORY: "UPDATE_EXPORT_REPORT_HISTORY",
	RESET_EXPORT_REPORT_HISTORY: "RESET_EXPORT_REPORT_HISTORY",
	GET_REPORT_PREVIEW_REQUEST: "GET_REPORT_PREVIEW_REQUEST",
	GET_REPORT_PREVIEW_SUCCESS: "GET_REPORT_PREVIEW_SUCCESS",
	GET_REPORT_PREVIEW_FAILURE: "GET_REPORT_PREVIEW_FAILURE",
	UPDATE_REPORT_PREVIEW: "UPDATE_REPORT_PREVIEW",
	RESET_REPORT_PREVIEW: "RESET_REPORT_PREVIEW",
	GET_SCHEDULE_REPORTS_LIST_REQUEST: "GET_SCHEDULE_REPORTS_LIST_REQUEST",
	GET_SCHEDULE_REPORTS_LIST_SUCCESS: "GET_SCHEDULE_REPORTS_LIST_SUCCESS",
	GET_SCHEDULE_REPORTS_LIST_FAILURE: "GET_SCHEDULE_REPORTS_LIST_FAILURE",
	UPDATE_SCHEDULE_REPORTS_LIST: "UPDATE_SCHEDULE_REPORTS_LIST",
	RESET_SCHEDULE_REPORTS_LIST: "RESET_SCHEDULE_REPORTS_LIST",
	SCHEDULE_REPORT_CREATE_REQUEST: "SCHEDULE_REPORT_CREATE_REQUEST",
	SCHEDULE_REPORT_CREATE_SUCCESS: "SCHEDULE_REPORT_CREATE_SUCCESS",
	SCHEDULE_REPORT_CREATE_FAILURE: "SCHEDULE_REPORT_CREATE_FAILURE",
	UPDATE_SCHEDULE_REPORT_CREATE_STATE: "UPDATE_SCHEDULE_REPORT_CREATE_STATE",
	RESET_SCHEDULE_REPORT_CREATE_STATE: "RESET_SCHEDULE_REPORT_CREATE_STATE",
	SCHEDULE_REPORT_DETAIL_REQUEST: "SCHEDULE_REPORT_DETAIL_REQUEST",
	SCHEDULE_REPORT_DETAIL_SUCCESS: "SCHEDULE_REPORT_DETAIL_SUCCESS",
	SCHEDULE_REPORT_DETAIL_FAILURE: "SCHEDULE_REPORT_DETAIL_FAILURE",
	UPDATE_SCHEDULE_REPORT_DETAIL_STATE: "UPDATE_SCHEDULE_REPORT_DETAIL_STATE",
	RESET_SCHEDULE_REPORT_DETAIL_STATE: "RESET_SCHEDULE_REPORT_DETAIL_STATE",
	GET_SCHEDULE_REPORT_HISTORY_REQUEST: "GET_SCHEDULE_REPORT_HISTORY_REQUEST",
	GET_SCHEDULE_REPORT_HISTORY_SUCCESS: "GET_SCHEDULE_REPORT_HISTORY_SUCCESS",
	GET_SCHEDULE_REPORT_HISTORY_FAILURE: "GET_SCHEDULE_REPORT_HISTORY_FAILURE",
	UPDATE_SCHEDULE_REPORT_HISTORY: "UPDATE_SCHEDULE_REPORT_HISTORY",
	RESET_SCHEDULE_REPORT_HISTORY: "RESET_SCHEDULE_REPORT_HISTORY",

	// customer feedback
	CUSTOMER_FEEDBACK_LIST_STATE_CHANGE: "CUSTOMER_FEEDBACK_LIST_STATE_CHANGE",
	CUSTOMER_FEEDBACK_LIST_STATE_CHANGE_SORT: "CUSTOMER_FEEDBACK_LIST_STATE_CHANGE_SORT",
	CUSTOMER_FEEDBACK_LIST_STATE_RESET: "CUSTOMER_FEEDBACK_LIST_STATE_RESET",
	GET_CUSTOMER_FEEDBACK_LIST_REQUEST: "GET_CUSTOMER_FEEDBACK_LIST_REQUEST",
	GET_CUSTOMER_FEEDBACK_LIST_SUCCESS: "GET_CUSTOMER_FEEDBACK_LIST_SUCCESS",
	GET_CUSTOMER_FEEDBACK_LIST_FAILURE: "GET_CUSTOMER_FEEDBACK_LIST_FAILURE",
	UPDATE_CUSTOMER_FEEDBACK_LIST: "UPDATE_CUSTOMER_FEEDBACK_LIST",
	CUSTOMER_FEEDBACK_LIST_SEARCH: "CUSTOMER_FEEDBACK_LIST_SEARCH",
	CUSTOMER_FEEDBACK_NPS_STATE_CHANGE: "CUSTOMER_FEEDBACK_NPS_STATE_CHANGE",
	CUSTOMER_FEEDBACK_NPS_STATE_RESET: "CUSTOMER_FEEDBACK_NPS_STATE_RESET",
	GET_CUSTOMER_FEEDBACK_NPS_REQUEST: "GET_CUSTOMER_FEEDBACK_NPS_REQUEST",
	GET_CUSTOMER_FEEDBACK_NPS_SUCCESS: "GET_CUSTOMER_FEEDBACK_NPS_SUCCESS",
	GET_CUSTOMER_FEEDBACK_NPS_FAILURE: "GET_CUSTOMER_FEEDBACK_NPS_FAILURE",

	// custom integrations
	GET_CUSTOM_INTEGRATIONS_REQUEST: "GET_CUSTOM_INTEGRATIONS_REQUEST",
	GET_CUSTOM_INTEGRATIONS_SUCCESS: "GET_CUSTOM_INTEGRATIONS_SUCCESS",
	GET_CUSTOM_INTEGRATIONS_FAILURE: "GET_CUSTOM_INTEGRATIONS_FAILURE",
	RESET_CUSTOM_INTEGRATIONS: "RESET_CUSTOM_INTEGRATIONS",
	EDIT_CUSTOM_INTEGRATIONS_REQUEST: "EDIT_CUSTOM_INTEGRATIONS_REQUEST",
	EDIT_CUSTOM_INTEGRATIONS_SUCCESS: "EDIT_CUSTOM_INTEGRATIONS_SUCCESS",
	EDIT_CUSTOM_INTEGRATIONS_FAILURE: "EDIT_CUSTOM_INTEGRATIONS_FAILURE",
	UPDATE_CUSTOM_INTEGRATIONS: "UPDATE_CUSTOM_INTEGRATIONS",

	//POS Details
	GET_POS_DETAILS_REQUEST: "GET_POS_DETAILS_REQUEST",
	GET_POS_DETAILS_SUCCESS: "GET_POS_DETAILS_SUCCESS",
	GET_POS_DETAILS_FAILURE: "GET_POS_DETAILS_FAILURE",
	UPDATE_POS_DETAILS: "UPDATE_POS_DETAILS",
	EDIT_POS_DETAILS_REQUEST: "EDIT_POS_DETAILS_REQUEST",
	EDIT_POS_DETAILS_SUCCESS: "EDIT_POS_DETAILS_SUCCESS",
	EDIT_POS_DETAILS_FAILURE: "EDIT_POS_DETAILS_FAILURE",

	// accept invite
	GET_INVITE_DETAIL_REQUEST: "GET_INVITE_DETAIL_REQUEST",
	GET_INVITE_DETAIL_SUCCESS: "GET_INVITE_DETAIL_SUCCESS",
	GET_INVITE_DETAIL_FAILURE: "GET_INVITE_DETAIL_FAILURE",
	EDIT_INVITE_DETAIL_REQUEST: "EDIT_INVITE_DETAIL_REQUEST",
	EDIT_INVITE_DETAIL_SUCCESS: "EDIT_INVITE_DETAIL_SUCCESS",
	EDIT_INVITE_DETAIL_FAILURE: "EDIT_INVITE_DETAIL_FAILURE",
	UPDATE_INVITE_DETAIL: "UPDATE_INVITE_DETAIL",
	OTP_GENERATION_REQUEST: "OTP_GENERATION_REQUEST",
	OTP_GENERATION_SUCCESS: "OTP_GENERATION_SUCCESS",
	OTP_GENERATION_FAILURE: "OTP_GENERATION_FAILURE",
	OTP_VALIDATION_REQUEST: "OTP_VALIDATION_REQUEST",
	OTP_VALIDATION_SUCCESS: "OTP_VALIDATION_SUCCESS",
	OTP_VALIDATION_FAILURE: "OTP_VALIDATION_FAILURE",
	UPDATE_INVITE_DETAIL_STATE: "UPDATE_INVITE_DETAIL_STATE",

	// pending invites
	PENDING_INVITES_LIST_STATE_CHANGE: "PENDING_INVITES_LIST_STATE_CHANGE",
	PENDING_INVITES_LIST_STATE_CHANGE_SORT: "PENDING_INVITES_LIST_STATE_CHANGE_SORT",
	PENDING_INVITES_LIST_STATE_RESET: "PENDING_INVITES_LIST_STATE_RESET",
	GET_PENDING_INVITES_LIST_REQUEST: "GET_PENDING_INVITES_LIST_REQUEST",
	GET_PENDING_INVITES_LIST_SUCCESS: "GET_PENDING_INVITES_LIST_SUCCESS",
	GET_PENDING_INVITES_LIST_FAILURE: "GET_PENDING_INVITES_LIST_FAILURE",
	UPDATE_PENDING_INVITES_LIST: "UPDATE_PENDING_INVITES_LIST",
	PENDING_INVITES_LIST_SEARCH: "PENDING_INVITES_LIST_SEARCH",
	RESEND_USER_INVITE_REQUEST: "RESEND_USER_INVITE_REQUEST",
	RESEND_USER_INVITE_SUCCESS: "RESEND_USER_INVITE_SUCCESS",
	RESEND_USER_INVITE_FAILURE: "RESEND_USER_INVITE_FAILURE",
	CANCEL_USER_INVITE_REQUEST: "CANCEL_USER_INVITE_REQUEST",
	CANCEL_USER_INVITE_SUCCESS: "CANCEL_USER_INVITE_SUCCESS",
	CANCEL_USER_INVITE_FAILURE: "CANCEL_USER_INVITE_FAILURE",

	// unified invites
	UNIFIED_INVITES_LIST_STATE_CHANGE: "UNIFIED_INVITES_LIST_STATE_CHANGE",
	UNIFIED_INVITES_LIST_STATE_CHANGE_SORT: "UNIFIED_INVITES_LIST_STATE_CHANGE_SORT",
	UNIFIED_INVITES_LIST_STATE_RESET: "UNIFIED_INVITES_LIST_STATE_RESET",
	GET_UNIFIED_INVITES_LIST_REQUEST: "GET_UNIFIED_INVITES_LIST_REQUEST",
	GET_UNIFIED_INVITES_LIST_SUCCESS: "GET_UNIFIED_INVITES_LIST_SUCCESS",
	GET_UNIFIED_INVITES_LIST_FAILURE: "GET_UNIFIED_INVITES_LIST_FAILURE",
	UPDATE_UNIFIED_INVITES_LIST: "UPDATE_UNIFIED_INVITES_LIST",
	UNIFIED_INVITES_LIST_SEARCH: "UNIFIED_INVITES_LIST_SEARCH",
	ULE_USER_INVITE_ACTION_REQUEST: "ULE_USER_INVITE_ACTION_REQUEST",
	ULE_USER_INVITE_ACTION_SUCCESS: "ULE_USER_INVITE_ACTION_SUCCESS",
	ULE_USER_INVITE_ACTION_FAILURE: "ULE_USER_INVITE_ACTION_FAILURE",
	GET_INVITE_DETAIL_REQUEST: "GET_INVITE_DETAIL_REQUEST",
	GET_INVITE_DETAIL_SUCCESS: "GET_INVITE_DETAIL_SUCCESS",
	GET_INVITE_DETAIL_FAILURE: "GET_INVITE_DETAIL_FAILURE",

	// piper academy
	UPDATE_PIPER_ACADEMY_STATE: "UPDATE_PIPER_ACADEMY_STATE",

	// notifications
	GET_NOTIFICATIONS_LIST_REQUEST: "GET_NOTIFICATIONS_LIST_REQUEST",
	GET_NOTIFICATIONS_LIST_SUCCESS: "GET_NOTIFICATIONS_LIST_SUCCESS",
	GET_NOTIFICATIONS_LIST_FAILURE: "GET_NOTIFICATIONS_LIST_FAILURE",
	UPDATE_NOTIFICATIONS_LIST: "UPDATE_NOTIFICATIONS_LIST",

	// ongoing tasks
	ADD_TASK_TO_ONGOING_TASKS_LIST: "ADD_TASK_TO_ONGOING_TASKS_LIST",
	UPDATE_TASK_IN_ONGOING_TASKS_LIST: "UPDATE_TASK_IN_ONGOING_TASKS_LIST",
	REMOVE_COMPLETED_TASK_FROM_ONGOING_TASKS_LIST: "REMOVE_COMPLETED_TASK_FROM_ONGOING_TASKS_LIST",
	UPDATE_ONGOING_TASKS_STATE: "UPDATE_ONGOING_TASKS_STATE",

	// reconciliation
	SET_FIRST_LOAD_STATE: "SET_FIRST_LOAD_STATE",
	SET_SELECTED_PLATFORM: "SET_SELECTED_PLATFORM",
	GET_PAYOUT_SHEETS_LIST_REQUEST: "GET_PAYOUT_SHEETS_LIST_REQUEST",
	GET_PAYOUT_SHEETS_LIST_SUCCESS: "GET_PAYOUT_SHEETS_LIST_SUCCESS",
	GET_PAYOUT_SHEETS_LIST_FAILURE: "GET_PAYOUT_SHEETS_LIST_FAILURE",
	RECONCILIATION_LIST_STATE_CHANGE: "RECONCILIATION_LIST_STATE_CHANGE",
	RECONCILIATION_LIST_STATE_CHANGE_SORT: "RECONCILIATION_LIST_STATE_CHANGE_SORT",
	GET_PAYOUT_SHEET_DETAILS_REQUEST: "GET_PAYOUT_SHEET_DETAILS_REQUEST",
	GET_PAYOUT_SHEET_DETAILS_SUCCESS: "GET_PAYOUT_SHEET_DETAILS_SUCCESS",
	GET_PAYOUT_SHEET_DETAILS_FAILURE: "GET_PAYOUT_SHEET_DETAILS_FAILURE",
	RECONCILIATION_STATS_STATE_CHANGE: "RECONCILIATION_STATS_STATE_CHANGE",
	RECONCILIATION_STATS_STATE_RESET: "RECONCILIATION_STATS_STATE_RESET",
	GET_RECONCILIATION_STATS_REQUEST: "GET_RECONCILIATION_STATS_REQUEST",
	GET_RECONCILIATION_STATS_SUCCESS: "GET_RECONCILIATION_STATS_SUCCESS",
	GET_RECONCILIATION_STATS_FAILURE: "GET_RECONCILIATION_STATS_FAILURE",
	SET_SELECTED_LOCATION_PAYOUT_STATUS: "SET_SELECTED_LOCATION_PAYOUT_STATUS",

	// catalogue backups
	CATALOGUE_BACKUPS_LIST_STATE_CHANGE: "CATALOGUE_BACKUPS_LIST_STATE_CHANGE",
	CATALOGUE_BACKUPS_LIST_STATE_CHANGE_SORT: "CATALOGUE_BACKUPS_LIST_STATE_CHANGE_SORT",
	CATALOGUE_BACKUPS_LIST_STATE_RESET: "CATALOGUE_BACKUPS_LIST_STATE_RESET",
	GET_CATALOGUE_BACKUPS_LIST_REQUEST: "GET_CATALOGUE_BACKUPS_LIST_REQUEST",
	GET_CATALOGUE_BACKUPS_LIST_SUCCESS: "GET_CATALOGUE_BACKUPS_LIST_SUCCESS",
	GET_CATALOGUE_BACKUPS_LIST_FAILURE: "GET_CATALOGUE_BACKUPS_LIST_FAILURE",
	UPDATE_CATALOGUE_BACKUP_CONFIG: "UPDATE_CATALOGUE_BACKUP_CONFIG",
	UPDATE_CATALOGUE_BACKUPS_LIST: "UPDATE_CATALOGUE_BACKUPS_LIST",
	GET_CATALOGUE_BACKUP_DETAIL_REQUEST: "GET_CATALOGUE_BACKUP_DETAIL_REQUEST",
	GET_CATALOGUE_BACKUP_DETAIL_SUCCESS: "GET_CATALOGUE_BACKUP_DETAIL_SUCCESS",
	GET_CATALOGUE_BACKUP_DETAIL_FAILURE: "GET_CATALOGUE_BACKUP_DETAIL_FAILURE",
	EDIT_CATALOGUE_BACKUP_DETAIL_REQUEST: "EDIT_CATALOGUE_BACKUP_DETAIL_REQUEST",
	EDIT_CATALOGUE_BACKUP_DETAIL_SUCCESS: "EDIT_CATALOGUE_BACKUP_DETAIL_SUCCESS",
	EDIT_CATALOGUE_BACKUP_DETAIL_FAILURE: "EDIT_CATALOGUE_BACKUP_DETAIL_FAILURE",
	UPDATE_CATALOGUE_BACKUP_DETAIL: "UPDATE_CATALOGUE_BACKUP_DETAIL",
	UPDATE_CATALOGUE_BACKUPS_STATUS: "UPDATE_CATALOGUE_BACKUPS_STATUS",

	// developer tools
	GET_AGGREGATOR_TEST_CONFIG_REQUEST: "GET_AGGREGATOR_TEST_CONFIG_REQUEST",
	GET_AGGREGATOR_TEST_CONFIG_SUCCESS: "GET_AGGREGATOR_TEST_CONFIG_SUCCESS",
	GET_AGGREGATOR_TEST_CONFIG_FAILURE: "GET_AGGREGATOR_TEST_CONFIG_FAILURE",
	TRIGGER_AGGREGATOR_TEST_CONFIG_REQUEST: "TRIGGER_AGGREGATOR_TEST_CONFIG_REQUEST",
	TRIGGER_AGGREGATOR_TEST_CONFIG_SUCCESS: "TRIGGER_AGGREGATOR_TEST_CONFIG_SUCCESS",
	TRIGGER_AGGREGATOR_TEST_CONFIG_FAILURE: "TRIGGER_AGGREGATOR_TEST_CONFIG_FAILURE",
	UPDATE_AGGREGATOR_TEST_CONFIG_DATA: "UPDATE_AGGREGATOR_TEST_CONFIG_DATA",
	RESET_AGGREGATOR_TEST_CONFIG_DATA: "RESET_AGGREGATOR_TEST_CONFIG_DATA",

	// global notifications
	PUSH_NOTIFICATIONS: "PUSH_NOTIFICATIONS",
	FLUSH_NOTIFICATIONS: "FLUSH_NOTIFICATIONS",
	REFRESH_NOTIFICATIONS_CONTENT: "REFRESH_NOTIFICATIONS_CONTENT",

	// brands
	GET_BRANDS_LIST_REQUEST: "GET_BRANDS_LIST_REQUEST",
	GET_BRANDS_LIST_SUCCESS: "GET_BRANDS_LIST_SUCCESS",
	GET_BRANDS_LIST_FAILURE: "GET_BRANDS_LIST_FAILURE",
	UPDATE_BRANDS_SEARCH_TEXT: "UPDATE_BRANDS_SEARCH_TEXT",
	UPDATE_ASSOCIATED_BRAND_LOCATIONS_STATE: "UPDATE_ASSOCIATED_BRAND_LOCATIONS_STATE",

	// breadcrumbs state
	BREADCRUMBS_ENABLE: "BREADCRUMBS_ENABLE",
	BREADCRUMBS_DISABLE: "BREADCRUMBS_DISABLE",
	BREADCRUMBS_PUSH: "BREADCRUMBS_PUSH",
	BREADCRUMBS_POP: "BREADCRUMBS_POP",

	// snowfall state
	UPDATE_SNOWFALL_STATE: "UPDATE_SNOWFALL_STATE",

	// config items
	UPDATE_SELECTED_BRAND: "UPDATE_SELECTED_BRAND",

	// menus
	GET_MENUS_LIST_REQUEST: "GET_MENUS_LIST_REQUEST",
	GET_MENUS_LIST_SUCCESS: "GET_MENUS_LIST_SUCCESS",
	GET_MENUS_LIST_FAILURE: "GET_MENUS_LIST_FAILURE",
	UPDATE_MENUS_LIST_DATA: "UPDATE_MENUS_LIST_DATA",

	GET_MENU_DETAILS_REQUEST: "GET_MENU_DETAILS_REQUEST",
	GET_MENU_DETAILS_SUCCESS: "GET_MENU_DETAILS_SUCCESS",
	GET_MENU_DETAILS_FAILURE: "GET_MENU_DETAILS_FAILURE",
	UPDATE_MENU_DETAILS_DATA: "UPDATE_MENU_DETAILS_DATA",
	TOTAL_MENU_DETAILS_DATA_UPDATE: "TOTAL_MENU_DETAILS_DATA_UPDATE",
	UPDATE_META_DATA: "UPDATE_META_DATA",

	GET_PARENT_SECTIONS_REQUEST: "GET_PARENT_SECTIONS_REQUEST",
	GET_PARENT_SECTIONS_SUCCESS: "GET_PARENT_SECTIONS_SUCCESS",
	GET_PARENT_SECTIONS_FAILURE: "GET_PARENT_SECTIONS_FAILURE",
	UPDATE_SELECTED_PARENT_SECTION: "UPDATE_SELECTED_PARENT_SECTION",
	RESET_PARENT_SECTIONS_STATE: "RESET_PARENT_SECTIONS_STATE",

	GET_PUBLISHED_VERSIONS_LIST_REQUEST: "GET_PUBLISHED_VERSIONS_LIST_REQUEST",
	GET_PUBLISHED_VERSIONS_LIST_SUCCESS: "GET_PUBLISHED_VERSIONS_LIST_SUCCESS",
	GET_PUBLISHED_VERSIONS_LIST_FAILURE: "GET_PUBLISHED_VERSIONS_LIST_FAILURE",
	UPDATE_PUBLISHED_VERSIONS_STATE: "UPDATE_PUBLISHED_VERSIONS_STATE",

	HANDLE_DESTRUCTIVE_SAVE_REQUEST: "HANDLE_DESTRUCTIVE_SAVE_REQUEST",
	HANDLE_DESTRUCTIVE_SAVE_SUCCESS: "HANDLE_DESTRUCTIVE_SAVE_SUCCESS",
	HANDLE_DESTRUCTIVE_SAVE_FAILURE: "HANDLE_DESTRUCTIVE_SAVE_FAILURE",

	GET_MENU_PREVIEW_LOCATIONS_REQUEST: "GET_MENU_PREVIEW_LOCATIONS_REQUEST",
	GET_MENU_PREVIEW_LOCATIONS_SUCCESS: "GET_MENU_PREVIEW_LOCATIONS_SUCCESS",
	GET_MENU_PREVIEW_LOCATIONS_FAILURE: "GET_MENU_PREVIEW_LOCATIONS_FAILURE",
	GET_MENU_PREVIEW_PLATFORMS_REQUEST: "GET_MENU_PREVIEW_PLATFORMS_REQUEST",
	GET_MENU_PREVIEW_PLATFORMS_SUCCESS: "GET_MENU_PREVIEW_PLATFORMS_SUCCESS",
	GET_MENU_PREVIEW_PLATFORMS_FAILURE: "GET_MENU_PREVIEW_PLATFORMS_FAILURE",
	UPDATE_MENU_PREVIEW_LOCATION: "UPDATE_MENU_PREVIEW_LOCATION",
	UPDATE_MENU_PREVIEW_PLATFORM: "UPDATE_MENU_PREVIEW_PLATFORM",

	MENU_PENDING_CHANGES_MODAL_STATE: "MENU_PENDING_CHANGES_MODAL_STATE",
	MENU_PENDING_CHANGES_TARGET_PATH_UPDATE: "MENU_PENDING_CHANGES_TARGET_PATH_UPDATE",

	GET_MENU_PREVIEW_REQUEST: "GET_MENU_PREVIEW_REQUEST",
	GET_MENU_PREVIEW_SUCCESS: "GET_MENU_PREVIEW_SUCCESS",
	GET_MENU_PREVIEW_FAILURE: "GET_MENU_PREVIEW_FAILURE",
	UPDATE_MENU_PREVIEW_SELECTED_SECTION: "UPDATE_MENU_PREVIEW_SELECTED_SECTION",

	GET_MENU_PREVIEW_VALIDATION_STATUS_REQUEST: "GET_MENU_PREVIEW_VALIDATION_STATUS_REQUEST",
	GET_MENU_PREVIEW_VALIDATION_STATUS_SUCCESS: "GET_MENU_PREVIEW_VALIDATION_STATUS_SUCCESS",
	GET_MENU_PREVIEW_VALIDATION_STATUS_FAILURE: "GET_MENU_PREVIEW_VALIDATION_STATUS_FAILURE",
	RESET_MENU_PREVIEW_VALIDATION_STATUS: "RESET_MENU_PREVIEW_VALIDATION_STATUS",

	GET_MENU_PUBLISH_VALIDATION_STATUS_REQUEST: "GET_MENU_PUBLISH_VALIDATION_STATUS_REQUEST",
	GET_MENU_PUBLISH_VALIDATION_STATUS_SUCCESS: "GET_MENU_PUBLISH_VALIDATION_STATUS_SUCCESS",
	GET_MENU_PUBLISH_VALIDATION_STATUS_FAILURE: "GET_MENU_PUBLISH_VALIDATION_STATUS_FAILURE",
	RESET_MENU_PUBLISH_VALIDATION_STATUS: "RESET_MENU_PUBLISH_VALIDATION_STATUS",

	// menu categories/sections
	GET_MENU_CATEGORIES_LIST_REQUEST: "GET_MENU_CATEGORIES_LIST_REQUEST",
	GET_MENU_CATEGORIES_LIST_SUCCESS: "GET_MENU_CATEGORIES_LIST_SUCCESS",
	GET_MENU_CATEGORIES_LIST_FAILURE: "GET_MENU_CATEGORIES_LIST_FAILURE",

	// onboarding
	GET_USER_FLOW_STEPS_REQUEST: "GET_USER_FLOW_STEPS_REQUEST",
	GET_USER_FLOW_STEPS_SUCCESS: "GET_USER_FLOW_STEPS_SUCCESS",
	GET_USER_FLOW_STEPS_FAILURE: "GET_USER_FLOW_STEPS_FAILURE",

	GET_USER_STEPS_REQUEST: "GET_USER_STEPS_REQUEST",
	GET_USER_STEPS_SUCCESS: "GET_USER_STEPS_SUCCESS",
	GET_USER_STEPS_FAILURE: "GET_USER_STEPS_FAILURE",
	GET_ATLAS_ONBOARDING_STATE: "GET_ATLAS_ONBOARDING_STATE",
	RESET_ATLAS_ONBOARDING_STATE: "RESET_ATLAS_ONBOARDING_STATE",

	// Periscope
	PERISCOPE_DASHBOARD_REQUEST: "PERISCOPE_DASHBOARD_REQUEST",
	PERISCOPE_DASHBOARD_SUCCESS: "PERISCOPE_DASHBOARD_SUCCESS",
	PERISCOPE_DASHBOARD_FAILURE: "PERISCOPE_DASHBOARD_FAILURE",
	PERISCOPE_STORE_METRIC_REQUEST: "PERISCOPE_STORE_METRIC_REQUEST",
	PERISCOPE_STORE_METRIC_SUCCESS: "PERISCOPE_STORE_METRIC_SUCCESS",
	PERISCOPE_STORE_METRIC_FAILURE: "PERISCOPE_STORE_METRIC_FAILURE",
	PERISCOPE_LOCATION_REQUEST: "PERISCOPE_LOCATION_REQUEST",
	PERISCOPE_LOCATION_SUCCESS: "PERISCOPE_LOCATION_SUCCESS",
	PERISCOPE_LOCATION_FAILURE: "PERISCOPE_LOCATION_FAILURE",
	PERISCOPE_ITEM_REQUEST: "PERISCOPE_ITEM_REQUEST",
	PERISCOPE_ITEM_SUCCESS: "PERISCOPE_ITEM_SUCCESS",
	PERISCOPE_ITEM_FAILURE: "PERISCOPE_ITEM_FAILURE",
	LOCATION_AVAILABILITY_LIST_STATE_CHANGE: "LOCATION_AVAILABILITY_LIST_STATE_CHANGE",
	LOCATION_AVAILABILITY_LIST_STATE_FILTERS_CHANGE: "LOCATION_AVAILABILITY_LIST_STATE_FILTERS_CHANGE",
	LOCATION_AVAILABILITY_LIST_STATE_APPLY_FILTERS_CHANGE: "LOCATION_AVAILABILITY_LIST_STATE_APPLY_FILTERS_CHANGE",
	LOCATION_AVAILABILITY_LIST_INITIAL_STATE_SORT: "LOCATION_AVAILABILITY_LIST_INITIAL_STATE_SORT",
	LOCATION_AVAILABILITY_LIST_STATE_RESET: "LOCATION_AVAILABILITY_LIST_STATE_RESET",
	LOCATION_AVAILABILITY_PERISCOPE_STATE_REQUEST: "LOCATION_AVAILABILITY_PERISCOPE_STATE_REQUEST",
	LOCATION_AVAILABILITY_PERISCOPE_STATE_SUCCESS: "LOCATION_AVAILABILITY_PERISCOPE_STATE_SUCCESS",
	LOCATION_AVAILABILITY_PERISCOPE_STATE_FAILURE: "LOCATION_AVAILABILITY_PERISCOPE_STATE_FAILURE",
	LOCATION_AVAILABILITY_LIST_STATE_OBJECTS_CHANGE: "LOCATION_AVAILABILITY_LIST_STATE_OBJECTS_CHANGE",
	LOCATION_AVAILABILITY_LIST_DATA_RESET: "LOCATION_AVAILABILITY_LIST_DATA_RESET",
	ITEM_AVAILABILITY_STATE_FILTERS: "ITEM_AVAILABILITY_STATE_FILTERS",
	ITEM_AVAILABILITY_INITIAL_STATE: "ITEM_AVAILABILITY_INITIAL_STATE",
	ITEM_AVAIALABILITY_ITEM_STATE_REQUEST: "ITEM_AVAIALABILITY_ITEM_STATE_REQUEST",
	ITEM_AVAIALABILITY_ITEM_STATE_SUCCESS: "ITEM_AVAIALABILITY_ITEM_STATE_SUCCESS",
	ITEM_AVAIALABILITY_ITEM_STATE_DATA_FAILURE: "ITEM_AVAIALABILITY_ITEM_STATE_DATA_FAILURE",
	ITEM_AVAIALABILITY_ITEM_STATE_FAILURE: "ITEM_AVAIALABILITY_ITEM_STATE_FAILURE",
	ITEM_AVAIALABILITY_STATE_FILTERS: "ITEM_AVAIALABILITY_STATE_FILTERS",
	ITEM_AVAILABILITY_STATE_OBJECTS_CHANGE: "ITEM_AVAILABILITY_STATE_OBJECTS_CHANGE",
	ITEM_AVAILABILITY_APPLY_FILTERS_CHANGE: "ITEM_AVAILABILITY_APPLY_FILTERS_CHANGE",
	ITEM_AVAILABILITY_ORIGINAL_DATA_CHANGE: "ITEM_AVAILABILITY_ORIGINAL_DATA_CHANGE",
	ITEM_AVILABILITY_VIEW_CHANGE: "ITEM_AVILABILITY_VIEW_CHANGE",
	ITEM_AVAILABILITY_ORIGINAL_DATA_CHANGE_FALSE_VALUE: "ITEM_AVAILABILITY_ORIGINAL_DATA_CHANGE_FALSE_VALUE",
	ITEM_AVAILABILITY_INITIAL_SORT: "ITEM_AVAILABILITY_INITIAL_SORT",
	ITEM_AVAILABILITY_DATA_FILTERS_CHANGE: "ITEM_AVAILABILITY_DATA_FILTERS_CHANGE",
	SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS: "SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS",
	SPECIFIC_ITEM_AVAILABILITY_ITEM_INITIAL_STATE: "SPECIFIC_ITEM_AVAILABILITY_ITEM_INITIAL_STATE",
	SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS: "SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS",
	SPECIFIC_ITEM_AVAILABILITY_LOCATION_INITIAL_STATE: "SPECIFIC_ITEM_AVAILABILITY_LOCATION_INITIAL_STATE",
	SPECIFIC_ITEM_AVAILABILITY_ITEM_INITIAL_STATE_SORT: "SPECIFIC_ITEM_AVAILABILITY_ITEM_INITIAL_STATE_SORT",
	SPECIFIC_ITEM_AVAILABILITY_LOCATION_INITIAL_STATE_SORT: "SPECIFIC_ITEM_AVAILABILITY_LOCATION_INITIAL_STATE_SORT",
	SPECIFIC_ITEM_AVAILABILITY_DATA_FILTERS_CHANGE: "SPECIFIC_ITEM_AVAILABILITY_DATA_FILTERS_CHANGE",
	SPECIFIC_ITEM_AVAILABILITY_ITEM_LOCATION_FILTERS_CHANGE: "SPECIFIC_ITEM_AVAILABILITY_ITEM_LOCATION_FILTERS_CHANGE",
	SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA_FILTERS_CHANGE: "SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA_FILTERS_CHANGE",
	SPECIFIC_ITEM_AVAILABILITY_ITEM_FILTERS_CHANGE: "SPECIFIC_ITEM_AVAILABILITY_ITEM_FILTERS_CHANGE",
	SPECIFIC_ITEM_AVAILABILITY_DATA_ITEM_REQUEST: "SPECIFIC_ITEM_AVAILABILITY_DATA_ITEM_REQUEST",
	SPECIFIC_ITEM_AVAILABILITY_DATA_REQUEST_SUCCESS: "SPECIFIC_ITEM_AVAILABILITY_DATA_REQUEST_SUCCESS",
	SPECIFIC_ITEM_AVAILABILITY_DATA_REQUEST_FAILURE: "SPECIFIC_ITEM_AVAILABILITY_DATA_REQUEST_FAILURE",
	SPECIFIC_ITEM_AVAILABILITY_DATA_OBJECTS_CHANGE: "SPECIFIC_ITEM_AVAILABILITY_DATA_OBJECTS_CHANGE",
	SPECIFIC_ITEM_AVAILABILITY_DATA_LOCATION_REQUEST: "SPECIFIC_ITEM_AVAILABILITY_DATA_LOCATION_REQUEST",
	SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA_REQUEST_SUCCESS:
		"SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA_REQUEST_SUCCESS",
	SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA_REQUEST_FAILURE:
		"SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA_REQUEST_FAILURE",
	SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA_OBJECTS_CHANGE: "SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA_OBJECTS_CHANGE",
	ITEM_AVAILABILITY_LOCATION_ORIGINAL_DATA_CHANGE: "ITEM_AVAILABILITY_LOCATION_ORIGINAL_DATA_CHANGE",
	ITEM_AVAILABILITY_LOCATION_ORIGINAL_DATA_CHANGE_FALSE_VALUE:
		"ITEM_AVAILABILITY_LOCATION_ORIGINAL_DATA_CHANGE_FALSE_VALUE",
	ITEM_ISSUES_FILTERS_CHANGE: "ITEM_ISSUES_FILTERS_CHANGE",
	ITEM_ISSUES_STATE_FILTERS: "ITEM_ISSUES_STATE_FILTERS",
	ITEM_ISSUES_APPLY_FILTERS_CHANGE: "ITEM_ISSUES_APPLY_FILTERS_CHANGE",
	ITEM_ISSUES_INITIAL_SORT: "ITEM_ISSUES_INITIAL_SORT",
	ITEM_ISSUES_DATA_FILTERS_CHANGE: "ITEM_ISSUES_DATA_FILTERS_CHANGE",
	ITEM_ISSUES_INITIAL_STATE_SORT: "ITEM_ISSUES_INITIAL_STATE_SORT",

	// menu pull request
	GET_MENU_PULL_INFO_REQUEST: "GET_MENU_PULL_INFO_REQUEST",
	GET_MENU_PULL_INFO_SUCCESS: "GET_MENU_PULL_INFO_SUCCESS",
	GET_MENU_PULL_INFO_FAILURE: "GET_MENU_PULL_INFO_FAILURE",
	GET_MENU_PULL_INFO_UPDATE: "GET_MENU_PULL_INFO_UPDATE",

	REQUEST_MENU_PULL_REQUEST: "REQUEST_MENU_PULL_REQUEST",
	REQUEST_MENU_PULL_SUCCESS: "REQUEST_MENU_PULL_SUCCESS",
	REQUEST_MENU_PULL_FAILURE: "REQUEST_MENU_PULL_FAILURE",
	ITEM_ISSUES_STATE_CHANGE: "ITEM_ISSUES_STATE_CHANGE",
	ITEM_ISSUES_STATE_REQUEST: "ITEM_ISSUES_STATE_REQUEST",
	ITEM_ISSUES_STATE_SUCCESS: "ITEM_ISSUES_STATE_SUCCESS",
	ITEM_ISSUES_STATE_FAILURE: "ITEM_ISSUES_STATE_FAILURE",
	GET_ITEM_ISSUES_METRIC_STATE_CHANGE: "GET_ITEM_ISSUES_METRIC_STATE_CHANGE",
	ITEM_ISSUES_OBJECTS_CHANGE: "ITEM_ISSUES_OBJECTS_CHANGE",
	ITEM_ISSUES_ORIGINAL_DATA_CHANGE_FALSE_VALUE: "ITEM_ISSUES_ORIGINAL_DATA_CHANGE_FALSE_VALUE",
	SPECIFIC_ITEM_ISSUE_STATE_FILTERS: "SPECIFIC_ITEM_ISSUE_STATE_FILTERS",
	ITEM_ISSUES_ORIGINAL_DATA_CHANGE: "ITEM_ISSUES_ORIGINAL_DATA_CHANGE",
	SPECIFIC_ITEM_ISSUE_STATE_FILTERS_INITIAL_STATE: "SPECIFIC_ITEM_ISSUE_STATE_FILTERS_INITIAL_STATE",
	SPECIFIC_ITEM_STATE_FILTERS_CHANGE: "SPECIFIC_ITEM_STATE_FILTERS_CHANGE",
	SPECIFIC_ITEM_ISSUE_DATA_REQUEST: "SPECIFIC_ITEM_ISSUE_DATA_REQUEST",
	SPECIFIC_ITEM_ISSUE_DATA_REQUEST_SUCCESS: "SPECIFIC_ITEM_ISSUE_DATA_REQUEST_SUCCESS",
	SPECIFIC_ITEM_ISSUE_DATA_REQUEST_FAILURE: "SPECIFIC_ITEM_ISSUE_DATA_REQUEST_FAILURE",
	SPECIFIC_ITEM_ISSUE_DATA_OBJECTS_CHANGE: "SPECIFIC_ITEM_ISSUE_DATA_OBJECTS_CHANGE",
	SPECIFIC_ITEM_ISSUE_DATA_FILTERS_CHANGE: "SPECIFIC_ITEM_ISSUE_DATA_FILTERS_CHANGE",
	ACTIVITY_LOG_STATE_FILTERS: "ACTIVITY_LOG_STATE_FILTERS",
	ACTIVITY_LOG_DATA_REQUEST: "ACTIVITY_LOG_DATA_REQUEST",
	ACTIVITY_LOG_DATA_REQUEST_SUCCESS: "ACTIVITY_LOG_DATA_REQUEST_SUCCESS",
	ACTIVITY_LOG_DATA_REQUEST_FAILURE: "ACTIVITY_LOG_DATA_REQUEST_FAILURE",
	ACTIVITY_LOG_DATA_OBJECTS_CHANGE: "ACTIVITY_LOG_DATA_OBJECTS_CHANGE",
	PERISCOPE_EARLY_ACCESS_STATE_CHANGE: "PERISCOPE_EARLY_ACCESS_STATE_CHANGE",
	PERISCOPE_EARLY_ACCESS_DATA_CHANGE: "PERISCOPE_EARLY_ACCESS_DATA_CHANGE",
	PERISCOPE_EARLY_ACCESS_DATA_REQUEST: "PERISCOPE_EARLY_ACCESS_DATA_REQUEST",
	PERISCOPE_EARLY_ACCESS_DATA_FAILURE: "PERISCOPE_EARLY_ACCESS_DATA_FAILURE",
	PERISCOPE_EARLY_ACCESS_DATA_VALUES_CHANGE: "PERISCOPE_EARLY_ACCESS_DATA_VALUES_CHANGE",
	PERISCOPE_RESPONSE_DATA_CHANGE: "PERISCOPE_RESPONSE_DATA_CHANGE",
	GET_PERISCOPE_ENABLED_LOCATIONS: "GET_PERISCOPE_ENABLED_LOCATIONS",
	GET_PERISCOPE_FILTERS: "GET_PERISCOPE_FILTERS",
	GET_PERISCOPE_ENABLED_BRANDS: "GET_PERISCOPE_ENABLED_BRANDS",
	GET_PERISCOPE_ENABLED_CITIES: "GET_PERISCOPE_ENABLED_CITIES",
	PERISCOPE_COUNTRY_WISE_PLATFORMS: "PERISCOPE_COUNTRY_WISE_PLATFORMS",

	// menu pull request
	GET_MENU_PULL_INFO_REQUEST: "GET_MENU_PULL_INFO_REQUEST",
	GET_MENU_PULL_INFO_SUCCESS: "GET_MENU_PULL_INFO_SUCCESS",
	GET_MENU_PULL_INFO_FAILURE: "GET_MENU_PULL_INFO_FAILURE",
	GET_MENU_PULL_INFO_UPDATE: "GET_MENU_PULL_INFO_UPDATE",

	REQUEST_MENU_PULL_REQUEST: "REQUEST_MENU_PULL_REQUEST",
	REQUEST_MENU_PULL_SUCCESS: "REQUEST_MENU_PULL_SUCCESS",
	REQUEST_MENU_PULL_FAILURE: "REQUEST_MENU_PULL_FAILURE",

	//test cart actions
	CREATE_TEST_CART: "CREATE_TEST_CART",
	RESET_TEST_CART: "RESET_TEST_CART",
	ADD_ITEMS_TEST_CART: "ADD_ITEMS_TEST_CART",
	PLACE_TEST_ORDER: "PLACE_TEST_ORDER",
	UPDATE_ITEMS_TEST_CART: "UPDATE_ITEMS_TEST_CART",
	REMOVE_ITEMS_TEST_CART: "REMOVE_ITEMS_TEST_CART",
	MENU_PULL_FROM_URL_STATUS_UPDATE: "MENU_PULL_FROM_URL_STATUS_UPDATE"
};

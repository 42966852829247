import React, { useEffect, useState } from "react";
import { trackEvent } from "../../atlas-utils";

const MerakiLanding = () => {
	const initialState = {
		hero: false,
		benefits: false,
		branding: false,
		marketing: false,
		checkout: false
	};

	const merakiEventFired = JSON.parse(localStorage.getItem("meraki_event_fired")) ?? {
		hero: false,
		benefits: false,
		branding: false,
		marketing: false,
		checkout: false
	};
	const [animationDetails, setAnimationDetails] = useState(initialState);
	const [isBannerPresent, setIsBannerPresent] = useState(false);
	const [eventFiredDetails, setEventFiredDetails] = useState(merakiEventFired);

	useEffect(() => {
		const observer = setUpObserver();
		registerPostHogEvent("meraki_landing_page_visit");

		window.addEventListener("beforeunload", handleReload);
		return () => {
			observer.disconnect();
			window.removeEventListener("beforeunload", handleReload);
		};
	}, []);

	useEffect(() => {
		const observer = setUpBannerObserver();

		return () => {
			observer.disconnect();
		};
	}, []);

	const handleReload = () => {
		localStorage.removeItem("meraki_event_fired");
	};

	const setUpObserver = () => {
		const options = {
			root: document.querySelector("#observer-container"),
			rootMargin: "0px",
			threshold: 0.5
		};
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const dataSet = entry.target.dataset["id"];
					if (dataSet) {
						registerPostHogEvent("meraki_scroll_depth", { section: `${dataSet}_section` }, dataSet);
						updateAnimationDetails(dataSet);
					}
				}
			});
		}, options);

		const targetElements = document.querySelectorAll(".landing-page-section");

		targetElements.forEach((element) => {
			observer.observe(element);
		});
		return observer;
	};

	const setUpBannerObserver = () => {
		const config = { childList: true, subtree: true };
		const observer = new MutationObserver(() => {
			const banner = document.querySelector(".banner-message");
			if (banner) {
				setIsBannerPresent(true);
			} else {
				setIsBannerPresent(false);
			}
		});

		observer.observe(document.body, config);
		return observer;
	};

	const updateAnimationDetails = (dataset) => {
		setAnimationDetails((prev) => {
			if (!prev[dataset]) {
				const details = { ...prev };
				details[dataset] = true;
				return details;
			}
			return prev;
		});
	};

	const registerPostHogEvent = (eventName, metaData, section) => {
		if (eventName === "meraki_landing_page_visit") {
			trackEvent(eventName, { ...metaData });
			return;
		}

		const storedEvents = JSON.parse(localStorage.getItem("meraki_event_fired")) || {};

		if (!storedEvents[section]) {
			storedEvents[section] = true;
			trackEvent(eventName, { ...metaData });
			localStorage.setItem("meraki_event_fired", JSON.stringify(storedEvents));
			setEventFiredDetails(storedEvents);
		}
	};

	return (
		<div id="observer-container" style={!isBannerPresent ? { height: "91vh" } : {}}>
			<HeroSection animateComplete={animationDetails["hero"]} />
			<KeyBenefitsSection animateComplete={animationDetails["benefits"]} />
			<CustomBranding animateComplete={animationDetails["branding"]} />
			<Marketing animateComplete={animationDetails["marketing"]} />
			<Checkout animateComplete={animationDetails["checkout"]} />
		</div>
	);
};

const HeroSection = ({ animateComplete }) => {
	return (
		<div data-id="hero" className="landing-page-section hero-section-container">
			<div className={`hero-wrapper ${animateComplete ? "animate-complete" : ""}`}>
				<div className="hero-section-left-sub">
					<div className="title">
						<span role="img" aria-label="star">
							⭐
						</span>{" "}
						Your Restaurant. Your Brand. Your Profits. Take Control Today!
					</div>

					<h1 className="header online-ordering">
						<span>Your Own Online Ordering System –</span> <span className="blue">Zero Commissions</span>
					</h1>

					<p>
						Take full control of your restaurant’s online orders with a custom-branded website and mobile
						app. No commissions, no middlemen—just direct customer connections and higher profits
					</p>

					<button className="at-btn demo-btn">Interested? Let’s Chat</button>
				</div>
				<div className="hero-section-right-sub">
					<div className="images-container">
						<img
							className="image-one"
							src="/assets/meraki/pages/landing/homepage-with-cart-updated.svg"
							alt=""
						/>
						<div className="image-two-container">
							<img className="image-two" src="/assets/meraki/pages/landing/homepage-mobile.svg" alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const KeyBenefitsSection = ({ animateComplete }) => {
	const benefitsData = [
		{
			id: 1,
			iconSrc: "/assets/meraki/pages/landing/percent.svg",
			title: "No Commission Fees",
			desc: "Keep 100% of your earnings with direct orders and no third-party cuts."
		},
		{
			id: 2,
			iconSrc: "/assets/meraki/pages/landing/website-icon.svg",
			title: "Branded Website & App",
			desc: "Get a fully SEO compliant, customised website and app designed for your brand."
		},
		{
			id: 3,
			iconSrc: "/assets/meraki/pages/landing/integrations-icon.svg",
			title: "Seamless Integrations",
			desc: "Easily integrate with POS, payment gateways, and delivery platforms."
		},
		{
			id: 4,
			iconSrc: "/assets/meraki/pages/landing/data-icon.svg",
			title: "Own Your Customer Data",
			desc: "Access and leverage customer insights to boost engagement and sales."
		},
		{
			id: 5,
			iconSrc: "/assets/meraki/pages/landing/promotions-icon.svg",
			title: "Marketing & Promotions",
			desc: "Run promotions, loyalty programs, and discounts effortlessly."
		},
		{
			id: 6,
			iconSrc: "/assets/meraki/pages/landing/location-support-icon.svg",
			title: "Multi-Location Support",
			desc: "A centralized dashboard to track and control multiple locations."
		}
	];

	return (
		<div
			data-id="benefits"
			className={`landing-page-section benefits-section-container ${animateComplete ? "animate-complete" : ""}`}
		>
			<div className="benefits-sub-container">
				<h1 className="header">
					<span className="blue">Key Benefits</span> – Why Choose Meraki?
				</h1>
				<div className="benefits-wrapper">
					{benefitsData.map((data) => (
						<div key={data.id} className="benefit-card">
							<div>
								<img src={data.iconSrc} alt="" />
							</div>
							<h5>{data.title}</h5>
							<p>{data.desc}</p>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

const CustomBranding = ({ animateComplete }) => {
	return (
		<div data-id="branding" className="landing-page-section branding-section-container">
			<div className="branding-sub-container">
				<div className="branding-section-left-sub">
					<div className={`left-sub-wrapper ${animateComplete ? "animation-complete" : ""}`}>
						<h1 className="header">
							Meraki,{" "}
							<div className="meraki-features">
								<div className="meraki-features-scroll">
									<span className="blue">Custom Branding</span>
									<span>UI Flexibility</span>
									<span>Faster Checkouts</span>
									<span className="blue">Custom Branding</span>
									<span>UI Flexibility</span>
									<span>Faster Checkouts</span>
								</div>
							</div>
						</h1>
						<p className="branding-description">
							Customise your platform to match your brand’s identity with flexible UI elements, colors,
							and logos. Tailor your menu effortlessly for a seamless and engaging experience. Easily
							switch to an Easily Customisable App, an easily creatable Menu, or Faster Checkout to suit
							your needs.
						</p>

						<button className="at-btn demo-btn">Interested? Let’s Chat</button>
					</div>
				</div>
				<div className={`branding-section-right-sub ${animateComplete ? "animation-complete" : ""}`}>
					<div className="branding-img-background">
						<img
							className={`image-one ${animateComplete ? "animation-complete" : ""}`}
							src="/assets/meraki/pages/landing/meraki-screen-landscape.svg"
							alt=""
						/>
						<img
							className={`image-two ${animateComplete ? "animation-complete" : ""}`}
							src="/assets/meraki/pages/landing/meraki-color-pallet.svg"
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const Marketing = ({ animateComplete }) => {
	return (
		<div data-id="marketing" className="landing-page-section marketing-section-container">
			<div className={`marketing-sub-container ${animateComplete ? "animation-complete" : ""}`}>
				<div className="marketing-section-left-sub">
					<div className="marketing-left-sub-wrapper">
						<img
							className={`phone ${animateComplete ? "animation-complete" : ""}`}
							src="/assets/meraki/pages/landing/landing-phone.svg"
							alt=""
						/>
						<img
							className={`campain ${animateComplete ? "animation-complete" : ""}`}
							src="/assets/meraki/pages/landing/campain-settings.svg"
							alt=""
						/>
						<img
							className={`notification ${animateComplete ? "animation-complete" : ""}`}
							src="/assets/meraki/pages/landing/landing-notification.svg"
							alt=""
						/>
						<img
							className={`message ${animateComplete ? "animation-complete" : ""}`}
							src="/assets/meraki/pages/landing/landing-message.svg"
							alt=""
						/>
					</div>
				</div>
				<div className="marketing-section-right-sub">
					<h1 className="header">
						Boost Customer Loyalty with <span className="blue">Smart Marketing</span>
					</h1>
					<p>
						Engage customers with personalised offers, automated campaigns, and loyalty rewards. Drive
						repeat sales through targeted promotions across email, SMS, and social media.
					</p>
					<button className="at-btn demo-btn">Interested? Let’s Chat</button>
				</div>
			</div>
		</div>
	);
};

const Checkout = ({ animateComplete }) => {
	return (
		<div data-id="checkout" className="landing-page-section checkout-section-container">
			<div className="checkout-sub-container">
				<div className="checkout-section-left-sub">
					<div className={`checkout-left-sub-wrapper ${animateComplete ? "animation-complete" : ""}`}>
						<h1 className="header">
							<span className="blue">Effortless</span> Checkout, <span className="blue">Reliable</span>{" "}
							Deliveries
						</h1>
						<p>
							Give your customers a smooth, hassle-free ordering experience with multiple payment options
							and seamless integration with trusted delivery partners for fast, on-time fulfillment.
						</p>
						<button className="at-btn demo-btn">Interested? Let’s Chat</button>
					</div>
				</div>
				<div className="checkout-section-right-sub">
					<div className="checkout-right-sub-wrapper">
						<img
							className={`phone ${animateComplete ? "animation-complete" : ""}`}
							src="/assets/meraki/pages/landing/landing-mcd-order-phone.svg"
							alt=""
						/>
						<img
							className={`checkout ${animateComplete ? "animation-complete" : ""}`}
							src="/assets/meraki/pages/landing/landing-checkout-btn-bar.svg"
							alt=""
						/>
						<img
							className={`notification ${animateComplete ? "animation-complete" : ""}`}
							src="/assets/meraki/pages/landing/landing-order-notification.svg"
							alt=""
						/>
						<img
							className={`razor-pay ${animateComplete ? "animation-complete" : ""}`}
							src="/assets/meraki/pages/landing/landing-razor-pay.svg"
							alt=""
						/>
						<img
							className={`pay-tabs ${animateComplete ? "animation-complete" : ""}`}
							src="/assets/meraki/pages/landing/landing-pay-tabs.svg"
							alt=""
						/>
						<img
							className={`stripe ${animateComplete ? "animation-complete" : ""}`}
							src="/assets/meraki/pages/landing/landing-stripe.svg"
							alt=""
						/>
						<img
							className={`wallet ${animateComplete ? "animation-complete" : ""}`}
							src="/assets/meraki/pages/landing/landing-wallet-icon.svg"
							alt=""
						/>
						<img
							className={`scooter ${animateComplete ? "animation-complete" : ""}`}
							src="/assets/meraki/pages/landing/landing-scooter-icon.svg"
							alt=""
						/>
						<img
							className={`shadow-fax ${animateComplete ? "animation-complete" : ""}`}
							src="/assets/meraki/pages/landing/landing-shadow-fax.svg"
							alt=""
						/>
						<img
							className={`add-logs ${animateComplete ? "animation-complete" : ""}`}
							src="/assets/meraki/pages/landing/landing-add-logs.svg"
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MerakiLanding;

import React from "react";

const DownloadIcon = ({ styles = {}, fill = "#AAAAAA", variations = {} }) => {
	return (
		<svg
			width="18"
			height="18"
			style={{ ...styles }}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...variations}
		>
			<path
				d="M8.16699 0.666664V2.33333H9.83366V0.666664H8.16699ZM8.16699 4V11.1549L5.42285 8.41081L4.24447 9.58919L9.00033 14.345L13.7562 9.58919L12.5778 8.41081L9.83366 11.1549V4H8.16699ZM0.666992 13.1667V17.3333H17.3337V13.1667H15.667V15.6667H2.33366V13.1667H0.666992Z"
				fill={fill}
			/>
		</svg>
	);
};

export default DownloadIcon;

import React from "react";
import { InputField } from "./InputField";

// third party
import { connect } from "react-redux";

@connect((store) => ({
	supportedLanguages: store.login.loggedInbizDetail.supportedLanguages,
	translation: store.configItems.translation
}))
export class InputWithLabel extends React.Component {
	render() {
		const {
			children,
			validationMessage,
			isFilterItem,
			requiredLabel = false,
			showCustomTooltip = false,
			tooltipInfo = "",
			tooltipPosition = "up-left",
			showLanguages = false,
			showCascade = false,
			supportedLanguages = [],
			currSelectedLang = "",
			handleLanguage,
			translation = {},
			handleCopyText = () => {},
			...props
		} = this.props;

		return (
			<div
				className={
					"at-input-container " +
					(props.classes || "") +
					(isFilterItem ? " filter-section-item" : "") +
					(isFilterItem && props.value ? " active" : "")
				}
			>
				{(showLanguages || children) && (
					<label className={"at-input-label" + (isFilterItem ? " meta-info" : "")}>
						{showLanguages || showCascade ? (
							<div className="label-multi-lang-container">
								{
									<div>
										<span className={"label" + (requiredLabel ? " required-sign" : "")}>
											{children ?? ""}
										</span>
										{showCustomTooltip && tooltipInfo && (
											<span aria-label={tooltipInfo} data-balloon-pos={tooltipPosition}>
												<img
													className="info"
													src="/assets/icons/info.png"
													onClick={(e) => e.stopPropagation()}
												/>
											</span>
										)}
									</div>
								}
								{showLanguages && supportedLanguages.length > 1 && (
									<div className="languages">
										<span>|</span>
										{supportedLanguages.map((lang, i) => (
											<React.Fragment key={i}>
												<span
													className={currSelectedLang === lang.value ? "selected" : ""}
													onClick={() => handleLanguage(lang)}
												>
													{lang.valueForDisplay}
												</span>
												<span>|</span>
											</React.Fragment>
										))}
									</div>
								)}
								{showCascade && (
									<div className="languages">
										{
											<React.Fragment>
												<span
													className={"cascade"}
													onClick={() => {
														this.props.showPriceCascadeSection();
													}}
												>
													Cascade
												</span>
											</React.Fragment>
										}
									</div>
								)}
							</div>
						) : (
							<React.Fragment>
								<span className={requiredLabel ? " required-sign" : ""}>{children}</span>
								{!showLanguages && showCustomTooltip && tooltipInfo && (
									<span aria-label={tooltipInfo} data-balloon-pos={tooltipPosition}>
										<img
											className="info"
											src="/assets/icons/info.png"
											onClick={(e) => e.stopPropagation()}
										/>
									</span>
								)}
							</React.Fragment>
						)}
					</label>
				)}
				<InputField
					{...props}
					handleCopyText={handleCopyText}
					showLanguages={showLanguages}
					actionState={translation}
				/>
				{validationMessage && <div className="validation-message">{validationMessage}</div>}
			</div>
		);
	}
}

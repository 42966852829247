import React, { useState, useEffect, useRef, useMemo } from "react";

// components
import Header from "../../components/Analytics/Common/Header";
import SectionHeader from "../../components/Analytics/Common/SectionHeader";
import ShareReport from "../../components/Analytics/Common/ShareReport";
import Filters, { BreakdownByFilter, CompareFilter } from "../../components/Analytics/Common/Filters";
import { Topbar } from "../../components/_commons/Topbar";
import MetricCard from "../../components/Analytics/Common/MetricCard";
import MetricHeader from "../../components/Analytics/Common/MetricHeader";
import MetricSection from "../../components/Analytics/Common/MetricSection";
import ChartMetric from "../../components/Analytics/Common/ChartMetric";
import MultiChartMetrics from "../../components/Analytics/Common/MultiChartMetrics";
import ActionButton from "../../components/Analytics/Common/ActionButton";
import ChartSelector from "../../components/Analytics/Common/ChartSelector";
import ShowComparison from "../../components/Analytics/Common/ShowComparison";
import { CustomTable } from "../../components/_commons/CustomTable";
import { ButtonIcon } from "../../components/_commons/ButtonIcon";
import { NestedEntityContainer } from "../../components/_commons/NestedEntityContainer";
import DownloadButton from "../../components/Analytics/Common/DownloadButton";
import { Share } from "../../components/Analytics/Common/Share";

// charts
import Line from "../../components/NivoCharts/Line";
import Sankey from "../../components/NivoCharts/Sankey";
import Pie from "../../components/NivoCharts/Pie";
import Heatmap from "../../components/NivoCharts/Heatmap";
import Bar from "../../components/NivoCharts/Bar";

// third party
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import history from "../../history";

// store
import { store } from "../../store/configureStore";

// utils
import {
	scroll,
	commifyNumbers,
	getSortOrder,
	breadcrumbs,
	trackEvent,
	adjustNestedContainer
} from "../../atlas-utils";

// helpers
import {
	captureScreenshot,
	generateDurationText,
	getDurationPreset,
	getReadableDateFilter,
	trackPNGDownload
} from "../../helpers/analytics";

// actions
import {
	updateOrderAnalyticsState,
	fetchOrdersReceivedMetrics,
	fetchOrdersReceivedChart,
	fetchOrderPerformanceMetrics,
	fetchOrderPerformanceChart,
	fetchOrdersBreakdownChart,
	fetchOrdersBreakdownTable,
	fetchOrdersByLocation,
	fetchOrderFrequency,
	fetchOrdersByItem,
	fetchLostOrdersMetrics,
	fetchLostOrdersChart,
	fetchLostOrdersTable,
	fetchLostOrdersBreakdownChart,
	fetchLostOrdersBreakdownTable,
	fetchAndGenerateCSVReport,
	getAllAppliedFilters
} from "../../actions/analytics";

// constants
import { ANALYTICS_SECTIONS, ANALYTICS_DEFAULT_COLORS, NESTED_ENTITY_TYPES } from "../../client-config";

const ENVIRONMENT_FLAG = process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG;
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const Orders = ({
	match,
	ordersAnalytics,
	analyticsFiltersState,
	currencySymbol,
	isMultibrandEnabled = false,
	analyticsReportState,
	access = {}
}) => {
	const { search } = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);
	const [currTab, setCurrTab] = useState(query.get("metric") || ANALYTICS_SECTIONS["orders"]?.["tabs"]?.[0]?.value);
	const [isPageScrolled, setPageScrolled] = useState(document?.documentElement?.scrollTop > 60 || false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const { appliedFilters } = analyticsFiltersState;
	const nestedRef = useRef();
	const refs = {
		orders_received: useRef(),
		order_performance: useRef(),
		orders_breakdown: useRef(),
		orders_by_location: useRef(),
		order_frequency: useRef(),
		orders_by_item: useRef(),
		lost_orders: useRef(),
		lost_orders_breakdown: useRef()
	};
	const areMultipleBrandsSelected =
		Boolean(appliedFilters?.brand_id?.find((brand) => brand === "all")) || appliedFilters?.brand_id?.length > 1;
	const areMultiplePlatformsSelected =
		Boolean(appliedFilters?.platform_names?.find((plf) => plf === "all")) ||
		appliedFilters?.platform_names?.length > 1;

	const switchTab = (tab, tabClick = false) => {
		setCurrTab(tab.value);
		window.requestAnimationFrame(() => {
			scroll(
				{
					top: refs[tab.value]?.current?.offsetTop - (document.querySelector(".with-banner") ? 290 : 230),
					left: 0
				},
				window,
				"instant"
			);
		});
		// track event
		if (tabClick) {
			trackEvent("analytics_tab_clicked", {
				module: "Orders",
				tab: tab.label
			});
		}
	};

	const handlePageScroll = () => {
		if (document.documentElement.scrollTop > 60) {
			setPageScrolled(true);
		} else {
			setPageScrolled(false);
		}
	};

	useEffect(() => {
		// if metric param exists in url, scroll to that metric
		if (query.get("metric")) {
			switchTab({ value: query.get("metric") });
		} else {
			document.documentElement.scrollTop = 0;
		}
		trackEvent("analytics_module", { module: "Orders" });
		window.addEventListener("scroll", handlePageScroll);
		return () => {
			window.removeEventListener("scroll", handlePageScroll);
		};
	}, []);

	const handleTableRowClick = (rowData, metric, entity) => {
		if (rowData.name && rowData.item) {
			// create breadcrumbs
			let bc = [];
			bc.push({
				title: "Orders Analytics",
				to: `${match.url}?metric=${metric}`
			});
			const encodedName = encodeURIComponent(rowData?.name?.replaceAll("%", "percent"));
			const url = `${match.url}/view/${entity}/${encodedName}/${rowData.item}${`?filters=${query.get(
				"filters"
			)}`}${`&bc=${breadcrumbs.encode(bc)}`}`;
			history.push(url);
		}
	};

	const renderCenterMetricValue = (props) => {
		let total = 0;
		props.dataWithArc.forEach((datum) => {
			total += Math.round(datum.value);
		});
		return commifyNumbers(total);
	};

	const handleMetricScroll = () => {
		switch (currTab) {
			case "orders_breakdown":
				if (isMultibrandEnabled && !areMultiplePlatformsSelected && !areMultipleBrandsSelected) {
					switchTab(ANALYTICS_SECTIONS["orders"]?.["tabs"]?.[0]);
				} else if (!isMultibrandEnabled && !areMultiplePlatformsSelected) {
					switchTab(ANALYTICS_SECTIONS["orders"]?.["tabs"]?.[0]);
				}
				break;
			case "lost_orders_breakdown":
				if (isMultibrandEnabled && !areMultiplePlatformsSelected && !areMultipleBrandsSelected) {
					switchTab(ANALYTICS_SECTIONS["orders"]?.["tabs"]?.[6]);
				} else if (!isMultibrandEnabled && !areMultiplePlatformsSelected) {
					switchTab(ANALYTICS_SECTIONS["orders"]?.["tabs"]?.[6]);
				}
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		handleMetricScroll();
	}, [appliedFilters?.platform_names, appliedFilters?.brand_id]);

	const handleNestedEntity = (toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	};

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "analytics",
				start: "01:10"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};
	const generateImage = (key, title = "Revenue", subChart) => {
		const durationObject = getAllAppliedFilters()?.durationObject?.duration;
		const comparisonDuration = getAllAppliedFilters()?.durationObject?.comparisonDuration;

		let timeDuration = durationObject?.preset
			? `${getDurationPreset(durationObject?.preset)?.label}`
			: durationObject?.preset ||
				`${generateDurationText(durationObject?.custom?.startDate, durationObject?.custom?.endDate)}`;
		timeDuration += `${comparisonDuration ? ` / Compared to ${generateDurationText(comparisonDuration?.startDate, comparisonDuration?.endDate)}` : ""}`;

		if (key === "orders_breakdown") {
			const metricSectionElement = refs[key]?.current?.querySelector(`.metric-section .nivo-chart-${subChart}`);
			if (metricSectionElement) {
				let elementRef = { current: metricSectionElement };
				captureScreenshot(elementRef, title, timeDuration).then((res) => {
					trackPNGDownload(key, subChart);
				});
			}
		} else if (key === "lost_orders" || key === "lost_orders_breakdown") {
			const metricSectionElement = refs[key]?.current?.querySelector(".metric-section .chart-container");
			if (metricSectionElement) {
				let elementRef = { current: metricSectionElement };
				captureScreenshot(elementRef, title, timeDuration).then((res) => {
					trackPNGDownload(key, subChart);
				});
			}
		} else {
			captureScreenshot(refs[key], title, timeDuration).then((res) => {
				trackPNGDownload(key, subChart);
			});
		}
	};

	const [isFormOpen, setIsFormOpen] = useState(false);
	const handleCancel = () => {
		setIsFormOpen(false);
	};
	const linkShareBtn = (
		<div onClick={() => setIsFormOpen(true)} className="share">
			Share
		</div>
	);
	return (
		<div className={"analytics-orders section-container-common" + (isPageScrolled ? " scrolled" : "")}>
			<Header>
				<SectionHeader
					title="Orders Analytics"
					// headerRight={<ShareReport switchToBtn={!isPageScrolled} />}
					showHelpBtn={true}
					handlePiperAcademy={handlePiperAcademy}
					isPageScrolled={isPageScrolled}
					headerRight={linkShareBtn}
				/>
				<Filters showBrands showLocations showPlatforms showDateCompare module="Orders" />
				<Topbar
					tabs={ANALYTICS_SECTIONS["orders"]["tabs"]}
					selectedTab={currTab}
					switchTab={(tab) => switchTab(tab, true)}
					hiddenTabs={
						(!isMultibrandEnabled && !areMultiplePlatformsSelected) ||
						(isMultibrandEnabled && !areMultiplePlatformsSelected && !areMultipleBrandsSelected)
							? ["orders_breakdown", "lost_orders_breakdown"]
							: []
					}
				/>
			</Header>
			<Share module="orders" handleCancel={handleCancel} isOpen={isFormOpen} />
			<MetricCard metric="orders_received" cardRef={refs["orders_received"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<React.Fragment>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchOrdersReceivedMetrics}
							state={ordersAnalytics}
						>
							{({ metricsLoading, metrics }) => (
								<React.Fragment>
									<MetricHeader>
										<div className="header-left">
											<ChartMetric
												size="large"
												loading={metricsLoading}
												label="Orders Received"
												description="Total count of all orders received during the specified time frame across all platforms."
												value={metrics?.order_received_orders?.value}
												percentageChange={metrics?.order_received_orders?.percentageChange}
												compareValue={metrics?.order_received_orders?.compareValue}
											/>
										</div>
										<div className="header-right">
											<ActionButton
												variant="link"
												href={`/analytics/revenue${
													query.get("filters") ? `?filters=${query.get("filters")}` : ""
												}`}
												clickHandler={() =>
													trackEvent("analytics_link_text_clicked", {
														module: "Orders",
														type: "Module",
														origin: metric,
														destination: "Revenue"
													})
												}
												showArrow={false}
											>
												Go to Revenue Analytics
											</ActionButton>
											<DownloadButton
												coachMark={true}
												emptyData={
													((ordersAnalytics?.orders_received?.graphData || []).length == 0 ||
														!ordersAnalytics?.orders_received?.metrics) &&
													!metricsLoading &&
													!ordersAnalytics?.orders_received?.chartLoading
												}
												tooltipText="Export Chart as PNG"
												loading={
													metricsLoading || ordersAnalytics?.orders_received?.chartLoading
												}
												handleClick={() => {
													!metricsLoading &&
														!ordersAnalytics?.orders_received?.chartLoading &&
														(ordersAnalytics?.orders_received?.graphData || []).length !==
															0 &&
														generateImage("orders_received", "Orders Received", "line");
												}}
												borderLeft={true}
											/>
										</div>
									</MetricHeader>
									<MultiChartMetrics>
										<ChartMetric
											size="small"
											loading={metricsLoading}
											label="Avg Orders / Day"
											description="Average number of orders received daily during the selected time frame."
											value={metrics?.order_avg_orders_per_day?.value}
											percentageChange={metrics?.order_avg_orders_per_day?.percentageChange}
											compareValue={metrics?.order_avg_orders_per_day?.compareValue}
										/>
										<ChartMetric
											size="small"
											loading={metricsLoading}
											label="Avg Order Value"
											description="Average transaction value of an order during the selected time frame. It is the net revenue divided by the total orders recieved for the selected time frame."
											value={metrics?.order_avg_order_value?.value}
											percentageChange={metrics?.order_avg_order_value?.percentageChange}
											compareValue={metrics?.order_avg_order_value?.compareValue}
											currencySymbol={currencySymbol}
										/>
										<ChartMetric
											size="small"
											loading={metricsLoading}
											label="Lost Orders"
											description="Total count of orders lost due to cancellations."
											value={metrics?.order_lost_orders?.value}
											percentageChange={metrics?.order_lost_orders?.percentageChange}
											compareValue={metrics?.order_lost_orders?.compareValue}
											invertColors={true}
										/>
									</MultiChartMetrics>
								</React.Fragment>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchOrdersReceivedChart}
							state={ordersAnalytics}
						>
							{({ chartLoading, graphData, yScaleMax }, applDateFilter) => (
								<Line
									height={400}
									data={graphData}
									durationPreset={applDateFilter?.current?.dateFilter}
									loading={chartLoading}
									marginLeft={70}
									showCompareInTooltip={applDateFilter?.compare?.dateFilter}
									// axisBottomLegend="Date"
									axisBottomTickRotation={graphData?.[0]?.data?.length > 7 ? -45 : 0}
									// axisLeftLegend="Orders"
									axisLeftLegendOffset={-60}
									dashedLines={applDateFilter?.compare?.dateFilter}
									dashedLineIds={
										applDateFilter?.compare?.dateFilter
											? [`Orders Received (${getReadableDateFilter(true)})*`]
											: []
									}
									yScaleMax={yScaleMax}
									yScaleStacked={false}
									enableArea={true}
									colors={["#2543B6"]}
									legendItemWidth={applDateFilter?.compare?.dateFilter ? 275 : 120}
									renderTooltipYName={(props) => "Orders Received"}
								/>
							)}
						</MetricSection>
					</React.Fragment>
				)}
			</MetricCard>
			<MetricCard metric="order_performance" cardRef={refs["order_performance"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<React.Fragment>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchOrderPerformanceMetrics}
							state={ordersAnalytics}
						>
							{({ metricsLoading, metrics }) => (
								<React.Fragment>
									<MetricHeader>
										<div className="header-left">
											<ChartMetric
												size="large"
												label="Overall Order Performance"
												description="Visual representation of the order journey, showing stages and possible endpoints."
											/>
										</div>
										{(access?.isOperationsAnalysis ||
											access?.isMarketing ||
											access?.isNonHqAnalytics) && (
											<div className="header-right">
												<ActionButton
													variant="link"
													href={`/analytics/operations${
														query.get("filters") ? `?filters=${query.get("filters")}` : ""
													}`}
													clickHandler={() =>
														trackEvent("analytics_link_text_clicked", {
															module: "Orders",
															type: "Module",
															origin: metric,
															destination: "Operations"
														})
													}
													showArrow={false}
												>
													Go to Operations Analytics
												</ActionButton>
												<DownloadButton
													emptyData={
														((ordersAnalytics?.order_performance?.graphData?.links || [])
															.length == 0 ||
															!ordersAnalytics?.order_performance?.metrics) &&
														!metricsLoading &&
														!ordersAnalytics?.order_performance?.chartLoading
													}
													tooltipText="Export Chart as PNG"
													loading={
														metricsLoading ||
														ordersAnalytics?.order_performance?.chartLoading
													}
													handleClick={() => {
														!metricsLoading &&
															!ordersAnalytics?.order_performance?.chartLoading &&
															(ordersAnalytics?.order_performance?.graphData?.links || [])
																.length !== 0 &&
															generateImage(
																"order_performance",
																"Order Performance",
																"sankey"
															);
													}}
													borderLeft={true}
												/>
											</div>
										)}
									</MetricHeader>
									<MultiChartMetrics>
										<ChartMetric
											size="large"
											loading={metricsLoading}
											label="Completed Orders"
											description="Total count of orders marked as completed during the selected time frame."
											value={metrics?.order_completed_orders?.value}
											percentageChange={metrics?.order_completed_orders?.percentageChange}
											compareValue={metrics?.order_completed_orders?.compareValue}
										/>
										<ChartMetric
											size="large"
											loading={metricsLoading}
											label="Cancelled Pre Ack"
											description="Orders cancelled by the restaurant or platform before acknowledgment."
											value={metrics?.order_lost_orders_can_pre_ack?.value}
											percentageChange={metrics?.order_lost_orders_can_pre_ack?.percentageChange}
											compareValue={metrics?.order_lost_orders_can_pre_ack?.compareValue}
											invertColors={true}
										/>
										<ChartMetric
											size="large"
											loading={metricsLoading}
											label="Cancelled Post Ack"
											description="Orders cancelled by the restaurant or platform after acknowledgment."
											value={metrics?.order_lost_orders_can_post_ack?.value}
											percentageChange={metrics?.order_lost_orders_can_post_ack?.percentageChange}
											compareValue={metrics?.order_lost_orders_can_post_ack?.compareValue}
											invertColors={true}
										/>
										{/* <ChartMetric
											size='large'
											loading={metricsLoading}
											label="Rejected Orders"
											description="Rejected Orders"
											value={metrics?.rejected_orders?.value}
											percentageChange={metrics?.rejected_orders?.percentageChange}
											compareValue={metrics?.rejected_orders?.compareValue}
											invertColors={true}
										/> */}
									</MultiChartMetrics>
								</React.Fragment>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchOrderPerformanceChart}
							state={ordersAnalytics}
						>
							{({ chartLoading, graphData, maxValue }) => (
								<React.Fragment>
									<Sankey
										height={400}
										nodeSpacing={80}
										data={graphData}
										maxValue={maxValue}
										loading={chartLoading}
										colors={(node) => node?.nodeColor}
										showPercentValue={true}
										enableLegends={false}
										labelPositions={[
											{ x: -20, y: 10, align: "left", position: "bottom" },
											{ x: -300, y: 10, align: "right", position: "bottom" },
											{ x: -300, y: 10, align: "right", position: "bottom" },
											{ x: -300, y: 10, align: "right", position: "bottom" },
											{ x: -300, y: 10, align: "right", position: "bottom" },
											{ x: -300, y: 10, align: "right", position: "bottom" }
										]}
									/>
									<div className="chart-time-period">
										Chart Time Period: <span>{getReadableDateFilter()}</span>
									</div>
								</React.Fragment>
							)}
						</MetricSection>
					</React.Fragment>
				)}
			</MetricCard>
			{((!isMultibrandEnabled && areMultiplePlatformsSelected) ||
				(isMultibrandEnabled && (areMultiplePlatformsSelected || areMultipleBrandsSelected))) && (
				<MetricCard metric="orders_breakdown" cardRef={refs["orders_breakdown"]} setCurrTab={setCurrTab}>
					{(metric, metricCardInViewport) => (
						<React.Fragment>
							<MetricSection
								metric={metric}
								metricCardInViewport={metricCardInViewport}
								metricSectionApi={fetchOrdersBreakdownChart}
								state={ordersAnalytics}
								dependencies={[
									ordersAnalytics[metric]?.selectedChart,
									ordersAnalytics[metric]?.breakdownBy?.value
								]}
							>
								{(
									{ chartLoading, selectedChart = "pie", breakdownBy, graphData, yScaleMax },
									applDateFilter
								) => (
									<React.Fragment>
										<MetricHeader>
											<div className="header-left">
												<ChartMetric
													size="large"
													label="Orders Breakdown"
													description="Detailed order distribution categorized by different platforms or brands."
												/>
											</div>
											<div className="header-right">
												<ChartSelector
													selectedChart={selectedChart}
													options={["pie", "line"]}
													clickHandler={(chart) => {
														updateOrderAnalyticsState(metric, {
															selectedChart: chart
														});
														trackEvent("analytics_chart_type_switched", {
															module: "Orders",
															origin: metric,
															from: selectedChart,
															to: chart
														});
													}}
												/>
												<BreakdownByFilter
													currValue={breakdownBy}
													setFilter={(field, value) => {
														updateOrderAnalyticsState(metric, {
															[field]: value
														});
														trackEvent("analytics_breakdown_selection", {
															module: "Orders",
															metric: metric,
															breakdownType: value.label
														});
													}}
													isMultibrandEnabled={isMultibrandEnabled}
													readOnly={
														!areMultiplePlatformsSelected || !areMultipleBrandsSelected
													}
												/>
												<DownloadButton
													handleClick={(opt) => {
														if (opt == "csv") {
															!analyticsReportState?.orders_breakdown?.loading &&
																!ordersAnalytics?.orders_breakdown?.tableLoading &&
																(
																	ordersAnalytics?.orders_breakdown?.tabularData
																		?.rows || []
																).length !== 0 &&
																fetchAndGenerateCSVReport(
																	fetchOrdersBreakdownTable,
																	"getTabularData",
																	["orders_breakdown", true]
																);
														} else if (opt == "png") {
															!chartLoading &&
																ordersAnalytics?.orders_breakdown?.graphData?.[
																	selectedChart
																]?.length !== 0 &&
																generateImage(
																	"orders_breakdown",
																	"Orders Breakdown",
																	selectedChart
																);
														}
													}}
													field="valueForDisplay"
													value="value"
													tooltipText="Export"
													classes={
														(ordersAnalytics?.orders_breakdown?.tabularData?.rows || [])
															.length !== 0 &&
														!ordersAnalytics?.orders_breakdown?.tableLoading &&
														ordersAnalytics?.orders_breakdown?.graphData?.[selectedChart]
															?.length !== 0 &&
														!chartLoading &&
														"center"
													}
													options={[
														{ value: "png", valueForDisplay: "Export Chart as PNG" },
														{ value: "csv", valueForDisplay: "Export Table as CSV" }
													]}
													emptyData={
														(ordersAnalytics?.orders_breakdown?.tabularData?.rows || [])
															.length == 0 &&
														!ordersAnalytics?.orders_breakdown?.tableLoading &&
														ordersAnalytics?.orders_breakdown?.graphData?.[selectedChart]
															?.length == 0 &&
														!chartLoading
													}
													loading={
														analyticsReportState?.orders_breakdown?.loading ||
														ordersAnalytics?.orders_breakdown?.tableLoading ||
														chartLoading
													}
												/>
											</div>
										</MetricHeader>
										{selectedChart === "pie" && (
											<Pie
												height={400}
												marginTop={40}
												marginBottom={100}
												data={graphData?.pie || []}
												colors={[...ANALYTICS_DEFAULT_COLORS]}
												idKey="name"
												arcLabel="name"
												loading={chartLoading}
												// commifyArcLinkLabel={true}
												enableCenterMetric={true}
												enableLegends={false}
												renderCenterMetricLabel={() => "Orders Received"}
												renderCenterMetricValue={renderCenterMetricValue}
											/>
										)}
										{selectedChart === "line" && (
											<Line
												height={400}
												data={graphData?.line || []}
												durationPreset={applDateFilter?.current?.dateFilter}
												showCompareInTooltip={applDateFilter?.compare?.dateFilter}
												// colors={[...ANALYTICS_DEFAULT_COLORS.slice(0, graphData?.line?.length)]}
												colors={{ datum: "color" }}
												loading={chartLoading}
												enableLegends={false}
												yScaleMax={yScaleMax}
												axisBottomTickRotation={
													graphData?.line?.[0]?.data?.length > 7 ? -45 : 0
												}
												dashedLines={applDateFilter?.compare?.dateFilter}
												dashedLineIndices={applDateFilter?.compare?.dateFilter ? "odd" : ""}
												showDashedLineOnHover={true}
												// axisBottomLegend="Date"
												// axisLeftLegend="Orders"
											/>
										)}
									</React.Fragment>
								)}
							</MetricSection>
							<MetricSection
								metric={metric}
								metricCardInViewport={metricCardInViewport}
								metricSectionApi={fetchOrdersBreakdownTable}
								state={ordersAnalytics}
								dependencies={[
									ordersAnalytics[metric]?.breakdownBy?.value,
									ordersAnalytics[metric]?.sort
								]}
							>
								{({
									tableLoading,
									breakdownBy,
									tabularData,
									legends,
									sortedField,
									sort,
									hideColumns
								}) => (
									<CustomTable
										forAnalytics={true}
										loading={tableLoading}
										data={tabularData.rows || []}
										columns={tabularData?.columns || []}
										legends={legends}
										sortList={(field) =>
											updateOrderAnalyticsState(metric, {
												sort: {
													field: field,
													order: getSortOrder(sort, { field })
												},
												sortedField: field
											})
										}
										sortedField={sortedField}
										sortedOrder={sort?.order}
										classes="orders-breakdown-table-container"
										content={`${breakdownBy?.label}s`}
										currencySymbol={currencySymbol}
										viewAction="View Revenue Breakdown"
										viewActionVariant="link"
										viewActionHref={`/analytics/revenue?metric=revenue_breakdown${
											query.get("filters") ? `&filters=${query.get("filters")}` : ""
										}`}
										handleViewAction={() =>
											trackEvent("analytics_link_text_clicked", {
												module: "Orders",
												type: "Module",
												origin: metric,
												destination: "revenue_breakdown"
											})
										}
										hideColumns={hideColumns || []}
									/>
								)}
							</MetricSection>
						</React.Fragment>
					)}
				</MetricCard>
			)}
			<MetricCard metric="orders_by_location" cardRef={refs["orders_by_location"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<MetricSection
						metric={metric}
						metricCardInViewport={metricCardInViewport}
						metricSectionApi={fetchOrdersByLocation}
						state={ordersAnalytics}
						dependencies={[ordersAnalytics[metric]?.sort]}
					>
						{({ loading, tabularData, tableColumnsSelected, sort, sortedField }) => (
							<React.Fragment>
								<MetricHeader>
									<div className="header-left">
										<ChartMetric
											size="large"
											label="Orders By Location"
											description="Overview of order distribution across different store locations."
										/>
									</div>
									<div className="header-right">
										<DownloadButton
											handleClick={() =>
												!analyticsReportState?.orders_by_location?.loading &&
												!ordersAnalytics?.orders_by_location?.loading &&
												(ordersAnalytics?.orders_by_location?.tabularData?.rows || [])
													.length !== 0 &&
												fetchAndGenerateCSVReport(fetchOrdersByLocation, "getTabularData", [
													"orders_by_location",
													{},
													true
												])
											}
											emptyData={
												(ordersAnalytics?.orders_by_location?.tabularData?.rows || []).length ==
													0 && !ordersAnalytics?.orders_by_location?.loading
											}
											classes={
												(ordersAnalytics?.orders_by_location?.tabularData?.rows || [])
													.length !== 0
													? "center"
													: ""
											}
											loading={
												analyticsReportState?.orders_by_location?.loading ||
												ordersAnalytics?.orders_by_location?.loading
											}
										/>
									</div>
								</MetricHeader>
								<CustomTable
									forAnalytics={true}
									loading={loading}
									data={tabularData?.rows || []}
									columns={
										tabularData?.columns?.filter(
											(col) => tableColumnsSelected?.columns?.[col.field]
										) || []
									}
									lastColumn={
										tableColumnsSelected?.columns
											? Object.keys(tableColumnsSelected?.columns)
													?.filter((key) => tableColumnsSelected?.columns?.[key])
													?.slice(-1)?.[0]
											: undefined
									}
									sortList={(field) =>
										updateOrderAnalyticsState(metric, {
											sort: {
												field: field,
												order: getSortOrder(sort, { field })
											},
											sortedField: field
										})
									}
									sortedField={sortedField}
									sortedOrder={sort?.order}
									classes="orders-by-location-table-container"
									content="Locations"
									currencySymbol={currencySymbol}
									hasLimitedRows={true}
									limitRows={5}
									isRowClickable={tabularData?.isRowClickable}
									viewAction="View All"
									viewActionVariant="link"
									viewActionHref={`/analytics/orders/list/location-performance/orders-by-location${`?filters=${query.get(
										"filters"
									)}`}${`&bc=${breadcrumbs.encode([
										{
											title: "Orders Analytics",
											to: "/analytics/orders?metric=orders_by_location"
										}
									])}`}`}
									handleViewAction={() =>
										trackEvent("analytics_link_text_clicked", {
											module: "Orders",
											type: "View All",
											origin: metric
										})
									}
								/>
							</React.Fragment>
						)}
					</MetricSection>
				)}
			</MetricCard>
			<MetricCard metric="order_frequency" cardRef={refs["order_frequency"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<MetricSection
						metric={metric}
						metricCardInViewport={metricCardInViewport}
						metricSectionApi={fetchOrderFrequency}
						state={ordersAnalytics}
						dependencies={[
							ordersAnalytics[metric]?.compare?.value,
							ordersAnalytics[metric]?.showComparison
						]}
					>
						{(
							{
								loading,
								graphData,
								maxValue,
								compare,
								applCompare,
								compareOptions = [],
								compareFilterDisabled = false,
								showComparison = false,
								applShowComparison = false
							},
							applDateFilter
						) => (
							<React.Fragment>
								<MetricHeader>
									<div className="header-left">
										<ChartMetric
											size="large"
											label="Order Frequency"
											description="Order volume frequency density across various times and days."
										/>
									</div>
								</MetricHeader>
								<div className="heatmap-filters">
									<CompareFilter
										currValue={compare}
										options={compareOptions}
										setFilter={(field, value) =>
											updateOrderAnalyticsState(metric, {
												[field]: value
											})
										}
										module="Orders"
										metric={metric}
										readOnly={compareFilterDisabled}
									/>
									<ShowComparison
										title="Average & Compare"
										loading={loading}
										isChecked={showComparison}
										clickHandler={() =>
											updateOrderAnalyticsState(metric, { showComparison: !showComparison })
										}
										module="Orders"
										metric={metric}
										readOnly={!applDateFilter?.compare?.dateFilter}
									/>
								</div>
								<Heatmap
									responsive={!["Hours", "Dates", "Weeks"].includes(applCompare?.label)}
									enableCustomLegends={["Hours", "Dates", "Weeks"].includes(applCompare?.label)}
									canvas={true}
									width={
										(applShowComparison && graphData.length === 2) || applCompare?.label === "Dates"
											? 3000
											: 2500
									}
									height={graphData?.length < 4 ? 350 : (graphData?.length || 3) * 28 + 160}
									loading={loading}
									data={graphData}
									minValue={maxValue === 0 ? 0 : undefined}
									maxValue={maxValue === 0 ? 4 : undefined}
									marginLeft={
										!applShowComparison && applCompare?.label === "Days of the Week"
											? 200
											: applShowComparison &&
												  applDateFilter.current.dateTypeSelected.value === "range"
												? 150
												: applShowComparison &&
													  applDateFilter.current.dateFilter === "YESTERDAY"
													? 125
													: 100
									}
									marginRight={!["Hours", "Dates", "Weeks"].includes(applCompare?.label) ? 10 : 40}
									colors="cool"
									yInnerPadding={applShowComparison && graphData.length === 2 ? 0.25 : 0}
									enableLabels={false}
									scrollOffset={
										applCompare?.label === "Hours" ? (applShowComparison ? 1000 : 800) : 0
									}
								/>
							</React.Fragment>
						)}
					</MetricSection>
				)}
			</MetricCard>
			<MetricCard metric="orders_by_item" cardRef={refs["orders_by_item"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<MetricSection
						metric={metric}
						metricCardInViewport={metricCardInViewport}
						metricSectionApi={fetchOrdersByItem}
						state={ordersAnalytics}
						dependencies={[ordersAnalytics[metric]?.sort]}
					>
						{({ loading, tabularData, tableColumnsSelected, sort, sortedField }) => (
							<React.Fragment>
								<MetricHeader>
									<div className="header-left">
										<ChartMetric
											size="large"
											label="Orders By Item"
											description="Overview of orders distribution based on individual menu items."
										/>
									</div>
									<div className="header-right">
										<DownloadButton
											handleClick={() =>
												!analyticsReportState?.orders_by_item?.loading &&
												!ordersAnalytics?.orders_by_item?.loading &&
												(ordersAnalytics?.orders_by_item?.tabularData?.rows || []).length !==
													0 &&
												fetchAndGenerateCSVReport(fetchOrdersByItem, "getTabularData", [
													"orders_by_item",
													{},
													true
												])
											}
											emptyData={
												(ordersAnalytics?.orders_by_item?.tabularData?.rows || []).length ==
													0 && !ordersAnalytics?.orders_by_item?.loading
											}
											classes={
												(ordersAnalytics?.orders_by_item?.tabularData?.rows || []).length !== 0
													? "center"
													: ""
											}
											loading={
												analyticsReportState?.orders_by_item?.loading ||
												ordersAnalytics?.orders_by_item?.loading
											}
										/>
									</div>
								</MetricHeader>
								<CustomTable
									forAnalytics={true}
									loading={loading}
									data={tabularData?.rows || []}
									columns={
										tabularData?.columns?.filter(
											(col) => tableColumnsSelected?.columns?.[col.field]
										) || []
									}
									lastColumn={
										tableColumnsSelected?.columns
											? Object.keys(tableColumnsSelected?.columns)
													?.filter((key) => tableColumnsSelected?.columns?.[key])
													?.slice(-1)?.[0]
											: undefined
									}
									sortList={(field) =>
										updateOrderAnalyticsState(metric, {
											sort: {
												field: field,
												order: getSortOrder(sort, { field })
											},
											sortedField: field
										})
									}
									sortedField={sortedField}
									sortedOrder={sort?.order}
									classes="orders-by-item-table-container"
									content="Items"
									currencySymbol={currencySymbol}
									hasLimitedRows={true}
									limitRows={5}
									isRowClickable={tabularData?.isRowClickable}
									rowClickHandler={(rowData) =>
										handleTableRowClick(rowData, metric, tabularData?.rowLink?.entity)
									}
									viewAction="View All"
									viewActionVariant="link"
									viewActionHref={`/analytics/orders/list/item-performance/orders-by-item${`?filters=${query.get(
										"filters"
									)}`}${`&bc=${breadcrumbs.encode([
										{
											title: "Orders Analytics",
											to: `/analytics/orders?metric=${metric}`
										}
									])}`}`}
									handleViewAction={() =>
										trackEvent("analytics_link_text_clicked", {
											module: "Orders",
											type: "View All",
											origin: metric
										})
									}
								/>
							</React.Fragment>
						)}
					</MetricSection>
				)}
			</MetricCard>
			<MetricCard metric="lost_orders" cardRef={refs["lost_orders"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<React.Fragment>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchLostOrdersMetrics}
							state={ordersAnalytics}
						>
							{(
								{
									metricsLoading,
									chartLoading,
									metrics,
									selectedChart = "bar",
									showComparison = false
								},
								applDateFilter
							) => (
								<React.Fragment>
									<MetricHeader>
										<div className="header-left">
											<ChartMetric
												size="large"
												loading={metricsLoading}
												label="Lost Orders"
												description="Cancelled orders categorisation segmented by restaurant and platform."
												value={metrics?.order_lost_orders?.value}
												percentageChange={metrics?.order_lost_orders?.percentageChange}
												compareValue={metrics?.order_lost_orders?.compareValue}
												invertColors={true}
											/>
										</div>
										<div className="header-right">
											<ChartSelector
												selectedChart={selectedChart}
												options={["bar", "line"]}
												clickHandler={(chart) => {
													updateOrderAnalyticsState(metric, {
														selectedChart: chart
													});
													trackEvent("analytics_chart_type_switched", {
														module: "Orders",
														origin: metric,
														from: selectedChart,
														to: chart
													});
												}}
											/>
											<DownloadButton
												handleClick={(opt) => {
													if (opt == "csv") {
														!analyticsReportState?.lost_orders?.loading &&
															!ordersAnalytics?.lost_orders?.tableLoading &&
															(ordersAnalytics?.lost_orders?.tabularData?.rows || [])
																.length !== 0 &&
															fetchAndGenerateCSVReport(
																fetchLostOrdersTable,
																"getTabularData",
																["lost_orders", true]
															);
													} else if (opt == "png") {
														!chartLoading &&
															generateImage("lost_orders", "Lost Orders", selectedChart);
													}
												}}
												options={[
													{ value: "png", valueForDisplay: "Export Chart as PNG" },
													{ value: "csv", valueForDisplay: "Export Table as CSV" }
												]}
												emptyData={
													(ordersAnalytics?.lost_orders?.tabularData?.rows || []).length ==
														0 &&
													!ordersAnalytics?.lost_orders?.tableLoading &&
													ordersAnalytics?.lost_orders?.graphData?.[selectedChart]?.length ==
														0 &&
													!chartLoading
												}
												classes={
													(ordersAnalytics?.lost_orders?.tabularData?.rows || []).length !==
														0 &&
													!ordersAnalytics?.lost_orders?.tableLoading &&
													ordersAnalytics?.lost_orders?.graphData?.[selectedChart]?.length !==
														0 &&
													!chartLoading
														? "center"
														: ""
												}
												loading={
													analyticsReportState?.lost_orders?.loading ||
													ordersAnalytics?.lost_orders?.tableLoading ||
													chartLoading
												}
												tooltipText="Export"
											/>
										</div>
									</MetricHeader>
									<ShowComparison
										loading={chartLoading}
										isChecked={showComparison}
										clickHandler={() =>
											updateOrderAnalyticsState(metric, { showComparison: !showComparison })
										}
										module="Orders"
										metric={metric}
										readOnly={!applDateFilter?.compare?.dateFilter}
									/>
								</React.Fragment>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchLostOrdersChart}
							state={ordersAnalytics}
							dependencies={[
								ordersAnalytics[metric]?.selectedChart,
								ordersAnalytics[metric]?.showComparison
							]}
						>
							{(
								{
									chartLoading,
									selectedChart = "bar",
									showComparison = false,
									graphData,
									yScaleMax,
									maxValue
								},
								applDateFilter
							) => (
								<div className="chart-container">
									{selectedChart === "bar" && (
										<Bar
											height={400}
											data={graphData?.bar}
											loading={chartLoading}
											colors={
												showComparison && applDateFilter?.compare?.dateFilter
													? ["#2543B6", "#2543B6", "#88D9F8", "#88D9F8", "#9048C8", "#9048C8"]
													: ["#2543B6", "#88D9F8", "#9048C8"]
											}
											keys={
												showComparison && applDateFilter?.compare?.dateFilter
													? [
															"Cancelled Pre Acknowledgement",
															"Cancelled Pre Acknowledgement*",
															"Cancelled Post Acknowledgement",
															"Cancelled Post Acknowledgement*"
														]
													: [
															"Cancelled Pre Acknowledgement",
															"Cancelled Post Acknowledgement"
														]
											}
											patternIds={[
												{
													match: { id: "Cancelled Pre Acknowledgement*" },
													id: "lines"
												},
												{
													match: { id: "Cancelled Post Acknowledgement*" },
													id: "lines"
												}
											]}
											maxValue={maxValue}
											enableLegends={!showComparison || !applDateFilter?.compare?.dateFilter}
											customLegends={showComparison && applDateFilter?.compare?.dateFilter}
											applDateFilter={applDateFilter}
											groupMode="grouped"
											indexBy="label"
											padding={showComparison ? 0.5 : 0.75}
											innerPadding={20}
											// axisLeftLegend="Orders"
											legendItemWidth={230}
										/>
									)}
									{selectedChart === "line" && (
										<Line
											height={400}
											data={graphData?.line}
											durationPreset={applDateFilter?.current?.dateFilter}
											showCompareInTooltip={showComparison && applDateFilter?.compare?.dateFilter}
											loading={chartLoading}
											marginLeft={70}
											// axisBottomLegend="Date"
											// axisLeftLegend="Orders"
											axisLeftLegendOffset={-60}
											yScaleMax={yScaleMax}
											yScaleStacked={false}
											axisBottomTickRotation={graphData?.line?.[0]?.data?.length > 7 ? -45 : 0}
											dashedLines={showComparison && applDateFilter?.compare?.dateFilter}
											dashedLineIds={
												applDateFilter?.compare?.dateFilter
													? [`Lost Orders (${getReadableDateFilter(true)})*`]
													: []
											}
											enableArea={true}
											colors={["#2543B6"]}
											legendItemWidth={
												showComparison && applDateFilter?.compare?.dateFilter ? 250 : 100
											}
											renderTooltipYName={(props) => "Lost Orders"}
										/>
									)}
								</div>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchLostOrdersTable}
							state={ordersAnalytics}
							dependencies={[ordersAnalytics[metric]?.sort]}
						>
							{({ tableLoading, tabularData, sortedField, sort }) => (
								<CustomTable
									forAnalytics={true}
									loading={tableLoading}
									data={tabularData?.rows || []}
									columns={tabularData?.columns || []}
									sortList={(field) =>
										updateOrderAnalyticsState(metric, {
											sort: {
												field: field,
												order: getSortOrder(sort, { field })
											},
											sortedField: field
										})
									}
									sortedField={sortedField}
									sortedOrder={sort?.order}
									classes="lost-orders-table-container"
									content="Data"
									currencySymbol={currencySymbol}
								/>
							)}
						</MetricSection>
					</React.Fragment>
				)}
			</MetricCard>
			{((!isMultibrandEnabled && areMultiplePlatformsSelected) ||
				(isMultibrandEnabled && (areMultiplePlatformsSelected || areMultipleBrandsSelected))) && (
				<MetricCard
					metric="lost_orders_breakdown"
					cardRef={refs["lost_orders_breakdown"]}
					setCurrTab={setCurrTab}
				>
					{(metric, metricCardInViewport) => (
						<React.Fragment>
							<MetricSection
								metric={metric}
								metricCardInViewport={metricCardInViewport}
								metricSectionApi={fetchLostOrdersBreakdownChart}
								state={ordersAnalytics}
								dependencies={[
									ordersAnalytics[metric]?.selectedChart,
									ordersAnalytics[metric]?.breakdownBy?.value,
									ordersAnalytics[metric]?.showComparison
								]}
							>
								{(
									{
										chartLoading,
										selectedChart = "bar",
										showComparison = false,
										breakdownBy,
										graphData,
										yScaleMax,
										maxValue
									},
									applDateFilter
								) => (
									<React.Fragment>
										<MetricHeader>
											<div className="header-left">
												<ChartMetric
													size="large"
													label="Lost Orders Breakdown"
													description="Lost orders categorisation based on reasons for order cancellations, segmented by acknowledgment status and source."
												/>
											</div>
											<div className="header-right">
												<ChartSelector
													selectedChart={selectedChart}
													options={["bar", "line"]}
													clickHandler={(chart) => {
														updateOrderAnalyticsState(metric, {
															selectedChart: chart
														});
														trackEvent("analytics_chart_type_switched", {
															module: "Orders",
															origin: metric,
															from: selectedChart,
															to: chart
														});
													}}
												/>
												<BreakdownByFilter
													currValue={breakdownBy}
													setFilter={(field, value) => {
														updateOrderAnalyticsState(metric, {
															[field]: value
														});
														trackEvent("analytics_breakdown_selection", {
															module: "Orders",
															metric: metric,
															breakdownType: value.label
														});
													}}
													isMultibrandEnabled={isMultibrandEnabled}
													readOnly={
														!areMultiplePlatformsSelected || !areMultipleBrandsSelected
													}
												/>
												<DownloadButton
													handleClick={(opt) => {
														if (opt == "csv") {
															!analyticsReportState?.lost_orders_breakdown?.loading &&
																!ordersAnalytics?.lost_orders_breakdown?.tableLoading &&
																(
																	ordersAnalytics?.lost_orders_breakdown?.tabularData
																		?.rows || []
																).length !== 0 &&
																fetchAndGenerateCSVReport(
																	fetchLostOrdersBreakdownTable,
																	"getTabularData",
																	["lost_orders_breakdown", true]
																);
														} else if (opt == "png") {
															!chartLoading &&
																generateImage(
																	"lost_orders_breakdown",
																	"Lost Orders Breakdown",
																	selectedChart
																);
														}
													}}
													options={[
														{ value: "png", valueForDisplay: "Export Chart as PNG" },
														{ value: "csv", valueForDisplay: "Export Table as CSV" }
													]}
													emptyData={
														(
															ordersAnalytics?.lost_orders_breakdown?.tabularData?.rows ||
															[]
														).length == 0 &&
														!ordersAnalytics?.lost_orders_breakdown?.tableLoading &&
														graphData?.[selectedChart].length == 0 &&
														!chartLoading
													}
													classes={
														(
															ordersAnalytics?.lost_orders_breakdown?.tabularData?.rows ||
															[]
														).length !== 0 &&
														graphData?.[selectedChart].length !== 0 &&
														"center"
													}
													loading={
														analyticsReportState?.lost_orders_breakdown?.loading ||
														ordersAnalytics?.lost_orders_breakdown?.tableLoading ||
														chartLoading
													}
													tooltipText="Export"
												/>
											</div>
										</MetricHeader>
										<ShowComparison
											loading={chartLoading}
											isChecked={showComparison}
											clickHandler={() =>
												updateOrderAnalyticsState(metric, { showComparison: !showComparison })
											}
											module="Orders"
											metric={metric}
											readOnly={!applDateFilter?.compare?.dateFilter}
										/>
										<div className="chart-container">
											{selectedChart === "bar" && (
												<Bar
													height={400}
													data={graphData?.bar}
													marginBottom={breakdownBy.value === "platform" ? 130 : 150}
													loading={chartLoading}
													colors={[...ANALYTICS_DEFAULT_COLORS]}
													keys={
														showComparison && applDateFilter?.compare?.dateFilter
															? ["current", "previous"]
															: [
																	"Merchant (Pre-Ack)",
																	"Merchant (Post-Ack)",
																	"Platform (Pre-Ack)",
																	"Platform (Post-Ack)"
																]
													}
													patternIds={[
														{
															match: {
																id: "previous"
															},
															id: "lines"
														}
													]}
													maxValue={maxValue}
													groupMode={
														showComparison && applDateFilter?.compare?.dateFilter
															? "grouped-stacked"
															: "stacked"
													}
													enableLegends={
														!showComparison || !applDateFilter?.compare?.dateFilter
													}
													customLegends={
														showComparison && applDateFilter?.compare?.dateFilter
													}
													padding={
														showComparison && applDateFilter?.compare?.dateFilter
															? 0.6
															: 0.8
													}
													innerPadding={20}
													applDateFilter={applDateFilter}
													indexBy="label"
													// axisBottomLegend={`${breakdownBy?.label}s`}
													axisBottomTickRotation={graphData?.bar?.length > 7 ? -35 : 0}
													// axisLeftLegend="Orders"
												/>
											)}
											{selectedChart === "line" && (
												<Line
													height={400}
													data={graphData?.line || []}
													durationPreset={applDateFilter?.current?.dateFilter}
													showCompareInTooltip={
														showComparison && applDateFilter?.compare?.dateFilter
													}
													// colors={[...ANALYTICS_DEFAULT_COLORS.slice(0, graphData?.line?.length)]}
													colors={{ datum: "color" }}
													loading={chartLoading}
													yScaleMax={yScaleMax}
													dashedLines={showComparison && applDateFilter?.compare?.dateFilter}
													dashedLineIndices={applDateFilter?.compare?.dateFilter ? "odd" : ""}
													showDashedLineOnHover={true}
													enableLegends={
														!showComparison || !applDateFilter?.compare?.dateFilter
													}
													axisBottomTickRotation={
														graphData?.line?.[0]?.data?.length > 7 ? -45 : 0
													}
													// axisBottomLegend="Date"
													// axisLeftLegend="Orders"
													legendItemWidth={breakdownBy.value === "platform" ? 100 : 130}
												/>
											)}
										</div>
									</React.Fragment>
								)}
							</MetricSection>
							<MetricSection
								metric={metric}
								metricCardInViewport={metricCardInViewport}
								metricSectionApi={fetchLostOrdersBreakdownTable}
								state={ordersAnalytics}
								dependencies={[
									ordersAnalytics[metric]?.breakdownBy?.value,
									ordersAnalytics[metric]?.sort
								]}
							>
								{({ tableLoading, breakdownBy, tabularData, sortedField, sort, hideColumns }) => (
									<CustomTable
										forAnalytics={true}
										loading={tableLoading}
										data={tabularData?.rows || []}
										columns={tabularData?.columns || []}
										sortList={(field) =>
											updateOrderAnalyticsState(metric, {
												sort: {
													field: field,
													order: getSortOrder(sort, { field })
												},
												sortedField: field
											})
										}
										sortedField={sortedField}
										sortedOrder={sort?.order}
										classes="lost-orders-breakdown-table-container"
										content={`${breakdownBy?.label}s`}
										currencySymbol={currencySymbol}
										viewAction="View Lost Revenue Breakdown"
										viewActionVariant="link"
										viewActionHref={`/analytics/revenue?metric=lost_revenue_breakdown${
											query.get("filters") ? `&filters=${query.get("filters")}` : ""
										}`}
										handleViewAction={() =>
											trackEvent("analytics_link_text_clicked", {
												module: "Orders",
												type: "Module",
												origin: metric,
												destination: "lost_revenue_breakdown"
											})
										}
										hideColumns={hideColumns || []}
									/>
								)}
							</MetricSection>
						</React.Fragment>
					)}
				</MetricCard>
			)}
			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
			/>
		</div>
	);
};
export default connect((store) => ({
	ordersAnalytics: store.ordersAnalytics,
	analyticsFiltersState: store.analyticsFiltersState,
	currencySymbol: store.login.loggedInbizDetail.currencySymbol,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	analyticsReportState: store.analyticsReportState,
	access: store.login.loginDetail.access
}))(Orders);

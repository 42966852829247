// action types
import { ActionTypes } from "../actions/_types";

export const POS_DETAILS_INITIAL_STATE = {
	loading: false,
	posData: {},
	error: {}
};
export const posDetails = (state = POS_DETAILS_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.GET_POS_DETAILS_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.GET_POS_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				posData: action.payload
			};
		case ActionTypes.GET_POS_DETAILS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		case ActionTypes.UPDATE_POS_DETAILS:
			return {
				...state,
				posData: {
					...state.posData,
					...action.payload
				}
			};
		case ActionTypes.EDIT_POS_DETAILS_REQUEST:
			return {
				...state,
				loading: true,
				error: {}
			};
		case ActionTypes.EDIT_POS_DETAILS_SUCCESS:
			return {
				...state,
				loading: false
			};
		case ActionTypes.EDIT_POS_DETAILS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.error
			};
		default:
			return state;
	}
};

// store
import { store } from "../store/configureStore";

// utils
import { formatDate, MONTHS, trackEvent } from "../atlas-utils";
import moment from "moment";
import { toPng } from "html-to-image";
import { DASHBOARD_DURATION_PRESETS } from "../client-config";
import { getAllAppliedFilters } from "../actions/analytics";
import NotificationServices from "../services/NotificationService";
// import { useCallback } from "react";

/**
 * for line charts with compare date filter
 */
export const getReadableDateFilter = (compare = false) => {
	const { appliedDateFilter } = store.getState().analyticsFiltersState;
	const currTimestamp = appliedDateFilter?.current?.dateFilter;
	const compTimestamp = appliedDateFilter?.compare?.dateFilter;

	let readableDateFilter = "";
	const presetMap = {
		TODAY: "Today",
		YESTERDAY: "Yesterday",
		THIS_WEEK: "This Week",
		THIS_MONTH: "This Month",
		THIS_YEAR: "This Year",
		LAST_7_DAYS: "Last 7D",
		LAST_15_DAYS: "Last 15D",
		LAST_30_DAYS: "Last 30D",
		LAST_90_DAYS: "Last 90D"
	};
	const comparePresetMap = {
		TODAY: "Yesterday",
		YESTERDAY: "Day Before Yesterday",
		THIS_WEEK: "Last Week",
		THIS_MONTH: "Last Month",
		THIS_YEAR: "Last Year",
		LAST_7_DAYS: "Prev 7D",
		LAST_15_DAYS: "Prev 15D",
		LAST_30_DAYS: "Prev 30D",
		LAST_90_DAYS: "Prev 90D"
	};

	if (compare) {
		if (comparePresetMap[currTimestamp]) {
			readableDateFilter = comparePresetMap[currTimestamp];
		} else {
			const dates = compTimestamp.split(",");
			readableDateFilter = formatDate(dates[0], "DD MMM, YYYY") + " - " + formatDate(dates[1], "DD MMM, YYYY");
		}
	} else {
		if (presetMap[currTimestamp]) {
			readableDateFilter = presetMap[currTimestamp];
		} else {
			const dates = currTimestamp.split(",");
			readableDateFilter = formatDate(dates[0], "DD MMM, YYYY") + " - " + formatDate(dates[1], "DD MMM, YYYY");
		}
	}
	return readableDateFilter;
};

export const parseValue = (value) => {
	const parsedValue = parseFloat(value);
	return isNaN(parsedValue) ? 0 : parsedValue;
};

export const calculatePercentages = (arrayValues, value = "value", sort = true) => {
	const totalSum = arrayValues.reduce((sum, val) => sum + parseValue(val?.[value]), 0);
	const percentages =
		totalSum === 0
			? arrayValues.map(() => 0)
			: arrayValues.map((val) => roundAndFormatNumber((parseValue(val?.[value]) / totalSum) * 100, 1));

	const finalValues = arrayValues.map((val, index) => {
		return {
			...val,
			percentage: percentages[index],
			value: Math.round(val?.[value])
		};
	});
	if (sort) {
		finalValues.sort((a, b) => (a[value] || 0) - (b[value] || 0));
	}
	return finalValues;
};

export const roundAndFormatNumber = (number, decimalPlaces = 2) => {
	if (isNaN(number)) return "0";
	let roundedNumber = number.toFixed(decimalPlaces);
	let formattedNumber = roundedNumber.replace(/(\.\d*[1-9])0+$|\.0*$/, "$1");
	return formattedNumber;
};

export const updatedFilters = (filters, updates) => {
	return updates.reduce(
		(acc, answer) => {
			const { field, value } = answer;
			if (field === "location_id") {
				acc.appliedFilters.location_id = value.split(",");
			} else if (field === "brand_id") {
				acc.appliedFilters.brand_id = value.split(",");
			} else if (field === "platform_names") {
				acc.appliedFilters.platform_names = value.split(",");
			} else if (field === "location_group_id") {
				acc.appliedFilters.location_group_id = value.split(",");
			}
			return acc;
		},
		{ ...filters }
	);
};
export const updateDurationFilter = (filters, update) => {
	if (update.custom) {
		const custom = update.custom;
		let datefilter = custom.startDate + "," + custom.endDate;
		filters.appliedDateFilter.current.dateFilter = datefilter;
		filters.appliedDateFilter.current.dateTypeSelected = {
			label: "Custom",
			value: "range"
		};
		filters.appliedDateFilter.current.presetTypeSelected = {
			label: "",
			value: "",
			title: "",
			meta_info: ""
		};
	}
	if (update?.preset) {
		filters.appliedDateFilter.current = dateKeys?.[update.preset];
	}
	return filters;
};

const createObjectDateKey = (timeFrame, label, title, metaInfo) => ({
	dateFilter: timeFrame,
	dateTypeSelected: {
		label: "Preset",
		value: "preset_duration"
	},
	presetTypeSelected: {
		label: `This ${label}`,
		value: timeFrame,
		title: title,
		meta_info: metaInfo
	}
});

const constructDateResponse = (text) => ({
	dateFilter: text.toUpperCase(),
	dateTypeSelected: {
		label: "Preset",
		value: "preset_duration"
	},
	presetTypeSelected: {
		label: text.charAt(0).toUpperCase() + text.slice(1),
		value: text.toUpperCase(),
		title: text.charAt(0).toUpperCase() + text.slice(1)
	}
});

const createDateKey = (days, title) => ({
	dateFilter: `LAST_${days}_DAYS`,
	dateTypeSelected: {
		label: "Preset",
		value: "preset_duration"
	},
	presetTypeSelected: {
		label: `${days} D`,
		value: `LAST_${days}_DAYS`,
		title: title,
		meta_info: `${days} days since`
	}
});

export const dateKeys = {
	LAST_90_DAYS: createDateKey(90, "Last 90 days"),
	LAST_30_DAYS: createDateKey(30, "Last 30 days"),
	LAST_15_DAYS: createDateKey(15, "Last 15 days"),
	LAST_7_DAYS: createDateKey(7, "Last 7 Days"),
	THIS_YEAR: createObjectDateKey("THIS_YEAR", "year", "This year", "A year since"),
	THIS_MONTH: createObjectDateKey("THIS_MONTH", "month", "This month", "A month since"),
	THIS_WEEK: createObjectDateKey("THIS_WEEK", "week", "This week", "A week since"),
	YESTERDAY: constructDateResponse("yesterday"),
	TODAY: constructDateResponse("today")
};

export const dateFormat = (date, format = "DD MMM, YYYY - hh:mm A") => {
	const localDate = moment(date).isValid() ? moment.utc(date).local() : null;
	return localDate ? localDate.format(format) : "";
};

export const roundNumber = (num) => {
	if (typeof num === "number") {
		return (Math.round(num * 100) / 100).toFixed(2);
	}
	return num;
};

export const removeKeys = (arr, keysToRemove = []) => {
	if (typeof keysToRemove === "string") {
		return arr.map((obj) => {
			const newObj = { ...obj };
			for (let key in newObj) {
				newObj[key] = roundNumber(newObj[key]);
			}
			delete newObj?.[keysToRemove];
			return newObj;
		});
	}
	if (Array.isArray(keysToRemove)) {
		return arr.map((obj) => {
			const newObj = { ...obj };
			for (let key in newObj) {
				newObj[key] = roundNumber(newObj[key]);
			}
			keysToRemove.forEach((key) => delete newObj?.[key]);
			return newObj;
		});
	}
	return arr;
};

export const callWorkerFunction = async (functionName, args) => {
	return new Promise((resolve, reject) => {
		const worker = new Worker("/workers/analytics.js");
		worker.postMessage({ functionName, args });
		worker.onmessage = (e) => {
			if (e.data.status === "Error") {
				reject(e.data.message);
			} else {
				resolve(e.data.cleanedData);
			}
			worker.terminate();
		};
		worker.onerror = (error) => {
			console.log(error);
			reject(error);
			worker.terminate();
		};
	});
};

export const downloadCSV = (csvData, fileName = "data.csv") => {
	const blob = new Blob(["\uFEFF" + csvData], { type: "text/csv;charset=utf-8;" });
	const url = URL.createObjectURL(blob);
	const a = document.createElement("a");
	a.href = url;
	a.download = fileName;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};

export const captureScreenshot = (ref, title, timeDuration) => {
	const trimTopAndBottomSpace = new Set([
		"Revenue Summary",
		"Average Order Value",
		"Orders Received",
		"Order Performance",
		"Order Completion Time"
	]);
	return new Promise((resolve, reject) => {
		if (ref.current === null) {
			console.error("ref.current is null");
			reject(new Error("ref.current is null"));
			return;
		}
		const durationObject = getAllAppliedFilters()?.durationObject?.duration;
		const currentTimeFilter =
			durationObject?.preset ||
			(durationObject?.custom?.startDate || "").replaceAll("-", "/") +
				"-" +
				(durationObject?.custom?.endDate || "").replaceAll("-", "/");

		const headerRight = ref.current?.querySelector(".header-right");
		const infoIcon = ref.current?.querySelector(".icon");
		const toolTip = ref.current?.querySelector(".popover-container");

		if (title == "Order Completion Time") {
			const comparePercent = ref.current?.querySelectorAll(".compare-percent");
			const popoverWrapperContent = ref.current?.querySelectorAll(".popover-wrapper--content");
			const comparePercentTime = ref.current?.querySelectorAll(".compare-percent .time");
			if (comparePercentTime) {
				comparePercentTime.forEach((element) => {
					element.style.marginRight = "10px";
				});
			}
			// .compare-percent .time
			if (comparePercent) {
				comparePercent.forEach((element) => {
					element.style.display = "flex";
					element.style.alignItems = "center";
					element.style.fontSize = "14px";
					element.style.padding = "32px 0";
					element.style.boxSizing = "border-box";
				});
			}
			if (popoverWrapperContent) {
				popoverWrapperContent.forEach((element) => {
					element.style.display = "flex";
					element.style.alignItems = "center";
				});
			}
		}

		// Temporarily hide elements
		if (headerRight) headerRight.style.opacity = "0";
		if (infoIcon) infoIcon.style.opacity = "0";
		if (toolTip) {
			// toolTip.style.opacity = "0";
		}

		const width = ref.current.offsetWidth + 50;
		const height = ref.current.offsetHeight;

		// Capture the screenshot using toPng
		toPng(ref.current, {
			cacheBust: true,
			backgroundColor: "white",
			useCORS: true,
			width: width,
			height: height
		})
			.then((dataUrl) => {
				const img = new Image();
				img.src = dataUrl;

				img.onload = () => {
					const canvas = document.createElement("canvas");
					const ctx = canvas.getContext("2d");

					// Set canvas dimensions based on image
					canvas.width = img.width + 350;
					canvas.height = img.height + 400;

					let startPoint = 280;

					// Draw background layer
					ctx.fillStyle = "#EAEAEA";
					ctx.fillRect(0, 0, canvas.width, canvas.height);

					// Draw white border
					ctx.fillStyle = "white"; // Border color
					ctx.lineWidth = 4; // Border thickness
					ctx.fillRect(width * 0.05, height * 0.1, canvas.width - width * 0.1, canvas.height - height * 0.2);

					// Draw the separator line
					ctx.beginPath();
					ctx.moveTo(width * 0.05, startPoint); // Start at left edge
					ctx.lineTo(canvas.width - width * 0.05, startPoint); // End at right edge
					ctx.strokeStyle = "#D0D0D0";
					ctx.lineWidth = 1;
					ctx.stroke();

					// Draw border around the image content
					ctx.strokeStyle = "#D0D0D0";
					ctx.lineWidth = 1;
					ctx.strokeRect(
						width * 0.05,
						height * 0.1,
						canvas.width - width * 0.1,
						canvas.height - height * 0.2
					);

					ctx.drawImage(
						img,
						trimTopAndBottomSpace.has(title) ? img.width * 0.025 : 0,
						trimTopAndBottomSpace.has(title) ? img.height * 0.005 : 0,
						img.width,
						trimTopAndBottomSpace.has(title) ? img.height - img.height * 0.01 : img.height,
						(canvas.width - img.width) / 2 + 95,
						315,
						img.width,
						img.height
					);

					// Add title text
					ctx.font = "bold 55px Source Sans Pro";
					ctx.fillStyle = "black";
					ctx.textAlign = "left";
					ctx.fillText(title, width * 0.15, 160);

					// Add time duration text
					ctx.font = "35px Source Sans Pro";
					ctx.fillStyle = "#8A8A8A";
					ctx.textAlign = "left";
					ctx.fillText(timeDuration, width * 0.15, 230);

					// Load and draw the logo
					const logo = new Image();
					logo.src = "/assets/up-logo.svg";
					logo.onload = () => {
						ctx.drawImage(logo, canvas.width - width * 0.15 - 300, 110, 300, 66.67);

						// Generate final image with overlay and download it
						const finalDataUrl = canvas.toDataURL("image/png");

						// Create a download link
						const link = document.createElement("a");
						link.download = `Urbanpiper_Export_${title.split(" ").join("_")}_${currentTimeFilter}.png`;
						link.href = finalDataUrl;
						link.click();
						NotificationServices.pushNotification({
							message: `${title} PNG Download Successful`,
							timeout: 3000,
							type: "success",
							isClosable: true,
							theme: "dark"
						});

						// Resolve the promise
						resolve(finalDataUrl);
					};

					logo.onerror = (err) => {
						console.error("Error loading logo:", err);
						reject(new Error("Failed to load logo"));
					};
				};

				img.onerror = (err) => {
					console.error("Error loading image:", err);
					reject(new Error("Failed to load captured image"));
				};
			})
			.catch((err) => {
				console.error("Error generating screenshot:", err);
				reject(err);
			})
			.finally(() => {
				// Restore the hidden elements
				if (headerRight) headerRight.style.opacity = "1";
				if (infoIcon) infoIcon.style.opacity = "1";
				if (toolTip) toolTip.style.opacity = "1";
			});
	});
};

export function getDurationPreset(value) {
	return DASHBOARD_DURATION_PRESETS.find((preset) => preset.value === value) || null;
}

export function generateDurationText(startDate, endDate) {
	let result = "";
	const startDateArray = (startDate || "").split("-");
	if (startDateArray.length === 0) {
		return startDate;
	}
	result = `${startDateArray[2]} ${MONTHS[Number(startDateArray[1]) - 1]} ${startDateArray[0]}`;
	const endDateArray = (endDate || "").split("-");
	if (!endDate || endDateArray.length === 0) {
		return result;
	}
	return result + ` to ${endDateArray[2]} ${MONTHS[Number(endDateArray[1]) - 1]} ${endDateArray[0]}`;
}

export const trackPNGDownload = (key, subChart) => {
	const updatedCurrentDateFilter = {
		...store.getState().analyticsFiltersState.appliedDateFilter
	};
	const durationObject = getAllAppliedFilters()?.durationObject?.duration;
	trackEvent("analytics_sub_module_exported", {
		sub_module_name: key,
		export_format: "png",
		chart_type: subChart + "_chart",
		date_range: (durationObject?.preset
			? durationObject.preset
			: `last_${updatedCurrentDateFilter?.current?.rangeEndDate.diff(
					updatedCurrentDateFilter?.current?.rangeStartDate,
					"d"
				)}_days` || ""
		).toLowerCase()
	});
};

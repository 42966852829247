import React, { useState, useEffect, useRef, useCallback } from "react";

// components
import { FormContainer } from "./Meraki";
import { Topbar } from "../components/_commons/Topbar";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { OutsideClick } from "../components/_commons/OutsideClick";
import Automation from "../components/Hub/Automation";
import PlatformConfigs from "../components/Hub/PlatformConfigs";
import Integrations from "../components/Hub/Integrations";
import DSPIntegration from "./DSPIntegration";
import MenuEdit from "./MenuEdit";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";
import history from "../history";
import { Route } from "react-router-dom";

// utils
import { client } from "../client";
import { store } from "../store/configureStore";
import { scroll, removeProp, extractInitials } from "../atlas-utils";

// graphql
import { GET_LOCATIONS_LIST } from "../graphql/hub";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchBizPlatforms } from "../actions/actions";
import { fetchHubConfigurations, editHubConfigurations } from "../actions/hub";

// config
import { CATALOGUE_PLATFORMS_LOGO, PLATFORM_NAME_MAP } from "../client-config";
import { SelectFilterCustom } from "../components/_commons/SelectFilterCustom";
import { fetchBrands } from "../actions/actions";

const FORM_TABS = [
	{
		label: "Integrations",
		value: "integrations",
		isBeta: true
	},
	{
		label: "Automation",
		value: "automation"
	},
	{
		label: "Platform Configs",
		value: "platform-configs"
	}
];

const LOCATIONS_LIST_INIT_STATE = {
	loading: false,
	items: []
};

const Hub = () => {
	return (
		<div>
			<Route exact path="/hub" component={HubContainer} />
			<Route exact path="/hub/:tab" component={HubContainer} />
			<Route exact path="/hub/:tab/:dsp" component={DSPIntegration} />
			<Route
				exact
				path="/hub/:tab/:dsp/edit"
				render={(props) => <DSPIntegration match={props.match} editMode={true} />}
			/>
			<Route
				exact
				path="/hub/:tab/:dsp/edit/:locId/menu/edit/:id"
				render={(props) => <MenuEdit match={props.match} isFromDspIntegration={true} />}
			/>
		</div>
	);
};
export default Hub;

const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail,
	hubConfigurations: store.hubConfigurations,
	isMultibrandEnabled: store.login.loggedInbizDetail?.isMultibrandEnabled || false,
	brands: store.configItems.brands,
	user: store.login.loginDetail
});
const HubContainer = connect(mapStateToProps)(({
	match,
	biz,
	hubConfigurations,
	isMultibrandEnabled,
	brands,
	user
}) => {
	const [formTab, setFormTab] = useState(match?.params?.tab || FORM_TABS[0].value);
	const [isFormTouched, setFormTouched] = useState(false);
	const { loading, data, error } = hubConfigurations;
	const [locationsList, setLocationsList] = useState(LOCATIONS_LIST_INIT_STATE);
	const [selectedLocation, setSelectedLocation] = useState({ id: "default", title: "Default" });
	const [hubNavOpen, setHubNav] = useState(false);
	const [channelsList, setChannelsList] = useState([]);
	const [selectedChannel, setSelectedChannel] = useState(undefined);
	const topRef = useRef();
	const [searchLocation, setSearchLocation] = useState({ key: "default", value: "" });
	const [searchingLocation, setSearchingLocation] = useState(false);
	const [selectedBrand, setSelectedBrand] = useState({ id: "default", name: "Default", color: "blue" });
	const isInternalUser = user?.email?.includes("@urbanpiper.com");

	useEffect(() => {
		if (isMultibrandEnabled) {
			fetchBrands("");
		}
	}, []);

	useEffect(() => {
		fetchHubConfigurations(selectedLocation, selectedBrand);
	}, [selectedLocation]);

	useEffect(() => {
		fetchBizPlatforms();
	}, []);

	const fetchLocationsList = useCallback(
		async (brandId, updateSelectedLocation = false) => {
			if (brandId === "default" && isMultibrandEnabled) {
				return;
			}
			try {
				setLocationsList((current) => ({
					...current,
					loading: true
				}));
				const variables = {
					limit: 50,
					offset: 0,
					filters: [
						{
							field: "is_active",
							value: "true"
						}
					],
					sort: {
						field: "name",
						order: "ASC"
					},
					search: [searchLocation]
				};
				if (brandId && isMultibrandEnabled) {
					variables.brand = String(brandId);
				}
				let resp = await client.query({
					query: GET_LOCATIONS_LIST,
					variables,
					fetchPolicy: "no-cache"
				});
				const locations = resp?.data?.stores?.objects || [];
				setLocationsList((current) => ({
					...current,
					loading: false,
					items: isMultibrandEnabled ? [...locations] : [{ id: "default", title: "Default" }, ...locations]
				}));

				if (locations.length > 0 && isMultibrandEnabled && updateSelectedLocation) {
					setSelectedLocation(locations?.[0] || null);
					setFormTouched(false);
				}

				if (locations?.length === 0 && isMultibrandEnabled && updateSelectedLocation) {
					setSelectedLocation(null);
					setFormTouched(false);
				}
			} catch (error) {
				setLocationsList((current) => ({
					...current,
					loading: false
				}));
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setSearchingLocation(false);
		},
		[searchLocation, selectedBrand]
	);

	useEffect(() => {
		fetchLocationsList(selectedBrand?.id || null);
	}, [searchLocation]);

	const getChannelsList = useCallback(() => {
		if (data && !channelsList.length) {
			let channelsList = data
				.filter((platform) => platform.keys.filter((config) => !config.key.includes("auto")).length > 0)
				.map((platform) => ({ name: platform.name.toLowerCase(), logo: platform?.logo || null }));
			setChannelsList(channelsList);
			if (channelsList.length > 0 && !selectedChannel) {
				setSelectedChannel(channelsList[0].name);
			}
		}
	}, [data]);

	useEffect(() => {
		getChannelsList();
	}, [getChannelsList]);

	const handleLocation = async (f, location) => {
		if (location === null) {
			return;
		}
		setSelectedLocation(location);
		setFormTouched(false);
	};

	const handleLocationSearch = debounce((searchQuery) => {
		if (searchQuery !== searchLocation.value) {
			setSearchingLocation(true);
			setSearchLocation({
				...searchLocation,
				value: searchQuery
			});
		}
	}, 300);

	const switchTab = (tab) => {
		setFormTab(tab.value);
		if (channelsList.length) {
			setSelectedChannel(channelsList[0].name);
		}
		// update url
		history.replace(`/hub/${tab.value}`);
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
	};

	const handleCancel = () => {
		fetchHubConfigurations(selectedLocation, selectedBrand);
		setFormTouched(false);
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
		if (channelsList.length) {
			setSelectedChannel(channelsList[0].name);
		}
	};

	const handleForm = (field, value, platform) => {
		store.dispatch({
			type: ActionTypes.UPDATE_HUB_CONFIGURATIONS,
			payload: {
				platform: platform,
				field: field,
				value: value
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		const sanitisedData = removeProp(data, "__typename");
		const resp = await editHubConfigurations(sanitisedData, selectedLocation, selectedBrand);
		if (resp) {
			setFormTouched(false);
			// scroll to the top
			if (topRef?.current) {
				scroll({ top: topRef?.current?.offset - 57, left: 0 });
			}
		}
	};

	const handleHubNavigation = (channel) => {
		// set channel
		setSelectedChannel(channel);

		// perform scroll
		const channelContainer = document.querySelector(`.card-container[channel="${channel}"]`);
		window.requestAnimationFrame(() => {
			scroll({ top: channelContainer.offsetTop - 135, left: 0 });
		});
		window.requestAnimationFrame(() => {
			setHubNav(false);
		});
	};

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 25 ? brand.name.slice(0, 25) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	const handleBrand = (brand) => {
		if (brand?.id === "default") {
			setLocationsList(LOCATIONS_LIST_INIT_STATE);
			setSelectedLocation({ id: "default", title: "Default" });
		}
		setSelectedBrand(brand);
		fetchLocationsList(brand?.id, true);
	};

	const brandOptions = [{ id: "default", name: "Default", color: "blue" }, ...(brands?.items ?? [])];

	return (
		<div className="hub-config-section section-container-common" ref={topRef}>
			<div className="credits-section-header hub-config-header">
				<div className="header-text">
					<div className="title">Platforms</div>
					<div className="subtitle">
						Manage Platform configurations for your business and your stores from here
					</div>
				</div>
				{formTab !== FORM_TABS[0].value && (
					<div className="header-action-button">
						{isMultibrandEnabled && (
							<SelectFilterCustom
								options={brandOptions}
								isLoading={brands.isLoading}
								field="brands"
								currValue={selectedBrand}
								setFilter={(f, value) => handleBrand(value)}
								labelKey="name"
								valueKey="id"
								isSearchable={false}
								customLabel={true}
								customOptions={true}
								// isClearable={true}
								renderLabel={handleBrandsLabelOption}
								renderOptions={handleBrandsLabelOption}
								placeholder="Select brand"
								handleBrand={handleBrand}
							/>
						)}
						<SelectFilter
							options={locationsList?.items}
							isLoading={locationsList.loading || loading || searchingLocation}
							field="selectedLocation"
							currValue={selectedLocation}
							setFilter={handleLocation}
							labelKey="title"
							valueKey="id"
							isClearable={false}
							isAsync={true}
							handleSearch={handleLocationSearch}
						/>
					</div>
				)}
			</div>
			<div className="hub-config-container">
				<Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={switchTab}
					isStickyOnTop={true}
					hiddenTabs={[]}
				/>
				<FormContainer
					cancel={handleCancel}
					submit={handleSubmit}
					submitTitle="Save"
					hideActions={formTab === FORM_TABS[0].value || !isFormTouched}
				>
					<div className="form-content">
						{formTab === FORM_TABS[0].value && <Integrations />}
						{formTab === FORM_TABS[1].value && (
							<Automation
								data={data}
								loading={loading}
								selectedLocation={selectedLocation}
								handleForm={handleForm}
								validations={error.fields || {}}
							/>
						)}
						{formTab === FORM_TABS[2].value && (
							<PlatformConfigs
								data={data}
								loading={loading}
								handleForm={handleForm}
								selectedLocation={selectedLocation}
								validations={error.fields || {}}
								isInternalUser={isInternalUser}
							/>
						)}
					</div>
					{channelsList.length > 1 && formTab === FORM_TABS[2].value && (
						<HubNavigation
							hubNavOpen={hubNavOpen}
							setHubNav={setHubNav}
							handleHubNavigation={handleHubNavigation}
							handleOutsideClick={() => setHubNav(false)}
							channelsList={channelsList}
							selectedChannel={selectedChannel}
						/>
					)}
				</FormContainer>
			</div>
		</div>
	);
});

const HubNavigation = OutsideClick(
	({ hubNavOpen, setHubNav, handleHubNavigation, channelsList, selectedChannel, nodeRef }) => {
		return (
			<div ref={nodeRef} className="hub-channels-navigation" onClick={() => setHubNav(!hubNavOpen)}>
				<img src="/assets/icons/icon-channels-navigation.svg" />
				<span>Channels</span>
				{hubNavOpen && (
					<div className="channels-list" onClick={(e) => e.preventDefault()}>
						{channelsList.map((channel, i) => (
							<div
								className="channel-list-item"
								data-active={channel.name === selectedChannel ? true : false}
								key={i}
								onClick={() => handleHubNavigation(channel.name)}
							>
								{PLATFORM_NAME_MAP[channel?.name?.toLowerCase()] || channel.name}
								<img
									src={
										channel?.logo ||
										CATALOGUE_PLATFORMS_LOGO[channel.name.toLowerCase()] ||
										"/assets/icons/icons8-globe-40.png"
									}
								/>
							</div>
						))}
					</div>
				)}
			</div>
		);
	}
);

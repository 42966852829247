import React, { useState, useEffect, useCallback, useReducer, useRef, useImperativeHandle } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Button } from "../components/_commons/Button";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BasicInfo from "../components/UnifiedUserEdit/BasicInfo";

// utils
import { store } from "../store/configureStore";
import history from "../history";
import { removeProp, adjustNestedContainer, trackEvent } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// actions
import { fetchUsersList, fetchUserDetail, editUserDetail } from "../actions/unifiedUsers";
import { ActionTypes } from "../actions/_types";

// reducers
import { unifiedUserDetailsReducer, UNIFIED_USER_DETAILS_INITIAL_STATE } from "../reducers/unifiedUsers";

// assets
import HelpIcon from "../components/_commons/HelpIcon";

// constants
import { NESTED_ENTITY_TYPES } from "../client-config";

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	}
];
const HELP_SECTION_FORM_TABS = [
	{
		label: "Help Video",
		value: "help",
		render: "default"
	},
	{
		label: "Contact Support",
		value: "support",
		render: () => {
			const user = store.getState().login.loginDetail;
			const biz = store.getState().login.loggedInbizDetail;
			const url = `https://docs.google.com/forms/d/e/1FAIpQLSdvAVdphUPw0s93pOgdjVgarMEm8wrOhRvUlCDnoAdNkeNkiw/viewform?usp=pp_url&${`entry.485428648=${
				user.fullName || ""
			}&`}${`entry.1433034111=${
				user.email || user.verificationDetails.email || user.verificationDetails.phone
			}&`}${`entry.24550726=${biz.name}&`}entry.757657104=Atlas+website`;

			const handleOpenUrl = (url, target = "_self") => {
				if (url) {
					// track event
					const eventName = "user_access_contact_support";
					const eventMeta = {
						name: user.fullName,
						credential: user.email || user.verificationDetails.email || user.verificationDetails.phone,
						from: "User Detail"
					};
					trackEvent(eventName, eventMeta);

					window.open(url, target).focus();
				}
			};

			return (
				<div className="contact-support">
					<img src="/assets/empty_states/graphics-empty-segment.svg" alt="" />
					<div className="sub-header-text">Trouble with user actions?</div>
					<div className="description">Raise a support ticket!</div>
					<Button clickHandler={() => handleOpenUrl(url, "_blank")}>Contact Support</Button>
				</div>
			);
		}
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const UnifiedUserEdit = ({
	user = {},
	access,
	match,
	readOnly = false,
	isNested = false,
	isForeignSource = false,
	closeNestedContainer,
	connectedRef
}) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormOpen, setFormState] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [userDetails, dispatch] = useReducer(unifiedUserDetailsReducer, UNIFIED_USER_DETAILS_INITIAL_STATE);
	const { loading, confirmLoading, data, error } = userDetails;
	const [reloading, setReloading] = useState(false);
	const [isModalBusy, setModalBusy] = useState(false);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	useEffect(() => {
		fetchUserDetail(parseInt(match.params.id), match.params.app, dispatch);
	}, [match.params.id]);

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				const updatesAppsUserData = data.appsUserData.map((app) => ({
					...app,
					isActive: !data.isActive
				}));
				dispatch({
					type: ActionTypes.UPDATE_UNIFIED_USER_DETAIL,
					payload: {
						appsUserData: updatesAppsUserData,
						isActive: !data.isActive
					}
				});
			}
			setArchiveRestore(false);
		},
		[data, dispatch]
	);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(() => {
				if (isNested || isForeignSource) {
					closeNestedContainer();
				} else {
					fetchUsersList();
					history.push("/unified-access");
				}
			}, 100);
		}
	};

	const handleCancelChanges = async () => {
		if (editMode) {
			if (isFormTouched) {
				setReloading(true);
				await fetchUserDetail(parseInt(match.params.id), match.params.app, dispatch);
			}
			setEditMode(false);
			setFormTouched(false);
			setReloading(false);
		}
	};

	const handleForm = (field, value, index, newInvite = false) => {
		if (newInvite) {
			// update fields in new invite data
			const updatedAppsData = data.inviteData?.inviteData?.appsData.map((app, i) => {
				let appData = { ...app };
				if (i === index) {
					appData[field] = value;
					if (field === "isActive") {
						appData.rolesValidation = "";
						appData.locationsValidation = "";
					}
					if (field === "roles") {
						appData.rolesValidation = "";
					}
					if (field === "locations" || field === "allLocations") {
						appData.locationsValidation = "";
					}
				}
				return appData;
			});
			dispatch({
				type: ActionTypes.UPDATE_UNIFIED_USER_DETAIL,
				payload: {
					inviteData: {
						...data.inviteData,
						inviteData: {
							appsData: updatedAppsData
						}
					}
				}
			});
		} else {
			// update field in app data
			const updatedAppsData = data.appsUserData.map((app, i) => {
				let appData = { ...app };
				if (i === index) {
					appData[field] = value;
					if (field === "isActive") {
						appData.rolesValidation = "";
						appData.locationsValidation = "";
					}
					if (field === "roles") {
						appData.rolesValidation = "";
					}
					if (field === "locations" || field === "allLocations") {
						appData.locationsValidation = "";
					}
				}
				return appData;
			});
			dispatch({
				type: ActionTypes.UPDATE_UNIFIED_USER_DETAIL,
				payload: {
					appsUserData: updatedAppsData
				}
			});
		}
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const updateUserDetailState = (updates, newInvite = false) => {
		if (newInvite) {
			dispatch({
				type: ActionTypes.UPDATE_UNIFIED_USER_DETAIL,
				payload: {
					inviteData: {
						...updates
					}
				}
			});
		} else {
			dispatch({
				type: ActionTypes.UPDATE_UNIFIED_USER_DETAIL,
				payload: {
					...updates
				}
			});
		}
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		let activePlatforms = 0;
		let emptyRolesExist = false;
		let emptyLocationsExist = false;
		const updatedAppsData = data.appsUserData.map((app) => {
			if (app.isActive) {
				if (app.appName === "atlas") {
					// handle roles validations
					if (app.roles.length === 0) {
						emptyRolesExist = true;
						app.rolesValidation = "This field is required.";
					}
					// handle locations validations
					if (
						app?.roles?.find((role) => role?.id != 59) !== undefined &&
						app.locations.length === 0 &&
						!app.allLocations
					) {
						emptyLocationsExist = true;
						app.locationsValidation = "This field is required.";
					}
					if (app.brands) delete app.brands;
					if (app.brandOptions) delete app.brandOptions;
				}
				if (app.appName === "prime") {
					// handle roles validations
					if (!app.roles) {
						emptyRolesExist = true;
						app.rolesValidation = "This field is required.";
					}
					// handle locations validations
					if (app.locations.length === 0 && !app.allLocations) {
						emptyLocationsExist = true;
						app.locationsValidation = "This field is required.";
					}
				}
			}
			return app;
		});
		const updatedInvitedAppsData = data?.inviteData?.inviteData?.appsData.map((app) => {
			if (app.isActive) {
				activePlatforms += 1;
				if (app.appName === "atlas") {
					// handle roles validations
					if (app.roles.length === 0) {
						emptyRolesExist = true;
						app.rolesValidation = "This field is required.";
					}
					// handle locations validations
					if (
						app?.roles?.find((role) => role?.id != 59) !== undefined &&
						app.locations.length === 0 &&
						!app.allLocations
					) {
						emptyLocationsExist = true;
						app.locationsValidation = "This field is required.";
					}
				}
				if (app.appName === "prime") {
					// handle roles validations
					if (!app.roles) {
						emptyRolesExist = true;
						app.rolesValidation = "This field is required.";
					}
					// handle locations validations
					if (app.locations.length === 0 && !app.allLocations) {
						emptyLocationsExist = true;
						app.locationsValidation = "This field is required.";
					}
				}
			}
			return app;
		});
		dispatch({
			type: ActionTypes.UPDATE_UNIFIED_USER_DETAIL,
			payload: {
				appsUserData: updatedAppsData,
				inviteData: {
					...data.inviteData,
					inviteData: {
						appsData: updatedInvitedAppsData
					}
				}
			}
		});
		if (emptyRolesExist || emptyLocationsExist) {
			return;
		}
		const sanitisedData = removeProp(data, "__typename");
		const newInviteData = activePlatforms > 0 && sanitisedData?.inviteData?.value;
		const resp = await editUserDetail(sanitisedData, dispatch, newInviteData);
		if (resp) {
			setFormTouched(false);
			setEditMode(false);
			const updatedAppsData = data.appsUserData.map((app) => {
				let appData = { ...app };
				if (app.appName === "prime" && app.thickClient && app.pin) {
					appData["pin"] = "__PIN__";
				}
				return appData;
			});
			dispatch({
				type: ActionTypes.UPDATE_UNIFIED_USER_DETAIL,
				payload: {
					appsUserData: updatedAppsData
				}
			});
			if (newInviteData) {
				setReloading(true);
				await fetchUserDetail(parseInt(match.params.id), match.params.app, dispatch);
				setReloading(false);
			}
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "access"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	const extractInitials = (name) => {
		if (!name) {
			return "";
		}
		let initials = "";
		if (name.length > 1) {
			initials = name[0].charAt(0) + name[1].charAt(0);
		} else {
			initials = name[0].charAt(0) + name[0].charAt(1);
		}
		return initials.toUpperCase();
	};

	return (
		<div className="unified-user-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				cancel={handleCancelChanges}
				title={data.fullName || data.email || data.phone || "User"}
				subTitle="Manage user permissions and controls for this user"
				submitTitle="Save"
				hideSubmitAction={!editMode || !isFormTouched}
				loading={confirmLoading || reloading}
				disabled={!isFormTouched}
				isNested={isNested}
				hideActions={formTab === FORM_TABS[0].value && !editMode}
				headerRight={
					<React.Fragment>
						{data.appsUserData &&
							(data.appsUserData?.find((app) => app.appName === "atlas")
								? data.appsUserData?.find((app) => app.appName === "atlas")?.appUserId !== user.id
								: true) &&
							!readOnly &&
							access.isAdmin &&
							!editMode && (
								<Button
									classes={data.isActive ? "at-btn--danger" : ""}
									clickHandler={() => setArchiveRestore(true)}
								>
									{data.isActive ? "Archive" : "Reactivate"}
								</Button>
							)}
						{data.appsUserData &&
							(data.appsUserData?.find((app) => app.appName === "atlas")
								? data.appsUserData?.find((app) => app.appName === "atlas")?.appUserId !== user.id
								: true) &&
							!readOnly &&
							access.isAdmin &&
							!editMode &&
							data.isActive && (
								<Button classes="edit-roles" clickHandler={() => setEditMode(true)}>
									Edit
								</Button>
							)}
						<div className="help-btn-container">
							<Button clickHandler={handlePiperAcademy} type="secondary">
								<HelpIcon />
								<span>Help</span>
							</Button>
						</div>
					</React.Fragment>
				}
			>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data}
							handleForm={handleForm}
							handleData={updateUserDetailState}
							extractInitials={extractInitials}
							validations={error.fields || {}}
							loading={loading}
							readOnly={readOnly}
							editMode={editMode}
							userId={user.id || null}
							userType={match.params.app}
						/>
					)}
					<ArchiveRestore
						isOpen={archiveRestore}
						close={handleArchiveRestore}
						entityType={CATALOGUE_ENTITY_TYPES[17]}
						entityName={data.fullName || data.email || data.phone || "this user"}
						object={data}
						mode={data.isActive ? "archive" : "reactivate"}
						fieldName="isActive"
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
						showTopBar={false}
						// tabs={HELP_SECTION_FORM_TABS}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	user: store.login.loginDetail,
	access: store.login.loginDetail.access
}))(UnifiedUserEdit);

import React, { useEffect, useState } from "react";

// third party
import Compressor from "compressorjs";
import { connect } from "react-redux";
import history from "../history";

// components
import { FormSidebar } from "../components/_commons/FormSidebar";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { InputWithLabel } from "../components/_commons/InputWithLabel";
import { Textarea } from "../components/_commons/Textarea";
import UploaderV2 from "../components/_commons/UploaderV2";
import { SelectFilterCustom } from "../components/_commons/SelectFilterCustom";

// utils
import { createTranslationsObject, extractInitials, isFeatureFlagEnabled, trackEvent } from "../atlas-utils";

// services
import NotificationServices from "../services/NotificationService";

// client
import { store } from "../store/configureStore";
import { clientMenu } from "../client-menu";

// graphql
import { CREATE_MENU, UPDATE_MENU } from "../graphql/menus";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchBrands, handleOnboardingFlowAction } from "../actions/actions";
import { fetchMenusList } from "../actions/menus";
import { TRACKING_EVENT_NAMES, ONBOARDING_FLOWS } from "../client-config";

const MenuCreate = ({
	readOnly = false,
	isMultibrandEnabled,
	brands,
	selectedBrand,
	bizId,
	isEditMode = false,
	menuEditData,
	menuEditPath = "/menus/edit/",
	match,
	isFromRTGLFlow = false,
	isOpen = false,
	isNested = false,
	isForeignSource = false,
	closeNestedContainer,
	onboardingFlow = false,
	handleBasicInfoEditStates,
	atlasOnboardingflowData,
	supportedLanguages
}) => {
	const [menuImage, setMenuImage] = useState(null);
	const [menuTitle, setMenuTitle] = useState(isEditMode ? menuEditData?.name : "");
	const [menuDesc, setMenuDesc] = useState(isEditMode ? menuEditData?.desc : "");
	const [menuBrand, setMenuBrand] = useState(isEditMode ? menuEditData?.brand : null);
	const [menuImageSrc, setMenuImageSrc] = useState(null);
	const [loading, setLoading] = useState(false);
	const [currSelectedLang, setCurrSelectedLang] = useState({
		menuTitle: {
			lang: supportedLanguages.length ? supportedLanguages[0].value : "",
			value: "",
			showActionBtn: false
		},
		menuDesc: {
			lang: supportedLanguages.length ? supportedLanguages[0].value : "",
			value: "",
			showActionBtn: false
		}
	});

	const [translations, setTranslations] = useState([]);
	const isOnboardingFlowEnabled = atlasOnboardingflowData?.name === ONBOARDING_FLOWS.ATLAS_ONBOARDING_FLOW;
	useEffect(() => {
		if (isMultibrandEnabled) {
			fetchBrands("", true);
		}
	}, []);

	useEffect(() => {
		if (menuEditData?.imageUrl) {
			setMenuImageSrc(menuEditData.imageUrl);
		}
	}, [menuEditData]);

	const handleImage = (files) => {
		const imgFile = files[0];
		if (imgFile) {
			const image = new Image();
			image.src = window.URL.createObjectURL(imgFile);

			// proceed only if the uploaded file is a valid image
			image.onload = async function () {
				if (imgFile.size < 1047520) {
					setMenuImage(imgFile);
				} else {
					// setConfirmLoading(true);
					const result = await new Promise((resolve, reject) => {
						new Compressor(imgFile, {
							quality: 0.6,
							maxWidth: 4000,
							success: resolve,
							error: reject
						});
					});
					if (result && result.size < 1047520) {
						setMenuImage(result);
						// setConfirmLoading(false);
					} else {
						// setConfirmLoading(false);
						store.dispatch({
							type: "SHOW_GLOBAL_MESSAGE",
							payload: {
								message: "Image size is too large, please keep it below 1 MB",
								timeout: 3000,
								error: true
							}
						});
					}
				}
			};

			// handle error if uploaded file is not an image
			image.onerror = function () {
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Please upload a valid image.",
						timeout: 3000,
						error: true
					}
				});
			};
		} else {
			store.dispatch({
				type: "SHOW_GLOBAL_MESSAGE",
				payload: {
					message: "Upload an image from your current device",
					timeout: 3000,
					error: true
				}
			});
		}
	};

	const resetCurrentLang = () => {
		setCurrSelectedLang({
			menuTitle: {
				lang: supportedLanguages.length ? supportedLanguages[0].value : "",
				value: "",
				showActionBtn: false
			},
			menuDesc: {
				lang: supportedLanguages.length ? supportedLanguages[0].value : "",
				value: "",
				showActionBtn: false
			}
		});
	};
	const handleClearImage = (e) => {
		e.stopPropagation();
		if (menuImage) {
			setMenuImage(null);
		} else {
			setMenuImageSrc("");
		}
	};

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 18 ? brand.name.slice(0, 18) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	const handleBrandSelection = (field, value) => {
		store.dispatch({
			type: ActionTypes.UPDATE_MENUS_LIST_DATA,
			payload: {
				key: "selectedBrand",
				value: value
			}
		});
	};

	const handleForm = (field, value) => {
		if (field === "menuTitle") {
			if (currSelectedLang.menuTitle.lang === "en") setMenuTitle(value);
			// Update the current selected language value for menuTitle
			setTranslations((prev) => ({
				...prev,
				[currSelectedLang.menuTitle.lang]: {
					...prev[currSelectedLang.menuTitle.lang],
					name: value
				}
			}));
		} else if (field === "menuDesc") {
			if (currSelectedLang.menuDesc.lang === "en") setMenuDesc(value);
			// Update the current selected language value for menuDesc
			setTranslations((prev) => ({
				...prev,
				[currSelectedLang.menuDesc.lang]: {
					...prev[currSelectedLang.menuDesc.lang],
					description: value
				}
			}));
		}
	};

	const handleCreateMenuClose = (menuId) => {
		if ((isNested || isForeignSource) && !menuId) {
			closeNestedContainer();
			return;
		}
		if (isEditMode) {
			handleBasicInfoEditStates(null);
			history.push("/menus");
		} else {
			store.dispatch({
				type: ActionTypes.RESET_PARENT_SECTIONS_STATE
			});
			if (menuId) {
				history.push(`${menuEditPath}${menuId}`);
			} else {
				history.push(`/menus`);
			}
		}
		resetCurrentLang();
	};

	const validateRequiredFields = () => {
		if (menuTitle?.length !== 0) {
			if (isMultibrandEnabled) {
				return selectedBrand?.id !== "all";
			}
			return true;
		}
		return false;
	};
	const handleLanguage = (lang, field) => {
		if (field === "title")
			setCurrSelectedLang({
				...currSelectedLang,
				menuTitle: { lang: lang?.value, value: translations[lang?.value]?.name || "" }
			});
		else if (field === "description")
			setCurrSelectedLang({
				...currSelectedLang,
				menuDesc: { lang: lang?.value, value: translations[lang?.value]?.description || "" }
			});
	};

	const createMenu = async () => {
		if (!validateRequiredFields()) {
			NotificationServices.pushNotification({
				message: "Please fill required details",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			return;
		}
		setLoading(true);
		try {
			if (!isEditMode) {
				const variables = {
					bizId: String(bizId),
					image: menuImage ? menuImage : null,
					name: menuTitle,
					description: menuDesc,
					brand: String(selectedBrand?.id),
					options: [],
					optionGroups: [],
					items: [],
					categories: [],
					translations: createTranslationsObject(translations)
				};
				const resp = await clientMenu.mutate({
					mutation: CREATE_MENU,
					variables
				});
				if (resp?.data?.addMenu?.menuId) {
					NotificationServices.pushNotification({
						message: "Successfully created menu!",
						timeout: 3000,
						type: "success",
						isClosable: true,
						theme: "dark"
					});
					const eventData = {
						source: "atlas",
						is_description_entered: menuDesc?.length > 0,
						is_cover_image_uploaded: menuImage?.size > 0,
						cover_image_size: menuImage && menuImage?.size / 1000000,
						status: "success"
					};
					trackEvent(TRACKING_EVENT_NAMES.NEW_MENU_CREATION, eventData);
					handleCreateMenuClose(resp?.data?.addMenu?.menuId);
					fetchMenusList(selectedBrand?.id);

					// if self-onboarding flow is enabled, mark create menu step as complete
					if (isOnboardingFlowEnabled) {
						handleOnboardingFlowAction("menu_created");

						// track event
						trackEvent("onboarding_menu_complete", { method: "Manual" });
					}
				}
			} else {
				const variablesBasicInfoEdit = {
					id: menuEditData?.menuId,
					menuData: {
						image: menuImage ? menuImage : null,
						name: menuTitle,
						description: menuDesc || "",
						brand: String(selectedBrand?.id),
						imageUrl: menuImageSrc,
						translations: createTranslationsObject(translations)
					}
				};
				const resp = await clientMenu.mutate({
					mutation: UPDATE_MENU,
					variables: variablesBasicInfoEdit
				});

				if (resp?.data?.updateMenuV2?.menuId) {
					NotificationServices.pushNotification({
						message: "Successfully updated basic info!",
						timeout: 3000,
						type: "success",
						isClosable: true,
						theme: "dark"
					});
					store.dispatch({
						type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
						payload: resp?.data?.updateMenuV2
					});
					handleCreateMenuClose();
					fetchMenusList(selectedBrand?.id);
				}
			}
		} catch (e) {
			NotificationServices.pushNotification({
				message: isEditMode ? "Failed to updated basic info" : "Failed to create menu",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			if (!isEditMode) {
				const eventData = {
					source: "atlas",
					is_description_entered: menuDesc?.length > 0,
					is_cover_image_uploaded: menuImage?.size > 0,
					cover_image_size: menuImage && menuImage?.size / 1000000,
					status: "failure"
				};
				trackEvent(TRACKING_EVENT_NAMES.NEW_MENU_CREATION, eventData);
			}
		}
		setLoading(false);
		resetCurrentLang();
	};

	useEffect(() => {
		setMenuDesc(menuEditData?.desc);
		setMenuTitle(menuEditData?.name);
		setMenuBrand(brands?.items.filter((brand) => brand.id === parseInt(menuEditData?.brandId))?.[0]);
		setTranslations(
			menuEditData?.translations && menuEditData.translations.length > 0
				? menuEditData.translations.reduce((acc, translation) => {
						acc[translation.language] = translation;
						return acc;
					}, {})
				: { en: { language: "en", name: menuEditData?.name, description: menuEditData?.desc } }
		);
	}, [menuEditData]);
	// if((selectedBrand?.id === "all") && !!brands?.items?.length) {
	// 	handleBrandSelection("brand", (brands?.items ?? []).filter(brand => brand?.id !== "all")?.[0])
	// }
	const getBrandCurrentValue = () => {
		if (selectedBrand?.id === "all" && isEditMode) {
			return menuBrand;
		}
		if (selectedBrand?.id === "all" && !isEditMode) {
			return null;
		}
		if (selectedBrand?.id !== "all") {
			return selectedBrand;
		}
	};

	return (
		<FormSidebar
			isOpen={menuEditData?.isOpen || match?.path === "/menus/new" || isOpen}
			title={isEditMode ? "Edit menu details" : "Create a Menu"}
			isNested={isNested}
			close={handleCreateMenuClose}
			submit={createMenu}
			loading={loading}
			disabled={!isEditMode ? !menuTitle || (isMultibrandEnabled && !selectedBrand) : false}
		>
			<div className="menu-creation-container">
				<div className="form-row row-half">
					<InputWithLabel
						value={
							isFeatureFlagEnabled("Menu-V2-Translations")
								? (translations[currSelectedLang.menuTitle.lang]?.name ??
									(currSelectedLang.menuTitle.lang === "en" ? menuTitle : ""))
								: menuTitle
						}
						onChange={(e) => handleForm("menuTitle", e.target.value)}
						requiredLabel
						showLanguages={isFeatureFlagEnabled("Menu-V2-Translations")}
						currSelectedLang={currSelectedLang.menuTitle.lang}
						handleLanguage={(lang) => handleLanguage(lang, "title")}
					>
						Name
					</InputWithLabel>
					{isMultibrandEnabled && (
						<SelectFilterCustom
							title="Associated Brand"
							options={brands?.items.filter((brand) => brand.id !== "all")}
							isLoading={brands?.isLoading}
							field="brands"
							currValue={getBrandCurrentValue()}
							setFilter={handleBrandSelection}
							labelKey={"name"}
							valueKey={"id"}
							isSearchable={false}
							customLabel={true}
							customOptions={true}
							renderLabel={handleBrandsLabelOption}
							renderOptions={handleBrandsLabelOption}
							placeholder="Select brand"
							requiredLabel
							readOnly={onboardingFlow || isFromRTGLFlow}
						/>
					)}
				</div>
				<div className="form-row row-full">
					<Textarea
						value={
							isFeatureFlagEnabled("Menu-V2-Translations")
								? (translations[currSelectedLang.menuDesc.lang]?.description ??
									(currSelectedLang.menuDesc.lang === "en" ? menuDesc : ""))
								: menuDesc
						}
						onChange={(e) => handleForm("menuDesc", e.target.value)}
						showLanguages={isFeatureFlagEnabled("Menu-V2-Translations")}
						currSelectedLang={currSelectedLang.menuDesc.lang}
						handleLanguage={(lang) => handleLanguage(lang, "description")}
					>
						Description
					</Textarea>
				</div>
				<div className="form-row row-half">
					<UploaderV2
						onChange={handleImage}
						classes={readOnly ? "read-only" : ""}
						showDelete={true}
						handleDelete={handleClearImage}
						file={menuImage}
						url={menuImageSrc}
					>
						Cover Image
					</UploaderV2>
				</div>
			</div>
		</FormSidebar>
	);
};
const mapStateToProps = (store) => ({
	brands: store.configItems.brands,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	selectedBrand: store.menusListState.selectedBrand,
	bizId: store.login.loggedInbizDetail.id,
	supportedLanguages: store.login.loggedInbizDetail.supportedLanguages,
	atlasOnboardingflowData: store.atlasOnboardingState.flowData
});
export default connect(mapStateToProps)(MenuCreate);

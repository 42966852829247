import React, { useState, useEffect, useCallback, useRef } from "react";

// third party
import { connect } from "react-redux";

// components
import { Loading } from "./Loading";

// utils
import { handleBodyScroll } from "../../atlas-utils";

// clients
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";

const OverlaySidebar = ({
	classes = "",
	children,
	close,
	canExpand = false, // UI to expand drawer (hover near the left edge of drawer)
	expandDrawer = false, // expand on render or selected tab
	setIsDrawerExpanded, // return state of drawer if expanded
	loading,
	showOverlaySidebar,
	isNested = false,
	isFilters = false,
	isOpen = false,
	formSidebarRef
}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const panelRef = useRef();

	useEffect(() => {
		if (showOverlaySidebar) {
			panelRef.current.focus({ preventScroll: true });
		}
		if (isOpen && !isNested) {
			store.dispatch({
				type: ActionTypes.SHOW_CLOSE_DRAWER_INSTRUCTION
			});
		}
		// if(!isNested) {
		// 	handleBodyScroll(showOverlaySidebar);
		// }
	}, [showOverlaySidebar]);

	const handleClose = useCallback(() => {
		if (loading) {
			return;
		}
		close();
		store.dispatch({
			type: "HIDE_CLOSE_DRAWER_INSTRUCTION"
		});
	}, [loading, close]);

	const handleKeyDown = (e) => {
		e.stopPropagation();
		if (e.key === "Escape") {
			handleClose();
			store.dispatch({
				type: "HIDE_CLOSE_DRAWER_INSTRUCTION"
			});
			const parentNestedContainer = document.querySelectorAll(
				".overlay-sidebar-container > .content.content-show"
			);
			if (!parentNestedContainer?.length) {
				return;
			}
			parentNestedContainer[0].parentElement.focus();
		}
	};

	const handleExpandDrawer = (expanded = false, customWidth = 0) => {
		setTimeout(() => {
			let drawer = document.querySelectorAll(".overlay-sidebar-container > .content.content-show");
			if (drawer?.length > 3) {
				drawer[0].style.width = 960 + "px";
				drawer[0].style.right = "-" + 960 + "px";
				setIsExpanded(false);
				if (setIsDrawerExpanded) {
					setIsDrawerExpanded(false);
				}
				return;
			}
			if (drawer?.length > 1) {
				return;
			}
			if (drawer[0] && expanded) {
				drawer[0].style.width = 750 + "px";
				drawer[0].style.right = "-" + 750 + "px";
				setIsExpanded(false);
				if (setIsDrawerExpanded) {
					setIsDrawerExpanded(false);
				}
			} else if (drawer[0]) {
				drawer[0].style.width = 1050 + "px";
				drawer[0].style.right = "-" + 1050 + "px";
				setIsExpanded(true);
				if (setIsDrawerExpanded) {
					setIsDrawerExpanded(true);
				}
			}
		}, 100);
	};

	useEffect(() => {
		if (!canExpand) {
			handleExpandDrawer(true);
		}
		if (expandDrawer) {
			handleExpandDrawer(false);
		}
	}, [canExpand, expandDrawer]);

	return (
		<div
			className={
				classes +
				" overlay-sidebar-container " +
				(isNested ? "is-nested" : "") +
				(isFilters ? " is-filters" : "")
			}
			onKeyDown={(e) => handleKeyDown(e)}
			ref={panelRef}
			tabIndex="0"
		>
			<div
				className={"at-modal--backdrop " + (showOverlaySidebar ? "backlayer-show" : "")}
				onClick={handleClose}
			/>
			<div
				className={"content " + (showOverlaySidebar ? "content-show " : "") + (loading ? "no-click" : "")}
				ref={formSidebarRef}
			>
				<div style={{ position: "relative", height: "100%", width: "100%" }}>
					{children}
					{loading && <Loading />}
				</div>
				{!isNested && canExpand && (
					<div className="resize-form-sidebar" onClick={() => handleExpandDrawer(isExpanded)}>
						<img
							className={"arrow " + (isExpanded ? "right" : "left")}
							src={`/assets/icons/icon-${isExpanded ? "right" : "left"}-arrow.svg`}
							alt=""
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default connect((store, props) => ({
	...props
}))(OverlaySidebar);

import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormSidebar } from "../../../components/_commons/FormSidebar";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { CheckBox } from "../../../components/_commons/CheckBox";
import { client } from "../../../client";
import { store } from "../../../store/configureStore";
import { connect } from "react-redux";
import { CustomTable } from "../../../components/_commons/CustomTable";
import { SelectFilter } from "../../../components/_commons/SelectFilter";
import {
	fetchDebouncedEarlyAccessLocationsList,
	fetchEarlyAccessLocationsList,
	fetchPeriscopeConfigPlatforms
} from "../../../actions/periscope";
import { SearchFilter } from "../../../components/_commons/SearchFilter";
import { Paginator } from "../../../components/_commons/Paginator";
import { Transition, animated, config } from "react-spring/renderprops";
import { GET_LOCATION } from "../../../graphql/periscope";
import { ActionTypes } from "../../../actions/_types";
import { lS } from "../../../atlas-utils";
import {
	PERISCOPE_BIZ_IDS_UNRESTRICTED_LOCATION_SELECTION,
	MAX_LOCATIONS_LIMIT_FOR_PERISCOPE__ACCESS
} from "../../../client-config";
import NotificationServices from "../../../services/NotificationService";
import InfoIcon from "../../../components/_icons/InfoIcon";
import { fetchBizPlatforms } from "../../../actions/actions";
const env = process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG;

const ShowLocations = ({
	periscopeEarlyAccessData,
	periscopeEarlyAccessState,
	login,
	isMultibrandEnabled,
	periscopeCountryWiseSupportedPlatforms,
	configItems
}) => {
	const location = useLocation();
	const pathName =
		location?.state?.path == "/periscope/early-access/free-trial"
			? "/periscope/early-access/free-trial"
			: "/periscope/early-access/get-started";
	const cities = periscopeEarlyAccessData?.data?.filters?.[2]?.values || [];
	const limit = periscopeEarlyAccessState?.limit;
	const offset = periscopeEarlyAccessState?.offset;
	const [isFormOpen, setFormState] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const locations = periscopeEarlyAccessState?.locations;
	const locationsPlatformMapping = periscopeEarlyAccessState?.locationsPlatformMapping;
	const bizId = login?.loggedInbizDetail?.id;
	const hasUnlimitedLocationAccess = PERISCOPE_BIZ_IDS_UNRESTRICTED_LOCATION_SELECTION?.[env]?.[bizId];

	const [selectedCity, setSelectedCity] = useState({
		value: "all",
		valueForDisplay: "All Cities"
	});
	const history = useHistory();
	const country = (login?.loggedInbizDetail?.country || lS.get("auth")?.biz?.country).toLowerCase();

	const getAllInfo = (business) => {
		let formattedData = [];
		let uniqBrands = {};
		let uniqPlatforms = {};
		let uniqLocations = {};

		if (!isMultibrandEnabled) {
			const extractData = ({ associatedPlatforms, locationId, name, city }) => {
				associatedPlatforms.forEach((associatedPlatform) => {
					const platformName = associatedPlatform?.platform?.platformName?.toLowerCase();
					const pltSupported = periscopeCountryWiseSupportedPlatforms?.[country]?.[platformName]?.enabled;
					const outletUrl = associatedPlatform.outletUrl;
					if (outletUrl && pltSupported) {
						uniqPlatforms[associatedPlatform.platform.id] = 1;
						uniqLocations[name] = 1;
						formattedData.push({
							bizId: String(login?.loggedInbizDetail?.id),
							location: { name: name, id: locationId ? String(locationId) : null },
							platform: {
								name: platformName,
								id: associatedPlatform.platform.id ? String(associatedPlatform.platform.id) : null
							},
							brand: null,
							city: city,
							externalId: associatedPlatform?.externalId ? String(associatedPlatform?.externalId) : null,
							testId: associatedPlatform?.testId ? String(associatedPlatform?.testId) : null,
							outletId: locationId ? String(locationId) : null,
							url: outletUrl
						});
					}
				});
			};
			business.forEach((val) => {
				extractData(val);
			});
			store.dispatch({
				type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
				payload: {
					dataSelected: {
						formattedData: formattedData,
						uniqBrands: null,
						uniqPlatforms: uniqPlatforms,
						uniqLocations: uniqLocations
					}
				}
			});
		} else {
			const extractData = ({ brandLocations, locationId, name, city }) => {
				brandLocations.forEach((plt) => {
					if (plt.isActive) {
						plt.associatedPlatforms.forEach((associatedPlatform) => {
							const outletUrl = associatedPlatform?.outletUrl;
							const platformName = associatedPlatform?.platform?.platformName?.toLowerCase();
							const pltSupported =
								periscopeCountryWiseSupportedPlatforms?.[country]?.[platformName]?.enabled;
							if (outletUrl && pltSupported) {
								uniqBrands[plt.brand.name] = 1;
								uniqPlatforms[associatedPlatform.platform.id] = 1;
								uniqLocations[name] = 1;
								formattedData.push({
									bizId: login?.loggedInbizDetail?.id ? String(login?.loggedInbizDetail?.id) : null,
									location: { name: name, id: String(locationId) },
									platform: {
										name: platformName,
										id: associatedPlatform.platform.id
											? String(associatedPlatform.platform.id)
											: associatedPlatform.platform.id
									},
									brand: { id: String(plt.brand.id), name: plt.brand.name },
									city: city,
									externalId: associatedPlatform?.externalId
										? String(associatedPlatform?.externalId)
										: null,
									testId: associatedPlatform?.testId ? String(associatedPlatform?.testId) : null,
									outletId: plt?.id ? String(plt?.id) : null,
									url: outletUrl
								});
							}
						});
					}
				});
			};
			business.forEach((val) => {
				extractData(val);
			});
			store.dispatch({
				type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
				payload: {
					dataSelected: {
						formattedData: formattedData,
						uniqBrands: uniqBrands,
						uniqPlatforms: uniqPlatforms,
						uniqLocations: uniqLocations
					}
				}
			});
		}

		history.push(`/periscope/early-access/checkout`);
	};

	useEffect(() => {
		(configItems?.bizPlatforms?.items || []).length == 0 && fetchBizPlatforms(true, true, true);
		setTimeout(() => setFormState(true), 60);
		if (!periscopeCountryWiseSupportedPlatforms?.[country]) {
			fetchPeriscopeConfigPlatforms();
		}
	}, []);

	const handleCancel = async () => {
		setFormState(false);
		setTimeout(() => {
			history.push(pathName);
		}, 100);
	};

	const locationsSelected = useMemo(() => {
		return Object.keys(locations || {}).filter((val) => locations[val]);
	}, [locations]);
	let displayBottomButton = (locationsSelected.length || []) > 0;

	const getLocationDetailView = (locationsPlatformMapping) => {
		const finalResult = locationsPlatformMapping.reduce((acc, curr) => {
			acc.push({
				associatedPlatforms: curr?.associatedPlatforms,
				brandLocations: curr?.brandLocations,
				city: curr?.city,
				name: curr?.name,
				locationId: curr?.id
			});
			return acc;
		}, []);
		getAllInfo(finalResult);
	};

	const handleSubmit = async () => {
		getLocationDetailView(locationsPlatformMapping);
	};

	const selectedCityValue = selectedCity?.value || "all";
	const getVariables = () => {
		return {
			brand: isMultibrandEnabled ? "all" : null,
			limit: limit,
			offset: offset,
			// platform_ids: (configItems?.bizPlatforms?.items || []).reduce((acc, plt) => {
			// 	if (
			// 		periscopeCountryWiseSupportedPlatforms?.[country]?.[(plt?.platformName || "").toLowerCase()]
			// 			?.enabled
			// 	) {
			// 		Number(plt.id) && acc.push(Number(plt.id));
			// 	}
			// 	return acc;
			// }, []),
			sort: { field: "name", order: "ASC" },
			filters: [
				{ field: "is_active", value: "true" }
				// { field: "state", value: "1,2" }
			]
		};
	};

	useEffect(() => {
		if ((configItems?.bizPlatforms?.items || []).length > 0) {
			let variables = getVariables();
			if (selectedCityValue !== "all") {
				variables = {
					...variables,
					filters: [...variables.filters, { field: "city", value: selectedCityValue }]
				};
			}
			fetchEarlyAccessLocationsList(variables);
		} else {
			(configItems?.bizPlatforms?.items || []).length == 0 && fetchBizPlatforms(true, true, true);
		}
	}, [limit, offset, selectedCityValue, (configItems?.bizPlatforms?.items || []).length]);

	const [searchQuery, setSearchQuery] = useState("");
	const handleSearch = (field, value) => {
		if ((configItems?.bizPlatforms?.items || []).length > 0) {
			setSearchQuery(value);
			store.dispatch({
				type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
				payload: { text: value }
			});
			let variables = getVariables();
			if (selectedCityValue !== "all") {
				variables = {
					...variables,
					filters: [{ field: "city", value: selectedCityValue }]
				};
			}
			if (value && value.length > 0) {
				variables = { ...variables, search: [{ key: "default", value: value }] };
			}
			fetchDebouncedEarlyAccessLocationsList(variables);
		}
	};

	const data = periscopeEarlyAccessData?.data || [];
	const columns = [
		{
			name: "Location",
			field: "location_id",
			render: (record, i) => {
				const status = locations?.[record?.id] === undefined ? false : locations?.[record?.id];
				return (
					<div className="table-cell location_id" title={record.bizLocationId} key={i}>
						<CheckBox
							checked={status}
							clickHandler={(e) => handleCheck(e, record.id, !status)}
							title={record.title || record.id}
						>
							<span className="title-display">{record.title || ""}</span>
						</CheckBox>
					</div>
				);
			}
		},
		{
			name: "City",
			field: "city",
			render: (record, i) => (
				<div className="table-cell city" key={i}>
					{record.city || ""}
				</div>
			)
		}
	];

	const [dataShown, setDataShown] = useState(data.objects || []);
	const [sortSelected, setSortSelected] = useState(false);

	let sortedData = useMemo(() => {
		const sortedData = [...(data?.objects || [])].sort((data1, data2) => {
			return locationsSelected.indexOf(String(data2?.id)) - locationsSelected.indexOf(String(data1?.id));
		});
		return sortedData;
	}, [data.objects, locationsSelected]);

	const handleSortDataBtn = (e) => {
		setSortSelected((prev) => !prev);
		if (!sortSelected) {
			setDataShown(sortedData);
		} else {
			setDataShown(data.objects);
		}
	};

	useEffect(() => {
		if (sortSelected) {
			setDataShown(sortedData);
		} else {
			setDataShown(data.objects);
		}
	}, [data]);

	const setInitialOffset = () => {
		store.dispatch({
			type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
			payload: { offset: 0 }
		});
	};
	const handleCityFilter = (val) => {
		setSelectedCity(val);
		setInitialOffset();
	};

	const isCheckedAll = periscopeEarlyAccessState?.isCheckedAll;
	const locationRef = useRef();
	useEffect(() => {
		if (hasUnlimitedLocationAccess) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
				payload: {
					isCheckedAll: false
				}
			});
		}
	}, [limit, offset]);
	const locationsPlatformMappingRef = useRef();
	const handleCheckAll = (e, toCheckAll) => {
		e.stopPropagation();
		if (hasUnlimitedLocationAccess) {
			const locationChecks = dataShown.reduce(
				(acc, curr) => {
					acc[curr?.id] = toCheckAll;
					return acc;
				},
				{ ...locations }
			);

			if (toCheckAll) {
				const platformMapping = [...new Set([...locationsPlatformMapping, ...dataShown])];
				store.dispatch({
					type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
					payload: {
						locations: locationChecks,
						locationsPlatformMapping: platformMapping,
						isCheckedAll: true,
						selectedLocationsCount: platformMapping.length
					}
				});
			} else {
				const platformMapping = [
					...new Set(
						locationsPlatformMapping.reduce((acc, curr) => {
							if (locationChecks[curr.id]) {
								acc.push(curr);
							}
							return acc;
						}, [])
					)
				];
				store.dispatch({
					type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
					payload: {
						locations: locationChecks,
						locationsPlatformMapping: platformMapping,
						isCheckedAll: false,
						selectedLocationsCount: platformMapping.length
					}
				});
			}
		} else {
			if (sortSelected) {
				setSortSelected((prev) => !prev);
			}

			if (toCheckAll) {
				if (Object.values(locationRef.current || {}).filter((val) => val).length > 0) {
					store.dispatch({
						type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
						payload: {
							locations: locationRef.current,
							locationsPlatformMapping: locationsPlatformMappingRef.current,
							isCheckedAll: true,
							selectedLocationsCount:
								Object.values(locationRef.current || {}).filter((val) => val)?.length || 0
						}
					});
				}
			} else {
				if (Object.values(locations || {}).filter((val) => val).length > 0) {
					locationRef.current = locations;
					locationsPlatformMappingRef.current = locationsPlatformMapping;
					store.dispatch({
						type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
						payload: {
							locations: {},
							locationsPlatformMapping: [],
							isCheckedAll: false,
							selectedLocationsCount: 0
						}
					});
				}
			}
		}
	};
	const handleCheck = (e, id, toSelect) => {
		if (
			Object.values(locations || {}).filter((val) => val).length < MAX_LOCATIONS_LIMIT_FOR_PERISCOPE__ACCESS ||
			hasUnlimitedLocationAccess ||
			!toSelect
		) {
			if (sortSelected) {
				setSortSelected((prev) => !prev);
			}
			let locationUpdates = locationsPlatformMapping.filter((loc) => locations[loc?.id]);
			if (toSelect) {
				locationUpdates.push(data.objects.find((loc) => loc?.id == id));
			} else {
				locationUpdates = locationUpdates.filter((loc) => loc?.id !== id);
			}
			let currentLocations = { ...locations, [parseInt(id)]: toSelect };
			let checkedVal;
			if (Object.values(currentLocations || {}).filter((val) => val).length > 0) {
				checkedVal = true;
			} else {
				checkedVal = false;
			}
			locationRef.current = currentLocations;
			store.dispatch({
				type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
				payload: {
					locations: currentLocations,
					locationsPlatformMapping: locationUpdates,
					isCheckedAll: hasUnlimitedLocationAccess ? false : checkedVal,
					selectedLocationsCount: Object.values(currentLocations || {}).filter((val) => val)?.length || 0
				}
			});
		} else {
			NotificationServices.pushNotification({
				message: "You can only select up to 5 locations.",
				timeout: 2000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
		}
	};
	const dataCount = periscopeEarlyAccessData.data?.count || 0;
	const handlePagination = async (page) => {
		const offset = (page - 1) * limit;
		store.dispatch({
			type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
			payload: { offset: offset }
		});
	};
	const handlePageSize = async (field, size) => {
		store.dispatch({
			type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
			payload: { limit: size.value, offset: 0 }
		});
	};

	return (
		<div className="periscope-container">
			<div className="filter-show-locations">
				<FormSidebar
					isOpen={isFormOpen}
					close={handleCancel}
					submit={handleSubmit}
					loading={confirmLoading}
					title="Select Locations"
					subTitle={
						<>
							<p>Select the locations you want to monitor during the free trial</p>
							<div className="show-locations-container">
								<div className="info-display">
									<InfoIcon styles={{ width: "20", height: "22" }} />
									<div className="info-text">
										<span className="location-note">
											{hasUnlimitedLocationAccess
												? "Platform URLs Required"
												: "You can select up to 5 locations"}
										</span>
										<span className="experience-note">
											{hasUnlimitedLocationAccess
												? "Make sure each location has valid platform URLs to ensure accurate tracking on Periscope."
												: "To ensure the best experience with Periscope, please make sure all locations have URLs added."}
										</span>
									</div>
								</div>
							</div>
							<div className="filters-div">
								<div className="cities">
									<SelectFilter
										placeholder="All Cities"
										options={cities}
										labelKey="valueForDisplay"
										valueKey="value"
										field={"cities"}
										valueForDisplay="City"
										values={cities}
										setFilter={(f, v) => handleCityFilter(v)}
										type="SINGLE_STRICT"
										currValue={{
											value: selectedCity,
											valueForDisplay: (
												<span>{selectedCity?.valueForDisplay || "All Cities"}</span>
											)
										}}
									/>
								</div>
								<SearchFilter
									placeholder="Search"
									filterOption={{
										field: "name"
									}}
									setFilter={handleSearch}
									value={searchQuery}
								/>
							</div>
						</>
					}
					submitTitle="Confirm Selection"
					hideSubmitAction={false}
					disabled={!Object.values(locations || {}).filter((val) => val).length > 0}
					hideActions={!displayBottomButton}
				>
					<div className="children-locations">
						<div className="text-top">
							<Transition
								native
								items={true}
								from={{ opacity: 0, height: 0 }}
								enter={{ opacity: 1, height: "auto" }}
								leave={{ opacity: 0, height: 0 }}
								config={config.stiff}
							>
								{(isOpen) =>
									isOpen &&
									((props) => (
										<animated.div style={props}>
											<Actions
												count={Object.values(locations || {}).filter((val) => val).length || 0}
												sortSelected={sortSelected}
												handleSortDataBtn={handleSortDataBtn}
											/>
										</animated.div>
									))
								}
							</Transition>
						</div>

						<div className="table-div" data-testid="">
							<CustomTable
								loading={periscopeEarlyAccessData?.loading}
								handleCheckAll={handleCheckAll}
								data={dataShown || []}
								columns={columns}
								checkbox={(dataShown || []).length > 0}
								classes="show-locations-table"
								isCheckedAll={isCheckedAll}
								isPartiallyChecked={!hasUnlimitedLocationAccess}
							/>
							{dataCount > 0 && (
								<Paginator
									limit={limit}
									offset={offset}
									count={dataCount || 0}
									showPageSize={true}
									goToPage={handlePagination}
									setPageSize={handlePageSize}
								/>
							)}
						</div>
					</div>
				</FormSidebar>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	configItems: state?.configItems,
	isMultibrandEnabled: state.login?.loggedInbizDetail?.isMultibrandEnabled,
	login: state.login,
	periscopeEarlyAccessData: state.periscopeEarlyAccessData,
	periscopeCountryWiseSupportedPlatforms: state.periscopeCountryWiseSupportedPlatforms,
	periscopeEarlyAccessState: state.periscopeEarlyAccessState
});

export default connect(mapStateToProps)(ShowLocations);

const Actions = ({ archived = false, count = 0, handleSortDataBtn, sortSelected }) => {
	return (
		<div className="bulk-actions">
			<span>{count > 0 ? <>{count} location(s) selected</> : ""}</span>
			{/* {count ? (
				<span>
					<CheckBox checked={sortSelected} clickHandler={() => handleSortDataBtn()} title={""}>
						<span className="title-display">Show selected on top</span>
					</CheckBox>
				</span>
			) : (
				""
			)} */}
		</div>
	);
};

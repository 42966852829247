import gql from "graphql-tag";

export const GET_MODIFIER_GROUPS_LIST = gql`
	query getModifierGroups(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
	) {
		modifierGroups(limit: $limit, offset: $offset, filters: $filters, sort: $sort, search: $search) {
			objects {
				id
				optionGroupTitle
				optionGroupDesc
				optionGroupMinSelectable
				optionGroupMaxSelectable
				isVariant
				merchantRefId
				crmTitle
				sortOrder
				modifiersCount
				itemsCount
			}
			searchKeywords {
				key
				valueForDisplay
			}
			count
			hasPrevious
			hasNext
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
				hide
			}
		}
	}
`;

export const CREATE_MODIFIER_GROUP = gql`
	mutation createModifierGroup(
		$min: Int
		$max: Int
		$title: String!
		$merchantRefId: String
		$crmTitle: String
		$sortOrder: Int
		$description: String
		$isDefault: Boolean
		$treatAsAddon: Boolean
		$multiOptionsEnabled: Boolean
	) {
		saveModifierGroup(
			input: {
				optionGroupMinSelectable: $min
				optionGroupMaxSelectable: $max
				optionGroupTitle: $title
				merchantRefId: $merchantRefId
				crmTitle: $crmTitle
				sortOrder: $sortOrder
				optionGroupDesc: $description
				isDefault: $isDefault
				treatAsAddon: $treatAsAddon
				multiOptionsEnabled: $multiOptionsEnabled
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				optionGroupTitle
				optionGroupDesc
				optionGroupMinSelectable
				optionGroupMaxSelectable
				isVariant
				merchantRefId
				crmTitle
				sortOrder
				modifiersCount
				itemsCount
			}
		}
	}
`;

export const GET_MODIFIER_GROUP = gql`
	query fetchModifierGroup($id: Int) {
		modifierGroup(id: $id) {
			id
			optionGroupTitle
			optionGroupDesc
			optionGroupMaxSelectable
			optionGroupMinSelectable
			isVariant
			merchantRefId
			crmTitle
			sortOrder
			isEnabled
			modifiersCount
			itemsCount
			isDefault
			treatAsAddon
			multiOptionsEnabled
		}
	}
`;

export const GET_MENU_SERVICE_MODIFIER_GROUP_DETAILS = gql`
	query fetchMenuServiceModifierGroupDetails($menuId: String!, $optionGroupId: String!) {
		optionGroup(menuId: $menuId, optionGroupId: $optionGroupId) {
			id
			name
			description
			minSelectable
			maxSelectable
			isVariant
			merchantRefId
			crmName
			sortOrder
			isEnabled
			isDefault
			treatAsAddon
			multiOptionsEnabled
			overridenFields
			translations {
				language
				name
				description
			}
		}
	}
`;

export const GET_MODIFIER_GROUP_ITEMS = gql`
	query getModifierGroupItems($id: Int, $limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		modifierGroup(id: $id) {
			paginatedItems(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
				count
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						valueForDisplay
						value
					}
				}
				objects {
					id
					itemTitle
					itemPrice
					crmTitle
					category
					foodType
					tags
				}
			}
		}
	}
`;

export const GET_MODIFIER_GROUP_ITEMS_FROM_MENU_SERVICE = gql`
	query getOptionGroupItems(
		$menuId: String!
		$optionGroupId: String!
		$limit: Int
		$offset: Int
		$filters: ItemFilterInput!
	) {
		optionGroup(menuId: $menuId, optionGroupId: $optionGroupId) {
			id
			items(limit: $limit, offset: $offset, filters: $filters) {
				objects {
					id
					name
					price
					crmName
					category {
						id
						name
					}
					foodType
					tags
				}
				totalObjectCount
			}
		}
	}
`;

export const GET_MODIFIER_GROUP_MODIFIERS = gql`
	query getModifierGroupModifiers(
		$id: Int
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
	) {
		modifierGroup(id: $id) {
			paginatedModifiers(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
				count
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						valueForDisplay
						value
					}
				}
				objects {
					id
					title
					price
					foodType
					crmTitle
				}
			}
		}
	}
`;

export const GET_MODIFIER_GROUP_MODIFIERS_FROM_MENU_SERVICE = gql`
	query getOptionGroupOptions(
		$menuId: String!
		$optionGroupId: String!
		$limit: Int!
		$offset: Int!
		$filters: OptionFilterInput!
	) {
		optionGroup(menuId: $menuId, optionGroupId: $optionGroupId) {
			id
			options(limit: $limit, offset: $offset, filters: $filters) {
				objects {
					id
					name
					price
					foodType
					crmName
					nestedOptionGroups(limit: $limit, offset: $offset) {
						objects {
							id
							name
							description
							minSelectable
							maxSelectable
							isVariant
							crmName
							options(limit: $limit, offset: $offset) {
								objects {
									id
									name
									crmName
									price
									foodType
									nestedOptionGroups(limit: $limit, offset: $offset) {
										objects {
											id
											name
											description
											minSelectable
											maxSelectable
											isVariant
											crmName
											options(limit: $limit, offset: $offset) {
												objects {
													id
													name
													crmName
													price
													foodType
												}
												totalObjectCount
											}
										}
										totalObjectCount
									}
								}
								totalObjectCount
							}
						}
						totalObjectCount
					}
				}
				totalObjectCount
			}
		}
	}
`;
export const GET_MODIFIER_GROUP_PARENT_MODIFIERS = gql`
	query getModifierGroupParentModifiers(
		$id: Int
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
	) {
		modifierGroup(id: $id) {
			parentModifiers(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
				count
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						valueForDisplay
						value
					}
				}
				objects {
					id
					title
					price
					foodType
					crmTitle
				}
			}
		}
	}
`;

export const EDIT_MODIFIER_GROUP = gql`
	mutation editModifierGroup(
		$id: Int
		$optionGroupTitle: String!
		$optionGroupDesc: String
		$optionGroupMinSelectable: Int
		$optionGroupMaxSelectable: Int
		$merchantRefId: String
		$crmTitle: String
		$sortOrder: Int
		$isDefault: Boolean
		$isEnabled: Boolean
		$treatAsAddon: Boolean
		$multiOptionsEnabled: Boolean
	) {
		saveModifierGroup(
			input: {
				id: $id
				optionGroupTitle: $optionGroupTitle
				optionGroupDesc: $optionGroupDesc
				optionGroupMinSelectable: $optionGroupMinSelectable
				optionGroupMaxSelectable: $optionGroupMaxSelectable
				merchantRefId: $merchantRefId
				crmTitle: $crmTitle
				sortOrder: $sortOrder
				isDefault: $isDefault
				isEnabled: $isEnabled
				treatAsAddon: $treatAsAddon
				multiOptionsEnabled: $multiOptionsEnabled
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				optionGroupTitle
				optionGroupDesc
				optionGroupMinSelectable
				optionGroupMaxSelectable
				isVariant
				merchantRefId
				crmTitle
				sortOrder
				modifiersCount
				itemsCount
			}
		}
	}
`;

import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";

// components
import { FormContainer } from "../../containers/Meraki";

// utils
import { client } from "../../client";
import { store } from "../../store/configureStore";
import { capitaliseText, openFreshchatWidget } from "../../atlas-utils";

// actions
import { ActionTypes } from "../../actions/_types";
import NotificationServices from "../../services/NotificationService";
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
import { Button } from "../_commons/Button";
import { CheckBox } from "../_commons/CheckBox";
import { GET_OUATH_URL } from "../../graphql/customIntegrations";
import { ButtonIcon } from "../_commons/ButtonIcon";

const CustomPOSConnections = ({ POSDetails }) => {
	const [isDisclaimerChecked, setIsDisclaimerChecked] = useState(false);
	const [statusOAuth, setStatusOAuth] = useState(null);
	const { posName, posLogo, posOauthHelpUrl, oauthStatus, connectionStatus } = POSDetails;
	const handleSubmit = async () => {
		try {
			const resp = await client.mutate({
				mutation: GET_OUATH_URL
			});

			if (resp.data.initiateOauth.status === true) {
				const signedUrl = resp.data.initiateOauth.signedUrl;
				window.open(signedUrl, "_self");
			} else {
				NotificationServices.pushNotification({
					message: resp.data.initiateOauth.message,
					timeout: 5000,
					type: "error"
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};
	const getURLParams = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const oAuthStatus = urlParams.get("status_oauth");
		setStatusOAuth(oAuthStatus);
	};

	useEffect(() => {
		getURLParams();
	}, []);
	const handleRedirectToDoc = () => {
		const url = posOauthHelpUrl ?? null;

		if (url) {
			window.open(url, "_blank").focus();
		}
	};
	return (
		<div className="custom-POS-container">
			{statusOAuth !== "failed" && <div className="section-header">Your POS Service</div>}

			<div>
				{oauthStatus === "success" ? (
					<div className="card-container">
						<div className="card-header">
							<div className="header-container">
								<div className="app-icon">
									<img src={posLogo} alt="" />
								</div>
								<div className="app-header">
									<div className="header-text">{posName}</div>
									<div className="header-subtext">POS service</div>
								</div>
							</div>
							<div className="POS-status POS-connection-success">
								<div className="text">{capitaliseText(connectionStatus)}</div>
							</div>
						</div>
					</div>
				) : statusOAuth === "failed" ? (
					<div className="pos-connection-failed">
						<div className="failure-container-wrapper">
							<div className="error-icon">
								<img src="/assets/icons/error-red-icon.svg" alt="" />
							</div>
							<div className="error-heading">Authorization failed</div>
							<div className="error-description">
								We were unable to connect to your POS system. Kindly review your POS configuration and
								try again. If the issue persists, please contact our support.
							</div>
							<div className="button-container">
								<div className="action-buttons-container">
									<Button onClick={handleSubmit}>
										<span>Try again</span>
									</Button>
								</div>
								<div className="action-buttons-container">
									<Button
										type="secondary"
										onClick={() => {
											window.location.href = "/integrations";
										}}
									>
										<span>Dismiss</span>
									</Button>
								</div>
							</div>
						</div>
					</div>
				) : (
					<FormContainer
						submit={handleSubmit}
						submitTitle="Authorize"
						hideActions={oauthStatus === "success"}
						hideCancelAction
						disableSubmit={!isDisclaimerChecked && posOauthHelpUrl}
					>
						<div className="form-content">
							<div className={"pos-authorization"}>
								<div className="card-container">
									<div className="header">
										<div className="platforms">
											<div className="platform" title={capitaliseText(posName)}>
												<img src={posLogo || "/assets/icons/icons8-globe-40.png"} alt="" />
											</div>
											<div className="integration">
												<img src="/assets/icons/icon-integrate.svg" alt="" />
											</div>
											<div className="platform" title="UrbanPiper">
												<img src={CATALOGUE_PLATFORMS_LOGO["urbanpiper"]} alt="" />
											</div>
										</div>
										<div className="integration-heading">
											Integrate with {capitaliseText(posName)}
										</div>
									</div>
									<div className="content">
										<div className="description">With this integration you will be able to:</div>
										<div className="benifits">
											<div className="point">
												<img src="/assets/icons/menu-new.svg" alt="" />
												<div className="text">Import and sync your menu</div>
											</div>
											<div className="point">
												<img src="/assets/icons/cart-new.svg" alt="" />
												<div className="text">Get online orders on your POS</div>
											</div>
											<div className="point">
												<img src="/assets/icons/inventory.svg" alt="" />
												<div className="text">Update items and stock from your POS</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							{posOauthHelpUrl && (
								<div className="pos-disclaimer">
									<div className="disclaimer-container">
										<div className="disclaimer-header">
											<img src="/assets/icons/info-black.png" alt="" />
											<div className="disclaimer-text">
												{" "}
												Configure {capitaliseText(posName)} for UrbanPiper
											</div>
										</div>
										<div className="disclaimer-description">
											To integrate with UrbanPiper, you'll need to complete a quick setup on{" "}
											{capitaliseText(posName)}. This process involves a few simple steps that are
											required to sync your menu and relay orders. It’s a one-time setup that must
											be done before moving forward.
										</div>
										<div className="button-container">
											<div className="action-buttons-container">
												<Button onClick={handleRedirectToDoc}>
													<span>Show me how</span>
													<ButtonIcon icon="redirect" color="white" />
												</Button>
											</div>
											<div className="action-buttons-container">
												<Button type="secondary" onClick={openFreshchatWidget}>
													<span>I need help</span>
												</Button>
											</div>
										</div>
										<div className="disclaimer-check">
											<CheckBox
												checked={isDisclaimerChecked}
												clickHandler={() => setIsDisclaimerChecked(!isDisclaimerChecked)}
											>
												<span className="text">I have done the changes on my POS</span>
											</CheckBox>
										</div>
									</div>
								</div>
							)}
						</div>
					</FormContainer>
				)}
			</div>
		</div>
	);
};

export default CustomPOSConnections;

import gql from "graphql-tag";

export const GET_MODIFIERS_LIST = gql`
	query getModifiers(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
	) {
		modifiers(limit: $limit, offset: $offset, filters: $filters, sort: $sort, search: $search) {
			objects {
				id
				optionTitle
				optionPrice
				crmTitle
				foodType
				optionOrder
				modifierGroupsCount
				numAssociatedLocations
				tags
			}
			searchKeywords {
				key
				valueForDisplay
			}
			count
			hasPrevious
			hasNext
			filters {
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
				hide
			}
		}
	}
`;

export const CREATE_MODIFIER = gql`
	mutation createModifier(
		$optionTitle: String
		$optionPrice: Float
		$foodType: FoodTypeType
		$optionOrder: Int
		$crmTitle: String
		$tags: [String]
		$nestedOptionGroupIds: [Int]
		$merchantRefId: String
		$optionDesc: String
		$optionWeight: Float
	) {
		saveModifier(
			input: {
				optionTitle: $optionTitle
				optionPrice: $optionPrice
				foodType: $foodType
				optionOrder: $optionOrder
				crmTitle: $crmTitle
				tags: $tags
				nestedOptionGroupIds: $nestedOptionGroupIds
				merchantRefId: $merchantRefId
				optionDesc: $optionDesc
				optionWeight: $optionWeight
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				optionTitle
				optionPrice
				crmTitle
				foodType
				optionOrder
				modifierGroupsCount
				tags
			}
		}
	}
`;

export const GET_MODIFIER = gql`
	query fetchModifier($id: Int) {
		modifier(id: $id) {
			id
			optionTitle
			optionPrice
			crmTitle
			merchantRefId
			foodType
			tags
			optionOrder
			modifierGroupsCount
			optionDesc
			optionWeight
			isEnabled
			numAssociatedLocations
			nestedOptionGroups {
				id
				optionGroupTitle
			}
			optionGroups {
				id
				optionGroupTitle
			}
		}
	}
`;

export const GET_MODIFIER_MENU_SERVICE = gql`
	query fetchModifierDetailsFromMenuService($menuId: String!, $optionId: String!) {
		option(menuId: $menuId, optionId: $optionId) {
			id
			name
			price
			crmName
			merchantRefId
			foodType
			tags
			sortOrder
			description
			isEnabled
			imageUrl
			isRecommended
			overridenFields
			translations {
				language
				name
				description
			}
		}
	}
`;

export const GET_MODIFIER_LOCATIONS = gql`
	query getModifierLocations($id: Int, $name: String, $limit: Int, $offset: Int, $city: String, $brandId: Int) {
		modifier(id: $id) {
			id
			numAssociatedLocations
			associatedCities
			associatedLocations(name: $name, limit: $limit, offset: $offset, city: $city, brandId: $brandId) {
				location {
					id
					name
					city
					tags
				}
				price
				available
				currentStock
			}
		}
	}
`;

export const GET_MODIFIER_MODIFIER_GROUPS = gql`
	query getModifierModifierGroups($id: Int, $limit: Int, $offset: Int) {
		modifier(id: $id) {
			id
			nestedOptionGroups {
				id
				optionGroupTitle
			}
			modifierGroups(limit: $limit, offset: $offset) {
				count
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						valueForDisplay
						value
					}
				}
				objects {
					id
					optionGroupTitle
					optionGroupDesc
					optionGroupMaxSelectable
					optionGroupMinSelectable
					isVariant
				}
			}
		}
	}
`;

export const GET_MODIFIER_MODIFIER_GROUPS_MENU_SERVICE = gql`
	query fetchModifierDetailsFromMenuService($menuId: String!, $optionId: String!, $limit: Int!, $offset: Int!) {
		option(menuId: $menuId, optionId: $optionId) {
			id
			parentOptionGroups(limit: $limit, offset: $offset) {
				objects {
					id
					name
					description
					isVariant
					maxSelectable
				}
				totalObjectCount
			}
		}
	}
`;

export const GET_MODIFIER_NESTED_MODIFIER_GROUPS = gql`
	query getModifierNestedModifierGroups($id: Int, $limit: Int, $offset: Int) {
		modifier(id: $id) {
			id
			optionGroups {
				id
				optionGroupTitle
			}
			nestedModifierGroups(limit: $limit, offset: $offset) {
				count
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						valueForDisplay
						value
					}
				}
				objects {
					id
					optionGroupTitle
					optionGroupDesc
					optionGroupMaxSelectable
					optionGroupMinSelectable
					isVariant
				}
			}
		}
	}
`;

export const GET_MENU_SERVICE_MODIFIER_NESTED_MODIFIER_GROUPS = gql`
	query fetchModifierDetailsFromMenuService($menuId: String!, $optionId: String!, $limit: Int!, $offset: Int!) {
		option(menuId: $menuId, optionId: $optionId) {
			id
			parentOptionGroups(limit: $limit, offset: $offset) {
				objects {
					id
					name
				}
			}
			nestedOptionGroups(limit: $limit, offset: $offset) {
				objects {
					id
					name
					description
					isVariant
					maxSelectable
					isVariant
					crmName
					options(limit: $limit, offset: $offset) {
						objects {
							id
							name
							crmName
							price
							foodType
							nestedOptionGroups(limit: $limit, offset: $offset) {
								objects {
									id
									name
									description
									minSelectable
									maxSelectable
									isVariant
									crmName
									options(limit: $limit, offset: $offset) {
										objects {
											id
											name
											crmName
											price
											foodType
											nestedOptionGroups(limit: $limit, offset: $offset) {
												objects {
													id
													name
													description
													minSelectable
													maxSelectable
													isVariant
													crmName
													options(limit: $limit, offset: $offset) {
														objects {
															id
															name
															crmName
															price
															foodType
														}
														totalObjectCount
													}
												}
												totalObjectCount
											}
										}
										totalObjectCount
									}
								}
								totalObjectCount
							}
						}
						totalObjectCount
					}
				}
				totalObjectCount
			}
		}
	}
`;

export const UPDATE_MODIFIER_LOCATION_FIELDS = gql`
	mutation updateModifierLocationFields($optionId: Int!, $olas: [ItemOptionLocationAssociationFieldsInputType]!) {
		updateModifierLocationFields(input: { optionId: $optionId, olas: $olas }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const EDIT_MODIFIER = gql`
	mutation editModifier(
		$id: Int
		$optionTitle: String
		$optionPrice: Float
		$foodType: FoodTypeType
		$optionOrder: Int
		$tags: [String]
		$isEnabled: Boolean
		$crmTitle: String
		$merchantRefId: String
		$nestedOptionGroupIds: [Int]
		$optionDesc: String
		$optionWeight: Float
		$locationsToUpdate: [Int]
	) {
		saveModifier(
			input: {
				id: $id
				optionTitle: $optionTitle
				optionPrice: $optionPrice
				foodType: $foodType
				optionOrder: $optionOrder
				tags: $tags
				isEnabled: $isEnabled
				crmTitle: $crmTitle
				merchantRefId: $merchantRefId
				nestedOptionGroupIds: $nestedOptionGroupIds
				optionDesc: $optionDesc
				optionWeight: $optionWeight
				locationsToUpdate: $locationsToUpdate
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				optionTitle
				optionPrice
				crmTitle
				foodType
				optionOrder
				modifierGroupsCount
				tags
			}
		}
	}
`;

export const UPDATE_MODIFIER_SPECIFIC_DETAILS = gql`
	mutation updateModifierAvailability(
		$optionId: Int!
		$olas: [ItemOptionLocationAssociationFieldsInputType]!
		$associateAll: Boolean
		$disassociateAll: Boolean
		$filters: [ListFilterArgument]
	) {
		updateModifierLocationFields(
			input: {
				optionId: $optionId
				olas: $olas
				associateAll: $associateAll
				disassociateAll: $disassociateAll
				filters: $filters
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_MODIFIER_CUSTOM_FIELDS = gql`
	query getModifierCustomFields($id: Int) {
		modifier(id: $id) {
			id
			keyValuesGroups {
				id
				name
				keyValues {
					id
					key
					value
				}
			}
		}
	}
`;

export const UPDATE_MODIFIER_CUSTOM_FIELDS = gql`
	mutation updateModifierCustomFields(
		$id: Int
		$keyValuesToInsert: [ModelKVGroupInput]
		$keyValuesToEdit: [ModelKVGroupEditInput]
		$keyValuesToDelete: ModelKVGroupDeleteInput
	) {
		saveModifier(
			input: {
				id: $id
				keyValuesToInsert: $keyValuesToInsert
				keyValuesToEdit: $keyValuesToEdit
				keyValuesToDelete: $keyValuesToDelete
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				keyValuesGroups {
					id
					name
					keyValues {
						id
						key
						value
					}
				}
			}
		}
	}
`;

export const GET_MODIFIER_PLATFORM_IMAGES = gql`
	query getModifierPlatformImages($id: Int) {
		modifier(id: $id) {
			id
			platformImages {
				id
				image
				platforms
			}
		}
	}
`;

export const GET_MODIFIER_NON_PLATFORM_IMAGES = gql`
	query getModifierNonPlatformImages($id: Int) {
		modifier(id: $id) {
			id
			nonPlatformImages {
				image
				id
			}
		}
	}
`;

export const UPLOAD_MODIFIER_IMAGE = gql`
	mutation uploadModifierImage($entityId: Int!, $entityType: String!, $tags: [String], $image: Upload!) {
		saveImage(entityId: $entityId, entityType: $entityType, tags: $tags, image: $image) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const DELETE_IMAGE = gql`
	mutation deleteModifierImage($imageIds: [Int]!) {
		deleteImage(imageIds: $imageIds) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

import React from "react";

// components
import { CustomTable } from "../_commons/CustomTable";
import { Paginator } from "../_commons/Paginator";
import { Filters } from "./LocationsAndMenu";

// constants
import { getColumns } from "./LocationsMapping";
import { PLATFORM_MAP } from "../Hub/Integrations";

const ReviewLocationsMapping = ({
	dsp,
	dspIntegration = {},
	stores,
	handleSearchFilter,
	handleStatusFilter,
	handlePagination,
	handlePageSize,
	handleAssociationUpdates,
	mappedLocations = {},
	editMode = true,
	isMultibrandEnabled = false,
	citiesListState,
	searchCitiesDebounced,
	handleCheck,
	handleCheckAll,
	isCheckedAll = false,
	validations,
	renderBrandSelection
}) => {
	const { loading, limit, offset, data, searchFieldValue, appliedFilters, associationUpdates, selectedBrand } =
		dspIntegration;
	return (
		<div className="form-content">
			<Filters
				showStoreStatus={false}
				brandEdit={true}
				searchFieldValue={searchFieldValue}
				handleSearchFilter={handleSearchFilter}
				statusFilter={data?.filters?.find((f) => f.field === "chefz_status") || {}}
				handleStatusFilter={handleStatusFilter}
				statusValue={appliedFilters["chefz_status"] || null}
				renderBrandSelection={renderBrandSelection}
				isMultibrandEnabled={isMultibrandEnabled}
			/>
			<div className="locations-table">
				<CustomTable
					bordered={true}
					loading={loading}
					data={data?.objects || []}
					checkbox={true}
					columns={getColumns(
						dsp,
						editMode,
						isMultibrandEnabled,
						selectedBrand,
						citiesListState,
						searchCitiesDebounced
					)}
					classes="locations-mapping-table-container"
					content="locations"
					showLoadingForce={loading}
					stores={stores}
					mappedLocations={mappedLocations}
					platform={dsp}
					handleCheck={handleCheck}
					handleCheckAll={handleCheckAll}
					isCheckedAll={isCheckedAll}
					validations={validations}
					associationUpdates={associationUpdates}
					handleAssociationUpdates={handleAssociationUpdates}
					hideColumns={["status"]}
				/>
			</div>
			<Paginator
				limit={limit}
				offset={offset}
				count={data?.count || 0}
				goToPage={handlePagination}
				setPageSize={handlePageSize}
				showPageSize={true}
			/>
		</div>
	);
};
export default ReviewLocationsMapping;

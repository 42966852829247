import React, { useState, useEffect, useCallback, useReducer, useRef, useImperativeHandle } from "react";

import AdditionalInfo from "../components/ItemEdit/AdditionalInfo";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";
import { useLocation } from "react-router-dom";
import SatismeterService from "../services/SatismeterService";

// components
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BasicInfo from "../components/ItemEdit/BasicInfo";
import AssociatedLocations from "../components/ItemEdit/AssociatedLocations";
import AssociatedPlatforms from "../components/ItemEdit/AssociatedPlatforms";
import RelatedItems from "../components/ItemEdit/RelatedItems";
import ModifierGroups from "../components/ItemEdit/ModifierGroups";
import TaxesAndCharges from "../components/ItemEdit/TaxesAndCharges";
import CustomFields from "../components/ItemEdit/CustomFields";
import Images from "../components/ItemEdit/Images";
import LightningIcon from "../components/_icons/LightningIcon";
import EntityRulesAssociationList from "../components/EntityAssociations/EntityRulesAssociationList";
import ItemGroups from "../components/ItemEdit/ItemGroups";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import {
	removeProp,
	scroll,
	adjustNestedContainer,
	findChangedKeysInTwoObjects,
	trackEvent,
	capitaliseText,
	createTranslationsObject
} from "../atlas-utils";

// graphql
import { GET_ITEM_TAGS } from "../graphql/items";
import { GET_BIZ_PLATFORMS } from "../graphql/locations";

// actions
import {
	fetchCiItemGroups,
	fetchFulfillmentModes,
	fetchItemCategories,
	fetchTimingGroups,
	fetchTranslation,
	updateTranslation
} from "../actions/actions";
import {
	fetchItemsList,
	fetchItemDetail,
	editItem,
	verifyHandle,
	updateItemLocationFields,
	updateItemPlatforms,
	fetchMenuServiceItemDetail
} from "../actions/items";
import { ActionTypes } from "../actions/_types";

// reducers
import { itemDetailsReducer, ITEM_DETAILS_INITIAL_STATE } from "../reducers/items";

// assets
import HelpIcon from "../components/_commons/HelpIcon";

// constants
import {
	FOOD_TYPE_MAP,
	NESTED_ENTITY_TYPES,
	TRACKING_EVENT_NAMES,
	TRACKING_MISC,
	TRACKING_SOURCE,
	TRACKING_STATUS
} from "../client-config";
import { DRAGGABLE_ENTITY_TYPES } from "../components/_commons/DraggableModifierAccordion";
import Logs from "../components/ItemEdit/Logs";
import { languages } from "prismjs/components/prism-core";
import { transactionsList } from "../reducers/transactions";

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Images",
		value: "images"
	},
	{
		label: "Item Groups",
		value: "itemGroups"
	},
	{
		label: "Taxes & Charges",
		value: "taxesAndCharges"
	},
	{
		label: "Locations",
		value: "locations"
	},
	{
		label: "Platforms",
		value: "platforms"
	},
	{
		label: "Modifier Groups",
		value: "modifierGroups"
	},
	{
		label: "Recommended Items",
		value: "recommendedItems"
	},
	{
		label: "Custom Fields",
		value: "customFields"
	},
	{
		label: "Logs",
		value: "logs"
	}
];

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const TRANSLATION_FIELDS_MAP = {
	itemTitle: "item_title",
	itemDesc: "item_desc"
};

const ItemEdit = ({
	match,
	biz,
	access,
	readOnly = false,
	supportedLanguages = [],
	isNested = false,
	isForeignSource = false,
	closeNestedContainer,
	connectedRef,
	brands,
	id = null,
	sectionId = null,
	isFromMenuSection = false,
	close = () => {},
	handleItemOverridesUpdate = () => {},
	menuBrandId,
	isFromPreviewSection = false,
	message = {},
	handleModifierSortOrderUpdate = () => {},
	menuDetailsData = {},
	handleEntityDissociation = () => {}
}) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormTouched, setFormTouched] = useState(false);
	const [isLocationsFormTouched, setLocationsFormTouched] = useState(false);
	const [isPlatformsFormTouched, setPlatformsFormTouched] = useState(false);
	const [isFormOpen, setFormState] = useState(false);
	const [currSelectedLang, setCurrSelectedLang] = useState({
		itemTitle: {
			lang: supportedLanguages.length ? supportedLanguages[0].value : "",
			value: "",
			showActionBtn: false
		},
		itemDesc: {
			lang: supportedLanguages.length ? supportedLanguages[0].value : "",
			value: "",
			showActionBtn: false
		}
	});

	const [itemTranslations, setItemTranslations] = useState({});
	const [translationLoader, setTranslationLoader] = useState(false);
	const [itemDetails, dispatch] = useReducer(itemDetailsReducer, ITEM_DETAILS_INITIAL_STATE);
	const [itemTags, setItemTags] = useState([]);
	const [itemDataCount, setItemDataCount] = useState(0);
	const [isModalBusy, setModalBusy] = useState(false);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [itemLocationFields, setItemLocationFields] = useState({});
	const [itemPlatformsAssociation, setItemPlatformsAssociation] = useState({});
	const [bizPlatformsList, setBizPlatformsList] = useState([]);
	const [cascadePriceModalOpen, setCascadePriceModalOpen] = useState(false);
	const [isDrawerExpanded, setIsDrawerExpanded] = useState(false);

	const { loading, data, error, backupData } = itemDetails;

	const nestedRef = useRef();
	const [menuItemInfo, setMenuItemInfo] = useState({});
	const [reOrderedMenuModifierGroups, setReOrderedMenuModifierGroups] = useState([]);
	const [isMenuUpdating, setIsMenuUpdating] = useState(false);

	const { pathname } = useLocation();
	const isViewedFromMenuSection = pathname.includes("/menus/edit/");
	const pathnameEntities = pathname.split("/");
	const menuId = match?.params?.menuId || pathnameEntities[pathnameEntities.length - 1];
	const isMenuOverCatalogueEnabled = biz?.isMenuOverCatalogueEnabled || false;
	const [refresh, setRefresh] = useState(false);

	const MENU_SERVICE_FORM_TABS = [
		{
			label: "Basic Information",
			value: "basic"
		},
		{
			label: (
				<span style={{ position: "relative" }}>
					Rules{" "}
					<span style={{ position: "absolute" }}>
						<LightningIcon fillColor={formTab === "rules" ? "#2f58f2" : "#D0D0D0"} />
					</span>
				</span>
			),
			value: "rules"
		},
		{
			label: "Images",
			value: "images"
		},
		{
			label: "Modifier Groups",
			value: "modifierGroups"
		}
	];

	const handleNestedEntity = useCallback((toOpen = false, type, id, tab = null) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
			if (type === "modifier-group") {
				setRefresh(true);
			}
		} else {
			setNestedEntity({
				show: true,
				type,
				id,
				tab
			});
			setModalBusy(true);
			setRefresh(false);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		if (isFromMenuSection || isFromPreviewSection) {
			return;
		}
		setTimeout(() => setFormState(true), 60);
		fetchItemDetail(parseInt(match.params.id), dispatch);
		fetchItemCategories();
		fetchCiItemGroups("", false);
	}, [match?.params?.id]);

	useEffect(() => {
		if ((isFromMenuSection && id) || (isFromPreviewSection && id)) {
			setFormState(true);
			fetchMenuServiceItemDetail(dispatch, menuId, sectionId, id);
		}
	}, [id, sectionId]);

	useEffect(() => {
		fetchBizPlatformsList();
		fetchTimingGroups();
		fetchFulfillmentModes();
	}, []);
	useEffect(() => {
		setItemTranslations(
			data?.translations?.reduce((acc, curr) => {
				acc[curr.language] = { language: curr.language, name: curr.name, description: curr.description };
				return acc;
			}, {})
		);
		setMenuItemInfo({
			isRecommended: data?.isRecommended || false,
			name: data?.name || "",
			price: data?.price || 0,
			description: data?.description || "",
			imageUrl: data?.imageUrl,
			markupPrice: data?.markupPrice,
			translations: data?.translations
				? Object.entries(data.translations).map(([key, translation]) => {
						return {
							language: translation.language,
							name: translation.name,
							description: translation.description
						};
					})
				: []
		});
	}, [data]);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			handleModal();
			setFormState(false);
			setFormTab(FORM_TABS[0].value);
			setTimeout(() => {
				if (isNested || isForeignSource) {
					closeNestedContainer();
				} else {
					if (isFromMenuSection) {
						setFormState(false);
						close();
						return;
					}
					fetchItemsList(brands?.selectedBrand?.id);
					history.push("/items");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const applyFilter = useCallback(
		debounce(async (value) => {
			const res = await verifyHandle(value);
			setItemDataCount(res);
		}, 500),
		[verifyHandle]
	);

	const fetchItemTags = async (searchText = "") => {
		try {
			const variables = {
				limit: 200,
				offset: 0,
				sort: { field: "title", order: "ASC" }
			};
			if (searchText !== "") {
				variables.search = [{ key: "default", value: searchText }];
			}
			const resp = await client.query({
				query: GET_ITEM_TAGS,
				variables
			});
			// get unique tag groups and its tags
			let tagGroups = {};
			resp.data.itemTags.objects.forEach((tag) => {
				tag = {
					...tag,
					titleForDisplay: tag?.tagGroup ? `${tag.title} (${tag?.tagGroup?.title})` : `${tag.title} (General)`
				};
				if (tagGroups[tag.tagGroup ? tag.tagGroup.title : "General"]) {
					tagGroups[tag.tagGroup ? tag.tagGroup.title : "General"].push(tag);
				} else {
					tagGroups[tag.tagGroup ? tag.tagGroup.title : "General"] = [tag];
				}
			});
			// combine all tag groups' tags using concat
			return [].concat.apply([], Object.values(tagGroups));
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
			return { options: [] };
		}
	};

	const fetchBizPlatformsList = async () => {
		try {
			const variables = {
				limit: 10,
				offset: 0,
				includeUrbanpiper: true
			};
			const resp = await client.query({
				query: GET_BIZ_PLATFORMS,
				variables,
				fetchPolicy: "no-cache"
			});
			setBizPlatformsList(resp.data.bizPlatforms.objects);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	};

	const handleForm = (field, value) => {
		if (isFromMenuSection) {
			setFormTouched(true);
			if (field === "name") {
				setItemTranslations((prev) => ({
					...prev,
					[currSelectedLang?.itemTitle?.lang]: {
						...prev[currSelectedLang?.itemTitle.lang],
						[field]: value
					}
				}));
				setTimeout(
					() =>
						setMenuItemInfo((current) => ({
							...current,
							translations: createTranslationsObject(itemTranslations)
						})),
					25
				);
				if (currSelectedLang?.itemTitle?.lang !== "en") {
					return;
				}
			}
			if (field === "description") {
				setItemTranslations((prev) => ({
					...prev,
					[currSelectedLang?.itemDesc?.lang]: {
						...prev[currSelectedLang?.itemDesc?.lang],
						[field]: value
					}
				}));
				setTimeout(
					() =>
						setMenuItemInfo((current) => ({
							...current,
							translations: createTranslationsObject(itemTranslations)
						})),
					25
				);
				if (currSelectedLang?.itemDesc?.lang !== "en") {
					return;
				}
			}
			setMenuItemInfo((current) => ({
				...current,
				[field]: value
			}));
			if (field === "image") {
				trackEvent(TRACKING_EVENT_NAMES.MENU_ITEMS_IMAGE_UPDATE, {
					image_size: value?.size / 1000000
				});
			}

			return;
		}
		// enforce positive value for these fields
		if (["sortOrder", "itemPrice", "markupPrice"].includes(field)) {
			if (value && value < 0) {
				value = 0;
			}
		}
		dispatch({
			type: ActionTypes.UPDATE_CATALOGUE_ITEM_DETAIL,
			payload: {
				[field]: field === "itemGroups" ? { objects: value } : value
			}
		});
		if (formTab === FORM_TABS[0].value && !isFormTouched) {
			setFormTouched(true);
		}
	};

	const resetCurrentLang = () => {
		setCurrSelectedLang({
			name: {
				lang: supportedLanguages.length ? supportedLanguages[0].value : "",
				value: "",
				showActionBtn: false
			},
			description: {
				lang: supportedLanguages.length ? supportedLanguages[0].value : "",
				value: "",
				showActionBtn: false
			}
		});
		setItemTranslations({});
	};

	const handleItemLocationFields = (id, object) => {
		setItemLocationFields({
			...itemLocationFields,
			[id]: object
		});
		if (!isLocationsFormTouched) {
			setLocationsFormTouched(true);
		}
	};

	const handleItemPlatformAssociation = (id, isAssociated) => {
		setItemPlatformsAssociation({
			...itemPlatformsAssociation,
			[id]: isAssociated
		});
		if (!isPlatformsFormTouched) {
			setPlatformsFormTouched(true);
		}
	};

	const updateItemPlatformAssociation = useCallback(async () => {
		const variables = {
			id: parseInt(match.params.id),
			availablePlatforms: Object.keys(itemPlatformsAssociation).map((id) => ({
				id,
				available: itemPlatformsAssociation[id]
			}))
		};

		let activeMerakiPlatformBefore = 0;
		let activeMerakiPlatformAfter = 0;
		let activeHubPlatformsBefore = 0;
		let activeHubPlatformsAfter = 0;

		if (data?.platforms?.length) {
			data.platforms.forEach((plat) => {
				const targetPlatform = variables.availablePlatforms.find((p) => p.id === plat.id);
				if (plat.platformName === "Urbanpiper") {
					if (targetPlatform) {
						activeMerakiPlatformBefore = plat.isItemAssociated ? 1 : 0;
						activeMerakiPlatformAfter = targetPlatform.available ? 1 : 0;
					} else {
						activeMerakiPlatformBefore = plat.isItemAssociated ? 1 : 0;
						activeMerakiPlatformAfter = plat.isItemAssociated ? 1 : 0;
					}
				} else {
					if (targetPlatform) {
						activeHubPlatformsBefore += plat.isItemAssociated ? 1 : 0;
						activeHubPlatformsAfter += targetPlatform.available ? 1 : 0;
					} else {
						activeHubPlatformsBefore += plat.isItemAssociated ? 1 : 0;
						activeHubPlatformsAfter += plat.isItemAssociated ? 1 : 0;
					}
				}
			});
		}

		const eventMeta = {
			change_in_hub_platforms_selected: activeMerakiPlatformAfter - activeMerakiPlatformBefore,
			change_in_meraki_channels_selected: activeHubPlatformsAfter - activeHubPlatformsBefore
		};

		const success = await updateItemPlatforms(variables, dispatch);
		if (success) {
			eventMeta["status"] = TRACKING_STATUS.SUCCESS;
			trackEvent(TRACKING_EVENT_NAMES.ITEM_PLATFORM_UPDATE, eventMeta);

			const updatedPlatforms = data.platforms.map((plat) => {
				if (itemPlatformsAssociation[plat.id] !== undefined) {
					plat.isItemAssociated = itemPlatformsAssociation[plat.id];
				}
				return plat;
			});
			dispatch({
				type: ActionTypes.UPDATE_CATALOGUE_ITEM_DETAIL,
				payload: {
					platforms: updatedPlatforms
				}
			});
		} else {
			eventMeta["status"] = TRACKING_STATUS.FAILURE;
			trackEvent(TRACKING_EVENT_NAMES.ITEM_PLATFORM_UPDATE, eventMeta);
		}
		setItemPlatformsAssociation({});
		setPlatformsFormTouched(false);
	}, [data, match?.params?.id, itemPlatformsAssociation, dispatch]);

	const handleSubmit = async () => {
		if (isFromMenuSection) {
			if (formTab === FORM_TABS[6].value) {
				setIsMenuUpdating(true);
				await handleModifierSortOrderUpdate(
					reOrderedMenuModifierGroups,
					itemDetails.data.id,
					DRAGGABLE_ENTITY_TYPES.MODIFIER_GROUP
				);
				setIsMenuUpdating(false);
				return;
			}

			setIsMenuUpdating(true);
			const success = await handleItemOverridesUpdate({
				...menuItemInfo,
				id,
				translations: createTranslationsObject(itemTranslations)
			});
			if (success) {
				handleCancel();
			}

			setIsMenuUpdating(false);
			return;
		}
		if (formTab === FORM_TABS[0].value) {
			if (itemDataCount === 0) {
				const keysToIgnore = ["currentItemPrice"];
				const fieldsChanged = findChangedKeysInTwoObjects(data, backupData, keysToIgnore);
				const isPriceChanged = fieldsChanged.includes("itemPrice");
				const priceChange = isPriceChanged
					? backupData.itemPrice > data.itemPrice
						? TRACKING_MISC.DECREASE
						: TRACKING_MISC.INCREASE
					: TRACKING_MISC.SAME;
				const eventMeta = {
					fields_updated: fieldsChanged,
					num_of_fields_updated: fieldsChanged.length,
					is_price_changed: isPriceChanged,
					price_change: priceChange
				};

				const sanitisedData = removeProp(data, "__typename");
				if (sanitisedData.merchantRefId === "") {
					sanitisedData.merchantRefId = "-1";
				}
				sanitisedData.locationsToUpdate = [];
				const resp = await editItem(sanitisedData, dispatch);
				if (resp) {
					eventMeta["status"] = TRACKING_STATUS.SUCCESS;
					trackEvent(TRACKING_EVENT_NAMES.ITEM_BASIC_INFO_UPDATE, eventMeta);

					setFormTouched(false);
					if (data.currentItemPrice !== data.itemPrice && !isMenuOverCatalogueEnabled) {
						setCascadePriceModalOpen(true);
						// satismeter event
						SatismeterService.menuPublish();
					}
				} else {
					eventMeta["status"] = TRACKING_STATUS.FAILURE;
					trackEvent(TRACKING_EVENT_NAMES.ITEM_BASIC_INFO_UPDATE, eventMeta);
				}
			}
		} else if (formTab === FORM_TABS[4].value) {
			const ilas = [];
			for (let id in itemLocationFields) {
				ilas.push(itemLocationFields[id]);
			}
			if (ilas.length) {
				const resp = await updateItemLocationFields({ ilas: ilas }, dispatch);
				if (resp) {
					setLocationsFormTouched(false);
				}
			}
		} else if (formTab === FORM_TABS[5].value) {
			updateItemPlatformAssociation();
		}
	};

	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				dispatch({
					type: ActionTypes.UPDATE_CATALOGUE_ITEM_DETAIL,
					payload: {
						isEnabled: !data.isEnabled
					}
				});
			}
			setArchiveRestore(false);
		},
		[data, dispatch]
	);

	const trackUserEvent = (value) => {
		if (value == "logs") {
			trackEvent("item_logs_viewed");
		}
	};
	const switchTab = (tab) => {
		// if switching to a specific tab then reset relevant values
		if (tab.value === FORM_TABS[4].value) {
			setLocationsFormTouched(false);
			setItemLocationFields({});
		} else if (tab.value == FORM_TABS[5].value) {
			setPlatformsFormTouched(false);
			setItemPlatformsAssociation({});
		}
		trackUserEvent(tab.value);
		setFormTab(tab.value);
	};

	const handleLanguage = async (lang, field) => {
		if (!isViewedFromMenuSection) {
			setTranslationLoader(true);
			const resp = await fetchTranslation(
				parseInt(match.params.id),
				"ITEM",
				TRANSLATION_FIELDS_MAP[field],
				lang.value
			);
			if (resp) {
				setCurrSelectedLang({
					...currSelectedLang,
					[field]: {
						lang: lang.value,
						value: resp.value,
						showActionBtn: false
					}
				});
			}
			setTranslationLoader(false);
		} else {
			setCurrSelectedLang({
				...currSelectedLang,
				[field]: {
					lang: lang.value,
					value: "",
					showActionBtn: false
				}
			});
		}
	};

	const handleTranslation = (field, value) => {
		if (currSelectedLang[field].lang === "en") {
			dispatch({
				type: ActionTypes.UPDATE_CATALOGUE_ITEM_DETAIL,
				payload: {
					[field]: value
				}
			});
			if (!isFormTouched) {
				setFormTouched(true);
			}
		} else {
			setCurrSelectedLang({
				...currSelectedLang,
				[field]: {
					...currSelectedLang[field],
					value: value,
					showActionBtn: true
				}
			});
		}
	};

	const saveTranslation = async (field, value) => {
		const resp = await updateTranslation(
			parseInt(match.params.id),
			"ITEM",
			TRANSLATION_FIELDS_MAP[field],
			currSelectedLang[field].lang,
			value
		);
		if (resp && !resp.status) {
			dispatch({
				type: ActionTypes.EDIT_CATALOGUE_ITEM_DETAIL_FAILURE,
				error: resp.error
			});
		} else if (resp && resp.status) {
			setTimeout(() => {
				setCurrSelectedLang({
					...currSelectedLang,
					[field]: {
						...currSelectedLang[field],
						showActionBtn: false
					}
				});
				store.dispatch({
					type: "RESET_TRANSLATION"
				});
			}, 3000);
		}
	};

	const scrollDown = () => {
		setTimeout(() => {
			const formContainer = document.getElementsByClassName("form-container")[0];
			if (formContainer) {
				scroll({ top: formContainer.scrollHeight, left: 0 }, formContainer);
			}
		}, 275);
	};

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "items"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	const renderTitle = () => {
		if (isFromMenuSection) {
			return (
				<div className="item-custom-title">
					{data.foodType && <div className={`food-type ${FOOD_TYPE_MAP[data.foodType.value]}`} />}
					<div className="item-title">{menuItemInfo?.name || "Item"}</div>
				</div>
			);
		}
		if (data.itemTitle) {
			return (
				<div className="item-custom-title">
					{data.foodType && <div className={`food-type ${FOOD_TYPE_MAP[data.foodType.value]}`} />}
					<div className="item-title">{menuItemInfo?.name || data.itemTitle}</div>
				</div>
			);
		}
		return "Item";
	};

	const handleModal = () => {
		setCascadePriceModalOpen(false);
		// Sets the currentItemPrice variable to the actual itemPrice
		dispatch({
			type: ActionTypes.UPDATE_CATALOGUE_ITEM_DETAIL,
			payload: {
				currentItemPrice: data.itemPrice
			}
		});
	};
	let styleInfo = {
		backgroundColor: "#FFF5F5",
		borderColor: "#D64949",
		icon: "/assets/icons/error-red-icon.svg"
	};
	if (isFromMenuSection && message) {
		if (message?.warningMsg?.length > 0) {
			styleInfo.backgroundColor = "#FEF6E9";
			styleInfo.borderColor = "#E5B917";
			styleInfo.icon = "/assets/icons/warning-icon.svg";
		}
	}

	const getTabs = () => {
		if (isFromMenuSection) {
			return MENU_SERVICE_FORM_TABS;
		} else if (biz?.isMenuOverCatalogueEnabled) {
			return FORM_TABS.filter((tab) => !["locations", "platforms", "modifierGroups"].includes(tab.value));
		} else {
			return FORM_TABS;
		}
	};

	return (
		<div className="item-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title={renderTitle()}
				subTitle="Edit this item"
				submitTitle="Save"
				formSidebarClasses={`${formTab}-sidebar`}
				loading={loading || isMenuUpdating}
				isNested={isNested}
				canExpand={biz.differentialPricingEnabled && formTab === FORM_TABS[4].value}
				expandDrawer={biz.differentialPricingEnabled && formTab === FORM_TABS[4].value}
				setIsDrawerExpanded={setIsDrawerExpanded}
				showMoreBtn={formTab === FORM_TABS[0].value}
				numRows={5}
				hideActions={
					(formTab === FORM_TABS[0].value && !isFormTouched) ||
					(formTab === FORM_TABS[1].value && !isFormTouched) ||
					formTab === FORM_TABS[2].value ||
					formTab === FORM_TABS[3].value ||
					(formTab === FORM_TABS[4].value && !isLocationsFormTouched) ||
					(formTab === FORM_TABS[5].value && !isPlatformsFormTouched) ||
					(formTab === FORM_TABS[6].value && isFromMenuSection && !isFormTouched) ||
					formTab === FORM_TABS[7].value ||
					formTab === FORM_TABS[8].value ||
					formTab === MENU_SERVICE_FORM_TABS[1].value ||
					formTab === FORM_TABS[9].value
				}
				headerRight={
					!isFromMenuSection && (
						<React.Fragment>
							{access.isCatalogueManagement && !readOnly && (
								<Button
									classes={data.isEnabled ? "at-btn--danger" : "at-btn--success"}
									clickHandler={() => setArchiveRestore(true)}
								>
									{data.isEnabled ? "Archive" : "Restore"}
								</Button>
							)}
							<div className="help-btn-container">
								<Button clickHandler={handlePiperAcademy} type="secondary">
									<HelpIcon />
									<span>Help</span>
								</Button>
							</div>
						</React.Fragment>
					)
				}
			>
				{isFromMenuSection && (message?.errorMsg?.length > 0 || message?.warningMsg?.length > 0) && (
					<div
						className="item-error-msg-wrapper"
						style={{
							borderLeft: `6px solid ${styleInfo.borderColor}`,
							backgroundColor: styleInfo.backgroundColor
						}}
					>
						<img src={styleInfo?.icon} />
						<p>
							{capitaliseText(
								(message?.errorMsg?.length > 0 && message.errorMsg) ||
									(message?.warningMsg?.length > 0 && message.warningMsg)
							)}
						</p>
					</div>
				)}

				<Topbar
					tabs={getTabs()}
					selectedTab={formTab}
					switchTab={switchTab}
					isStickyOnTop={true}
					isScrollable={!biz.isMenuOverCatalogueEnabled && !isFromMenuSection}
					hiddenTabs={!biz?.modulesEnabled?.includes("Meraki") ? ["additionalInfo", "recommendedItems"] : []}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data}
							handleForm={handleForm}
							validations={error.fields || {}}
							count={itemDataCount}
							itemTags={itemTags}
							scrollDown={scrollDown}
							showLanguages={true}
							currSelectedLang={currSelectedLang}
							handleLanguage={handleLanguage}
							handleTranslation={handleTranslation}
							saveTranslation={saveTranslation}
							readOnly={!access.isCatalogueManagement || readOnly || isFromMenuSection}
							setModalBusy={setModalBusy}
							handleNestedEntity={handleNestedEntity}
							cascadePriceModalOpen={cascadePriceModalOpen}
							handleModal={handleModal}
							fetchItemTags={fetchItemTags}
							isFromMenuSection={isFromMenuSection}
							menuItemInfo={menuItemInfo}
							ruleAppliedFields={data?.ruleAppliedFields ?? []}
							itemTranslations={itemTranslations}
							translationLoader={translationLoader}
						/>
					)}
					{formTab === MENU_SERVICE_FORM_TABS[1].value && (
						<EntityRulesAssociationList
							isOpen={MENU_SERVICE_FORM_TABS[1].value === formTab}
							menuId={menuId}
							entityType="item"
							brandId={menuBrandId}
							entityName={menuItemInfo?.name}
							entityId={String(id)}
						/>
					)}
					{formTab === FORM_TABS[1].value && (
						<Images
							itemId={parseInt(isFromMenuSection ? id : match?.params?.id)}
							platforms={bizPlatformsList}
							modulesEnabled={biz ? biz.modulesEnabled : []}
							readOnly={!access.isCatalogueManagement || readOnly || isFromMenuSection}
							isFromMenuSection={isFromMenuSection}
							menuItemInfo={menuItemInfo}
							handleForm={handleForm}
							imageUrl={data?.imageUrl}
						/>
					)}
					{formTab === FORM_TABS[2].value && (
						<ItemGroups
							itemId={parseInt(isFromMenuSection ? id : match?.params?.id)}
							handleNestedEntity={handleNestedEntity}
							setModalBusy={setModalBusy}
						/>
					)}
					{formTab === FORM_TABS[3].value && (
						<TaxesAndCharges
							match={match}
							itemId={parseInt(isFromMenuSection ? id : match?.params?.id)}
							currencySymbol={biz.currencySymbol}
							handleNestedEntity={handleNestedEntity}
						/>
					)}
					{formTab === FORM_TABS[4].value && (
						<AssociatedLocations
							itemId={parseInt(isFromMenuSection ? id : match?.params?.id)}
							setModalBusy={setModalBusy}
							handleNestedEntity={handleNestedEntity}
							currencySymbol={biz.currencySymbol}
							isInternationalMerchant={biz.differentialPricingEnabled}
							isDrawerExpanded={isDrawerExpanded}
							handleItemLocationFields={handleItemLocationFields}
							readOnly={!access.isCatalogueManagement || readOnly || isFromMenuSection}
						/>
					)}
					{formTab === FORM_TABS[5].value && (
						<AssociatedPlatforms
							itemId={parseInt(isFromMenuSection ? id : match?.params?.id)}
							platforms={data.platforms}
							itemPlatformsAssociation={itemPlatformsAssociation}
							handleItemPlatformAssociation={handleItemPlatformAssociation}
							readOnly={!access.isCatalogueManagement || readOnly || isFromMenuSection}
						/>
					)}
					{formTab === FORM_TABS[6].value && (
						<ModifierGroups
							itemId={parseInt(isFromMenuSection ? id : match?.params?.id)}
							handleNestedEntity={handleNestedEntity}
							setModalBusy={setModalBusy}
							readOnly={!access.isCatalogueManagement || readOnly}
							isFromMenuSection={isFromMenuSection}
							menuId={menuId}
							setFormTouched={setFormTouched}
							reOrderedMenuModifierGroups={reOrderedMenuModifierGroups}
							setReOrderedMenuModifierGroups={setReOrderedMenuModifierGroups}
							menuDetailsData={menuDetailsData}
							handleEntityDissociation={handleEntityDissociation}
							refresh={refresh}
						/>
					)}
					{formTab === FORM_TABS[7].value && (
						<RelatedItems
							itemId={parseInt(isFromMenuSection ? id : match?.params?.id)}
							currencySymbol={biz.currencySymbol}
							handleNestedEntity={handleNestedEntity}
							setModalBusy={setModalBusy}
							readOnly={!access.isCatalogueManagement || readOnly || isFromMenuSection}
						/>
					)}
					{formTab === FORM_TABS[8].value && (
						<CustomFields
							itemId={parseInt(isFromMenuSection ? id : match?.params?.id)}
							readOnly={!access.isCatalogueManagement || readOnly || isFromMenuSection}
						/>
					)}
					{formTab === FORM_TABS[9].value && (
						<Logs itemId={parseInt(isFromMenuSection ? id : match?.params?.id)} />
					)}
					<ArchiveRestore
						isOpen={archiveRestore}
						close={handleArchiveRestore}
						entityType={CATALOGUE_ENTITY_TYPES[2]}
						entityName={data.itemTitle}
						object={data}
						mode={data.isEnabled ? "archive" : "restore"}
						fieldName="isEnabled"
					/>

					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						tab={nestedEntity?.tab || null}
						closeNestedContainer={() =>
							handleNestedEntity(false, nestedEntity?.type, nestedEntity?.id, nestedEntity?.tab)
						}
						nestedRef={nestedRef}
						readOnly={!access.isCatalogueManagement || readOnly}
						handleEntityDissociation={handleEntityDissociation}
						handleModifierSortOrderUpdate={handleModifierSortOrderUpdate}
						menuDetailsData={menuDetailsData}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	biz: store.login.loggedInbizDetail,
	access: store.login.loginDetail.access,
	supportedLanguages: store.login.loggedInbizDetail.supportedLanguages,
	brands: store.configItems.brands,
	menuDetailsData: store.menuDetailsState.menuDetailsData
}))(ItemEdit);

// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { GET_USERS_LIST, GET_USER, EDIT_USER } from "../graphql/unifiedUsers";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

export const fetchUsersList = async () => {
	const { limit, offset, appliedFilters } = store.getState().unifiedUsersListState;
	const { searchFieldSelected = {}, searchFieldValue = "" } = store.getState().unifiedUsersList.data;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_UNIFIED_USERS_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		let processedActiveFilter = false;
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (f === "roles" && appliedFilters[f] !== null && appliedFilters[f]?.value !== "") {
					filtersObject.push({
						field: f,
						value: appliedFilters[f].value
					});
				} else if (appliedFilters[f]?.value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				if (f === "is_active") {
					// this value is being reversed because the behaviour of
					// is_active filter has been changed to work as if it's
					// is_archived.
					filtersObject.push({
						field: f,
						value: String(!(String(appliedFilters[f])?.toLowerCase() === "true"))
					});
					processedActiveFilter = true;
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			}
		});

		if (!processedActiveFilter) {
			filtersObject.push({
				field: "is_active",
				value: "true"
			});
		}

		// set filter
		variables.filters = filtersObject;
		// search filter
		if (searchFieldSelected && searchFieldValue) {
			variables.search = [{ key: searchFieldSelected.key, value: searchFieldValue }];
		}
		const resp = await client.query({
			query: GET_USERS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_UNIFIED_USERS_LIST_SUCCESS,
			payload: { ...resp.data.authServiceUsersList }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_UNIFIED_USERS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

const parseUserData = (data, to = "form", newInviteData = false) => {
	let result = { ...data };
	let biz = store.getState().login.loggedInbizDetail;
	let user = store.getState().login.loginDetail;
	let appsUserData = [];
	switch (to) {
		case "form":
			result.clientToken = user.token;
			result.userLevelAction = false;
			appsUserData = [];
			if (result.atlasData?.length) {
				result.atlasData.forEach((appData) => {
					let app = {
						bizId: biz.id,
						authUserId: result.authUserId,
						appName: appData.app,
						appBizId: appData.appBizId ? parseInt(appData.appBizId) : appData.appBizId,
						appUserId: appData.appUserId ? parseInt(appData.appUserId) : appData.appUserId,
						appBizLogo: appData.appBizLogo,
						appBizName: appData.appBizName,
						isActive: appData.isActive,
						thickClient: appData.thickClient,
						pin: appData.pin,
						roles: appData?.roles?.filter((role) => role.isAssociated) || [],
						rolesOptions: appData.roles || [],
						brands: appData.allLocations
							? appData.brands
							: appData.brands?.filter((brand) => brand.isAssociated) || [],
						brandsOptions: appData.brands || [],
						locations: appData.allLocations
							? appData.locations
							: appData.locations?.filter((location) => location.isAssociated) || [],
						locationsOptions: appData.locations || [],
						allLocations: appData.allLocations || false,
						rolesValidation: "",
						locationsValidation: ""
					};
					console.log(app);
					if (!result.email && (appData.uleEmail || appData.email)) {
						result.email = appData.uleEmail || appData.email;
						result.emailVerified = appData.emailVerified;
					}
					if (!result.fullName && appData.fullName) {
						result.fullName = appData.fullName.trim();
					}
					if (!result.phone && (appData.ulePhone || appData.phone)) {
						result.phone = appData.ulePhone || appData.phone;
						result.isdCode = appData.isdCode;
						result.phoneVerified = appData.phoneVerified;
					}
					appsUserData.push(app);
				});
			}
			delete result.atlasData;
			if (result.primeData?.length) {
				result.primeData.forEach((appData) => {
					let app = {
						bizId: biz.id,
						authUserId: result.authUserId,
						appName: appData.app,
						appBizId: appData.appBizId ? parseInt(appData.appBizId) : appData.appBizId,
						appUserId: appData.appUserId ? parseInt(appData.appUserId) : appData.appUserId,
						appBizLogo: appData.appBizLogo,
						appBizName: appData.appBizName,
						isActive: appData.isActive,
						thickClient: appData.thickClient,
						pin: appData.pin,
						roles: appData?.roles?.find((role) => role.isAssociated) || null,
						rolesOptions: appData.roles || [],
						locations: appData.locations?.filter((location) => location.isAssociated) || [],
						locationsOptions: appData.locations || [],
						allLocations: appData.allLocations || false,
						rolesValidation: "",
						locationsValidation: ""
					};
					if (!result.email && (appData.uleEmail || appData.email)) {
						result.email = appData.uleEmail || appData.email;
						result.emailVerified = appData.emailVerified;
					}
					if (!result.fullName && appData.fullName) {
						result.fullName = appData.fullName.trim();
					}
					if (!result.phone && (appData.ulePhone || appData.phone)) {
						result.phone = appData.ulePhone || appData.phone;
						result.isdCode = appData.isdCode;
						result.phoneVerified = appData.phoneVerified;
					}
					appsUserData.push(app);
				});
			}
			delete result.primeData;
			result.appsUserData = appsUserData;

			// handle invite data
			result.inviteData = {
				invitedThrough: result?.email ? "email" : result?.isdCode && result?.phone ? "phone" : "",
				value: result?.email ? result?.email : result?.isdCode && result?.phone ? result?.phone : "",
				isdCode: result?.isdCode || "",
				inviteData: {
					appsData:
						result?.uninvitedData?.length > 0
							? result?.uninvitedData?.map((app) => ({
									...app,
									rolesOptions: app.roles,
									brandOptions: app.brands,
									locationsOptions: app.locations,
									roles: app.appName === "atlas" ? [] : null,
									brands: [],
									locations: [],
									rolesValidation: "",
									locationsValidation: "",
									allLocations: false,
									isActive: false
								}))
							: []
				}
			};
			break;
		case "archive":
			result.userLevelAction = true;
			result.inviteData = null;
			if (result?.appsUserData?.length) {
				result.appsUserData = result.appsUserData.map((appData) => {
					let updatedAppData = { ...appData };
					if (updatedAppData.appName === "atlas" && updatedAppData.roles.length) {
						updatedAppData.roles = updatedAppData.roles.map((role) => ({
							id: role.id,
							name: role.name
						}));
					}
					if (updatedAppData.appName === "prime" && updatedAppData.roles) {
						updatedAppData.roles = [
							{
								id: updatedAppData.roles?.id,
								name: updatedAppData.roles?.name
							}
						];
					}
					if (updatedAppData.appName === "prime" && updatedAppData.pin === "__PIN__") {
						delete updatedAppData.pin;
					}
					if (updatedAppData.locations.length) {
						updatedAppData.locations = updatedAppData.locations.map((location) => ({
							id: location.id,
							name: location.name
						}));
					}
					delete updatedAppData.appBizLogo;
					delete updatedAppData.appBizName;
					delete updatedAppData.rolesOptions;
					delete updatedAppData.brandsOptions;
					delete updatedAppData.brandsValidation;
					delete updatedAppData.locationsOptions;
					delete updatedAppData.rolesValidation;
					delete updatedAppData.locationsValidation;
					delete updatedAppData.thickClient;
					return updatedAppData;
				});
			}
			break;
		case "server":
			if (result?.appsUserData?.length) {
				result.appsUserData = result.appsUserData.map((appData) => {
					let updatedAppData = { ...appData };
					if (updatedAppData.appName === "atlas" && updatedAppData.roles.length) {
						updatedAppData.roles = updatedAppData.roles.map((role) => ({
							id: role.id,
							name: role.name
						}));
					}
					if (updatedAppData.appName === "prime" && updatedAppData.roles) {
						updatedAppData.roles = [
							{
								id: updatedAppData.roles?.id,
								name: updatedAppData.roles?.name
							}
						];
					}
					if (updatedAppData.appName === "prime" && updatedAppData.pin && updatedAppData.pin === "__PIN__") {
						delete updatedAppData.pin;
					}
					if (updatedAppData.locations.length) {
						updatedAppData.locations = updatedAppData.locations.map((location) => ({
							id: location.id,
							name: location.name
						}));
					}
					delete updatedAppData.appBizLogo;
					delete updatedAppData.appBizName;
					delete updatedAppData.rolesOptions;
					delete updatedAppData.brandsOptions;
					delete updatedAppData.brandsValidation;
					delete updatedAppData.locationsOptions;
					delete updatedAppData.rolesValidation;
					delete updatedAppData.locationsValidation;
					delete updatedAppData.thickClient;
					return updatedAppData;
				});
			}
			// new invite data
			if (newInviteData) {
				result.inviteData = {
					...result.inviteData,
					clientToken: user.token,
					bizId: biz.id,
					inviteData: {
						bizId: biz.id,
						status: "CREATED",
						appUserId: user.id,
						invitedBy: {
							id: user.id,
							name: user.name
						},
						appsData: result?.inviteData?.inviteData?.appsData
							.filter((app) => app.isActive)
							.map((app) => ({
								appName: app.appName,
								appBizId: app.appBizId,
								roles:
									app.appName === "prime"
										? [
												{
													id: app.roles.id,
													name: app.roles.name
												}
											]
										: app.roles.map((role) => ({
												id: role.id,
												name: role.name
											})),
								locations: app.locations.map((location) => ({
									id: location.id,
									name: location.name
								})),
								allLocations: app.allLocations
							}))
					}
				};
			} else {
				result.inviteData = null;
			}
			break;
		case "archive_from_list":
			result.clientToken = user.token;
			result.userLevelAction = true;
			result.inviteData = null;
			appsUserData = [];
			if (result.atlasData?.length) {
				result.atlasData.forEach((appData) => {
					let app = {
						bizId: biz.id,
						authUserId: result.authUserId,
						appName: appData.app,
						appBizId: appData.appBizId ? parseInt(appData.appBizId) : appData.appBizId,
						appUserId: appData.appUserId ? parseInt(appData.appUserId) : appData.appUserId,
						isActive: appData.isActive,
						roles:
							appData?.roles?.map((role) => ({
								id: role.id,
								name: role.name
							})) || [],
						locations:
							appData.locations?.map((location) => ({
								id: location.id,
								name: location.name
							})) || [],
						allLocations: appData.allLocations || false
					};
					appsUserData.push(app);
				});
			}
			delete result.atlasData;
			if (result.primeData?.length) {
				result.primeData.forEach((appData) => {
					let app = {
						bizId: biz.id,
						authUserId: result.authUserId,
						appName: appData.app,
						appBizId: appData.appBizId ? parseInt(appData.appBizId) : appData.appBizId,
						appUserId: appData.appUserId ? parseInt(appData.appUserId) : appData.appUserId,
						isActive: appData.isActive,
						roles:
							appData?.roles
								?.filter((role) => role.isAssociated)
								?.map((role) => ({
									id: role.id,
									name: role.name
								})) || [],
						locations:
							appData.locations
								?.filter((location) => location.isAssociated)
								?.map((location) => ({
									id: location.id,
									name: location.name
								})) || [],
						allLocations: appData.allLocations || false
					};
					appsUserData.push(app);
				});
			}
			delete result.primeData;
			result.appsUserData = appsUserData;
			break;
		default:
			break;
	}
	return result;
};

export const fetchUserDetail = async (id, app, dispatch) => {
	dispatch({
		type: ActionTypes.GET_UNIFIED_USER_DETAIL_REQUEST
	});
	try {
		const user = store.getState().login.loginDetail;
		const variables = {
			id,
			app,
			clientToken: user.token
		};
		const resp = await client.query({
			query: GET_USER,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_UNIFIED_USER_DETAIL_SUCCESS,
			payload: parseUserData(resp.data.authServiceUserDetails?.object, "form")
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_UNIFIED_USER_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching User details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const archiveRestoreUnifiedUser = async (data, fromListView = false) => {
	try {
		const resp = await client.mutate({
			mutation: EDIT_USER,
			variables: parseUserData(data, fromListView ? "archive_from_list" : "archive")
		});
		if (resp?.data?.authServiceUpdateInvitedUser?.status?.success) {
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp?.data?.authServiceUpdateInvitedUser?.status?.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const editUserDetail = async (data, dispatch, newInviteData = false) => {
	dispatch({
		type: ActionTypes.EDIT_UNIFIED_USER_DETAIL_REQUEST
	});
	try {
		const variables = parseUserData(data, "server", newInviteData);
		const resp = await client.mutate({
			mutation: EDIT_USER,
			variables
		});
		if (
			resp.data.authServiceUpdateInvitedUser?.status?.success &&
			(resp.data.authServiceUpdateInvitedUser?.inviteStatus === null ||
				resp.data.authServiceUpdateInvitedUser?.inviteStatus?.success)
		) {
			dispatch({
				type: ActionTypes.EDIT_UNIFIED_USER_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "User details saved!",
					timeout: 2000,
					error: false
				}
			});
			return true;
		} else {
			// handle error message
			if (
				!resp.data.authServiceUpdateInvitedUser.status.success &&
				resp.data.authServiceUpdateInvitedUser.status.messages.length &&
				resp.data.authServiceUpdateInvitedUser.status.messages[0].field === null
			) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: resp.data.authServiceUpdateInvitedUser.status.messages[0].message,
						timeout: 3000,
						error: true
					}
				});
				dispatch({
					type: ActionTypes.EDIT_UNIFIED_USER_DETAIL_FAILURE,
					error: parseErrorMessages(resp.data.authServiceUpdateInvitedUser.status.messages)
				});
			} else if (
				!resp.data.authServiceUpdateInvitedUser?.inviteStatus?.success &&
				resp.data.authServiceUpdateInvitedUser?.inviteStatus?.messages?.length
			) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: resp.data.authServiceUpdateInvitedUser.inviteStatus.messages[0].message,
						timeout: 3000,
						error: true
					}
				});
				dispatch({
					type: ActionTypes.EDIT_UNIFIED_USER_DETAIL_FAILURE,
					error: parseErrorMessages(resp.data.authServiceUpdateInvitedUser.inviteStatus.messages)
				});
			}
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_UNIFIED_USER_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving User details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

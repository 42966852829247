import React, { useState, useEffect, useCallback, useReducer, useRef, useImperativeHandle } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BasicInfo from "../components/ModifierGroupEdit/BasicInfo";
import AssociatedItems from "../components/ModifierGroupEdit/AssociatedItems";
import Modifiers from "../components/ModifierGroupEdit/Modifiers";
import ParentModifiers from "../components/ModifierGroupEdit/ParentModifiers";

// client
import { client } from "../client";
import { clientMenu } from "../client-menu";
import { store } from "../store/configureStore";

// utils
import history from "../history";
import {
	removeProp,
	adjustNestedContainer,
	findChangedKeysInTwoObjects,
	trackEvent,
	capitaliseText,
	createTranslationsObject
} from "../atlas-utils";

// third party
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

// actions
import { fetchTranslation, updateTranslation, fetchBizPlatforms } from "../actions/actions";
import {
	fetchModifierGroupsList,
	fetchModifierGroupDetail,
	editModifierGroup,
	fetchMenuServiceModifierGroupDetail
} from "../actions/modifierGroups";
import { ActionTypes } from "../actions/_types";

// graphql
import { GET_MODIFIER_GROUP_PARENT_MODIFIERS } from "../graphql/modifierGroups";
import { UPDATE_MENU } from "../graphql/menus";

// reducers
import { modifierGroupDetailsReducer, MODIFIER_GROUP_DETAILS_INITIAL_STATE } from "../reducers/modifierGroups";

// assets
import HelpIcon from "../components/_commons/HelpIcon";

// services
import NotificationServices from "../services/NotificationService";

// constants
import { NESTED_ENTITY_TYPES, TRACKING_EVENT_NAMES, TRACKING_SOURCE, TRACKING_STATUS } from "../client-config";
import { DRAGGABLE_ENTITY_TYPES } from "../components/_commons/DraggableModifierAccordion";
const MODIFIER_GROUP_TYPES = [
	{ label: "Add-On", value: "Add On" },
	{ label: "Variant", value: "Variant" }
];

const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Items",
		value: "items"
	},
	{
		label: "Nested Modifiers",
		value: "nestedModifiers"
	},
	{
		label: "Parent Modifiers",
		value: "parentModifiers"
	}
];
// tabs to be shown when the user tries to view list of modifier -> detail view when menu management enabled
const FORM_TABS_MENU = [
	{
		label: "Basic Information",
		value: "basic"
	}
];
//tabs to be shown when the user tries add/edit through menus -> select a single item detail view -> modifier grp
const FORM_TABS_MENU_SERVICE = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Nested Modifiers",
		value: "nestedModifiers"
	},
	{
		label: "Parent Modifiers",
		value: "parentModifiers"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const TRANSLATION_FIELDS_MAP = {
	optionGroupTitle: "option_group_title",
	optionGroupDesc: "option_group_desc"
};

const ModifierGroupEdit = ({
	match,
	biz,
	access,
	readOnly = false,
	supportedLanguages = [],
	isNested = false,
	isForeignSource = false,
	bizPlatforms = [],
	closeNestedContainer,
	connectedRef,
	menuDetailsData,
	isOpen,
	isFromMenuSection,
	isMenuFlowIgnored = false,
	message = {},
	tab = null,
	handleEntityDissociation = () => {},
	handleModifierSortOrderUpdate = () => {}
}) => {
	const [formTab, setFormTab] = useState(tab ? tab : FORM_TABS[0].value);
	const [isFormOpen, setFormState] = useState(false);
	const [modifierGroupDetails, dispatch] = useReducer(
		modifierGroupDetailsReducer,
		MODIFIER_GROUP_DETAILS_INITIAL_STATE
	);
	const { loading, data, error, backupData } = modifierGroupDetails;
	const [isModalBusy, setModalBusy] = useState(false);
	const [parentModifiers, setParentModifiers] = useState({
		objects: [],
		count: 0
	});
	const isMenuOverCatalogueEnabled = biz?.isMenuOverCatalogueEnabled || false;
	const [currSelectedLang, setCurrSelectedLang] = useState({
		optionGroupTitle: {
			lang: supportedLanguages.length ? supportedLanguages[0].value : "",
			value: "",
			showActionBtn: false
		},
		optionGroupDesc: {
			lang: supportedLanguages.length ? supportedLanguages[0].value : "",
			value: "",
			showActionBtn: false
		}
	});
	const [modifierGroupType, setModifierGroupType] = useState(MODIFIER_GROUP_TYPES?.[0]);
	const [isFormUntouched, setBtnState] = useState(true);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();
	const [menuSectionInfo, setMenuSectionInfo] = useState({});
	const [isMenuUpdating, setMenuUpdating] = useState(false);

	const { pathname } = useLocation();
	const isViewedFromMenuSection = pathname.includes("/menus/edit/");
	const pathnameEntities = pathname.split("/");
	const menuId = pathnameEntities[pathnameEntities.length - 1];
	const [reOrderedMenuModifiers, setReOrderedMenuModifiers] = useState([]);

	const [optionGroupTranslations, setOptionGroupTranslations] = useState({});

	const fetchModifierGroupParentModifiers = useCallback(async () => {
		try {
			const variables = {
				id: parseInt(match.params.id),
				limit: 5,
				offset: 0,
				sort: {
					field: "option_order",
					order: "ASC"
				}
			};
			const resp = await client.query({
				query: GET_MODIFIER_GROUP_PARENT_MODIFIERS,
				variables,
				fetchPolicy: "no-cache"
			});
			setParentModifiers(resp.data.modifierGroup.parentModifiers);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
	}, [match]);

	const handleNestedEntity = useCallback((toOpen = false, type, id, tab = null) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id,
				tab
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchBizPlatforms(true);
	}, []);

	useEffect(() => {
		if (menuId && isViewedFromMenuSection && isFormOpen && !isMenuFlowIgnored) {
			fetchMenuServiceModifierGroupDetail(dispatch, menuId, String(match.params.id));
		} else if (isFormOpen) {
			fetchModifierGroupDetail(parseInt(match.params.id), dispatch);
			fetchModifierGroupParentModifiers();
		}
	}, [match.params.id, isFormOpen]);

	useEffect(() => {
		data.isVariant
			? setModifierGroupType(MODIFIER_GROUP_TYPES?.[1])
			: setModifierGroupType(MODIFIER_GROUP_TYPES?.[0]);
	}, [data.isVariant]);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(() => {
				if (isNested || isForeignSource) {
					closeNestedContainer();
				} else {
					fetchModifierGroupsList();
					history.push("/modifier-groups");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handleForm = (field, value) => {
		if (isViewedFromMenuSection) {
			if (field === "name") {
				setOptionGroupTranslations((prev) => ({
					...prev,
					[currSelectedLang?.optionGroupTitle?.lang]: {
						...prev[currSelectedLang?.optionGroupTitle.lang],
						[field]: value
					}
				}));
				setBtnState(false);
				if (currSelectedLang?.optionGroupTitle?.lang !== "en") {
					return;
				}
			}
			if (field === "description") {
				setOptionGroupTranslations((prev) => ({
					...prev,
					[currSelectedLang?.optionGroupDesc?.lang]: {
						...prev[currSelectedLang?.optionGroupDesc?.lang],
						[field]: value
					}
				}));
				setBtnState(false);
				if (currSelectedLang?.optionGroupDesc?.lang !== "en") {
					return;
				}
			}
			setMenuSectionInfo((current) => ({
				...current,
				[field]: value
			}));
			setBtnState(false);
			return;
		}
		isFormUntouched && setBtnState(false);
		dispatch({
			type: ActionTypes.UPDATE_MODIFIER_GROUP_DETAIL,
			payload: {
				[field]: value
			}
		});
	};

	const handleModifierGroupDetailsUpdateInMenuService = async () => {
		const menuId = menuDetailsData?.menuId;
		const updatedOptionGroupOverrideFields = { ...menuSectionInfo };
		const stringifiedOptionGroupId = String(match.params.id);
		if (!updatedOptionGroupOverrideFields?.name) {
			updatedOptionGroupOverrideFields.name = null;
		}
		if (!updatedOptionGroupOverrideFields?.description) {
			updatedOptionGroupOverrideFields.description = null;
		}

		if (!updatedOptionGroupOverrideFields?.minSelectable) {
			updatedOptionGroupOverrideFields.min = null;
		} else {
			updatedOptionGroupOverrideFields.min = parseInt(updatedOptionGroupOverrideFields?.minSelectable);
			delete updatedOptionGroupOverrideFields?.minSelectable;
		}

		if (!updatedOptionGroupOverrideFields?.maxSelectable) {
			updatedOptionGroupOverrideFields.max = null;
		} else {
			updatedOptionGroupOverrideFields.max = parseInt(updatedOptionGroupOverrideFields?.maxSelectable);
			delete updatedOptionGroupOverrideFields?.maxSelectable;
		}

		try {
			const menuUpdationVariables = {
				id: menuId,
				menuData: {
					image: null,
					imageUrl: menuDetailsData?.imageUrl,
					name: menuDetailsData.name,
					description: menuDetailsData.description,
					brand: menuDetailsData.brand,
					translations: menuDetailsData.translations,
					options: menuDetailsData.options.map((option) => ({
						...option,
						overrides: {
							...option.overrides,
							image: ""
						}
					})),
					optionGroups: menuDetailsData.optionGroups.map((optionGroup) =>
						optionGroup?.id === stringifiedOptionGroupId
							? {
									...optionGroup,
									overrides: {
										...optionGroup.overrides,
										name: menuSectionInfo?.name,
										description: menuSectionInfo?.description,
										min: menuSectionInfo?.minSelectable,
										max: menuSectionInfo?.maxSelectable,
										translations: createTranslationsObject(optionGroupTranslations)
									}
								}
							: optionGroup
					),
					items: menuDetailsData.items.map((item) => ({
						...item,
						overrides: { ...item.overrides, image: "" }
					})),
					categories: menuDetailsData.categories.map((category) => ({
						...category,
						overrides: {
							...category.overrides,
							image: ""
						}
					}))
				}
			};

			const itemOptionGroupAddResp = await clientMenu.mutate({
				mutation: UPDATE_MENU,
				variables: menuUpdationVariables
			});

			if (itemOptionGroupAddResp?.data?.updateMenuV2) {
				NotificationServices.pushNotification({
					message: "Updated modifier group successfully!",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: itemOptionGroupAddResp?.data?.updateMenuV2
				});
				setBtnState(true);
				return true;
			} else {
				NotificationServices.pushNotification({
					message: "Failed to update modifier group",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
		} catch (e) {
			NotificationServices.pushNotification({
				message: "Failed to update modifier group",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			console.log(e);
		}
	};

	const handleSubmit = async () => {
		if (isViewedFromMenuSection) {
			if (formTab === FORM_TABS[2].value) {
				setMenuUpdating(true);
				await handleModifierSortOrderUpdate(
					reOrderedMenuModifiers,
					match.params.id,
					DRAGGABLE_ENTITY_TYPES.MODIFIER
				);
				setMenuUpdating(false);
				return;
			}
			handleModifierGroupDetailsUpdateInMenuService();
			return;
		}
		const fieldChanges = findChangedKeysInTwoObjects(data, backupData);

		const eventMeta = {
			fields_changed: fieldChanges,
			num_of_fields_changed: fieldChanges.length
		};

		const sanitisedData = removeProp(data, "__typename");
		if (
			modifierGroupType?.value === "Add On" &&
			sanitisedData?.optionGroupMinSelectable === 1 &&
			sanitisedData?.optionGroupMaxSelectable === 1
		) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message:
						"For Add-on modifier group type, both the values of minimum and maximum range of modifiers cannot be 1",
					timeout: 3000,
					error: true
				}
			});
			return;
		}
		if (sanitisedData.merchantRefId === "") {
			sanitisedData.merchantRefId = "-1";
		}

		const resp = await editModifierGroup(sanitisedData, dispatch);
		if (resp) {
			eventMeta.status = TRACKING_STATUS.SUCCESS;
			trackEvent(TRACKING_EVENT_NAMES.MODIFIER_GROUP_BASIC_INFO_UPDATE, eventMeta);
			setBtnState(true);
		} else {
			eventMeta.status = TRACKING_STATUS.FAILED;
			trackEvent(TRACKING_EVENT_NAMES.MODIFIER_GROUP_BASIC_INFO_UPDATE, eventMeta);
		}
	};

	const handleModifierGroupType = (field, type) => {
		isFormUntouched && setBtnState(false);
		if (modifierGroupType?.value === "Add On") {
			setModifierGroupType(type);
			dispatch({
				type: ActionTypes.UPDATE_MODIFIER_GROUP_DETAIL,
				payload: {
					optionGroupMinSelectable: 1,
					optionGroupMaxSelectable: 1
				}
			});
		} else {
			setModifierGroupType(type);
			dispatch({
				type: ActionTypes.UPDATE_MODIFIER_GROUP_DETAIL,
				payload: {
					optionGroupMinSelectable: 0,
					optionGroupMaxSelectable: 12,
					treatAsAddon: false
				}
			});
		}
	};

	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				dispatch({
					type: ActionTypes.UPDATE_MODIFIER_GROUP_DETAIL,
					payload: {
						isEnabled: !data.isEnabled
					}
				});
			}
			setArchiveRestore(false);
		},
		[data]
	);

	const handleLanguage = async (lang, field) => {
		if (!isViewedFromMenuSection) {
			const resp = await fetchTranslation(
				parseInt(match.params.id),
				"OPTION_GROUP",
				TRANSLATION_FIELDS_MAP[field],
				lang.value
			);
			if (resp) {
				setCurrSelectedLang({
					...currSelectedLang,
					[field]: {
						lang: lang.value,
						value: resp.value,
						showActionBtn: false
					}
				});
			}
		} else {
			setCurrSelectedLang({
				...currSelectedLang,
				[field]: {
					lang: lang.value,
					value: "",
					showActionBtn: false
				}
			});
		}
	};

	const handleTranslation = (field, value) => {
		if (currSelectedLang[field].lang === "en") {
			dispatch({
				type: ActionTypes.UPDATE_MODIFIER_GROUP_DETAIL,
				payload: {
					[field]: value
				}
			});
			if (isFormUntouched) {
				setBtnState(false);
			}
		} else {
			setCurrSelectedLang({
				...currSelectedLang,
				[field]: {
					...currSelectedLang[field],
					value: value,
					showActionBtn: true
				}
			});
		}
	};

	const saveTranslation = async (field, value) => {
		const resp = await updateTranslation(
			parseInt(match.params.id),
			"OPTION_GROUP",
			TRANSLATION_FIELDS_MAP[field],
			currSelectedLang[field].lang,
			value
		);
		if (resp && !resp.status) {
			dispatch({
				type: ActionTypes.EDIT_MODIFIER_GROUP_DETAIL_FAILURE,
				error: resp.error
			});
		} else if (resp && resp.status) {
			setTimeout(() => {
				setCurrSelectedLang({
					...currSelectedLang,
					[field]: {
						...currSelectedLang[field],
						showActionBtn: false
					}
				});
				store.dispatch({
					type: "RESET_TRANSLATION"
				});
			}, 3000);
		}
	};

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "modifier-groups"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	useEffect(() => {
		setOptionGroupTranslations(
			data?.translations?.reduce((acc, curr) => {
				acc[curr.language] = { language: curr.language, name: curr.name, description: curr.description };
				return acc;
			}, {})
		);
		setMenuSectionInfo({
			name: data?.name,
			description: data?.description,
			crmName: data?.crmName,
			maxSelectable: data?.maxSelectable,
			minSelectable: data?.minSelectable,
			translations: data?.translations
				? Object.entries(data.translations).map(([key, translation]) => {
						return {
							language: translation.language,
							name: translation.name,
							description: translation.description
						};
					})
				: []
		});
	}, [data]);

	let styleInfo = {
		backgroundColor: "#FFF5F5",
		borderColor: "#D64949",
		icon: "/assets/icons/error-red-icon.svg"
	};
	if (isFromMenuSection && message) {
		if (message?.warningMsg?.length > 0) {
			styleInfo.backgroundColor = "#FEF6E9";
			styleInfo.borderColor = "#E5B917";
			styleInfo.icon = "/assets/icons/warning-icon.svg";
		}
	}
	const getTabs = () => {
		if (isMenuOverCatalogueEnabled && isViewedFromMenuSection) {
			return FORM_TABS_MENU_SERVICE;
		} else if (isMenuOverCatalogueEnabled && !isViewedFromMenuSection) {
			return FORM_TABS_MENU;
		} else {
			return FORM_TABS;
		}
	};

	return (
		<div className="modifier-group-edit-container">
			<FormSidebar
				isOpen={isFromMenuSection ? isOpen : isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title={data.optionGroupTitle || "Modifier Group"}
				subTitle="Edit this modifier group"
				submitTitle="Save"
				loading={loading || isMenuUpdating}
				disabled={isFormUntouched}
				isNested={isNested}
				showMoreBtn={formTab === FORM_TABS[0].value}
				numRows={4}
				hideActions={
					(formTab === FORM_TABS[0].value && isFormUntouched) ||
					formTab === FORM_TABS[1].value ||
					(formTab === FORM_TABS[2].value && isFormUntouched) ||
					formTab === FORM_TABS[3].value
				}
				headerRight={
					<React.Fragment>
						{access.isCatalogueManagement && !readOnly && (
							<Button
								classes={data.isEnabled ? "at-btn--danger" : "at-btn--success"}
								clickHandler={() => setArchiveRestore(true)}
							>
								{data.isEnabled ? "Archive" : "Restore"}
							</Button>
						)}
						<div className="help-btn-container">
							<Button clickHandler={handlePiperAcademy} type="secondary">
								<HelpIcon />
								<span>Help</span>
							</Button>
						</div>
					</React.Fragment>
				}
			>
				{isFromMenuSection && (message?.errorMsg?.length > 0 || message?.warningMsg?.length > 0) && (
					<div
						className="item-error-msg-wrapper"
						style={{
							borderLeft: `6px solid ${styleInfo.borderColor}`,
							backgroundColor: styleInfo.backgroundColor
						}}
					>
						<img src={styleInfo?.icon} />
						<p>
							{capitaliseText(
								(message?.errorMsg?.length > 0 && message.errorMsg) ||
									(message?.warningMsg?.length > 0 && message.warningMsg)
							)}
						</p>
					</div>
				)}

				<Topbar
					tabs={getTabs()}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
					hiddenTabs={parentModifiers.count === 0 ? ["parentModifiers"] : []}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data}
							modifierGroupType={modifierGroupType}
							options={MODIFIER_GROUP_TYPES}
							handleForm={handleForm}
							handleModifierGroupType={handleModifierGroupType}
							validations={error.fields || {}}
							bizPlatforms={bizPlatforms || []}
							showLanguages={true}
							currSelectedLang={currSelectedLang}
							handleLanguage={handleLanguage}
							handleTranslation={handleTranslation}
							saveTranslation={saveTranslation}
							readOnly={
								!access.isCatalogueManagement ||
								readOnly ||
								(isViewedFromMenuSection && !isMenuFlowIgnored)
							}
							isViewedFromMenuSection={isViewedFromMenuSection && !isMenuFlowIgnored}
							menuSectionInfo={menuSectionInfo}
							optionGroupTranslations={optionGroupTranslations}
						/>
					)}
					{formTab === FORM_TABS[1].value && (
						<AssociatedItems
							modifierGroupId={parseInt(match.params.id)}
							setModalBusy={setModalBusy}
							currencySymbol={biz.currencySymbol}
							handleNestedEntity={handleNestedEntity}
							readOnly={!access.isCatalogueManagement || readOnly}
							isViewedFromMenuSection={isViewedFromMenuSection && !isMenuFlowIgnored}
							menuId={menuId}
						/>
					)}
					{formTab === FORM_TABS[2].value && (
						<Modifiers
							modifierGroupId={parseInt(match.params.id)}
							setModalBusy={setModalBusy}
							currencySymbol={biz.currencySymbol}
							handleNestedEntity={handleNestedEntity}
							readOnly={!access.isCatalogueManagement || readOnly}
							isViewedFromMenuSection={isViewedFromMenuSection && !isMenuFlowIgnored}
							menuId={menuId}
							reOrderedMenuModifiers={reOrderedMenuModifiers}
							setReOrderedMenuModifiers={setReOrderedMenuModifiers}
							setBtnState={setBtnState}
							handleEntityDissociation={handleEntityDissociation}
							menuDetailsData={menuDetailsData}
						/>
					)}
					{formTab === FORM_TABS[3].value && (
						<ParentModifiers
							modifierGroupId={parseInt(match.params.id)}
							setModalBusy={setModalBusy}
							currencySymbol={biz.currencySymbol}
							handleNestedEntity={handleNestedEntity}
							readOnly={!access.isCatalogueManagement || readOnly}
						/>
					)}
					<ArchiveRestore
						isOpen={archiveRestore}
						close={handleArchiveRestore}
						entityType={CATALOGUE_ENTITY_TYPES[3]}
						entityName={data.optionGroupTitle}
						object={data}
						mode={data.isEnabled ? "archive" : "restore"}
						fieldName="isEnabled"
						sourceOfTrigger={TRACKING_SOURCE.DETAIL_VIEW}
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						tab={nestedEntity?.tab || null}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
						readOnly={!access.isCatalogueManagement || readOnly}
						menuDetailsData={menuDetailsData}
						handleEntityDissociation={handleEntityDissociation}
						handleModifierSortOrderUpdate={handleModifierSortOrderUpdate}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail,
	access: store.login.loginDetail.access,
	supportedLanguages: store.login.loggedInbizDetail.supportedLanguages,
	bizPlatforms: store.configItems.bizPlatforms.items,
	menuDetailsData: store.menuDetailsState.menuDetailsData
});
export default connect(mapStateToProps)(ModifierGroupEdit);

import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";

// third party
import { Transition, animated, config } from "react-spring/renderprops";
import _ from "lodash";

// component
import { Button } from "../_commons/Button";
import { CheckBox } from "../_commons/CheckBox";
import CreateIcon from "../_commons/CreateIcon";
import { RightClickMenuContext } from "../_commons/RightClickContext";
// import PlaceholderComponent from '../_commons/PlaceholderComponent';
import FormTable from "../_commons/FormTable";
import { SearchFilter } from "../_commons/SearchFilter";
import { SelectFilter } from "../_commons/SelectFilter";
import TripleDotIcon from "../_icons/TripleDotIcon";
import LpaLogs from "../LocationsList/LpaLogs";
import ContextMenu from "../_commons/ContextMenu";
import StoreToggle from "../LocationsList/StoreToggle";
import PublishCatalogue from "../LocationsList/PublishCatalogue";
import { Accordion } from "../_commons/Accordion";
import BrandLocationAssociations from "./BrandEntityAssociations/BrandLocationAssociations";
import ToggleBrandLocationPlatforms from "./BrandEntityAssociations/ToggleBrandLocationPlatforms";
import { Paginator } from "../_commons/Paginator";
import Popover from "../_commons/Popover";

// constant
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
import { client } from "../../client";
import { GET_ASSOCIATED_BRAND_SPECIFIC_LOCATIONS } from "../../graphql/brands";
import { capitaliseText } from "../../atlas-utils";

const Locations = ({
	hoverData,
	setHoverData,
	formSidebarRef,
	clickMenuData,
	setClickMenuData,
	brandId,
	formSidebarTimerRef,
	brandName,
	setModalViewData,
	selectedLocations,
	setSelectedLocations,
	fetchLocationsListRef,
	brandImage,
	image,
	readOnly = true,
	isMenuOverCatalogueEnabled = false
}) => {
	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			render: (data) => (
				<div className="loc-details">
					<span className="name--underline name">{data.name}</span>
					<span className="city">{data.city}</span>
				</div>
			)
		},
		{
			title: "Assoc. Items",
			dataIndex: "ilaCount",
			render: (data) => data
		},
		{
			title: "Assoc. Platforms",
			dataIndex: "associatedPlatforms",
			render: (data, renderValuesAndFunctions) => {
				return (
					<div className="platforms-list">
						{data?.associatedPlatforms?.map((platform, i) => (
							<Popover
								showOnClick
								key={i}
								tooltipInfo={`Click to see logs for ${capitaliseText(platform.platformName)}`}
								renderPopover={() =>
									renderValuesAndFunctions.renderPopover(platform, {
										id: data?.id,
										name: data?.locationName
									})
								}
							>
								<div
									className={"platform " + (platform.state === "Enabled" ? "platform--enabled" : "")}
									// onMouseEnter={(e) => renderValuesAndFunctions.handleHoverData(true, e.target.getBoundingClientRect())}
									// onMouseLeave={() => renderValuesAndFunctions.handleHoverData(false)}
									title={platform.platformName}
								>
									<img
										src={
											platform?.logo ||
											CATALOGUE_PLATFORMS_LOGO[platform.platformName.toLowerCase()] ||
											"/assets/icons/icons8-globe-40.png"
										}
										className="platform-img"
									/>
									<span className="platform-status-indicator"></span>
								</div>
							</Popover>
						))}
						{!renderValuesAndFunctions.readOnly && (
							<ContextMenu
								isOpen={renderValuesAndFunctions.contextMenuData?.id === data?.id}
								renderMenuItems={() => renderValuesAndFunctions.renderMenuItems()}
								handleOpenMenu={() => renderValuesAndFunctions.openContextMenu({ id: data?.id })}
								handleOutsideClick={
									renderValuesAndFunctions.contextMenuData?.id === data?.id
										? () => renderValuesAndFunctions.closeContextMenu()
										: () => {}
								}
								clickEvent="mousedown"
								disableContextMenu={!!Object.keys(renderValuesAndFunctions?.selectedLocations).length}
							/>
						)}
						{/* <span
							// onClick={(e) => {
							// 	e.stopPropagation();
							// 	renderValuesAndFunctions.handleClickMenuRender(e.target.getBoundingClientRect(), data?.id)
							// 	}
							// }
							className={'actions-list ' + (data?.id === renderValuesAndFunctions?.clickedId ? 'actions-list--selected' : '')}
						>
						</span> */}
					</div>
				);
			}
		}
	];

	const tooltipDiscardTimerRef = useRef();
	const reRenderPreventionTimerRef = useRef();
	const [brandLocationAssociationSidebar, setBrandLocationAssociationSidebar] = useState(false);
	const [toggleBrandLocationPlatformSidebar, setToggleBrandLocationPlatformSidebar] = useState(false);
	const [publishMenuSidebar, setPublishMenuSidebar] = useState(false);
	const [modalBusy, setModayBusy] = useState(false);
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);
	const [isAllFieldSelected, setAllFieldSelected] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [currentFilters, setCurrentFilters] = useState({});
	const debounceRef = useRef();
	const [offset, setOffset] = useState(0);
	const [contextMenuData, setContextMenuData] = useState({ id: null });
	const [sidebarData, setSidebarData] = useState({ locations: [] });
	const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
	const [toggleAction, setToggleAction] = useState("disable");

	const handleHoverData = (show, boundedRect) => {
		if (!show) {
			tooltipDiscardTimerRef.current = setTimeout(() => {
				setHoverData((current) => ({
					...current,
					show: false
				}));
			}, 200);
			return;
		}

		if (tooltipDiscardTimerRef.current) {
			clearInterval(tooltipDiscardTimerRef.current);
		}

		const parentElement = formSidebarRef.current.getBoundingClientRect();
		const targetX = boundedRect.x - parentElement.x;
		const targetY = boundedRect.y + 50;
		setHoverData((current) => ({
			...current,
			position: {
				x: targetX - 330,
				y: targetY
			},
			show: true
		}));
	};

	const handleTooltipMouseEnter = () => {
		clearInterval(tooltipDiscardTimerRef.current);
		if (reRenderPreventionTimerRef.current) {
			return;
		}
		setHoverData((current) => ({
			...current,
			show: true
		}));
	};

	const handleTooltipMouseLeave = () => {
		setHoverData((current) => ({
			...current,
			show: false
		}));
		reRenderPreventionTimerRef.current = setTimeout(() => {}, 300);
	};

	const handleClickMenuRender = (boundedRect, id) => {
		if (!boundedRect) {
			return;
		}
		setClickMenuData((current) => ({
			...current,
			show: !(current.id === id),
			id: current.id === id ? null : id,
			anchorPoint: {
				x: 574,
				y: boundedRect.y + 28
			}
		}));
	};

	const handleAllFieldSelection = () => {
		const updatedSelectedLocations = {
			...selectedLocations
		};
		if (isAllFieldSelected) {
			if (data?.stores?.objects) {
				data.stores.objects.forEach((store) => {
					if (updatedSelectedLocations[store.id]) {
						delete updatedSelectedLocations[store.id];
					}
				});
			}
			setSelectedLocations(updatedSelectedLocations);
			setAllFieldSelected(false);
		} else {
			if (data?.stores?.objects) {
				data.stores.objects.forEach((store) => {
					updatedSelectedLocations[store.id] = { ...store };
				});
			}
			setSelectedLocations(updatedSelectedLocations);
			setAllFieldSelected(true);
		}
	};

	const handleSingleFieldSelection = (id) => {
		const updatedSelectedLocations = {
			...selectedLocations
		};
		if (updatedSelectedLocations[id]) {
			delete updatedSelectedLocations[id];
			setAllFieldSelected(false);
		} else {
			updatedSelectedLocations[id] = data?.stores.objects.find((loc) => loc.id === id);
		}
		setSelectedLocations(updatedSelectedLocations);
	};

	const fetchAssociatedLocations = async (brand, isSelectedLocationsEmpty = false) => {
		try {
			setAllFieldSelected(false);
			setLoading(true);
			const variables = {};
			variables.brand = brand ? String(brand) : String(brandId);
			variables.limit = 10;
			variables.offset = offset;
			variables.filters = [
				{
					field: "is_active",
					value: "true"
				}
			];
			variables.sort = { field: "name", order: "ASC" };
			variables.search = [
				{
					key: "default",
					value: searchQuery
				}
			];
			const appliedFilters = Object.keys(currentFilters);
			if (appliedFilters.length > 0) {
				appliedFilters.forEach((filter) => {
					variables.filters.push({
						field: filter,
						value: currentFilters[filter].value
					});
				});
			}
			const resp = await client.query({
				query: GET_ASSOCIATED_BRAND_SPECIFIC_LOCATIONS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data) {
				const data = {
					stores: {
						...resp.data.stores,
						objects: [
							...resp.data.stores.objects.map((object) => ({
								...object,
								name: {
									name: object.name,
									city: object.city
								},
								associatedPlatforms: {
									id: object.id,
									locationName: object.name,
									associatedPlatforms: [...object.locationPlatforms]
								}
							}))
						]
					}
				};
				setData(data);
				if (resp?.data?.stores?.objects) {
					let allSelected = true;
					if (!isSelectedLocationsEmpty) {
						const data = resp?.data?.stores?.objects ?? [];
						for (let i = 0; i < data.length; i++) {
							if (!selectedLocations[data[i].id]) {
								allSelected = false;
								break;
							}
						}
					} else {
						allSelected = false;
					}
					setAllFieldSelected(allSelected);
				}
			}
			setLoading(false);
		} catch (e) {
			setLoading(false);
			console.log(e);
		}
	};

	const handleBrandLocationAssociationSidebar = (open = true) => {
		if (open) {
			setBrandLocationAssociationSidebar(true);
		} else {
			fetchAssociatedLocations(brandId);
			setBrandLocationAssociationSidebar(false);
		}
		setModayBusy(true);
	};

	const handlePublishClick = (isMulti = false) => {
		setSidebarData({
			locations: isMulti
				? Object.keys(selectedLocations).map((id) => ({
						id: selectedLocations[id].id,
						name: selectedLocations[id].name.name,
						city: selectedLocations[id].city
					}))
				: data.stores.objects
						.filter((object) => object.id === contextMenuData.id)
						.map((loc) => ({ id: loc.id, name: loc.name.name, city: loc.city }))
		});
		setPublishMenuSidebar(true);
	};

	const handleToggleClick = (isMulti = false, action = "disable") => {
		setToggleAction(action);
		setSidebarData({
			locations: isMulti
				? Object.keys(selectedLocations).map((id) => ({
						id: selectedLocations[id].id,
						name: selectedLocations[id].name.name,
						city: selectedLocations[id].city
					}))
				: data.stores.objects
						.filter((object) => object.id === contextMenuData.id)
						.map((loc) => ({ id: loc.id, name: loc.name.name, city: loc.city }))
		});
		setToggleBrandLocationPlatformSidebar(true);
	};

	const handleRemoveClick = (isMulti = false) => {
		setModalViewData((current) => ({
			...current,
			isVisible: true,
			type: "remove",
			isMulti: isMulti,
			id: isMulti ? "multi" : contextMenuData?.id,
			data: isMulti
				? Object.keys(selectedLocations).map((id) => ({ ...selectedLocations[id] }))
				: data.stores.objects.filter((object) => object.id === contextMenuData.id)
		}));

		setClickMenuData({
			show: false,
			anchorPoint: { x: 0, y: 0 },
			id: null
		});
	};

	const selectedLocationsCount = Object.keys(selectedLocations).length;

	useEffect(() => {
		if (brandId) {
			fetchAssociatedLocations(brandId);
		}
	}, [currentFilters, offset]);

	useEffect(() => {
		if (brandId) {
			fetchAssociatedLocations(brandId);
		}
	}, []);

	const setFilter = (field, value) => {
		const updatedCurrentFilters = {
			...currentFilters
		};

		if (!value && updatedCurrentFilters[field]) {
			delete updatedCurrentFilters[field];
		} else {
			updatedCurrentFilters[field] = value;
		}
		setCurrentFilters(updatedCurrentFilters);
	};

	const handleSearch = (field, value) => {
		if (!value) {
			setSearchQuery("");
		} else {
			setSearchQuery(value);
		}
	};

	const handlePagination = (page) => {
		setOffset((page - 1) * 10);
	};

	useEffect(() => {
		if (debounceRef.current) {
			clearTimeout(debounceRef.current);
		}
		debounceRef.current = setTimeout(() => fetchAssociatedLocations(brandId), 500);
	}, [searchQuery]);

	useImperativeHandle(fetchLocationsListRef, () => ({
		fetchAssociatedLocations: (id, isSelectedLocationsEmpty) =>
			fetchAssociatedLocations(id, isSelectedLocationsEmpty)
	}));

	const placeholderContent = {
		placeholderText: "No locations associated yet!",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-location.svg",
		placeholderButtonContent: (
			<>
				<CreateIcon />
				<span>Associate Your Location</span>
			</>
		),
		placeholderButtonClickAction: handleBrandLocationAssociationSidebar,
		redirectionLink: "www.google.com",
		redirectionLinkText: "learn more about locations"
	};

	const renderPopover = (platform, location) => {
		return (
			<LpaLogs
				platform={platform}
				brand={{ id: brandId, name: brandName }}
				location={location}
				fromBrands={true}
			/>
		);
	};

	const handleExpand = () => {
		setIsAccordionExpanded((current) => !current);
	};

	const renderMenuItems = () => {
		return (
			<React.Fragment>
				{!isMenuOverCatalogueEnabled && (
					<div className="action-item" onClick={() => handlePublishClick(false)}>
						Publish
					</div>
				)}
				<Accordion
					title="Toggle Status"
					isExpanded={isAccordionExpanded}
					handleExpand={handleExpand}
					showDropdownArrow={true}
				>
					<div className="action" onClick={() => handleToggleClick(false, "enable")}>
						Set Online
					</div>
					<div className="action" onClick={() => handleToggleClick(false, "disable")}>
						Set Offline
					</div>
				</Accordion>
				<div className="action-item" onClick={() => handleRemoveClick(false)} style={{ color: "#FF425C" }}>
					Remove Location
				</div>
			</React.Fragment>
		);
	};

	const openContextMenu = (data, props, isMulti) => {
		setContextMenuData({
			id: data?.id
		});
	};

	const closeContextMenu = () => {
		setContextMenuData({
			id: null
		});
		setIsAccordionExpanded(false);
	};

	const closePublishSidedrawer = (success) => {
		if (success) {
			setAllFieldSelected(false);
			setSelectedLocations({});
		}
		setPublishMenuSidebar(false);
	};

	const closeToggleSidedrawer = (success) => {
		if (success) {
			setAllFieldSelected(false);
			setSelectedLocations({});
		}
		setToggleBrandLocationPlatformSidebar(false);
	};

	const renderValuesAndFunctions = {
		handleHoverData,
		handleClickMenuRender,
		contextMenuData,
		renderPopover,
		openContextMenu,
		closeContextMenu,
		renderMenuItems,
		selectedLocations,
		readOnly
	};

	return (
		<div className="brand-locations">
			{
				<Header
					toggleBrandLocationAssociationSidebar={handleBrandLocationAssociationSidebar}
					readOnly={readOnly}
				/>
			}
			{
				<Filters
					filters={data?.stores?.filters}
					setFilter={setFilter}
					currentFilters={currentFilters}
					handleSearch={handleSearch}
					searchQuery={searchQuery}
				/>
			}
			{loading ? (
				<div className="P(10px)">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			) : (
				<>
					<BulkActions
						selectedLocationsCount={selectedLocationsCount}
						handleRemoveClick={handleRemoveClick}
						handleToggleClick={handleToggleClick}
						handlePublishClick={handlePublishClick}
						isMenuOverCatalogueEnabled={isMenuOverCatalogueEnabled}
					/>
					<FormTable
						dataSource={data?.stores?.objects}
						renderValuesAndFunctions={renderValuesAndFunctions}
						columns={columns}
						placeholderContent={placeholderContent}
						contentTableClass="brand-locations--table"
						isSelectionEnabled
						handleAllFieldsSelection={handleAllFieldSelection}
						handleSingleFieldSelection={handleSingleFieldSelection}
						isAllFieldSelected={isAllFieldSelected}
						selectedFields={selectedLocations}
						readOnly={readOnly}
					/>
					{/* <TableToolTip
					isVisible={hoverData.show}
					position={hoverData.position}
					handleMouseEnter={handleTooltipMouseEnter}
					handleMouseLeave={handleTooltipMouseLeave}
				>
					<div className='brand-loc-tooltip-data-container'>
						Click to get ILPA logs
						 <div className='brand-loc-info'>HSR, ZOMATO</div>
						<div
							className='brand-verification-info'
							style={{
								borderBottom: '1px solid #eaeaea',
								padding: '12px'
							}}
						>
							<div className='brand-basic-info'></div>
							<div className='brand-verification-status'>
								<div className='status'>Verification failed</div>
								<div className='data'>At 02 Dec, 7:23PM</div>
							</div>
						</div>
						<div style={{
							display: 'flex',
							justifyContent: 'flex-end',
							padding: '12px',
						}}
							className='action-tab'
						>
							<span className='link-text hyperlink'>Fix Warnings</span>
						</div>
					</div>
				</TableToolTip> */}
					{/* {
					clickMenuData?.show &&
					<RightClickMenuContext
						isUnbordered
						isPositionFixed
						anchorPoint={clickMenuData.anchorPoint}
					>
						<li onClick={() => handlePublishClick(false)}>Publish</li>
						<li onClick={() => handleToggleClick(false)}>Toggle Status</li>
						<li onClick={() => handleRemoveClick(false)} style={{color: '#FF425C'}}>Remove Location</li>
					</RightClickMenuContext>
				} */}
					<BrandLocationAssociations
						isOpen={brandLocationAssociationSidebar}
						close={handleBrandLocationAssociationSidebar}
						brandId={brandId}
						brandName={brandName}
						image={image}
					/>
					<StoreToggle
						isOpen={toggleBrandLocationPlatformSidebar}
						close={closeToggleSidedrawer}
						isNested
						locations={sidebarData?.locations || []}
						selectedBrand={{
							id: brandId,
							name: brandName,
							image: brandImage
						}}
						fromBrands={true}
						action={toggleAction}
					/>
					<PublishCatalogue
						isOpen={publishMenuSidebar}
						close={closePublishSidedrawer}
						isNested
						locations={sidebarData?.locations || []}
						selectedBrand={{
							id: brandId,
							name: brandName,
							image: brandImage
						}}
						fromBrands={true}
					/>

					{/* <ToggleBrandLocationPlatforms
					isOpen={toggleBrandLocationPlatformSidebar}
					close={handleBrandLocationPlatformSidebar}
				/>
				<StoreToggle
					isOpen
					close={() => {}}
					isNested
					// locations=Array of locations*
					selectedBrand={{
						id, name, image
					}}
				/>
				<PublishCatalogue
					isOpen
					close={() => {}}
					isNested
					// locations=Array of locations*
					selectedBrand={{
						id, name, image
					}}
				/> */}
					<Paginator
						limit={10}
						offset={offset}
						count={data?.stores?.count || 0}
						goToPage={handlePagination}
					/>
				</>
			)}
		</div>
	);
};
export default Locations;

const Header = ({ toggleBrandLocationAssociationSidebar, readOnly = true }) => (
	<div className="header-container">
		<div className="header-text">
			<div className="title">Associated Locations</div>
			<div className="sub-title">
				{" "}
				Manage locations where this brand is sold and the platforms it is available on
			</div>
		</div>
		<Button classes={readOnly ? "disabled" : ""} clickHandler={toggleBrandLocationAssociationSidebar}>
			Add Locations
		</Button>
	</div>
);

const Filters = ({ filters, setFilter, currentFilters, handleSearch, searchQuery }) => {
	const cityFilter = filters?.filter((filter) => filter.field === "city");
	const tagFilter = filters?.filter((filter) => filter.field === "tags");
	return (
		<div className="filters">
			<div className="dropdown-filters">
				<SelectFilter
					placeholder="City"
					options={cityFilter?.[0]?.values}
					labelKey="valueForDisplay"
					valueKey="value"
					setFilter={setFilter}
					currValue={currentFilters?.city}
					field="city"
				/>
				<SelectFilter
					placeholder="Tags"
					options={tagFilter?.[0]?.values}
					labelKey="valueForDisplay"
					valueKey="value"
					setFilter={setFilter}
					currValue={currentFilters?.tags}
					field="tags"
				/>
			</div>
			<SearchFilter
				placeholder="Search"
				setFilter={handleSearch}
				value={searchQuery}
				filterOption={{ field: "default" }}
			/>
		</div>
	);
};

const BulkActions = ({
	selectedLocationsCount,
	handleToggleClick,
	handleRemoveClick,
	handlePublishClick,
	isMenuOverCatalogueEnabled
}) => {
	return (
		<Transition
			native
			from={{ opacity: 0 }}
			enter={{ opacity: 1 }}
			leave={{ opacity: 0 }}
			items={selectedLocationsCount > 0}
			config={config.stiff}
		>
			{(isOpen) =>
				isOpen &&
				((props) => (
					<animated.div className="bulk-actions-container" style={props}>
						<div className="selected-entity-counter">{selectedLocationsCount} location(s) selected</div>
						<div className="actions-container">
							{!isMenuOverCatalogueEnabled && (
								<div className="link-text hyperlink" onClick={() => handlePublishClick(true)}>
									Publish
								</div>
							)}
							<SelectFilter
								field="toggleStatus"
								options={[
									{ label: "Set Online", value: "enable" },
									{ label: "Set Offline", value: "disable" }
								]}
								currValue={{ label: "Toggle Status", value: "toggle" }}
								setFilter={(f, v) => handleToggleClick(true, v?.value)}
								isClearable={false}
								isSearchable={false}
							/>
							{/* <div className='link-text hyperlink' onClick={() => handleToggleClick(true)}>Toggle Status</div> */}
							<div className="link-text hyperlink remove-loc" onClick={() => handleRemoveClick(true)}>
								Remove
							</div>
						</div>
					</animated.div>
				))
			}
		</Transition>
	);
};
// Tables used in Atlas has a lot of flex contents and building a tooltip on top of each of them wont be straight forward.
const TableToolTip = ({ isVisible, position, children, handleMouseEnter, handleMouseLeave }) => {
	return (
		<Transition
			native
			from={{ opacity: 0 }}
			enter={{ opacity: 1 }}
			leave={{ opacity: 0 }}
			items={isVisible}
			config={config.stiff}
		>
			{(isOpen) =>
				isOpen &&
				((props) => (
					<animated.div
						className="table--tooltip"
						style={{ ...props, left: position.x, top: position.y }}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						<span className="arrow"></span>
						{children}
					</animated.div>
				))
			}
		</Transition>
	);
};

// action types
import { ActionTypes } from "../actions/_types";

export const DSP_PLATFORMS_LIST_INITIAL_STATE = {
	limit: 20,
	offset: 0,
	isLoading: true,
	count: 0,
	objects: [],
	filters: []
};
export const dspPlatformsList = (state = DSP_PLATFORMS_LIST_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_DSP_PLATFORMS_LIST_STATE:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};

export const DSP_INTEGRATION_INITIAL_STATE = {
	loading: false,
	platform: null,
	isDSPIntegrated: false,
	isValidDSP: false,
	selectedTab: 1,
	limit: 10,
	offset: 0,
	currentFilters: {
		store_status: { valueForDisplay: "All", value: "all" },
		chefz_status: { valueForDisplay: "All", value: "all" }
	},
	appliedFilters: {
		store_status: { valueForDisplay: "All", value: "all" },
		chefz_status: { valueForDisplay: "All", value: "all" }
	},
	searchFieldSelected: { key: "default", valueForDisplay: "Default" },
	searchFieldValue: "",
	data: {
		count: 0,
		filters: [],
		searchKeywords: [{ key: "default", valueForDisplay: "Default" }],
		objects: [],
		storesCount: 0,
		mappedStoresCount: 0,
		activeStoresCount: 0,
		unmappedStoresCount: 0
	},
	selectedBrand: null,
	mappedLocations: {},
	associationUpdates: {},
	aggregatedStatus: []
};
export const dspIntegration = (state = DSP_INTEGRATION_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_DSP_INTEGRATION_STATE:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};

// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import { GET_USERS_LIST, GET_USER, EDIT_USER } from "../graphql/users";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";
import { GET_CURRENT_USER } from "../graphql/misc";

export const fetchUsersList = async () => {
	const { limit, offset, sort, appliedFilters } = store.getState().usersListState;
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_USERS_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		// sidebar filters
		let filtersObject = [];
		let processedActiveFilter = false;
		Object.keys(appliedFilters).forEach((f) => {
			if (typeof appliedFilters[f] === "object") {
				if (appliedFilters[f].value) {
					filtersObject.push(appliedFilters[f]);
				}
			} else {
				if (f === "is_active") {
					// this value is being reversed because the behaviour of
					// is_active filter has been changed to work as if it's
					// is_archived.
					filtersObject.push({
						field: f,
						value: !appliedFilters[f]
					});
					processedActiveFilter = true;
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			}
		});

		if (!processedActiveFilter) {
			filtersObject.push({
				field: "is_active",
				value: true
			});
		}

		// sort
		if (sort.field !== "" && sort.order !== "") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		const resp = await client.query({
			query: GET_USERS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_USERS_LIST_SUCCESS,
			payload: { ...resp.data.users }
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_USERS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};

const parseUserData = (data, to = "form") => {
	let result = { ...data };
	switch (to) {
		case "form":
			break;
		case "archive":
			if (result["locations"]) {
				const showLocations =
					result["groups"]?.find((role) => role.id === "53" || role.id === "55" || role.id === "66") !==
					undefined
						? true
						: false;
				let locations = [];
				if (showLocations) {
					locations = result["locations"].map((loc) => loc.id);
				}
				result["locations"] = locations;
			}
			if (result["groups"]) {
				const groups = result["groups"].map((grp) => grp.id);
				result["groups"] = groups;
			}
			delete result.username;
			delete result.email;
			break;
		case "server":
			if (result["locations"]) {
				const showLocations =
					result["groups"]?.find((role) => role.id === "53" || role.id === "55" || role.id === "66") !==
					undefined
						? true
						: false;
				let locations = [];
				if (showLocations) {
					locations = result["locations"].map((loc) => loc.id);
				}
				result["locations"] = locations;
			}
			if (result["groups"]) {
				const groups = result["groups"].map((grp) => grp.id);
				result["groups"] = groups;
			}
			delete result.isActive;
			delete result.username;
			delete result.email;
			break;
		default:
			break;
	}
	return result;
};

export const fetchUserDetail = async (id, dispatch) => {
	dispatch({
		type: ActionTypes.GET_USERS_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		const resp = await client.query({
			query: GET_USER,
			variables,
			fetchPolicy: "no-cache"
		});
		dispatch({
			type: ActionTypes.GET_USERS_DETAIL_SUCCESS,
			payload: parseUserData(resp.data.user, "form")
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.GET_USERS_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching User details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const archiveRestoreUser = async (data) => {
	try {
		const resp = await client.mutate({
			mutation: EDIT_USER,
			variables: parseUserData(data, "archive")
		});
		if (resp.data.updateUser.status.success) {
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.updateUser.status.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		throw error;
	}
};

export const editUserDetail = async (data, dispatch) => {
	dispatch({
		type: ActionTypes.EDIT_USERS_DETAIL_REQUEST
	});
	try {
		const resp = await client.mutate({
			mutation: EDIT_USER,
			variables: parseUserData(data, "server")
		});
		if (resp.data.updateUser.status.success) {
			dispatch({
				type: ActionTypes.EDIT_USERS_DETAIL_SUCCESS
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "User details saved!",
					timeout: 2000,
					error: false
				}
			});
			store.dispatch({
				type: ActionTypes.UPDATE_USERS_LIST,
				payload: resp.data.updateUser.object
			});
			return true;
		} else {
			// handle error message
			dispatch({
				type: ActionTypes.EDIT_USERS_DETAIL_FAILURE,
				error: parseErrorMessages(resp.data.updateUser.status.messages)
			});
		}
	} catch (error) {
		console.log(error);
		dispatch({
			type: ActionTypes.EDIT_USERS_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving User details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const fetchCurrentUserDetail = async () => {
	try {
		const resp = await client.query({
			query: GET_CURRENT_USER,
			fetchPolicy: "no-cache"
		});
		let user_data = {};
		if (resp)
			user_data = {
				associatedBrands: resp.data.me.object.associatedBrands,
				associatedLocations: resp.data.me.object.associatedLocations,
				authUserId: resp.data.me.object.authUserId,
				bizId: resp.data.me.object.bizId,
				bizName: resp.data.me.object.bizName,
				roles: resp.data.me.object.roles
			};

		window.lS.set("user_access", user_data);
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_USERS_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Current User's details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

import React, { useEffect, useMemo, useRef, useState } from "react";
import Header from "../../components/Analytics/Common/Header";
import SectionHeader from "../../components/Analytics/Common/SectionHeader";
import MetricCard from "../../components/Analytics/Common/MetricCard";
import MetricSection from "../../components/Analytics/Common/MetricSection";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ANALYTICS_DEFAULT_COLORS, ANALYTICS_SECTIONS } from "../../client-config";
import { Topbar } from "../../components/_commons/Topbar";
import { commifyNumbers, getSortOrder, scroll, trackEvent } from "../../atlas-utils";
import ChartMetric from "../../components/Analytics/Common/ChartMetric";
import MultiChartMetrics from "../../components/Analytics/Common/MultiChartMetrics";
import MetricHeader from "../../components/Analytics/Common/MetricHeader";
import DownloadButton from "../../components/Analytics/Common/DownloadButton";
import Line from "../../components/NivoCharts/Line";
import {
	captureScreenshot,
	generateDurationText,
	getDurationPreset,
	getReadableDateFilter,
	trackPNGDownload
} from "../../helpers/analytics";
import ChartSelector from "../../components/Analytics/Common/ChartSelector";
import {
	// fetchAndGenerateCSVReport,
	fetchCustomerBreakdownTable,
	// fetchCustomersBreakdownChart,
	fetchNewCustomersBreakdownChart,
	fetchNewCustomersBreakdownTable,
	fetchActiveCustomerMetrics,
	fetchTotalCustomersBreakdownChart,
	// fetchTotalCustomersBreakdownValues,
	getAllAppliedFilters,
	updateCustomerAnalyticsState,
	fetchRepeatCustomerMetrics,
	fetchAndGenerateCSVReport
} from "../../actions/analytics";
import Filters, { BreakdownByFilter } from "../../components/Analytics/Common/Filters";
import Pie from "../../components/NivoCharts/Pie";
import { CustomTable } from "../../components/_commons/CustomTable";
import he from "he";
import Bar from "../../components/NivoCharts/Bar";
import ShowComparison from "../../components/Analytics/Common/ShowComparison";

const Customers = ({ customerAnalytics, isMultibrandEnabled = false, currencySymbol, analyticsReportState }) => {
	const { search } = useLocation();

	const [isPageScrolled, setPageScrolled] = useState(document?.documentElement?.scrollTop > 60 || false);
	const query = useMemo(() => new URLSearchParams(search), [search]);

	const handlePageScroll = () => {
		if (document.documentElement.scrollTop > 60) {
			setPageScrolled(true);
		} else {
			setPageScrolled(false);
		}
	};

	useEffect(() => {
		if (query.get("metric")) {
			switchTab({ value: query.get("metric") });
		} else {
			document.documentElement.scrollTop = 0;
		}
		trackEvent("analytics_module", { module: "Customers" });
		window.addEventListener("scroll", handlePageScroll);
		return () => {
			window.removeEventListener("scroll", handlePageScroll);
		};
	}, []);
	const { appliedFilters } = customerAnalytics;
	const areMultipleBrandsSelected =
		Boolean(appliedFilters?.brand_id?.find((brand) => brand === "all")) || appliedFilters?.brand_id?.length > 1;
	const areMultiplePlatformsSelected =
		Boolean(appliedFilters?.platform_names?.find((plf) => plf === "all")) ||
		appliedFilters?.platform_names?.length > 1;
	const [currTab, setCurrTab] = useState(ANALYTICS_SECTIONS["customers"]?.["tabs"]?.[0]?.value);
	const refs = {
		customer_summary: useRef(),
		total_customers: useRef(),
		customers_breakdown: useRef(),
		new_customers_breakdown: useRef()
	};

	const switchTab = (tab, tabClick = false) => {
		setCurrTab(tab.value);
		window.requestAnimationFrame(() => {
			scroll(
				{
					top: refs[tab.value]?.current?.offsetTop - (document.querySelector(".with-banner") ? 290 : 230),
					left: 0
				},
				window,
				"instant"
			);
		});
		// track event
		if (tabClick) {
			trackEvent("analytics_tab_clicked", {
				module: "Customer",
				tab: tab.label
			});
		}
	};

	const renderCenterMetricValue = (props) => {
		let total = 0;
		props.dataWithArc.forEach((datum) => {
			total += Math.round(datum.value);
		});
		return `${he.unescape(currencySymbol)}${commifyNumbers(total)}`;
	};

	const generateImage = (key, title = "Revenue", subChart) => {
		const durationObject = getAllAppliedFilters()?.durationObject?.duration;
		const comparisonDuration = getAllAppliedFilters()?.durationObject?.comparisonDuration;

		let timeDuration = durationObject?.preset
			? `${getDurationPreset(durationObject?.preset)?.label}`
			: durationObject?.preset ||
				`${generateDurationText(durationObject?.custom?.startDate, durationObject?.custom?.endDate)}`;
		timeDuration += `${comparisonDuration ? ` / Compared to ${generateDurationText(comparisonDuration?.startDate, comparisonDuration?.endDate)}` : ""}`;

		if (key === "customers_breakdown" || key == "total_customers") {
			const metricSectionElement = refs[key]?.current?.querySelector(`.metric-section .nivo-chart-${subChart}`);
			if (metricSectionElement) {
				let elementRef = { current: metricSectionElement };
				captureScreenshot(elementRef, title, timeDuration).then((res) => {
					trackPNGDownload(key, subChart);
				});
			}
		} else if (key === "new_customers_breakdown") {
			const metricSectionElement = refs[key]?.current?.querySelector(".metric-section .chart-container");
			if (metricSectionElement) {
				let elementRef = { current: metricSectionElement };
				captureScreenshot(elementRef, title, timeDuration).then((res) => {
					trackPNGDownload(key, subChart);
				});
			}
		} else {
			captureScreenshot(refs[key], title, timeDuration).then((res) => {
				trackPNGDownload(key, subChart);
			});
		}
	};

	return (
		<div className="analytics-customer section-container-common">
			<Header>
				<SectionHeader title="Customer Analytics" showHelpBtn isPageScrolled={isPageScrolled} />
				<Filters showBrands showLocations showPlatforms showDateCompare module="Catalogue" />

				<Topbar
					tabs={ANALYTICS_SECTIONS["customers"]["tabs"]}
					selectedTab={currTab}
					switchTab={(tab) => switchTab(tab, true)}
				/>
			</Header>
			<MetricCard
				metric="customer_summary"
				tabMetric="customer_summary"
				cardRef={refs["customer_summary"]}
				classes="customer_summary"
			>
				{(metric, metricCardInViewport) => (
					<>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchActiveCustomerMetrics}
							state={customerAnalytics}
						>
							{({ metrics }) => (
								<MultiChartMetrics>
									<ChartMetric
										size="large"
										loading={metrics?.active_customers?.metricsLoading}
										label="Active Customers"
										description="Measures the typical time taken to deliver an order after its acceptance. Helps assess the efficiency of the delivery process."
										value={metrics?.active_customers?.value}
										percentageChange={metrics?.active_customers?.percentageChange}
										compareValue={metrics?.active_customers?.compareValue}
									/>
								</MultiChartMetrics>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchRepeatCustomerMetrics}
							state={customerAnalytics}
						>
							{({ metrics }) => (
								<>
									<MultiChartMetrics>
										<ChartMetric
											size="large"
											loading={metrics?.repeat_customers?.metricsLoading}
											label="Repeat Customers"
											description="Measures the typical time taken to deliver an order after its acceptance. Helps assess the efficiency of the delivery process."
											value={metrics?.repeat_customers?.value}
											percentageChange={metrics?.repeat_customers?.percentageChange}
											compareValue={metrics?.repeat_customers?.compareValue}
										/>
									</MultiChartMetrics>
								</>
							)}
						</MetricSection>
					</>
				)}
			</MetricCard>

			<MetricCard metric="total_customers" cardRef={refs["total_customers"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<React.Fragment>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchActiveCustomerMetrics}
							state={customerAnalytics}
						>
							{({ metrics, chartLoading, graphData }) => (
								<React.Fragment>
									<MetricHeader>
										<div className="header-left">
											<ChartMetric
												size="large"
												loading={metrics?.active_customers?.metricsLoading}
												label="Total Customers"
												description="Average transaction value of an order during the selected time frame. It is the net revenue divided by the total orders recieved for the selected time frame."
												value={metrics?.active_customers?.value}
												percentageChange={metrics?.active_customers?.percentageChange}
												compareValue={metrics?.active_customers?.compareValue}
											/>
										</div>
										<div className="header-right">
											<DownloadButton
												emptyData={
													((graphData || []).length == 0 || !metrics?.total_customers) &&
													!metrics?.active_customers?.metricsLoading &&
													!chartLoading
												}
												tooltipText="Export Chart as PNG"
												loading={metrics?.active_customers?.metricsLoading || chartLoading}
												handleClick={() => {
													!metrics?.active_customers?.metricsLoading &&
														!chartLoading &&
														(graphData || []).length !== 0 &&
														generateImage("total_customers", "Total Customers", "line");
												}}
												coachMark={true}
											/>
										</div>
									</MetricHeader>
								</React.Fragment>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchTotalCustomersBreakdownChart}
							state={customerAnalytics}
						>
							{({ chartLoading, graphData, yScaleMax }, applDateFilter) => (
								<>
									<Line
										height={400}
										data={graphData}
										metric={metric}
										durationPreset={applDateFilter?.current?.dateFilter}
										loading={chartLoading}
										marginLeft={70}
										yScaleMax={yScaleMax}
										axisBottomTickRotation={graphData?.[0]?.data?.length > 7 ? -45 : 0}
										axisLeftLegendOffset={-60}
										enableArea={true}
										colors={["#2543B6"]}
										showCompareInTooltip={applDateFilter?.compare?.dateFilter}
										dashedLines={applDateFilter?.compare?.dateFilter}
										dashedLineIds={applDateFilter?.compare?.dateFilter ? [`Total Customers*`] : []}
										customAxisLeft={false}
										customAxisLeftCondition={(value) => (Number.isInteger(value) ? value : "")}
										legendItemWidth={applDateFilter?.compare?.dateFilter ? 130 : 200}
										yScaleStacked={false}
									/>
								</>
							)}
						</MetricSection>
					</React.Fragment>
				)}
			</MetricCard>

			<MetricCard metric="customers_breakdown" cardRef={refs["customers_breakdown"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<React.Fragment>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={
								() => {}
								// fetchCustomersBreakdownChart
							}
							state={customerAnalytics}
							dependencies={[
								customerAnalytics[metric]?.selectedChart,
								customerAnalytics[metric]?.breakdownBy?.value
							]}
						>
							{(
								{
									chartLoading,
									selectedChart = "pie", // breakdownBy, 	graphData,
									yScaleMax,
									tabularData,
									tableLoading
								},
								applDateFilter
							) => (
								<React.Fragment>
									<MetricHeader>
										<div className="header-left">
											<ChartMetric
												size="large"
												label="Customers Breakdown"
												description="A detailed view of net revenue categorised by different platforms or brands."
											/>
										</div>
										{/* <div className="header-right">
											<ChartSelector
												selectedChart={selectedChart}
												options={["pie", "line"]}
												clickHandler={(chart) => {
													updateCustomerAnalyticsState(metric, {
														selectedChart: chart
													});
													trackEvent("analytics_chart_type_switched", {
														module: "Customer",
														origin: metric,
														from: selectedChart,
														to: chart
													});
												}}
											/>
											<DownloadButton
												emptyData={
													(tabularData?.rows || []).length == 0 &&
													!tableLoading &&
													!chartLoading
												}
												tooltipText="Export"
												classes={
													(tabularData?.rows || []).length !== 0 &&
													!tableLoading &&
													!chartLoading
														? "center"
														: ""
												}
												loading={
													analyticsReportState?.customers_breakdown?.loading ||
													tableLoading ||
													chartLoading
												}
												options={[
													{ value: "png", valueForDisplay: "Export Chart as PNG" },
													{ value: "csv", valueForDisplay: "Export Table as CSV" }
												]}
												handleClick={(opt) => {
													if (opt == "csv") {
														!analyticsReportState?.customers_breakdown?.loading &&
															!tableLoading &&
															!chartLoading &&
															(tabularData?.rows || []).length !== 0 &&
															fetchAndGenerateCSVReport(
																fetchCustomerBreakdownTable,
																"getTabularData",
																["customers_breakdown", true]
															);
													} else if (opt == "png") {
														!chartLoading &&
															generateImage(
																"customers_breakdown",
																"Total Customers Breakdown",
																selectedChart
															);
													}
												}}
											/>
										</div> */}
									</MetricHeader>
									{/* {selectedChart === "pie" && (
										<Pie
											height={400}
											marginTop={40}
											marginBottom={100}
											data={graphData?.pie || []}
											colors={[...ANALYTICS_DEFAULT_COLORS]}
											idKey="name"
											arcLabel="name"
											loading={chartLoading}
											// commifyArcLinkLabel={true}
											enableCenterMetric={true}
											enableLegends={false}
											renderCenterMetricLabel={() => "Net Revenue"}
											renderCenterMetricValue={renderCenterMetricValue}
											sortByValue={false}
											// currency={currency}
											// currencySymbol={currencySymbol}
										/>
									)}
									{selectedChart == "line" && (
										<>
											<Line
												height={400}
												data={graphData?.line || []}
												durationPreset={applDateFilter?.current?.dateFilter}
												// colors={[...ANALYTICS_DEFAULT_COLORS.slice(0, graphData?.line?.length)]}
												colors={{ datum: "color" }}
												loading={chartLoading}
												enableLegends={false}
												yScaleMax={yScaleMax}
												showCompareInTooltip={applDateFilter?.compare?.dateFilter}
												// axisBottomLegend="Date"
												// axisLeftLegend="Revenue"
												suffixedYValues={true}
												axisBottomTickRotation={
													graphData?.line?.[0]?.data?.length > 7 ? -45 : 0
												}
												dashedLines={applDateFilter?.compare?.dateFilter}
												dashedLineIndices={applDateFilter?.compare?.dateFilter ? "odd" : ""}
												showDashedLineOnHover={true}
												currencySymbol={currencySymbol}
											/>
										</>
									)} */}
								</React.Fragment>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchCustomerBreakdownTable}
							state={customerAnalytics}
							dependencies={[customerAnalytics[metric]?.sort]}
						>
							{({ tableLoading, tabularData, legends, sortedField, sort }) => (
								<>
									<CustomTable
										forAnalytics={true}
										loading={tableLoading}
										data={tabularData?.rows || []}
										columns={tabularData?.columns || []}
										legends={legends}
										sortList={(field) =>
											updateCustomerAnalyticsState(metric, {
												sort: {
													field: field,
													order: getSortOrder(sort, { field })
												},
												sortedField: field
											})
										}
										sortedField={sortedField}
										content="results"
										sortedOrder={sort?.order}
										classes="customers-breakdown-table-container"
									/>
								</>
							)}
						</MetricSection>
					</React.Fragment>
				)}
			</MetricCard>

			<MetricCard
				metric="new_customers_breakdown"
				cardRef={refs["new_customers_breakdown"]}
				setCurrTab={setCurrTab}
			>
				{(metric, metricCardInViewport) => (
					<>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchNewCustomersBreakdownChart}
							state={customerAnalytics}
							dependencies={[
								customerAnalytics[metric]?.showComparison,
								customerAnalytics[metric]?.selectedChart
							]}
						>
							{(
								{
									chartLoading,
									selectedChart = "bar",
									showComparison = false,
									graphData,
									yScaleMax,
									maxValue
								},
								applDateFilter
							) => (
								<React.Fragment>
									<MetricHeader>
										<div className="header-left">
											<ChartMetric
												size="large"
												label="New Customers Breakdown"
												description="Lost revenue categorisation based on reasons for order cancellations, segmented by acknowledgment status and source."
											/>
										</div>
										<div className="header-right">
											<ChartSelector
												selectedChart={selectedChart}
												options={["bar", "line"]}
												clickHandler={(chart) => {
													updateCustomerAnalyticsState(metric, {
														selectedChart: chart
													});
													trackEvent("analytics_chart_type_switched", {
														module: "Customers",
														origin: metric,
														from: selectedChart,
														to: chart
													});
												}}
											/>

											<DownloadButton
												handleClick={(opt) => {
													if (opt == "csv") {
														!analyticsReportState?.new_customers_breakdown?.loading &&
															!customerAnalytics?.new_customers_breakdown?.tableLoading &&
															(
																customerAnalytics?.new_customers_breakdown?.tabularData
																	?.rows || []
															).length !== 0 &&
															fetchAndGenerateCSVReport(
																fetchNewCustomersBreakdownTable,
																"getTabularData",
																["new_customers_breakdown", true]
															);
													} else if (opt == "png") {
														!chartLoading &&
															generateImage(
																"new_customers_breakdown",
																"New Revenue Breakdown",
																selectedChart
															);
													}
												}}
												options={[
													{ value: "png", valueForDisplay: "Export Chart as PNG" },
													{ value: "csv", valueForDisplay: "Export Table as CSV" }
												]}
												tooltipText="Export"
												emptyData={
													(
														customerAnalytics?.new_customers_breakdown?.tabularData?.rows ||
														[]
													).length == 0 &&
													customerAnalytics?.new_customers_breakdown?.graphData?.[
														selectedChart
													].length == 0 &&
													!customerAnalytics?.new_customers_breakdown?.tableLoading &&
													!chartLoading
												}
												classes={
													(
														customerAnalytics?.new_customers_breakdown?.tabularData?.rows ||
														[]
													).length !== 0 &&
													customerAnalytics?.new_customers_breakdown?.graphData?.[
														selectedChart
													].length !== 0 &&
													"center"
												}
												loading={
													analyticsReportState?.new_customers_breakdown?.loading ||
													customerAnalytics?.new_customers_breakdown?.tableLoading ||
													chartLoading
												}
											/>
										</div>
									</MetricHeader>
									<ShowComparison
										loading={chartLoading}
										isChecked={showComparison}
										clickHandler={() =>
											updateCustomerAnalyticsState(metric, {
												showComparison: !showComparison
											})
										}
										module="Revenue"
										metric={metric}
										readOnly={!applDateFilter?.compare?.dateFilter}
									/>
									<div className="chart-container">
										{selectedChart === "bar" && (
											<Bar
												height={400}
												data={graphData?.bar}
												marginBottom={130}
												loading={chartLoading}
												colors={[...ANALYTICS_DEFAULT_COLORS]}
												keys={
													showComparison && applDateFilter?.compare?.dateFilter
														? ["New Customers", "New Customers*"]
														: ["New Customers"]
												}
												patternIds={[
													{
														match: {
															id: "previous"
														},
														id: "lines"
													}
												]}
												maxValue={maxValue}
												groupMode={"grouped"}
												enableLegends={!showComparison || !applDateFilter?.compare?.dateFilter}
												customLegends={showComparison && applDateFilter?.compare?.dateFilter}
												padding={
													showComparison && applDateFilter?.compare?.dateFilter ? 0.6 : 0.8
												}
												innerPadding={20}
												applDateFilter={applDateFilter}
												indexBy="label"
												axisBottomTickRotation={graphData?.bar?.length > 7 ? -35 : 0}
												suffixedYValues={false}
											/>
										)}
										{selectedChart === "line" && (
											<Line
												height={400}
												data={graphData?.line || []}
												durationPreset={applDateFilter?.current?.dateFilter}
												colors={{ datum: "color" }}
												showCompareInTooltip={
													showComparison && applDateFilter?.compare?.dateFilter
												}
												dashedLines={showComparison && applDateFilter?.compare?.dateFilter}
												dashedLineIndices={applDateFilter?.compare?.dateFilter ? "odd" : ""}
												showDashedLineOnHover={true}
												enableLegends={!showComparison || !applDateFilter?.compare?.dateFilter}
												loading={chartLoading}
												yScaleMax={yScaleMax}
												suffixedYValues={true}
												axisBottomTickRotation={
													graphData?.line?.[0]?.data?.length > 7 ? -45 : 0
												}
											/>
										)}
									</div>
								</React.Fragment>
							)}
						</MetricSection>

						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchNewCustomersBreakdownTable}
							state={customerAnalytics}
							dependencies={[customerAnalytics[metric]?.sort]}
						>
							{({ tableLoading, tabularData, sortedField, sort }) => (
								<CustomTable
									forAnalytics={true}
									loading={tableLoading}
									data={tabularData?.rows || []}
									columns={tabularData?.columns || []}
									sortList={(field) =>
										updateCustomerAnalyticsState(metric, {
											sort: {
												field: field,
												order: getSortOrder(sort, { field })
											},
											sortedField: field
										})
									}
									sortedField={sortedField}
									sortedOrder={sort?.order}
									classes="new-customers-breakdown-table-container"
								/>
							)}
						</MetricSection>
					</>
				)}
			</MetricCard>
		</div>
	);
};

export default connect((store) => ({
	analyticsFiltersState: store.analyticsFiltersState,
	currencySymbol: store.login.loggedInbizDetail.currencySymbol,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	analyticsReportState: store.analyticsReportState,
	customerAnalytics: store.customerAnalytics
}))(Customers);

import gql from "graphql-tag";
export const INVOICES_LIST_VIEW = gql`
	query getInvoices($limit: Int!, $offset: Int!) {
		invoices(limit: $limit, offset: $offset) {
			count
			limit
			offset
			outstandingAmount
			totalBilledAmount
			totalPaidAmount
			objects {
				id
				externalRefId
				invType
				invoiceDate
				invoiceLink
				source
				title
				number
				txnId
				grossAmount
				netAmount
				finalAmount
				totalTax
				discount
				dueDate
				status
				created
				updated
				currency
				currencySymbol
				creditInvoices {
					externalRefId
					amount
				}
				statusUpdates {
					created
					updated
					status
				}
			}
		}
	}
`;

export const INVOICE_DETAIL_VIEW = gql`
	query getInvoices($id: Int!) {
		invoice(id: $id) {
			id
			externalRefId
			invType
			invoiceDate
			invoiceLink
			source
			title
			number
			txnId
			grossAmount
			netAmount
			totalTax
			discount
			dueDate
			status
			created
			updated
			currency
			currencySymbol
			finalAmount
			paymentLink
			franchisee {
				id
				gstin
				name
				addressLine1
				addressLine2
				city
				state
				country
				postalCode
			}
			paymentTransactions {
				txnId
				txnAmount
				txnDate
			}
			invoicePayments {
				id
				amount
				txnDate
			}
			creditInvoices {
				externalRefId
				amount
				created
			}
			statusUpdates {
				created
				updated
				status
			}
			items {
				taxRate
				type
				quantity
				unitPrice
				netAmount
				grossAmount
				discount
				billingEntity {
					title
					description
					unitType
				}
				taxes {
					id
					title
					description
					rateType
					rate
					value
				}
			}
		}
	}
`;

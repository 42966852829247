import React, { useState } from "react";

// third party
import history from "../../history";
import { useSpring, animated as a } from "react-spring";

// utils
import { lS, extractInitials } from "../../atlas-utils";
import { store } from "../../store/configureStore";
import { ActionTypes } from "../../actions/_types";

const BrandSelection = ({ brands }) => {
	const contentProps = useSpring({
		from: { transform: "scale(0)", opacity: "0" },
		to: { transform: "scale(1)", opacity: "1" }
	});

	const handleBrandSelection = (brand) => {
		// now route to main landing page
		lS.set("brand", brand);
		store.dispatch({
			type: ActionTypes.UPDATE_SELECTED_BRAND,
			payload: brand
		});
		history.push("/");
	};
	const associatedBrands = store.getState().user_access?.associatedBrands ?? [];
	const access = store.getState().login.loginDetail.access;

	const [filteredBrands, setFilteredBrands] = useState(
		!access?.isAdmin ? [...new Set(associatedBrands?.map((brand) => brand?.id))] : []
	);
	return (
		<div className="login-brands-selection">
			<div className="top-header">
				<img src="/assets/up-logo.svg" alt="" />
			</div>
			<a.div className="card-container" style={contentProps}>
				<div className="card-header">Select a brand</div>
				<div className="text">Your brands ({brands.items.length - 1})</div>
				<div className="brands-list">
					{brands.isLoading && (
						<div className="P(10px)">
							<div className="shimmer H(72px) Mb(10px)" />
							<div className="shimmer H(72px) Mb(10px)" />
						</div>
					)}
					{!brands.isLoading &&
						brands.items
							.filter(
								(brand) =>
									brand.id !== "all" &&
									(filteredBrands?.length > 0 ? filteredBrands?.includes(brand.id) : brand)
							)
							.map((brand, i) => (
								<div className="brand-container" key={i} onClick={() => handleBrandSelection(brand)}>
									<div className="brand-info">
										<div className={"brand-initials " + brand.color}>
											{brand.image ? (
												<img src={brand.image} alt="" />
											) : (
												extractInitials(brand.name?.split(" "))
											)}
										</div>
										<div className="brand-name">{brand.name || ""}</div>
									</div>
									<div className="arrow">
										<img src="/assets/icons/ic_left_arrow_gray.svg" alt="" />
									</div>
								</div>
							))}
				</div>
			</a.div>
		</div>
	);
};
export default BrandSelection;

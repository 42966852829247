// utils
import { getLoggedInBizId, isLoggedin, lS } from "../atlas-utils";

// types
import { ActionTypes } from "../actions/_types";

//this block needs be refactored
const INITIAL_STATE = {
	loginDetail: {
		success: isLoggedin() ? true : false,
		id: isLoggedin() ? lS.get("auth").userInfo.id : "",
		name: isLoggedin() ? lS.get("auth").userInfo.fullName : "",
		firstName: isLoggedin() ? lS.get("auth").userInfo.firstName : "",
		lastName: isLoggedin() ? lS.get("auth").userInfo.lastName : "",
		fullName: isLoggedin() ? lS.get("auth").userInfo.fullName : "",
		username: isLoggedin() ? lS.get("auth").userInfo.username : "",
		token: isLoggedin() ? lS.get("auth").token : "",
		email: isLoggedin() ? lS.get("auth").userInfo.email : "",
		phone: isLoggedin() ? lS.get("auth").userInfo.phone : "",
		isdCode: isLoggedin() ? lS.get("auth").userInfo.isdCode : "",
		access: isLoggedin() ? lS.get("auth").userInfo.access : {},
		associatedLocations: isLoggedin() ? lS.get("auth").userInfo.associatedLocations : [],
		verificationDetails: isLoggedin() ? lS.get("auth").userInfo.verificationDetails : {},
		internalUser: isLoggedin() ? (lS.get("auth").userInfo.email?.includes("urbanpiper.com") ? true : false) : false
	},
	loggedInbizDetail: {
		id: isLoggedin() ? lS.get("auth").biz.id : "",
		name: isLoggedin() ? lS.get("auth").biz.name : "",
		modulesEnabled: isLoggedin() ? lS.get("auth").biz.modulesEnabled : [],
		supportedLanguages: isLoggedin() ? lS.get("auth").biz.supportedLanguages : [],
		currency: isLoggedin() ? lS.get("auth").biz.currency : "",
		currencySymbol: isLoggedin() ? lS.get("auth").biz.currencySymbol : "",
		billingCurrency: isLoggedin() ? lS.get("auth").biz.billingCurrency : "",
		billingCurrencySymbol: isLoggedin() ? lS.get("auth").biz.billingCurrencySymbol : "",
		feedbackChoiceTexts: isLoggedin() ? lS.get("auth").biz.feedbackChoiceTexts : "",
		credits: isLoggedin() ? lS.get("auth").biz.credits : "",
		logo: isLoggedin() ? lS.get("auth").biz.logo : "",
		addressLine1: isLoggedin() ? lS.get("auth").biz.addressLine1 : "",
		addressLine2: isLoggedin() ? lS.get("auth").biz.addressLine2 : "",
		city: isLoggedin() ? lS.get("auth").biz.city : "",
		state: isLoggedin() ? lS.get("auth").biz.state : "",
		country: isLoggedin() ? lS.get("auth").biz.country : "",
		postalCode: isLoggedin() ? lS.get("auth").biz.postalCode : "",
		gstin: isLoggedin() ? lS.get("auth").biz.gstin : "",
		contactPersonName: isLoggedin() ? lS.get("auth").biz.contactPersonName : "",
		billingPhones: isLoggedin() ? lS.get("auth").biz.billingPhones : "",
		billingEmails: isLoggedin() ? lS.get("auth").biz.billingEmails : "",
		contactEmails: isLoggedin() ? lS.get("auth").biz.contactEmails : "",
		bizWebsite: isLoggedin() ? lS.get("auth").biz.bizWebsite : "",
		timezone: isLoggedin() ? lS.get("auth").biz.timezone : "",
		name: isLoggedin() ? lS.get("auth").biz.name : "",
		bizIsdCode: isLoggedin() ? lS.get("auth").biz.bizIsdCode : "",
		tin: isLoggedin() ? lS.get("auth").biz.tin : "",
		registeredBusinessName: isLoggedin() ? lS.get("auth").biz.registeredBusinessName : "",
		created: isLoggedin() ? lS.get("auth").biz.created : "",
		fssaiLicenseNumber: isLoggedin() ? lS.get("auth").biz.fssaiLicenseNumber : "",
		isActive: isLoggedin() ? lS.get("auth").biz.isActive : true,
		isTest: isLoggedin() ? lS.get("auth").biz.isTest : false,
		currentState: isLoggedin() ? lS.get("auth").biz.currentState : "",
		isEnforcedOla: isLoggedin() ? lS.get("auth").biz.isEnforcedOla : false,
		eisEnabled: isLoggedin() ? lS.get("auth").biz.eisEnabled : false,
		eisSupportEnabled: isLoggedin() ? lS.get("auth").biz.eisSupportEnabled : false,
		orderPrepTime: isLoggedin() ? lS.get("auth").biz.orderPrepTime : 0,
		busyOrderPrepTime: isLoggedin() ? lS.get("auth").biz.busyOrderPrepTime : 0,
		meraki: isLoggedin() ? lS.get("auth").biz.meraki : {},
		description: isLoggedin() ? lS.get("auth").biz.description : "",
		seoDescription: isLoggedin() ? lS.get("auth").biz.seoDescription : "",
		differentialPricingEnabled: isLoggedin() ? lS.get("auth").biz.differentialPricingEnabled : false,
		isReconEnabled: isLoggedin() ? lS.get("auth").biz.isReconEnabled : false,
		isMultibrandEnabled: isLoggedin() ? lS.get("auth").biz.isMultibrandEnabled : false,
		isPgServiceEnabled: isLoggedin() ? lS.get("auth").biz.isPgServiceEnabled : false,
		isMenuOverCatalogueEnabled: isLoggedin() ? lS.get("auth").biz.isMenuOverCatalogueEnabled : false,
		isBaseMenuValidationEnabled: isLoggedin() ? lS.get("auth").biz.isBaseMenuValidationEnabled : false,
		scheduledActionsAndNotifications: isLoggedin() ? lS.get("auth").biz.scheduledActionsAndNotifications : {}
	},
	creditsCost: isLoggedin() ? lS.get("auth").creditsCost : null,
	creditsMapping: isLoggedin() ? lS.get("auth").creditsMapping : null,
	allCreditsCost: isLoggedin() ? lS.get("auth").allCreditsCost : null,
	authServiceData: isLoggedin() ? lS.get("auth").authServiceData : null,
	uleLogin: isLoggedin() ? lS.get("uleLogin") || false : false,
	showCustomMessage: true,
	showCustomBannerMessage: isLoggedin() ? lS.get("showCustomBannerMessage")?.[getLoggedInBizId()] || false : false,
	isBannerMessageDismissed: isLoggedin() ? lS.get("isBannerMessageDismissed") : { state: false, timeStamp: null },
	loading: false
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.LOGIN_REQUEST:
			return {
				...state,
				loading: true
			};
		case ActionTypes.LOGIN_SUCCESS:
			return {
				...state,
				...action.payload,
				error: false,
				loading: false
			};
		case ActionTypes.LOGIN_FAILRUE:
			return {
				...state,
				error: true,
				loading: false
			};
		case ActionTypes.UPDATE_LOGGEDIN_BIZ_REQUEST:
			return {
				...state,
				loading: true
			};
		case ActionTypes.UPDATE_LOGGEDIN_BIZ_SUCCESS:
			return {
				...state,
				...action.payload,
				error: false,
				loading: false
			};
		case ActionTypes.UPDATE_LOGGEDIN_BIZ_FAILURE:
			return {
				...state,
				error: true,
				loading: false
			};
		case ActionTypes.UPDATE_LOGIN_STATE:
			return {
				...state,
				...action.payload
			};
		default:
			return state;
	}
};

import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { SelectFilterCustom } from "../../../components/_commons/SelectFilterCustom";
import { SelectFilter } from "../../../components/_commons/SelectFilter";
import { Filters } from "../../../components/_commons/Filters";
import {
	fetchBizPlatforms,
	fetchBrands,
	fetchCities,
	fetchStores,
	fetchStoresDebounced
} from "../../../actions/actions";
import { SearchFilter } from "../../../components/_commons/SearchFilter";
import SpecificLocationTableHeader from "../../../components/Periscope/ItemAvailability/SpecificLocationTableHeader";
import SelectedLocationTable from "./SelectedLocationTable";
import { store } from "../../../store/configureStore";
import { extractInitials, lS, scroll } from "../../../atlas-utils";
import {
	fetchDebouncedItemAvailabilitySpecificLocation,
	fetchItemAvailabilitySpecificLocation,
	fetchMenuCategoriesValues,
	fetchPeriscopeEnabledBrands,
	fetchPeriscopeEnabledCities,
	fetchPeriscopeEnabledLocations
} from "../../../actions/periscope";
import { ActionTypes } from "../../../actions/_types";
import EmptyImportantItemState from "../../../components/Periscope/EmptyImportantItemState";
import { getBrandDetail, periscopeFilterEvent } from "../../../helpers/periscope";
import { GET_LOCATION } from "../../../graphql/analytics";
import { client } from "../../../client";

const SpecificLocationTable = ({
	configItems,
	periscopeSpecificItemAvailabilityLocationData,
	periscopeSpecificItemAvailabilityLocationState,
	isMultibrandEnabled,
	login,
	periscopeDataResponse,
	periscopeCountryWiseSupportedPlatforms,
	periscopeFilters
}) => {
	const firstRender = useRef(true);
	const stores = configItems?.stores?.items;
	const specificItemAvailabilityDataFilters = periscopeSpecificItemAvailabilityLocationData?.data?.filters;
	const location = useLocation();
	const queryParams = new URLSearchParams(location?.search);
	const platformId = queryParams.get("platform_id");
	const platformName = queryParams.get("platform");
	const brandId = queryParams.get("brand_id");
	const brandName = queryParams.get("brand");
	const locationId = queryParams.get("location_id");
	const locationName = queryParams.get("location");
	const locationValues = { id: locationId, name: locationName };
	const brand = { name: brandName, id: brandId };
	const currentFilters = periscopeSpecificItemAvailabilityLocationState?.currentFilters;
	const appliedFilters = periscopeSpecificItemAvailabilityLocationState?.appliedFilters;
	const menuCategoriesFilterValues = periscopeSpecificItemAvailabilityLocationState?.menuCategoriesFilterValues;
	const currentFiltersCollection = periscopeSpecificItemAvailabilityLocationState?.currentFiltersCollection;
	const appliedFiltersCollection = periscopeSpecificItemAvailabilityLocationState?.appliedFiltersCollection;
	const offset = periscopeSpecificItemAvailabilityLocationState?.offset;
	const limit = periscopeSpecificItemAvailabilityLocationState?.limit;
	const initialCall = periscopeSpecificItemAvailabilityLocationState?.initialCall;
	const sort = periscopeSpecificItemAvailabilityLocationState?.sort;
	const appliedPlatformNames = appliedFilters?.platform_names;
	const brands = configItems?.brands?.items;

	const cities = periscopeFilters?.cities;
	const periscopeBrandIds = periscopeFilters?.brands;
	const periscopeLocationIds = periscopeFilters?.locations;

	const [filteredBrands, setFilteredBrands] = useState([]);
	const [filteredLocations, setFilteredLocations] = useState([]);

	useEffect(() => {
		if (!isMultibrandEnabled || !brands || !periscopeBrandIds) return;

		const filteredBrands = brands.filter((brand) => {
			return periscopeBrandIds.includes(String(brand?.id)) || brand?.id === "all";
		});
		setFilteredBrands(filteredBrands);
	}, [isMultibrandEnabled, brands, periscopeBrandIds]);

	useEffect(() => {
		if (!stores || !periscopeLocationIds) return;

		const locations = stores.filter((store) => {
			return periscopeLocationIds.includes(String(store?.id));
		});
		setFilteredLocations(locations);
	}, [stores, periscopeLocationIds]);

	const country = (login?.loggedInbizDetail?.country || lS.get("auth")?.biz?.country).toLowerCase();
	const [bizPlatforms, setBizPlatforms] = useState(
		configItems?.bizPlatforms?.items.filter((platform) => {
			if (platform.platformName == "All Platforms") {
				return true;
			}
			return (periscopeCountryWiseSupportedPlatforms?.[country] ||
				lS.get("periscopeCountryWisePlatforms")?.[country])?.[platform.platformName.toLowerCase()]?.enabled;
		})
	);
	const bizPlatformsLoading = configItems?.bizPlatforms?.isLoading;
	const data = periscopeSpecificItemAvailabilityLocationData?.data?.objects?.data;

	const [selectedBrand, setSelectedBrand] = useState(
		isMultibrandEnabled
			? !initialCall
				? brand?.name
					? brand
					: lS.get("brand") && lS.get("brand")?.id !== "all"
						? lS.get("brand")
						: { id: "all", name: "All Brands", image: "/assets/icons/icon-brands.svg" }
				: null
			: null
	);

	const handleBrandsLabelOption = (brand) => {
		let currBrand;
		if (!brand?.image && (configItems?.brands?.items || []).length) {
			currBrand = getBrandDetail(brand?.id, configItems?.brands?.items);
		}
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image || currBrand?.image ? (
						<img src={brand.image || currBrand?.image} alt="" />
					) : (
						extractInitials(brand?.name?.split(" "))
					)}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 18 ? brand.name.slice(0, 18) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	const [optionUpdates, setOptionUpdates] = useState({
		platform_names: {}
	});
	const initialPlatformFilterRef = useRef(null);
	useEffect(() => {
		if (
			Object.keys(
				periscopeCountryWiseSupportedPlatforms?.[country] ||
					lS.get("periscopeCountryWisePlatforms")?.[country] ||
					{}
			).length > 0 &&
			(configItems?.bizPlatforms?.items || []).length &&
			!initialPlatformFilterRef.current &&
			Object.keys(optionUpdates?.["platform_names"] || []).length > 0
		) {
			setEntitiesWithSelectedOnTop("platform");
			initialPlatformFilterRef.current = true;
		}
	}, [
		Object.keys(periscopeCountryWiseSupportedPlatforms?.[country] || {}).join(","),
		(configItems?.bizPlatforms?.items || []).length,
		Object.keys(optionUpdates?.["platform_names"] || []).length
	]);

	const setEntitiesWithSelectedOnTop = (entity) => {
		if (entity == "platform") {
			let selectedPlatforms = [];
			let notSelectedPlatforms = [];
			(configItems?.bizPlatforms?.items || []).map((plt) => {
				if (
					plt.platformName &&
					(periscopeCountryWiseSupportedPlatforms?.[country] ||
						lS.get("periscopeCountryWisePlatforms")?.[country])?.[plt.platformName.toLowerCase()]?.enabled
				) {
					if (optionUpdates?.["platform_names"]?.[plt.id]) {
						selectedPlatforms.push(plt);
					} else {
						notSelectedPlatforms.push(plt);
					}
				}
			});
			setBizPlatforms([...selectedPlatforms, ...notSelectedPlatforms]);
		}
	};

	const [selectedCity, setSelectedCity] = useState(
		cities?.[0] || { value: "all", valueForDisplay: "All Cities", __typename: "CityType" }
	);
	const setInitialOffset = () => {
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
			payload: { offset: 0 }
		});
	};
	const [status, setStatus] = useState("all");
	const handleStatusFilter = (val) => {
		periscopeFilterEvent("status");
		if (val?.value) {
			setStatus(val.value);
		} else {
			setStatus(val);
		}
		setInitialOffset();
	};

	const handleBrand = (brand) => {
		periscopeFilterEvent("brand");
		setSelectedBrand(brand);
		clearFilters();
		setInitialOffset();
	};

	const [itemDropdown, setItemDropdown] = useState("all");
	const handleItemFilter = (val) => {
		periscopeFilterEvent("item");
		setItemDropdown(val.value);
		setInitialOffset();
	};

	const [showFilters, setShowFilters] = useState(false);
	const searchText = periscopeSpecificItemAvailabilityLocationState?.searchText;

	const filterSidebarCloseHandler = () => {
		setShowFilters(false);
		let currentFiltersCollection = { ...appliedFiltersCollection };
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
			payload: { currentFiltersCollection }
		});
	};

	const applyFilters = () => {
		setInitialOffset();
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_ITEM_LOCATION_FILTERS_CHANGE,
			payload: currentFiltersCollection
		});
		setShowFilters(false);
	};

	const clearFilters = () => {
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
			payload: { offset: 0, limit: 10 }
		});

		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_LOCATION_INITIAL_STATE
		});
		setStatus("all");
		setShowFilters(false);
		setItemDropdown("all");
	};

	const setFilterr = (field, value) => {
		let currentFiltersCollection = {
			...periscopeSpecificItemAvailabilityLocationState.currentFiltersCollection,
			[field]: value
		};
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
			payload: { currentFiltersCollection }
		});
	};

	const textDebouncedItemAvailabilitySpecificLocation = (searchText) => {
		let finalPlatforms =
			appliedPlatformNames && appliedPlatformNames[0]?.length > 0 && appliedPlatformNames[0] !== "all"
				? appliedPlatformNames
				: defaultPlatforms;

		let brandSelected = String(selectedBrand?.id);
		let locationSelected = appliedFiltersCollection?.location
			? String(appliedFiltersCollection?.location?.id)
			: String(locationValues?.id);

		const variables = {
			bizId: String(login.loggedInbizDetail.id),
			brand: isMultibrandEnabled ? brandSelected : null,
			city: String(selectedCity?.value),
			limit: limit || 10,
			location: locationSelected,
			offset: 0,
			platforms: finalPlatforms,
			view: "item",
			status: String(status),
			search: searchText,
			sort: sort,
			items: itemDropdown
		};
		fetchDebouncedItemAvailabilitySpecificLocation(variables);
	};

	const [searchQuery, setSearchQuery] = useState("");
	const handleSearch = (field, value) => {
		setSearchQuery(value);
		textDebouncedItemAvailabilitySpecificLocation(value);
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
			payload: { searchText: value, offset: 0 }
		});
	};

	const fetchLocationIdDetails = async (id) => {
		const variables = { id };
		try {
			const resp = await client.query({
				query: GET_LOCATION,
				variables,
				fetchPolicy: "cache-first"
			});
			if (
				resp?.data?.store?.id &&
				resp?.data?.store?.name &&
				(resp?.data?.store?.id != locationId || resp?.data?.store?.name != locationName)
			) {
				history.push("/periscope/item-availability?view=location");
			}
		} catch (err) {
			history.push("/periscope/item-availability?view=location");
		}
	};
	const urlValidationRef = useRef({
		platformValidation: false,
		brandValidation: !isMultibrandEnabled ? true : false,
		locationValidation: false
	});
	const history = useHistory();
	const validateURL = () => {
		if (!urlValidationRef.current.platformValidation && (configItems?.bizPlatforms?.items || []).length > 0) {
			if (!platformName) {
				history.push("/periscope/item-availability?view=location");
			}
			const currentPlt = configItems?.bizPlatforms?.items.find((plt) => {
				return platformName && plt.platformName.toLowerCase() == platformName.toLowerCase();
			});
			if (
				!currentPlt ||
				(currentPlt || []).length == 0 ||
				currentPlt?.id != platformId ||
				(currentPlt?.platformName).toLowerCase() !== platformName.toLowerCase()
			) {
				history.push("/periscope/item-availability?view=location");
			}
			urlValidationRef.current.platformValidation = true;
		}
		if (!urlValidationRef.current.brandValidation && (configItems?.brands?.items || []).length > 0) {
			const currentBrand = configItems?.brands?.items.find((item) => {
				return brandId && item.id == brandId;
			});
			if (
				!currentBrand ||
				(currentBrand || []).length == 0 ||
				currentBrand?.id != brandId ||
				(currentBrand?.name || "").toLowerCase() !== (brand?.name || "").toLowerCase()
			) {
				history.push("/periscope/item-availability?view=location");
			}
			urlValidationRef.current.brandValidation = true;
		}
		if (!urlValidationRef.current.locationValidation && !location?.state?.locationValues) {
			fetchLocationIdDetails(locationId);
			urlValidationRef.current.locationValidation = true;
		}
	};
	useEffect(() => {
		validateURL();
	}, [configItems?.bizPlatforms?.items.length, configItems?.brands?.items.length]);
	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
		}
		fetchBrands("", true);
		fetchBizPlatforms(false, false, true);
		fetchStores("", 50, null, false);
		fetchMenuCategoriesValues({ bizId: String(login?.loggedInbizDetail?.id) }, "location");

		fetchPeriscopeEnabledCities(String(login?.loggedInbizDetail?.id), true);
		fetchPeriscopeEnabledBrands(String(login?.loggedInbizDetail?.id));
		fetchPeriscopeEnabledLocations(String(login?.loggedInbizDetail?.id));

		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
			payload: {
				currentFilters: { platform_names: [platformName] },
				appliedFilters: { platform_names: [platformName] }
			}
		});
		if (tableRef.current) {
			scroll({ top: tableRef.current.offsetTop - 157, left: 0 });
		}
	}, []);

	const previousFilterPlatformRef = useRef(null);
	useEffect(() => {
		const platformValue = periscopeSpecificItemAvailabilityLocationState?.appliedFilters?.platform_names.join(",");
		if (
			!firstRender.current &&
			previousFilterPlatformRef.current !== null &&
			previousFilterPlatformRef.current !== platformValue
		) {
			periscopeFilterEvent("platform");
		}
		previousFilterPlatformRef.current = platformValue;
	}, [periscopeSpecificItemAvailabilityLocationState?.appliedFilters?.platform_names.join(",")]);

	const handleCheck = (state, field, option, prop) => {
		let _currentFilters = { ...currentFilters };
		const _optionUpdates = {
			...optionUpdates,
			[field]: {
				...optionUpdates[field],
				[option.id]: state
			}
		};

		let lowerCasePlatformName = option.platformName.toLowerCase();
		setOptionUpdates(_optionUpdates);
		const _isCheckedAllUpdates = { ...isCheckedAll };
		_isCheckedAllUpdates[field] = false;
		setIsCheckedAll(_isCheckedAllUpdates);

		if (state) {
			_currentFilters[field] = [String(lowerCasePlatformName), ..._currentFilters[field]];
			_currentFilters[field] = _currentFilters[field].filter((item) => item !== "all");
			_currentFilters[field] = [...new Set(_currentFilters[field])]; // remove duplicates
		} else {
			_currentFilters[field] = _currentFilters[field].filter((item) => item !== String(lowerCasePlatformName));

			if (_currentFilters[field].length === 0) {
				handleCheckAll(true, field);
				return;
			}
		}

		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
			payload: { currentFilters: _currentFilters }
		});
	};

	const [isCheckedAll, setIsCheckedAll] = useState({ platform_names: false });
	const handleCheckAll = (state, field, options, props) => {
		let _currentFilters = { ...currentFilters };
		const _optionUpdates = { ...optionUpdates };
		const _isCheckedAllUpdates = { ...isCheckedAll };
		_isCheckedAllUpdates[field] = state;
		_currentFilters[field] = state ? ["all"] : [];
		_optionUpdates[field] = {};
		setOptionUpdates(_optionUpdates);
		setIsCheckedAll(_isCheckedAllUpdates);

		if (_currentFilters.platform_names.length == 0) {
			_currentFilters.platform_names[0] = "all";
		}
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
			payload: { currentFilters: _currentFilters }
		});
	};
	const handleSelectFilterState = (isOpen = false, field = null) => {
		if (!isOpen) {
			const currentPlatforms = currentFilters?.platform_names;
			const appliedPlatforms = appliedFilters?.platform_names;
			let currentFiltersChanged = false;
			if (currentPlatforms?.length !== appliedPlatforms?.length) {
				currentFiltersChanged = true;
			} else {
				if (currentPlatforms) {
					currentPlatforms.forEach((platform, index) => {
						if (platform != appliedPlatforms[index]) {
							currentFiltersChanged = true;
							return;
						}
					});
				}
			}

			if (currentFiltersChanged) {
				store.dispatch({
					type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
					payload: { appliedFilters: currentFilters, offset: 0, sort: null }
				});
				setEntitiesWithSelectedOnTop("platform");
			}
		}
	};

	const [initialState, setInitialState] = useState(true);
	useEffect(() => {
		if (initialState) {
			let data;
			if (platformId) {
				data = { [platformId]: true };
				setIsCheckedAll({
					platform_names: false
				});
				setInitialState(false);
			}
			setOptionUpdates({
				platform_names: data
			});
		}
	}, [currentFilters?.platform_names, bizPlatforms]);

	const menuCategories = useMemo(() => {
		return (menuCategoriesFilterValues || []).map((menu) => ({
			id: menu,
			name: menu
		}));
	}, [menuCategoriesFilterValues]);

	const filterOptions = [
		{
			field: "location",
			valueForDisplay: "Location",
			values: filteredLocations,
			labelKey: "name",
			valueKey: "id",
			type: "SINGLE_STRICT",
			isAsync: true,
			handleAsyncSearch: fetchStoresDebounced,
			isLoading: configItems?.stores?.isLoading
		},
		{
			field: "menuCategories",
			valueForDisplay: " Menu Category",
			values: menuCategories,
			labelKey: "name",
			valueKey: "id",
			type: "SINGLE_STRICT"
		}
	];

	const defaultPlatforms = useMemo(() => {
		let allPlatforms = bizPlatforms.map((platform) => {
			return platform.platformName.toLowerCase();
		});
		if (allPlatforms[0] == "all platforms") {
			allPlatforms.shift();
		}
		return allPlatforms;
	}, [bizPlatforms]);

	const fetchData = (periscopeDataFetch = false) => {
		let finalPlatforms =
			appliedPlatformNames && appliedPlatformNames[0]?.length > 0 && appliedPlatformNames[0] !== "all"
				? appliedPlatformNames
				: defaultPlatforms;
		let brandSelected;
		let locationSelected;
		brandSelected = String(selectedBrand?.id);

		if (!initialCall) {
			locationSelected = String(locationValues?.id);
		} else {
			locationSelected = appliedFiltersCollection?.location
				? String(appliedFiltersCollection?.location?.id)
				: String(locationValues?.id);
		}
		const category = appliedFiltersCollection?.menuCategories?.id;

		const variables = {
			bizId: String(login.loggedInbizDetail.id),
			brand: isMultibrandEnabled ? brandSelected : null,
			city: String(selectedCity?.value),
			limit: limit || 10,
			location: locationSelected,
			offset: offset || 0,
			platforms: finalPlatforms,
			view: "item",
			status: String(status),
			sort: sort,
			items: itemDropdown,
			category: category || "",
			search: searchQuery
		};
		fetchItemAvailabilitySpecificLocation(variables, periscopeDataFetch);
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
			payload: { initialCall: true }
		});
	};

	const filterCount = useMemo(() => {
		if (appliedFiltersCollection) {
			const allKeys = Object.keys(appliedFiltersCollection || {});
			return allKeys.reduce((acc, keys) => {
				if (appliedFiltersCollection?.[keys]?.value || appliedFiltersCollection?.[keys]?.id) {
					acc += 1;
				}
				return acc;
			}, 0);
		}
	}, [appliedFiltersCollection]);

	const tableRef = useRef(null);

	useEffect(() => {
		let finalPlatforms =
			appliedPlatformNames && appliedPlatformNames[0]?.length > 0 && appliedPlatformNames[0] !== "all"
				? appliedPlatformNames
				: defaultPlatforms;

		if (finalPlatforms[0]?.length > 0) {
			fetchData();
		}
	}, [
		selectedBrand,
		selectedCity,
		status,
		limit,
		offset,
		appliedFiltersCollection,
		sort,
		itemDropdown,
		appliedPlatformNames
	]);

	const periscopeDataFetch = periscopeDataResponse?.periscopeDataFetch;
	useEffect(() => {
		if (periscopeDataFetch) {
			let finalPlatforms =
				appliedPlatformNames && appliedPlatformNames[0]?.length > 0 && appliedPlatformNames[0] !== "all"
					? appliedPlatformNames
					: defaultPlatforms;
			if (finalPlatforms[0]?.length > 0) {
				fetchData(periscopeDataFetch);
			}
		}
	}, [periscopeDataFetch]);

	const refreshBtn = () => {
		let finalPlatforms =
			appliedPlatformNames && appliedPlatformNames[0]?.length > 0 && appliedPlatformNames[0] !== "all"
				? appliedPlatformNames
				: defaultPlatforms;
		if (finalPlatforms[0]?.length > 0) {
			fetchData();
		}
	};
	return (
		<div className="periscope-container">
			<div className="specificlocation-table-div" ref={tableRef}>
				<div className="specificlocation-table-container">
					<SpecificLocationTableHeader refreshBtn={refreshBtn} locationValues={locationValues} />
					<div className="specific-location-filters-div">
						<div className="specific-location-left-filters  brand-state-filters">
							{isMultibrandEnabled && (
								<SelectFilterCustom
									options={filteredBrands}
									field="brands"
									currValue={selectedBrand}
									setFilter={(f, value) => handleBrand(value)}
									labelKey={"name"}
									valueKey={"id"}
									isSearchable={true}
									customLabel={true}
									customOptions={true}
									renderLabel={handleBrandsLabelOption}
									renderOptions={handleBrandsLabelOption}
									placeholder="Select brand"
								/>
							)}
							<div className="specificlocation-platform-filter">
								<SelectFilterCustom
									options={bizPlatforms?.filter((plf) => plf.id !== "all") || []}
									field={"platform_names"}
									labelKey={"platformName"}
									valueKey={"id"}
									iconKey={"image"}
									multi={true}
									showCheckBox={true}
									requiredLabel={true}
									showSelectAllOption
									showIcon={true}
									ellipsizedLength={40}
									isLoading={bizPlatformsLoading}
									optionUpdates={optionUpdates}
									handleCheck={handleCheck}
									isCheckedAll={isCheckedAll}
									handleCheckAll={handleCheckAll}
									placeholder={"Select platforms"}
									selectAllPlaceholder="All Platforms"
									countDisplayText={"platform"}
									handleSelectFilterState={handleSelectFilterState}
								/>
							</div>
							<div className="platform-status">
								<SelectFilter
									options={[
										{ value: "all", valueForDisplay: "All" },
										{ value: "offline", valueForDisplay: "Offline" },
										{ value: "partial_offline", valueForDisplay: "Partially Offline" },
										{ value: "online", valueForDisplay: "Online" }
									]}
									field="status"
									currValue={{
										value: status,
										valueForDisplay: (
											<span>
												<span className="status-heading" style={{ color: "#8A8A8A" }}>
													Status
												</span>
												<span className="item-availability-status">
													{status == "offline"
														? "Offline"
														: status == "online"
															? "Online"
															: status == "partial_offline"
																? "Partially Offline"
																: "All"}
												</span>
											</span>
										)
									}}
									labelKey="valueForDisplay"
									valueKey="value"
									setFilter={(f, v) => handleStatusFilter(v)}
									isSearchable={false}
									isClearable={false}
								/>
							</div>
							<div className="specificitem-location-item">
								<SelectFilter
									options={[
										{ value: "all", valueForDisplay: "All" },
										{ value: "important", valueForDisplay: "Important" }
									]}
									field="itemDropdown"
									currValue={{
										value: itemDropdown,
										valueForDisplay: (
											<span>
												<span className="status-heading" style={{ color: "#8A8A8A" }}>
													Items
												</span>
												<span className="item-status">
													{itemDropdown == "important" ? "Important" : "All"}
												</span>
											</span>
										)
									}}
									labelKey="valueForDisplay"
									valueKey="value"
									setFilter={(f, v) => handleItemFilter(v)}
									isSearchable={false}
									isClearable={false}
								/>
							</div>
							<div
								style={{ width: "90px" }}
								className={(filterCount > 0 ? "active" : "") + " filter-in-header campaign-list-filter"}
							>
								{configItems?.dimensions?.width > 768 && (
									<Filters
										isOpen={showFilters}
										close={filterSidebarCloseHandler}
										options={filterOptions}
										apply={applyFilters}
										clear={clearFilters}
										currentFilters={currentFiltersCollection}
										setFilter={(f, value) => setFilterr(f, value)}
									/>
								)}
								<div className="container" onClick={() => setShowFilters((prev) => !prev)}>
									<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
									<div className="filter-title">
										Filter
										{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
									</div>
								</div>
							</div>
						</div>
						<div className="specificitem-right-filters">
							<SearchFilter
								placeholder="Search"
								filterOption={{
									field: "name"
								}}
								setFilter={handleSearch}
								value={searchQuery}
							/>
						</div>
					</div>

					{itemDropdown == "important" && data && data?.length == 0 ? (
						<EmptyImportantItemState setItemDropdown={setItemDropdown} />
					) : (
						<div className="specific-location-table">
							<SelectedLocationTable
								tableRef={tableRef}
								defaultPlatforms={defaultPlatforms}
								refreshBtn={refreshBtn}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	configItems: state.configItems,
	login: state.login,
	periscopeSpecificItemAvailabilityLocationData: state.periscopeSpecificItemAvailabilityLocationData,
	periscopeSpecificItemAvailabilityLocationState: state.periscopeSpecificItemAvailabilityLocationState,
	isMultibrandEnabled: state.login.loggedInbizDetail.isMultibrandEnabled,
	periscopeDataResponse: state.periscopeDataResponse,
	periscopeCountryWiseSupportedPlatforms: state.periscopeCountryWiseSupportedPlatforms,
	periscopeFilters: state.periscopeFilters
});

export default connect(mapStateToProps)(SpecificLocationTable);

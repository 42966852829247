import React, { useEffect, useRef } from "react";

// third party
import { InvoicePaymentAlert, PaymentVariant, HorizonText, TextVariant } from "@urbanpiper-engineering/horizon";
import parse from "html-react-parser";
import history from "../history";
import { connect } from "react-redux";
import moment from "moment";

// utils
import { getLoggedInBizId, lS, trackEvent } from "../atlas-utils";

// store
import { store } from "../store/configureStore";

// actions
import { toggleGlobalLoader } from "../actions/actions";
import { ActionTypes } from "../actions/_types";

const BillingRecovery = ({ billingRecoveryState, access = {}, biz }) => {
	const { modal } = billingRecoveryState;
	const timerRef = useRef();

	useEffect(() => {
		handleOverdueAlerts();
		return () => {
			clearTimeout(timerRef?.current);
		};
	}, [modal?.dismissedAt]);

	const handleOverdueAlerts = () => {
		clearTimeout(timerRef?.current);

		if (!modal.dismissedAt) {
			return;
		}

		if (modal.dueDateDiff >= -6 && modal.dueDateDiff <= 0) {
			// reopen modal every 24 hours (1 day)
			const reOpenModalAt = moment(modal.dismissedAt).add(24, "hours").format();

			const diff = moment().diff(reOpenModalAt, "seconds");

			if (diff >= 0) {
				showModal();
			} else {
				timerRef.current = setTimeout(
					() => {
						showModal();
					},
					Math.abs(diff) * 1000
				);
			}
			return;
		}

		if (modal.dueDateDiff >= 1) {
			// reopen modal every 1 hour
			const reOpenModalAt = moment(modal.dismissedAt).add(1, "hours").format();

			const diff = moment().diff(reOpenModalAt, "seconds");

			if (diff >= 0) {
				showModal();
			} else {
				timerRef.current = setTimeout(
					() => {
						showModal();
					},
					Math.abs(diff) * 1000
				);
			}
			return;
		}
	};

	const showModal = () => {
		store.dispatch({
			type: ActionTypes.UPDATE_BILLING_RECOVERY_MODAL_STATE,
			payload: {
				isOpen: true
			}
		});
	};

	const updateLs = () => {
		// update local storage
		let billingAlert = lS.get("billing_alert") || {};
		billingAlert = {
			...billingAlert,
			[getLoggedInBizId()]: {
				...(billingAlert[getLoggedInBizId()] ?? {}),
				isOpen: false,
				dismissedAt: moment().format()
			}
		};
		lS.set("billing_alert", billingAlert);
	};

	const handleContactSupport = () => {
		try {
			store.dispatch(toggleGlobalLoader(true));
			const bizName = biz?.name || "Business";
			const bizId = biz?.id || "Not Available";
			const currentDate = new Date().toLocaleDateString();

			// Format business details for better readability
			const formattedBizName = bizName.trim();
			const formattedBizId = bizId.toString().trim();

			const subject = `[URGENT] Account Deactivation (Non-payment) - ${formattedBizName}`;

			// Email body (keep the indentation as it is, don't add extra spaces)
			const body = `Dear Support Team,

I am writing to request the delay of my business account deactivation due to non-payment or request reactivation if the account is already deactivated.

Account Details:
- Business Name: ${formattedBizName}
- Business ID: ${formattedBizId}
- Date of Request: ${currentDate}
- Issue: Account Deactivation (Non-payment)

I understand that:
1. The reactivation process may take 4-5 business days if the account is already deactivated
2. A reactivation fee may be applicable
3. All integrations with connected platforms may be disabled

Please guide me through the reactivation process if the account is already deactivated and provide information about any pending payments or requirements.
Thank you for your assistance.

Best regards.`;

			// Construct and validate mailto link
			const mailtoLink = `mailto:support@urbanpiper.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
			if (mailtoLink.length > 2048) {
				throw new Error("Email content exceeds maximum length");
			}

			// Open email client
			window.location.href = mailtoLink;

			// Reset loading state after a short delay
			setTimeout(() => store.dispatch(toggleGlobalLoader(false)), 1000);
		} catch (error) {
			console.error("Error opening email client:", error);
			store.dispatch(toggleGlobalLoader(false));
		}
	};

	const handleCancel = (contactSupport = true) => {
		if (contactSupport && access.isAdmin && modal?.variant === PaymentVariant.Deactivation) {
			handleContactSupport();
		}

		store.dispatch({
			type: ActionTypes.UPDATE_BILLING_RECOVERY_MODAL_STATE,
			payload: {
				isOpen: false,
				dismissedAt: moment().format()
			}
		});
		updateLs();

		// track event
		trackEvent("billing_dialog_cta", {
			dialog: modal?.milestone,
			source: "atlas"
		});
	};

	const handleSubmit = () => {
		handleCancel(false);

		// redirect to billing and invoices module
		history.push("/billing");
	};

	const renderModalTitle = () => {
		return <HorizonText variant={TextVariant.HEADING3}>{modal?.header || ""}</HorizonText>;
	};

	const renderModalBody = () => {
		return <HorizonText variant={TextVariant.BODY1}>{modal?.body ? parse(modal?.body) : ""}</HorizonText>;
	};

	return (
		<InvoicePaymentAlert
			className="billing-recovery"
			type={modal?.variant}
			open={modal?.isOpen}
			onClose={handleCancel}
			onSubmit={handleSubmit}
			okText="View Invoices"
			cancelText={
				access.isAdmin && modal?.variant === PaymentVariant.Deactivation ? "Contact Support" : "I understand"
			}
			title={renderModalTitle()}
			body={renderModalBody()}
			cancelButtonProps={{
				danger: access.isAdmin && modal?.variant === PaymentVariant.Deactivation ? false : true
			}}
			modalProps={{
				zIndex: 99999,
				width: modal?.invoiceMsgType?.toLowerCase()?.includes("hub") && modal?.dueDateDiff < 8 ? 615 : 575,
				footer: (_, { OkBtn, CancelBtn }) => (
					<>
						<CancelBtn />
						{access.isAdmin && <OkBtn />}
					</>
				)
			}}
		/>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail,
	access: store.login.loginDetail.access,
	billingRecoveryState: store.billingRecoveryState
});
export default connect(mapStateToProps)(BillingRecovery);

import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import PlatformCell from "../../../components/Periscope/ItemAvailability/PlatformCell";
import BrandCell from "../../../components/Periscope/ItemAvailability/BrandCell";
import { useTable } from "react-table";
import ItemTableLoading from "../../../components/Periscope/ItemAvailability/ItemTableLoading";
import TablePlatform from "../../../components/Periscope/ItemAvailability/TablePlatform";
import { Paginator } from "../../../components/_commons/Paginator";
import Tooltip from "../../../components/Periscope/ItemAvailability/Tooltip";
import { store } from "../../../store/configureStore";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CoachMark } from "react-coach-mark";
import { createVisitHistory, visitHistory } from "../../../actions/periscope";
import { lS, scroll, trackEvent } from "../../../atlas-utils";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { ActionTypes } from "../../../actions/_types";
import { getPlatformDetailViaName } from "../../../helpers/periscope";
import Popover from "../../../components/_commons/Popover";

const ItemTable = ({
	tableData,
	platformCount,
	defaultPlatforms,
	periscopeItemAvailabilityData,
	periscopeItemAvailabilityState,
	handlePagination,
	handlePageSize,
	login,
	isMultibrandEnabled,
	refreshBtn,
	periscopeCountryWiseSupportedPlatforms,
	configItems
}) => {
	const data = periscopeItemAvailabilityData?.data?.objects?.data;
	const loading = periscopeItemAvailabilityData?.loading;
	const error = periscopeItemAvailabilityData?.error;
	const country = (login?.loggedInbizDetail?.country || lS.get("auth")?.biz?.country).toLowerCase();
	const bizPlatforms = configItems?.bizPlatforms?.items.filter((platform) => {
		if (platform.platformName == "All Platforms") {
			return true;
		}
		return (periscopeCountryWiseSupportedPlatforms?.[country] ||
			lS.get("periscopeCountryWisePlatforms")?.[country])?.[platform.platformName.toLowerCase()]?.enabled;
	});
	const dataCount = periscopeItemAvailabilityState.count || 0;
	const limit = periscopeItemAvailabilityState.limit;
	const offset = periscopeItemAvailabilityState.offset;

	const columns = useMemo(() => {
		return Object.keys(data?.[0] || {}).map((column) => {
			return {
				accessor: column,
				Cell: (props) => {
					if (props.column.id == "brandItem") {
						return (
							<>
								<BrandCell props={props} />
							</>
						);
					} else {
						return (
							<>
								<PlatformCell props={props} />
							</>
						);
					}
				}
			};
		});
	}, [data]);

	const { getTableProps, getTableBodyProps, rows, prepareRow, headerGroups } = useTable({ columns, data });

	const history = useHistory();

	const goToSpecificItemTable = (cell) => {
		let total = cell?.value?.total;
		let foodItem = cell?.row?.allCells[0]?.value?.item;
		if (total !== 0) {
			trackEvent("periscope_detail_view_clicked", { module: "item_availability" });
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS,
				payload: {
					currentFilters: { platform_names: [cell.column.id] },
					appliedFilters: { platform_names: [cell.column.id] }
				}
			});
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_ITEM_INITIAL_STATE
			});
			const platformId = getPlatformDetailViaName(cell.column.id, bizPlatforms).id;
			let queryParam = `?platform_id=${platformId}&platform=${cell.column.id}${
				isMultibrandEnabled
					? `&brand_id=${cell.row.allCells[0].value?.brand_id}&brand=${cell.row.allCells[0].value?.brand}`
					: ""
			}`;
			history.push(`/periscope/item-availability/item/${foodItem}${queryParam}`);
		}
	};

	const dispatchSortFunction = (sort) => {
		if (sort == null) {
			store.dispatch({
				type: ActionTypes.ITEM_AVAILABILITY_INITIAL_SORT
			});
		} else {
			store.dispatch({
				type: ActionTypes.ITEM_AVAILABILITY_STATE_FILTERS,
				payload: sort
			});
		}
	};

	const sortThePlatform = (column) => {
		const currentSort = periscopeItemAvailabilityState?.sort || {};
		const isCurrentColumn = currentSort.field === column;
		let order, sort;
		store.dispatch({
			type: ActionTypes.ITEM_AVAILABILITY_STATE_FILTERS,
			payload: { offset: 0 }
		});
		if (isCurrentColumn) {
			order = currentSort.order === "asc" ? "desc" : currentSort.order === "desc" ? null : "asc";
			sort = order ? { sort: { field: column, order } } : null;
		} else {
			order = "asc";
			sort = { sort: { field: column, order } };
		}
		dispatchSortFunction(sort);
	};

	useEffect(() => {
		let variables = {
			bizId: String(login.loggedInbizDetail.id)
		};
		visitHistory(variables);
	}, []);

	const hasVisitedItemsAvailability = periscopeItemAvailabilityState?.visitHistory?.hasVisitedItemsAvailability;
	const hasVisitedItemsIssue = periscopeItemAvailabilityState?.visitHistory?.hasVisitedItemsIssue;
	const [showCoachMark, setShowCoachMark] = useState(false);
	const apiDataTable = useRef(null);
	let tableDataInitialDiv = useRef(null);
	const [activatedNumber, setActivatedNumber] = useState(0);
	const coachRef = useRef(null);
	const coachList = [
		{
			activate: showCoachMark && activatedNumber === 0,
			component: (
				<div className="coach-mark-hover-div" style={{ padding: "8px 12px 12px 12px " }} ref={coachRef}>
					<span className="coach-mark-para1">Selecting Important Items</span> <br></br>
					<span className="coach-mark-para2">Monitor your important menu items across various reports</span>
				</div>
			),
			reference: tableDataInitialDiv,
			tooltip: { position: "right", margin: "" }
		}
	];

	useEffect(() => {
		setTimeout(() => {
			if (data?.length > 0 && !hasVisitedItemsAvailability) {
				if (apiDataTable.current) {
					if (apiDataTable.current) {
						scroll({ top: 0, left: 0 });
					}
					const firstTd = apiDataTable?.current?.querySelector(
						"table .api-data tr:first-child td:first-child .brand-item-img-span"
					);
					if (firstTd) {
						tableDataInitialDiv.current = firstTd;
						setShowCoachMark(true);
						document.body.classList.add("no-scroll");
					}
				}
			}
		}, 1000);
	}, [data, loading]);

	useOnClickOutside(coachRef, (event) => handleClickOutside(event));

	const handleClickOutside = (event) => {
		setShowCoachMark(false);
		document.body.classList.remove("no-scroll");
		let variables = {
			bizId: String(login.loggedInbizDetail.id),
			hasVisitedItemsAvailability: true,
			hasVisitedItemsIssue: hasVisitedItemsIssue
		};
		createVisitHistory(variables);
	};

	useEffect(() => {
		let variables = {
			bizId: String(login.loggedInbizDetail.id)
		};
		visitHistory(variables);
	}, []);

	const renderPopover = (obj) => {
		return <div className="info-popover">{obj?.item}</div>;
	};

	return (
		<>
			<div>
				<div className="coach-mark">
					<CoachMark {...coachList[activatedNumber]} />
				</div>
				{defaultPlatforms.length > 0 ? (
					<>
						<table ref={apiDataTable} className="table-item-availability" {...getTableProps()}>
							<thead style={{ border: "1px solid #EAEAEA" }}>
								<tr>
									<td
										rowSpan={2}
										style={{
											border: "1px solid #EAEAEA",
											minWidth: "340px",
											padding: "0 0 0 68px"
										}}
										className="item-tablehead-text"
									>
										{isMultibrandEnabled ? "Brand - Item" : "Item"}
									</td>
									<td
										className="item-tablehead-text"
										colSpan={platformCount.count}
										style={{ padding: "12px 24px 12px 24px", border: "1px solid #EAEAEA" }}
									>
										Locations with item
									</td>
								</tr>
								<tr>
									{tableData.map((table, index) => {
										return (
											<td
												key={index}
												className="item-tablehead-text "
												style={{
													cursor: "pointer",
													width: platformCount?.percent
												}}
												onClick={() => sortThePlatform(table)}
											>
												<TablePlatform tableName={table} />
											</td>
										);
									})}
								</tr>
							</thead>
							{loading || error ? (
								<>
									<ItemTableLoading tableData={tableData} error={error} refreshBtn={refreshBtn} />
								</>
							) : (
								<>
									<tbody
										className="api-data"
										{...getTableBodyProps()}
										style={{ borderBottom: "1px solid #EAEAEA" }}
									>
										{rows.map((row, rowIndex) => {
											prepareRow(row);
											return (
												<tr key={rowIndex}>
													{row.cells.map((cell, cellIndex) => {
														let totalLocations = cell.value.total;
														let onlineLocations = cell.value.available;
														let classname = "";
														if (
															onlineLocations == 0 &&
															totalLocations > 0 &&
															cell.column.id !== "brandItem"
														) {
															classname = "hover-red-offline";
														} else if (
															totalLocations == onlineLocations &&
															totalLocations !== 0 &&
															onlineLocations !== 0 &&
															cell.column.id !== "brandItem"
														) {
															classname = "hover-green-online";
														} else if (
															totalLocations == 0 &&
															onlineLocations == 0 &&
															cell.column.id !== "brandItem"
														) {
															classname = "hover-partial-offline";
														} else if (cell.column.id !== "brandItem") {
															classname = "hover-red-min-offline";
														}
														return (
															<td
																key={cellIndex}
																className={classname}
																style={{
																	borderBottom: "1px dashed #EAEAEA",
																	...(cell.column.id !== "brandItem"
																		? { textAlign: "right" }
																		: { borderRight: "1px solid #EAEAEA" }),
																	...(cell.column.id !== "brandItem" &&
																	cell.value.total > 0
																		? { cursor: "pointer" }
																		: {})
																}}
															>
																{cell.column.id !== "brandItem" ? (
																	<>
																		<div
																			className={`tooltip-item`}
																			onClick={() => goToSpecificItemTable(cell)}
																		>
																			<div style={{ paddingRight: "24px" }}>
																				{cell.render("Cell")}
																			</div>
																			<Tooltip cell={cell} />
																		</div>
																	</>
																) : (
																	<>
																		{cell.column?.id == "brandItem" &&
																		(cell?.value?.item || "").length >= 20 ? (
																			<Popover
																				data={cell.value}
																				renderPopover={renderPopover}
																				position={"down-left"}
																				showOnHover={true}
																				showOnClick
																				classes="fit-content"
																			>
																				{cell.render("Cell")}
																			</Popover>
																		) : (
																			<>{cell.render("Cell")}</>
																		)}
																	</>
																)}
															</td>
														);
													})}
												</tr>
											);
										})}
									</tbody>
									{dataCount > 0 && !loading ? (
										<tfoot>
											<tr>
												<td
													colSpan="100%"
													style={{ textAlign: "right" }}
													className="paginator-location"
												>
													<Paginator
														limit={limit}
														offset={offset}
														count={dataCount || 0}
														showPageSize={true}
														goToPage={handlePagination}
														setPageSize={handlePageSize}
													/>
												</td>
											</tr>
										</tfoot>
									) : (
										<></>
									)}
								</>
							)}
						</table>
					</>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => ({
	login: state.login,
	periscopeItemAvailabilityData: state.periscopeItemAvailabilityData,
	isMultibrandEnabled: state.login.loggedInbizDetail.isMultibrandEnabled,
	periscopeItemAvailabilityState: state.periscopeItemAvailabilityState,
	periscopeSpecificItemAvailabilityItemState: state.periscopeSpecificItemAvailabilityItemState,
	periscopeCountryWiseSupportedPlatforms: state.periscopeCountryWiseSupportedPlatforms,
	configItems: state.configItems
});

export default connect(mapStateToProps)(ItemTable);

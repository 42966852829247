import React, { useState, useCallback, useEffect, useRef } from "react";

// third party
import moment from "moment";
import { connect } from "react-redux";
import _isArray from "lodash/isArray";

// components
import { SelectFilter } from "../_commons/SelectFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import Popover from "../_commons/Popover";
import { NewDateCompareFilter } from "../_commons/NewDateCompareFilter";
import { Paginator } from "../_commons/Paginator";
import { CustomTable } from "../_commons/CustomTable";
import { NestedEntityContainer } from "../_commons/NestedEntityContainer";

// store
import { client } from "../../client";
import { store } from "../../store/configureStore";

// utils
import { adjustNestedContainer, capitaliseText } from "../../atlas-utils";

// graphql
import { GET_BRAND_PUBLISH_LOGS } from "../../graphql/brands";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchAssociatedBrandLocationsDebounced } from "../../actions/actions";

// constants
import { CATALOGUE_PLATFORMS_LOGO, NESTED_ENTITY_TYPES, PLATFORM_NAME_MAP } from "../../client-config";
import { PRESET_TYPES, DATE_TYPES, CUSTOM_TYPES } from "../_commons/NewDateCompareFilter";
const STATUS_LABEL_MAP = {
	ACK_SUCCESS: "Success",
	ACK_FAILED: "Failed",
	SUCCESS: "Success",
	FAILED: "Failed",
	VALIDATION_FAILED: "Failed",
	NA: "NA"
};
const ACTION_RENDER_TEXT_MAP = {
	req_to_go_live: "Request to Go Live",
	verify: "Verification",
	pub_del_coord: "Delivery Area Update"
};
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const INITIAL_FILTERS_STATE = {
	platform: ["all"],
	action: ["all"],
	status: { value: "all", valueForDisplay: "All" }
};
const INITIAL_DATE_FILTER_STATE = {
	currentDateFilter: {
		current: {
			dateFilter: PRESET_TYPES[8].value,
			dateTypeSelected: DATE_TYPES[0],
			presetTypeSelected: PRESET_TYPES[8],
			rangeStartDate: undefined,
			rangeEndDate: undefined,
			customTypeSelected: CUSTOM_TYPES[0]
		}
	},
	appliedDateFilter: {
		current: {
			dateFilter: PRESET_TYPES[8].value,
			dateTypeSelected: DATE_TYPES[0],
			presetTypeSelected: PRESET_TYPES[8],
			rangeStartDate: undefined,
			rangeEndDate: undefined,
			customTypeSelected: CUSTOM_TYPES[0]
		}
	}
};
const ALLOWED_MONTHS = 12;
const containsHTML = (str) => {
	return /<[a-z][\s\S]*>/i.test(str);
};

const columns = [
	{
		name: "Actions",
		field: "actions",
		render: (record, i) => (
			<div className="table-cell actions" key={i}>
				<img
					src={
						record?.locationPlatform?.platform?.logo ||
						CATALOGUE_PLATFORMS_LOGO[record?.platformName?.toLowerCase()] ||
						"/assets/icons/icons8-globe-40.png"
					}
					title={capitaliseText(
						PLATFORM_NAME_MAP[record?.platformName?.toLowerCase()] || record?.platformName
					)}
					alt=""
				/>
				<div className="action-log">
					<div
						className={`${ACTION_RENDER_TEXT_MAP[record?.action?.toLowerCase()] ? "" : "text-capitalize"}`}
					>
						<b>{ACTION_RENDER_TEXT_MAP[record?.action?.toLowerCase()] || record?.action?.toLowerCase()}</b>
					</div>
					<div className="date-time">
						{`${moment(record?.updated).format("DD MMM, YYYY")} at ${moment(record?.updated).format(
							"hh:mm A"
						)}`}
					</div>
				</div>
			</div>
		)
	},
	{
		name: "Performed by",
		field: "performed-by",
		render: (record, i) => {
			const email = `${record?.user?.uleEmail || record?.user?.email || ""}`;
			const phone =
				`${record?.user?.uleIsdCode || record?.user?.isdCode || ""}` +
				`${record?.user?.ulePhone || record?.user?.phone || ""}`;
			return (
				<div
					className="table-cell performed-by"
					key={i}
					title={record?.user?.fullName || email || phone || null}
				>
					{record?.user?.fullName || email || phone || "--"}
				</div>
			);
		}
	},
	{
		name: "Message",
		field: "message",
		render: (record, i, rest) => (
			<div className="table-cell message" key={i}>
				{containsHTML(record?.result) ? (
					<div dangerouslySetInnerHTML={{ __html: record?.result }} />
				) : (
					<div>{record?.result || "--"}</div>
				)}

				<div>
					{(record?.verificationStatus === "FAILED" ||
						record?.record?.finalStatus === "VALIDATION_FAILED") && (
						<div>
							Menu verification failed. Please{" "}
							<span
								className="fix-errors"
								onClick={() =>
									rest.handleCatalogueVerification("error", {
										id: parseInt(
											rest?.selectedLocation?.id ||
												record?.location ||
												record?.locationPlatform?.location?.bizLocationId
										),
										brand: rest?.selectedBrand || null,
										name:
											rest?.selectedLocation?.name ||
											record?.locationName ||
											record?.locationPlatform?.location?.name,
										publish: record?.updated,
										platform: record?.locationPlatform?.platform || null
									})
								}
							>
								fix errors
							</span>{" "}
							and try again.
						</div>
					)}
				</div>
			</div>
		)
	},
	{
		name: "Status",
		field: "status",
		render: (record, i) => (
			<div className="table-cell status" key={i}>
				<div className={"log-status " + record?.finalStatus}>
					<span>{STATUS_LABEL_MAP[record?.finalStatus] || record?.finalStatus || "--"}</span>
				</div>
			</div>
		)
	}
];

const Logs = ({
	associatedBrandLocations,
	brandId,
	brandName,
	brandImage,
	locationName,
	locationId,
	isMultibrandEnabled,
	isPrismEnabled,
	fromLocations,
	selectedBrandLocation,
	brandLocations,
	handleBrandLocation,
	handleBrandsLabelOption,
	showPopover,
	renderPopover,
	handlePopoverClose
}) => {
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [data, setData] = useState({});
	const [currentFilters, setCurrentFilters] = useState(INITIAL_FILTERS_STATE);
	const [appliedFilters, setAppliedFilters] = useState(INITIAL_FILTERS_STATE);
	const [optionUpdates, setOptionUpdates] = useState({ platform: {}, action: {} });
	const [isCheckedAll, setIsCheckedAll] = useState({ platform: true, action: true });
	const [dateFilter, setDateFilter] = useState(INITIAL_DATE_FILTER_STATE);
	const [loading, setLoading] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [isCatalogueVerificationOpen, setCatalogueVerificationOpen] = useState(false);
	const nestedRef = useRef();
	const [selectedLocation, setSelectedLocation] = useState(null);

	const handleNestedEntity = useCallback((toOpen = false, type) => {
		if (!toOpen) {
			setCatalogueVerificationOpen(false);
			setTimeout(() => setNestedEntity(NESTED_ENTITY_INITIAL_STATE), 100);
		} else {
			setNestedEntity({
				show: true,
				type
			});
			setTimeout(() => setCatalogueVerificationOpen(true), 60);
		}
		adjustNestedContainer(toOpen);
	}, []);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				limit,
				offset,
				sort: {
					field: "created",
					order: "DESC"
				}
			};
			if (isMultibrandEnabled && brandId) {
				variables.brandId = parseInt(brandId);
			}
			if (fromLocations) {
				variables.locationIds = [locationId];
			}
			if (selectedLocation) {
				variables.locationIds = [selectedLocation?.id];
			}
			// filters
			let filtersObject = [];
			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (_isArray(appliedFilters[f]) && !appliedFilters[f]?.includes("all")) {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]?.join(",")
					});
				} else if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f]?.value && appliedFilters[f]?.value !== "all") {
						filtersObject.push({
							field: f,
							value: appliedFilters[f]?.value
						});
					}
				}
			});
			// date filter
			if (dateFilter?.appliedDateFilter?.current?.dateFilter) {
				filtersObject.push({
					field: "created",
					value: dateFilter?.appliedDateFilter?.current?.dateFilter
				});
			}
			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}
			const resp = await client.query({
				query: GET_BRAND_PUBLISH_LOGS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp?.data?.locationPlatformActionLogs) {
				setData(resp?.data?.locationPlatformActionLogs);
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [
		selectedLocation,
		limit,
		offset,
		appliedFilters,
		dateFilter,
		brandId,
		locationId,
		fromLocations,
		isMultibrandEnabled
	]);

	useEffect(() => {
		if (!fromLocations) {
			fetchAssociatedBrandLocationsDebounced(String(brandId), "");
		}
	}, []);

	useEffect(() => {
		fetchData();
	}, [selectedLocation, appliedFilters, dateFilter?.appliedDateFilter, limit, offset, brandId]);

	const setFilter = (field, value) => {
		if (field === "locationId") {
			setSelectedLocation(value ? value : null);
			return;
		}
		// set both current and applied filters
		setCurrentFilters((current) => ({
			...current,
			[field]: value
		}));
		setAppliedFilters((current) => ({
			...current,
			[field]: value
		}));
	};

	const setDateRangeFilter = (payload) => {
		setDateFilter({
			...dateFilter,
			...payload
		});
	};

	const handleCheck = (state, field, option, props) => {
		let _currentFilters = { ...currentFilters };

		const _optionUpdates = {
			...optionUpdates,
			[field]: {
				...(optionUpdates[field] ?? {}),
				[option[props.valueKey]]: state
			}
		};
		setOptionUpdates(_optionUpdates);

		const _isCheckedAllUpdates = { ...isCheckedAll };
		_isCheckedAllUpdates[field] = false;
		setIsCheckedAll(_isCheckedAllUpdates);

		if (state) {
			_currentFilters[field] = [String(option[props.valueKey]), ..._currentFilters[field]];
			_currentFilters[field] = _currentFilters[field].filter((item) => item !== "all");
			_currentFilters[field] = [...new Set(_currentFilters[field])]; // remove duplicates
		} else {
			_currentFilters[field] = _currentFilters[field].filter((item) => item !== String(option[props.valueKey]));
			if (_currentFilters[field].length === 0) {
				// if no option is selected, select all
				handleCheckAll(true, field);
				return;
			}
		}
		setCurrentFilters(_currentFilters);
	};

	const handleCheckAll = (state, field, options, props) => {
		state = true;
		let _currentFilters = { ...currentFilters };
		const _optionUpdates = { ...optionUpdates };
		const _isCheckedAllUpdates = { ...isCheckedAll };

		_isCheckedAllUpdates[field] = state;
		_currentFilters[field] = state ? ["all"] : [];
		_optionUpdates[field] = {};

		setOptionUpdates(_optionUpdates);
		setIsCheckedAll(_isCheckedAllUpdates);
		setCurrentFilters(_currentFilters);
	};

	const handleSelectFilterState = (isOpen = false, field = null) => {
		if (!isOpen) {
			setAppliedFilters(currentFilters);
		}
	};

	const handlePagination = (page) => {
		setOffset((page - 1) * 10);
	};

	const handlePageSize = (field, size) => {
		if (size && size?.value !== limit) {
			setLimit(size.value);
		}
	};

	const handleCatalogueVerification = (status = "error", location = {}) => {
		let payload = {
			currentFilters: {
				error_status: status
			},
			appliedFilters: {
				error_status: status
			},
			offset: 0
		};
		if (!isPrismEnabled) {
			payload.currentFilters.channel = {
				valueForDisplay: location?.platform?.platformName,
				value: location?.platform?.platformName?.toLowerCase()
			};
			payload.appliedFilters.channel = {
				valueForDisplay: location?.platform?.platformName,
				value: location?.platform?.platformName?.toLowerCase()
			};
		}
		store.dispatch({
			type: ActionTypes.CATALOGUE_VERIFICATION_LIST_STATE_CHANGE,
			payload
		});
		store.dispatch({
			type: ActionTypes.CATALOGUE_VERIFICATION_LIST_SEARCH,
			payload: {
				selectedTab: status,
				location: location,
				platform: location?.platform
			}
		});
		// open catalogue verification drawer
		handleNestedEntity(true, NESTED_ENTITY_TYPES[20]);
	};

	const actionFilterValues = (data?.filters ?? []).filter((filter) => filter.field === "action");
	const platformFilterValues = (data?.filters ?? []).filter((filter) => filter.field === "platform");
	const statusFilterValues = (data?.filters ?? []).filter((filter) => filter.field === "status");

	const placeholderContent = {
		placeholderText: "No logs found!",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-logs.svg",
		redirectionLinkText: "learn more about brands",
		size: "medium"
	};

	return (
		<div className={"brand-logs-tab" + (loading ? " no-click" : "")}>
			<div className="search-container">
				{isMultibrandEnabled && fromLocations && brandLocations?.length > 0 && selectedBrandLocation && (
					<div className="row">
						<Popover
							open={showPopover}
							renderPopover={renderPopover}
							showClose={true}
							onClose={handlePopoverClose}
							position="down-left"
						>
							<SelectFilterCustom
								options={brandLocations || []}
								field="selectedBrandLocation"
								currValue={selectedBrandLocation}
								setFilter={(f, value) => handleBrandLocation(value)}
								labelKey={"brandName"}
								valueKey={"brandId"}
								isSearchable={false}
								customLabel={true}
								customOptions={true}
								isClearable={false}
								renderLabel={handleBrandsLabelOption}
								renderOptions={handleBrandsLabelOption}
								placeholder="Select brand"
								classes="brand-selector"
							/>
						</Popover>
					</div>
				)}
				{!fromLocations && (
					<div className="row">
						<SelectFilter
							options={associatedBrandLocations.items || []}
							placeholder="Location"
							currValue={selectedLocation}
							setFilter={setFilter}
							isAsync={true}
							handleSearch={(search) => fetchAssociatedBrandLocationsDebounced(String(brandId), search)}
							labelKey="name"
							valueKey="id"
							field="locationId"
						/>
					</div>
				)}
				<div className="row">
					<SelectFilterCustom
						options={
							platformFilterValues?.[0]?.values?.map((plf) => ({
								...plf,
								image:
									CATALOGUE_PLATFORMS_LOGO[plf?.valueForDisplay?.toLowerCase()] ||
									"/assets/icons/icons8-globe-40.png"
							})) || []
						}
						field={"platform"}
						labelKey={"valueForDisplay"}
						valueKey={"value"}
						multi={true}
						showCheckBox={true}
						showSelectAllOption
						showIcon={true}
						iconKey={"image"}
						ellipsizedLength={35}
						optionUpdates={optionUpdates}
						isSearchable={false}
						handleCheck={handleCheck}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						placeholder={"Select platforms"}
						selectAllPlaceholder="All Platforms"
						countDisplayText={"platform"}
						selected=""
						classes="platform-filter"
						handleSelectFilterState={handleSelectFilterState}
					/>
					<SelectFilterCustom
						options={actionFilterValues?.[0]?.values || []}
						field={"action"}
						labelKey={"valueForDisplay"}
						valueKey={"value"}
						multi={true}
						showCheckBox={true}
						showSelectAllOption
						ellipsizedLength={35}
						optionUpdates={optionUpdates}
						isSearchable={false}
						handleCheck={handleCheck}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						placeholder={"Select actions"}
						selectAllPlaceholder="All Actions"
						countDisplayText={"action"}
						selected=""
						classes="action-filter"
						handleSelectFilterState={handleSelectFilterState}
					/>
					<SelectFilter
						placeholder="Status"
						options={[{ value: "all", valueForDisplay: "All" }, ...(statusFilterValues?.[0]?.values ?? [])]}
						currValue={appliedFilters.status || null}
						setFilter={setFilter}
						isClearable={false}
						customDropdownLabel={
							appliedFilters.status ? (
								<div className="custom-value">
									Status <span>{appliedFilters.status?.valueForDisplay}</span>
								</div>
							) : null
						}
						labelKey="valueForDisplay"
						valueKey="value"
						field="status"
						containerClasses="status"
					/>
					<NewDateCompareFilter
						showDropdown={true}
						hideComparison={true}
						includeAllTime={true}
						currentDateFilter={dateFilter?.currentDateFilter}
						appliedDateFilter={dateFilter?.appliedDateFilter}
						updateState={setDateRangeFilter}
						position={"right"}
						monthsShown={1}
						defaultCustomRange={10}
						minDate={moment().subtract(ALLOWED_MONTHS, "months")}
					/>
				</div>
			</div>
			<CustomTable
				loading={loading}
				data={data?.objects || []}
				columns={columns}
				classes="locations-list-table-container"
				content="Locations"
				isMultibrandEnabled={isMultibrandEnabled}
				selectedBrand={
					brandId && brandName
						? { id: brandId, name: brandName, image: brandImage }
						: selectedBrandLocation?.brand
				}
				selectedLocation={
					locationId && locationName ? { id: locationId, name: locationName } : selectedLocation
				}
				handleCatalogueVerification={handleCatalogueVerification}
				showPlaceholder
				placeholderContent={placeholderContent}
			/>
			<Paginator
				limit={limit}
				offset={offset}
				count={data?.count || 0}
				goToPage={handlePagination}
				setPageSize={handlePageSize}
				showPageSize={true}
			/>
			<NestedEntityContainer
				isOpen={isCatalogueVerificationOpen}
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={true}
			/>
		</div>
	);
};

const mapStateToProps = (store) => ({
	associatedBrandLocations: store.configItems.associatedBrandLocations,
	isPrismEnabled: store.login.loggedInbizDetail.modulesEnabled.includes("PRISM")
});
export default connect(mapStateToProps)(Logs);

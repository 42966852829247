import React, { useState, useEffect } from "react";

// third party
import { connect } from "react-redux";
import moment from "moment";
import { useTrail, config, animated } from "react-spring";
import { fetchInvoiceDetailView } from "../../actions/invoices";

// utils
import history from "../../history";
import { formatDate, commifyNumbers, printCurrency, lS, trackEvent } from "../../atlas-utils";

// components
import { CopyToClipboard } from "../../components/_commons/CopyToClipboard";
import { FormSidebar } from "../../components/_commons/FormSidebar";
import { Button } from "../_commons/Button";

// constants
import { INVOICE_STATUS_MAP } from "./InvoiceListView";

const InvoiceDetailView = ({ invoiceDetailViewState, id, biz }) => {
	const [isFormOpen, setFormState] = useState(true);
	const [isModalBusy, setModalBusy] = useState(false);
	const { data, loading, error } = invoiceDetailViewState;

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	useEffect(() => {
		fetchInvoiceDetailView({
			id
		});
	}, [id, fetchInvoiceDetailView]);

	useEffect(() => {
		if (!loading && data?.dueDate && data?.source === "zoho_books") {
			const dueDateDiffDays = moment().diff(moment(data?.dueDate), "days");

			const range =
				dueDateDiffDays >= -30 && dueDateDiffDays <= -16
					? "-30"
					: dueDateDiffDays >= -15 && dueDateDiffDays <= -8
						? "-15"
						: dueDateDiffDays >= -7 && dueDateDiffDays <= -4
							? "-7"
							: dueDateDiffDays;

			trackEvent("billing_invoice_viewed", { cycle: `T${range > 0 ? `+${range}` : range}` });
		}
	}, [data.dueDate]);

	const handleCancel = () => {
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(() => {
				history.push("/billing");
			}, 100);
		}
	};

	const handlePaymentSuccess = () => {
		fetchInvoiceDetailView(
			{
				id
			},
			"no-cache"
		);
	};

	const handleSubmit = () => {
		if (data?.invoiceLink) {
			window.open(data.invoiceLink, "_blank");
		}
	};

	let invoiceItems = data.items || [];
	let invoiceStatus = "";
	let display_payment_link = false;
	let [downloading, setDownloading] = useState(false);
	const auth = lS.get("auth");
	const headers = { "Content-Type": "application/json", Authorization: auth && `Bearer ${auth.token}` };
	switch (data.status) {
		case "pending":
			invoiceStatus = "new";
			break;
		case "expired":
		case "void":
			invoiceStatus = "void";
			break;
		case "awaiting_confirmation":
			invoiceStatus = data.status.replace("_", " ");
			break;
		case "awaiting_payment":
			invoiceStatus = moment().diff(moment(data?.dueDate), "days") <= 0 ? "unpaid" : "overdue";
			break;
		case "partially_paid":
			invoiceStatus =
				data.status.replace("_", " ") +
				(moment().diff(moment(data?.dueDate), "days") <= 0 ? " pending" : " overdue");
			break;
		case "overdue":
		case "paid":
		default:
			invoiceStatus = data.status;
	}
	let invoiceStatusRibbon = "/assets/icons/icon-invoice-" + invoiceStatus + ".svg";
	let taxesInvoiceLevel = {};
	invoiceItems.forEach((invoiceItem) => {
		invoiceItem.taxes.forEach((tax) => {
			taxesInvoiceLevel[tax.id] = {
				...tax,
				totalAmount:
					((taxesInvoiceLevel[tax.id] && taxesInvoiceLevel[tax.id].totalAmount) || 0) +
					(invoiceItem.grossAmount - invoiceItem.discount)
			};
		});
	});
	let taxCalculations = Object.values(taxesInvoiceLevel) || [];
	let currency = (biz && biz.billingCurrency) || "";
	let currencySymbol = (biz && biz.billingCurrencySymbol) || "";
	let tds = (data.items && data.items.filter((x) => x.type === "billing_entity_tds")) || [];
	const statusLogs = createStatusLogs(data, currencySymbol);
	if (["new", "overdue"].includes(invoiceStatus)) {
		display_payment_link = true;
	}
	return (
		<div className="invoice-detail-view">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				title="Invoice Preview"
				subTitle="Preview your invoice, download a copy or make payment here"
				headerRight={
					<>
						<Button clickHandler={handleSubmit}>Pay Now</Button>
						<a
							className="upiper-id Mend(15px)"
							href={data.invoiceLink}
							style={{ textDecoration: "none", cursor: "pointer" }}
							target="_blank"
						>
							Download Invoice
						</a>
					</>
				}
				hideHeaderRight={
					!loading && data && data?.source
						? data?.source !== "zoho_books" ||
							!data?.invoiceLink ||
							["void", "paid", "expired"].includes(invoiceStatus)
						: true
				}
				hideActions={true}
			>
				<div className="invoice-section">
					{loading && (
						<div className="Pstart(25px) Pend(25px) Pt(25px)">
							<div className="shimmer H(35px)"></div>
						</div>
					)}
					{!loading && (
						<div className="invoice-detail-section-header">
							<div>
								<div className="header-text">
									<div className="title">
										<div className="text">
											# {data.title || data.number || `# ${data.externalRefId}`}
										</div>
										{/* {!["awaiting_payment", "partially_paid"].includes(invoiceStatus) && (
											<img src={invoiceStatusRibbon} alt="paid" />
										)} */}
										<div className="status">
											<div
												className={
													"chip " + invoiceStatus?.split(" ")?.join("-")?.toLowerCase()
												}
											>
												{INVOICE_STATUS_MAP[invoiceStatus] || invoiceStatus}
											</div>
										</div>
									</div>
									{data.source !== "zoho_books" && (
										<div className="invoice-from">
											Invoice From
											<br />
											<div style={{ paddingTop: 10 }}>
												<div>UrbanPiper Technologies Pvt. Ltd.</div>
												Unit 204, Brigade IRV Center
												<br />
												Nallurhalli
												<br />
												Bangalore, Karnataka, 560066
												<br />
												India
												<br />
												GSTIN: 29AAFCM5807R1ZP
												<br />
												<div className="payment-link">
													{display_payment_link && (
														<div>
															<a
																href={data.paymentLink}
																style={{ textDecoration: "none" }}
																target="_blank"
																rel="noopener noreferrer"
															>
																Pay Now
															</a>
															<CopyToClipboard
																content={data.paymentLink}
																label={"Copy Link"}
															/>
														</div>
													)}
												</div>
											</div>
										</div>
									)}
									{data.source !== "zoho_books" && (
										<div className="invoice-to">
											Invoice To
											<br />
											{data.franchisee ? (
												<div style={{ paddingTop: 10 }}>
													{data.franchisee.name || "--"}
													<br />
													{data.franchisee.addressLine1}
													{data.franchisee.addressLine1 && <br />}
													{data.franchisee.addressLine2}
													{data.franchisee.addressLine2 && <br />}
													{(data.franchisee.city ? data.franchisee.city + ", " : "") +
														(data.franchisee.state ? data.franchisee.state + ", " : "") +
														(data.franchisee.postalCode || "")}
													<br />
													{data.franchisee.country}
													<br />
													GSTIN: {data.franchisee.gstin || "--"}
												</div>
											) : (
												<div style={{ paddingTop: 10 }}>
													{biz && biz.registeredBusinessName}
													<br />
													{biz && biz.addressLine1}
													{biz && biz.addressLine1 && <br />}
													{biz && biz.addressLine2}
													{biz && biz.addressLine2 && <br />}
													{biz &&
														(biz.city ? biz.city + ", " : "") +
															(biz.state ? biz.state + ", " : "") +
															(biz.postalCode || "")}
													<br />
													{biz && biz.country}
													<br />
													GSTIN: {(biz && biz.gstin) || "--"}
												</div>
											)}
										</div>
									)}
								</div>
								<div className="header-action-button">
									<span className="invoice-date-text">
										Invoice date:
										<span className="invoice-date">
											{formatDate(data.invoiceDate || data.created, " DD MMM, YYYY")}
										</span>{" "}
									</span>
									{data.source !== "zoho_books" && (
										<div
											onClick={() => {
												if (!downloading) {
													setDownloading(true);
													fetch(
														`${process.env.REACT_APP_API_URL.replace(
															"graphql",
															"api"
														)}/download?type=invoice&id=${id}`,
														{ headers }
													)
														.then((res) => res.blob())
														.then((res) => {
															// set the blob type to final pdf
															const file = new Blob([res], { type: "application/pdf" });

															// process to auto download it
															const fileURL = URL.createObjectURL(file);
															const link = document.createElement("a");
															link.href = fileURL;
															link.download = `Invoice-${data.externalRefId}.pdf`;
															link.click();
															setDownloading(false);
														})
														.catch((err) => setDownloading(false));
												}
											}}
											className="button-container Cur(p)"
										>
											{!downloading && (
												<div>
													<img src="/assets/icons/icon-pdf-download.svg" />
												</div>
											)}
											{downloading && (
												<div>
													<div class="loader"></div>
												</div>
											)}
											<div className="button-title">Download PDF</div>
										</div>
									)}
								</div>
							</div>
						</div>
					)}
				</div>
				<div className="invoice-section" style={{ marginTop: "10px" }}>
					{loading && (
						<div className="P(25px)">
							<div className="shimmer H(100px) Mb(20px)"></div>
							<div className="shimmer H(100px) Mb(20px)"></div>
							<div className="shimmer H(100px) Mb(20px)"></div>
						</div>
					)}
					{!loading && (
						<div>
							{data?.finalAmount > 0 && (
								<div className="due-date-text">
									{printCurrency(data.currencySymbol || currencySymbol)}{" "}
									{commifyNumbers(parseFloat(data?.finalAmount?.toFixed(2)))} due on{" "}
									{formatDate(data?.dueDate, "DD MMM, YYYY")}
								</div>
							)}
							<Table
								loading={loading}
								data={data.items || []}
								isMobile={window.isMobile}
								currencySymbol={data?.currencySymbol ? data?.currencySymbol : currencySymbol}
							/>
							<div className="details-container">
								<div className="details">
									<div className="details-names">
										<div className="names">Sub Total</div>
										{data.discount > 0 && <div className="names">Discount</div>}
										{taxCalculations.map((tax, i) => {
											return (
												<div key={i} className="names">
													{tax.description} @ {tax.rate}% on{" "}
													{commifyNumbers(tax?.totalAmount)}{" "}
												</div>
											);
										})}
									</div>
									<div className="details-values">
										<div className="values">
											{printCurrency(data?.currencySymbol || currencySymbol)}&nbsp;{" "}
											{commifyNumbers(parseFloat(data?.grossAmount?.toFixed(2)))}
										</div>
										{data.discount > 0 && (
											<div className="values">
												- {printCurrency(data?.currencySymbol || currencySymbol)}&nbsp;{" "}
												{commifyNumbers(parseFloat(data?.discount?.toFixed(2)))}
											</div>
										)}
										{taxCalculations.map((tax, i) => {
											return (
												<div key={i} className="values">
													{printCurrency(data?.currencySymbol || currencySymbol)}&nbsp;{" "}
													{commifyNumbers(
														parseFloat(((tax?.rate / 100) * tax?.totalAmount)?.toFixed(2))
													)}
												</div>
											);
										})}
									</div>
								</div>
								<div className="details">
									<div className="details-names">
										<div className="names highlight">Net Total</div>
										{(data.invoicePayments || []).map((payment, i) => {
											return (
												<div key={i} className="names">
													Payment made
												</div>
											);
										})}
										{tds.map((t, i) => {
											return (
												<div key={i} className="names">
													{t.billingEntity.title}
												</div>
											);
										})}
									</div>
									<div className="details-values">
										<div className="values highlight">
											{printCurrency(data.currencySymbol || currencySymbol)}&nbsp;
											{commifyNumbers(parseFloat(data?.netAmount?.toFixed(2)))}
										</div>
										{(data.invoicePayments || []).map((payment, i) => {
											return (
												<div key={i} className="values">
													- {printCurrency(data.currencySymbol || currencySymbol)}&nbsp;{" "}
													{commifyNumbers(parseFloat(payment?.amount?.toFixed(2)))}
												</div>
											);
										})}
										{tds.map((t, i) => {
											return (
												<div key={i} className="values">
													- {printCurrency(data.currencySymbol || currencySymbol)}&nbsp;{" "}
													{commifyNumbers(parseFloat(t?.grossAmount?.toFixed(2)))}
												</div>
											);
										})}
									</div>
								</div>
								<div className="details highlight" style={{ borderBottom: "none" }}>
									<div className="details-names">
										<div className="names highlight">Balance Due</div>
									</div>
									<div className="details-values">
										<div className="values highlight">
											{printCurrency(data.currencySymbol || currencySymbol)}&nbsp;
											{commifyNumbers(parseFloat(data.finalAmount.toFixed(2)))}
										</div>
									</div>
								</div>
							</div>
							<div className="preview-note">
								This is a preview. Please download the invoice for an official copy.
							</div>
							<div className="invoice-detail-section-footer">
								<div className="history">
									<span style={{ paddingLeft: "24px", paddingTop: "10px", fontWeight: "bold" }}>
										History
									</span>
									<div className="transaction-details-status">
										{statusLogs.map((status, i) => {
											return (
												<TimelineItem
													key={i}
													color={status.color}
													time={status.time}
													isFinal={status.isFinal}
													update={status.update}
												/>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</FormSidebar>
		</div>
	);
};
const createStatusLogs = (invoice, currencySymbol) => {
	const standardStatusLogsColorPalette = {
		violet: "#753675",
		green: "#096329"
	};
	const violetColorVariants = {
		backgroundColor: "#7536751A",
		color: "#753675"
	};
	const greenColorVariants = {
		backgroundColor: "#0963291A",
		color: "#096329"
	};

	const statusLogs = [];
	currencySymbol = invoice?.currencySymbol ? invoice?.currencySymbol : currencySymbol;
	if (invoice) {
		statusLogs.push({
			color: standardStatusLogsColorPalette.violet,
			time: invoice.created,
			update: () => (
				<div className="status-tag" style={{ ...violetColorVariants }}>
					Invoice created
				</div>
			)
		});
	}
	if (invoice.creditInvoices) {
		invoice.creditInvoices.forEach((creditInvoice) => {
			statusLogs.push({
				color: standardStatusLogsColorPalette.violet,
				time: creditInvoice.created,
				update: () => (
					<React.Fragment>
						<div className="status-tag" style={{ ...violetColorVariants }}>
							Credit memo raised
						</div>
						<span className="status-info-content">
							(#{creditInvoice.externalRefId}) raised - {printCurrency(currencySymbol)}{" "}
							{commifyNumbers(creditInvoice.amount, null, { minimumFractionDigits: 2 })}
						</span>
					</React.Fragment>
				)
			});
		});
	}
	if (invoice.paymentTransactions) {
		invoice.paymentTransactions.forEach((paymentTransaction) => {
			statusLogs.push({
				color: standardStatusLogsColorPalette.violet,
				time: paymentTransaction.txnDate,
				update: () => (
					<React.Fragment>
						<div className="status-tag" style={{ ...violetColorVariants }}>
							Paid online
						</div>
						<span className="status-info-content">
							(#{paymentTransaction.txnId.substring(0, 5)}) - {printCurrency(currencySymbol)}{" "}
							{commifyNumbers(paymentTransaction.txnAmount, null, { minimumFractionDigits: 2 })}
						</span>
					</React.Fragment>
				)
			});
		});
	}
	if (invoice.status === "paid") {
		statusLogs.push({
			color: standardStatusLogsColorPalette.green,
			time: invoice.updated,
			update: () => (
				<React.Fragment>
					<div className="status-tag" style={{ ...greenColorVariants }}>
						Invoice paid
					</div>
				</React.Fragment>
			)
		});
	}
	if (invoice.invoicePayments) {
		invoice.invoicePayments.forEach((invoicePayment) => {
			statusLogs.push({
				color: standardStatusLogsColorPalette.green,
				time: invoicePayment.txnDate,
				update: () => (
					<React.Fragment>
						<div className="status-tag" style={{ ...greenColorVariants }}>
							Payment received
						</div>
						<span className="status-info-content">
							{printCurrency(currencySymbol)}{" "}
							{commifyNumbers(invoicePayment.amount, null, { minimumFractionDigits: 2 })}
						</span>
					</React.Fragment>
				)
			});
		});
	}
	statusLogs.sort((a, b) => moment(b.time) - moment(a.time));
	if (statusLogs.length > 0) {
		statusLogs[statusLogs.length - 1].isFinal = true;
	}
	return statusLogs;
};
const Table = (props) => {
	let nodes = [];
	if (props.data) {
		nodes = props.data;
	}
	const trail = useTrail(nodes.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div className={(props.loading ? "disabled" : "") + "invoice-details-table"}>
			<div className="at-table-row-based at-table--6cols">
				<TableHeader isMobile={props.isMobile} />
				{trail.map(({ rotate }, i) => {
					return (
						<TableList
							key={i}
							currLocation={props.currLocation}
							isMobile={props.isMobile}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...nodes[i]}
							currencySymbol={props.currencySymbol}
						/>
					);
				})}
				{nodes.length == 0 && <div className="no-items-placeholder Pb(50px)">Unable to load items</div>}
			</div>
		</div>
	);
};
const TableHeader = ({ isMobile }) => (
	<div className="at-table-row header-row">
		<div className="at-table-cell at-table-header at-header-text title">Title</div>
		<div className="at-table-cell at-table-header at-header-text description">Description</div>
		<div className="at-table-cell at-table-header at-header-text rate">Rate</div>
		<div className="at-table-cell at-table-header at-header-text quantity">Quantity</div>
		<div className="at-table-cell at-table-header at-header-text tax">Tax</div>
		<div className="at-table-cell at-table-header at-header-text amount">Amount</div>
	</div>
);

const TableList = (props) => {
	return (
		props.type !== "billing_entity_tds" && (
			<animated.div
				className={"at-table-row invoice-item-rows"}
				// style={props.style}
				key={props.id}
			>
				<div className="at-table-cell at-cell-text title">{props.billingEntity.title}</div>
				<div className="at-table-cell at-cell-text description">{props.billingEntity.description}</div>
				<div className="at-table-cell at-cell-text rate">
					{printCurrency(props.currencySymbol)}&nbsp;
					{commifyNumbers(parseFloat(props.unitPrice?.toFixed(2)))}
					{/* {props.billingEntity.unitType !== "fixed" && <span>&nbsp;per {props.billingEntity.unitType}</span>} */}
				</div>
				<div className="at-table-cell at-cell-text quantity">{commifyNumbers(props.quantity)}</div>
				<div className="at-table-cell at-cell-text tax">
					{commifyNumbers(parseFloat(props.taxRate?.toFixed(2)))} %
				</div>
				<div className="at-table-cell at-cell-text amount">
					{printCurrency(props.currencySymbol)}&nbsp;
					{commifyNumbers(parseFloat(props.grossAmount?.toFixed(2)))}
				</div>
			</animated.div>
		)
	);
};

const TimelineItem = ({ color, time, isFinal, update }) => {
	const circleBackground = {
		backgroundColor: color + "1A"
	};
	const concentricCircleBackground = {
		backgroundColor: color
	};
	return (
		<div className="status-item-container">
			<div className="timeline-container">
				<div className={`mask-circle ${isFinal && "animated-circle"}`}>
					<div style={circleBackground} className="timeline-circle">
						<div style={concentricCircleBackground} className="timeline-concentric-circle"></div>
					</div>
				</div>
				{!isFinal && <div className="timeline-bar" />}
			</div>
			<div className="status-info">
				<div className="status-title">{update()}</div>
				<div className="status-update-time">{formatDate(time, " Do MMMM YYYY - h:mm a")}</div>
			</div>
		</div>
	);
};

export default connect((store, props) => ({
	invoiceDetailViewState: store.invoiceDetailViewState,
	id: props.id,
	biz: store.login.loggedInbizDetail
}))(InvoiceDetailView);

import React, { useState, useRef } from "react";

// third party
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import parse from "html-react-parser";
import { HorizonBanner, TextVariant } from "@urbanpiper-engineering/horizon";

// components
import { Topbar } from "../../components/_commons/Topbar";
import CreditSettings from "../../containers/CreditSettings";
import InvoiceList from "../../containers/InvoiceList";
import InvoiceDetail from "../../containers/InvoiceDetail";
import { ButtonIcon } from "../_commons/ButtonIcon";

// utils
import { scroll, printCurrency, commifyNumbers } from "../../atlas-utils";

// constants
const bannerStatusColorMap = {
	info: "#3B5FEB",
	warning: "#E5B917",
	error: "#D64949",
	success: "#0DA125"
};
const FORM_TABS = [
	{
		label: "Invoices & Payments",
		value: "invoicesAndPayments"
	},
	{
		label: "Piper Credits",
		value: "piperCredits"
	}
];

const BillingContainer = ({ biz, renderRechargeDrawer, invoiceListViewState, billingRecoveryState }) => {
	return (
		<div>
			<Route
				path="/billing"
				render={(props) => (
					<BillingSettings
						location={props.location}
						biz={biz}
						renderRechargeDrawer={renderRechargeDrawer}
						invoiceListViewState={invoiceListViewState}
						billingRecoveryState={billingRecoveryState}
					/>
				)}
			/>
			<Route exact path="/billing/:id" component={InvoiceDetail} />
		</div>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail,
	invoiceListViewState: store.invoiceListViewState,
	billingRecoveryState: store.billingRecoveryState
});
export default connect(mapStateToProps)(BillingContainer);

export const BillingSettings = ({
	location,
	biz = {},
	renderRechargeDrawer = false,
	invoiceListViewState,
	billingRecoveryState
}) => {
	const query = new URLSearchParams(location.search);
	const [formTab, setFormTab] = useState(
		renderRechargeDrawer || query.get("tab") === FORM_TABS[1].value ? FORM_TABS[1].value : FORM_TABS[0].value
	);
	const { data, loading, error } = invoiceListViewState;
	const { modal } = billingRecoveryState;
	const topRef = useRef();

	const switchTab = (tab) => {
		setFormTab(tab.value);
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
	};

	const handleHorizonBannerText = (banner) => {
		return (
			<span className="banner-text">
				<span className="header">{banner?.header || ""}</span>
				<span className="body">{banner?.body ? parse(banner?.body?.replaceAll("<br>", "")) : ""}</span>
			</span>
		);
	};

	const bizState = biz.currentState;
	let totalOutstandingAmount = 0;
	let totalBilledAmount = 0;
	let totalPaidAmount = 0;
	let currencySymbol = "";
	if (!loading && !error) {
		totalOutstandingAmount = data?.outstandingAmount || 0;
		totalBilledAmount = data?.totalBilledAmount || 0;
		totalPaidAmount = data?.totalPaidAmount || 0;
		currencySymbol = data?.objects?.length ? data?.objects?.[0]?.currencySymbol : "";
	}
	const isBizDeactivated = bizState === "inactive" || bizState === "pending_deactivation";

	return (
		<div className="billing-section section-container-common" ref={topRef}>
			<div className="settings-header no-border">
				<div>
					<div className="header-text">Billing</div>
					<div className="subheader-text">
						View your invoices and payments here. Make payments to invoices directly
					</div>
				</div>
			</div>
			{modal?.invoiceId && modal?.header && modal?.body ? (
				<div className="billing-banner">
					<HorizonBanner
						className="!border-l-4"
						status={modal?.type || "info"}
						bannerText={handleHorizonBannerText(modal)}
						bannerTextVariant={TextVariant.BODY1}
						textClass="text-up-black-80"
						icon={
							<ButtonIcon
								icon={"invoice"}
								color={bannerStatusColorMap[modal?.type || "info"]}
								width="28px"
								height="28px"
							/>
						}
						onClose={undefined}
					/>
				</div>
			) : null}
			<div className="summary">
				<div className="heading">Summary</div>
				<div className="summary-cards">
					{loading && !data?.hasOwnProperty("outstandingAmount") ? (
						<div className="shimmer H(100px)"></div>
					) : (
						<>
							<div className="card-container">
								<div className="title">Billed</div>
								<div className="value">
									{printCurrency(currencySymbol || biz.billingCurrencySymbol)}{" "}
									{commifyNumbers(parseFloat(totalBilledAmount.toFixed(2)))}
								</div>
							</div>
							<div className="card-container">
								<div className="title">Paid</div>
								<div className="value">
									- {printCurrency(currencySymbol || biz.billingCurrencySymbol)}{" "}
									{commifyNumbers(parseFloat(totalPaidAmount.toFixed(2)))}
								</div>
							</div>
							<div className="card-container">
								<div className="title">Net Outstanding</div>
								<div className="value">
									<span>
										= {printCurrency(currencySymbol || biz.billingCurrencySymbol)}{" "}
										{commifyNumbers(parseFloat(totalOutstandingAmount.toFixed(2)))}
									</span>
								</div>
							</div>
						</>
					)}
				</div>
			</div>
			<Topbar
				tabs={FORM_TABS}
				selectedTab={formTab}
				switchTab={switchTab}
				isStickyOnTop={true}
				hiddenTabs={isBizDeactivated ? ["piperCredits"] : []}
			/>
			<div className="billing-container">
				{formTab === FORM_TABS[0].value && <InvoiceList />}
				{formTab === FORM_TABS[1].value && (
					<CreditSettings location={location} renderRechargeDrawer={renderRechargeDrawer} />
				)}
			</div>
		</div>
	);
};

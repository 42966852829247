import React, { useCallback, useState, useEffect } from "react";

// third party
import { connect } from "react-redux";
import { useTrail, config, animated } from "react-spring";
import { fetchInvoicesListView } from "../../actions/invoices";
import { Link } from "react-router-dom";
// utils
import { formatDate, commifyNumbers, printCurrency, scroll } from "../../atlas-utils";
import { InvoicePaymentFlow } from "../_commons/InvoicePaymentFlow";

// components
import { Paginator } from "../../components/_commons/Paginator";
import moment from "moment";

// constants
export const INVOICE_STATUS_MAP = {
	"partially paid pending": "partially paid",
	"partially paid overdue": "partially paid"
};

const InvoiceListView = ({ invoiceListViewState, biz, outstandingInvoicesState }) => {
	const [limit, setLimit] = useState(10);
	let [offset, setOffset] = useState(0);
	const [tableRef, setTableRef] = useState({});
	const { data, loading, error } = invoiceListViewState;

	const currencySymbol = biz.billingCurrencySymbol || "";
	useEffect(() => {
		fetchInvoicesListView(
			{
				limit,
				offset
			},
			"cache-first"
		);
	}, [limit, offset, fetchInvoicesListView]);
	const handlePagination = useCallback(
		async (page) => {
			await fetchInvoicesListView({
				limit,
				offset
			});
			if (tableRef) {
				scroll({ top: tableRef.offsetTop - 57, left: 0 });
			}
			setOffset((page - 1) * limit);
		},
		[limit, offset, fetchInvoicesListView]
	);
	const handlePaymentSuccess = () => {
		fetchInvoicesListView(
			{
				limit,
				offset
			},
			"no-cache"
		);
	};

	return (
		<div className="settings-section" ref={(ref) => setTableRef(ref)}>
			{loading && (
				<div className="P(25px)">
					<div className="shimmer H(100px) Mb(20px)"></div>
					<div className="shimmer H(100px) Mb(20px)"></div>
					<div className="shimmer H(100px) Mb(20px)"></div>
				</div>
			)}
			{!loading && (
				<div>
					{/* <Header
						outstandingInvoicesState={invoiceListViewState}
						currencySymbol={data?.objects?.length ? data?.objects?.[0]?.currencySymbol : currencySymbol}
						currency={biz.currency}
					/> */}
					<Table
						loading={loading}
						data={data || []}
						isMobile={window.isMobile}
						handlePaymentSuccess={handlePaymentSuccess}
						currencySymbol={currencySymbol}
					/>
				</div>
			)}
			<Paginator limit={data.limit} offset={data.offset} count={data.count} goToPage={handlePagination} />
		</div>
	);
};
export default connect((store) => ({
	invoiceListViewState: store.invoiceListViewState,
	biz: store.login.loggedInbizDetail,
	outstandingInvoicesState: store.outstandingInvoicesState
	// location: props.location
}))(InvoiceListView);

const Header = (props) => {
	const { data, loading, error } = props.outstandingInvoicesState;
	let totalOutstandingAmount = "--";
	let currencySymbol = "";
	if (!loading && !error) {
		// totalOutstandingAmount = (data.objects || []).map((x) => x.finalAmount || 0).reduce((acc, x) => acc + x, 0);
		totalOutstandingAmount = data.outstandingAmount || 0;
		currencySymbol = data?.objects?.length ? data?.objects?.[0]?.currencySymbol : "";
	}
	return (
		<div className="invoices-section-header">
			<div className="header-action-button">
				<div className="subheader-text">
					<span>&ensp;Find all your invoices at one place</span>
				</div>
				<div className="credit-balance">
					<span className="title">Total outstanding:</span>
					&nbsp;{printCurrency(currencySymbol || props.currencySymbol)}
					&nbsp;{commifyNumbers(totalOutstandingAmount, null, { minimumFractionDigits: 2 })}
				</div>
			</div>
		</div>
	);
};

const Table = (props) => {
	let nodes = [];
	if (props.data) {
		nodes = props.data.objects || [];
	}
	const trail = useTrail(nodes.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div className={(props.loading ? "disabled" : "") + " invoices-list-table"}>
			<div className="at-table-row-based at-table--5cols">
				<TableHeader isMobile={props.isMobile} />
				{trail.map(({ rotate }, i) => {
					if (nodes[i].purpose === "ONLINE_RELOAD" && nodes[i].status !== "PROCESSED") {
						return null;
					}
					return (
						<TableList
							handlePaymentSuccess={props.handlePaymentSuccess}
							key={nodes[i].id}
							isMobile={props.isMobile}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...nodes[i]}
							billingCurrencySymbol={props.currencySymbol}
						/>
					);
				})}
				{nodes.length == 0 && <div className="no-items-placeholder Pb(50px)">No invoice history!</div>}
			</div>
		</div>
	);
};

const TableHeader = ({ isMobile }) => (
	<div className="at-table-row header-row">
		<div className="at-table-cell at-table-header at-header-text invoice_num">Invoice No.</div>
		<div className="at-table-cell at-table-header at-header-text invoice_date">Invoice date</div>
		{!isMobile && (
			<React.Fragment>
				<div className="at-table-cell at-table-header at-header-text amount">Amount</div>
				<div className="at-table-cell at-table-header at-header-text paid_on">Due Date</div>
				<div className="at-table-cell at-table-header at-header-text status">Status</div>
			</React.Fragment>
		)}
		<div className="at-table-cell at-table-header at-header-text payment">Payment</div>
	</div>
);

const TableList = (props) => {
	let payment_status_text = "";
	let display_payment_link = false;
	let invoiceStatus = "";
	switch (props.status) {
		case "pending":
			invoiceStatus = "new";
			break;
		case "expired":
		case "void":
			invoiceStatus = "void";
			break;
		case "awaiting_confirmation":
			invoiceStatus = props.status.replace("_", " ");
			break;
		case "awaiting_payment":
			invoiceStatus = moment().diff(moment(props?.dueDate), "days") <= 0 ? "unpaid" : "overdue";
			break;
		case "partially_paid":
			invoiceStatus =
				props.status.replace("_", " ") +
				(moment().diff(moment(props?.dueDate), "days") <= 0 ? " pending" : " overdue");
			break;
		case "overdue":
		case "paid":
		default:
			invoiceStatus = props.status;
	}
	if (["new", "overdue"].includes(invoiceStatus)) {
		payment_status_text = "Due on " + formatDate(props.dueDate, "DD MMM, YYYY");
		display_payment_link = true;
	} else if (invoiceStatus === "paid") {
		let paid_status_update = props.statusUpdates.filter((x) => x.status === "paid")[0];
		payment_status_text = paid_status_update
			? "Paid on " + formatDate(paid_status_update.created, "DD MMM, YYYY")
			: "--";
	} else if (["awaiting payment", "awaiting confirmation", "partially paid"].includes(invoiceStatus)) {
		payment_status_text = invoiceStatus;
	} else {
		payment_status_text = "--";
	}
	return (
		<animated.div
			className={"at-table-row transaction-rows"}
			// style={props.style}
			key={props.id}
		>
			<div className="at-table-cell at-cell-text invoice_num">
				<Link className="upiper-id" to={`/billing/${props.id}`} style={{ textDecoration: "none" }}>
					<span className="hyperlink hyperlink--black-color">
						{props.title || props.number || props.externalRefId}
					</span>
				</Link>
				{props.invType === "reactivation" && <div className="sub-text">{"Reactivation fee"}</div>}
			</div>
			<div className="at-table-cell at-cell-text invoice_date">
				{formatDate(props.invoiceDate || props.created, "DD MMM, YYYY")}
			</div>
			<div className="at-table-cell at-cell-text amount">
				{(props.status === "pending" || props.status === "overdue") && props.finalAmount > 0 ? (
					<div>
						{printCurrency(props.currencySymbol || props.billingCurrencySymbol)}&nbsp;
						{commifyNumbers(parseFloat(props.finalAmount.toFixed(2)))}
					</div>
				) : (
					<div>
						{printCurrency(props.currencySymbol || props.billingCurrencySymbol)}&nbsp;
						{commifyNumbers(parseFloat(props.netAmount.toFixed(2)))}
					</div>
				)}
			</div>
			<div className="at-table-cell at-cell-text paid_on">
				{formatDate(props.dueDate, "DD MMM, YYYY")}
				{/* {!["paid", "void"].includes(invoiceStatus) ? formatDate(props.dueDate, "DD MMM, YYYY") : "--"} */}
			</div>
			<div className="at-table-cell at-cell-text status">
				<div className={"chip " + invoiceStatus?.split(" ")?.join("-")?.toLowerCase()}>
					{INVOICE_STATUS_MAP[invoiceStatus] || invoiceStatus}
				</div>
				{/* {["pending", "overdue"].includes(props.status) && props.creditInvoices.length > 0 && (
					<div className="sub-text">{"Credit note adjusted"}</div>
				)}
				{["awaiting_payment", "awaiting_confirmation"].includes(props.status) && (
					<div className="sub-text">{"Payment received"}</div>
				)} */}
			</div>
			<div className="at-table-cell at-cell-text payment">
				{props.source === "zoho_books" &&
				props.invoiceLink &&
				!["void", "paid", "expired"].includes(invoiceStatus) ? (
					<a
						className="upiper-id"
						href={props.invoiceLink}
						style={{ textDecoration: "none", cursor: "pointer" }}
						target="_blank"
					>
						Pay Now
					</a>
				) : props.source !== "zoho_books" && display_payment_link ? (
					<InvoicePaymentFlow
						finalAmount={props.finalAmount}
						invoiceId={props.id}
						invoiceRefId={props.externalRefId}
						paymentSuccessCallback={props.handlePaymentSuccess}
						currency={props.currency}
						currencySymbol={props.currencySymbol}
					/>
				) : null}
			</div>
		</animated.div>
	);
};

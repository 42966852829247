import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { dateFormat, periscopeHelpClicked } from "../../helpers/periscope";
import { Button } from "../_commons/Button";
import { connect } from "react-redux";
import HelpIcon from "../_commons/HelpIcon";
import { NestedEntityContainer } from "../_commons/NestedEntityContainer";
import { store } from "../../store/configureStore";
import { adjustNestedContainer, lS } from "../../atlas-utils";
import { NESTED_ENTITY_TYPES, periscopeVidTimeStamps } from "../../client-config";
import { ActionTypes } from "../../actions/_types";
import CheckMark from "../_icons/CheckMark";
import Popover from "../_commons/Popover";
import { Loading } from "../_commons/Loading";
import AutoRefresh from "../_icons/AutoRefresh";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const AutoRefreshPeriscope = ({ periscopeDataResponse, showTime = true }) => {
	const location = useLocation();
	const updated_at = periscopeDataResponse?.updated_at;
	const periscopeDataFetch = periscopeDataResponse.periscopeDataFetch;
	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "periscope",
				start: periscopeVidTimeStamps?.[location.pathname.split("/")[2]]
					? periscopeVidTimeStamps?.[location.pathname.split("/")[2]]?.time
					: "00:00"
			}
		});
		periscopeHelpClicked(periscopeVidTimeStamps?.[location.pathname.split("/")[2]]?.type || "Home");
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};
	const nestedRef = useRef();
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const handleNestedEntity = (toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	};
	const autoRefresh = periscopeDataResponse?.autoRefresh;
	const [autoRefreshValue, setAutoRefreshValue] = useState({
		value: "every_5_min",
		valueForDisplay: "Every 5 minutes"
	});
	const [borderColor, setBorderColor] = useState(false);
	const [canChange, setCanChange] = useState(true);
	const [showToolTip, setShowToolTip] = useState(false);

	// useEffect(() => {
	// 	const lastChangeTime = lS.get("autoRefreshPeriscope");
	// 	if (lastChangeTime) {
	// 		const currentTime = Date.now();
	// 		const timeDiff = currentTime - lastChangeTime;
	// 		if (timeDiff < 5 * 60 * 1000) {
	// 			setCanChange(false);
	// 			const remainingTime = 5 * 60 * 1000 - timeDiff;
	// 			setTimeout(() => {
	// 				setCanChange(true);
	// 			}, remainingTime);
	// 		}
	// 	}
	// }, []);

	const disableBtnFunc = () => {
		if (canChange) {
			setCanChange(false);
			setTimeout(
				() => {
					setCanChange(true);
					setBorderColor(true);
				},
				5 * 60 * 1000
			);
			const currentTime = Date.now();
			lS.set("autoRefreshPeriscope", currentTime);
			setBorderColor(false);
		}
	};
	const renderPopover = (data) => {
		return <div className="info-popover">{data}</div>;
	};

	const handleFiltersLabelOption = (a) => {
		return <></>;
	};
	const setAutpRefreshFilter = (f, value) => {
		if (value?.value == "off") {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { autoRefresh: false }
			});
		} else {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { autoRefresh: true, periscopeDataFetch: true }
			});
		}
		setAutoRefreshValue(value);
	};
	const refreshStatus = () => {
		store.dispatch({
			type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
			payload: { periscopeDataFetch: true }
		});
		disableBtnFunc();
	};

	const handleRefreshOptions = (filter) => {
		return (
			<div className="refresh-opt">
				<span>{filter.valueForDisplay}</span>
				<span className="check-mark">
					<CheckMark
						height={10}
						width={13}
						fill={filter.value == autoRefreshValue.value ? "#3B5FEB" : "none"}
					/>
				</span>
			</div>
		);
	};
	return (
		<div>
			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
			/>
			<div className="head-right-div">
				<div className="fetch-time">
					{/* {periscopeDataFetch ? (
						<span> Refreshing...</span>
					) : (
						showTime && updated_at && dateFormat(updated_at)
					)} */}
					{showTime && updated_at && dateFormat(updated_at)}
				</div>
				{/* <div
					className={`auto-refresh  ${borderColor ? "p-border-blue" : ""} ${!canChange ? "btn-disable" : ""}`}
				>
					<div
						className={`refresh-icon ${autoRefresh ? "pop-container" : ""}`}
						onClick={() => {
							refreshStatus();
						}}
						onMouseEnter={() => setBorderColor(true)}
						onMouseLeave={() => setBorderColor(false)}
					>
						<Popover
							data={autoRefresh ? "Auto refresh every 5 minutes" : "Refresh Now"}
							renderPopover={renderPopover}
							showOnHover={canChange}
							classes="fit-content"
							position={"down-left"}
							showOnClick
						>
							{periscopeDataFetch ? (
								<div className="loading-circle">
									<Loading circleClass={"circle"} circular filled color="#FFFFFF" />
								</div>
							) : (
								<AutoRefresh
									variations={borderColor ? { fill: "#3B5FEB" } : {}}
									hideText={!autoRefresh}
								/>
							)}
						</Popover>
					</div>

					<div className="chevron">
						<Popover
							data={"Auto refresh options"}
							renderPopover={renderPopover}
							showOnHover={showToolTip}
							classes="fit-content"
							position={"down-left"}
							showOnClick={showToolTip}
						>
							<SelectFilterCustom
								options={[
									{ value: "off", valueForDisplay: "Off" },
									{ value: "every_5_min", valueForDisplay: "Every 5 minutes" }
								]}
								field="autoRefreshValue"
								currValue={autoRefreshValue}
								setFilter={(f, value) => setAutpRefreshFilter(f, value)}
								labelKey={"valueForDisplay"}
								valueKey={"value"}
								renderLabel={handleFiltersLabelOption}
								customLabel={true}
								isSearchable={false}
								customOptions
								titleOption={"AUTO REFRESH"}
								renderOptions={handleRefreshOptions}
								handleSelectFilterState={() => setShowToolTip((prev) => !prev)}
							/>
						</Popover>
					</div>
				</div> */}

				<Button classes={"help"} clickHandler={handlePiperAcademy} type="secondary">
					<HelpIcon />
					<span>Help</span>
				</Button>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	periscopeDataResponse: state.periscopeDataResponse
});

export default connect(mapStateToProps)(AutoRefreshPeriscope);

import React, { useState, useEffect, useRef } from "react";

// components
import ParentCategoriesList from "./ParentCategoriesList";
import Placeholder from "../_commons/Placeholder";
import ItemsList from "./ItemsList";
import ListShimmer from "../_commons/ListShimmer";
import PreviewItems from "./PreviewItems";

// utils
import { trackEvent } from "../../atlas-utils";
import cloneDeep from "lodash/cloneDeep";

// constants
import { TRACKING_EVENT_NAMES } from "../../client-config";

const CategoriesSection = ({
	showHideCreateSection,
	showHideMenuReorderDrawer,
	showHideAddItemsDrawer,
	parentSections = [],
	selectedSection,
	currClickedSection,
	setCurrClickedSection,
	updatedSelectedSection,
	selectedSectionContent = {},
	filteredParentSections,
	handleItemDetailsClick,
	handleEntityDeletionForm,
	handleDetailsEditSection,
	parentSectionSearchKey = "",
	isSectionsLoading,
	isMenuLocked,
	showHideCustomisableItemDrawer,
	bulkItemModifierGroupMap,
	setBulkItemModifierGroupMap,
	numberOfItemsSelected = 0,
	showCopyMenuSection,
	openItemCreationForm = () => {}
}) => {
	if (parentSectionSearchKey !== "" && parentSections.length === 0 && !isSectionsLoading) {
		const emptySearchPlaceholderData = {
			placeholderText: "No results found",
			placeholderSubtext: "Try searching for another section, subsection or item",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-search.svg",
			size: "medium"
		};
		return <Placeholder {...emptySearchPlaceholderData} />;
	}

	if (isSectionsLoading && parentSections.length === 0) {
		return <ListShimmer />;
	}

	if (isMenuLocked) {
		return (
			<div className="preview-cart-container">
				<ParentCategoriesList
					isOnPreviewMode
					parentSections={filteredParentSections}
					selectedSection={selectedSection}
					setCurrClickedSection={setCurrClickedSection}
					updatedSelectedSection={updatedSelectedSection}
					isMenuLocked
				/>
				<PreviewSections
					showHideCustomisableItemDrawer={showHideCustomisableItemDrawer}
					selectedParentSectionData={selectedSectionContent}
					parentSections={parentSections}
					filteredParentSections={filteredParentSections}
					updatedSelectedSection={updatedSelectedSection}
					currClickedSection={currClickedSection}
					isMenuLocked
				/>
			</div>
		);
	}
	return (
		<div className={`main-categories-section ${isSectionsLoading ? "disabled" : ""}`}>
			<ParentCategoriesList
				showHideCreateSection={showHideCreateSection}
				showHideMenuReorderDrawer={showHideMenuReorderDrawer}
				parentSections={filteredParentSections}
				selectedSection={selectedSection}
				setCurrClickedSection={setCurrClickedSection}
				updatedSelectedSection={updatedSelectedSection}
				handleEntityDeletionForm={handleEntityDeletionForm}
				handleDetailsEditSection={handleDetailsEditSection}
				isMenuLocked={isMenuLocked}
				numberOfItemsSelected={numberOfItemsSelected}
				showCopyMenuSection={showCopyMenuSection}
			/>
			<CategoriesContents
				showHideAddItemsDrawer={showHideAddItemsDrawer}
				showHideCreateSection={showHideCreateSection}
				currSelectedSection={selectedSectionContent}
				parentSections={parentSections}
				filteredParentSections={filteredParentSections}
				currClickedSection={currClickedSection}
				handleItemDetailsClick={handleItemDetailsClick}
				handleEntityDeletionForm={handleEntityDeletionForm}
				handleDetailsEditSection={handleDetailsEditSection}
				updatedSelectedSection={updatedSelectedSection}
				isMenuLocked={isMenuLocked}
				selectedSection={selectedSection}
				bulkItemModifierGroupMap={bulkItemModifierGroupMap}
				setBulkItemModifierGroupMap={setBulkItemModifierGroupMap}
				numberOfItemsSelected={numberOfItemsSelected}
				showCopyMenuSection={showCopyMenuSection}
				openItemCreationForm={openItemCreationForm}
			/>
		</div>
	);
};
export default CategoriesSection;

const CategoriesContents = ({
	showHideAddItemsDrawer,
	showHideCreateSection,
	parentSections,
	filteredParentSections,
	currClickedSection,
	handleItemDetailsClick,
	handleEntityDeletionForm,
	handleDetailsEditSection,
	updatedSelectedSection,
	isMenuLocked,
	selectedSection,
	bulkItemModifierGroupMap,
	setBulkItemModifierGroupMap,
	numberOfItemsSelected,
	showCopyMenuSection,
	openItemCreationForm = () => {}
}) => {
	const categoriesContainerRef = useRef();
	const [isAllFieldSelectedMap, setIsAllFieldSelectedMap] = useState({});

	useEffect(() => {
		if (bulkItemModifierGroupMap) {
			// reset isAllFieldSelectedMap if bulkItemModifierGroupMap is an empty object
			const totalKeys = Object.keys(bulkItemModifierGroupMap)?.length || 0;
			if (totalKeys === 0) {
				setIsAllFieldSelectedMap({});
			}
		}
	}, [bulkItemModifierGroupMap]);

	const handleAllFieldsSelection = (state, categoryId) => {
		setIsAllFieldSelectedMap({
			...isAllFieldSelectedMap,
			[categoryId]: state
		});

		const _selectedFields = cloneDeep(bulkItemModifierGroupMap);
		if (state) {
			const targetSection = parentSections?.find((section) => section.id === categoryId);
			_selectedFields[categoryId] = {};
			if (targetSection?.items?.length) {
				targetSection.items.forEach((item) => {
					_selectedFields[categoryId][item.id] = true;
				});
			} else {
				_selectedFields[categoryId] = {};
			}
		} else {
			_selectedFields[categoryId] = {};
		}

		setBulkItemModifierGroupMap(_selectedFields);
	};

	const handleSingleFieldSelection = (id, categoryId) => {
		const _selectedFields = cloneDeep(bulkItemModifierGroupMap);
		if (_selectedFields[categoryId]) {
			if (_selectedFields[categoryId][id]) {
				_selectedFields[categoryId][id] = false;
				setIsAllFieldSelectedMap({
					...isAllFieldSelectedMap,
					[categoryId]: false
				});
			} else {
				_selectedFields[categoryId][id] = true;
				const numberOfFieldsSelected = Object.keys(_selectedFields[categoryId])?.length;
				const targetSection = parentSections?.find((section) => section.id === categoryId);
				if (numberOfFieldsSelected === targetSection?.items?.length) {
					setIsAllFieldSelectedMap({
						...isAllFieldSelectedMap,
						[categoryId]: true
					});
				}
			}
		} else {
			_selectedFields[categoryId] = {};
			_selectedFields[categoryId][id] = true;
		}
		setBulkItemModifierGroupMap(_selectedFields);
	};

	return (
		<div className="categories-contents-container" ref={categoriesContainerRef}>
			{filteredParentSections.map((section, i) => (
				<SelectedCategoryContent
					key={i}
					showHideAddItemsDrawer={showHideAddItemsDrawer}
					showHideCreateSection={showHideCreateSection}
					selectedSectionContent={section}
					currClickedSection={currClickedSection}
					parentSections={parentSections}
					handleItemDetailsClick={handleItemDetailsClick}
					handleEntityDeletionForm={handleEntityDeletionForm}
					handleDetailsEditSection={handleDetailsEditSection}
					updatedSelectedSection={updatedSelectedSection}
					isMenuLocked={isMenuLocked}
					categoriesContainerRef={categoriesContainerRef}
					selectedSection={selectedSection}
					selectedFields={bulkItemModifierGroupMap}
					isAllFieldSelectedMap={isAllFieldSelectedMap}
					handleAllFieldsSelection={handleAllFieldsSelection}
					handleSingleFieldSelection={handleSingleFieldSelection}
					numberOfItemsSelected={numberOfItemsSelected}
					showCopyMenuSection={showCopyMenuSection}
					openItemCreationForm={openItemCreationForm}
				/>
			))}
		</div>
	);
};

export const PreviewSections = ({
	showHideCustomisableItemDrawer,
	selectedParentSectionData,
	parentSections,
	filteredParentSections,
	updatedSelectedSection,
	currClickedSection,
	showRulesApplied = false,
	handleRuleAppliedClick,
	addOrCreateTestCart = () => {},
	isMenuLocked = false,
	selectedLanguage = {}
}) => {
	const previewContainerRef = useRef();
	return (
		<div className="preview-sections-container" ref={previewContainerRef}>
			{filteredParentSections?.map((section, i) => (
				<PreviewItems
					key={i}
					showHideCustomisableItemDrawer={showHideCustomisableItemDrawer}
					selectedParentSectionData={section}
					currSelectedSection={selectedParentSectionData}
					parentSections={parentSections}
					updatedSelectedSection={updatedSelectedSection}
					currClickedSection={currClickedSection}
					previewContainerRef={previewContainerRef}
					showRulesApplied={showRulesApplied}
					handleRuleAppliedClick={handleRuleAppliedClick}
					addOrCreateTestCart={addOrCreateTestCart}
					isMenuLocked={isMenuLocked}
					selectedLanguage={selectedLanguage}
				/>
			))}
		</div>
	);
};

const SelectedCategoryContent = ({
	showHideAddItemsDrawer,
	showHideCreateSection,
	selectedSectionContent,
	parentSections,
	currClickedSection,
	handleItemDetailsClick,
	handleEntityDeletionForm,
	handleDetailsEditSection,
	updatedSelectedSection,
	isMenuLocked,
	categoriesContainerRef,
	selectedSection,
	selectedFields,
	isAllFieldSelectedMap,
	handleAllFieldsSelection,
	handleSingleFieldSelection,
	numberOfItemsSelected,
	showCopyMenuSection,
	openItemCreationForm = () => {}
}) => {
	const categoryRef = useRef();

	const handleCardIntersection = (entries) => {
		entries.forEach((e) => {
			if (e.isIntersecting) {
				updatedSelectedSection(selectedSectionContent?.id);
			}
		});
	};

	const updateItemAdditionDrawerState = () => {
		const tempData = {
			categoryId: selectedSectionContent?.id,
			selectedItemsUnderCategory: [],
			categoryDetail: parentSections.find((category) => category.id === selectedSectionContent?.id)
		};
		showHideAddItemsDrawer(true, tempData);
	};

	const menuImportPlaceholderContent = {
		placeholderText: "No items or subsections added yet!",
		placeholderSubtext: "Add item or create subsections in this section",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-catalogue.svg",
		size: "medium",
		secondaryButtonContent: isMenuLocked ? null : <>Create Subsection</>,
		secondaryButtonClickAction: () => {
			trackEvent(TRACKING_EVENT_NAMES.NEW_SUBSECTION_CREATION, {
				type: "empty_section_create_subsection_button"
			});
			showHideCreateSection(true, true, selectedSectionContent?.id);
		},
		placeholderMultiActionButtonTitle: isMenuLocked ? null : <>Add Items</>,
		placeholderMultiActionButtonActions: [
			{
				title: "Create New Item",
				clickHandler: () => openItemCreationForm(selectedSectionContent?.id)
			},
			{
				title: "Add from Existing Items",
				clickHandler: isMenuLocked ? () => {} : () => updateItemAdditionDrawerState()
			},
			{
				title: "Copy from Another Menu",
				clickHandler: () => showCopyMenuSection(selectedSectionContent?.id)
			}
		],
		placeholderMultiActionButtonInstantAction: () => {}
	};

	useEffect(() => {
		const cardObserver = new IntersectionObserver(handleCardIntersection, {
			root: categoriesContainerRef?.current,
			threshold: 0.01,
			rootMargin: "0px 0px -90% 0px"
		});

		if (categoryRef?.current) {
			cardObserver.observe(categoryRef.current);
		}

		return () => {
			cardObserver.disconnect();
		};
	}, [categoryRef, parentSections]);

	const handleScrollToSection = () => {
		if (categoryRef?.current && currClickedSection === selectedSectionContent?.id) {
			updatedSelectedSection(selectedSectionContent?.id);
			categoryRef.current.scrollIntoView();
		}
	};

	useEffect(() => {
		if (currClickedSection) {
			handleScrollToSection();
		}
	}, [currClickedSection, parentSections]);

	if (selectedSectionContent?.subCategories?.length === 0 && selectedSectionContent?.items?.length === 0) {
		return (
			<div className="selected-category-content--container" ref={categoryRef}>
				<SectionHeader
					id={selectedSectionContent?.id}
					sectionTitle={selectedSectionContent?.name}
					subSectionsCount={selectedSectionContent?.subCategories.length}
					itemsCount={selectedSectionContent?.items.length}
					showHideCreateSection={showHideCreateSection}
					isMenuLocked={isMenuLocked}
					selectedSection={selectedSection}
					numberOfItemsSelected={numberOfItemsSelected}
				/>
				<Placeholder {...menuImportPlaceholderContent} />
			</div>
		);
	}

	if (!!selectedSectionContent?.subCategories?.length) {
		return (
			<div className="selected-category-content--container" ref={categoryRef}>
				<SectionHeader
					id={selectedSectionContent?.id}
					sectionTitle={selectedSectionContent?.name}
					subSectionsCount={selectedSectionContent?.subCategories.length}
					itemsCount={selectedSectionContent?.items.length}
					isRenderedFromSubCategory
					showHideCreateSection={showHideCreateSection}
					isMenuLocked={isMenuLocked}
					selectedSection={selectedSection}
					numberOfItemsSelected={numberOfItemsSelected}
				/>
				{selectedSectionContent.subCategories.map((subCategory) => (
					<ItemsList
						key={subCategory?.id}
						showHideAddItemsDrawer={showHideAddItemsDrawer}
						categoryId={subCategory?.id}
						isRenderedFromSubCategory
						subCategoryName={subCategory?.name}
						subCategoryId={subCategory?.id}
						categoryName={selectedSectionContent?.name}
						parentSections={parentSections}
						handleItemDetailsClick={handleItemDetailsClick}
						handleEntityDeletionForm={handleEntityDeletionForm}
						handleDetailsEditSection={handleDetailsEditSection}
						isMenuLocked={isMenuLocked}
						handleAllFieldsSelection={(state) => handleAllFieldsSelection(state, subCategory?.id)}
						handleSingleFieldSelection={(id) => handleSingleFieldSelection(id, subCategory?.id)}
						selectedFields={selectedFields?.[subCategory?.id] || {}}
						isAllFieldSelected={isAllFieldSelectedMap?.[subCategory?.id] ? true : false}
						numberOfItemsSelected={numberOfItemsSelected}
						showCopyMenuSection={showCopyMenuSection}
						openItemCreationForm={openItemCreationForm}
					/>
				))}
			</div>
		);
	}

	// Handling case when subCategories doesnt exist - occurrence of error
	if (!selectedSectionContent?.subCategories) {
		return null;
	}

	// handling case for section having only items in it
	return (
		<div className="selected-category-content--container" ref={categoryRef}>
			<SectionHeader
				id={selectedSectionContent?.id}
				sectionTitle={selectedSectionContent?.name}
				subSectionsCount={selectedSectionContent?.subCategories.length}
				itemsCount={selectedSectionContent?.items.length}
				description={selectedSectionContent?.description}
				isMenuLocked={isMenuLocked}
				selectedSection={selectedSection}
				numberOfItemsSelected={numberOfItemsSelected}
			/>
			<ItemsList
				itemsData={selectedSectionContent?.items}
				showHideAddItemsDrawer={showHideAddItemsDrawer}
				categoryId={selectedSectionContent?.id}
				categoryName={selectedSectionContent?.name}
				parentSections={parentSections}
				handleItemDetailsClick={handleItemDetailsClick}
				handleEntityDeletionForm={handleEntityDeletionForm}
				isMenuLocked={isMenuLocked}
				handleAllFieldsSelection={(state) => handleAllFieldsSelection(state, selectedSectionContent?.id)}
				handleSingleFieldSelection={(id) => handleSingleFieldSelection(id, selectedSectionContent?.id)}
				selectedFields={selectedFields?.[selectedSectionContent?.id] || {}}
				isAllFieldSelected={isAllFieldSelectedMap?.[selectedSectionContent?.id] ? true : false}
				numberOfItemsSelected={numberOfItemsSelected}
				showCopyMenuSection={showCopyMenuSection}
				openItemCreationForm={openItemCreationForm}
			/>
		</div>
	);
};

const SectionHeader = ({
	id,
	sectionTitle = "",
	subSectionsCount = 0,
	itemsCount = 0,
	description = "",
	isRenderedFromSubCategory,
	showHideCreateSection = () => {},
	isMenuLocked,
	numberOfItemsSelected = 0
}) => {
	return (
		<div className="section-header-container">
			<div className="main-section-details">
				<div className="title-and-entity-length">
					<span className="title">{sectionTitle}</span>
					<span className="entities-count">
						{!!subSectionsCount
							? `${subSectionsCount} subsections`
							: !!itemsCount
								? `${itemsCount} items`
								: ""}
					</span>
				</div>
				<div className="description">{description}</div>
			</div>
			<div className="menu-header-cta">
				{isRenderedFromSubCategory && !isMenuLocked && numberOfItemsSelected === 0 && (
					<span className="hyperlink" onClick={() => showHideCreateSection(true, true, id)}>
						Add Subsection
					</span>
				)}
			</div>
		</div>
	);
};

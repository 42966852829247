import React, { useEffect, useState } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Button } from "../_commons/Button";
import { CommonTable } from "../_commons/CommonTable";
import { FormSidebar } from "../_commons/FormSidebar";
import HorizonChip from "@urbanpiper-engineering/horizon/dist/base/HorizonChip";
import { Modal } from "../_commons/Modal";
import NotificationServices from "../../services/NotificationService";
import StatusAlertBox from "../_commons/StatusAlertBox";

// actions
import {
	addMerakiWebWorkspaceDomain,
	fetchMerakiWebWorkspace,
	deleteMerakiWebWorkspaceDomain,
	fetchMerakiWebWorkspaceDomain
} from "../../actions/merakiWeb";
import { CopyToClipboard } from "../_commons/CopyToClipboard";

// assets
import CreateIcon from "../_commons/CreateIcon";

// constants
const MERAKI_DNS_URL = process.env.REACT_APP_MERAKI_DNS_URL;

const DOMAIN_FORM_INITIAL_STATE = {
	url: "",
	type: "Alias"
};

const domainColumns = [
	{
		name: "TYPE",
		field: "record-type",
		sortKey: "",
		render: (aRecord, i) => (
			<div className={"at-table-cell at-cell-text record-type"} key={i}>
				<div>{aRecord.type}</div>
			</div>
		)
	},
	{
		name: "NAME",
		field: "record-name",
		sortKey: "",
		render: (aRecord, i) => (
			<div className={"at-table-cell at-cell-text record-name"} key={i}>
				<div>{aRecord.name}</div>
			</div>
		)
	},
	{
		name: "VALUE",
		field: "record-value",
		sortKey: "",
		render: (aRecord, i) => (
			<div className={`at-table-cell at-cell-text record-value ${aRecord.error ? "missing-record" : ""}`} key={i}>
				<div>{aRecord.value}</div>
				{aRecord.error && <span className="error-message">The value is missing on the hosting platform.</span>}
			</div>
		)
	},
	{
		name: "",
		field: "record-action",
		sortKey: "",
		render: (aRecord, i) => (
			<div className={"at-table-cell at-cell-text record-action"} key={i}>
				<CopyToClipboard content={aRecord.value} showIcon={true} />
			</div>
		)
	}
];

const columns = [
	{
		name: "Domain Name",
		field: "domain",
		sortKey: "",
		render: (record, i, _, _1, handleTask) => (
			<div className={"at-table-cell at-cell-text domain"} key={i}>
				<div>
					{record?.url ? (
						<div className="hyperlink hyperlink--black-color" onClick={() => handleTask("verify", record)}>
							{record.url}
						</div>
					) : (
						"--"
					)}
					{record?.type === "Internal" ? (
						<span className="domain-internal">UrbanPiper Managed Domain</span>
					) : null}
				</div>
				{/* Todo: {record.type === "Primary" ? (
					<HorizonChip
						normalFont
						color="gray"
						status={{ text: "Primary" }}
						containerProps={{
							className: "ml-4"
						}}
					/>
				) : null} */}
			</div>
		)
	},
	{
		name: "Status",
		field: "verified",
		sortKey: "",
		render: (record, i, _, _1, handleTask) => (
			<div className={"at-table-cell at-cell-text verified"} key={i}>
				<HorizonChip
					normalFont
					color={record?.verified || record?.type === "Internal" ? "green" : "red"}
					status={{
						text: record?.verified || record?.type === "Internal" ? "Connected" : "Not Connected"
					}}
					containerProps={{
						className: ""
					}}
				/>
			</div>
		)
	}
];

export const Domains = ({ workspace = {}, loading }) => {
	const [isFormOpen, setFormOpen] = useState(false);
	const [domainVerification, setDomainVerification] = useState(false);
	const [domainForm, setDomainForm] = useState(DOMAIN_FORM_INITIAL_STATE);
	const [domainData, setDomainData] = useState(null);
	const [isPrimaryModalOpen, setPrimaryModalOpen] = useState(false);
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const [loadinDomainForm, setLoadingDomainForm] = useState(false);
	const [deleteDomain, setDeleteDomain] = useState(null);
	const [contextMenuId, setContextMenuId] = useState(null);
	const [showFailedStatus, setShowFailedStatus] = useState(false);

	useEffect(() => {
		handleFailedDomainVerification();
	}, [workspace]);

	const netlify = workspace?.netlify || {};
	// if the website is deployed on netlify, use their cname or else use
	// UrbanPiper's cname which should work fine for all businesses hosted on Kuberenetes
	const cname = netlify?.id?.trim() ? netlify?.cname : MERAKI_DNS_URL;

	if (loading && !workspace.netlify) {
		return (
			<div style={{ margin: "25px" }}>
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	const getDomainsForDisplay = () => {
		let domains = [...(workspace?.netlify?.domains || [])];
		domains = domains.map((domain) => ({ ...domain, id: domain.url }));
		// show internal preview URL
		if (workspace?.netlify?.cname) {
			domains.unshift({
				url: workspace.netlify.cname,
				type: "Internal",
				id: workspace.netlify.cname
			});
		}
		return domains;
	};

	function handleFailedDomainVerification() {
		const domains = workspace?.netlify?.domains || [];
		const failed = domains.find((domain) => domain.verified === false);
		if (failed) {
			setShowFailedStatus(true);
		} else {
			setShowFailedStatus(false);
		}
	}

	const handleAddDomain = () => {
		setFormOpen(true);
		setDomainForm(DOMAIN_FORM_INITIAL_STATE);
	};

	const handleClose = () => {
		setFormOpen(false);
		setDomainForm(DOMAIN_FORM_INITIAL_STATE);
	};

	const handleDomainVerification = async (domain) => {
		setDomainVerification(true);

		if (domain.type === "Internal") {
			domain = {
				...domain,
				verified: true,
				aRecords: { connected: workspace.netlify.aRecords },
				cname: { missing: workspace.netlify.cname }
			};
		}

		setDomainData((prev) => {
			if (!prev) return domain;
			return { ...prev, domain };
		});
		setLoadingDomainForm(true);
		const data = await fetchMerakiWebWorkspaceDomain(domain);
		setLoadingDomainForm(false);
		if (data) {
			setDomainData((prev) => {
				return { ...prev, ...data };
			});
		}
	};

	const handleCloseDomainVerification = () => {
		setDomainVerification(false);
		setDomainData(null);
	};

	const openContextMenu = (record) => {
		setDomainData(record);
		setContextMenuId(record.url);
	};

	const handleDomainForm = (field, value) => {
		const update = {
			...domainForm,
			[field]: value
		};
		setDomainForm(update);
	};

	const handleDomainFormSubmit = async () => {
		setLoadingDomainForm(true);
		try {
			if (workspace.netlify.domains.length === 0) {
				domainForm.type = "Primary";
			}
			await addMerakiWebWorkspaceDomain(domainForm);
			handleClose();
			const workSpace = await fetchMerakiWebWorkspace();
			const domains = workSpace.netlify.domains;
			if (domains.length) {
				const newDomain = domains[domains.length - 1];
				setDomainData(newDomain);
			}
			handleDomainVerification(domainForm);
		} catch (error) {
			console.log(error);
		}
		setLoadingDomainForm(false);
	};

	const handleDomainDelete = async (domain) => {
		setLoadingDomainForm(true);
		try {
			await deleteMerakiWebWorkspaceDomain(domain);
			setDeleteModalOpen(false);
			handleCloseDomainVerification();
			fetchMerakiWebWorkspace();
			handleFailedDomainVerification();
		} catch (error) {
			console.log(error);
		}
		setLoadingDomainForm(false);
	};

	const handleDomainActions = (type, domain) => {
		if (type === "delete") {
			handleDomainDelete(domain);
		} else if (type === "verify") {
			handleDomainVerification(domain);
		} else if (type === "primary") {
			setPrimaryModalOpen(true);
		}
	};

	const handleClosePrimaryModal = () => {
		setPrimaryModalOpen(false);
	};

	const handleCloseDeleteModal = () => {
		setDeleteModalOpen(false);
	};

	const handleDeleteModalOpen = (domain) => {
		setDeleteModalOpen(true);
		setDeleteDomain(domain);
	};

	const handlePrimaryModalOpen = () => {
		setPrimaryModalOpen(true);
	};

	const getDomainArecordData = () => {
		let connected = domainData?.aRecords?.connected || [];
		let missing = domainData?.aRecords?.missing || [];

		connected = connected.map((record) => ({
			type: "A",
			name: "@",
			value: record
		}));

		missing = missing.map((record) => ({
			type: "A",
			name: "@",
			value: record,
			error: true
		}));
		const data = [...connected, ...missing];
		data.unshift({ type: "CNAME", name: "www", value: domainData?.cname?.missing });
		return data;
	};

	const handleFetchWorkspaceData = async () => {
		try {
			setLoadingDomainForm(true);
			const data = await fetchMerakiWebWorkspace();

			const domainConnected = data.netlify.domains.find((domain) => domain.url === domainData.url).verified;

			if (domainConnected) {
				NotificationServices.pushNotification({
					message: "Custom domain connection successful",
					timeout: 3000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
			} else {
				NotificationServices.pushNotification({
					message: "Domain yet to be connected !",
					timeout: 3000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
		} catch (error) {
			console.error(error);
		} finally {
			setLoadingDomainForm(false);
		}
	};

	const handleCloseContextMenu = () => {
		setContextMenuId(null);
	};

	const renderMenuItems = () => {
		return (
			<>
				{/* <div
					onClick={() => {
						handlePrimaryModalOpen();
						setContextMenuId(null);
					}}
					className="context-menu-item"
				>
					Set as Primary
				</div> */}
				<div
					onClick={() => {
						if (domainData?.type !== "Internal") {
							handleDeleteModalOpen(domainData);
							setContextMenuId(null);
						}
					}}
					className={`context-menu-item remove-btn ${domainData?.type === "Internal" ? "btn-disabled" : ""}`}
				>
					Remove
				</div>
			</>
		);
	};

	return (
		<div className={"domains-container " + (loading && workspace.netlify ? "disabled" : "")}>
			{showFailedStatus ? (
				<div className="status-container">
					<StatusAlertBox
						status="ERROR"
						title="DNS records entered incorrectly"
						message="Your DNS records do not match. Please review and update the correct DNS records in your hosting platform to point your custom domain to UrbanPiper's subdomain using the new values provided below."
					/>
				</div>
			) : null}

			<div className="domain-header">
				<div className="title">Manage your domains here</div>
				<div className="action">
					<Button clickHandler={handleAddDomain}>
						<CreateIcon />
						<span>Add Custom Domain</span>
					</Button>
				</div>
			</div>
			<CommonTable
				loading={loading}
				data={getDomainsForDisplay()}
				columns={columns}
				classes="domains-list-table-container"
				content="Domains"
				handleTask={handleDomainActions}
				renderMenuItems={renderMenuItems}
				openContextMenu={openContextMenu}
				contextMenuId={contextMenuId}
				showContextMenu={true}
				closeContextMenu={handleCloseContextMenu}
			/>
			<FormSidebar
				isOpen={isFormOpen}
				close={handleClose}
				submit={handleDomainFormSubmit}
				title="Add a Custom Domain"
				subTitle="Enter the Domain you want to connect"
				loading={loadinDomainForm}
				submitTitle="Save"
				disabled={domainForm.url === "" || loadinDomainForm ? true : false}
			>
				<div className="add-domain-container">
					<InputWithLabel
						value={domainForm.url}
						onChange={(e) => handleDomainForm("url", e.target.value)}
						placeholder="Example: order.example.com"
					>
						Domain
					</InputWithLabel>
				</div>
			</FormSidebar>
			<FormSidebar
				isOpen={domainVerification}
				loading={loadinDomainForm}
				close={handleCloseDomainVerification}
				title={domainData?.url || ""}
				subTitle="Your custom domain setup"
				hideSubmitAction={true}
				cancelTitle="Close"
				headerRight={
					domainData?.type !== "Internal" && (
						<Button
							onClick={() => {
								handleDeleteModalOpen(domainData);
							}}
							classes={"remove-btn"}
							type="secondary"
						>
							Remove
						</Button>
					)
				}
			>
				<div className="domain-container">
					{!domainData?.verified ? (
						<StatusAlertBox
							status="ERROR"
							title="DNS records are entered incorrectly"
							message="Your DNS records do not match. Please review and update the correct DNS records in your hosting platform to point your custom domain to UrbanPiper's subdomain using the new values provided below. If already done click on verify connection."
						/>
					) : null}

					<div className="domain-status-container">
						<div className="domain-status-left">
							<div className="domain-status-globe-bg">
								<img src="/assets/icons/icon-globe.svg" alt="" />
							</div>
							<div>
								<h5 className="domain-status-title">Domain Status</h5>
								<div className="domain-status">
									<HorizonChip
										normalFont
										color={
											domainData?.verified || domainData?.type === "Internal" ? "green" : "red"
										}
										status={{
											text:
												domainData?.verified || domainData?.type === "Internal"
													? "Connected"
													: "Not Connected"
										}}
									/>
									{!domainData?.verified ? (
										<span onClick={handleFetchWorkspaceData} className="link">
											Check Manually
										</span>
									) : null}
								</div>
							</div>
						</div>
						{/* Todo:  <span
							onClick={() => {
								if (domainData?.type !== "Primary") {
									handlePrimaryModalOpen();
								}
							}}
							className={`${domainData?.type !== "Primary" ? "link" : ""} domain-status-right`}
						>
							{domainData?.type === "Primary"
								? "Primary"
								: domainData?.type === "Internal"
									? ""
									: "Set as Primary"}
						</span> */}
					</div>
					<div>
						<h4 className="hosting-service-title">DNS records for hosting service</h4>
						<p className="hosting-service-desc">
							Add DNS records to your hosting service to connect your domain to your website.
						</p>
						<CommonTable
							loading={false}
							data={getDomainArecordData()}
							columns={domainColumns}
							bordered
							classes="aRecords-list-table-container"
							content="aRecords"
						/>
						<div className="help-articles">
							Help articles:{" "}
							<a href="https://www.godaddy.com/en-in/help/add-a-cname-record-19236" target="_blank">
								GoDaddy <img src="/assets/icons/icon-external-link.svg" alt="" />
							</a>
							<a
								href="https://www.namecheap.com/support/knowledgebase/article.aspx/9646/2237/how-to-create-a-cname-record-for-your-domain/"
								target="_blank"
							>
								Namecheap <img src="/assets/icons/icon-external-link.svg" alt="" />
							</a>
							<a
								href="https://support.hostinger.com/en/articles/4738777-how-to-manage-cname-records"
								target="_blank"
							>
								Hostinger <img src="/assets/icons/icon-external-link.svg" alt="" />
							</a>
						</div>
					</div>
				</div>
			</FormSidebar>
			<Modal
				title={"Set Domain as Primary Domain ?"}
				isOpen={isPrimaryModalOpen}
				close={handleClosePrimaryModal}
				submitAction={() => {
					handleClosePrimaryModal();
					NotificationServices.pushNotification({
						message: "Domain set as primary successful",
						timeout: 3000,
						type: "success",
						isClosable: true,
						theme: "dark"
					});
				}}
				submitTitle="Set as Primary"
				cancelAction={handleClosePrimaryModal}
				cancelTitle="Cancel"
				classes="primary-modal"
				showSubmitAction
				showCancelAction
			>
				<div className="primary-modal-content">
					Are you sure you want to make this domain your primary? This will set it as the main address for
					your website and redirect all traffic from other domains.
				</div>
			</Modal>
			<Modal
				title={"Remove Custom Domain?"}
				isOpen={isDeleteModalOpen}
				close={handleCloseDeleteModal}
				deleteAction={() => handleDomainActions("delete", deleteDomain)}
				deleteTitle="Confirm Removal"
				cancelAction={handleCloseDeleteModal}
				cancelTitle="Cancel"
				classes="delete-modal"
				showDeleteAction
				showCancelAction
			>
				<div className="primary-modal-content">
					Are you sure you want to remove your custom domain? By doing so, the redirection will be removed,
					and it will no longer be connected to your website.
				</div>
			</Modal>
		</div>
	);
};

import React, { useState, useEffect } from "react";

// third party
import { connect } from "react-redux";
import history from "../../history";

// store
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchDspPlatformsList } from "../../actions/dspIntegration";

// constants
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
import { DSP_INTEGRATION_INITIAL_STATE } from "../../reducers/dspIntegration";
import { ButtonIcon } from "../_commons/ButtonIcon";
import StatusInfo from "../_commons/StatusInfo";
export const PLATFORM_MAP = {
	ubereats: "Uber Eats",
	doordash: "DoorDash",
	thechefz: "Chefz"
};

const Integrations = ({ dspPlatformsList, dspIntegration, isMenuOverCatalogueEnabled = false }) => {
	const { isLoading, objects, count } = dspPlatformsList;
	const [hasError, setHasError] = useState(false);

	useEffect(() => {
		fetchDspPlatformsList();
	}, []);

	const handleDSPIntegration = (dsp, isDSPIntegrated = false) => {
		// update state
		store.dispatch({
			type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
			payload: {
				...DSP_INTEGRATION_INITIAL_STATE,
				selectedBrand: dspIntegration?.platform === dsp ? dspIntegration?.selectedBrand : null,
				isDSPIntegrated,
				isValidDSP: true,
				platform: dsp
			}
		});
		// redirect
		history.push(`/hub/integrations/${dsp}`);
	};

	useEffect(() => {
		const errorFound = objects.filter((obj) => obj.failedMenuAssociations > 0);
		setHasError(errorFound.length);
	}, [objects]);

	if (isLoading && objects?.length === 0) {
		return (
			<div>
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	if (!isLoading && objects?.length === 0) {
		return (
			<div className="integrations-container">
				<div className="header-text">Integrate an Online Ordering Platform</div>
				<div className="no-items-placeholder">No Platforms found</div>
			</div>
		);
	}

	return (
		<div className="integrations-container">
			<div className="header-text">Integrate an Online Ordering Platform</div>
			{hasError ? (
				<StatusInfo
					icon="error-triangle"
					status="failed"
					color="#b02b2b"
					header={`Requests failed for ${hasError} Online Ordering ${
						hasError == 1 ? "Platform" : "Platforms"
					}`}
					description="Review failed requests on respective platforms to take locations live."
				/>
			) : null}

			<div className={"dsps" + (isLoading ? " disabled" : "")}>
				{objects.map((plf, i) => (
					<div
						key={i}
						className="plf-card-container"
						onClick={() => handleDSPIntegration(plf?.platform?.toLowerCase(), !plf?.isExpired)}
					>
						<div className="icon" title={PLATFORM_MAP[plf.platform] || plf.platform}>
							<img
								src={
									CATALOGUE_PLATFORMS_LOGO[plf?.platform?.toLowerCase()] ||
									"/assets/icons/icons8-globe-40.png"
								}
								alt=""
							/>
						</div>
						<div className="platform">
							<div className="link">
								<div className="name">{PLATFORM_MAP[plf.platform] || plf.platform}</div>
								{!plf?.isExpired && (
									<div className="info">
										{
											<div className="live-stores">
												<img src="/assets/icons/blue-dot.svg" alt="" />
												<div className="text">
													{plf?.activeStoresCount} /{" "}
													{plf?.activeStoresCount +
														plf?.mappedStoresCount +
														plf?.unmappedStoresCount}
													{plf?.activeStoresCount +
														plf?.mappedStoresCount +
														plf?.unmappedStoresCount ===
													1
														? " Store"
														: " Stores"}{" "}
													Active
												</div>
											</div>
										}
										{plf?.failedMenuAssociations ===
											plf?.activeStoresCount +
												plf?.mappedStoresCount +
												plf?.unmappedStoresCount && plf?.failedMenuAssociations != 0 ? (
											<div className="store-status store-status-error">
												<div className="text">Failed</div>
											</div>
										) : plf?.failedMenuAssociations > 0 ? (
											<div className="store-status store-status-error">
												<div className="text">Partially Failed</div>
											</div>
										) : plf?.inProgressMenuAssociations > 0 ? (
											<div className="store-status store-status-inprogress">
												<div className="text">Mapping in progress</div>
											</div>
										) : plf?.activeStoresCount ===
												plf?.activeStoresCount +
													plf?.mappedStoresCount +
													plf?.unmappedStoresCount &&
										  plf?.activeStoresCount + plf?.mappedStoresCount + plf?.unmappedStoresCount >
												0 ? (
											<div className="store-status store-status-success">
												<div className="text">Mapping Complete</div>
											</div>
										) : null}
										<img src="/assets/icons/icon-right-arrow.svg" alt="" />
									</div>
								)}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	dspPlatformsList: store.dspPlatformsList,
	dspIntegration: store.dspIntegration,
	isMenuOverCatalogueEnabled: store.login.loggedInbizDetail?.isMenuOverCatalogueEnabled || false
});
export default connect(mapStateToProps)(Integrations);

import React, { useState, useEffect, useRef } from "react";

// components
import { FormSidebar } from "../_commons/FormSidebar";
import { Topbar } from "../_commons/Topbar";
import { CustomTable } from "../_commons/CustomTable";
import { Paginator } from "../_commons/Paginator";
import { SelectFilter } from "../_commons/SelectFilter";
import { SearchFilter } from "../_commons/SearchFilter";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import { NestedEntityContainer } from "../_commons/NestedEntityContainer";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";

// store
import { client } from "../../client";
import { store } from "../../store/configureStore";

// utils
import { formatDate, adjustNestedContainer, scroll, capitaliseText, extractInitials } from "../../atlas-utils";

// graphql
import { GET_STORES_LIST } from "../../graphql/misc";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchCatalogueVerificationList } from "../../actions/catalogueVerification";
import { messageArrayToText } from "../../containers/CatalogueVerificationList";
import { fetchBrands, fetchBizPlatforms } from "../../actions/actions";

// constants
import { NESTED_ENTITY_TYPES } from "../../client-config";
import { ENTITY_TYPE_MAP } from "../../containers/CatalogueVerificationList";
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const columns = [
	{
		name: "Name",
		field: "name",
		render: (record, i, rest) => (
			<div className="table-cell name" title={record.name} key={i}>
				{record.entityId ? (
					<a
						role="button"
						className="hyperlink hyperlink--black-color"
						onClick={() =>
							rest.handleNestedEntity(
								true,
								NESTED_ENTITY_TYPES[
									NESTED_ENTITY_TYPES.indexOf(ENTITY_TYPE_MAP[record.entity].split(" ").join("-"))
								],
								parseInt(record.entityId)
							)
						}
					>
						{record.name || record.entityId}
					</a>
				) : (
					<div>
						<b>{record.name || "--"}</b>
					</div>
				)}
				<div className="text--light desc-text" title={record.crmTitle}>
					{record.crmTitle && `CRM Title: ${record.crmTitle}`}
				</div>
			</div>
		)
	},
	{
		name: "Type",
		field: "type",
		render: (record, i) => (
			<div className="table-cell type" key={i}>
				{record.entityId ? ENTITY_TYPE_MAP[record.entity] || record.entity || "--" : "Misc"}
			</div>
		)
	},
	{
		name: "Platform",
		field: "platform",
		render: (record, i) => (
			<div className="table-cell platform" key={i}>
				{record.platformName || "--"}
			</div>
		)
	},
	{
		name: "Description",
		field: "description",
		render: (record, i, rest) => (
			<div className="table-cell description" key={i}>
				{messageArrayToText(record.messages, record.entityId, rest.expandedDesc, rest.handleReadMore, 80)}
			</div>
		)
	}
];

const CatalogueVerification = ({
	isOpen = false,
	close,
	verificationDetails,
	isNested = false,
	isPrismEnabled = false,
	isMultibrandEnabled = false,
	brands,
	bizPlatforms,
	catalogueVerificationList,
	catalogueVerificationListState,
	closeNestedContainer
}) => {
	const { limit, offset, sortedField, currentFilters, appliedFilters } = catalogueVerificationListState;
	const [loading, setLoading] = useState(false);
	const [expandedDesc, setExpandedDesc] = useState({});
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [locationsList, setLocationsList] = useState({
		isLoading: false,
		objects: []
	});
	const tableRef = useRef();
	const nestedRef = useRef();

	useEffect(() => {
		if (isOpen) {
			fetchCatalogueVerificationList();
		}
	}, [
		isOpen,
		limit,
		offset,
		catalogueVerificationList?.data?.selectedTab,
		catalogueVerificationList?.data?.location,
		appliedFilters
	]);

	useEffect(() => {
		if (isOpen) {
			fetchBizPlatforms();
			if (isMultibrandEnabled) {
				fetchBrands("", true);
			}
		}
	}, [isOpen]);

	const handleClose = () => {
		if (isNested) {
			closeNestedContainer();
			return;
		}
		close();
	};

	const fetchLocations = async (searchText, brandId = null) => {
		if (searchText !== undefined) {
			setLocationsList({
				...locationsList,
				isLoading: true
			});
			try {
				const variables = {
					limit: 50,
					sort: {
						field: "name",
						order: "ASC"
					}
				};
				if (brandId) {
					variables.brand = String(brandId);
				} else {
					variables.brand = null;
				}
				let filters = [
					{
						field: "is_active",
						value: "true"
					}
				];
				if (searchText) {
					filters.push({ field: "name", value: searchText });
				}
				variables.filters = filters;
				const resp = await client.query({
					query: GET_STORES_LIST,
					variables
				});
				setLocationsList({
					isLoading: false,
					objects: resp.data.stores.objects
				});
				return resp.data.stores.objects;
			} catch (error) {
				console.log(error);
				setLocationsList({
					...locationsList,
					isLoading: false
				});
			}
		}
	};

	const fetchLocationsDebounced = debounce((searchText, brandId) => fetchLocations(searchText, brandId), 300);

	useEffect(() => {
		fetchLocations("", isMultibrandEnabled ? catalogueVerificationList?.data?.location?.brand?.id || null : null);
	}, []);

	const updatecatalogueVerificationListState = (payload) => {
		store.dispatch({
			type: ActionTypes.CATALOGUE_VERIFICATION_LIST_STATE_CHANGE,
			payload
		});
	};

	const setFilter = (field, value) => {
		let updatedCurrentFilters = {
			...currentFilters
		};
		updatedCurrentFilters[field] = value;
		updatecatalogueVerificationListState({
			currentFilters: updatedCurrentFilters,
			appliedFilters: updatedCurrentFilters,
			offset: 0
		});
	};

	const applySearchFilter = debounce(() => fetchCatalogueVerificationList(), 500);

	const setSearchFilter = (field, value) => {
		store.dispatch({
			type: ActionTypes.CATALOGUE_VERIFICATION_LIST_SEARCH,
			payload: { [field]: value }
		});
		applySearchFilter();
	};

	const handleFormTab = async (tab) => {
		if (catalogueVerificationList.data.selectedTab !== tab.value) {
			store.dispatch({
				type: ActionTypes.CATALOGUE_VERIFICATION_LIST_SEARCH,
				payload: { selectedTab: tab.value }
			});
			setFilter("error_status", tab.value);
		}
		// scroll to top of the list
		if (tableRef) {
			scroll({ top: tableRef?.offsetTop - 57, left: 0 });
		}
	};

	const handleViewItem = (toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			fetchCatalogueVerificationList();
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	};

	const handlePagination = (page) => {
		// set new offset
		const offset = (page - 1) * limit;
		updatecatalogueVerificationListState({
			offset
		}); // scroll to top of the list
		if (tableRef) {
			scroll({ top: tableRef?.offsetTop - 57, left: 0 });
		}
	};

	const handlePageSize = async (field, size) => {
		// set new limit
		if (size && size?.value !== limit) {
			updatecatalogueVerificationListState({
				[field]: size.value
			});
		}
		// scroll to top of the list
		if (tableRef) {
			scroll({ top: tableRef?.offsetTop - 57, left: 0 });
		}
	};

	const sortList = (field) => {
		store.dispatch({
			type: ActionTypes.CATALOGUE_VERIFICATION_LIST_STATE_CHANGE_SORT,
			payload: {
				sort: { field }
			}
		});
		updatecatalogueVerificationListState({
			offset: 0
		});
	};

	const handleReadMore = (id, state = false) => {
		setExpandedDesc({
			...expandedDesc,
			[id]: state
		});
	};

	const handleBrand = async (brand) => {
		const { location } = catalogueVerificationList.data;
		const locationsList = await fetchLocations("", brand?.id || null);
		const selectedLocation = locationsList?.length > 0 ? locationsList[0] : { id: null, name: "" };
		store.dispatch({
			type: ActionTypes.CATALOGUE_VERIFICATION_LIST_SEARCH,
			payload: {
				location: {
					...location,
					...selectedLocation,
					brand,
					publish: ""
				},
				offset: 0
			}
		});
	};

	const handleLocation = (selectedLocation) => {
		const { location } = catalogueVerificationList.data;
		store.dispatch({
			type: ActionTypes.CATALOGUE_VERIFICATION_LIST_SEARCH,
			payload: {
				location: {
					...location,
					...selectedLocation,
					publish: ""
				},
				offset: 0
			}
		});
	};

	const handlePlatform = (platform) => {
		const { location } = catalogueVerificationList.data;
		store.dispatch({
			type: ActionTypes.CATALOGUE_VERIFICATION_LIST_SEARCH,
			payload: {
				location: {
					...location,
					platform,
					publish: ""
				},
				platform,
				offset: 0
			}
		});
	};

	const renderTab = (tab) => {
		return (
			<React.Fragment>
				<div className={"count " + tab.value}>{tab.count}</div>
				<div className="text">{tab.label}</div>
			</React.Fragment>
		);
	};

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 85 ? brand.name.slice(0, 85) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	const renderCustomPlaceholder = () => {
		return (
			<React.Fragment>
				<img src="/assets/icons/catalogue-verification.svg" />
				<div className="placeholder-text">
					Catalogue verification successful. {`No ${catalogueVerificationList.data.selectedTab}s found.`} You
					are all set!
				</div>
			</React.Fragment>
		);
	};

	const filterOptions = catalogueVerificationList.data.filters.filter(
		(f) => !f.hide && f.field !== "error_status" && f.field !== "only_platform_messages"
	);

	return (
		<div className="catalogue-verification-container">
			<FormSidebar
				isOpen={isOpen}
				loading={loading}
				title="Catalogue Verification"
				subTitle="Make your catalogue compliant with platform rules to avoid publish failures"
				close={handleClose}
				hideActions={true}
				isNested={isNested}
			>
				<div className={"wrapper" + (isPrismEnabled ? " prism-enabled" : "")}>
					{isPrismEnabled && (
						<div className="info-text">
							Displaying{" "}
							<span>{capitaliseText(catalogueVerificationList?.data?.platform?.platformName)}</span>{" "}
							catalogue verification issues, for{" "}
							<span>{catalogueVerificationList?.data?.location?.name || "location"}</span>
							{catalogueVerificationList?.data?.location?.publish ? (
								<React.Fragment>
									{" "}
									for {catalogueVerificationList?.data?.action || "publish"} requested on{" "}
									<span>
										{formatDate(catalogueVerificationList?.data?.location?.publish, "DD MMM, YYYY")}
									</span>{" "}
									at{" "}
									<span>
										{formatDate(catalogueVerificationList?.data?.location?.publish, "hh:mm A")}
									</span>
									.
								</React.Fragment>
							) : (
								"."
							)}
						</div>
					)}
					<Topbar
						tabs={catalogueVerificationList.data.formTabs}
						selectedTab={catalogueVerificationList.data.selectedTab}
						switchTab={(tab) => handleFormTab(tab)}
						renderTab={renderTab}
						isStickyOnTop={true}
						classes="custom-topbar"
					/>
					{(isMultibrandEnabled || isPrismEnabled) && (
						<div className="custom-filters-container">
							{isMultibrandEnabled && (
								<SelectFilterCustom
									title="Brand"
									options={brands.items.filter((brand) => brand.id !== "all")}
									isLoading={brands.isLoading}
									field="brands"
									currValue={
										catalogueVerificationList?.data?.location?.brand?.id
											? catalogueVerificationList?.data?.location?.brand
											: null
									}
									setFilter={(f, value) => handleBrand(value)}
									labelKey={"name"}
									valueKey={"id"}
									ellipsizedLength={40}
									isSearchable={false}
									customLabel={true}
									customOptions={true}
									isClearable={true}
									renderLabel={handleBrandsLabelOption}
									renderOptions={handleBrandsLabelOption}
									placeholder="Select brand"
									classes="brands"
								/>
							)}
							{isPrismEnabled && (
								<SelectFilter
									title="Location"
									options={locationsList?.objects}
									isLoading={locationsList?.isLoading}
									field="location"
									currValue={catalogueVerificationList?.data?.location || null}
									setFilter={(f, value) => handleLocation(value)}
									labelKey="name"
									valueKey="id"
									isClearable={false}
									isAsync={true}
									handleSearch={(search) =>
										fetchLocationsDebounced(
											search,
											catalogueVerificationList?.data?.location?.brand?.id || null
										)
									}
									placeholder="Select location"
								/>
							)}
						</div>
					)}
					<div className="filters-container">
						{isPrismEnabled && (
							<SelectFilter
								title="Platform"
								options={bizPlatforms?.items}
								isLoading={bizPlatforms?.isLoading}
								field="platform"
								currValue={catalogueVerificationList?.data?.platform || null}
								setFilter={(f, value) => handlePlatform(value)}
								labelKey="platformName"
								valueKey="id"
								isSearchable={true}
								isClearable={false}
								placeholder="Select Platform"
							/>
						)}
						{filterOptions.map((opt, i) => (
							<SelectFilter
								key={i}
								title={opt.valueForDisplay === "Channel" ? "Platform" : opt.valueForDisplay}
								options={opt.values || []}
								field={opt.field}
								currValue={currentFilters[opt.field] || null}
								setFilter={setFilter}
								isLoading={opt.isLoading}
								isAsync={opt.isAsync}
								handleSearch={opt.handleAsyncSearch}
								labelKey={opt.labelKey || "valueForDisplay"}
								valueKey={opt.valueKey || "value"}
							/>
						))}
						<SearchFilter
							title="Search"
							filterOption={{ field: "searchFieldValue" }}
							value={catalogueVerificationList.data.searchFieldValue}
							setFilter={setSearchFilter}
							placeholder="Search"
						/>
					</div>
					<CustomTable
						loading={catalogueVerificationList.loading}
						data={catalogueVerificationList.data.objects || []}
						columns={columns}
						sortList={sortList}
						sortedField={sortedField}
						handleNestedEntity={handleViewItem}
						expandedDesc={expandedDesc}
						handleReadMore={handleReadMore}
						customPlaceholder={
							appliedFilters["channel"] &&
							!appliedFilters["entity_type"]?.value &&
							!appliedFilters["only_platform_messages"]
								? !catalogueVerificationList.data.errorCount &&
									!catalogueVerificationList.data.warningCount
									? renderCustomPlaceholder()
									: ""
								: catalogueVerificationList.data.status !== "warning" &&
									  catalogueVerificationList.data.status !== "error"
									? renderCustomPlaceholder()
									: ""
						}
						classes="catalogue-verification-list-table-container"
						placeholderClasses="placeholder-img"
						content={
							catalogueVerificationList.data.selectedTab === "error"
								? "Catalogue errors"
								: "Catalogue warnings"
						}
						hideColumns={isPrismEnabled ? ["platform"] : []}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={catalogueVerificationList.data.count || 0}
						goToPage={handlePagination}
						setPageSize={handlePageSize}
					/>
				</div>
				<NestedEntityContainer
					show={nestedEntity.show}
					type={nestedEntity.type}
					id={nestedEntity.id}
					closeNestedContainer={() => handleViewItem(false)}
					nestedRef={nestedRef}
					isNested={true}
					// isForeignSource={true}
				/>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	catalogueVerificationList: store.catalogueVerificationList,
	catalogueVerificationListState: store.catalogueVerificationListState,
	isPrismEnabled: store.login.loggedInbizDetail.modulesEnabled.includes("PRISM"),
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	brands: store.configItems.brands,
	bizPlatforms: store.configItems.bizPlatforms
}))(CatalogueVerification);

import React, { useEffect, useRef, useState } from "react";

// third party
import { connect } from "react-redux";

// components
import { FormSidebar } from "../../_commons/FormSidebar";
import FormTable from "../../_commons/FormTable";
import { Loading } from "../../_commons/Loading";
import { Paginator } from "../../_commons/Paginator";
import { SearchFilter } from "../../_commons/SearchFilter";
import { SelectFilter } from "../../_commons/SelectFilter";
import { ProgressBar } from "../../_commons/ProgressBar";

// client
import { client } from "../../../client";
import { store } from "../../../store/configureStore";

// graphql
import { ASSOCIATE_BRAND_LOCATIONS, GET_NON_ASSOCIATED_BRAND_LOCATIONS } from "../../../graphql/brands";

// services
import NotificationServices from "../../../services/NotificationService";

// constant
const ASSOCIATION_STATUS_URL = process.env.REACT_APP_LOCATION_CREATE_WIZARD_URL;

const BrandLocationAssociations = ({
	isOpen,
	close,
	brandId,
	brandName,
	image,
	brandsCreationLocationAssociationState
}) => {
	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			render: (data) => data
		},
		{
			title: "city",
			dataIndex: "city",
			render: (data) => data
		}
	];

	const [data, setData] = useState({});
	const [offset, setOffset] = useState(0);
	const [selectedLocations, setSelectedLocations] = useState({});
	const [isAllFieldSelected, setIsAllFieldSelected] = useState(false);
	const [locationName, setLocationName] = useState("");
	const [loading, setLoading] = useState(false);
	const [currentFilters, setCurrentFilters] = useState({});
	const [isAssociatingLocations, setIsAssociatingLocations] = useState(false);
	const { currentAssociatedLocationsCount, status, failedLocationsCount } = brandsCreationLocationAssociationState;

	const [successfullyAssociatedLocationsCount, setSuccessfullyAssociatedLocationsCount] = useState(0);
	const showedLocationAssociationNotificationRef = useRef();

	const debounceRef = useRef();
	const exponentialBackOffTimeoutRef = useRef();
	const maxTimeoutLimit = 128;
	let timeout = 1;

	const delayedStatusCheckApiCallRef = useRef();

	const fetchNonAssociatedLocations = async (brandId) => {
		try {
			setLoading(true);
			setIsAllFieldSelected(false);
			const variables = {};
			variables.brandId = brandId;
			variables.limit = 10;
			variables.offset = offset;
			variables.filters = [
				{
					field: "is_active",
					value: "true"
				}
			];
			variables.sort = { field: "name", order: "ASC" };
			variables.search = [
				{
					key: "default",
					value: locationName
				}
			];
			const appliedFilters = Object.keys(currentFilters);
			if (appliedFilters.length > 0) {
				appliedFilters.forEach((filter) => {
					variables.filters.push({
						field: filter,
						value: currentFilters[filter].value
					});
				});
			}
			const resp = await client.query({
				query: GET_NON_ASSOCIATED_BRAND_LOCATIONS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.nonAssociatedBrandLocations) {
				setData(resp.data.nonAssociatedBrandLocations);
				let allSelected = true;
				const data = resp.data?.nonAssociatedBrandLocations?.objects ?? [];
				for (let i = 0; i < data.length; i++) {
					if (!selectedLocations[data[i].id]) {
						allSelected = false;
					}
				}
				setIsAllFieldSelected(allSelected);
			}
			setLoading(false);
		} catch (e) {
			setLoading(false);
			console.log(e);
		}
	};

	const handlePagination = (page) => {
		setOffset((page - 1) * 10);
	};

	const handleSingleFieldSelection = (id) => {
		const updatedSelectedLocations = { ...selectedLocations };
		if (updatedSelectedLocations[id]) {
			delete updatedSelectedLocations[id];
			if (isAllFieldSelected) {
				setIsAllFieldSelected(false);
			}
		} else {
			updatedSelectedLocations[id] = true;
		}
		setSelectedLocations({ ...updatedSelectedLocations });
	};

	const handleAllFieldsSelection = () => {
		const updatedSelectedLocations = {
			...selectedLocations
		};
		if (isAllFieldSelected) {
			if (data.objects) {
				data.objects.forEach((loc) => {
					if (updatedSelectedLocations[loc.id]) {
						delete updatedSelectedLocations[loc.id];
					}
				});
			}
			setIsAllFieldSelected(false);
		} else {
			if (data.objects) {
				data.objects.forEach((loc) => {
					updatedSelectedLocations[loc.id] = true;
				});
			}
			setIsAllFieldSelected(true);
		}
		setSelectedLocations({ ...updatedSelectedLocations });
	};

	const fetchAsyncLocationsAssociationStatus = async (referenceId) => {
		try {
			const url = `${ASSOCIATION_STATUS_URL}/?reference_id=${referenceId}&brand=True`;
			const token = store.getState().login.loginDetail.token;
			const resp = await fetch(url, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});

			const jsonifiedResp = await resp.json();

			if (jsonifiedResp?.status === "SUCCESS") {
				const locationsCount = Object.keys(selectedLocations).length;
				setSuccessfullyAssociatedLocationsCount(locationsCount);
				setTimeout(() => {
					if (locationsCount > 0) {
						if (!!showedLocationAssociationNotificationRef.current) {
							return;
						}
						showedLocationAssociationNotificationRef.current = true;
						if (jsonifiedResp?.locations_data?.length === locationsCount) {
							NotificationServices.pushNotification({
								message: locationsCount + " location(s) has been associated",
								timeout: 5000,
								type: "success",
								isClosable: true,
								theme: "dark"
							});
						} else {
							NotificationServices.pushNotification({
								message:
									"Only " +
									(jsonifiedResp?.locations_data ?? []).length +
									" location(s) has been associated",
								timeout: 5000,
								type: "warning",
								isClosable: true,
								theme: "dark"
							});
						}
					}
					fetchNonAssociatedLocations(brandId);
					setSelectedLocations({});
					close(false);
					setIsAssociatingLocations(false);
				}, 1000);
			} else if (jsonifiedResp?.status === "PROCESSING" || jsonifiedResp === "QUEUED") {
				if (Array.isArray(jsonifiedResp?.locations_data)) {
					setSuccessfullyAssociatedLocationsCount(jsonifiedResp.locations_data.length);
				}
				if (timeout > maxTimeoutLimit) {
					const cancelReqResp = await fetch(url, {
						headers: {
							Authorization: `Bearer ${token}`
						},
						body: JSON.stringify({
							reference_id: referenceId,
							action: "CANCEL"
						})
					});

					const cancelReqRespJson = cancelReqResp.json();
					if (cancelReqResp.status === "SUCCESS") {
						if (!!showedLocationAssociationNotificationRef.current) {
							return;
						}
						const locationsCount = Object.keys(selectedLocations).length;
						setSuccessfullyAssociatedLocationsCount(locationsCount);
						setTimeout(() => {
							if (locationsCount > 0) {
								showedLocationAssociationNotificationRef.current = true;
								if (cancelReqRespJson?.locations_data?.length === locationsCount) {
									NotificationServices.pushNotification({
										message: locationsCount + " location(s) has been associated",
										timeout: 5000,
										type: "success",
										isClosable: true,
										theme: "dark"
									});
								} else {
									NotificationServices.pushNotification({
										message:
											"Only " +
											(cancelReqRespJson?.locations_data ?? []).length +
											" location(s) has been associated",
										timeout: 5000,
										type: "warning",
										isClosable: true,
										theme: "dark"
									});
								}
							}
							fetchNonAssociatedLocations(brandId);
							setSelectedLocations({});
							close(false);
							setIsAssociatingLocations(false);
						}, 1000);
					} else if (cancelReqRespJson.status === "PROCESSING") {
						if (Array.isArray(cancelReqRespJson?.locations_data)) {
							setSuccessfullyAssociatedLocationsCount(cancelReqRespJson.locations_data.length);
						}
						timeout = 1;
						exponentialBackOffTimeoutRef.current = setTimeout(() => {
							fetchAsyncLocationsAssociationStatus(referenceId);
						}, timeout * 1000);
					} else {
						NotificationServices.pushNotification({
							message: "Failed to associate locations",
							timeout: 5000,
							type: "success",
							isClosable: true,
							theme: "dark"
						});
						close(false);
					}
					return;
				}
				exponentialBackOffTimeoutRef.current = setTimeout(() => {
					fetchAsyncLocationsAssociationStatus(referenceId);
				}, timeout * 1000);
				timeout *= 2;
			} else {
				showedLocationAssociationNotificationRef.current = true;
				NotificationServices.pushNotification({
					message: "Failed to associate locations",
					timeout: 5000,
					type: "failure",
					isClosable: true,
					theme: "dark"
				});
				close(false);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const handleFormSubmit = async () => {
		try {
			setLoading(true);
			const variables = {};
			variables.locationIds = Object.keys(selectedLocations).map((id) => parseInt(id));
			variables.id = brandId;
			variables.name = brandName;
			variables.image = image;
			const resp = await client.mutate({
				mutation: ASSOCIATE_BRAND_LOCATIONS,
				variables
			});
			if (resp?.data?.saveBrand?.status?.success) {
				showedLocationAssociationNotificationRef.current = false;
				setIsAssociatingLocations(true);
				if (resp?.data?.saveBrand?.referenceId) {
					delayedStatusCheckApiCallRef.current = setTimeout(() => {
						if (!status) {
							fetchAsyncLocationsAssociationStatus(resp?.data?.saveBrand?.referenceId);
						}
					}, 30000);
				}
			}
			setLoading(false);
		} catch (e) {
			setLoading(false);
			console.log(e);
		}
	};

	const setFilter = (field, value) => {
		if (field === "name") {
			setLocationName(value ? value : "");
			return;
		}
		const updatedCurrentFilters = {
			...currentFilters
		};

		if (!value && updatedCurrentFilters[field]) {
			delete updatedCurrentFilters[field];
		} else {
			updatedCurrentFilters[field] = value;
		}
		setCurrentFilters(updatedCurrentFilters);
	};

	const handleClose = () => {
		if (!isAssociatingLocations) {
			close(false);
		}
	};

	const handleLocationAssociationProgress = () => {
		if (status === "PROCESSED" && !!Object.keys(selectedLocations).length) {
			if (!!showedLocationAssociationNotificationRef.current) {
				return;
			}
			if (exponentialBackOffTimeoutRef.current) {
				clearTimeout(exponentialBackOffTimeoutRef.current);
			}
			setTimeout(() => {
				const locationsCount = Object.keys(selectedLocations).length;
				if (locationsCount > 0) {
					if (!!failedLocationsCount) {
						showedLocationAssociationNotificationRef.current = true;
						NotificationServices.pushNotification({
							message:
								failedLocationsCount === locationsCount
									? "Failed to associate locations"
									: "Only " +
										(locationsCount - failedLocationsCount) +
										"/" +
										locationsCount +
										" location(s) has been associated",
							timeout: 5000,
							type: failedLocationsCount === locationsCount ? "error" : "warning",
							isClosable: true,
							theme: "dark"
						});
					} else {
						showedLocationAssociationNotificationRef.current = true;
						NotificationServices.pushNotification({
							message: locationsCount + " location(s) has been associated",
							timeout: 5000,
							type: "success",
							isClosable: true,
							theme: "dark"
						});
					}
				}
				fetchNonAssociatedLocations(brandId);
				setSelectedLocations({});
				close(false);
				setIsAssociatingLocations(false);
			}, 500);
		}
	};

	const clearTimers = () => {
		if (delayedStatusCheckApiCallRef.current) {
			clearTimeout(delayedStatusCheckApiCallRef.current);
		}
		if (exponentialBackOffTimeoutRef.current) {
			clearTimeout(exponentialBackOffTimeoutRef.current);
		}
	};

	useEffect(handleLocationAssociationProgress, [status]);

	useEffect(() => {
		if (isOpen) {
			fetchNonAssociatedLocations(brandId);
		}
	}, [offset, isOpen, currentFilters]);

	useEffect(() => {
		if (isOpen) {
			if (debounceRef.current) {
				clearTimeout(debounceRef.current);
			}
			debounceRef.current = setTimeout(() => fetchNonAssociatedLocations(brandId), 500);
		}
	}, [locationName]);

	useEffect(() => {
		return () => clearTimers();
	}, []);

	return (
		<FormSidebar
			isOpen={isOpen}
			isNested
			close={handleClose}
			hideActions={Object.keys(selectedLocations).length === 0 || isAssociatingLocations}
			title="Add Locations"
			subTitle="Manage locations where this brand is sold"
			submitTitle="Save"
			submit={handleFormSubmit}
			loading={loading}
		>
			{isAssociatingLocations ? (
				<div className="brand-loc-association--progress">
					<div>Adding locations</div>
					<ProgressBar
						maximum={Object.keys(selectedLocations).length}
						currProgress={
							successfullyAssociatedLocationsCount > (currentAssociatedLocationsCount || 0)
								? successfullyAssociatedLocationsCount
								: currentAssociatedLocationsCount || 0
						}
						animated
					/>
				</div>
			) : (
				<div className="brand-loc-association">
					<Filters
						locationName={locationName}
						setLocationName={setLocationName}
						setFilter={setFilter}
						filters={data?.filters}
						currentFilters={currentFilters}
					/>
					<FormTable
						columns={columns}
						dataSource={data?.objects || []}
						isSelectionEnabled
						handleSingleFieldSelection={handleSingleFieldSelection}
						handleAllFieldsSelection={handleAllFieldsSelection}
						selectedFields={selectedLocations}
						isAllFieldSelected={isAllFieldSelected}
						isLoading={loading}
						showShimmerLoader={false}
					/>
					<Paginator limit={10} offset={offset} count={data?.count || 0} goToPage={handlePagination} />
				</div>
			)}
		</FormSidebar>
	);
};
const mapStateToProps = (store) => ({
	brandsCreationLocationAssociationState: store.brandsCreationLocationAssociationState
});
export default connect(mapStateToProps)(BrandLocationAssociations);

const Filters = ({ locationName, setFilter, filters, currentFilters }) => {
	const cityFilter = filters?.filter((filter) => filter.field === "city");
	const tagFilter = filters?.filter((filter) => filter.field === "tags");
	return (
		<div className="brand-loc-association--filters">
			<div className="section-1">
				<SelectFilter
					placeholder="City"
					options={cityFilter?.[0]?.values}
					labelKey="valueForDisplay"
					valueKey="value"
					setFilter={setFilter}
					currValue={currentFilters?.city}
					field="city"
				/>
				<SelectFilter
					placeholder="Tags"
					options={tagFilter?.[0]?.values}
					labelKey="valueForDisplay"
					valueKey="value"
					setFilter={setFilter}
					currValue={currentFilters?.tags}
				/>
			</div>
			<div className="section-2">
				<SearchFilter
					placeholder="Search"
					filterOption={{ field: "name" }}
					setFilter={setFilter}
					value={locationName}
				/>
			</div>
		</div>
	);
};

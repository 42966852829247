import React, { useState, useRef } from "react";

// third party
import { HorizonBanner, TextVariant, HorizonCarousel } from "@urbanpiper-engineering/horizon";
import moment from "moment";
import history from "../../history";

// components
import { ButtonIcon } from "./ButtonIcon";

// store
import { store } from "../../store/configureStore";

// utils
import { lS, getLoggedInBizId, trackEvent } from "../../atlas-utils";

// actions
import { ActionTypes } from "../../actions/_types";

// constants
const iconColors = {
	default: "#8A8A8A",
	info: "#8A8A8A",
	neutral: "#363636",
	dark: "#EAEAEA",
	warning: "#8A8A8A",
	error: "#8A8A8A",
	success: "#8A8A8A",
	rewind: "#FFFFFF"
};
const bannerStatusColorMap = {
	info: "#3B5FEB",
	warning: "#E5B917",
	error: "#D64949",
	success: "#0DA125"
};

const TopBanner = ({ showCustomBannerMessage = false, banners = [] }) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const carouselRef = useRef();

	const handleOpenUrl = (banner, target = "_self", eventType) => {
		if (banner.url) {
			if (banner?.trackEvent && banner?.topic && eventType) {
				const eventName = "top_banner";
				const eventMeta = {
					topic: banner?.topic,
					type: eventType
				};
				trackEvent(eventName, eventMeta);
			}
			window.open(banner.url, target).focus();
		}
	};

	const handleCloseBanner = (banner) => {
		const updateBanners = banners.map((b) => {
			if (b.topic === banner.topic) {
				return { ...b, isDismissed: true, dismissedAt: moment() };
			}
			return b;
		});
		store.dispatch({
			type: ActionTypes.UPDATE_BANNER_STATE,
			payload: updateBanners
		});
		lS.set("banner", {
			...(lS.get("banner") ?? {}),
			[getLoggedInBizId()]: updateBanners
		});
	};

	const handleModal = (banner, eventType) => {
		if (!isModalOpen && banner?.trackEvent && banner?.topic && eventType) {
			const eventName = "top_banner";
			const eventMeta = {
				topic: banner?.topic,
				type: eventType
			};
			trackEvent(eventName, eventMeta);
		}
		setModalOpen(!isModalOpen);
	};

	const handleLink = (link, banner) => {
		if (link.type === "open-url") {
			handleOpenUrl(banner, link.target, link.eventType);
			return;
		}
		if (link.type === "page-redirect") {
			history.push(link.url);

			// track event
			if (link.trackEvent && link.eventName) {
				trackEvent(link.eventName, link.eventMeta);
			}
			return;
		}
		if (link.type === "open-modal") {
			handleModal(banner, link.eventType);
			return;
		}
		if (link.type === "action" && link.actionType) {
			store.dispatch({
				type: link.actionType,
				payload: link.payload
			});

			// track event
			if (link.trackEvent && link.eventName) {
				trackEvent(link.eventName, link.eventMeta);
			}
			return;
		}
	};

	const getButtonColor = (banner) => {
		if (banner.classes === "neutral") {
			return "#2f58f2";
		}
		// return iconColors[banner.classes];
		return "#363636";
	};

	const handleHorizonBannerText = (banner) => {
		return (
			<span className="banner-text">
				<span>{banner?.message || ""}</span>
				{banner?.links?.map((link, i) => (
					<span key={i} className="action" onClick={() => handleLink(link, banner)}>
						{link.text}
					</span>
				))}
			</span>
		);
	};

	const onPrev = () => {
		if (carouselRef?.current) {
			carouselRef.current.prev();
		}
	};

	const onNext = () => {
		if (carouselRef?.current) {
			carouselRef.current.next();
		}
	};

	const filteredBanners = banners.filter((b) => !b.isDismissed);

	if (!showCustomBannerMessage) {
		return null;
	}
	if (filteredBanners.length === 1) {
		return (
			<div className="atlas-top-banner">
				<Banner
					b={filteredBanners?.[0]}
					handleCloseBanner={handleCloseBanner}
					handleLink={handleLink}
					getButtonColor={getButtonColor}
					handleHorizonBannerText={handleHorizonBannerText}
				/>
			</div>
		);
	}
	if (filteredBanners.length > 1) {
		return (
			<div className="atlas-top-banner">
				<div className="arrow left" onClick={onPrev}>
					<ButtonIcon icon="previous" color="#7a7a7a" />
				</div>
				<HorizonCarousel carouselRef={carouselRef} autoplay infinite dots={false} autoplaySpeed={6000}>
					{filteredBanners.map((b, i) => (
						<Banner
							b={b}
							key={i}
							handleCloseBanner={handleCloseBanner}
							handleLink={handleLink}
							getButtonColor={getButtonColor}
							handleHorizonBannerText={handleHorizonBannerText}
						/>
					))}
				</HorizonCarousel>
				<div className="arrow right" onClick={onNext}>
					<ButtonIcon icon="next" color="#7a7a7a" />
				</div>
			</div>
		);
	}
	return null;
};
export default TopBanner;

const Banner = ({ b, key, handleCloseBanner, handleLink, getButtonColor, handleHorizonBannerText }) => {
	if (!b) {
		return null;
	}
	return (
		<>
			{b.use === "horizon" ? (
				<HorizonBanner
					key={key}
					className="h-full items-center !border-l-4"
					status={b?.type || "info"}
					bannerText={handleHorizonBannerText(b)}
					bannerTextVariant={TextVariant.BODY1}
					textClass="text-up-black-80"
					icon={
						b?.icon ? <ButtonIcon icon={b?.icon} color={bannerStatusColorMap[b?.type || "info"]} /> : null
					}
					onClose={b?.isDismissible ? () => handleCloseBanner(b) : undefined}
				/>
			) : (
				<div className={"banner-message " + (b.classes || "default")} key={key}>
					{b.icon && <ButtonIcon icon={b.icon} classes="icon" color={bannerStatusColorMap[b.classes]} />}
					<div className="message">
						<span
							dangerouslySetInnerHTML={{
								__html: b?.message.replace(/\*{2}(.*?)\*{2}/g, "<b>$1</b>")
							}}
						></span>
					</div>
					{b?.links?.length > 0 && (
						<div className="links">
							{b?.links?.map((link, i) => (
								<div key={i} className="link" onClick={() => handleLink(link, b)}>
									{link.text}
									{link.icon && (
										<span>
											<ButtonIcon icon={link.icon} color={getButtonColor(b)} />
										</span>
									)}
								</div>
							))}
						</div>
					)}
					{b.isDismissible && (
						<ButtonIcon
							icon="close"
							color={iconColors[b.classes]}
							clickHandler={() => handleCloseBanner(b)}
						/>
					)}
				</div>
			)}
		</>
	);
};

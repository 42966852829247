import React, { useEffect, useState } from "react";
import DownloadIcon from "../../_icons/DownloadIcon";
import Popover from "../../_commons/Popover";
import { SelectFilterCustom } from "../../_commons/SelectFilterCustom";
import { debounce } from "lodash";
import { lS } from "../../../atlas-utils";
import { store } from "../../../store/configureStore";
import { ActionTypes } from "../../../actions/_types";
import { connect } from "react-redux";
const DownloadButton = ({
	coachMark = false,
	handleClick = () => {},
	hoverBgColor = "#363636",
	fill = "#AAAAAA",
	loading = false,
	emptyData = false,
	tooltipText = "Export CSV",
	classes = "",
	options = [],
	field = "valueForDisplay",
	value = "value",
	borderLeft = false,
	analyticsFiltersState,
	login
}) => {
	const downloadBtnInitialVisit = analyticsFiltersState?.downloadBtnInitialVisit;
	const initialAnalyticsExport = lS.get("initialAnalyticsExport") || {};
	const initialInteraction = coachMark ? initialAnalyticsExport?.[login?.loggedInbizDetail?.id]?.state : true;
	const [hover, setHover] = useState(!initialInteraction);
	const [hideTootip, setHideTooltip] = useState(false);
	const toggleBg = debounce((prev) => {
		if (!initialInteraction) {
			setHover(true);
		} else {
			setHover(prev);
		}
	}, 200);
	useEffect(() => {
		if (!loading) {
			toggleBg(hover);
		}
	}, [loading]);
	const renderPopover = (a) => {
		return <div className="render-popover">{a}</div>;
	};
	useEffect(() => {
		if (!initialAnalyticsExport?.[login?.loggedInbizDetail?.id]?.visited) {
			let value = initialAnalyticsExport;
			value[login?.loggedInbizDetail?.id] = { ...(value[login?.loggedInbizDetail?.id] || {}) };
			value[login?.loggedInbizDetail?.id].visited = 1;
			lS.set("initialAnalyticsExport", value);
		} else {
			!loading && onClose();
		}
	}, []);

	const handleRenderOptions = (opt, field, handleCheck, optionUpdates, { labelKey, valueKey }) => {
		return (
			<div
				onClick={() => {
					handleClick(opt?.[valueKey]);
				}}
			>
				{opt?.[labelKey]}
			</div>
		);
	};

	const onClose = () => {
		let values = lS.get("initialAnalyticsExport") || {};
		values[login?.loggedInbizDetail?.id] = { visited: 1, state: 1 };
		lS.set("initialAnalyticsExport", values);
		store.dispatch({
			type: ActionTypes.ANALYTICS_FILTERS_STATE_CHANGE,
			payload: { downloadBtnInitialVisit: true }
		});
		toggleBg(false);
	};

	const [closeTooltip, setCloseTooltip] = useState(true);
	const [hideInfo, setHideInfo] = useState(false);
	useEffect(() => {
		if (downloadBtnInitialVisit || initialInteraction) {
			toggleBg(false);
			setHideInfo(true);
			let timeoutId = setTimeout(() => {
				setHideInfo(false);
			}, 1000);
			return () => {
				clearInterval(timeoutId);
			};
		}
	}, [downloadBtnInitialVisit, emptyData]);
	useEffect(() => {
		if (!downloadBtnInitialVisit && !emptyData) {
			setCloseTooltip(true);
		} else {
			setCloseTooltip(false);
		}
	}, [initialInteraction, downloadBtnInitialVisit]);

	return (
		<div
			className={`download-csv  ${hideInfo ? "csv-loading" : ""} ${emptyData && !downloadBtnInitialVisit ? "hide" : ""} ${borderLeft ? "download-left-border" : ""} ${options.length > 0 && !hideTootip ? "hide-tooltip" : ""} ${loading || !hover ? "csv-loading" : ""}`}
			onMouseEnter={() => toggleBg(true)}
			onMouseLeave={() => toggleBg(false)}
		>
			<Popover
				data={
					!initialInteraction && !emptyData
						? InitialDownloadAttempt
						: emptyData
							? "No data available for download"
							: tooltipText
				}
				renderPopover={renderPopover}
				open={closeTooltip}
				showOnHover={!loading && initialInteraction}
				classes={`${!initialInteraction && !emptyData ? "first-visit" : ""} ${!initialInteraction ? "" : classes}`}
				showClose={!initialInteraction}
				onClose={() => onClose()}
			>
				{!emptyData && (options || []).length > 0 ? (
					<div className={`${(!loading && hover && !emptyData) || !hideTootip ? "container-hover" : ""}`}>
						<SelectFilterCustom
							isLoading={loading}
							options={!loading ? options : []}
							labelKey={field}
							valueKey={value}
							currValue={options?.[0] || {}}
							setFilter={(f, value) => {}}
							renderLabel={() => {
								return (
									<div>
										<DownloadIcon
											styles={{ width: 16.67, height: 16.67 }}
											fill={
												emptyData && hover
													? "#FFFFFF"
													: (hover && !loading) || !hideTootip
														? hoverBgColor
														: fill
											}
										/>
									</div>
								);
							}}
							isSearchable={false}
							customLabel={true}
							customOptions
							renderOptions={handleRenderOptions}
							handleSelectFilterState={(a, b) => {
								setHideTooltip(!a);
							}}
						/>
					</div>
				) : (
					<div
						className={`${!initialInteraction && !loading && !emptyData ? "download-btn-hover background-none" : ""} ${emptyData ? "empty-data" : ""} ${loading ? "download-btn-disable" : hover && !emptyData ? "download-btn-hover" : ""} download-btn`}
						onClick={() => !loading && handleClick()}
					>
						<DownloadIcon
							styles={{ width: 16.67, height: 16.67 }}
							fill={emptyData ? "#FFFFFF" : hover && !loading ? hoverBgColor : fill}
						/>
					</div>
				)}
			</Popover>
		</div>
	);
};

const mapStateToProps = (state) => ({
	analyticsFiltersState: state?.analyticsFiltersState,
	login: state.login
});
export default connect(mapStateToProps)(DownloadButton);

const InitialDownloadAttempt = (
	<div className="initial-interaction">
		<div className="title">New! Export Your Data Effortlessly</div>
		<div className="description">Save charts as PNGs and tables as CSVs in a click!</div>
	</div>
);

import React, { useEffect, useState } from "react";

// component
import { FormSidebar } from "../_commons/FormSidebar";
import Image from "../_commons/Image";
import { CheckBox } from "../_commons/CheckBox";
import { clientMenu } from "../../client-menu";
import { CUSTOMISABLE_ITEM_DETAILS } from "../../graphql/menus";
import { FOODTYPE_MAPS } from "../../client-config";
import { connect } from "react-redux";
import { printCurrency } from "../SiteComp";
import { fixedToTwoDecimal, getTranslatedField } from "../../atlas-utils";
import FoodTypeIndicator from "../_commons/FoodTypeIndicator";
import { addItemsTestCart, createTestCart, updateItemsTestCart } from "../../actions/menus";
import { RadioButton } from "../_commons/RadioButton";

const CustomisableItemDrawer = ({
	isOpen,
	showHideCustomisableItemDrawer,
	itemId,
	menuId,
	currencySymbol,
	selectedLocation = null,
	selectedPlatform = "",
	menuTestCart,
	prevSelectedAddonsAndVariants = {},
	isNested = false,
	isMenuLocked,
	selectedLanguage
}) => {
	const isTestOrderEnabled = !isMenuLocked;
	console.log(selectedLanguage);
	const [isLoading, setIsLoading] = useState(false);
	const [itemInfo, setItemInfo] = useState({});
	const [selectedAddOnsAndVariants, setSelectedAddOnsAndVariants] = useState(prevSelectedAddonsAndVariants);

	const handleAddOrCreateCart = async () => {
		const parsedItemId = parseInt(itemId, 10);
		const platformName = selectedPlatform?.platform?.name || selectedPlatform?.platformName;
		const createItemWithOptions = (id) => ({
			id,
			quantity: 1,
			options: Object.entries(selectedAddOnsAndVariants).map(([key]) => ({
				id: parseInt(key, 10),
				quantity: 1
			}))
		});
		if (!menuTestCart?.data?.cartId) {
			const variables = {
				menuId,
				items: [createItemWithOptions(parsedItemId)],
				bizLocationId: selectedLocation?.id?.toString(),
				platform: platformName
			};
			const res = await createTestCart(variables);
			if (res?.success) {
				handleClose();
			}
		} else {
			const updatedItems = [createItemWithOptions(parsedItemId)];

			const variables = {
				cartId: menuTestCart.data.cartId,
				items: updatedItems
			};
			const res = await addItemsTestCart(variables);
			if (res?.success) {
				handleClose();
			}
		}
	};
	const handleUpdateTestCartItems = async () => {
		const parsedItemId = parseInt(itemId, 10);
		const cartId = menuTestCart?.data?.cartId;
		const cartItems = menuTestCart?.data?.cartObject?.items || [];
		const menuItem = cartItems.find((item) => item.id === parsedItemId);
		const createItemWithOptions = (id, quantity) => ({
			id,
			quantity,
			options: Object.entries(selectedAddOnsAndVariants).map(([key]) => ({
				id: parseInt(key, 10),
				quantity: 1
			}))
		});

		if (!cartId || !menuItem) return;
		let updatedItems;

		updatedItems = cartItems.map(({ id, quantity, options }) => {
			if (id == parsedItemId) {
				return createItemWithOptions(id, quantity);
			}
			return {
				id,
				options: (options || [])?.map(({ id, quantity }) => ({ id, quantity })),
				quantity
			};
		});

		const res = await updateItemsTestCart({ cartId, items: updatedItems });

		if (res?.success) {
			handleClose();
		}
	};
	const fetchItemCustomisableInfo = async () => {
		try {
			setIsLoading(true);
			const variables = {
				menuId,
				itemId,
				applyRules: true,
				locationId: selectedLocation?.id && String(selectedLocation?.id),
				platform: selectedPlatform?.platformName,
				limit: 100
			};
			const resp = await clientMenu.query({
				query: CUSTOMISABLE_ITEM_DETAILS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp?.data?.item) {
				setItemInfo(resp?.data?.item);
			}

			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
			console.log(e);
		}
	};

	const handleClose = () => {
		setSelectedAddOnsAndVariants({});
		showHideCustomisableItemDrawer(false);
	};

	const handleAddOnSelection = (id) => {
		if (selectedAddOnsAndVariants[id]) {
			setSelectedAddOnsAndVariants((current) => {
				const presentSelections = {
					...current
				};
				delete presentSelections[id];
				return presentSelections;
			});
			return;
		}
		setSelectedAddOnsAndVariants((current) => ({
			...current,
			[id]: true
		}));
	};

	useEffect(() => {
		if (isOpen && itemId) {
			fetchItemCustomisableInfo();
		}
	}, [isOpen, itemId]);

	if (isLoading) {
		return (
			<div className="customisable-item-drawer-container">
				<FormSidebar
					isOpen={isOpen}
					title="Item Details"
					subTitle="Item details shown on adding to cart"
					hideActions
					close={handleClose}
				>
					<div className="shimmer-container">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				</FormSidebar>
			</div>
		);
	}

	const { optionGroups } = itemInfo;
	const handleVariantSelection = (modifierGroupId, optionId) => {
		const updatedAddOns = { ...selectedAddOnsAndVariants };
		const modifierGroup = optionGroups?.objects?.find((optgrp) => optgrp?.id === modifierGroupId);
		const options = Array.isArray(modifierGroup?.options?.objects) ? modifierGroup.options.objects : [];
		options.forEach((option) => {
			delete updatedAddOns[option.id];
		});
		updatedAddOns[optionId] = true;
		setSelectedAddOnsAndVariants(updatedAddOns);
	};

	return (
		<div className="customisable-item-drawer-container">
			<FormSidebar
				isOpen={isOpen}
				title="Item Details"
				subTitle="Item details shown on adding to cart"
				submitTitle={Object.keys(prevSelectedAddonsAndVariants).length > 0 ? "Update Item" : "Add To Cart"}
				submit={
					Object.keys(prevSelectedAddonsAndVariants).length > 0
						? handleUpdateTestCartItems
						: handleAddOrCreateCart
				}
				close={() => showHideCustomisableItemDrawer(false)}
				submitButtonWidth={105}
				disabled={Object.keys(selectedAddOnsAndVariants).length === 0}
				hideActions={!isTestOrderEnabled}
				isNested={isNested}
			>
				<ItemBasicDetails itemInfo={itemInfo} selectedLanguage={selectedLanguage} />
				{(optionGroups?.objects ?? []).map((modifierGroup) =>
					modifierGroup?.isVariant ? (
						<VariantSelector
							name={getTranslatedField(modifierGroup, selectedLanguage?.value, "name")}
							key={modifierGroup?.id}
							modifierGroupId={modifierGroup?.id}
							options={modifierGroup?.options}
							handleVariantSelection={handleVariantSelection}
							currencySymbol={currencySymbol}
							isTestOrderEnabled={isTestOrderEnabled}
							selectedAddOnsAndVariants={selectedAddOnsAndVariants}
							selectedLanguage={selectedLanguage}
						/>
					) : (
						<AddOnSelector
							name={getTranslatedField(modifierGroup, selectedLanguage?.value, "name")}
							key={modifierGroup?.id}
							options={modifierGroup?.options}
							maxSelectable={modifierGroup?.maxSelectable}
							currencySymbol={currencySymbol}
							handleAddOnSelection={handleAddOnSelection}
							selectedAddOnsAndVariants={selectedAddOnsAndVariants}
							isTestOrderEnabled={isTestOrderEnabled}
							selectedLanguage={selectedLanguage}
						/>
					)
				)}
			</FormSidebar>
		</div>
	);
};
const mapStateToProps = (store) => ({
	currencySymbol: store.login.loggedInbizDetail.currencySymbol,
	menuTestCart: store.menuTestCart
});
export default connect(mapStateToProps)(CustomisableItemDrawer);

const ItemBasicDetails = ({ itemInfo, selectedLanguage }) => {
	const { name, imageUrl, description, foodType } = itemInfo;
	console.log("item", selectedLanguage);
	return (
		<div className="item-basic-details">
			<div className="item-image-container">
				<Image
					src={imageUrl || "/assets/empty-dish.jpg"}
					alt={name || "item title"}
					errorOverrideImage="/assets/empty-dish.jpg"
				/>
			</div>
			<div className="item-info">
				<div className="foodtype-item-name">
					<div className={`foodtype-indicator ${FOODTYPE_MAPS[foodType]}`}></div>
					<div className="item-name">
						{getTranslatedField(itemInfo, selectedLanguage?.value, "name") || "--"}
					</div>
				</div>
				<div className="desc">
					{getTranslatedField(itemInfo, selectedLanguage?.value, "description") || "--"}
				</div>
				<div className="food-content">
					Contains:
					<br />
					--
				</div>
			</div>
		</div>
	);
};

const VariantSelector = ({
	name,
	options,
	currencySymbol,
	handleVariantSelection,
	modifierGroupId,
	isTestOrderEnabled,
	selectedAddOnsAndVariants,
	selectedLanguage
}) => {
	return (
		<>
			{isTestOrderEnabled ? (
				<div className="modifier-quantity-selector-container">
					<div className="header">
						<div className="action-header">{name || "--"}</div>
					</div>
					{(options.objects ?? []).map((option) => (
						<div
							className="radio-value-container"
							key={option?.id}
							onClick={() => handleVariantSelection(modifierGroupId, option?.id)}
						>
							<div className="radio-selector">
								<label htmlFor={option?.id}>
									<FoodTypeIndicator foodType={FOODTYPE_MAPS[option?.foodType]} />
									{getTranslatedField(option, selectedLanguage?.value, "name") || "--"}
									<span className="price">
										{printCurrency(currencySymbol)} {fixedToTwoDecimal(option?.price) || "--"}
									</span>
								</label>
							</div>

							<RadioButton
								checked={!!selectedAddOnsAndVariants[option?.id]}
								clickHandler={() => handleVariantSelection(modifierGroupId, option?.id)}
							></RadioButton>
						</div>
					))}
				</div>
			) : (
				<div className="modifier-quantity-selector-container">
					<div className="header">
						<div className="action-header">
							{name || "--"} <span className="qty-min-limit">(min. 1)</span>
						</div>
						<div className="total-modifiers-count">{options?.totalObjectCount || 0} modifiers</div>
					</div>
					{(options.objects ?? []).map((option) => (
						<div className="radio-value-container" key={option?.id}>
							<div className="radio-selector">
								{/* <input type="radio" name="modifier_qnty" value={option?.id} /> */}
								<label for="html">{option?.name || "--"}</label>
							</div>
							<div className="price">
								{printCurrency(currencySymbol)} {fixedToTwoDecimal(option?.price) || "--"}
							</div>
						</div>
					))}
				</div>
			)}
		</>
	);
};

const AddOnSelector = ({
	name,
	maxSelectable = 0,
	options,
	currencySymbol,
	handleAddOnSelection,
	selectedAddOnsAndVariants,
	isTestOrderEnabled,
	selectedLanguage
}) => {
	const [selectedAddOns, setSelectedAddOns] = useState(() => {
		const initialSelections = {};
		const validAddOnIds = new Set((options?.objects ?? []).map((option) => option?.id));

		Object.keys(selectedAddOnsAndVariants).forEach((id) => {
			if (validAddOnIds.has(id) && selectedAddOnsAndVariants[id]) {
				initialSelections[id] = true;
			}
		});

		return initialSelections;
	});

	const handleSelection = (id) => {
		handleAddOnSelection(id);
		if (selectedAddOns[id]) {
			setSelectedAddOns((current) => {
				const presentSelections = {
					...current
				};
				delete presentSelections[id];
				return presentSelections;
			});
			return;
		}
		setSelectedAddOns((current) => ({
			...current,
			[id]: true
		}));
	};

	const isAddOnDisabled = (id) => {
		if (maxSelectable === -1 || maxSelectable === 0) {
			return false;
		}

		const selectedCount = Object.keys(selectedAddOns).length;
		return selectedCount >= maxSelectable && !selectedAddOns[id];
	};

	return (
		<>
			{isTestOrderEnabled ? (
				<div className="modifier-quantity-selector-container">
					<div className="header">
						<div className="action-header">
							{name || "--"} <span className="qty-min-limit">(max. {maxSelectable})</span>
						</div>
					</div>
					{(options?.objects ?? []).map((option) => (
						<div
							key={option?.id}
							className={`radio-value-container ${isAddOnDisabled(option?.id) ? "disabled" : ""}`}
							onClick={() => handleSelection(option?.id)}
						>
							<div className="radio-selector">
								<label htmlFor={option?.id}>
									<FoodTypeIndicator foodType={FOODTYPE_MAPS[option?.foodType]} />
									{getTranslatedField(option, selectedLanguage?.value, "name") || "--"}{" "}
									<span>
										{printCurrency(currencySymbol)} {fixedToTwoDecimal(option?.price)}
									</span>
								</label>
							</div>
							<CheckBox
								clickHandler={() => handleSelection(option?.id)}
								checked={selectedAddOnsAndVariants[option?.id] || false}
								disabled={isAddOnDisabled(option?.id)}
							/>
						</div>
					))}
				</div>
			) : (
				<div className="modifier-quantity-selector-container">
					<div className="header">
						<div className="action-header">
							{name || "--"} <span className="qty-min-limit">(max. {maxSelectable})</span>
						</div>
						<div className="total-modifiers-count">{options?.totalObjectCount || 0} modifiers</div>
					</div>
					{(options?.objects ?? []).map((option) => (
						<div key={option?.id} className="radio-value-container">
							<div className="radio-selector">
								<label htmlFor="html">{option?.name || "--"}</label>
							</div>
							<div className="price">
								+ {printCurrency(currencySymbol)} {fixedToTwoDecimal(option?.price)}
							</div>
						</div>
					))}
				</div>
			)}
		</>
	);
};

import React, { useState, useEffect, useRef, useMemo } from "react";

// components
import Header from "../../components/Analytics/Common/Header";
import SectionHeader from "../../components/Analytics/Common/SectionHeader";
import ShareReport from "../../components/Analytics/Common/ShareReport";
import Filters, { BreakdownByFilter, CompareFilter } from "../../components/Analytics/Common/Filters";
import { Topbar } from "../../components/_commons/Topbar";
import MetricCard from "../../components/Analytics/Common/MetricCard";
import MetricHeader from "../../components/Analytics/Common/MetricHeader";
import MetricSection from "../../components/Analytics/Common/MetricSection";
import ChartMetric from "../../components/Analytics/Common/ChartMetric";
import MultiChartMetrics from "../../components/Analytics/Common/MultiChartMetrics";
import ActionButton from "../../components/Analytics/Common/ActionButton";
import ChartSelector from "../../components/Analytics/Common/ChartSelector";
import ShowComparison from "../../components/Analytics/Common/ShowComparison";
import { CustomTable } from "../../components/_commons/CustomTable";
import { ButtonIcon } from "../../components/_commons/ButtonIcon";
import Popover from "../../components/_commons/Popover";
import RestaurantAvailability from "../../components/Analytics/Operations/RestaurantAvailability";
import { NestedEntityContainer } from "../../components/_commons/NestedEntityContainer";
import { Share } from "../../components/Analytics/Common/Share";
import DownloadButton from "../../components/Analytics/Common/DownloadButton";

// charts
import Line from "../../components/NivoCharts/Line";
import Sankey from "../../components/NivoCharts/Sankey";
import Bar from "../../components/NivoCharts/Bar";
import Heatmap from "../../components/NivoCharts/Heatmap";

// third party
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";

// store
import { store } from "../../store/configureStore";

// utils
import { scroll, getSortOrder, trackEvent, adjustNestedContainer } from "../../atlas-utils";

// helpers
import {
	captureScreenshot,
	generateDurationText,
	getDurationPreset,
	getReadableDateFilter,
	trackPNGDownload
} from "../../helpers/analytics";

// actions
import {
	updateOrderAnalyticsState,
	updateOperationsAnalyticsState,
	fetchOperationsSummaryMetrics,
	fetchOrderCompletionTimeMetrics,
	fetchOrderCompletionTimeChart,
	fetchOrderCompletionFunnelChart,
	fetchRestaurantAvailability,
	fetchLostOrdersMetrics,
	fetchLostOrdersChart,
	fetchLostOrdersTable,
	fetchLostOrdersBreakdownChart,
	fetchLostOrdersBreakdownTable,
	getAllAppliedFilters
} from "../../actions/analytics";

// constants
import { ANALYTICS_SECTIONS, ANALYTICS_DEFAULT_COLORS, NESTED_ENTITY_TYPES } from "../../client-config";

const ENVIRONMENT_FLAG = process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG;
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const Operations = ({
	ordersAnalytics,
	operationsAnalytics,
	analyticsFiltersState,
	currencySymbol,
	isMultibrandEnabled = false,
	access = {}
}) => {
	const { search } = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);
	const [currTab, setCurrTab] = useState(
		query.get("metric") || ANALYTICS_SECTIONS["operations"]?.["tabs"]?.[0]?.value
	);
	const [isPageScrolled, setPageScrolled] = useState(document?.documentElement?.scrollTop > 60 || false);
	const [isRestaurantAvailabilityOpen, setRestaurantAvailabilityOpen] = useState(false);
	const [restaurantAvailabilityData, setRestaurantAvailabilityData] = useState({});
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const { appliedFilters } = analyticsFiltersState;
	const nestedRef = useRef();
	const refs = {
		operations_summary: useRef(),
		order_completion_time: useRef(),
		order_completion_funnel: useRef(),
		restaurant_availability: useRef(),
		lost_orders: useRef(),
		lost_orders_breakdown: useRef()
	};
	const areMultipleBrandsSelected =
		Boolean(appliedFilters?.brand_id?.find((brand) => brand === "all")) || appliedFilters?.brand_id?.length > 1;
	const areMultiplePlatformsSelected =
		Boolean(appliedFilters?.platform_names?.find((plf) => plf === "all")) ||
		appliedFilters?.platform_names?.length > 1;

	const switchTab = (tab, tabClick = false) => {
		setCurrTab(tab.value);
		window.requestAnimationFrame(() => {
			scroll(
				{
					top: refs[tab.value]?.current?.offsetTop - (document.querySelector(".with-banner") ? 290 : 230),
					left: 0
				},
				window,
				"instant"
			);
		});
		// track event
		if (tabClick) {
			trackEvent("analytics_tab_clicked", {
				module: "Operations",
				tab: tab.label
			});
		}
	};

	const handlePageScroll = () => {
		if (document.documentElement.scrollTop > 60) {
			setPageScrolled(true);
		} else {
			setPageScrolled(false);
		}
	};

	useEffect(() => {
		// if metric param exists in url, scroll to that metric
		if (query.get("metric")) {
			switchTab({ value: query.get("metric") });
		} else {
			document.documentElement.scrollTop = 0;
		}
		trackEvent("analytics_module", { module: "Operations" });
		window.addEventListener("scroll", handlePageScroll);
		return () => {
			window.removeEventListener("scroll", handlePageScroll);
		};
	}, []);

	const handleMetricScroll = () => {
		switch (currTab) {
			case "lost_orders_breakdown":
				if (isMultibrandEnabled && !areMultiplePlatformsSelected && !areMultipleBrandsSelected) {
					switchTab(ANALYTICS_SECTIONS["orders"]?.["tabs"]?.[6]);
				} else if (!isMultibrandEnabled && !areMultiplePlatformsSelected) {
					switchTab(ANALYTICS_SECTIONS["orders"]?.["tabs"]?.[6]);
				}
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		handleMetricScroll();
	}, [appliedFilters?.platform_names, appliedFilters?.brand_id]);

	const handleHeatmapCellClick = (cell, compareFilter, metric) => {
		setRestaurantAvailabilityData({
			...cell,
			compareFilter
		});
		setRestaurantAvailabilityOpen(true);
		// track event
		trackEvent("analytics_heatmap_cell_drill_down", {
			module: "Operations",
			metric
		});
	};

	const closeRestaurantAvailability = () => {
		setRestaurantAvailabilityOpen(false);
		setRestaurantAvailabilityData({});
	};

	const handleNestedEntity = (toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	};

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "analytics",
				start: "02:46"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	const generateImage = (key, title = "Revenue", subChart) => {
		const durationObject = getAllAppliedFilters()?.durationObject?.duration;
		const comparisonDuration = getAllAppliedFilters()?.durationObject?.comparisonDuration;

		let timeDuration = durationObject?.preset
			? `${getDurationPreset(durationObject?.preset)?.label}`
			: durationObject?.preset ||
				`${generateDurationText(durationObject?.custom?.startDate, durationObject?.custom?.endDate)}`;
		timeDuration += `${comparisonDuration ? ` / Compared to ${generateDurationText(comparisonDuration?.startDate, comparisonDuration?.endDate)}` : ""}`;

		if (key === "order_completion_funnel") {
			const metricSectionElement = refs[key]?.current?.querySelector(`.metric-section .nivo-chart-${subChart}`);
			if (metricSectionElement) {
				let elementRef = { current: metricSectionElement };
				captureScreenshot(elementRef, title, timeDuration).then(() => {
					trackPNGDownload(key, subChart);
				});
			}
		} else {
			captureScreenshot(refs[key], title, timeDuration).then(() => {
				trackPNGDownload(key, subChart);
			});
		}
	};

	const [isFormOpen, setIsFormOpen] = useState(false);
	const handleCancel = () => {
		setIsFormOpen(false);
	};
	const linkShareBtn = (
		<div onClick={() => setIsFormOpen(true)} className="share">
			Share
		</div>
	);

	return (
		<div className={"analytics-operations section-container-common" + (isPageScrolled ? " scrolled" : "")}>
			<Header>
				<SectionHeader
					title="Operations Analytics"
					// headerRight={<ShareReport switchToBtn={!isPageScrolled} />}
					showHelpBtn={true}
					handlePiperAcademy={handlePiperAcademy}
					isPageScrolled={isPageScrolled}
					headerRight={linkShareBtn}
				/>
				<Filters showBrands showLocations showPlatforms showDateCompare module="Operations" />
				<Topbar
					tabs={ANALYTICS_SECTIONS["operations"]["tabs"]}
					selectedTab={currTab}
					switchTab={(tab) => switchTab(tab, true)}
					hiddenTabs={
						(!isMultibrandEnabled && !areMultiplePlatformsSelected) ||
						(isMultibrandEnabled && !areMultiplePlatformsSelected && !areMultipleBrandsSelected)
							? ["lost_orders_breakdown"]
							: []
					}
				/>
			</Header>
			<Share module="operations" handleCancel={handleCancel} isOpen={isFormOpen} />

			<MetricCard
				metric="operations_summary"
				tabMetric="order_completion_time"
				cardRef={refs["operations_summary"]}
				setCurrTab={setCurrTab}
			>
				{(metric, metricCardInViewport) => (
					<MetricSection
						metric={metric}
						metricCardInViewport={metricCardInViewport}
						metricSectionApi={fetchOperationsSummaryMetrics}
						state={operationsAnalytics}
					>
						{({ metricsLoading, metrics }) => (
							<MultiChartMetrics>
								<ChartMetric
									size="large"
									loading={metricsLoading}
									label="Avg. Delivery Time"
									description="Measures the typical time taken to deliver an order after its acceptance. Helps assess the efficiency of the delivery process."
									value={
										metrics?.order_avg_delivery_time?.value
											? Math.round(metrics?.order_avg_delivery_time?.value / 60)
											: metrics?.order_avg_delivery_time?.value
									}
									percentageChange={metrics?.order_avg_delivery_time?.percentageChange}
									compareValue={
										metrics?.order_avg_delivery_time?.compareValue
											? Math.round(metrics?.order_avg_delivery_time?.compareValue / 60)
											: metrics?.order_avg_delivery_time?.compareValue
									}
									units=" min"
									invertColors={true}
								/>
								<ChartMetric
									size="large"
									loading={metricsLoading}
									label="Avg. Orders / Hour"
									description="Shows the average order volume per hour, providing insights into operational throughput."
									value={metrics?.order_avg_order_per_hour?.value}
									percentageChange={metrics?.order_avg_order_per_hour?.percentageChange}
									compareValue={metrics?.order_avg_order_per_hour?.compareValue}
								/>
								{/* <ChartMetric
									size='large'
									loading={metricsLoading}
									label="Order Failure Rate"
									description="Percentage of orders that couldn't be completed successfully. Helps gauge overall order reliability."
									value={metrics?.order_failure_rate?.value}
									percentageChange={metrics?.order_failure_rate?.percentageChange}
									compareValue={metrics?.order_failure_rate?.compareValue}
									units="%"
								/> */}
							</MultiChartMetrics>
						)}
					</MetricSection>
				)}
			</MetricCard>
			<MetricCard metric="order_completion_time" cardRef={refs["order_completion_time"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<React.Fragment>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchOrderCompletionTimeMetrics}
							state={operationsAnalytics}
						>
							{({ metricsLoading, metrics }) => (
								<MetricHeader>
									<div className="header-left">
										<ChartMetric
											size="large"
											loading={metricsLoading}
											label="Order Completion Time"
											description="Average time from order placement to completion for all successful orders. Note: The total completion time may differ from the sum of individual stages, as only orders moving through each stage sequentially are considered for the latter."
											value={
												metrics?.order_avg_completion_time?.value
													? Math.round(metrics?.order_avg_completion_time?.value / 60)
													: metrics?.order_avg_completion_time?.value
											}
											percentageChange={metrics?.order_avg_completion_time?.percentageChange}
											compareValue={
												metrics?.order_avg_completion_time?.compareValue
													? Math.round(metrics?.order_avg_completion_time?.compareValue / 60)
													: metrics?.order_avg_completion_time?.compareValue
											}
											units=" min"
											invertColors={true}
										/>
									</div>
									<div className="header-right">
										<DownloadButton
											coachMark={true}
											emptyData={
												!metricsLoading &&
												!operationsAnalytics?.order_completion_time?.chartLoading &&
												(!metrics?.order_avg_completion_time?.description ||
													(operationsAnalytics?.order_completion_time?.graphData || [])
														?.length == 0)
											}
											loading={
												metricsLoading ||
												operationsAnalytics?.order_completion_time?.chartLoading
											}
											handleClick={() =>
												generateImage("order_completion_time", "Order Completion Time", "bar")
											}
											tooltipText="Export Chart as PNG"
											classes={
												(!metrics?.order_avg_completion_time?.description ||
													(operationsAnalytics?.order_completion_time?.graphData || [])
														?.length !== 0) &&
												!metricsLoading &&
												!operationsAnalytics?.order_completion_time?.chartLoading
													? "download-csv"
													: ""
											}
										/>
									</div>
								</MetricHeader>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchOrderCompletionTimeChart}
							state={operationsAnalytics}
						>
							{({ chartLoading, graphData, maxValue }, applDateFilter) => (
								<Bar
									height={300}
									marginLeft={120}
									data={graphData}
									metric={metric}
									loading={chartLoading}
									layout="horizontal"
									customOuterLabel={true}
									renderCustomOuterLabel={({ width, height, x, y, data }, i) => {
										return (
											<foreignObject
												key={i}
												width="320px"
												height="76px"
												transform={`translate(${(width ? x : 0) + width + 16}, ${
													y + (height - 81) / 2
												})`}
											>
												<div className="compare-percent">
													<div className="time">
														{data.value !== null
															? data.value > 1
																? `${Math.round(data.value)} min`
																: `${Math.round(data.value * 60)} sec`
															: ""}
													</div>
													<Popover
														showOnHover={true}
														renderPopover={() => (
															<div className="compare-info">
																<div className="compare-date">
																	{getReadableDateFilter(true)}
																</div>
																<div className="compare-value">
																	{data?.data?.compare !== null
																		? data?.data?.compare > 1
																			? `${Math.round(data?.data?.compare)} min`
																			: `${Math.round(
																					(data?.data?.compare || 0) * 60
																				)} sec`
																		: ""}
																</div>
															</div>
														)}
														position="middle-right"
														classes={data?.data?.compare >= 0 ? "pve" : "nve"}
													>
														<ButtonIcon
															icon={data?.data?.percent <= 0 ? "down" : "up"}
															classes="arrow"
															color={data?.data?.percent > 0 ? "#D64949" : "#0DA125"}
														/>
														<div
															className="cent-change"
															style={{
																color: data?.data?.percent > 0 ? "#D64949" : "#0DA125"
															}}
														>
															{data?.data?.percent || "0.0"}%
														</div>
													</Popover>
												</div>
											</foreignObject>
										);
									}}
									enableGridX={true}
									colors={["#2543B6"]}
									keys={["time"]}
									maxValue={maxValue}
									enableLegends={false}
									applDateFilter={applDateFilter}
									groupMode="grouped"
									indexBy="label"
									axisBottomLegend="Average Time in Minutes"
									padding={0.6}
									innerPadding={20}
								/>
							)}
						</MetricSection>
					</React.Fragment>
				)}
			</MetricCard>
			<MetricCard
				metric="order_completion_funnel"
				cardRef={refs["order_completion_funnel"]}
				setCurrTab={setCurrTab}
			>
				{(metric, metricCardInViewport) => (
					<MetricSection
						metric={metric}
						metricCardInViewport={metricCardInViewport}
						metricSectionApi={fetchOrderCompletionFunnelChart}
						state={operationsAnalytics}
					>
						{({ chartLoading, graphData, maxValue }) => (
							<React.Fragment>
								<MetricHeader>
									<div className="header-left">
										<ChartMetric
											size="large"
											label="Order Completion Funnel"
											description="A visual flow showing how orders move through different stages, highlighting potential bottlenecks or drop-off points in the order lifecycle."
										/>
									</div>
									{(access?.isSalesAnalysis || access?.isMarketing || access?.isNonHqAnalytics) && (
										<div className="header-right">
											<ActionButton
												variant="link"
												href={`/analytics/orders${
													query.get("filters") ? `?filters=${query.get("filters")}` : ""
												}`}
												clickHandler={() =>
													trackEvent("analytics_link_text_clicked", {
														module: "Operations",
														type: "Module",
														origin: metric,
														destination: "Orders"
													})
												}
												showArrow={false}
											>
												Go to Order Analytics
											</ActionButton>
											<DownloadButton
												emptyData={
													(
														operationsAnalytics?.order_completion_funnel?.graphData
															?.links || []
													).length == 0 && !chartLoading
												}
												tooltipText="Export Chart as PNG"
												loading={chartLoading}
												handleClick={() => {
													!chartLoading &&
														(
															operationsAnalytics?.order_completion_funnel?.graphData
																?.links || []
														).length !== 0 &&
														generateImage(
															"order_completion_funnel",
															"Order Completion Funnel",
															"sankey"
														);
												}}
												borderLeft={true}
											/>
										</div>
									)}
								</MetricHeader>
								<Sankey
									height={400}
									marginTop={60}
									nodeSpacing={100}
									data={graphData}
									maxValue={maxValue}
									loading={chartLoading}
									colors={(node) => node?.nodeColor}
									enableLegends={false}
									showPercentValue={true}
									labelPositions={[
										{ x: -20, y: -55, align: "left", position: "top" },
										{ x: -20, y: -55, align: "left", position: "top" },
										{ x: -20, y: -55, align: "left", position: "top" },
										{ x: -20, y: -55, align: "left", position: "top" },
										{ x: -300, y: -55, align: "right", position: "top" },
										{ x: -300, y: -55, align: "right", position: "top" },
										{ x: -300, y: -55, align: "right", position: "top" }
									]}
								/>
								<div className="chart-time-period">
									Chart Time Period: <span>{getReadableDateFilter()}</span>
								</div>
							</React.Fragment>
						)}
					</MetricSection>
				)}
			</MetricCard>
			<MetricCard
				metric="restaurant_availability"
				cardRef={refs["restaurant_availability"]}
				setCurrTab={setCurrTab}
			>
				{(metric, metricCardInViewport) => (
					<MetricSection
						metric={metric}
						metricCardInViewport={metricCardInViewport}
						metricSectionApi={fetchRestaurantAvailability}
						state={operationsAnalytics}
						dependencies={[
							operationsAnalytics[metric]?.compare?.value,
							operationsAnalytics[metric]?.showComparison
						]}
					>
						{(
							{
								loading,
								graphData,
								maxValue,
								compare,
								applCompare,
								compareOptions = [],
								compareFilterDisabled = false,
								showComparison = false,
								applShowComparison = false
							},
							applDateFilter
						) => (
							<React.Fragment>
								<MetricHeader>
									<div className="header-left">
										<ChartMetric
											size="large"
											label="Restaurant Availability"
											description="A heatmap visualization showing the restaurant's operational status. Darker shades indicate longer offline periods, assisting in identifying potential availability issues."
										/>
									</div>
								</MetricHeader>
								<div className="heatmap-filters">
									<CompareFilter
										currValue={compare}
										options={compareOptions}
										setFilter={(field, value) =>
											updateOperationsAnalyticsState(metric, {
												[field]: value
											})
										}
										module="Operations"
										metric={metric}
										readOnly={compareFilterDisabled}
									/>
									<ShowComparison
										title="Average & Compare"
										loading={loading}
										isChecked={showComparison}
										clickHandler={() =>
											updateOperationsAnalyticsState(metric, {
												showComparison: !showComparison
											})
										}
										module="Operations"
										metric={metric}
										readOnly={!applDateFilter?.compare?.dateFilter}
									/>
								</div>
								<Heatmap
									responsive={!["Hours", "Dates", "Weeks"].includes(applCompare?.label)}
									enableLegends={false}
									enableCustomLegends={true}
									canvas={true}
									width={
										(applShowComparison && graphData.length === 2) || applCompare?.label === "Dates"
											? 3000
											: 2500
									}
									height={
										graphData?.length === 1
											? 200
											: graphData?.length < 6
												? 350
												: (graphData?.length || 3) * 28 + 160
									}
									loading={loading}
									data={graphData}
									minValue={maxValue === 0 ? 0 : undefined}
									maxValue={maxValue === 0 ? 4 : undefined}
									marginLeft={
										!applShowComparison && applCompare?.label === "Days of the Week"
											? 200
											: applShowComparison &&
												  applDateFilter.current.dateTypeSelected.value === "range"
												? 150
												: applShowComparison &&
													  applDateFilter.current.dateFilter === "YESTERDAY"
													? 125
													: 100
									}
									marginRight={!["Hours", "Dates", "Weeks"].includes(applCompare?.label) ? 10 : 40}
									colors="warm"
									yInnerPadding={applShowComparison && graphData.length === 2 ? 0.25 : 0}
									enableLabels={false}
									legendTicks={["Online", "", "", "", "Offline"]}
									legendCellLength={100}
									scrollOffset={
										applCompare?.label === "Hours" ? (applShowComparison ? 1000 : 800) : 0
									}
									onClick={
										!applShowComparison
											? (cell) => handleHeatmapCellClick(cell, compare, metric)
											: undefined
									}
									infoTip={!applShowComparison ? "Click on the cell to know more" : ""}
									renderCustomTooltipLabel={() => "Offline Percentage"}
								/>
							</React.Fragment>
						)}
					</MetricSection>
				)}
			</MetricCard>
			<MetricCard metric="lost_orders" cardRef={refs["lost_orders"]} setCurrTab={setCurrTab}>
				{(metric, metricCardInViewport) => (
					<React.Fragment>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchLostOrdersMetrics}
							state={ordersAnalytics}
						>
							{(
								{
									metricsLoading,
									chartLoading,
									metrics,
									selectedChart = "bar",
									showComparison = false
								},
								applDateFilter
							) => (
								<React.Fragment>
									<MetricHeader>
										<div className="header-left">
											<ChartMetric
												size="large"
												loading={metricsLoading}
												label="Lost Orders"
												description="Cancelled orders categorisation segmented by restaurant and platform."
												value={metrics?.order_lost_orders?.value}
												percentageChange={metrics?.order_lost_orders?.percentageChange}
												compareValue={metrics?.order_lost_orders?.compareValue}
												invertColors={true}
											/>
										</div>
										<div className="header-right">
											<ChartSelector
												selectedChart={selectedChart}
												options={["bar", "line"]}
												clickHandler={(chart) => {
													updateOrderAnalyticsState(metric, {
														selectedChart: chart
													});
													trackEvent("analytics_chart_type_switched", {
														module: "Operations",
														origin: metric,
														from: selectedChart,
														to: chart
													});
												}}
											/>
										</div>
									</MetricHeader>
									<ShowComparison
										loading={chartLoading}
										isChecked={showComparison}
										clickHandler={() =>
											updateOrderAnalyticsState(metric, { showComparison: !showComparison })
										}
										module="Operations"
										metric={metric}
										readOnly={!applDateFilter?.compare?.dateFilter}
									/>
								</React.Fragment>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchLostOrdersChart}
							state={ordersAnalytics}
							dependencies={[
								ordersAnalytics[metric]?.selectedChart,
								ordersAnalytics[metric]?.showComparison
							]}
						>
							{(
								{
									chartLoading,
									selectedChart = "bar",
									showComparison = false,
									graphData,
									yScaleMax,
									maxValue
								},
								applDateFilter
							) => (
								<React.Fragment>
									{selectedChart === "bar" && (
										<Bar
											height={400}
											data={graphData?.bar}
											loading={chartLoading}
											colors={
												showComparison && applDateFilter?.compare?.dateFilter
													? ["#2543B6", "#2543B6", "#88D9F8", "#88D9F8", "#9048C8", "#9048C8"]
													: ["#2543B6", "#88D9F8", "#9048C8"]
											}
											keys={
												showComparison && applDateFilter?.compare?.dateFilter
													? [
															"Cancelled Pre Acknowledgement",
															"Cancelled Pre Acknowledgement*",
															"Cancelled Post Acknowledgement",
															"Cancelled Post Acknowledgement*"
														]
													: [
															"Cancelled Pre Acknowledgement",
															"Cancelled Post Acknowledgement"
														]
											}
											patternIds={[
												{
													match: { id: "Cancelled Pre Acknowledgement*" },
													id: "lines"
												},
												{
													match: { id: "Cancelled Post Acknowledgement*" },
													id: "lines"
												}
											]}
											maxValue={maxValue}
											enableLegends={!showComparison || !applDateFilter?.compare?.dateFilter}
											customLegends={showComparison && applDateFilter?.compare?.dateFilter}
											applDateFilter={applDateFilter}
											groupMode="grouped"
											indexBy="label"
											padding={showComparison ? 0.5 : 0.75}
											innerPadding={20}
											// axisLeftLegend="Orders"
											legendItemWidth={230}
										/>
									)}
									{selectedChart === "line" && (
										<Line
											height={400}
											data={graphData?.line}
											durationPreset={applDateFilter?.current?.dateFilter}
											showCompareInTooltip={showComparison && applDateFilter?.compare?.dateFilter}
											loading={chartLoading}
											marginLeft={70}
											// axisBottomLegend="Date"
											// axisLeftLegend="Orders"
											axisLeftLegendOffset={-60}
											yScaleMax={yScaleMax}
											yScaleStacked={false}
											axisBottomTickRotation={graphData?.line?.[0]?.data?.length > 7 ? -45 : 0}
											dashedLines={showComparison && applDateFilter?.compare?.dateFilter}
											dashedLineIds={
												applDateFilter?.compare?.dateFilter
													? [`Lost Orders (${getReadableDateFilter(true)})*`]
													: []
											}
											enableArea={true}
											colors={["#2543B6"]}
											legendItemWidth={
												showComparison && applDateFilter?.compare?.dateFilter ? 250 : 100
											}
											renderTooltipYName={(props) => "Lost Orders"}
										/>
									)}
								</React.Fragment>
							)}
						</MetricSection>
						<MetricSection
							metric={metric}
							metricCardInViewport={metricCardInViewport}
							metricSectionApi={fetchLostOrdersTable}
							state={ordersAnalytics}
							dependencies={[ordersAnalytics[metric]?.sort]}
						>
							{({ tableLoading, tabularData, sortedField, sort }) => (
								<CustomTable
									forAnalytics={true}
									loading={tableLoading}
									data={tabularData?.rows || []}
									columns={tabularData?.columns || []}
									sortList={(field) =>
										updateOrderAnalyticsState(metric, {
											sort: {
												field: field,
												order: getSortOrder(sort, { field })
											},
											sortedField: field
										})
									}
									sortedField={sortedField}
									sortedOrder={sort?.order}
									classes="lost-orders-table-container"
									content="Data"
									currencySymbol={currencySymbol}
								/>
							)}
						</MetricSection>
					</React.Fragment>
				)}
			</MetricCard>
			{((!isMultibrandEnabled && areMultiplePlatformsSelected) ||
				(isMultibrandEnabled && (areMultiplePlatformsSelected || areMultipleBrandsSelected))) && (
				<MetricCard
					metric="lost_orders_breakdown"
					cardRef={refs["lost_orders_breakdown"]}
					setCurrTab={setCurrTab}
				>
					{(metric, metricCardInViewport) => (
						<React.Fragment>
							<MetricSection
								metric={metric}
								metricCardInViewport={metricCardInViewport}
								metricSectionApi={fetchLostOrdersBreakdownChart}
								state={ordersAnalytics}
								dependencies={[
									ordersAnalytics[metric]?.selectedChart,
									ordersAnalytics[metric]?.breakdownBy?.value,
									ordersAnalytics[metric]?.showComparison
								]}
							>
								{(
									{
										chartLoading,
										selectedChart = "bar",
										showComparison = false,
										breakdownBy,
										graphData,
										yScaleMax,
										maxValue
									},
									applDateFilter
								) => (
									<React.Fragment>
										<MetricHeader>
											<div className="header-left">
												<ChartMetric
													size="large"
													label="Lost Orders Breakdown"
													description="Lost orders categorisation based on reasons for order cancellations, segmented by acknowledgment status and source."
												/>
											</div>
											<div className="header-right">
												<ChartSelector
													selectedChart={selectedChart}
													options={["bar", "line"]}
													clickHandler={(chart) => {
														updateOrderAnalyticsState(metric, {
															selectedChart: chart
														});
														trackEvent("analytics_chart_type_switched", {
															module: "Operations",
															origin: metric,
															from: selectedChart,
															to: chart
														});
													}}
												/>
												<BreakdownByFilter
													currValue={breakdownBy}
													setFilter={(field, value) => {
														updateOrderAnalyticsState(metric, {
															[field]: value
														});
														trackEvent("analytics_breakdown_selection", {
															module: "Operations",
															metric: metric,
															breakdownType: value.label
														});
													}}
													isMultibrandEnabled={isMultibrandEnabled}
													readOnly={
														!areMultiplePlatformsSelected || !areMultipleBrandsSelected
													}
												/>
											</div>
										</MetricHeader>
										<ShowComparison
											loading={chartLoading}
											isChecked={showComparison}
											clickHandler={() =>
												updateOrderAnalyticsState(metric, { showComparison: !showComparison })
											}
											module="Operations"
											metric={metric}
											readOnly={!applDateFilter?.compare?.dateFilter}
										/>
										{selectedChart === "bar" && (
											<Bar
												height={400}
												data={graphData?.bar}
												marginBottom={breakdownBy.value === "platform" ? 130 : 150}
												loading={chartLoading}
												colors={[...ANALYTICS_DEFAULT_COLORS]}
												keys={
													showComparison && applDateFilter?.compare?.dateFilter
														? ["current", "previous"]
														: [
																"Merchant (Pre-Ack)",
																"Merchant (Post-Ack)",
																"Platform (Pre-Ack)",
																"Platform (Post-Ack)"
															]
												}
												patternIds={[
													{
														match: {
															id: "previous"
														},
														id: "lines"
													}
												]}
												maxValue={maxValue}
												groupMode={
													showComparison && applDateFilter?.compare?.dateFilter
														? "grouped-stacked"
														: "stacked"
												}
												enableLegends={!showComparison || !applDateFilter?.compare?.dateFilter}
												customLegends={showComparison && applDateFilter?.compare?.dateFilter}
												padding={
													showComparison && applDateFilter?.compare?.dateFilter ? 0.6 : 0.8
												}
												innerPadding={20}
												applDateFilter={applDateFilter}
												indexBy="label"
												// axisBottomLegend={`${breakdownBy?.label}s`}
												axisBottomTickRotation={graphData?.bar?.length > 7 ? -35 : 0}
												// axisLeftLegend="Orders"
											/>
										)}
										{selectedChart === "line" && (
											<Line
												height={400}
												data={graphData?.line || []}
												durationPreset={applDateFilter?.current?.dateFilter}
												showCompareInTooltip={
													showComparison && applDateFilter?.compare?.dateFilter
												}
												// colors={[...ANALYTICS_DEFAULT_COLORS.slice(0, graphData?.line?.length)]}
												colors={{ datum: "color" }}
												loading={chartLoading}
												yScaleMax={yScaleMax}
												dashedLines={showComparison && applDateFilter?.compare?.dateFilter}
												dashedLineIndices={applDateFilter?.compare?.dateFilter ? "odd" : ""}
												showDashedLineOnHover={true}
												enableLegends={!showComparison || !applDateFilter?.compare?.dateFilter}
												axisBottomTickRotation={
													graphData?.line?.[0]?.data?.length > 7 ? -45 : 0
												}
												// axisBottomLegend="Date"
												// axisLeftLegend="Orders"
												legendItemWidth={breakdownBy.value === "platform" ? 100 : 130}
											/>
										)}
									</React.Fragment>
								)}
							</MetricSection>
							<MetricSection
								metric={metric}
								metricCardInViewport={metricCardInViewport}
								metricSectionApi={fetchLostOrdersBreakdownTable}
								state={ordersAnalytics}
								dependencies={[
									ordersAnalytics[metric]?.breakdownBy?.value,
									ordersAnalytics[metric]?.sort
								]}
							>
								{({ tableLoading, breakdownBy, tabularData, sortedField, sort, hideColumns }) => (
									<CustomTable
										forAnalytics={true}
										loading={tableLoading}
										data={tabularData?.rows || []}
										columns={tabularData?.columns || []}
										sortList={(field) =>
											updateOrderAnalyticsState(metric, {
												sort: {
													field: field,
													order: getSortOrder(sort, { field })
												},
												sortedField: field
											})
										}
										sortedField={sortedField}
										sortedOrder={sort?.order}
										classes="lost-orders-breakdown-table-container"
										content={`${breakdownBy?.label}s`}
										currencySymbol={currencySymbol}
										viewAction="View Lost Revenue Breakdown"
										viewActionVariant="link"
										viewActionHref={`/analytics/revenue?metric=lost_revenue_breakdown${
											query.get("filters") ? `&filters=${query.get("filters")}` : ""
										}`}
										handleViewAction={() =>
											trackEvent("analytics_link_text_clicked", {
												module: "Operations",
												type: "Module",
												origin: metric,
												destination: "lost_revenue_breakdown"
											})
										}
										hideColumns={hideColumns || []}
									/>
								)}
							</MetricSection>
						</React.Fragment>
					)}
				</MetricCard>
			)}
			<RestaurantAvailability
				isOpen={isRestaurantAvailabilityOpen}
				close={closeRestaurantAvailability}
				data={restaurantAvailabilityData}
			/>
			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
			/>
		</div>
	);
};
export default connect((store) => ({
	operationsAnalytics: store.operationsAnalytics,
	ordersAnalytics: store.ordersAnalytics,
	analyticsFiltersState: store.analyticsFiltersState,
	currencySymbol: store.login.loggedInbizDetail.currencySymbol,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	access: store.login.loginDetail.access
}))(Operations);

import React, { useState, useEffect, useCallback } from "react";

// components
import { CustomTable } from "../_commons/CustomTable";
import { SelectFilter } from "../_commons/SelectFilter";
import PreviewCatalogue from "./PreviewCatalogue";
import ContextMenu from "../_commons/ContextMenu";

// store
import { client } from "../../client";
import { store } from "../../store/configureStore";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";

// graphql
import { GET_STORES_LIST } from "../../graphql/misc";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchStores, fetchStoresDebounced } from "../../actions/actions";

// constants
const PREVIEW_CATALOGUE = {
	isOpen: false,
	brand: {}
};
const columns = [
	{
		name: "Name",
		field: "name",
		render: (record, i, rest) => (
			<div className="table-cell name" key={i}>
				{record.bizLocationNickname || ""}
			</div>
		)
	},
	{
		name: "Brands",
		field: "brands",
		render: (record, i, rest) => {
			if (rest.isMulti) {
				return (
					<div className="table-cell brands" key={i}>
						{record.brands.map((brand, j) => (
							<div className="brand-container" key={j}>
								<div className={"brand-initials " + brand.color}>
									{brand.image ? (
										<img src={brand.image} alt="" />
									) : (
										rest.extractInitials(brand.name?.split(" "))
									)}
								</div>
								<div className="brand-name">{brand.name || ""}</div>
							</div>
						))}
					</div>
				);
			}
			return (
				<div className="table-cell brands" title={record.name} key={i}>
					<div className={"brand-initials " + record.color}>
						{record.image ? (
							<img src={record.image} alt="" />
						) : (
							rest.extractInitials(record.name?.split(" "))
						)}
					</div>
					<div className="brand-name">{record.name || ""}</div>
				</div>
			);
		}
	},
	{
		name: "Existing Location",
		field: "location",
		render: (record, i, rest) => {
			if (rest.isMulti) {
				return (
					<div className="table-cell location" key={i}>
						{record.brands.map((brand, j) => (
							<div className="location-container" key={j}>
								<LocationDropdown
									copyCatalogue={brand.copyCatalogue || null}
									handleChange={(value, brand) => rest.handleChange(value, brand, rest.rowIndex)}
									brand={brand}
								/>
								{brand.copyCatalogue && (
									<div className="preview" onClick={() => rest.handleOpenPreviewCatalogue(brand)}>
										Preview
									</div>
								)}
								<ContextMenu
									isOpen={rest.contextMenuId === `${rest.rowIndex}${brand.id}`}
									data={brand}
									renderMenuItems={() => rest.renderMenuItems(brand, rest, rest.isMulti)}
									handleOpenMenu={() => rest.openContextMenu(brand, rest, rest.isMulti)}
									handleOutsideClick={
										rest.contextMenuId === `${rest.rowIndex}${brand.id}`
											? () => rest.closeContextMenu(brand, rest, rest.isMulti)
											: () => {}
									}
									clickEvent="mousedown"
									rest={rest}
								/>
							</div>
						))}
					</div>
				);
			}
			return (
				<div className="table-cell location" key={i}>
					<LocationDropdown
						copyCatalogue={record.copyCatalogue || null}
						handleChange={rest.handleChange}
						brand={record}
					/>
					{record.copyCatalogue && (
						<div className="preview" onClick={() => rest.handleOpenPreviewCatalogue(record)}>
							Preview
						</div>
					)}
				</div>
			);
		}
	}
];

const BrandCatalogue = ({ formData = {}, handleFormData, isMulti = false, isMultibrandEnabled = false, stores }) => {
	const [previewCatalogue, setPreviewCatalogue] = useState(PREVIEW_CATALOGUE);
	const [contextMenu, setContextMenu] = useState({
		id: null,
		data: {},
		props: {}
	});

	useEffect(() => {
		if (!isMultibrandEnabled) {
			fetchStores("", 20);
		}
	}, []);

	const extractInitials = (name) => {
		let initials = "";
		if (name.length > 1) {
			initials = name[0].charAt(0) + name[1].charAt(0);
		} else {
			initials = name[0].charAt(0) + name[0].charAt(1);
		}
		return initials.toUpperCase();
	};

	const handleChange = useCallback(
		(location, brand, index) => {
			if (isMultibrandEnabled) {
				if (isMulti) {
					const multiLocationDetails = formData.multiLocationDetails.map((loc, i) => {
						let updatedLocation = { ...loc };
						if (i === index) {
							// update brands in location
							let brands = updatedLocation.brands.map((brnd) => {
								const updatedBrand = { ...brnd };
								if (updatedBrand.id === brand.id) {
									updatedBrand.copyCatalogue = location || null;
								}
								return updatedBrand;
							});
							updatedLocation.brands = brands;
						}
						return updatedLocation;
					});
					handleFormData({ multiLocationDetails: multiLocationDetails });
				} else {
					const brands = formData.brands.map((brnd) => {
						const updatedBrand = { ...brnd };
						if (updatedBrand.id === brand.id) {
							updatedBrand.copyCatalogue = location || null;
						}
						return updatedBrand;
					});
					handleFormData({ brands: brands });
				}
			} else {
				handleFormData({ copyCatalogue: location || null });
			}
		},
		[formData, handleFormData, isMulti, isMultibrandEnabled]
	);

	const handleOpenPreviewCatalogue = (brand = {}) => {
		setPreviewCatalogue({
			isOpen: true,
			brand
		});
	};

	const handleClosePreviewCatalogue = () => {
		setPreviewCatalogue(PREVIEW_CATALOGUE);
	};

	const copyCatalogue = useCallback(
		(data, props, isMulti) => {
			if (!data.copyCatalogue) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Please select an existing location first",
						timeout: 3000,
						error: true
					}
				});
				closeContextMenu();
				return;
			}
			if (isMulti) {
				let sameBrandFound = false;
				const multiLocationDetails = formData.multiLocationDetails.map((loc, i) => {
					let updatedLocation = { ...loc };
					if (i === props.rowIndex || props.rowIndex === "all") {
						// update brands in location
						let brands = updatedLocation.brands.map((brnd) => {
							const updatedBrand = { ...brnd };
							if (
								data.id === updatedBrand.id &&
								data.copyCatalogue?.id !== updatedBrand.copyCatalogue?.id
							) {
								updatedBrand.copyCatalogue = data.copyCatalogue || null;
								sameBrandFound = true;
							}
							return updatedBrand;
						});
						updatedLocation.brands = brands;
					}
					return updatedLocation;
				});
				handleFormData({ multiLocationDetails: multiLocationDetails });
				if (!sameBrandFound) {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "No other location(s) found for this brand",
							timeout: 3000,
							error: true
						}
					});
				}
			} else {
				const brands = formData.brands.map((brnd) => {
					const updatedBrand = { ...brnd };
					updatedBrand.copyCatalogue = data.copyCatalogue || null;
					return updatedBrand;
				});
				handleFormData({ brands: brands });
			}
			closeContextMenu();
		},
		[formData, handleFormData]
	);

	const renderMenuItems = (data, props, isMulti) => {
		if (isMulti) {
			return (
				<React.Fragment>
					<div
						className="action-item"
						onClick={() => copyCatalogue(data, { ...props, rowIndex: "all" }, isMulti)}
					>
						Copy catalogue to other location(s) for this brand
					</div>
				</React.Fragment>
			);
		}
		return null;
	};

	const openContextMenu = (data, props, isMulti) => {
		setContextMenu({
			id: isMulti
				? contextMenu.id === `${props.rowIndex}${data.id}`
					? null
					: `${props.rowIndex}${data.id}`
				: contextMenu.id === data.id
					? null
					: data.id,
			data,
			props
		});
	};

	const closeContextMenu = () => {
		setContextMenu({
			id: null,
			data: {},
			props: {}
		});
	};

	return (
		<div className="brand-catalogue">
			<div className="section-title">Copy existing catalogues</div>
			{!isMultibrandEnabled && isMulti && (
				<div className="section-subtitle">
					<div>This catalogue will be copied to all the new locations.</div>
					<div>You can edit individual location catalogues later, or skip this step for now.</div>
				</div>
			)}
			{isMultibrandEnabled ? (
				<CustomTable
					columns={columns}
					data={isMulti ? formData.multiLocationDetails || [] : formData?.brands || []}
					extractInitials={extractInitials}
					handleOpenPreviewCatalogue={handleOpenPreviewCatalogue}
					handleChange={handleChange}
					classes={"brands-list-table-container" + (isMulti ? " multi" : "")}
					content="Brands"
					isMulti={isMulti}
					renderMenuItems={renderMenuItems}
					openContextMenu={openContextMenu}
					closeContextMenu={closeContextMenu}
					contextMenuId={contextMenu.id}
					hideColumns={!isMulti ? ["name"] : []}
				/>
			) : (
				<div className="select-location-catalogue">
					<SelectFilter
						title="Select location"
						options={stores?.items}
						isLoading={stores?.isLoading}
						field="copyCatalogue"
						currValue={formData.copyCatalogue || null}
						setFilter={(f, value) => handleChange(value)}
						labelKey="name"
						valueKey="id"
						isAsync={true}
						handleSearch={(search) => fetchStoresDebounced(search)}
						placeholder="Select location"
					/>
					{formData.copyCatalogue && (
						<div
							className="preview"
							onClick={() => handleOpenPreviewCatalogue({ copyCatalogue: formData.copyCatalogue })}
						>
							Preview
						</div>
					)}
					{/* <div className="preview-catalogue"></div> */}
				</div>
			)}
			<PreviewCatalogue {...previewCatalogue} close={handleClosePreviewCatalogue} />
		</div>
	);
};
const mapStateToProps = (store) => ({
	stores: store.configItems.stores
});
export default connect(mapStateToProps)(BrandCatalogue);

const LocationDropdown = ({ copyCatalogue = null, handleChange, brand = {} }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [locationsList, setLocationsList] = useState({
		isLoading: false,
		objects: []
	});

	const fetchBrandLocations = async (searchText, brandId = null) => {
		if (searchText !== undefined) {
			try {
				setLocationsList({
					...locationsList,
					isLoading: true
				});
				const variables = {
					limit: 20,
					sort: {
						field: "name",
						order: "ASC"
					}
				};
				if (brandId) {
					variables.brand = String(brandId);
				} else {
					variables.brand = null;
				}
				let filters = [
					{
						field: "is_active",
						value: "true"
					}
				];
				if (searchText) {
					filters.push({ field: "name", value: searchText });
				}
				variables.filters = filters;
				const resp = await client.query({
					query: GET_STORES_LIST,
					variables
				});
				setLocationsList({
					...locationsList,
					objects: resp.data.stores.objects,
					isLoading: false
				});
			} catch (error) {
				console.log(error);
				setLocationsList({
					...locationsList,
					isLoading: false
				});
			}
		}
	};

	const fetchBrandLocationsDebounced = debounce(
		(searchText, brandId) => fetchBrandLocations(searchText, brandId),
		300
	);

	const handleMenuOpen = () => {
		if (!isOpen) {
			setIsOpen(true);
			fetchBrandLocations("", brand?.id);
		}
	};

	const handleMenuClose = () => {
		setIsOpen(false);
	};

	return (
		<SelectFilter
			options={locationsList.objects}
			isLoading={locationsList.isLoading}
			field="copyCatalogue"
			currValue={copyCatalogue || null}
			setFilter={(f, value) => handleChange(value, brand)}
			labelKey="name"
			valueKey="id"
			isAsync={true}
			onMenuOpen={handleMenuOpen}
			onMenuClose={handleMenuClose}
			handleSearch={(search) => fetchBrandLocationsDebounced(search, brand?.id)}
			placeholder="Select location"
		/>
	);
};

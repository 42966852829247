import React, { Component, useEffect } from "react";

// third party
import { connect } from "react-redux";
import history from "./history";
import { Router, Route, Redirect, Switch, Link } from "react-router-dom";
import { withScope, captureException } from "@sentry/browser";
import _ from "lodash";
import PubSub from "pubsub-js";
import SatismeterService from "./services/SatismeterService";
import moment from "moment";
import { Snowfall, TopNavbar, HorizonText, TextVariant } from "@urbanpiper-engineering/horizon";

// utils
import {
	isLoggedin,
	lS,
	scroll,
	isFeatureFlagEnabled,
	isChristmasSeason,
	isStartOfNewYear,
	trackEvent,
	openFreshchatWidget,
	capitaliseTextStrict,
	isInternalUser
} from "./atlas-utils";
import { initFreshChat } from "./atlas-utils/init";
import { UPDATE_USER_PROPERTIES } from "./atlas-utils/tracking";

// actions
import { updateBizState } from "./actions/actions";
import { fetchHubConfigurations } from "./actions/hub";
import { subscribeToFlowSteps, unsubscribeToFlowSteps, fetchOnboardingFlows } from "./actions/onboarding";
import { periscopeAutoRefresh } from "./actions/periscope";

// components
import {
	GlobalMessage,
	GlobalDeactivationBlocker,
	ShowCloseDrawerMessage,
	toggleSidebar,
	logout,
	InfiniteLoader
} from "./components/SiteComp";
import TopBanner from "./components/_commons/TopBanner";
import SideNavContainer, { LogoBlock } from "./components/_commons/SideNavContainer";
import Rewind from "./containers/Rewind";
import Login from "./containers/Login";
import AutoLogin from "./containers/AutoLogin";
import AuthServiceLogin from "./containers/AuthServiceLogin";
import Dashboard from "./containers/Dashboard";
import CampaignContainer from "./containers/Campaigns";
import SegmentsContainer from "./containers/Segments";
import { BusinessProfile } from "./containers/BusinessProfile";
import BillingContainer from "./components/Settings/BillingSettings";
import UserManagementContainer from "./components/Settings/UserManagementSettings";
import UnifiedUserManagementContainer from "./components/Settings/UnifiedUserManagement";
import APIAccessManagementContainer from "./components/Settings/APIAccessManagementSettings";
import TransactionsContainer from "./containers/Transactions";
import CustomersContainer from "./containers/Customers";
import AnalyticsContainer from "./containers/Analytics";
import SalesAnalytics from "./containers/SalesAnalytics";
import Operations from "./containers/Operations";
import RhiTransactions from "./containers/RhiTransactions";
import ErrorPage from "./containers/ErrorPage";
import AccessDenied from "./containers/AccessDenied";
import LocationsContainer from "./containers/Locations";
import CategoriesContainer from "./containers/Categories";
import ItemsContainer from "./containers/Items";
import ModifierGroupsContainer from "./containers/ModifierGroups";
import ModifiersContainer from "./containers/Modifiers";
import TaxesContainer from "./containers/Taxes";
import ItemGroupsContainer from "./containers/ItemGroups";
import LocationGroupsContainer from "./containers/LocationGroups";
import TimingGroupsContainer from "./containers/TimingGroups";
import ChargesContainer from "./containers/Charges";
import BulkOperationsContainer from "./containers/BulkOperations";
import MerakiContainer from "./containers/Meraki";
import Hub from "./containers/Hub";
import DiscountsContainer from "./containers/Discounts";
import ReferralsContainer from "./containers/Referrals";
import CouponsContainer from "./containers/Coupons";
import ItemTagsContainer from "./containers/ItemTags";
import CatalogueVerificationContainer from "./containers/CatalogueVerification";
import MerakiWebContainer from "./containers/MerakiWeb/index";
import MerakiPlansBilling from "./containers/MerakiPlansBilling";
import WebhooksContainer from "./containers/Webhooks";
import GalleryContainer from "./containers/Gallery";
import ReportsContainer from "./containers/Reports";
import ReportsNew from "./containers/ReportsNew";
import CustomerFeedbackContainer from "./containers/CustomerFeedback";
import CustomIntegrationsContainer from "./containers/CustomIntegrations";
import UserVerification from "./containers/UserVerification";
import AcceptInvite from "./containers/AcceptInvite";
import MerakiWebEditorContainer from "./containers/MerakiWebEditor";
import PiperAcademyContainer from "./containers/PiperAcademy";
import AuditEventsContainer from "./containers/AuditEvents";
import ReconciliationContainer from "./containers/Reconciliation";
import ReconciliationStats from "./containers/ReconciliationStats";
import DeveloperTools from "./containers/DeveloperTools";
import OngoingTasks from "./components/_commons/OngoingTasks";
import CatalogueBackupsContainer from "./containers/CatalogueBackups";
import Brands from "./containers/Brands";
import MenusContainer from "./containers/Menus";
import SectionsContainer from "./containers/Sections";
import Onboarding from "./containers/Onboarding";
import OnBoardingBrands from "./containers/OnBoardingBrands";
import OnboardingLocations from "./containers/OnboardingLocations";
import OnboardingMenus from "./containers/OnboardingMenus";
import BillingRecovery from "./containers/BillingRecovery";
import Home from "./containers/Persicope/Dashboard/Home";
import LocationAvailability from "./containers/Persicope/LocationAvailability/LocationAvailability";
import ItemAvailabilityRoutes from "./containers/Persicope/ItemAvailability/ItemAvailabilityRoutes";
import ItemIssuesRoutes from "./containers/Persicope/ItemIssues/ItemIssuesRoutes";
import ActivityLog from "./containers/Persicope/ActivityLog/ActivityLog";
import UserEarlyAccess from "./containers/Persicope/UserEarlyAccess/UserEarlyAccess";
import WaitingPage from "./containers/Persicope/UserEarlyAccess/WaitingPage";
import { ButtonIcon } from "./components/_commons/ButtonIcon";
import Notifications from "./containers/Notifications";
import MerakiLanding from "./containers/MerakiWeb/MerakiLanding";

// clients
import { store } from "./store/configureStore";
import { clientOnboarding, resetClientOnboarding, closeOnboardingWsLink } from "./client-onboarding";

// graphql
import { GET_INFO_FOR_MIXPANEL } from "./graphql/misc";

// services
import NotificationService from "./services/NotificationService";

// style
import "./css/main.scss";

// constants;
import { DEVELOPER_TOOLS_ENABLED_PRODUCTION_BIZ_IDS } from "./client-config";
import { ONBOARDING_FLOWS } from "./client-config";
const TRACKING_ENABLED = process.env.REACT_APP_TRACKING_ENABLED;
const ENVIRONMENT_FLAG = process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG;
const ULE_SWITCH_APP_URL = process.env.REACT_APP_ULE_SWITCH_APP;

@connect((store) => ({
	globalMessage: store.globalMessage,
	dimensions: store.configItems.dimensions,
	showCloseDrawerMessage: store.showCloseDrawerMessage,
	verificationDetails: store.login.loginDetail.verificationDetails,
	login: store.login,
	atlasOnboardingflowData: store.atlasOnboardingState.flowData,
	periscopeEarlyAccessState: store.periscopeEarlyAccessState
}))
class App extends Component {
	constructor(props) {
		super(props);
	}

	componentDidCatch(error, errorInfo) {
		if (TRACKING_ENABLED === "yes") {
			withScope((scope) => {
				Object.keys(errorInfo).forEach((key) => {
					scope.setExtra(key, errorInfo[key]);
				});
				if (this.props?.login?.loggedInbizDetail?.id) {
					scope.setExtra("Biz", {
						id: this.props?.login?.loggedInbizDetail?.id,
						name: this.props?.login?.loggedInbizDetail?.name
					});
				}
				captureException(error);
			});
		}
	}

	updateDimensions = () => {
		store.dispatch({
			type: "UPDATE_DIMENSIONS",
			payload: {
				height: window.innerHeight,
				width: window.innerWidth
			}
		});
	};

	updateDimensionsDebounced = _.debounce(() => this.updateDimensions(), 500);

	getFreshChatInitData = () => {
		const freshChatInitData = {
			bizName: this.props.login?.loggedInbizDetail?.name,
			bizId: this.props.login?.loggedInbizDetail?.id,
			firstName: this.props.login?.loginDetail?.firstName,
			email: this.props.login?.loginDetail?.email,
			phone: this.props.login?.loginDetail?.phone,
			mode: "atlas",
			companyCountry: this.props.login?.loggedInbizDetail?.country,
			externalId: this.props.login?.authServiceData?.authUserId || this.props.login?.loginDetail?.id // ule userId takes the precedence
		};
		return freshChatInitData;
	};

	componentDidMount() {
		this.updateDimensions();
		window.addEventListener("resize", this.updateDimensionsDebounced);

		initFreshChat(this.getFreshChatInitData());
	}

	componentDidUpdate(prevProps) {
		if (window.fcWidget && !window?.fcWidget?.isInitialized()) {
			initFreshChat(this.getFreshChatInitData());
		}

		// start subscription for onboarding flow steps if data is available, and redirect to onboarding page
		if (
			clientOnboarding &&
			!["/login", "/temp-login", "/auth-service"].includes(window?.location?.pathname) &&
			this.props.atlasOnboardingflowData?.name === ONBOARDING_FLOWS.ATLAS_ONBOARDING_FLOW
		) {
			subscribeToFlowSteps("atlas-onboarding");

			// if user is not already on onboarding, redirect to onboarding
			if (
				this.props.atlasOnboardingflowData?.name !== prevProps.atlasOnboardingflowData?.name &&
				!window?.location?.pathname.includes("/onboarding")
			) {
				history.push("/onboarding");
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensionsDebounced);

		// unsubscribe to onboarding flow steps
		unsubscribeToFlowSteps();
	}

	render() {
		const { show, message } = this.props.globalMessage;
		const { showInfo } = this.props.showCloseDrawerMessage;
		return (
			<Router history={history}>
				<div>
					{show && window.location.pathname !== "/login" ? (
						<GlobalMessage />
					) : message !== "" && message[0] === "Invalid Credentials\n" ? (
						<GlobalMessage />
					) : null}
					{NotificationService.renderGlobalNotificationsContainer}
					{showInfo && <ShowCloseDrawerMessage />}
					<Switch>
						<Route exact path="/login" component={Login} />
						<Route exact path="/temp-login" component={AutoLogin} />
						<Route exact path="/auth-service" component={AuthServiceLogin} />
						<Route exact path="/invite/:uuid/accept" component={AcceptInvite} />
						<Route
							exact
							path="/meraki-web/editor"
							render={(props) =>
								!isLoggedin() ? (
									<Redirect
										to={{
											pathname: "/login",
											state: props.location
										}}
									/>
								) : (
									<MerakiWebEditorContainer {...props} />
								)
							}
						/>
						<Route
							exact
							path="/user-verification"
							render={(props) =>
								!isLoggedin() ? (
									<Redirect
										to={{
											pathname: "/login",
											state: props.location
										}}
									/>
								) : this.props?.verificationDetails?.emailValidated &&
								  this.props?.verificationDetails?.phoneValidated ? (
									<Redirect
										to={{
											pathname: "/",
											state: props.location
										}}
									/>
								) : (
									<UserVerification {...props} />
								)
							}
						/>
						<Route
							path="/"
							render={(props) =>
								!isLoggedin() ? (
									<Redirect
										to={{
											pathname: "/login",
											state: props.location
										}}
									/>
								) : (!this.props?.verificationDetails?.emailValidated ||
										!this.props?.verificationDetails?.phoneValidated) &&
								  ENVIRONMENT_FLAG === "prod" ? (
									<Redirect
										to={{
											pathname: "/user-verification",
											state: props.location
										}}
									/>
								) : (
									<SecureRoutes {...props} updateDimensions={this.updateDimensions} />
								)
							}
						/>
					</Switch>
				</div>
			</Router>
		);
	}
}

@connect((store) => ({
	login: store.login,
	banner: store.banner,
	dimensions: store.configItems.dimensions,
	outstandingInvoicesState: store.outstandingInvoicesState,
	biz: store.login.loggedInbizDetail,
	user: store.login.loginDetail,
	banner: store.banner,
	sidebarState: store.sidebarState,
	headwayWidgetState: store.headwayWidgetState,
	globalLoaderState: store.globalLoaderState,
	access: store.login.loginDetail.access,
	userEmail: store.login.loginDetail.email,
	snowfall: store.snowfallState,
	verificationList: store.catalogueVerificationList,
	merakiWebSubscription: store.merakiWebSubscription.data,
	showCustomBannerMessage: store.login.showCustomBannerMessage,
	isBannerMessageDismissed: store.login.isBannerMessageDismissed,
	catalogueBackupsStatus: store.catalogueBackupsStatus,
	periscopeEarlyAccessState: store.periscopeEarlyAccessState,
	atlasOnboardingState: store.atlasOnboardingState,
	billingRecoveryState: store.billingRecoveryState,
	periscopeDataResponse: store.periscopeDataResponse
}))
class SecureRoutes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			endDate: new Date("Nov 10, 2021 23:59:59").getTime(),
			timer: undefined,
			isPeriscopeEarlyAccessEnabled: undefined,
			isPeriscopeEnabled: undefined
		};
	}

	async componentDidMount() {
		// this.countdown();
		this.props.updateDimensions();
		if (window?.posthog) {
			const isPeriscopeEnabled = window.posthog?.isFeatureEnabled("enable-periscope");
			const isEarlyAccessFeatureEnabled = window.posthog?.isFeatureEnabled("periscope-early-access");
			this.setState({
				isPeriscopeEarlyAccessEnabled: isEarlyAccessFeatureEnabled,
				isPeriscopeEnabled: isPeriscopeEnabled
			});
		}

		// start client onboarding and fetch flows
		await resetClientOnboarding();
		fetchOnboardingFlows();

		this.handleSidebarState();

		await updateBizState(this.props?.login);

		// initialize user data in satismeter
		SatismeterService.userInit(this.props?.login);

		if (!this.props.access.isNonHqAnalytics && !this.props.access.isNonHqReport) {
			fetchHubConfigurations();
		}

		// try {
		// 	// get additional info for mixpanel
		// 	const { data } = await client.query({
		// 		query: GET_INFO_FOR_MIXPANEL,
		// 		variables: {
		// 			brand: "all"
		// 		}
		// 	});
		// 	if (data) {
		// 		const isMultibrandEnabled = this.props.biz.isMultibrandEnabled;
		// 		const eventMeta = {
		// 			num_of_physical_locations_atlas: data?.physicalLocations?.count,
		// 			num_of_brand_locations_atlas: isMultibrandEnabled
		// 				? data?.brandLocations?.count
		// 				: data?.physicalLocations?.count,
		// 			num_of_brands: data?.brands?.count,
		// 			platforms_enabled: data?.configurations?.map((platform) => platform?.name)
		// 		};
		// 		PubSub.publish(UPDATE_USER_PROPERTIES, eventMeta);
		// 	}
		// } catch (err) {
		// 	// do nothing
		// }
	}

	periscopeAutoRefreshHandler() {
		if (
			window.location.pathname.startsWith("/periscope/") &&
			!this.fetchPeriscopeInterval
			// && this.props.periscopeDataResponse.autoRefresh
		) {
			periscopeAutoRefresh(true);
			this.fetchPeriscopeInterval = setInterval(
				() => {
					periscopeAutoRefresh(true);
				},
				5 * 60 * 1000
			);
		} else {
			if (
				// !this.props.periscopeDataResponse.autoRefresh ||
				this.fetchPeriscopeInterval &&
				!window.location.pathname.startsWith("/periscope/")
			) {
				clearInterval(this.fetchPeriscopeInterval);
				this.fetchPeriscopeInterval = null;
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.dimensions.width > 768 && this.props.dimensions.width <= 768) {
			toggleSidebar(true);
		}
		if (prevProps.dimensions.width <= 768 && this.props.dimensions.width > 768) {
			toggleSidebar(false);
		}
		if (
			window.location.pathname.startsWith("/periscope/") &&
			prevProps.periscopeDataResponse.autoRefresh !== this.props.periscopeDataResponse.autoRefresh
		) {
			this.periscopeAutoRefreshHandler();
		}
		this.periscopeAutoRefreshHandler();
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	handleSidebarState = () => {
		if (this.props.dimensions.width <= 768) {
			toggleSidebar(true);
		}
		if (this.props.dimensions.width > 768) {
			toggleSidebar(false);
		}
	};

	handleOpenUrl = (url, target = "_self") => {
		if (url) {
			// onboarding
			unsubscribeToFlowSteps();
			closeOnboardingWsLink();

			// track event
			const eventName = "switch_app";
			const eventMeta = {};
			trackEvent(eventName, eventMeta);

			window.open(url, target).focus();
		}
	};

	openProfile = () => {
		history.push("/profile");
	};

	renderRightBlock = () => {
		const headwayWidgetState = this.props.headwayWidgetState;
		return (
			<div className="top-navbar-icons right-block">
				<div className="meraki-changelog">
					<div className="icon-changelog">
						<ButtonIcon
							icon={"announcement" + (headwayWidgetState?.active ? "-selected" : "")}
							color={headwayWidgetState?.active ? "#3F66FF" : "#828282"}
						/>
					</div>
				</div>
				<Notifications />
				<Link to="/piper-academy" onClick={() => trackEvent("clicked_get_started_with_atlas", {})}>
					<ButtonIcon icon="help" color="#828282" />
				</Link>
				<ButtonIcon icon="call-chat-support" color="#828282" clickHandler={openFreshchatWidget} />
			</div>
		);
	};

	renderCenterBlock = () => {
		return (
			<HorizonText variant={TextVariant.BODY1_SEMIBOLD} className="center-block-breadcrumbs text-xs">
				<span>
					{capitaliseTextStrict(this.props.sidebarState?.openItems?.[0]?.split("-")?.join(" "), true)}
				</span>
				{this.props.sidebarState?.openItems?.[1] && (
					<>
						<span>{" / "}</span>
						<HorizonText variant={TextVariant.BODY1} className="text-xs text-up-black-50" inline>
							{capitaliseTextStrict(this.props.sidebarState?.openItems?.[1]?.split("-")?.join(" "), true)}
						</HorizonText>
					</>
				)}
			</HorizonText>
		);
	};

	countdown = () => {
		this.interval = setInterval(() => {
			const now = new Date().getTime();
			const timeleft = this.state.endDate - now;
			if (timeleft < 0) {
				this.setState({ timer: undefined });
				clearInterval(this.interval);
				return;
			}

			// set timer in days, hours, minutes and seconds
			const timer = {
				days: Math.floor(timeleft / (1000 * 60 * 60 * 24)),
				hours: Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
				minutes: Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)),
				seconds: Math.floor((timeleft % (1000 * 60)) / 1000)
			};
			this.setState({ timer });
		}, 1000);
	};

	render() {
		const {
			login,
			dimensions,
			access = {},
			userEmail = "",
			banner,
			merakiWebSubscription,
			showCustomBannerMessage,
			isBannerMessageDismissed,
			catalogueBackupsStatus,
			...rest
		} = this.props;
		const {
			modal: { isWebhookDisabled }
		} = this.props.billingRecoveryState;
		const { data, loading, error } = this.props.outstandingInvoicesState;
		let outstandingInvoices = data.objects || [];
		let pendingInvoices = (data.objects || []).sort((a, b) => a.dueDate.valueOf() - b.dueDate.valueOf());
		const currencySymbol = this.props.biz.currencySymbol || "";
		const bizState = this.props.biz.currentState;
		const bizId = this.props?.biz?.id;
		const bizCountry = this.props?.biz?.country;
		const isReconEnabled = this.props.biz.isReconEnabled;
		const isMultibrandEnabled = this.props.biz.isMultibrandEnabled;
		const isMenuOverCatalogueEnabled = this.props.biz.isMenuOverCatalogueEnabled;
		const isBizDeactivated = bizState === "inactive" || bizState === "pending_deactivation";
		const isOnboardingFlowEnabled =
			this.props.atlasOnboardingState?.flowData?.name === ONBOARDING_FLOWS.ATLAS_ONBOARDING_FLOW;
		const showFullNav = this.props?.atlasOnboardingState?.flowSteps?.find(
			(step) => step?.step?.name === "SHOW_FULL_NAV"
		)
			? true
			: false;
		let bannerstyles = {};
		if (showCustomBannerMessage && banner.filter((b) => !b.isDismissed).length > 0) {
			bannerstyles = { paddingTop: "120px" };
		} else if (outstandingInvoices.length > 0 || bizState === "pending_activation") {
			bannerstyles = { paddingTop: "120px" };
		} else if (merakiWebSubscription?.subscription?.status === "trialing") {
			bannerstyles = { paddingTop: "120px" };
		}
		// need to remove in next release
		const todaysDate = moment();
		const targetDate = moment("15-03-2024", "DD-MM-YYYY");
		const isDateBeforeTargetDate = todaysDate.isBefore(targetDate);
		const withBanner = dimensions?.width > 768 && bannerstyles?.paddingTop;

		return (
			<div>
				{dimensions.width > 768 && (
					<TopBanner showCustomBannerMessage={showCustomBannerMessage} banners={banner} />
				)}
				<TopNavbar
					className="atlas-top-navbar"
					profile={{ biz: this.props?.biz, user: this.props?.user }}
					appLogo={<LogoBlock redirectTo={isOnboardingFlowEnabled ? "/onboarding" : "/"} />}
					onMenuClick={
						dimensions?.width > 768 ? () => toggleSidebar(!this.props.sidebarState.active) : undefined
					}
					showBizLogo={false}
					showProfileDetails={false}
					showUserInitials={true}
					onUserInfoClick={this.openProfile}
					showAppSwitcher={this.props?.login?.authServiceData?.userBizSwitch || false}
					onAppSwitcherClick={() => this.handleOpenUrl(ULE_SWITCH_APP_URL)}
					onLogoutClick={() => logout(this.props?.login?.authServiceData, this.props?.user?.id)}
					centerBlock={dimensions?.width > 768 ? this.renderCenterBlock() : null}
					rightBlock={dimensions?.width > 768 ? this.renderRightBlock() : null}
					style={{ top: withBanner ? "57px" : "0px" }}
				/>
				<div
					className={
						"global-loader-container " +
						(this.props.globalLoaderState.active ? "active " : "") +
						(withBanner ? "with-banner " : "")
					}
				>
					<InfiniteLoader />
				</div>
				<div
					className={
						"layout-container " + (this.props.sidebarState.active ? "minimized " : " ") + "secure-routes"
					}
				>
					<SideNavContainer
						collapsed={this.props.sidebarState.active}
						classes={withBanner ? "with-banner" : ""}
					/>
					<OngoingTasks />
					{isStartOfNewYear() && access.isAdmin && <Rewind />}
					{isChristmasSeason() && <Snowfall active={rest?.snowfall?.active} count={75} color="#c6d2ff" />}
					<BillingRecovery />
					<div
						className={"page-container" + (withBanner ? " with-banner" : "")}
						style={dimensions.width > 768 ? bannerstyles : {}}
					>
						<GlobalDeactivationBlocker
							currentPathname={this.props.location.pathname}
							currencySymbol={currencySymbol}
							outstandingInvoices={outstandingInvoices}
							show={isBizDeactivated}
						/>
						{!access.isNonHqReport && !access.isNonHqAnalytics ? (
							<div className="content-container">
								<Switch>
									<Route
										exact
										path="/"
										render={() =>
											access.isSalesAnalysis ? (
												isDateBeforeTargetDate ? (
													<Dashboard />
												) : isOnboardingFlowEnabled && !showFullNav ? (
													<Redirect to="/onboarding" />
												) : (
													<Redirect to="/orders" />
												)
											) : (
												<Redirect
													to="/locations"
													restrictView={catalogueBackupsStatus.restrict}
												/>
											)
										}
									/>
									<Route
										path="/onboarding"
										exact
										render={() =>
											isOnboardingFlowEnabled ? <Onboarding /> : <Redirect to="/orders" />
										}
									/>
									<Route
										path="/onboarding/brands"
										exact
										render={() =>
											isOnboardingFlowEnabled ? <OnBoardingBrands /> : <Redirect to="/orders" />
										}
									/>
									<Route
										path="/onboarding/locations"
										exact
										render={() =>
											isOnboardingFlowEnabled ? (
												<OnboardingLocations />
											) : (
												<Redirect to="/orders" />
											)
										}
									/>
									<Route
										path="/onboarding/menu"
										exact
										render={() =>
											isOnboardingFlowEnabled ? <OnboardingMenus /> : <Redirect to="/orders" />
										}
									/>
									<Route
										path="/campaigns"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : (
												<CampaignContainer />
											)
										}
									/>
									<Route
										path="/segments"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : (
												<SegmentsContainer />
											)
										}
									/>
									<Route
										path="/profile"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isAdmin ? (
												<BusinessProfile />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/billing"
										render={(props) => {
											return access.isBilling ? (
												<BillingContainer
													isBizDeactivated={isBizDeactivated}
													location={this.props.location}
													renderRechargeDrawer={props?.history?.action === "REPLACE"}
												/>
											) : (
												<Redirect to="/access-denied" />
											);
										}}
									/>
									<Route
										path="/recharge"
										render={() => (
											<Redirect
												to={{
													pathname: "/billing"
												}}
											/>
										)}
									/>
									<Route
										path="/access"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isAdmin && ENVIRONMENT_FLAG === "staging" ? (
												<UserManagementContainer />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/unified-access"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isAdmin ? (
												<UnifiedUserManagementContainer />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/api-access"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isAdmin ? (
												<APIAccessManagementContainer />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/meraki"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : this.props.biz.modulesEnabled.includes("Meraki") &&
											  !(
													merakiWebSubscription?.subscription === null ||
													["cancelled", "not-subscribed"].includes(
														merakiWebSubscription?.subscription?.status
													)
											  ) ? (
												<MerakiContainer />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/meraki-web"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : this.props.biz.modulesEnabled.includes("Meraki") ? (
												<MerakiWebContainer />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/meraki-plans"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={
														{
															pathname: "/",
															state: props.location
														}
														// } />) : (this.props.biz.modulesEnabled.includes('Meraki')) ?
													}
												/>
											) : this.props.biz.modulesEnabled.includes("Meraki") &&
											  (merakiWebSubscription?.subscription === null ||
													merakiWebSubscription?.subscription?.status !==
														"legacy-billing") ? (
												<MerakiPlansBilling />
											) : (
												<Redirect to="/meraki-web" />
											)
										}
									/>
									<Route
										path="/hub"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isHubManagement ? (
												<Hub />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/verification"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isCatalogueManagement ? (
												<CatalogueVerificationContainer />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/activity-history"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isAdmin || access.isOperationsAnalysis || access.isSupport ? (
												<AuditEventsContainer />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/feedback"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : this.props.biz.modulesEnabled.includes("Meraki") &&
											  (access.isSupport || access.isMarketing) ? (
												<CustomerFeedbackContainer />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/orders"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isSupport ||
											  access.isSalesAnalysis ||
											  access.isOperationsAnalysis ? (
												<TransactionsContainer />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/customers"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isSupport || access.isMarketing ? (
												<CustomersContainer />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/brands"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : isMultibrandEnabled ? (
												<Brands
													hasAccess={access.isAdmin}
													restrictView={catalogueBackupsStatus.restrict}
												/>
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/locations"
										render={(props) =>
											isBizDeactivated ? (
												access.isSalesAnalysis ? (
													<Redirect
														to={{
															pathname: "/",
															state: props.location
														}}
													/>
												) : (
													<Redirect to="/access-denied" />
												)
											) : (
												<LocationsContainer
													hasAccess={access.isAdmin}
													bizCountry={bizCountry}
													isInternalUser={userEmail?.includes("@urbanpiper.com")}
													restrictView={catalogueBackupsStatus.restrict}
												/>
											)
										}
									/>
									<Route
										path="/menus"
										render={(props) =>
											isBizDeactivated ? (
												access.isSalesAnalysis ? (
													<Redirect
														to={{
															pathname: "/",
															state: props.location
														}}
													/>
												) : (
													<Redirect to="/access-denied" />
												)
											) : access.isClosedBetaAccess && isMenuOverCatalogueEnabled ? (
												<MenusContainer hasAccess={access.isAdmin} />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/sections"
										render={(props) =>
											isBizDeactivated ? (
												access.isSalesAnalysis ? (
													<Redirect
														to={{
															pathname: "/",
															state: props.location
														}}
													/>
												) : (
													<Redirect to="/access-denied" />
												)
											) : access.isClosedBetaAccess && isMenuOverCatalogueEnabled ? (
												<SectionsContainer hasAccess={access.isAdmin} />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/item-groups"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isCatalogueManagement ? (
												<ItemGroupsContainer
													hasAccess={access.isCatalogueManagement}
													restrictView={catalogueBackupsStatus.restrict}
												/>
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/location-groups"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isCatalogueManagement ? (
												<LocationGroupsContainer
													hasAccess={access.isCatalogueManagement}
													restrictView={catalogueBackupsStatus.restrict}
												/>
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/timing-groups"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isCatalogueManagement ? (
												<TimingGroupsContainer
													hasAccess={access.isCatalogueManagement}
													restrictView={catalogueBackupsStatus.restrict}
												/>
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route path="/meraki-landing" render={(props) => <MerakiLanding />} />
									<Route
										path="/discounts"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isHubManagement ? (
												<DiscountsContainer />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/coupons"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : (
												<CouponsContainer />
											)
										}
									/>
									<Route
										path="/referrals"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : (
												<ReferralsContainer />
											)
										}
									/>
									<Route
										path="/payout-sheets"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : isReconEnabled ? (
												<ReconciliationContainer />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/reconciliation-stats"
										render={(props) =>
											(
												isBizDeactivated ? (
													<Redirect
														to={{
															pathname: "/",
															state: props.location
														}}
													/>
												) : (
													isReconEnabled
												)
											) ? (
												<ReconciliationStats />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/categories"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isCatalogueManagement ? (
												<CategoriesContainer
													hasAccess={access.isCatalogueManagement}
													restrictView={catalogueBackupsStatus.restrict}
												/>
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/items"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isCatalogueManagement ? (
												<ItemsContainer
													hasAccess={access.isCatalogueManagement}
													restrictView={catalogueBackupsStatus.restrict}
												/>
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/modifier-groups"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isCatalogueManagement ? (
												<ModifierGroupsContainer
													hasAccess={access.isCatalogueManagement}
													restrictView={catalogueBackupsStatus.restrict}
												/>
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/modifiers"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isCatalogueManagement ? (
												<ModifiersContainer
													hasAccess={access.isCatalogueManagement}
													restrictView={catalogueBackupsStatus.restrict}
												/>
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/taxes"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isCatalogueManagement ? (
												<TaxesContainer
													hasAccess={access.isCatalogueManagement}
													restrictView={catalogueBackupsStatus.restrict}
												/>
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/charges"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isCatalogueManagement ? (
												<ChargesContainer
													hasAccess={access.isCatalogueManagement}
													restrictView={catalogueBackupsStatus.restrict}
												/>
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/tags"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isCatalogueManagement ? (
												<ItemTagsContainer
													hasAccess={access.isCatalogueManagement}
													restrictView={catalogueBackupsStatus.restrict}
												/>
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/backups"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isCatalogueManagement || access.isAdmin ? (
												<CatalogueBackupsContainer
													hasAccess={access.isCatalogueManagement || access.isAdmin}
													restrictView={catalogueBackupsStatus.restrict}
												/>
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/bulk"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isCatalogueManagement ? (
												<BulkOperationsContainer
													restrictView={catalogueBackupsStatus.restrict}
													bizCountry={bizCountry}
													isInternalUser={userEmail?.includes("@urbanpiper.com")}
												/>
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/analytics"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : ENVIRONMENT_FLAG !== "prod" ||
											  access.isSalesAnalysis ||
											  access.isOperationsAnalysis ||
											  access.isMarketing ? (
												<AnalyticsContainer />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/webhooks"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isAdmin && (!isWebhookDisabled || isInternalUser()) ? (
												<WebhooksContainer hasAccess={access.isAdmin} />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/gallery"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isAdmin ? (
												<GalleryContainer hasAccess={access.isAdmin} />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/integrations"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isAdmin && rest.biz.eisEnabled ? (
												<CustomIntegrationsContainer hasAccess={access.isAdmin} />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/developer-tools"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isAdmin &&
											  (ENVIRONMENT_FLAG === "posint" ||
													(ENVIRONMENT_FLAG === "prod" &&
														DEVELOPER_TOOLS_ENABLED_PRODUCTION_BIZ_IDS[bizId])) ? (
												<DeveloperTools hasAccess={access.isAdmin} />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/reports"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : access.isSalesAnalysis || access.isOperationsAnalysis ? (
												<ReportsNew
													hasAccess={access.isSalesAnalysis || access.isOperationsAnalysis}
												/>
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										exact
										path="/piper-academy"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : (
												<PiperAcademyContainer {...props} />
											)
										}
									/>
									<Route
										exact
										path="/piper-academy/:id"
										render={(props) =>
											isBizDeactivated ? (
												<Redirect
													to={{
														pathname: "/",
														state: props.location
													}}
												/>
											) : (
												<PiperAcademyContainer {...props} />
											)
										}
									/>
									<Route
										exact
										path="/periscope/home"
										render={(props) =>
											this.state.isPeriscopeEnabled &&
											(this.props.periscopeEarlyAccessState?.bizSubscription ||
												lS?.get("periscope")?.bizSubscription) ? (
												<Home />
											) : (
												this.state.isPeriscopeEarlyAccessEnabled !== undefined && (
													<Redirect to="/access-denied" />
												)
											)
										}
									/>
									<Route
										path="/periscope/item-availability"
										render={(props) =>
											this.state.isPeriscopeEnabled &&
											(this.props.periscopeEarlyAccessState?.bizSubscription ||
												lS?.get("periscope")?.bizSubscription) ? (
												<ItemAvailabilityRoutes />
											) : (
												this.state.isPeriscopeEarlyAccessEnabled !== undefined && (
													<Redirect to="/access-denied" />
												)
											)
										}
									/>
									<Route
										exact
										path="/periscope/storefront-availability"
										render={(props) =>
											this.state.isPeriscopeEnabled &&
											(this.props.periscopeEarlyAccessState?.bizSubscription ||
												lS?.get("periscope")?.bizSubscription) ? (
												<LocationAvailability />
											) : (
												this.state.isPeriscopeEarlyAccessEnabled !== undefined && (
													<Redirect to="/access-denied" />
												)
											)
										}
									/>
									<Route
										path="/periscope/item-issues"
										render={(props) =>
											this.state.isPeriscopeEnabled &&
											(this.props.periscopeEarlyAccessState?.bizSubscription ||
												lS?.get("periscope")?.bizSubscription) ? (
												<ItemIssuesRoutes />
											) : (
												this.state.isPeriscopeEarlyAccessEnabled !== undefined && (
													<Redirect to="/access-denied" />
												)
											)
										}
									/>

									<Route
										exact
										path="/periscope/activity-log"
										render={(props) =>
											this.state.isPeriscopeEnabled &&
											(this.props.periscopeEarlyAccessState?.bizSubscription ||
												lS?.get("periscope")?.bizSubscription) ? (
												<ActivityLog />
											) : (
												this.state.isPeriscopeEarlyAccessEnabled !== undefined && (
													<Redirect to="/access-denied" />
												)
											)
										}
									/>
									<Route path="/periscope/collect" render={(props) => <WaitingPage />} />

									<Route
										path="/periscope/early-access"
										render={(props) => {
											const isAdmin = access?.isAdmin;
											const isInternalUser =
												this.props?.login?.loginDetail?.email?.includes("@urbanpiper.com");

											const showWaitlistCTA =
												this.state.isPeriscopeEnabled &&
												!this.state.isPeriscopeEarlyAccessEnabled &&
												!lS?.get("periscope")?.bizSubscription &&
												!isInternalUser;

											const showEarlyAccessSignUp =
												this.state.isPeriscopeEnabled &&
												this.state.isPeriscopeEarlyAccessEnabled &&
												!lS?.get("periscope")?.bizSubscription;

											if (!isAdmin || !isFeatureFlagEnabled("enable-periscope")) {
												return <Redirect to="/access-denied" />;
											}

											if (showEarlyAccessSignUp) {
												return (
													<UserEarlyAccess
														waitlist={false}
														periscopeFeatureFlag={this.state.isPeriscopeEarlyAccessEnabled}
													/>
												);
											}

											return (
												<UserEarlyAccess
													waitlist={true}
													showWaitlistCTA={showWaitlistCTA}
													periscopeFeatureFlag={this.state.isPeriscopeEarlyAccessEnabled}
												/>
											);
										}}
									/>
									<Route path="/error" component={ErrorPage} />
									<Route path="/access-denied" component={AccessDenied} />
									<Redirect to="/error" />
								</Switch>
							</div>
						) : (
							<div className="content-container">
								<Switch>
									<Route
										exact
										path="/"
										render={() => (
											<Redirect to={access.isNonHqAnalytics ? "/analytics/home" : "/reports"} />
										)}
									/>
									<Route
										path="/analytics"
										render={(props) =>
											!isBizDeactivated && access.isNonHqAnalytics ? (
												<AnalyticsContainer />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route
										path="/reports"
										render={(props) =>
											!isBizDeactivated && access.isNonHqReport ? (
												<ReportsNew hasAccess={access.isNonHqReport} />
											) : (
												<Redirect to="/access-denied" />
											)
										}
									/>
									<Route path="/error" component={ErrorPage} />
									<Route path="/access-denied" component={AccessDenied} />
									<Redirect to={access.isNonHqAnalytics ? "/analytics/home" : "/reports"} />
								</Switch>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const ScrollToTop = ({ location }) => {
	useEffect(() => {
		scroll({ top: 0, left: 0 });
	}, [location.pathname]);
	return null;
};

export default App;

import React from "react";

// components
import { Button } from "./Button";

// third party
import { connect } from "react-redux";

@connect((store) => ({
	supportedLanguages: store.login.loggedInbizDetail.supportedLanguages,
	actionState: store.configItems.translation
}))
export class Textarea extends React.Component {
	render() {
		const {
			children,
			validationMessage,
			isFilterItem,
			readOnly,
			requiredLabel = false,
			showCustomTooltip = false,
			tooltipInfo = "",
			tooltipPosition = "up-left",
			showLanguages = false,
			supportedLanguages = [],
			currSelectedLang = "",
			handleLanguage,
			showLoading = false,
			actionState = {},
			showActionBtn = false,
			action,
			classes = "",
			endIcon = null,
			...rest
		} = this.props;

		return (
			<div
				className={
					`at-textarea-container ${endIcon ? "end-icon-included" : ""} ` +
					(classes || "") +
					(isFilterItem ? " filter-section-item" : "")
				}
			>
				{(showLanguages || children) && (
					<label className={"at-textarea-label" + (isFilterItem ? " meta-info" : "")}>
						{showLanguages ? (
							<div className="label-multi-lang-container">
								<div>
									<span className="label">{children ?? ""}</span>
									{showCustomTooltip && tooltipInfo && (
										<span aria-label={tooltipInfo} data-balloon-pos={tooltipPosition}>
											<img
												className="info"
												src="/assets/icons/info.png"
												onClick={(e) => e.stopPropagation()}
											/>
										</span>
									)}
								</div>
								{supportedLanguages.length > 1 && (
									<div className="languages">
										<span>|</span>
										{supportedLanguages.map((lang, i) => (
											<React.Fragment key={i}>
												<span
													className={currSelectedLang === lang.value ? "selected" : ""}
													onClick={() => handleLanguage(lang)}
												>
													{lang.valueForDisplay}
												</span>
												<span>|</span>
											</React.Fragment>
										))}
									</div>
								)}
							</div>
						) : (
							<React.Fragment>
								<span className={requiredLabel ? " required-sign" : ""}>{children}</span>
								{!showLanguages && showCustomTooltip && tooltipInfo && (
									<span aria-label={tooltipInfo} data-balloon-pos={tooltipPosition}>
										<img
											className="info"
											src="/assets/icons/info.png"
											onClick={(e) => e.stopPropagation()}
										/>
									</span>
								)}
							</React.Fragment>
						)}
					</label>
				)}
				<textarea
					style={readOnly ? { backgroundColor: "#eaeaea", color: "#777" } : {}}
					autoComplete="off"
					readOnly={readOnly || false}
					className={`at-textarea ${endIcon ? "end-icon-included" : ""}`}
					{...rest}
				/>
				{showActionBtn && (
					<Button
						classes={
							(actionState.status && rest.field === actionState.field ? "action-success" : "") +
							(actionState.isLoading ? " no-click" : "")
						}
						clickHandler={action}
					>
						{!actionState.isLoading
							? actionState.status && rest.field === actionState.field
								? "Saved"
								: "Save"
							: !actionState.status && rest.field === actionState.field
								? ""
								: "Save"}
					</Button>
				)}
				{showLoading && rest.field === actionState.field && actionState.isLoading && (
					<div className="loading-container">
						<div className="at-textarea-loading"></div>
					</div>
				)}
				{validationMessage && <div className="validation-message">{validationMessage}</div>}
				{endIcon && <div className="end-icon">{endIcon}</div>}
			</div>
		);
	}
}

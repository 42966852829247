// utils
import { lS, isInternalUser, isFeatureFlagEnabled } from "../atlas-utils";

// actions
import { getEncodedAnalyticsFilters } from "../actions/analytics";

// constants
import { ONBOARDING_FLOWS, ATLAS_ONBOARDING_STEPS, DEVELOPER_TOOLS_ENABLED_PRODUCTION_BIZ_IDS } from "../client-config";
const ENVIRONMENT_FLAG = process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG;

const getSideNavItems = ({
	biz = {},
	access = {},
	merakiWebSubscription,
	periscopeEarlyAccessState = {},
	atlasOnboardingState = {},
	billingRecoveryState = {},
	handleModalOpen,
	handleBillingAlertModal
}) => {
	const {
		modal: { isWebhookDisabled }
	} = billingRecoveryState;
	const { flowData, flowSteps } = atlasOnboardingState;
	const isOnboardingFlowEnabled = flowData?.name === ONBOARDING_FLOWS.ATLAS_ONBOARDING_FLOW;
	const showFullNav = flowSteps?.find((step) => step?.step?.name === "SHOW_FULL_NAV") ? true : false;

	const completedSteps = flowSteps?.filter((step) => step?.status === "complete")?.map((step) => step?.step?.name);
	const inprogressSteps = flowSteps?.filter((step) => step?.status === "inprogress")?.map((step) => step?.step?.name);

	const isCompanyDetailsStepComplete = completedSteps?.includes(ATLAS_ONBOARDING_STEPS.COMPLETE_COMPANY_DETAILS);
	const isBrandStepComplete = completedSteps?.includes(ATLAS_ONBOARDING_STEPS.CREATE_BRANDS);
	const isLocationStepComplete = completedSteps?.includes(ATLAS_ONBOARDING_STEPS.CONFIGURE_LOCATIONS);
	const isMenuStepInProgress = inprogressSteps?.includes(ATLAS_ONBOARDING_STEPS.CONFIGURE_MENUS);
	const isMenuStepComplete = completedSteps?.includes(ATLAS_ONBOARDING_STEPS.CONFIGURE_MENUS);
	const isRTGLStepComplete = completedSteps?.includes(ATLAS_ONBOARDING_STEPS.GO_LIVE_ON_LOCATIONS);
	const isInviteUsersStepComplete = completedSteps?.includes(ATLAS_ONBOARDING_STEPS.INVITE_USERS);

	const items =
		!access?.isNonHqReport && !access?.isNonHqAnalytics
			? [
					{
						label: "Orders",
						key: "orders",
						iconId: "online-orders",
						path: "/orders",
						render:
							(access?.isSupport || access?.isSalesAnalysis || access?.isOperationsAnalysis) &&
							(!isOnboardingFlowEnabled || showFullNav)
					},
					{
						label: "Locations",
						key: "locations",
						iconId: "locations",
						path: "/locations",
						render: !isOnboardingFlowEnabled || isLocationStepComplete || showFullNav
					},
					{
						label: "Catalogue",
						key: "catalogue",
						iconId: "catalogue",
						render:
							!biz?.isMenuOverCatalogueEnabled &&
							access?.isCatalogueManagement &&
							!isOnboardingFlowEnabled,
						childrenProps: [
							{
								label: "Categories",
								key: "categories",
								path: "/categories",
								render: access?.isCatalogueManagement
							},
							{
								label: "Items",
								key: "items",
								path: "/items",
								render: access?.isCatalogueManagement
							},
							{
								label: "Modifier Groups",
								key: "modifier-groups",
								path: "/modifier-groups",
								render: access?.isCatalogueManagement
							},
							{
								label: "Modifiers",
								key: "modifiers",
								path: "/modifiers",
								render: access?.isCatalogueManagement
							},
							{
								label: "Taxes",
								key: "taxes",
								path: "/taxes",
								render: access?.isCatalogueManagement
							},
							{
								label: "Charges",
								key: "charges",
								path: "/charges",
								render: access?.isCatalogueManagement
							},
							{
								label: "Tags",
								key: "tags",
								path: "/tags",
								render: access?.isCatalogueManagement
							},
							{
								label: "Backups",
								key: "backups",
								path: "/backups",
								render: access?.isAdmin || access?.isCatalogueManagement
							}
						]
					},
					{
						label: "Menu Management",
						key: "menu-management",
						iconId: "menus",
						render:
							biz?.isMenuOverCatalogueEnabled &&
							access?.isCatalogueManagement &&
							(!isOnboardingFlowEnabled || isMenuStepInProgress || isMenuStepComplete || showFullNav),
						childrenProps: [
							{
								label: "Menus",
								key: "menus",
								path: "/menus",
								render: biz?.isMenuOverCatalogueEnabled && access?.isCatalogueManagement
							},
							{
								label: "Sections",
								key: "sections",
								path: "/sections",
								render: biz?.isMenuOverCatalogueEnabled && access?.isCatalogueManagement
							},
							{
								label: "Items",
								key: "items",
								path: "/items",
								render: biz?.isMenuOverCatalogueEnabled && access?.isCatalogueManagement
							},
							{
								label: "Modifier Groups",
								key: "modifier-groups",
								path: "/modifier-groups",
								render: biz?.isMenuOverCatalogueEnabled && access?.isCatalogueManagement
							},
							{
								label: "Modifiers",
								key: "modifiers",
								path: "/modifiers",
								render: biz?.isMenuOverCatalogueEnabled && access?.isCatalogueManagement
							},
							{
								label: "Taxes",
								key: "taxes",
								path: "/taxes",
								render: biz?.isMenuOverCatalogueEnabled && access?.isCatalogueManagement
							},
							{
								label: "Charges",
								key: "charges",
								path: "/charges",
								render: biz?.isMenuOverCatalogueEnabled && access?.isCatalogueManagement
							},
							{
								label: "Tags",
								key: "tags",
								path: "/tags",
								render: biz?.isMenuOverCatalogueEnabled && access?.isCatalogueManagement
							}
						]
					},
					{
						label: "Groups",
						key: "groups",
						iconId: "groups",
						render: access?.isCatalogueManagement && (!isOnboardingFlowEnabled || showFullNav),
						childrenProps: [
							{
								label: "Item Groups",
								key: "item-groups",
								path: "/item-groups",
								render: access?.isCatalogueManagement
							},
							{
								label: "Location Groups",
								key: "location-groups",
								path: "/location-groups",
								render: access?.isCatalogueManagement
							}
						]
					},
					{
						label: "Schedules",
						key: "schedules",
						iconId: "schedules",
						path: "/timing-groups",
						render: access?.isCatalogueManagement && (!isOnboardingFlowEnabled || showFullNav)
					},
					{
						label: "Analytics",
						key: "analytics",
						iconId: "analytics",
						render:
							(access?.isSalesAnalysis || access?.isOperationsAnalysis || access?.isMarketing) &&
							(!isOnboardingFlowEnabled || showFullNav),
						childrenProps: [
							{
								label: "Home",
								key: "home",
								path: "/analytics/home",
								render: true
							},
							{
								label: "Revenue",
								key: "revenue",
								path: `/analytics/revenue?filters=${getEncodedAnalyticsFilters()}`,
								render: access?.isSalesAnalysis || access?.isMarketing
							},
							{
								label: "Orders",
								key: "orders",
								path: `/analytics/orders?filters=${getEncodedAnalyticsFilters()}`,
								render: access?.isSalesAnalysis || access?.isMarketing
							},
							{
								label: "Operations",
								key: "operations",
								path: `/analytics/operations?filters=${getEncodedAnalyticsFilters()}`,
								render: access?.isOperationsAnalysis || access?.isMarketing
							},
							{
								label: "Catalogue",
								key: "catalogue",
								path: `/analytics/catalogue?filters=${getEncodedAnalyticsFilters()}`,
								render: access?.isSalesAnalysis || access?.isMarketing
							},
							{
								label: "Customers",
								key: "customers",
								path: `/analytics/customers?filters=${getEncodedAnalyticsFilters()}`,
								render: isInternalUser() && (access?.isSalesAnalysis || access?.isMarketing)
							},
							{
								label: "Compare",
								key: "compare",
								path: `/analytics/compare?filters=${getEncodedAnalyticsFilters()}`,
								isNew: true,
								render: true
							}
						]
					},
					{
						label: "Central Reports",
						key: "central-reports",
						iconId: "reports",
						path: "/reports",
						render:
							(access?.isSalesAnalysis || access?.isOperationsAnalysis) &&
							(!isOnboardingFlowEnabled || showFullNav)
					},
					{
						label: "Periscope",
						key: "periscope-early-access",
						iconId: "globe",
						path: "/periscope/early-access",
						render:
							access?.isAdmin &&
							isFeatureFlagEnabled("enable-periscope") &&
							!lS?.get("periscope")?.bizSubscription &&
							(!isOnboardingFlowEnabled || showFullNav)
					},
					{
						label: "Periscope",
						key: "periscope",
						iconId: "periscope",
						render:
							isFeatureFlagEnabled("enable-periscope") &&
							(periscopeEarlyAccessState?.bizSubscription || lS?.get("periscope")?.bizSubscription) &&
							(!isOnboardingFlowEnabled || showFullNav),
						childrenProps: [
							{
								label: "Home",
								key: "home",
								path: "/periscope/home",
								render: true
							},
							{
								label: "Storefront Availability",
								key: "storefront-availability",
								path: "/periscope/storefront-availability",
								render: true
							},
							{
								label: "Item Availability",
								key: "item-availability",
								path: "/periscope/item-availability",
								render: true
							},
							{
								label: "Item Issues",
								key: "item-issues",
								path: "/periscope/item-issues",
								render: true
							},
							{
								label: "Activity Log",
								key: "activity-log",
								path: "/periscope/activity-log",
								render: true
							}
						]
					},
					{
						label: "Reconciliation",
						key: "reconciliation",
						iconId: "reconciliation",
						render: biz?.isReconEnabled && (!isOnboardingFlowEnabled || showFullNav),
						childrenProps: [
							{
								label: "Payouts",
								key: "payouts",
								path: "/payout-sheets",
								render: true
							},
							{
								label: "Stats",
								key: "stats",
								path: "/reconciliation-stats",
								render: true
							}
						]
					},
					{
						label: "Marketing",
						key: "marketing",
						iconId: "marketing",
						render: !isOnboardingFlowEnabled || showFullNav,
						childrenProps: [
							{
								label: "Customers",
								key: "customers",
								path: "/customers",
								render:
									(access?.isSupport || access?.isMarketing) &&
									(!isOnboardingFlowEnabled || showFullNav)
							},
							{
								label: "Segments",
								key: "segments",
								path: "/segments",
								render: true
							},
							{
								label: "Campaigns",
								key: "campaigns",
								path: "/campaigns",
								render: true
							},
							{
								label: "Platform Discounts",
								key: "platform-discounts",
								path: "/discounts",
								render: access?.isHubManagement
							},
							{
								label: "Meraki Coupons",
								key: "meraki-coupons",
								path: "/coupons",
								isLocked:
									merakiWebSubscription?.subscription === null ||
									["cancelled", "not-subscribed"]?.includes(
										merakiWebSubscription?.subscription?.status
									),
								render: true && biz?.modulesEnabled?.includes("Meraki")
							},
							{
								label: "Meraki Referrals",
								key: "meraki-referrals",
								path: "/referrals",
								isLocked:
									merakiWebSubscription?.subscription === null ||
									["cancelled", "not-subscribed"]?.includes(
										merakiWebSubscription?.subscription?.status
									),
								render: access?.isHubManagement && biz?.modulesEnabled?.includes("Meraki")
							}
						]
					},
					{
						label: "Meraki",
						key: "meraki",
						iconId: "meraki",
						isLocked: !biz?.modulesEnabled?.includes("Meraki"),
						path: !biz?.modulesEnabled?.includes("Meraki") ? "/meraki-landing" : undefined,
						render: !isOnboardingFlowEnabled || showFullNav,
						childrenProps: biz?.modulesEnabled?.includes("Meraki")
							? [
									{
										label: "Web",
										key: "web",
										path: "/meraki-web",
										isLocked:
											merakiWebSubscription?.subscription === null ||
											["cancelled", "not-subscribed"]?.includes(
												merakiWebSubscription?.subscription?.status
											),
										render: true
									},
									{
										label: "Gallery",
										key: "gallery",
										path: "/gallery",
										render:
											access?.isAdmin &&
											!(
												merakiWebSubscription?.subscription === null ||
												["cancelled", "not-subscribed"]?.includes(
													merakiWebSubscription?.subscription?.status
												)
											)
									},
									{
										label: "Feedback",
										key: "feedback",
										iconId: "feedback",
										path: biz?.modulesEnabled?.includes("Meraki") ? "/feedback" : "",
										onClick: !biz?.modulesEnabled?.includes("Meraki") ? handleModalOpen : undefined,
										isLocked: !biz?.modulesEnabled?.includes("Meraki"),
										render:
											(access?.isSupport || access?.isMarketing) &&
											(!isOnboardingFlowEnabled || showFullNav)
									},
									{
										label: "Plans & Billing",
										key: "plans-&-billing",
										path: "/meraki-plans",
										render:
											merakiWebSubscription?.subscription === null ||
											merakiWebSubscription?.subscription?.status !== "legacy-billing"
									},
									{
										label: "Settings",
										key: "settings",
										path: "/meraki",
										render: !(
											merakiWebSubscription?.subscription === null ||
											["cancelled", "not-subscribed"]?.includes(
												merakiWebSubscription?.subscription?.status
											)
										)
									}
								]
							: undefined
					},
					{
						label: "Settings",
						key: "settings",
						iconId: "settings",
						render:
							access?.isAdmin ||
							access?.isBilling ||
							access?.isHubManagement ||
							access?.isOperationsAnalysis ||
							access?.isSupport,
						childrenProps: [
							{
								label: "Profile",
								key: "profile",
								path: "/profile",
								render: !isOnboardingFlowEnabled || isCompanyDetailsStepComplete || showFullNav
							},
							{
								label: "Brands",
								key: "brands",
								path: "/brands",
								render:
									biz?.isMultibrandEnabled &&
									(!isOnboardingFlowEnabled || isBrandStepComplete || showFullNav)
							},
							{
								label: "Billing",
								key: "billing",
								path: "/billing",
								render: access?.isBilling && (!isOnboardingFlowEnabled || showFullNav)
							},
							{
								label: "Users",
								key: "users",
								path: "/unified-access",
								render: !isOnboardingFlowEnabled || isInviteUsersStepComplete || showFullNav
							},
							{
								label: "API Access",
								key: "api-access",
								path: "/api-access",
								render:
									access?.isAdmin &&
									(!isOnboardingFlowEnabled || isCompanyDetailsStepComplete || showFullNav)
							},
							{
								label: "Platforms",
								key: "platforms",
								path: "/hub",
								render: !isOnboardingFlowEnabled || isRTGLStepComplete || showFullNav
							},
							{
								label: "Webhooks",
								key: "webhooks",
								path: "/webhooks",
								isLocked: isWebhookDisabled && !isInternalUser(),
								onClick: isWebhookDisabled && !isInternalUser() ? handleBillingAlertModal : undefined,
								render: access?.isAdmin && (!isOnboardingFlowEnabled || showFullNav)
							},
							{
								label: "Activity History",
								key: "activity-history",
								path: "/activity-history",
								render: !isOnboardingFlowEnabled || showFullNav
							},
							{
								label: "Gallery",
								key: "gallery",
								path: "/gallery",
								render:
									access?.isAdmin &&
									(!biz?.modulesEnabled?.includes("Meraki") ||
										merakiWebSubscription?.subscription === null ||
										["cancelled", "not-subscribed"]?.includes(
											merakiWebSubscription?.subscription?.status
										)) &&
									(!isOnboardingFlowEnabled || showFullNav)
							},
							{
								label: "POS Integrations",
								key: "pos-integrations",
								path: "/integrations",
								render: access?.isAdmin && biz?.eisEnabled && (!isOnboardingFlowEnabled || showFullNav)
							},
							{
								label: "Developer Tools",
								key: "developer-tools",
								path: "/developer-tools",
								render:
									access?.isAdmin &&
									(ENVIRONMENT_FLAG === "posint" ||
										(ENVIRONMENT_FLAG === "prod" &&
											DEVELOPER_TOOLS_ENABLED_PRODUCTION_BIZ_IDS[biz?.id])) &&
									(!isOnboardingFlowEnabled || showFullNav)
							}
						]
					}
				]
			: [
					{
						label: "Analytics",
						key: "analytics",
						iconId: "analytics",
						render: true,
						childrenProps: [
							{
								label: "Home",
								key: "home",
								path: "/analytics/home",
								render: access?.isNonHqAnalytics
							},
							{
								label: "Revenue",
								key: "revenue",
								path: `/analytics/revenue?filters=${getEncodedAnalyticsFilters()}`,
								render: access?.isNonHqAnalytics
							},
							{
								label: "Orders",
								key: "orders",
								path: `/analytics/orders?filters=${getEncodedAnalyticsFilters()}`,
								render: access?.isNonHqAnalytics
							},
							{
								label: "Operations",
								key: "operations",
								path: `/analytics/operations?filters=${getEncodedAnalyticsFilters()}`,
								render: access?.isNonHqAnalytics
							},
							{
								label: "Catalogue",
								key: "catalogue",
								path: `/analytics/catalogue?filters=${getEncodedAnalyticsFilters()}`,
								render: access?.isNonHqAnalytics
							},
							{
								label: "Customers",
								key: "customers",
								path: `/analytics/customers?filters=${getEncodedAnalyticsFilters()}`,
								render: isInternalUser() && (access?.isSalesAnalysis || access?.isMarketing)
							},
							{
								label: "Compare",
								key: "compare",
								path: `/analytics/compare?filters=${getEncodedAnalyticsFilters()}`,
								render: access?.isNonHqAnalytics
							}
						]
					},
					{
						label: "Central Reports",
						key: "central-reports",
						iconId: "reports",
						path: "/reports",
						render: access?.isNonHqReport && (!isOnboardingFlowEnabled || showFullNav)
					}
				];
	return items;
};

export default getSideNavItems;

// third party
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import _ from "lodash";

// reducers
import reducers from "../reducers";

// actions
import { saveCampaign } from "../actions/campaign";
const NODE_ENV = process.env.NODE_ENV;

const saveCampaignDebounced = _.debounce((campaign) => saveCampaign(campaign), 500);

const savecampaignhandler = (store) => (next) => (action) => {
	next(action);
	if (action.type === "CREATE_CAMPAIGN_STATE_UPDATE" && action.payload.noSave != true) {
		let campaignToSave = store.getState().createCampaign;
		let campaignIDToSave = campaignToSave && campaignToSave.id;
		if (campaignIDToSave) {
			console.log(campaignIDToSave, "to save");
			saveCampaignDebounced(campaignToSave);
		}
	}
};

const configureStore = () => {
	// configure middlewares
	const middlewares = [savecampaignhandler];
	const middlewareEnhancer = applyMiddleware(...middlewares);
	const composedEnhancer = composeWithDevTools(middlewareEnhancer);

	// finally, create the store. the second param can be used to preload the store,
	// it's undefined for now.
	const store = createStore(reducers, undefined, composedEnhancer);

	// Enable Webpack hot module replacement for reducers
	if (module.hot && NODE_ENV !== "production") {
		module.hot.accept("../reducers", () => store.replaceReducer(reducers));
	}

	return store;
};

export const store = configureStore();
window.store = store;
